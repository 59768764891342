import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ApExpenseModels } from './ApExpenseModels';
import { APIResponse } from '../../../Shared/shared';

export const addApExpense = createAsyncThunk(
  'ApExpense/addApExpense',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Expense/AddApExpenseHeaders', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApExpenseList = createAsyncThunk(
  'ApExpense/getApExpenseList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApExpenseModels[]>>(
        'Expense/GetApExpenseHeadersList'
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ApExpenseList: ApExpenseModels[];
  loading: boolean;
  error: any | null;
} = {
  ApExpenseList: [],
  loading: false,
  error: null,
};

const ApExpenseSlice = createSlice({
  name: 'ApExpense',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getApExpenseList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getApExpenseList.fulfilled, (state, action: PayloadAction<ApExpenseModels[]>) => {
        state.loading = false;
        state.ApExpenseList = action.payload;
      })
      .addCase(getApExpenseList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ApExpenseSlice.reducer;
