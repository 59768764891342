import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const purchasesMenu = [
  {
    System: 'PUR',
    menu: [
      { is_header: true, title: 'المشتريات', isActive: true },

      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'Home',
        isActive: true,
      },

      { is_header: true, title: 'العمليات', isActive: true },

      {
        path: 'PurchasesTransactions/PurchaseRequests',
        icon: 'fas fa-shopping-cart',
        title: 'طلبات الشـراء',
        isActive: true,
      },
      {
        path: 'PurchasesTransactions/Quotation',
        icon: 'fas fa-tags',
        title: 'عروص الأسعار',
        isActive: true,
      },
      {
        path: 'PurchasesTransactions/PurchaseOrder',
        icon: 'fas fa-file-invoice-dollar',
        title: 'أوامر الشراء',
        isActive: true,
      },
 
      {
        path: 'PurchasesTransactions/ReceiveNoneInventoryItem',
        icon: 'fas fa-box-open',
        title: 'إستلام الطلبات الغيــر مخزنية',
        isActive: true,
      },
 

      { is_header: true, title: 'الإعدادات', isActive: true },

      {
        path: 'PurchasesSettings/SUPPLIERS',
        icon: 'fas fa-users',
        title: 'الموردين',
        isActive: true,
      },
 

      { is_header: true, title: 'التقاريـــر', isActive: true },

 
      {
        path: 'Purchases/PurchaseReports',
        icon: 'fas fa-chart-bar',
        title: 'التقارير',
        isActive: true,
      },
      {
        path: 'Purchases/PurchaseDashboards',
        icon: 'fas fa-tachometer-alt',
        title: 'لوحة المعلومات',
        isActive: true,
      },
    ],
  },
];

export default purchasesMenu;
