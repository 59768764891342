/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext } from 'react';
import { slideToggle } from './../../composables/slideToggle.js';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { useTranslation } from 'react-i18next';
import useGetNtxProfile from '../../hooks/NTX/useGetNtxProfile.ts';
import useGetSysCode from '../../hooks/useGetSysCode';
import { AppSettings } from '../../config/app-settings.js';
import NotificationIcon from './Notifications/notificationIcon.jsx';
import { useDispatch } from 'react-redux';
import { LogOut } from '../../store/Login/loginSlice';
import TimerToken from './TimerToken/TimerToken';
import AppsMenu from './AppsMenu/AppsMenu';

function Header({ themeClass }) {
  const sysCode = useGetSysCode();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { UserProfile: Profile } = useGetNtxProfile();
  const context = useContext(AppSettings);
  const appTopNav = context.appTopNav;
  const appSidebar = context.appSidebarNone;

  const toggleAppSidebarDesktop = () => {
    var elm = document.querySelector('.app');
    if (elm) {
      if (
        !(
          elm.classList.contains('app-with-top-nav') &&
          elm.classList.contains('app-without-sidebar')
        )
      ) {
        elm.classList.toggle('app-sidebar-collapsed');
      }
    }
  };

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector('.app');
    if (elm) {
      if (
        !(
          elm.classList.contains('app-with-top-nav') &&
          elm.classList.contains('app-without-sidebar')
        )
      ) {
        elm.classList.toggle('app-sidebar-mobile-toggled');
      } else {
        slideToggle(document.querySelector('.app-top-nav'));
      }
    }
  };
  // const AppLicationNavigate = () => {
  //   var elm = document.querySelector(".app");
  //   elm.classList.toggle("app-header-menu-search-toggled");
  // };

  // handle Logout
  const handleLogout = () => {
    localStorage.clear();
    dispatch(LogOut());
  };

  return (
    <Fragment>
      <div
        id="header"
        className={`app-header ${!appTopNav ? 'shadow border-bottom border-theme' : '  '} `}>
        <>
          <div className="desktop-toggler" hidden={appSidebar}>
            <button type="button" className="menu-toggler" onClick={toggleAppSidebarDesktop}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </button>
          </div>
          <div className="mobile-toggler">
            <button type="button" className="menu-toggler" onClick={toggleAppSidebarMobile}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </button>
          </div>
        </>

        <div className="brand">
          <Link
            className="brand-logo"
            // to="/Neatix/Applications">
            to="/UserDashboard">
            <Logo themeClass={themeClass} />
          </Link>
        </div>

        <div className="brand  mt-1 text-theme text-center ">
          <h6 className="text-theme">{sysCode.name}</h6>
        </div>

        <div className="menu">
          {/* <div className="menu-item dropdown  ">
            <Link to="/Neatix/Applications" className="menu-link">
              <div className="menu-icon">
                <i className="bi bi-grid-3x3-gap nav-icon"></i>
              </div>
            </Link>
          </div> */}
 
          <TimerToken />
          <AppsMenu />

          <NotificationIcon />

          <div className="menu-item dropdown dropdown-mobile-full">
            <a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
              <div className="menu-img online">
                <div className="d-flex align-items-center justify-content-center w-100 h-100 bg-inverse bg-opacity-25 text-inverse text-opacity-50 rounded-circle overflow-hidden">
                  {Profile?.personImage === null ? (
                    <img
                      src="../../../assets/img/user/manEmp.png"
                      alt=""
                      width={100}
                      className="rounded-circle"
                    />
                  ) : (
                    <img
                      className="rounded-circle "
                      src={Profile?.personImage?.filePath}
                      alt=""
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="menu-text d-sm-block d-none w-200px">
                {i18n.language === 'en' ? Profile?.name : Profile?.name2}
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
              <Link to="/Neatix/Profile" className="dropdown-item d-flex align-items-center">
                {t('Profile')}
                <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1"></i>
              </Link>

              <Link to="/Neatix/Settings" className="dropdown-item d-flex align-items-center">
                {t('Settings')}
                <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i>
              </Link>
              <div className="dropdown-divider"></div>
              <Link
                to="Security/Users/ChangePassword"
                className="dropdown-item d-flex align-items-center">
                {t('ChangePassword')}
                <i className="bi bi-key ms-auto text-theme fs-16px my-n1"></i>
              </Link>
              <Link
                to="/login"
                replace
                onClick={() => handleLogout()}
                className="dropdown-item d-flex align-items-center">
                {t('logout')}
                <i className="bi bi-box-arrow-right ms-auto text-theme fs-16px my-n1"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Header;

// <div className="menu-item dropdown dropdown-mobile-full">
// <a
//   href="#/"
//   data-bs-toggle="dropdown"
//   data-bs-display="static"
//   className="menu-link"
// >
//   <div className="menu-icon">
//     <i className="bi bi-bell nav-icon"></i>
//   </div>
//   {/* <div className="menu-badge bg-theme"></div> */}
// </a>
// <div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
//   <h6 className="dropdown-header fs-10px mb-1">
//     {t("NOTIFICATIONS")}
//   </h6>
//   <div className="dropdown-divider mt-1"></div>
//   {notificationData?.length > 0 ? (
//     notificationData.map((notification, index) => (
//       <a
//         href="#/"
//         key={index}
//         className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold"
//       >
//         <div className="fs-20px">
//           <i className={notification.icon}></i>
//         </div>
//         <div className="flex-1 flex-wrap ps-3">
//           <div className="mb-1 text-inverse">
//             {notification.title}
//           </div>
//           <div className="small text-inverse text-opacity-50">
//             {notification.time}
//           </div>
//         </div>
//         <div className="ps-2 fs-16px">
//           <i className="bi bi-chevron-right"></i>
//         </div>
//       </a>
//     ))
//   ) : (
//     <div className="dropdown-notification-item p-3 text-center">
//       لا يوجد لديك إشعارات
//     </div>
//   )}
//   <hr className="mb-0 mt-2" />
//   <div className="py-10px mb-n2 text-center">
//     <a href="#/" className="text-decoration-none fw-bold">
//       ___
//     </a>
//   </div>
// </div>

// </div>

// كانت تظهر ايقونة التطبيقات في الجوالفقط
//  <div className="menu-item dropdown d-lg-none">
//             <Link
//               to="/Neatix/Applications"
//                className="menu-link">
//               <div className="menu-icon">
//                 <i className="bi bi-grid-3x3-gap nav-icon"></i>
//               </div>
//             </Link>
//           </div>
