import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
// import { GetPaymentTermsList } from "../../store/NTX/Lookup/lookupsSlice";
import { getPaymentTermsList } from '../../store/Finance/PaymentTerms/PaymentTermsSlice';

const useGetPaymentTermsList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (PaymentTermsList?.length === 0) {
      dispatch(getPaymentTermsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { PaymentTermsList, loading, error } = useAppSelector((state) => state.PaymentTerms);

  return { PaymentTermsList, loading, error };
};

export default useGetPaymentTermsList;
