import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const hrMenu = [
  {
    System: 'HR',
    isActive: false,
    menu: [
      { is_header: true, title: 'HumanResources', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'HomePage',
      },

      {
        path: '/HRsettings',
        icon: 'bi bi-gear',
        title: 'Settings',
        children: [
          {
            path: '/HRsettings/Job',
            title: 'Jobs',
            isActive: HasPermission('ViewlistHrPerJob'),
          },
          {
            path: '/HRsettings/JobGrade',
            title: 'Jobgrades',
            isActive: HasPermission('ViewlistHrPerGrade'),
          },
          {
            path: '/HRsettings/Location',
            title: 'Locations',
            isActive: HasPermission('listHrLocation'),
          },
          {
            path: '/HRsettings/Position',
            title: 'Positions',
            isActive: HasPermission('listHrPerPosition'),
          },
          {
            path: '/HRsettings/StaffGroup',
            title: 'Staffgroup',
            isActive: HasPermission('listHrPerGroups'),
          },
        ],
      },
      //
      {
        path: '/Org',
        icon: 'bi bi-building',
        title: 'Organizations',
        children: [
          {
            path: '/Org/Departments',
            title: 'List_Organizations',
            isActive: HasPermission('ListHrDepartments'),
          },

          {
            path: '/Org/OrganizationStructure',
            title: 'OrganizationalStructure',
            isActive: HasPermission('ViewStructureHrDepartments'),
          },
        ],
      },

      {
        path: 'HR/Employees',
        icon: 'bi bi-people',
        title: 'employees',
        isActive: HasPermission('ViewlistHrPerson'),
        // isActive:  usePermissions().HasPermission('ViewlistHrPerson'),
      },

      {
        path: 'HR/Delegation',
        icon: 'bi bi-shield-check',
        title: 'Delegations',
        isActive: true,
      },

      // { is_divider: true , isActive : true },
      // { is_header: true, title: 'الموارد البشرية', isActive : true   },

      // {
      //   path: 'HRAme/',
      //   icon: 'fa-solid fa-code-compare',
      //   title: 'selfService',
      //   children: [
      //     {
      //       path: 'HRAme/Requests',
      //       title: 'NewRequest',
      //       isActive: true,
      //     },
      //     {
      //       path: 'Ame/SelfService/MyRequests',
      //       title: 'MyRequests',
      //       isActive: true,
      //     },
      //     {
      //       path: 'Ame/SelfService/AllRequests',
      //       title: 'RequestsTracking',
      //       isActive: true,
      //     },
      //   ],
      // },

      // {
      //   path: '/RAQ',
      //   icon: 'far fa-clock',
      //   title: 'attendancePlan',
      //   children: [
      //     {
      //       path: 'RAQ/RotationPlan',
      //       title: 'RotationPlan',
      //       isActive: HasPermission('ViewListRaqRotationPlans'),
      //     },
      //     {
      //       path: 'RAQ/AttendancAnalysis',
      //       title: 'AttendanceAnalysis',
      //       isActive: HasPermission('AttendanceDataAnalysisReport'),
      //     },
      //     {
      //       path: 'RAQ/UpdateRaqeebData',
      //       title: 'UpdateAttendanceData',
      //       isActive: HasPermission('RAQAddUpdateAnalysisResults'),
      //     },
      //   ],
      // },

      // {
      //   path: '/Pay',
      //   icon: 'fas fa-money-bill-alt',
      //   title: 'PayrollManagement',
      //   children: [
      //     {
      //       path: 'Pay/payrolls',
      //       title: 'PayrollsSettings',
      //       isActive: HasPermission('PayViewPayrollList'),
      //       // isActive:false,
      //     },

      //     {
      //       path: 'Pay/PayrollElement',
      //       title: 'PayrollElements',
      //       isActive: HasPermission('PayViewListPayrollElement'),
      //       // isActive:false,
      //     },

      //     {
      //       path: 'Pay/payrollsRun',
      //       title: 'RunPayrolls',
      //       isActive: HasPermission('PayCreatRunPayroll'),
      //       // isActive:false,
      //     },
      //     {
      //       path: 'Pay/QuickPay',
      //       title: 'QuickPay',
      //       isActive: HasPermission('PayCreatePayrollQuickPay'),
      //       // isActive:false,
      //     },

      //     {
      //       path: 'Pay/PayrollElementEntry',
      //       title: 'PayrollElementEntry',
      //       isActive: HasPermission('PayPayrollElementEntries'),
      //       // isActive:false,
      //     },
      //   ],
      // },

      {
        path: 'HR/Reports',
        icon: 'bi bi-clipboard-data-fill',
        title: 'Reports',
        // isActive:false,
        isActive: true,
      },

      {
        path: 'HrDashboard',
        icon: 'bi bi-cpu',
        title: 'Dashboard',
        // isActive:false,
        isActive: true,
        // highlight: true,
      },
    ],
  },
];

export default hrMenu;
