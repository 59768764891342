import ReportsBuilder from '../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder';

import SalesReports from '../../pages/Sales/SalesReports/SalesReports';
import SalesDashboards from '../../pages/Sales/SalesDashboards/SalesDashboards';
import SalesRequests from '../../pages/Sales/SalesRequests/SalesRequests';

import Customers from '../../pages/Sales/SalesSettings/Customers/Index';
import UpdateCustomers from '../../pages/Sales/SalesSettings/Customers/Update';
import AddCustomers from '../../pages/Sales/SalesSettings/Customers/Add';
import BranchesCustomer from '../../pages/Sales/SalesSettings/Customers/Branches/Index';

import PeopleLocations from '../../pages/Sales/SalesSettings/Customers/CustomersLocations/CustomersLocations';

// import SalesInvoices from '../../pages/Sales/Transactions/SalesInvoices/Index';
// import AddSalesInvoices from '../../pages/Sales/Transactions/SalesInvoices/Add';
// import UpdateSalesInvoices from '../../pages/Sales/Transactions/SalesInvoices/Update';
import Quotation from '../../pages/Sales/Transactions/Quotation/Index';
import UpdateQuotation from '../../pages/Sales/Transactions/Quotation/Update';
import AddQuotation from '../../pages/Sales/Transactions/Quotation/Add';

import SalesContracts from '../../pages/Sales/Transactions/Contracts/index/Contracts';
import AddSalesContracts from '../../pages/Sales/Transactions/Contracts/Add/AddContracts';

import SellOrder from '../../pages/Sales/Transactions/SellOrder/Index';
import AddSellOrder from '../../pages/Sales/Transactions/SellOrder/Add';
import UpdateSellOrder from '../../pages/Sales/Transactions/SellOrder/Update';

import SalesReturn from '../../pages/Sales/Transactions/SalesReturn/Index';
import AddSalesReturn from '../../pages/Sales/Transactions/SalesReturn/SalesReturn';

// import DeliveryPermission from '../../pages/Sales/Transactions/DeliveryPermission/Index';
// import AddDeliveryPermission from '../../pages/Sales/Transactions/DeliveryPermission/Add';

import PriceList from '../../pages/Sales/SalesSettings/PriceList/Index';
import DetailsPriceList from '../../pages/Sales/SalesSettings/PriceList/DetailsPriceList';

import Vouchers from '../../pages/Sales/Vouchers/Index';
import AddVouchers from '../../pages/Sales/Vouchers/AddVoucher';

import Vouchers2 from '../../pages/Sales/Vouchers2/Index';
import VoucherType from '../../pages/Sales/Vouchers2/VoucherType';
import BuyXgetY from '../../pages/Sales/Vouchers2/BuyXgetY/Add';

import BuilderDashboard from '../../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard';

const salesRoutes = [
  {
    path: 'SalesSettings/*',
    children: [
      { path: 'Customers', element: <Customers /> },
      { path: 'Customers/AddCustomers', element: <AddCustomers /> },
      { path: 'Customers/:Id/UpdateCustomers', element: <UpdateCustomers /> },
      { path: 'Customers/:id/BranchesCustomer', element: <BranchesCustomer /> },

      { path: 'Customers/:peopleId/PeopleLocations', element: <PeopleLocations /> },

      { path: 'PriceList', element: <PriceList /> },
      { path: 'PriceList/:id/DetailsPriceList', element: <DetailsPriceList /> },

      { path: 'Vouchers', element: <Vouchers /> },
      { path: 'Vouchers/AddVouchers', element: <AddVouchers /> },

      { path: 'Vouchers2', element: <Vouchers2 /> },
      { path: 'Vouchers2/:voucherTypeId/VoucherType', element: <VoucherType /> },
      {
        path: 'Vouchers2/:voucherTypeId/VoucherType/:voucherId?/BuyXgetY',
        element: <BuyXgetY />,
      },
    ],
  },

  {
    path: 'SalesTransactions/*',
    children: [
      // { path: 'SalesInvoices', element: <SalesInvoices /> },
      // { path: 'SalesInvoices/AddSalesInvoices/:id?', element: <AddSalesInvoices /> },
      // { path: 'SalesInvoices/:id/UpdateSalesInvoices', element: <UpdateSalesInvoices /> },

      { path: 'Contracts', element: <SalesContracts /> },
      { path: 'Contracts/AddSalesContracts', element: <AddSalesContracts /> },

      { path: 'SellOrder', element: <SellOrder /> },
      { path: 'SellOrder/AddSellOrder/:id?', element: <AddSellOrder /> },
      { path: 'SellOrder/:id/UpdateSellOrder', element: <UpdateSellOrder /> },

      { path: 'Quotation', element: <Quotation /> },
      { path: 'Quotation/AddQuotation', element: <AddQuotation /> },
      { path: 'Quotation/:id/UpdateQuotation', element: <UpdateQuotation /> },

      { path: 'SalesReturn', element: <SalesReturn /> },
      { path: 'SalesReturn/:InvoiceId/AddSalesReturn', element: <AddSalesReturn /> },

      // { path: 'DeliveryPermission', element: <DeliveryPermission /> },
      // { path: 'DeliveryPermission/AddDeliveryPermission', element: <AddDeliveryPermission /> },
    ],
  },

  {
    path: 'Sales/*',
    children: [
      { path: 'SalesRequests', element: <SalesRequests /> },

      { path: 'SalesDashboards', element: <SalesDashboards /> },
      { path: 'SalesDashboards/:dashboardId/BuilderDashboard', element: <BuilderDashboard /> },

      { path: 'SalesReports', element: <SalesReports /> },
      {
        path: 'SalesReports/:ReportId/ReportsBuilder',
        element: <ReportsBuilder />,
      },
    ],
  },
];

export default salesRoutes;
