import Suppliers from '../../pages/PUR/Settings/Suppliers/Index';
import AddSuppliers from '../../pages/PUR/Settings/Suppliers/Add';
import UpdateSupplier from '../../pages/PUR/Settings/Suppliers/Update';
import BranchesSupplier from '../../pages/PUR/Settings/Suppliers/Branches/Index';

import TypesOfExpenses from '../../pages/Finance/Settings/TypesOfExpenses/ExpenseCategory/Index';
import PaymentTerms from '../../pages/Finance/Settings/PaymentTerms/Index';
import Currencies from '../../pages/Finance/Settings/Currencies/Index';

//_Finance Ap
import ApInvoices from '../../pages/Finance/Ap/ApInvoices/Index';
import AddApInvoices from '../../pages/Finance/Ap/ApInvoices/Add';
import UpdateApInvoices from '../../pages/Finance/Ap/ApInvoices/Update';
import ApInvoicesCreateAccount from '../../pages/Finance/Ap/ApInvoices/CreateAccount/ListApInvoices';

import ApPayments from '../../pages/Finance/Ap/ApPayments/Index';
import AddApPayments from '../../pages/Finance/Ap/ApPayments/Add/Add';
import UpdateApPayments from '../../pages/Finance/Ap/ApPayments/Update/Update';
import ApPaymentsCreateAccount from '../../pages/Finance/Ap/ApPayments/CreateAccount/ListApPayments';

import ApExpense from '../../pages/Finance/Ap/ApExpense/Index';
import AddApExpense from '../../pages/Finance/Ap/ApExpense/Add';

import InvoicePayments from '../../pages/Finance/Ap/InvoicePayments/Index';
import ViewInvoice from '../../pages/Finance/Ap/InvoicePayments/ViewInvoice/ViewInvoice';

import ApReports from '../../pages/Finance/Ap/ApReports/ApReports';
import ReportsBuilder from '../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder';

import ApDashboards from '../../pages/Finance/Ap/ApDashboards/ApDashboards';
import BuilderDashboard from '../../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard';

const apRoutes = [
  {
    path: 'Finance/Ap',
    children: [
      //الإعدادات
      { path: 'Suppliers', element: <Suppliers /> },
      { path: 'SUPPLIERS/AddSupplier', element: <AddSuppliers /> },
      { path: 'SUPPLIERS/:Id/UpdateSupplier', element: <UpdateSupplier /> },

      { path: 'SUPPLIERS/:id/BranchesSupplier', element: <BranchesSupplier /> },

      { path: 'TypesOfExpenses', element: <TypesOfExpenses /> },
      { path: 'PaymentTerms', element: <PaymentTerms /> },
      { path: 'Currencies', element: <Currencies /> },

      { path: 'ApInvoices', element: <ApInvoices /> },
      { path: 'ApInvoices/AddApInvoices/:referenceId?', element: <AddApInvoices /> },
      { path: 'ApInvoices/:IdInvoices/UpdateApInvoices', element: <UpdateApInvoices /> },
      { path: 'CreateAccountApInvoices', element: <ApInvoicesCreateAccount /> },

      { path: 'ApPayments', element: <ApPayments /> },
      { path: 'ApPayments/AddApPayments', element: <AddApPayments /> },
      { path: 'ApPayments/:paymentId/UpdateApPayments', element: <UpdateApPayments /> },
      { path: 'CreateAccountApPayments', element: <ApPaymentsCreateAccount /> },

      { path: 'ApExpense', element: <ApExpense /> },
      { path: 'ApExpense/AddApExpense/:referenceId?', element: <AddApExpense /> },

      { path: 'InvoicePayments', element: <InvoicePayments /> },
      { path: 'InvoicePayments/:invoicesId/ViewInvoice', element: <ViewInvoice /> },

      { path: 'ApReports', element: <ApReports /> },
      { path: 'ApReports/:ReportId/ReportsBuilder', element: <ReportsBuilder /> },

      { path: 'ApDashboards', element: <ApDashboards /> },
      { path: 'ApDashboards/:dashboardId/BuilderDashboard', element: <BuilderDashboard /> },
    ],
  },
];

export default apRoutes;
