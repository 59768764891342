import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import useGetReceiptsList from '../../../../hooks/AR/ArReceipts/useGetReceiptsList';
import NtxTable from '../../../../components/DataTable/NtxTable';

const Index = () => {
  const [search, setSearch] = useState('');
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  // const { PurchaseBillList, loading, error } = useGetPurchaseBillList();

  const { ArReceiptsList, loading, error } = useGetReceiptsList();

  const filteredSearch = ArReceiptsList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item.id.toLowerCase().includes(searchLowerCase);
  });

  const columns = [
    { title: 'رقم السند', key: 'code', sortable: false },
    { title: 'التاريخ', key: 'paymentDate' },
    { title: 'نوع السند', key: 'paymentTypeName' },
    { title: 'المبلغ', key: 'amount', className: 'text-theme' },
    { title: 'العميل', key: 'peopleName' },
    { title: 'الفرع', key: 'organizationName' },
    { title: 'حالة الترحيل', key: 'postedFlagDisplay', className: 'text-center', sortable: false }, // إضافة حالة الترحيل

    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData = ArReceiptsList?.map((item) => {
    let rowClass;

    const postedFlagBadge = item.postedFlag ? (
      <span className="badge bg-success">
        <i className="fas fa-check me-1"></i> {/* أيقونة للترحيل */}
        مُرحلة
      </span>
    ) : (
      <span className="badge bg-danger">
        <i className="fas fa-times me-1"></i> {/* أيقونة لعدم الترحيل */}
        غير مُرحلة
      </span>
    );
    return {
      code: item.code,
      paymentDate: item.paymentDate,
      paymentTypeName: i18n.language === 'en' ? item.paymentType : item.paymentType,
      amount: item.amount,
      peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
      postedFlag: item.postedFlag,
      postedFlagDisplay: postedFlagBadge,
      action: (
        <Link to={`${item.id}/UpdateArReceipts`} className="btn btn-outline-theme mx-1">
          <i className="fas fa-info-circle me-2"></i>
          {t('التفاصيل')}
        </Link>
      ),
      rowClass, // استخدم rowClass هنا
    };
  });

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'سندات الإستلام',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="سندات الإستلام" />
        <div className="ms-auto">
          <Link to="AddArReceipts" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            سنـد جديـد
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              {/* <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">{t('Search')}</span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control ps-35px"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <NtxTable
                data={tableData}
                columns={columns}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch={true}
                showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Index;
