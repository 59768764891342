import { useFormik } from 'formik';
import React from 'react';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import TransactionsLines2 from '../TransactionsLines/TransactionsLines2';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import {
  updateInvoiceTransactions,
 } from '../../../../store/Sales/SalesInvoices/salesInvoicesSlice';
import { useAppDispatch } from '../../../../store/hooks';
import useGetPeoplesList from '../../../../hooks/NTX/useGetPeoplesList';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import {  useNavigate, useParams } from 'react-router-dom';
 import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import DynamicPrint from '../../../../components/DynamicPrint/DynamicPrint';
import useGetSalesInvoicesById from '../../../../hooks/Sales/useGetSalesInvoicesById';

const Update = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { Currency } = useLookupCurrency();
  const { dataEmployees, loading } = useGetPersons();
  const { BranchsOptions, InventoryOptions, loading: loading3 } = useGetOrganizations();
  const {
    OptionCustomers,
    CustomerList,
    loading: loading2,
  } = useGetPeoplesList({ LookupType: '' });
  const { PaymentMethodList } = useGetPaymentMethod();

  const { SalesInvoicesById: data } = useGetSalesInvoicesById({ id: id ?? '' });

  //#region Formik

  const formik = useFormik({
    initialValues: {
      id: data ? data.id : '',
      no: data ? data.no : '',
      code: data ? data.code : '',
      name: data ? data.name : '',
      name2: data ? data.name2 : '',
      transTypeId: data ? data.transTypeId : '',
      organizationId: data ? data.organizationId : '',
      peopleId: data ? data.peopleId : '',
      recipientname: data ? data.recipientname : '',
      billToLocationId: data ? data.billToLocationId : '',
      shipToLocationId: data ? data.shipToLocationId : '',
      referenceId: data ? data.referenceId : '',
      transactionDate: data ? data.transactionDate : '',
      deliveryDate: data ? data.deliveryDate : '',
      expirationDate: data ? data.expirationDate : '',
      dueDate: data ? data.dueDate : '',
      paymentTermsId: data ? data.paymentTermsId : '',
      paymentMethodId: data ? data.paymentMethodId : '',
      description: data ? data.description : '',
      grossSale: data ? data.grossSale : '',
      discountRate: data ? data.discountRate : '',
      discountAmount: data ? data.discountAmount : '',
      vat: data ? data.vat : '',
      netSale: data ? data.netSale : '',
      contractId: data ? data.contractId : '',
      ameStatus: data ? data.ameStatus : '',
      personId: data ? data.personId : '',
      paymentTerms: data ? data.paymentTerms : '',
      deliveryTerm: data ? data.deliveryTerm : '',
      inventoryId: data ? data.inventoryId : '',
      waybill: data ? data.waybill : '',
      phone: data ? data.phone : '',
      currencyCode: data ? data.currencyCode : '',
      currencyConversionRate: data ? data.currencyConversionRate : '',
      batchId: data ? data.batchId : '',
      transactionsLinesList:
        data?.transactionsLinesList?.map((item) => ({
          id: item.id,
          // transactionId: item.transactionId,
          itemId: item.itemId,
          lineNumber: item.lineNumber,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          // unitCost: item.unitCost,
          // codeCombinationId: item.codeCombinationId,
          // currencyCode: item.currencyCode,
          // currencyConversionRate: item.currencyConversionRate,
          // lotId: item.lotId,
          // headerId: item.headerId,
          // lineId: item.lineId,
          inventoryId: item.inventoryId,
        })) || [],
    },
    enableReinitialize: true,
    // validationSchema: AddInvoiceSalesSchema(t),
    onSubmit: (values) => {
      const paymentTermsId = CustomerList?.find((f) => f.id === values.peopleId)?.termsId;
      const shipToLocationId = CustomerList?.find((f) => f.id === values.peopleId)?.shipToLocationId;
      const billToLocationId = CustomerList?.find((f) => f.id === values.peopleId)?.billToLocationId;
      const grossSale = values.transactionsLinesList.reduce(
        (s, a) => Number(s) + Number(a.total),
        0
      );

      if (values.transactionsLinesList?.length === 0) {
        SwalAlert({ text: 'لا يوجد أصناف' });
        formik.setSubmitting(false);

        return;
      }

      dispatch(
        updateInvoiceTransactions({
          id: data ? data.id : '',
          organizationId: values.organizationId,
          peopleId: values.peopleId,
          recipientname: values.recipientname,
          billToLocationId: billToLocationId,
          shipToLocationId: shipToLocationId,
          referenceId: values.referenceId,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate,
          paymentTermsId: paymentTermsId,
          paymentMethodId: values.paymentMethodId,
          description: values.description,
          grossSale: grossSale,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netSale: values.netSale || 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: values.personId,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,

          transactionsLinesList: values.transactionsLinesList?.map((item, index) => ({
            // transactionId: null,
            id: item.id,
            itemId: item.itemId,
            lineNumber: ++index,
            // lineNumber: item.lineNumber,
            uom: item.uom,
            description: item.description,
            price: Number(item.price),
            qty: Number(item.qty),
            discountRate: Number(item.discountRate),
            discountAmount: Number(item.discountAmount),
            vatRate: Number(item.vatRate),
            total: Number(item.total),
            vatAmount: Number(item.vatAmount),
            // unitCost: Number(item.unitCost),
            // codeCombinationId: null,
            codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
            currencyCode: 'SR',
            currencyConversionRate: 0,
            lotId: null,
            headerId: null,
            lineId: null,
            inventoryId: values.inventoryId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            Navigate('/SalesTransactions/SalesInvoices', { replace: true });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'فواتيـر مبيعات',
      link: '/SalesTransactions/SalesInvoices',
    },
    {
      name: 'تفاصيـل الفاتورة',
      link: null,
    },
  ];
  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="تفاصيـل الفاتورة" />
        <div className="ms-auto">
          <div className="col-5 col-md-6 text-end">
            <DynamicPrint
              PrintTemplateTypeId="bec64af9-e08f-4498-8206-be1b14783906"
              ReferenceId={id || ''}
            />
          </div>
        </div>
      </div>

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group flex-nowrap mb-3">
                  <label className="form-label">{t('رقم الفاتورة')}</label>
                  <div className="input-group flex-nowrap">
                    <Form.Control
                      autoComplete="off"
                      className="form-control  text-center"
                      type="text" 
                       name="no"
                      onChange={formikhandleChange}
                      value={values.no || ''}
                      isInvalid={!!(touched.no && errors.no)}
                    />
                    <span className="input-group-text" id="addon-wrapping">
                      -INV
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('تاريخ الفاتورة')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="transactionDate"
                    onChange={formikhandleChange}
                    value={values.transactionDate || ''}
                    isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    // isValid={
                    //   formik.touched.transactionDate &&
                    //   !formik.errors.transactionDate
                    // }
                  />
                </div>
              </div>

              <div className="col-lg-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('تاريخ الإستحقاق')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="dueDate"
                    onChange={formikhandleChange}
                    value={values.dueDate || ''}
                    isInvalid={!!(touched.dueDate && errors.dueDate)}
                    // isValid={formik.touched.dueDate && !formik.errors.dueDate}
                  />
                </div>
              </div>

              <div className="col-lg-2">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('Currency')} </label>
                  <Form.Select
                    className="form-select"
                    name="currencyCode"
                    onChange={formikhandleChange}
                    value={values.currencyCode || ''}
                    isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    // isValid={
                    //   formik.touched.currencyCode && !formik.errors.currencyCode
                    // }
                  >
                    {Currency &&
                      Currency?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.currencyCode}>
                          {item.description}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('PaymentMethod')}</label>
                  <Form.Select
                    className="form-select"
                    name="paymentMethodId"
                    onChange={formik.handleChange}
                    value={formik.values.paymentMethodId ?? ''}
                    isInvalid={!!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)}>
                    <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {PaymentMethodList?.map((item: any, idx: number) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === 'ar' ? item.name : item.name2}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.paymentMethodId}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن')}</label>

                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    value={InventoryOptions?.find((f: any) => f.value === formik.values.inventoryId)}
                    onChange={(option: any) => {
                      formik.setFieldValue('inventoryId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('العميل')}</label>

                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading2}
                    isSearchable={true}
                    isClearable
                    options={OptionCustomers}
                    value={OptionCustomers?.find((f: any) => f.value === formik.values.peopleId)}
                    onChange={(option: any) => {
                      formik.setFieldValue('peopleId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع')}</label>

                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    value={BranchsOptions?.find(
                      (f: any) => f.value === formik.values.organizationId
                    )}
                    onChange={(option: any) => {
                      formik.setFieldValue('organizationId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('مندوب المبيعات')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    name="personId"
                    options={dataEmployees}
                    onChange={(option: any) => {
                      formik.setFieldValue('personId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('اسم العميل')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control text-center"
                    type="text" 
                     name="recipientname"
                    onChange={formikhandleChange}
                    value={values.recipientname || ''}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('تاريخ التوصيل')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="deliveryDate"
                    onChange={formikhandleChange}
                    value={values.deliveryDate || ''}
                    isInvalid={!!(touched.deliveryDate && errors.deliveryDate)}
                    // isValid={
                    //   formik.touched.deliveryDate &&
                    //   !formik.errors.deliveryDate
                    // }
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Note')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    // isValid={touched.description && !errors.description}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <CardFooter>
              <div className="row">
                <TransactionsLines2 formik={formik} />
              </div>
            </CardFooter>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-1"></i>
                )}

                {t('Save')}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate('/SalesTransactions/SalesInvoices', { replace: true })}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Update;
