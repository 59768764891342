import React from 'react';
import HrDashBoard from '../../HR/DashboardMain/HrDashBoard';
import SuDahboard from '../../SU/DashBoard/SuDahboard';
import FLEETDahboard from '../../FLEET/DashBoard/FLEETDahboard';
import Integration from '../../Integration/Index.tsx';
import useGetSysCode from '../../../hooks/useGetSysCode';
import AmeDahboard from '../../Ame/DashBoard/AmeDahboard';
import NtxDashboard from '../../NTX/Dashboard/NtxDashboard.tsx';
import SalesHome from '../../Sales/SalesHome/SalesHome.tsx';
import InvHome from '../../INV/Home/InvDashboard.tsx';
import PayrollDashboard from '../../HR/payroll/Main/PayrollDashboard';
import RaqeebDashboard from '../../HR/Raqeeb/Main/RaqeebDashboard';
import FinancialDashboard from '../../Finance/Main/FinancialDashboard ';
import APDashboard from '../../Finance/Ap/Main/ApDashboard ';
import ReportDashboard from '../../NTX/Reports/Report/Index/Index';
import NotificationDashboard from '../../NTX/Notifications/Index/Index';
import PosDashboard from '../../POS/PosCard/PosCard';

function SystemDashboard() {
  const sysCode = useGetSysCode();

  let Component = null;

  switch (sysCode.code) {
    case 'HR':
      Component = <HrDashBoard />;
      break;
    case 'SU':
      Component = <SuDahboard />;
      break;
    case 'FLEET':
      Component = <FLEETDahboard />;
      break;
    case 'NTX':
      Component = <NtxDashboard />;
      break;
    case 'AME':
      Component = <AmeDahboard />;
      break;
    case 'SAL':
      Component = <SalesHome />;
      break;
    case 'INV':
      Component = <InvHome />;
      break;
    case 'INT':
      Component = <Integration />;
      break;
    case 'PAYROLL':
      Component = <PayrollDashboard />;
      break;
    case 'RAQEEB':
      Component = <RaqeebDashboard />;
      break;
    case 'FIN':
      Component = <FinancialDashboard />;
      break;
    case 'AP':
      Component = <APDashboard />;
      break;
    case 'REPORT':
      Component = <ReportDashboard />;
      break;
    case 'NOTIFICATION':
      Component = <NotificationDashboard />;
      break;
    case 'POS':
      Component = <PosDashboard />;
      break;
    default:
      <div> null </div>;
  }

  return <>{Component}</>;
  // return <>{<UserDashboard/> }</>;
}

export default SystemDashboard;
