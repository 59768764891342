import { Card, CardBody, CardExpandToggler } from '../../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import BarChart from './BarChart.js';

// بيانات الموظفين الافتراضية مع الرواتب والإدارات
const Employees = [
  { id: 2, name: 'أحمد', department: 'التسويق', salary: 4500 },
  { id: 3, name: 'سارة', department: 'المبيعات', salary: 5200 },
  { id: 5, name: 'علي', department: 'الموارد البشرية', salary: 4600 },
  { id: 8, name: 'يوسف', department: 'المالية', salary: 6200 },
];

const SalaryByOrg = () => {
  const { t } = useTranslation();

  // حساب إجمالي الرواتب لكل إدارة
  function calculateTotalSalaryByDepartment(employees) {
    const departmentSalary = {};

    employees?.forEach((employee) => {
      const department = employee.department;
      if (departmentSalary[department]) {
        departmentSalary[department].totalSalary += employee.salary;
      } else {
        departmentSalary[department] = {
          name: department,
          count: employee.salary,
        };
      }
    });

    // تحويل النتيجة إلى مصفوفة من الكائنات
    const result = Object.values(departmentSalary);

    return result;
  }

  const [data, setData] = useState([]);

  useEffect(() => {
    const generatedData = calculateTotalSalaryByDepartment(Employees);
    setData(generatedData);
  }, []);

  return (
    <div>
      <Card className="mb-3" style={{ height: '100%' }}>
        <CardBody>
          <div className="d-flex fw-bold small mb-3">
            <span className="flex-grow-1">إجمالي الرواتب لكل إدارة</span>
            {/* افترض وجود مكون لتوسيع البطاقة */}
            <CardExpandToggler />
          </div>
          <div className="ratio ratio-21x9 mb-3">
            {data && data.length > 0 ? <BarChart data={data} /> : <p>لا توجد بيانات لعرضها.</p>}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default SalaryByOrg;
