import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { addAttribute } from '../../../../store/INV/Attributes/AttributesSlice';
import AttributeValuesList from './AttributeValuesList';

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      attType: '',
      name: '',
      name2: '',
      description: '',
      attributeValuesList: [
        {
          attributeId: '',
          name: '',
          name2: '',
          extraPrice: 0,
          color: '#348fe2',
          description: '',
        },
      ],
    },

    // validationSchema: formOrganization(t),
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(
        addAttribute({
          attType: values.attType,
          name: values.name,
          name2: values.name2,
          description: values.description,
          attributeValuesList: values.attributeValuesList.map((item) => ({
            attributeId: null,
            name: item.name,
            name2: item.name2,
            extraPrice: Number(item.extraPrice) ,
            color: item.color,
            description: item.description,
          })),
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
      formik.setSubmitting(false);
    },
  });

  // console.log(formik.values);

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text"
                autoComplete="off"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text"
                autoComplete="off"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-lg-2">
            <div className="form-group mb-3">
              <label className="form-label"> {t('Type')} </label>
              <Form.Select
                className="form-select"
                name="attType"
                onChange={formik.handleChange}
                value={formik.values.attType}
                isInvalid={!!(formik.touched.attType && formik.errors.attType)}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                <option value="Multi_Select">
                  {i18n.language === 'ar' ? 'اختيار متعدد' : 'Multi Select'}
                </option>
                <option value="Select">{i18n.language === 'ar' ? 'اختيار فردي' : 'Select'}</option>
                <option value="Color">{i18n.language === 'ar' ? 'لون' : 'Color'}</option>
              </Form.Select>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('الوصـف')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text"
                autoComplete="off"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                isInvalid={!!(formik.touched.description && formik.errors.description)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </div>
          </div>

          <AttributeValuesList formik={formik} />
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Add;
