import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import {
  getTransactionsById,
  cleanUpTransactionsByIdById,
} from '../../../store/purchases/PurchaseBill/purchaseBillSlice';

interface Props {
  id: string | undefined;
}

const useGetPurchaseTransactionsById = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
     if (id) {
      dispatch(getTransactionsById(id));
    }

    return () => {
      dispatch(cleanUpTransactionsByIdById());
    };
    
  }, [dispatch, id]);

  const { TransactionsById, loading, error } = useAppSelector((state) => state.PurchaseBill);
  return { TransactionsById, loading, error };
};

export default useGetPurchaseTransactionsById;
