import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const notificationsMenu = [
  {
    System: 'NOTIFICATION',
    isActive: false,
    menu: [

      { is_header: true, title: 'إدارة الإشعارات', isActive: true }, // عنوان إدارة الإشعارات

      {
        path: 'SystemDashboard',
        icon: 'fas fa-home', // أيقونة الصفحة الرئيسية
        title: 'HomePage',
        isActive: true,
      },

      {
        path: '/NTF/Notifications',
        icon: 'fas fa-bell', // أيقونة الإشعارات
        title: 'إدارة الإشعارات',
        isActive: true,
      },
      
      // { is_header: true, title: 'إدارة الإشعارات', isActive: true },
      // {
      //   path: 'dashboard',
      //   icon: 'fas fa-home',
      //   title: 'HomePage',
      // },

      // {
      //   path: '/NTF',
      //   icon: 'bi bi-bell',
      //   title: ' الإشعارات',
      //   children: [
      //     {
      //       path: 'NTF/Notifications',
      //       title: 'إدارة الإشعارات',
      //       isActive: true,
      //     },
      //   ],
      // },
    ],
  },
];

export default notificationsMenu;
