import { store } from '../../store';

export const HasPermission = (permission: string) => {
  const Permission = store.getState()?.userLogin?.AuthUser?.permission;

  if (!Permission) {
    return false; // إذا لم تكن موجودة، أعد false
  }

  const permissionsArray = Permission?.split(',');
  return permissionsArray?.includes(permission);
};
