import { Fragment, useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../../components/card/card';
import ModalComponent from '../../../../../components/Modal/ModalComponent';

import AddBranchesCustomer from './AddBrancheSuppliers';
import PeoplesContacts from '../../../../NTX/Peoples/PeoplesContacts/PeoplesContacts';
import useGetSuppliersList from '../../../../../hooks/NTX/Peoples/useGetSuppliersList';
import NtxTable from '../../../../../components/DataTable/NtxTable';

const Index = () => {
  const [peopleData, setPeopleData] = useState<any>('');

  const [Show, setShow] = useState(false);

  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { SuppliersList } = useGetSuppliersList();

  const columns = [
    { title: t('SupplierName'), key: 'name' },
    { title: t('SupplierNumber'), key: 'num' },
    { title: t('TradingName'), key: 'name2' },
    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData =
    SuppliersList?.filter((f) => f.parentId === id)?.map((item) => {
      let rowClass;

      return {
        name: i18n.language === 'en' ? item.name : item.name2,
        num: '000',
        name2: item.tradingName,

        action: (
          <div className="btn-group">
            <Link
              to={``}
              data-bs-toggle="modal"
              data-bs-target="#CustomersContactsTest"
              className="btn btn-outline-theme"
              onClick={() => setPeopleData(item)}>
              <i className="far fa-lg me-2  bi bi-telephone"></i>
              {t('ContactInformation')}
            </Link>

            <Link
              to={`/PurchasesSettings/SUPPLIERS/${item.id}/UpdateSupplier`}
              className="btn btn-outline-theme"
              state={{
                name: i18n.language === 'en' ? item.name : item.name2,
              }}>
              <i className="far fa-lg fa-fw  me-2 fa-edit"></i>
              {t('Button.Edit')}
            </Link>
          </div>
        ),
      };
    }) || [];

  //_______________________________________

  const location = useLocation();
  const { name } = location?.state || {};

  const titleModal = () => {
    return (
      <div className="">
        {t('ContactInformationForSupplier')} :{'  '}
        <span className="text-theme">
          {`(${i18n.language === 'en' ? peopleData?.name : peopleData?.name2})`}
        </span>
      </div>
    );
  };
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Suppliers'),
      link: '',
    },
    {
      name: t('Branches'),
      link: null,
    },
  ];
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('SupplierBranches')} subtitle={name} />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddCustomers"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
             {t('AddBranchForSupplier')} :  {name}
          </Link>
          <Link
            to=""
            className="btn me-1 btn-outline-secondary"
            onClick={() => navigate(-1)}
            replace>
            {t('Back')}
          </Link>
        </div>
      </div>

      <Card>
        <CardBody>
          <NtxTable
            data={tableData || []}
            columns={columns || []}
            dataPerPage={10}
            theadClass=""
            tableClass="table text-nowrap table-hover "
            enableSearch={true}
            showRecordsSelector
          />
        </CardBody>
      </Card>

      <ModalComponent title={` إضافة فرع للمورد ${name}`} id="modalAddCustomers">
        {Show && <AddBranchesCustomer />}
      </ModalComponent>

      <ModalComponent title={titleModal()} id="CustomersContactsTest">
        {peopleData?.id && <PeoplesContacts peopleId={peopleData.id} />}
      </ModalComponent>
    </Fragment>
  );
};

export default Index;
