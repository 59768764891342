import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTransactionTypesReceiveList } from '../../../store/INV/Transactions/InvReceive/invReceiveSlice';
import { useTranslation } from 'react-i18next';

const useGetTransactionTypesReceiveList = () => {
  const dispatch = useAppDispatch();
  const {i18n ,t} = useTranslation();

  useEffect(() => {
    if (TransactionTypes?.length === 0) {
      dispatch(getTransactionTypesReceiveList());
    }
  }, []);
  const {
    TransactionTypesReceiveList: TransactionTypes,
    loading,
    error,
  } = useAppSelector((state) => state.InvReceive);

  const OptionsTransactionTypes = TransactionTypes?.map(item => ({
    value : item.code,
    label : i18n.language === 'en'? item.name : item.name2,
    transTypeId : item.id  ,
    isDisabled: ['MOR' ].includes(item.code),
  }))

  return { TransactionTypes, OptionsTransactionTypes,  loading, error };
};

export default useGetTransactionTypesReceiveList;
