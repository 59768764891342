import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { AddPurchaseOrderSchema } from '../../../ValidationForm/validationSchema';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { addPurchaseOrder } from '../../../../store/purchases/PurchaseOrder/purchaseOrderSlice';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import POLines from './POLines';
import useGetPurchaseTransactionsById from '../../../../hooks/PUR/PurchaseTransactions/useGetPurchaseTransactionsById';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetInventoryWithPermission from '../../../../hooks/NTX/Organization/useGetInventoryWithPermission';

const Add = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading4 } = useLookupCurrency();
  // const { dataEmployees, loading } = useGetPersons();
  const { PaymentMethodList } = useGetPaymentMethod();
  const { BranchOrgOptions: BranchsOptions } = useGetBranchOrgWithPermission();
  const { InventoryOptions, loading: loading3 } = useGetInventoryWithPermission();

  const { treeSuppliersData, SuppliersList, loading: loading2, error } = useGetSuppliersList();
  const { TransactionsById: data } = useGetPurchaseTransactionsById({ id: id });

  //#region Formik
  const formik = useFormik({
    // initialValues: {
    //   isAme: false,
    //   categoryId: null,
    //   organizationId: data?.organizationId ?? '',
    //   peopleId: data?.peopleId ?? '',
    //   recipientname: data?.recipientname ?? '',
    //   billToLocationId: data?.billToLocationId ?? '', // معرف الموقع الذي سيتم إرسال الفاتورة إليه.
    //   shipToLocationId: data?.shipToLocationId ?? '', //  معرف الموقع الذي سيتم شحن المواد إليه.
    //   referenceId: data?.id ?? '', // معرف مرجعي للربط بين أوامر الشراء المختلفة.
    //   transactionDate: new Date().toISOString().split('T')[0], //    تاريخ تقديم طلب الشراء.
    //   deliveryDate: data?.deliveryDate ?? '', // التاريخ المتوقع لتسليم المواد.
    //   expirationDate: data?.expirationDate ?? '', // : تاريخ انتهاء صلاحية أمر الشراء.
    //   dueDate: data?.dueDate ?? '',
    //   paymentTermsId: data?.paymentTermsId ?? '', //معرف شروط الدفع.
    //   paymentMethodId: data?.paymentMethodId ?? '',
    //   description: data?.description ?? '', // وصف عام.
    //   grossAmount: data?.grossAmount ?? '', //  إجمالي مبلغ أمر الشراء.
    //   discountRate: data?.discountRate ?? '',
    //   discountAmount: data?.discountAmount ?? '',
    //   vat: data?.vat ?? '',
    //   netAmount: data?.netAmount ?? '',
    //   contractId: data?.contractId ?? '',
    //   ameStatus: data?.ameStatus ?? '',
    //   personId: data?.personId ?? '',
    //   paymentTerms: data?.paymentTerms ?? '',
    //   deliveryTerm: data?.deliveryTerm ?? '',
    //   inventoryId: data?.inventoryId ?? '',
    //   waybill: data?.waybill ?? '',
    //   phone: data?.phone ?? '',
    //   currencyCode: data?.currencyCode ?? '',
    //   currencyConversionRate: data?.currencyConversionRate ?? '',
    //   batchId: data?.batchId ?? '',
    //   transactionsLinesList:
    //     data?.transactionsLinesList?.map((item) => ({
    //       id: item.id,
    //       itemId: item.itemId,
    //       lineNumber: item.lineNumber,
    //       uom: item.uom,
    //       description: item.description,
    //       price: item.price,
    //       qty: item.qty,
    //       discountRate: item.discountRate,
    //       discountAmount: item.discountAmount,
    //       vatRate: item.vatRate,
    //       total: item.total,
    //       vatAmount: item.vatAmount,
    //       // unitCost: item.unitCost,
    //       // codeCombinationId: item.codeCombinationId,
    //       // currencyCode: item.currencyCode,
    //       // currencyConversionRate: item.currencyConversionRate,
    //       // lotId: item.lotId,
    //       // headerId: item.headerId,
    //       // lineId: item.lineId,
    //       inventoryId: item.inventoryId,
    //     })) || [],

    //   // transactionsLinesList: [
    //   //   {
    //   //     description: '',
    //   //     discountAmount: 0,
    //   //     discountRate: 0,
    //   //     itemId: '',
    //   //     lineNumber: 0,
    //   //     price: 0,
    //   //     qty: 1,
    //   //     total: 0,
    //   //     uom: '',
    //   //     vatAmount: 0,
    //   //     vatRate: 0,
    //   //   },
    //   // ] as TransactionsLinesList[],
    // },
    initialValues: {
      isAme: false,
      categoryId: null,

      organizationId: data?.organizationId ?? '',
      peopleId: data?.peopleId ?? '',
      recipientname: data?.recipientname ?? '',
      billToLocationId: data?.billToLocationId ?? '',
      shipToLocationId: data?.shipToLocationId ?? '',
      referenceId: data?.id ?? '',
      transactionDate: new Date().toISOString().split('T')[0],
      deliveryDate: data?.deliveryDate ?? '',
      expirationDate: data?.expirationDate ?? '',
      dueDate: data?.dueDate ?? '',
      paymentTermsId: data?.paymentTermsId ?? '',
      paymentMethodId: data?.paymentMethodId ?? '',
      description: data?.description ?? '',
      grossAmount: data?.grossAmount ?? '',
      discountRate: data?.discountRate ?? '',
      discountAmount: data?.discountAmount ?? '',
      vat: data?.vat ?? '',
      netAmount: data?.netAmount ?? '',
      contractId: data?.contractId ?? '',
      ameStatus: data?.ameStatus ?? '',
      personId: data?.personId ?? '',
      paymentTerms: data?.paymentTerms ?? '',
      deliveryTerm: data?.deliveryTerm ?? '',
      inventoryId: data?.inventoryId ?? '',
      waybill: data?.waybill ?? '',
      phone: data?.phone ?? '',
      currencyCode: data?.currencyCode ?? '',
      currencyConversionRate: data?.currencyConversionRate ?? '',
      batchId: data?.batchId ?? '',
      transactionsLinesList:
        data?.transactionsLinesList?.map((item) => ({
          id: item.id,
          itemId: item.itemId,
          lineNumber: item.lineNumber,
          uom: item.uom,
          description: item.description,
          price: Number(item.price),
          qty: Number(item.qty),
          discountRate: Number(item.discountRate),
          discountAmount: Number(item.discountAmount),
          vatRate: Number(item.vatRate),
          total: Number(item.total),
          vatAmount: Number(item.vatAmount),
          inventoryId: item.inventoryId,
          //
          type: item.itemId === null ? 'Non_Inv' : 'Inv',
        })) || [],
    },

    enableReinitialize: true,
    validationSchema: AddPurchaseOrderSchema(t),
    onSubmit: (values) => {
      // const { transactionsLinesList, ...restValues } = values;

      if (values.transactionsLinesList?.length === 0) {
        SwalAlert({ text: 'أدخل أصناف' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addPurchaseOrder({
          isAme: false,
          categoryId: null,

          organizationId: values.organizationId,
          peopleId: values.peopleId || null,
          recipientname: values.recipientname,
          billToLocationId: billToLocationId,
          shipToLocationId: shipToLocationId,
          referenceId: values.referenceId || null,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate || null,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate || null,
          paymentTermsId: paymentTermsId,
          paymentMethodId: null,
          description: values.description,
          grossAmount: 0,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netAmount: 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: values.personId || null,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,
          transactionsLinesList: formik.values.transactionsLinesList?.map((item) => ({
            itemId: item.itemId || null,
            lineNumber: item.lineNumber,
            uom: item.uom,
            description: item.description,
            price: Number(item.price),
            qty: Number(item.qty),
            discountRate: Number(item.discountRate),
            discountAmount: Number(item.discountAmount),
            vatRate: Number(item.vatRate),
            total: Number(item.total),
            vatAmount: Number(item.vatAmount),
            // unitCost: Number(item.unitCost),
            codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
            // currencyCode: 'SR',
            // currencyConversionRate: 0,
            // lotId: null,
            // headerId: null,
            // lineId: null,
            inventoryId: values.inventoryId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const paymentTermsId = SuppliersList?.find((f) => f.id === values.peopleId)?.termsId;
  const shipToLocationId = SuppliersList?.find((f) => f.id === values.peopleId)?.shipToLocationId;
  const billToLocationId = SuppliersList?.find((f) => f.id === values.peopleId)?.billToLocationId;

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-2">
            <div className="form-group mb-3">
              <label className="form-label">{t('تاريخ أمر الشراء')}</label>
              <Form.Control
                className="form-control  text-center"
                type="date"
                name="transactionDate"
                onChange={formikhandleChange}
                value={values.transactionDate || ''}
                // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
              />

              <div className="text-danger">{formik.errors.transactionDate}</div>
            </div>
          </div>

          <div className="col-lg-2">
            <div className="form-group mb-3">
              <label className="form-label">{t('تاريخ التسليم')}</label>
              <Form.Control
                className="form-control  text-center"
                type="date"
                name="deliveryDate"
                onChange={formikhandleChange}
                value={values.deliveryDate || ''}
                // isInvalid={!!(touched.deliveryDate && errors.deliveryDate)}
              />
              <div className="text-danger">{formik.errors.deliveryDate}</div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('الفرع')}</label>
              <Select
                classNamePrefix="react-select"
                isLoading={loading3}
                isSearchable={true}
                isClearable
                options={BranchsOptions}
                onChange={(option: any) => {
                  formik.setFieldValue('organizationId', option === null ? null : option.value);
                }}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
              <div className="text-danger">{formik.errors.organizationId}</div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('المخزن')}</label>

              <Select
                classNamePrefix="react-select"
                isLoading={loading3}
                isSearchable={true}
                isClearable
                options={InventoryOptions}
                onChange={(option: any) => {
                  formik.setFieldValue('inventoryId', option === null ? null : option.value);
                }}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
              <div className="text-danger">{formik.errors.inventoryId}</div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('المورد')}</label>
              <TreeSelect
                showSearch
                // value={value}
                className="w-100"
                treeLine
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                allowClear
                // treeDefaultExpandAll
                value={formik.values.peopleId}
                onChange={(option: any) => {
                  formik.setFieldValue('peopleId', option === null ? null : option);
                }}
                treeData={treeSuppliersData}
                treeNodeFilterProp="title"
              />

              <div className="text-danger">{formik.errors.peopleId}</div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label"> {t('Currency')} </label>
              <Form.Select
                className="form-select"
                name="currencyCode"
                onChange={formikhandleChange}
                value={values.currencyCode || ''}
                // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
              >
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                {Currency &&
                  Currency?.map((item: any, idx: number) => (
                    <option key={++idx} value={item.currencyCode}>
                      {item.description}
                    </option>
                  ))}
              </Form.Select>
              <div className="text-danger">{formik.errors.currencyCode}</div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('PaymentMethod')}</label>
              <Form.Select
                className="form-select"
                name="paymentMethodId"
                onChange={formik.handleChange}
                value={formik.values.paymentMethodId}
                isInvalid={!!formik.errors.paymentMethodId}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {PaymentMethodList?.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {/* {formik.errors.TITLE} */}
              </Form.Control.Feedback>
            </div>
          </div>

          {/* <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مدة التوريد')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="deliveryTerm"
                      onChange={formik.handleChange}
                      value={values.deliveryTerm}
                      isInvalid={!!(touched.deliveryTerm && errors.deliveryTerm)}
                      // isValid={touched.deliveryTerm && !errors.deliveryTerm}
                    />
                  </div>
                </div> */}

          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('الشروط')}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={2}
                name="paymentTerms"
                onChange={formik.handleChange}
                value={values.paymentTerms}
                isInvalid={!!(touched.paymentTerms && errors.paymentTerms)}
                // isValid={touched.paymentTerms && !errors.paymentTerms}
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={2}
                name="description"
                onChange={formik.handleChange}
                value={values.description}
                isInvalid={!!(touched.description && errors.description)}
                // isValid={touched.description && !errors.description}
              />
            </div>
          </div>
        </div>

        <CardFooter className="px-1">
          {/* <PurchaseLines formik={formik} /> */}
          <POLines formik={formik} />
          {formik.errors.transactionsLinesList?.length && (
            <div className="text-danger"> * مطلوب </div>
          )}
        </CardFooter>

        <div className="text-center mt-2">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn btn-lg me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn btn-lg me-1 btn-default mb-1"
            onClick={() => navigate(-1)}>
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Add;
