/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlPeriodsList } from '../../../store/Finance/Periods/PeriodsSlice';
import { useTranslation } from 'react-i18next';

const useGetGlPeriodsList = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (PeriodsList?.length === 0) {
      dispatch(GetGlPeriodsList());
    }
  }, [dispatch]);
  const { PeriodsList, loading, error } = useAppSelector((state) => state.Periods);

  const GlPeriods = PeriodsList.filter((f) => f.periodYear !== null).map((m) => ({
    id: m.id,
    name: `من ${m.startDate} - إلى   ${m.endDate}`,
    label: `من ${m.startDate} - إلى   ${m.endDate}`,
  }));

  const OptionsGlPeriods =
    PeriodsList &&
    PeriodsList.filter((f) => f.periodYear !== null)?.map((item) => ({
      value: item.id,
      label: `من ${item.startDate} - إلى   ${item.endDate}`,
      name: i18n.language === 'ar' ? item.name2 : item.name,
      //
    }));

  return { PeriodsList, loading, error, GlPeriods, OptionsGlPeriods };
};

export default useGetGlPeriodsList;
