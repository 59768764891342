import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
import { TransactionsLinesList } from '../../../../store/Sales/SalesInvoices/SalesInvoicesModels';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import useGetItemConversionList from '../../../../hooks/INV/ItemConversion/useGetItemConversionList';

interface LinesProps {
  formik: FormikProps<any>;
}

const PurchaseQuotationLines: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;
  //________________________________________________________________
  const { UnitsOfMeasureList: UomList } = useGetUnitsOfMeasureList();
  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsList();
  const { ItemConversionList, loading: loading2 } = useGetItemConversionList();
  // const [Lines, setLines] = useState<any[]>(formik?.values.transactionsLinesList || []);

  // useEffect(() => {
  //   setLines(formik?.values.transactionsLinesList);
  // }, [formik?.values.transactionsLinesList]);

  const GetUom = (line: any) => {
    const uomIdForItem = ItemsOptions?.find((f) => f.value === line?.itemId)?.uom;

    // تصفية العناصر من قائمة التحويلات بناءً على itemId
    const filteredList = ItemConversionList?.filter((f) => f.itemId === line.itemId);
    // العثور على الوحدة المقابلة من قائمة UomList بناءً على uomId
    const filteredList2 = UomList?.find((f) => f.id === uomIdForItem);

    // دمج القائمتين في قائمة ثالثة
    const combinedList = [...filteredList];

    // إذا كانت filteredList2 موجودة وغير موجودة في filteredList، يتم إضافتها إلى القائمة
    if (filteredList2 && !filteredList.some((f) => f.uom === filteredList2.id)) {
      combinedList.push({
        id: filteredList2.id,
        itemId: line.itemId,
        uom: filteredList2.id,
        conversionValue: 1, // أو أي قيمة افتراضية مناسبة للتحويل
        barcode: '',
        uomName: filteredList2.name,
        uomName2: filteredList2.name2,
      });
    }

    return combinedList;
  };

  const handleLineChange = (index: number, field: string, value: any) => {
    if (field === 'itemId') {
      // const updatedLines = [...Lines];
      const updatedLines = [...values.transactionsLinesList];

      let price = ItemsOptions?.find((f) => f.value === value)?.salesPrice;
      let total = Number(updatedLines[index].qty) * Number(price);
      updatedLines[index] = {
        ...updatedLines[index],
        itemId: value,
        price: Number(price),
        total,
      };

      // setLines(updatedLines);
      formik?.setFieldValue('transactionsLinesList', updatedLines);

      return;
    }

    const updatedLines = [...values.transactionsLinesList];
    updatedLines[index] = {
      ...updatedLines[index],
      [field]: value,
    };

    // Recalculate total and VAT if needed
    if (field === 'qty' || field === 'price' || field === 'vatRate') {
      const total = Number(updatedLines[index].qty) * Number(updatedLines[index].price);
      const vatAmount = (total * Number(updatedLines[index].vatRate)) / 100;
      updatedLines[index] = {
        ...updatedLines[index],
        total,
        vatAmount,
      };
    }

    // setLines(updatedLines);
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine: TransactionsLinesList = {
      itemId: null,
      lineNumber: values.transactionsLinesList?.length + 1,
      uom: '',
      price: 0,
      qty: 1,
      discountRate: 0,
      discountAmount: 0,
      vatRate: 15,
      total: 0,
      vatAmount: 0,
      description: '',
      id: null, //
      inventoryId: '', //
    };
    // setLines([...Lines, newLine]);
    formik.setFieldValue('transactionsLinesList', [
      ...formik.values.transactionsLinesList,
      newLine,
    ]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.transactionsLinesList?.filter((_: any, i: number) => i !== index);
    // setLines(updatedLines);
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.transactionsLinesList.reduce((sum: any, line: any) => sum + line.total, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  return (
    <>
      <Loading loading={loading || loading2} error={error} Type="Dots">
        <>
          <div className="row">
            <div className="col-12 col-md-4 mb-2">
              <div className="rounded-3 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{t('Total')}:</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
                   {t('إضافة صنف')}
                </button>
              </div>
            </div> */}
          </div>

          {values.transactionsLinesList?.length > 0 && (
            <div className={`table-responsive rounded-2 text-center  `}>
              <PerfectScrollbar
                className="text-theme"
                option={{ suppressScrollX: false, suppressScrollY: true }}>
                <div style={{ width: '150%' }}>
                  <table className="table table-borderless table-hover mb-2">
                    <thead className="bg-theme bg-opacity-30 ">
                      <tr>
                        <th className="align-middle  small sticky-col" style={{ width: '1%' }}>
                          #
                        </th>

                        <th className="align-middle  small" style={{ width: '15%' }}>
                          {t('الوصف')}
                        </th>
                        <th className="align-middle  small" style={{ width: '4%' }}>
                          {t('الوحدة')}
                        </th>
                        <th className="align-middle  small" style={{ width: '2%' }}>
                          {t('الكمية')}
                        </th>
                        <th className="align-middle  small" style={{ width: '3%' }}>
                          {t('السعر')}
                        </th>
                        <th className="align-middle  small" style={{ width: '2%' }}>
                          {t('نسبة الخصم')}
                        </th>
                        <th className="align-middle  small" style={{ width: '2%' }}>
                          {t('قيمة الخصم')}
                        </th>
                        <th className="align-middle  small" style={{ width: '2%' }}>
                          VAT
                          <br />%
                        </th>
                        <th className="align-middle  small" style={{ width: '2%' }}>
                          {t('VAT')}
                        </th>
                        <th className="align-middle small" style={{ width: '4%' }}>
                          {t('Total')}
                        </th>
                        {/* <th className="align-middle small" style={{ width: '18%' }}>
                          {t('الوصف')}
                        </th> */}
                        <th className="align-middle text-end" style={{ width: '1%' }}></th>
                      </tr>
                    </thead>

                    <tbody className="fw-bold">
                      {values.transactionsLinesList
                        ?.slice()
                        .reverse()
                        ?.map((line: any, index: any) => {
                          const Index = values.transactionsLinesList?.length - 1 - index;

                          return (
                            <tr key={index}>
                              <td className="align-middle bg-theme bg-opacity-30 small">
                                {values.transactionsLinesList?.length - index}
                              </td>

                              <td className="align-middle small">
                                <Form.Control
                                  className="form-control"
                                  as="textarea"
                                  rows={1}
                                  value={line.description}
                                  onChange={(e) => {
                                    handleLineChange(Index, 'description', e.target.value);
                                  }}
                                  style={{ overflow: 'hidden' }} // Optional: Hide scrollbars
                                />
                                {/* <Select
                                  classNamePrefix="react-select"
                                  className="w-100"
                                  isSearchable
                                  isClearable
                                  options={ItemsOptions?.filter(
                                    (f) =>
                                      !values.transactionsLinesList?.some(
                                        (line: any) => line.itemId === f.value
                                      ) &&
                                      (formik.values.categoryId === null ||
                                        f.categoryId?.toLowerCase() ===
                                          formik.values.categoryId?.toLowerCase())
                                  )}
                                  onChange={(option) =>
                                    handleLineChange(
                                      Index,
                                      'itemId',
                                      option === null ? null : option.value
                                    )
                                  }
                                  value={
                                    line.itemId === null
                                      ? null
                                      : ItemsOptions?.find((option) => option.value === line.itemId)
                                  }
                                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                  }}
                                /> */}
                              </td>

                              <td className="align-middle small">
                                <select
                                  className="form-select text-center"
                                  value={line.uom || ''}
                                  onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
                                  <option value="">
                                    {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                  </option>

                                  {GetUom(line)?.map((item, idx) => (
                                    <option key={++idx} value={item.uom}>
                                      {i18n.language === 'en' ? item.uomName : item.uomName2}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td className="align-middle small">
                                <input
                                  type="text" 
autoComplete='off'
                                  className="form-control text-center px-0"
                                  value={line.qty}
                                  onChange={(e) =>
                                    handleLineChange(Index, 'qty', Number(e.target.value))
                                  }
                                  onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                  }}
                                />
                              </td>
                              <td className="align-middle small">
                                <input
                                  type="text" 
autoComplete='off'
                                  className="form-control text-center px-0"
                                  value={line.price}
                                  onChange={(e) =>
                                    handleLineChange(Index, 'price', Number(e.target.value))
                                  }
                                  onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                  }}
                                />
                              </td>
                              <td className="align-middle small">
                                <input
                                  type="text" 
autoComplete='off'
                                  className="form-control text-center px-0"
                                  value={line.discountRate}
                                  onChange={(e) =>
                                    handleLineChange(Index, 'discountRate', Number(e.target.value))
                                  }
                                  onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                  }}
                                />
                              </td>
                              <td className="align-middle small">{line.discountAmount}</td>
                              <td className="align-middle small">
                                <input
                                  type="text" 
autoComplete='off'
                                  className="form-control text-center px-0"
                                  value={line.vatRate}
                                  onChange={(e) =>
                                    handleLineChange(Index, 'vatRate', Number(e.target.value))
                                  }
                                  onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                  }}
                                />
                              </td>
                              <td className="align-middle small">{line.vatAmount}</td>
                              <td className="align-middle small">{line.total}</td>
                              {/* <td className="align-middle small">
                                <Form.Control
                                  className="form-control"
                                  as="textarea"
                                  rows={1}
                                  value={line.description}
                                  onChange={(e) => {
                                    handleLineChange(Index, 'description', e.target.value);
                                  }}
                                  style={{ overflow: 'hidden' }} // Optional: Hide scrollbars
                                />
                              </td> */}
                              <td className="align-middle small">
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() => handleDeleteLine(Index)}>
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </PerfectScrollbar>
            </div>
          )}
        </>
      </Loading>
    </>
  );
};

export default PurchaseQuotationLines;

//
