import { useEffect, useState } from 'react';

import useGetGlDailyRatesList from './useGetGlDailyRatesList';
import useGetGlLedgersList from '../useGetGlLedgersList';

type props = {
  LedgarId: string;
  currencyCode: string;
  date: string;
};
const useConversionRateByLedgard = ({ LedgarId, currencyCode, date }: props) => {
  const [conversionRate, setConversionRate] = useState<number>(1);
  const { DailyRatesList } = useGetGlDailyRatesList();
  const { LedgersList } = useGetGlLedgersList();

  useEffect(() => {
    const getConversionRate = () => {
      let rate = 1;

      // ابحث عن الدفتر بناءً على organizationId
      const ledger = LedgersList?.find((f) => f.id === LedgarId);

      if (!ledger) {
        return rate; // إذا لم يتم العثور على دفتر، العودة مع معدل 1
      }

      const currencyCodeFrom = ledger.currencyCode;
      const currencyCodeTo = currencyCode;
      const juDate = new Date(date);

      // إذا كانت المعلومات غير كاملة، العودة بمعدل 1
      if (!currencyCodeFrom || !currencyCodeTo || !juDate) {
        return rate;
      }

      // إذا كان نفس العملة، العودة بمعدل 1
      if (currencyCodeFrom === currencyCodeTo) {
        return rate;
      }

      // البحث عن معدل التحويل في القائمة
      const foundRate = DailyRatesList?.find((rate) => {
        const startDate = new Date(rate.conversionStartDate);
        const endDate = rate.conversionEndDate ? new Date(rate.conversionEndDate) : null;

        return (
          rate.fromCurrencyCode === currencyCodeFrom &&
          rate.toCurrencyCode === currencyCodeTo &&
          startDate <= juDate &&
          (!endDate || endDate >= juDate)
        );
      });

      // إذا تم العثور على معدل تحويل، يتم تعيينه
      if (foundRate) {
        rate = foundRate.conversionRate;
      }

      return rate;
    };

    const rate = getConversionRate();
    setConversionRate(rate); // تحديث معدل التحويل
  }, [LedgarId, currencyCode, date, LedgersList, DailyRatesList]);

  return conversionRate;
};

export default useConversionRateByLedgard;
