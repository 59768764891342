import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Add from './Add';
// import useLookupCurrency from '../../../hooks/Lookups/use-Lookup-Currency';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Card, CardBody } from '../../../components/card/card';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import ModalComponent from '../../../components/Modal/ModalComponent';
import MedModalComponent from '../../../components/Modal/MedModalComponent';
import { getGlBudgetsList } from '../../../store/Finance/GlBudget/GlBudgetSlice';
import NtxTable from '../../../components/DataTable/NtxTable';
import Update from './Update';

const Index = () => {
  // const [ID, setID] = useState<string>('');
  const navigate = useNavigate();
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGlBudgetsList({ LedgerId: '', PeriodId: '' }));
  }, []);
  const { GlBudgetList } = useAppSelector((s) => s.GlBudget);

  const filteredSearch = GlBudgetList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item?.name2?.toLowerCase().includes(searchLowerCase);
  });

  const columns = [
    { title: 'الأســم', key: 'name' },
    { title: 'الاستاذ العام', key: 'ledger' },
    { title: 'الفترة المحاسبية', key: 'period' },
    { title: 'الوصف', key: 'description' },
    { title: 'status', key: 'status' },
    { title: '', key: 'action', className: 'text-end', sortable: false },
  ];

  const tableData = filteredSearch?.map((item) => {
    let rowClass;

    return {
      name: i18n.language === 'en' ? item.name : item.name2,
      ledger: i18n.language === 'en' ? item.ledgerName : item.ledgerName2,
      period: i18n.language === 'en' ? item.periodName : item.periodName2,
      description: item.description,
      status: item.status,
      action: (
        <button
          type="button"
          className="btn btn-outline-theme"
          // data-bs-toggle="modal"
          // data-bs-target="#modalUpdate"
          // onClick={() => setID(item.id)}
          onClick={() => navigate(`${item.id}/UpdateBudget`)}>
          <i className="far fa-lg fa-fw fa-edit me-1"></i>
          {t('Budget Assignments')}
        </button>
      ),
    };
  });

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الميزانية',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="الميزانية" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAdd"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة
          </Link>
        </div>
      </div>

      <Card>
        <CardBody>
          {/* <div className="row mb-2">
            <div className="col-lg-6">
              <div className="input-group flex-nowrap">
                <span className="input-group-text fw-bold">{t('Search')}</span>
                <div className="input-group">
                  <div className="flex-fill position-relative">
                    <div className="input-group border-0 ">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: '10px' }}>
                        <i className="fa fa-search opacity-5 text-theme"></i>
                      </div>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control ps-35px"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <NtxTable
            data={tableData}
            columns={columns}
            dataPerPage={10}
            theadClass=""
            tableClass="table text-nowrap table-hover "
            enableSearch={true}
            showRecordsSelector
          />
        </CardBody>
      </Card>

      <MedModalComponent title="اضافة" id="modalAdd">
        <Add />
      </MedModalComponent>
      {/* <ModalComponent title="تعديل" id="modalUpdate">
        {ID && <Update id={ID} />}
      </ModalComponent> */}
    </div>
  );
};

export default Index;
