import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPurchaseRequestList } from '../../../store/purchases/PurchaseRequests/purchaseRequestsSlice';
import { useTranslation } from 'react-i18next';

const useGetPurchaseRequestList = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (PurchaseRequestsList?.length === 0) {
      dispatch(getPurchaseRequestList());
    }
  }, []);
  const { PurchaseRequestsList, loading, error } = useAppSelector((state) => state.PurchaseRequest);

  const PurchaseRequesOptions = PurchaseRequestsList?.map((item) => ({
    value: item.id,
    label: `${i18n.language === 'en' ? item.name : item.name2} - ${item.code}`,
  }));

  return { PurchaseRequestsList, PurchaseRequesOptions, loading, error };
};

export default useGetPurchaseRequestList;
