import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../../components/card/card';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetFormAme from '../../../../../hooks/Ame/useGetFormAme';
import Select from 'react-select';
import useLookupModule from '../../../../../hooks/Lookups/use-Lookup-Module';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import AddRequests from './AddRequests';

const AmeForm = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const { OptionsModule } = useLookupModule();
  const { DynamicFormAme: FormList, loading } = useGetFormAme();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(8);
  const [moduleId, setModuleId] = useState(null);
  const filteredSearch = FormList?.slice()?.filter((item) => {
    const matchesSource = moduleId ? item.moduleId === moduleId : true;
    return matchesSource;
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  // console.log(moduleId);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {};

  return (
    <>
    
        <Loading loading={loading}>
          {!selectedItem ? (
            <>
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">{t('التطبيـق')}</span>
                    <Select
                      classNamePrefix="react-select"
                      className="w-100"
                      isSearchable={true}
                      isClearable
                      options={OptionsModule}
                      onChange={(option) => {
                        setModuleId(option?.value);
                        setCurrentPage(1);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                {FormList &&
                  currentData?.map((item) => (
                    <div className="col-xl-3 col-md-6 " key={item.id}>
                      <div className="mailbox-detail-content">
                        <div className="mailbox-attachment1 w-100">
                          <Link
                            onClick={() => setSelectedItem(item)} // عند النقر على عنصر يتم استبدال المحتوى بالمكون المختار
                            to=""
                            style={{ cursor: 'pointer' }}>
                            <div className="document-file1 h-100px">
                              <i
                                className={
                                  item.icon
                                    ? item.icon.replace('fa-lg fa-fw me-2', '') + ' pt-2 '
                                    : 'bi bi-menu-up pt-2'
                                }></i>
                            </div>
                            <div className="document-name">
                              {i18n.language === 'ar' ? item.name2 : item.name}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <div>
              {selectedItem.id && (
                <AddRequests FormId={selectedItem.id} onBack={() => setSelectedItem(null)} />
              )}
            </div>
          )}

          {!selectedItem && (
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          )}
        </Loading>
   
    </>
  );
};

export default AmeForm;
