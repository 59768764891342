import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import Loader from '../../../../components/Shared/Loader/Loader';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import useGetGlJeCategoryList from '../../../../hooks/Finance/useGetGlJeCategoryList';
import useGetGlJeSourceList from '../../../../hooks/Finance/useGetGlJeSourceList';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
import useGetGlPeriodsList from '../../../../hooks/Finance/GlPeriods/useGetGlPeriodsList';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import { useNavigate, useParams } from 'react-router-dom';
import { LedgersModels } from '../../../../store/Finance/Ledgers/LedgersModels';
import { GlJeLinesList } from '../../../../store/Finance/Journals/JournalsModels';
import { AddGlJeHeaders } from '../../../../store/Finance/Journals/JournalsSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { AddGlJeHeaderSchema } from '../../../ValidationForm/validationSchema';
import JournalLines2 from '../JournalLines/JournalLines2';
import { Card, CardBody, CardFooter } from '../../../../components/card/card';
import useConversionRateByLedgard from '../../../../hooks/Finance/DailyRates/useConversionRateByLedgard';
import useCheckDateOnPeriod from '../../../../hooks/Finance/GlPeriods/useCheckDateOnPeriod';

const AddJournals = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [stateJu, setStateJu] = useState<string>('T');
  const dispatch = useAppDispatch();
  const { Currency } = useLookupCurrency();
  const { GlJeCategoryList } = useGetGlJeCategoryList();
  const { GlJeSourceList } = useGetGlJeSourceList();
  const { LedgersList, loading } = useGetGlLedgersList();
  const { PeriodsList, GlPeriods } = useGetGlPeriodsList();
  const { ledgerId } = useParams();
  const [ledgar, setLedgar] = useState<LedgersModels | undefined>(undefined);

  useEffect(() => {
    if (LedgersList && ledgerId) {
      const foundLedger = LedgersList?.find((ledger) => ledger.id === ledgerId);
      if (foundLedger) {
        setLedgar(foundLedger);
      }
    }
  }, [LedgersList]);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      glDate: new Date().toISOString().split('T')[0],
      ledgerId: ledgerId,
      jeCategory: '',
      jeSource: '',
      periodId: '',
      name: 'manual +  periodId + jeCategory + jeSource', // لا يظهر في الشاشة هو تحميع حقول مع بعض
      status: '', // temp | unpost

      currencyCode: ledgar ? ledgar?.currencyCode : 'SR',
      conversionFlag: false,
      currencyConversionRate: 1,
      conversionDate: '', // date يأتي تلقائي من جدول الrate
      ///
      reverseFlag: false,
      jeBatchId: null,
      parentJeHeaderId: null,
      postedDate: '',
      description: '',
      accountedDr: 0,
      accountedCr: 0,
      enteredDr: 0,
      enteredCr: 0,
      JeLinesList: [] as GlJeLinesList[],
    },
    enableReinitialize: true,
    validationSchema: AddGlJeHeaderSchema(t),
    onSubmit: (values) => {
      if (values.JeLinesList?.length === 0) {
        SwalAlert({ text: 'There is no Data' });
        formik.setSubmitting(false);
        return;
      }

      if (!isDateValid) {
        SwalAlert({ text: 'التاريـخ ليس ضمن الفترة المحاسبية' });
        formik.setSubmitting(false);
        return;
      }

      if (stateJu === 'U' && Number(totalEnteredDr) !== Number(totalEnteredCr)) {
        SwalAlert({ text: 'يجب ان يتساوى الدائن والمديـن' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        AddGlJeHeaders({
          glDate: values.glDate,
          ledgerId: values.ledgerId || '',
          jeCategory: values.jeCategory,
          jeSource: values.jeSource,
          periodId: values.periodId,
          name: getNameJournals(),
          status: stateJu,
          currencyCode: values.currencyCode,
          conversionFlag: values.currencyCode !== ledgar?.currencyCode,
          currencyConversionRate: conversionRate,
          // conversionDate: values.currencyCode !== ledgar?.currencyCode ? conversionDate : null,
          conversionDate: null,
          ///
          jeBatchId: values.jeBatchId,
          parentJeHeaderId: values.parentJeHeaderId,
          postedDate: new Date().toISOString().split('T')[0],
          description: values.description,
          accountedDr: Number(totalAccountedDr),
          accountedCr: Number(totalAccountedCr),
          enteredDr: Number(totalEnteredDr),
          enteredCr: Number(totalEnteredCr),
          glJeLinesList: values.JeLinesList?.map((item) => ({
            jeLineNum: Number(item.jeLineNum),
            enteredDr: Number(item.enteredDr) || 0,
            enteredCr: Number(item.enteredCr) || 0,
            accountDr: Number(item.accountDr) || 0,
            accountCr: Number(item.accountCr) || 0,
            description: item.description,
            accountId: item.accountId,
            companyId: item.companyId,
            branchId: item.branchId,
            costCenterId: item.costCenterId,
            // lineType: item.lineType,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const conversionRate = useConversionRateByLedgard({
    LedgarId: values.ledgerId || '',
    currencyCode: values.currencyCode,
    date: values.glDate,
  });

  const isDateValid = useCheckDateOnPeriod(values.glDate, values.periodId);

  //#region Calculate the total debit and credit amounts
  const totalEnteredDr = formik.values.JeLinesList.reduce(
    (total, line) => total + line.enteredDr,
    0
  );
  const totalEnteredCr = formik.values.JeLinesList.reduce(
    (total, line) => total + line.enteredCr,
    0
  );
  const totalAccountedDr = formik.values.JeLinesList.reduce(
    (total, line) => total + line.accountDr,
    0
  );
  const totalAccountedCr = formik.values.JeLinesList.reduce(
    (total, line) => total + line.accountCr,
    0
  );
  //#endregion

  const getNameJournals = () => {
    'manual +  periodId + jeCategory + jeSource';
    let periodName = PeriodsList?.find((f) => f.id === values.periodId)?.name;
    let CategoryName = GlJeCategoryList?.find((f) => f.id === values.jeCategory)?.name;
    let SourceName = GlJeSourceList?.find((f) => f.id === values.jeSource)?.name;

    return `Manual + ${periodName} + ${CategoryName} + ${SourceName} `.trim();
  };

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'القيود المالية',
      link: '/Finance/Journals',
    },
    {
      name: 'إضــافة قيـد',
      link: null,
    },
  ];
  //#endregion

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName="إضـافة قيد جديد"
          subtitle={ledgar?.name2}
        />
        <div className="ms-auto">
          {/* <Link to="/Finance/Journals" replace className="btn btn-default ">
            {t("Back")}
          </Link> */}
        </div>
      </div>

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('AccountingPeriod')}</label>
                  <Form.Select
                    className="form-select "
                    name="periodId"
                    onChange={formikhandleChange}
                    value={values.periodId || ''}
                    isInvalid={!!(touched.periodId && errors.periodId)}
                    isValid={formik.touched.periodId && !formik.errors.periodId}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {GlPeriods &&
                      GlPeriods?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.periodId}</Form.Control.Feedback>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Date')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="glDate"
                    onChange={formikhandleChange}
                    value={values.glDate || ''}
                    isInvalid={!!(touched.glDate && errors.glDate)}
                    isValid={formik.touched.glDate && !formik.errors.glDate}
                  />
                  <Form.Control.Feedback type="invalid">{errors.glDate}</Form.Control.Feedback>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Source')}</label>
                  <Form.Select
                    className="form-select"
                    name="jeSource"
                    onChange={formikhandleChange}
                    value={values.jeSource || ''}
                    isInvalid={!!(touched.jeSource && errors.jeSource)}
                    isValid={formik.touched.jeSource && !formik.errors.jeSource}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {GlJeSourceList &&
                      GlJeSourceList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.jeSource}</Form.Control.Feedback>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Category')}</label>
                  <Form.Select
                    className="form-select "
                    name="jeCategory"
                    onChange={formikhandleChange}
                    value={values.jeCategory || ''}
                    isInvalid={!!(touched.jeCategory && errors.jeCategory)}
                    isValid={formik.touched.jeCategory && !formik.errors.jeCategory}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {GlJeCategoryList &&
                      GlJeCategoryList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.jeCategory}</Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('Currency')} </label>
                  <Form.Select
                    disabled={values.JeLinesList?.length > 0}
                    className="form-select"
                    name="currencyCode"
                    onChange={formikhandleChange}
                    value={values.currencyCode || ''}
                    isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    isValid={formik.touched.currencyCode && !formik.errors.currencyCode}>
                    {Currency &&
                      Currency?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.currencyCode}>
                          {item.description}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('ExchangeRate')}</label>
                  <Form.Control
                    disabled
                    className="form-control text-center bg-inverse bg-opacity-15"
                    type="text"
                    autoComplete="off"
                    name="currencyConversionRate"
                    value={conversionRate || ''}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="form-group mb-3">
                  <label className="form-label">{t('البيــان')}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    isValid={touched.description && !errors.description}
                  />
                  <div></div>
                </div>
              </div>

              {/* <div className="col-lg-2 ">
                <div className="form-group mb-3">
                  <label className="form-label">{t("conversionDate")}</label>
                  <Form.Control
                    disabled
                    className="form-control text-center bg-inverse bg-opacity-15"
                    type="date"
                    name="conversionDate"
                    value={conversionDate || ""}
                  />
                </div>
              </div> */}
            </div>
          </Form>

          <CardFooter className=" p-1 mt-3">
            <JournalLines2 formik={formik} ledgar={ledgar} />

            <div className="text-center mt-4">
              <button
                disabled={formik.isSubmitting || values.JeLinesList?.length === 0}
                type="button"
                className="btn btn-lg me-1 btn-warning mb-1 rounded-2"
                onClick={() => {
                  setStateJu('T');
                  formik.submitForm();
                }}>
                <i className="fa fa-clock fa-fw me-1"></i>

                {t('TemporarySave')}
              </button>
              <button
                disabled={formik.isSubmitting || values.JeLinesList?.length === 0}
                type="button"
                className="btn btn-lg me-1 btn-theme mb-1 rounded-2"
                onClick={() => {
                  setStateJu('U');
                  formik.submitForm();
                }}>
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-1"></i>
                )}

                {t('Save')}
              </button>
              <button
                 type="button"
                className="btn btn-lg me-1 btn-default mb-1 rounded-2"
                onClick={() => navigate('/Finance/Journals', { replace: true })}>
                <i className="fa fa-times fa-fw me-1"></i>

                {t('Cancel')}
              </button>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddJournals;
