import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from '../../../components/card/card';
import Loading from '../../../components/Shared/Loader/Loading';
import { ItemCard } from './ItemCard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useGetInventoryWithPermission from '../../../hooks/NTX/Organization/useGetInventoryWithPermission';
import CountUp from 'react-countup';

const InvDashboard = () => {
  const [moduleId, setModuleId] = useState('0a6c6b89-1d52-4eaa-8d2c-010c74b1f6e0'); // الموارد البشرية افتراضي

  const { i18n } = useTranslation();

  const excludedIds = [
    '0a6c6b89-1d52-4eaa-8d2c-060c74b1f6e0',
    '0a6c6b89-1d52-4eaa-8d2c-000c74b1f6e0',
  ];

  const { data, loading } = useGetInventoryWithPermission();

  const InventoryList = [
    {
      id: null,
      name2: 'كل المخازن',
      name: 'All Inventories',
    },
    ...data,
  ];

  return (
    <div>
      <Loading loading={loading}>
        <Card className="mb-2">
          <CardHeader className="px-3 py-0">
            <ul className="nav nav-pills card-header-pills  d-flex m-0 p-0 w-100 ">
              {InventoryList?.map((m: any) => (
                <li className="nav-item flex-fill m-1 " key={m.id}>
                  <Link
                    className={` nav-link text-center ${m.id === moduleId ? 'active' : ''}`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    to="#pills-home"
                    onClick={() => setModuleId(m.id)}>
                    {i18n.language === 'en' ? m.name : m.name2}
                  </Link>
                </li>
              ))}
            </ul>
          </CardHeader>
        </Card>

        <CardBody>
          <div className="row">
            <div className="col-12   col-md-6 col-lg-3 mb-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">عدد الأصناف المخزنة</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7 col-md-8">
                      <h1 className="mb-0 text-theme">
                        <CountUp end={400} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 col-md-4 text-center">
                      <div className="overflow-hidden" style={{ height: '80px' }}>
                        {' '}
                        {/* Adjust the height as necessary */}
                        <i className="fas fa-boxes fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-12   col-md-6 col-lg-3 mb-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">إجمالي القيمة المخزنية</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7 col-md-8">
                      <h1 className="mb-0 text-theme">
                        <CountUp end={400} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 col-md-4 text-center">
                      <div className="overflow-hidden" style={{ height: '80px' }}>
                        <i className="fas fa-dollar-sign fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-12   col-md-6 col-lg-3 mb-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">الأصناف منخفضة المخزون</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7 col-md-8">
                      <h1 className="mb-0 text-theme">
                        <CountUp end={400} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 col-md-4 text-center">
                      <div className="overflow-hidden" style={{ height: '80px' }}>
                        <i className="fas fa-exclamation-triangle fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-12   col-md-6 col-lg-3 mb-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">الأصناف المنتهية</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7 col-md-8">
                      <h1 className="mb-0 text-theme">
                        <CountUp end={400} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 col-md-4 text-center">
                      <div className="overflow-hidden" style={{ height: '80px' }}>
                        <i className="fas fa-ban fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </CardBody>
      </Loading>
    </div>
  );
};

export default InvDashboard;
