import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getInventoryCountById } from '../../../store/INV/InventoryCount/inventoryCountSlice';
import useGetItemsList from '../MasterItems/useGetItemsList';

interface Props {
  invCountId: string | undefined;
}

const useGetInventoryCountById = ({ invCountId }: Props) => {
  const dispatch = useAppDispatch();

  const { InventoryCountById: data, loading, error } = useAppSelector((a) => a.InventoryCount);
  useEffect(() => {
    if (invCountId) {
      dispatch(getInventoryCountById(invCountId));
    }
  }, [invCountId]);
  const { ItemsOptions } = useGetItemsList();

  const CountItemsOptions = data.inventoryCountItemsList?.map((m) => ({
    value: m.inventoryItemId,
    label: ItemsOptions.find((f) => f.value === m.inventoryItemId)?.label,
    //_______________________________________
    invCountId: m.invCountId,
    inventoryItemId: m.inventoryItemId,
    unitOfMeasure: m.unitOfMeasure,
    subInventoryId: m.subInventoryId,
    systemQuantity: m.systemQuantity,
    itemId: m.inventoryItemId,
    invCountItemsId: m.id,
  }));

  return { data, CountItemsOptions, loading, error };
};

export default useGetInventoryCountById;
