import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
// import Loader from "../../../../components/Shared/Loader/Loader";
import './DashboardByModule.css';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useAppSelector } from '../../../../store/hooks';
import NoData from '../../../../components/NoData/NoData';

interface Props {
  dashboardList: any[];
}

const DashboardByModule: React.FC<Props> = ({ dashboardList = [] }) => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPerPage] = useState<number>(10);

  //__________________________________________________

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = dashboardList?.slice(indexOfFirstData, indexOfLastData);

  //___________________________________________________
  const [imgSrc, setImgSrc] = useState<any>(imageSource());
  function imageSource() {
    var imageSource = `/assets/img/Dashboard/${
      localStorage.appMode === 'dark' ? 'DashboardDark.jpg' : 'DashboardLight.jpg'
    }`;
    return imageSource;
  }
  function setimageSource() {
    setImgSrc(imageSource);
  }

  useEffect(() => {
    setimageSource();
    document.addEventListener('theme-reload', () => {
      setimageSource();
    });
  }, []);

  const { loading, error } = useAppSelector((s) => s.DashBoard);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Dashboard'),
      link: null,
    },
  ];
  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Dashboard')} />
      <hr />
      {/* <Card> */}
      <Loading loading={loading} error={error}>
        <div className="tab-content p-4">
          {dashboardList?.length === 0 && <NoData message="No dashboard available" />}
          <div className="row">
            {currentData?.map((item) => (
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 my-2 " key={item.id}>
                <Link to={`${item.id}/BuilderDashboard`} className="text-decoration-none ">
                  <div className="m-1 bg-inverse bg-opacity-10 rounded-3 border border-theme">
                    <div
                      className="position-relative overflow-hidden shadow dashboard-image-container "
                      style={{ height: '160px' }}>
                      <img
                        src={imgSrc}
                        className="card-img rounded-3 dashboard-image"
                        alt=""
                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                      />
                      <div className="card-img-overlay text-white text-center"></div>
                    </div>
                    <CardBody className="py-2 px-3">
                      <div className="row text-center">
                        <div className="fw-bold">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Loading>
      {/* </Card> */}
    </>
  );
};

export default DashboardByModule;
