import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, CardFooter, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';

import useGetOrganizationWithPermissionByType from '../../../../hooks/NTX/Organization/useGetOrganizationWithPermissionByType';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';
import { AddApExpanseSchema } from '../../../ValidationForm/validationSchema';
import {
  addApExpense,
  getApExpenseList,
} from '../../../../store/Finance/Expense/ApExpense/ApExpenseSlice';
import useGetApExpenseCategoryList from '../../../../hooks/Finance/Expense/useGetApExpenseCategoryList';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import ApExpenseLines from './ApExpenseLines';

const Add2 = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading4 } = useLookupCurrency();
  const { DataOptions: BranchsOptions, loading: loading3 } =
    useGetOrganizationWithPermissionByType('150');
  const { treeSuppliersData, error: error2, loading: loading2 } = useGetSuppliersList();
  const { OptionsExpenseCategory } = useGetApExpenseCategoryList();

  const { dataEmployees, loading: loading5 } = useGetPersons();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      no: 0,
      expenseCategoryId: '',
      organizationId: '',
      peopleId: '',
      personId: '',
      expenseDate: '',
      description: '',
      totalAmount: 0,
      totalVat: 0,
      isIncludeVat: false,
      ameStatus: 0,
      isAme: false,
      currencyCode: '',
      currencyConversionRate: 1,
      apExpenseLinesList: [
        {
          expenseCategoryItemId: '',
          description: '',
          amount: 0,
          vat: 0,
          isIncludeVat: true,
          currencyCode: '',
          currencyConversionRate: 1,
        },
      ],
    },

    enableReinitialize: true,
    validationSchema: AddApExpanseSchema(t),

    onSubmit: async (values) => {
      let isConfirmation = await confirmAction('هل ترغب بالتأكيد على إضافة هذه المصروفات؟');
      if (!isConfirmation) {
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addApExpense({
          no: values.no,
          expenseCategoryId: values.expenseCategoryId,
          organizationId: values.organizationId,
          peopleId: values.peopleId,
          personId: values.personId,
          expenseDate: values.expenseDate,
          description: values.description,
          totalAmount: values.totalAmount,
          totalVat: values.totalVat,
          isIncludeVat: false,
          ameStatus: values.ameStatus,
          isAme: false,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate,
          apExpenseLinesList: values.apExpenseLinesList?.map((item) => ({
            expenseCategoryItemId: item.expenseCategoryItemId,
            description: item.description,
            amount: item.amount,
            vat: item.vat,
            isIncludeVat: item.isIncludeVat,
            //
            currencyCode: values.currencyCode,
            currencyConversionRate: values.currencyConversionRate,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          // console.log(res);

          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            dispatch(getApExpenseList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  //#region BcrumbList

  //#endregion

  return (
    <Form onSubmit={formik.handleSubmit} className="container-fluid">
      <div className="row">
        {/* التاريخ */}
        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
          <div className="form-group mb-3">
            <label className="form-label">{t('التاريخ')}</label>
            <Form.Control
              className="form-control"
              type="date"
              name="expenseDate"
              onChange={formikhandleChange}
              value={values.expenseDate || ''}
              // isInvalid={!!(touched.expenseDate && errors.expenseDate)}
            />
            <div className="text-danger small">{formik.errors.expenseDate}</div>
          </div>
        </div>

        {/* expenseCategoryId */}
        <div className="col-lg-3 col-md-6 col-12">
          <div className="form-group mb-3">
            <label className="form-label">{t('فئة المصروف')}</label>

            <Select
              classNamePrefix="react-select"
              // isLoading={loading5}
              isSearchable={true}
              isClearable
              options={OptionsExpenseCategory}
              value={OptionsExpenseCategory?.find(
                (f: any) => f.value === formik.values.expenseCategoryId
              )}
              onChange={(option: any) =>
                setFieldValue('expenseCategoryId', option === null ? null : option.value)
              }
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
            />
            <div className="text-danger small">{formik.errors.expenseCategoryId}</div>
          </div>
        </div>

        {/* peopleId */}
        <div className="col-lg-3 col-md-6 col-12">
          <div className="form-group mb-3">
            <label className="form-label">{t('المورد')}</label>
            <TreeSelect
              showSearch
              className="w-100"
              treeLine
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              allowClear
              treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
              value={values.peopleId}
              onChange={(option) => {
                formik.setFieldValue('peopleId', option === null ? null : option);
              }}
              treeData={treeSuppliersData}
              treeNodeFilterProp="title"
            />
            <div className="text-danger small">{formik.errors.peopleId}</div>
          </div>
        </div>

        {/* organizationId */}
        <div className="col-lg-3 col-md-6 col-12">
          <div className="form-group mb-3">
            <label className="form-label">{t('الفرع')}</label>
            <Select
              classNamePrefix="react-select"
              isLoading={loading3}
              isSearchable={true}
              isClearable
              options={BranchsOptions}
              value={BranchsOptions?.find((f: any) => f.value === formik.values.organizationId)}
              onChange={(option: any) =>
                setFieldValue('organizationId', option === null ? null : option.value)
              }
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
            />
            <div className="text-danger small">{formik.errors.organizationId}</div>
          </div>
        </div>

        {/* personId */}
        {/* <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('personId')}</label>
                    <Select
                      classNamePrefix="react-select"
                      className=" w-100"
                      // isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="personId"
                      options={dataEmployees}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      onChange={(option: any) =>
                        setFieldValue('personId', option === null ? null : option.value)
                      }
                      // value={OptionsAccounts?.find((f) => f.value === selectedAccounts.account.id)}
                    />
                    <div className="text-danger small">{formik.errors.personId}</div>
                  </div>
                </div> */}

        {/* Currency */}
        <div className="col-lg-2 col-md-6 col-12">
          <div className="form-group mb-3">
            <label className="form-label"> {t('Currency')} </label>
            <Form.Select
              className="form-select"
              name="currencyCode"
              onChange={formikhandleChange}
              value={values.currencyCode || ''}
              // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
            >
              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

              {Currency &&
                Currency?.map((item: any, idx: number) => (
                  <option key={++idx} value={item.currencyCode}>
                    {item.description}
                  </option>
                ))}
            </Form.Select>
            <div className="text-danger small">{formik.errors.currencyCode}</div>
          </div>
        </div>

        {/* currencyConversionRate */}
        <div className="col-lg-2 col-md-6 col-12">
          <div className="form-group mb-3">
            <label className="form-label">{t('معدل العملة')}</label>
            <Form.Control
              className="form-control  text-center"
              type="text"
              autoComplete="off"
              name="currencyConversionRate"
              onChange={formikhandleChange}
              value={values.currencyConversionRate || ''}
              // isInvalid={!!(touched.currencyConversionRate && errors.currencyConversionRate)}
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, '');
              }}
            />
            <div className="text-danger small">{formik.errors.currencyConversionRate}</div>
          </div>
        </div>

        {/* description */}
        <div className="col-xl-8">
          <div className="form-group mb-3">
            <label className="form-label">{t('Note')}</label>
            <Form.Control
              className="form-control  "
              type="text"
              autoComplete="off"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              isInvalid={!!(formik.touched.description && formik.errors.description)}
            />
          </div>
        </div>
      </div>

      <CardFooter className="px-1">
        {values.expenseCategoryId && (
          <>
            <ApExpenseLines formik={formik} />
            {formik.errors.apExpenseLinesList?.length && (
              <div className="text-danger"> * مطلوب </div>
            )}
          </>
        )}
      </CardFooter>

      <div className="col-12 text-center mt-2">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn btn-lg me-1 btn-theme mb-1">
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
          )}
          {t('Add')}
        </button>
        <button
          type="button"
          className="btn btn-lg me-1 btn-default mb-1"
          onClick={() => navigate(-1, { replace: true })}>
          <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
        </button>
      </div>
    </Form>
  );
};

export default Add2;

//    {/* المبلغ */}
//    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
//    <div className="form-group mb-3">
//      <label className="form-label">{t('totalAmount')}</label>
//      <Form.Control
//        className="form-control"
//        type="text"
//        autoComplete="off"
//        name="totalAmount"
//        onChange={formikhandleChange}
//        value={values.totalAmount || ''}
//        onInput={(e) => {
//          const input = e.target as HTMLInputElement;
//          input.value = input.value.replace(/[^0-9]/g, '');
//        }}
//      />
//      <div className="text-danger small">{errors.totalAmount}</div>
//    </div>
//  </div>

//  {/* vat الضريبة  */}
//  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
//    <div className="form-group mb-3">
//      <label className="form-label">{t('totalVat')}</label>
//      <Form.Control
//        className="form-control"
//        type="text"
//        autoComplete="off"
//        name="totalVat"
//        onChange={formikhandleChange}
//        value={values.totalVat || ''}
//        onInput={(e) => {
//          const input = e.target as HTMLInputElement;
//          input.value = input.value.replace(/[^0-9]/g, '');
//        }}
//      />
//      <div className="text-danger small">{errors.totalVat}</div>
//    </div>
//  </div>
