import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getApExpenseList } from '../../../store/Finance/Expense/ApExpense/ApExpenseSlice';

const useGetApExpenseList = () => {
  const dispatch = useAppDispatch();

  const { i18n, t } = useTranslation();
  useEffect(() => {
    if (ApExpenseList?.length === 0) {
      dispatch(getApExpenseList());
    }
  }, []);
  const { ApExpenseList, loading, error } = useAppSelector((state) => state.ApExpense);

  return { ApExpenseList, loading, error };
};

export default useGetApExpenseList;
