import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getPurchaseRequestById,
  cleanUpPurchaseRequestById,
} from '../../../../store/purchases/PurchaseRequests/purchaseRequestsSlice';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/Shared/Loader/Loading';

interface Props {
  Quotations: any[];
}
const QuotationComparison = ({ Quotations }: Props) => {
  const { t } = useTranslation();
  var referenceId = Quotations[0]?.referenceId;
  const dispatch = useAppDispatch();
  const {
    PurchaseRequestsById: PurchaseRequests,
    loading,
    error,
  } = useAppSelector((s) => s.PurchaseRequest);
  useEffect(() => {
    if (referenceId) {
      dispatch(getPurchaseRequestById(referenceId));
    }
    return () => {
      dispatch(cleanUpPurchaseRequestById());
    };
  }, [dispatch, referenceId]);

  var listOfAllQUT = Quotations?.map((m: any) => ({
    id: m.id,
  }));
  var ListOfAllLine = PurchaseRequests?.transactionsLinesList?.map((m) => ({
    itemId: m.itemId,
    itemName: m.description,
  }));

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <div className="p-2">
          <div className="text-center bg-inverse bg-opacity-10 rounded-0 p-2 fw-bold fs-5 mb-2">
            مقارنة عروض الأسعار للطلب <span className="text-theme">{Quotations[0]?.code}</span>
          </div>
          <div className="table-responsive ">
            <table className="table table-hover text-nowrap text-center">
              <thead className="table-dark">
                <tr>
                  <th>الصنف</th>
                  {Quotations?.map((quotation: any) => (
                    <th key={quotation.id}>{quotation.peopleName2}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {ListOfAllLine?.map((m: any, idx) => {
                  // Collect all prices for the current item
                  const prices = listOfAllQUT?.map((item) => {
                    const price = Quotations?.find(
                      (f: any) => f.id === item?.id
                    )?.transactionsLinesList?.find((f: any) => f.itemId === m.itemId)?.price;
                    return price;
                  });

                  // Find the minimum price using reduce
                  const minPrice = prices.reduce((min, price) => {
                    if (price === undefined || price === null) return min;
                    return min === null || price < min ? price : min;
                  }, null);

                  return (
                    <tr key={idx}>
                      <td>{m.itemName}</td>

                      {listOfAllQUT?.map((item, idx2) => {
                        const price = Quotations?.find(
                          (f: any) => f.id === item?.id
                        )?.transactionsLinesList?.find((f: any) => f.itemId === m.itemId)?.price;

                        // Apply style for the minimum price
                        return (
                          <td
                            key={idx2}
                            className={price === minPrice ? 'bg-success  bg-opacity-20 ' : ''}>
                            {price || 'None'}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-lg btn-theme  w-100 rounded-0">
                <i className="fas fa-shopping-cart me-2"></i>

                {t('إنشاء أمر شراء تلقائي للأصناف المحددة')}
              </button>
            </div>
          </div>
        </div>
      </Loading>
    </>
  );
};

export default QuotationComparison;
