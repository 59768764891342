import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import useGetArTransactionsList from '../../../../hooks/AR/ArTransactions/useGetArTransactionsList';
import NtxTable from '../../../../components/DataTable/NtxTable';

const Index = () => {
  const [search, setSearch] = useState('');
  const { t, i18n } = useTranslation();

  const { ArTransactionsList, loading, error } = useGetArTransactionsList();

  const filteredSearch = ArTransactionsList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item.id?.toLowerCase().includes(searchLowerCase);
  });

  const columns = [
    { title: 'رقم الفاتورة', key: 'invoiceNum' },
    { title: 'العميل', key: 'peopleName' },
    { title: 'تاريخ الفاتورة', key: 'invoiceDate' },
    { title: 'نوع الفاتورة', key: 'invoiceType' },
    { title: 'الفرغ', key: 'organizationName' },
    { title: 'المبلغ', key: 'invoiceAmount' },
    { title: 'حالة الترحيل', key: 'postedFlag', className: 'text-center' }, // إضافة حالة الترحيل

    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData = ArTransactionsList?.map((item) => {
    let rowClass;

    // if (item.invoiceAmount > 1000) {
    //   rowClass = 'table-success';
    // } else if (item.invoiceAmount < 500) {
    //   rowClass = 'table-warning';
    // }
    // تحديد الشارة بناءً على قيمة postedFlag
    const postedFlagBadge = item.postedFlag ? (
      <span className="badge bg-success">
        <i className="fas fa-check me-1"></i>
        مُرحلة
      </span>
    ) : (
      <span className="badge bg-danger">
        <i className="fas fa-times me-1"></i>
        غير مُرحلة
      </span>
    );
    return {
      invoiceNum: item.transactionNum,
      peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      invoiceDate: item.transactionDate,
      invoiceType: item.transactionType,
      organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
      invoiceAmount: item.transactionAmount,
      postedFlag: postedFlagBadge, // استخدام الشارة هنا
      action: (
        <Link to={`${item.id}/UpdateArTransactions`} className="btn btn-outline-theme mx-1">
          <i className="fas fa-info-circle me-2"></i>
          {t('التفاصيل')}
        </Link>
      ),
      rowClass, // استخدم rowClass هنا
    };
  });

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    // {
    //   name: 'إدارة المدفوعات',
    //   link: null,
    // },
    {
      name: 'فواتيــر الإستحقاق',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="فواتيــر الإستحقاق" />

        <div className="ms-auto">
          <Link to="AddArTransactions" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            فاتورة جديدة
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <NtxTable
                data={tableData}
                columns={columns}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch={true}
                showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Index;
