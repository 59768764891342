import { Form } from 'react-bootstrap';
import { FormikProps } from 'formik';
// import { useTranslation } from 'react-i18next';
import UnApplyPrepayments from './UnApplyPrepaymentsModal';
import { memo, useState } from 'react';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';
import { UnApplyPrepaymentsModels } from '../../../../store/Finance/AP/ApPayments/ApPaymentsModels';

interface Props {
  formik: FormikProps<any>;
  UnApplyPrepaymentsList: UnApplyPrepaymentsModels[];
}

const ArReceiptSchedules = ({ formik, UnApplyPrepaymentsList }: Props) => {
  const { values, setFieldValue } = formik;

  const {   arTransactionLinesList, arReceiptSchedulesList, transactionType, postedFlag } = values;

  const [showModal, setShowModal] = useState(false);
 
  const totalInvoiceLines = arTransactionLinesList?.reduce(
    (sum: number, item: any) => sum + item.total,
    0
  );
  const totalReceiptSchedules = arReceiptSchedulesList?.reduce(
    (sum: number, payment: any) => sum + Number(payment.amount),
    0
  );
  const remainingAmount = Number(totalInvoiceLines) - Number(totalReceiptSchedules);

  const handlePaymentsChange = (index: number, field: string, value: any) => {
    const updatedReceipts = [...arReceiptSchedulesList];
    const Receipt = updatedReceipts[index];

    updatedReceipts[index] = {
      ...Receipt,
      [field]: value,
    };

    // إذا كان الحقل "amount"، تأكد من أن القيمة رقمية
    if (field === 'amount') {
      const numericValue = value === '' || value === null ? 0 : Number(value);
      updatedReceipts[index][field] = !isNaN(numericValue) ? numericValue : 0;
    }

    // تحديث القيم في النموذج
    formik?.setFieldValue('arReceiptSchedulesList', updatedReceipts);
  };

  const handleAddPayment = () => {
    const newReceipt = {
      id: null,
      amount: remainingAmount || 0,
      dueDate: new Date().toISOString().split('T')[0],
      receiptId: null,
    };
    setFieldValue('arReceiptSchedulesList', [...arReceiptSchedulesList, newReceipt]);
  };

  const handleDeletePayment = (index: number) => {
    const updatedReceipts = arReceiptSchedulesList?.filter(
      (_: any, paymentIndex: any) => paymentIndex !== index
    );
    setFieldValue('arReceiptSchedulesList', updatedReceipts);
  };

  // إضافة دفعة مقدمة مختارة
  const handleSelectPrepayment = (item: any) => {
    let selectedAmount =
      item.amountUnApply > remainingAmount ? remainingAmount : item.amountUnApply;

    const prepayment = {
      id: null,
      amount: selectedAmount,
      dueDate: new Date().toISOString().split('T')[0],
      //
      paymentId: item.id,
      PaymentAmountUnApply: item.amountUnApply, // مبلغ الدفعة المتبقي - لمنع الممستخدم من إدخال اكبر منه
      paymentCode: item.code, // رقم الدفعة
      paymentDate: new Date(item.depositDate).toLocaleDateString(), // تريخ الدقعة
      paymentAmount: item.amount, // مبلغ الدفعة
    };

    setFieldValue('arReceiptSchedulesList', [...arReceiptSchedulesList, prepayment]);
    setShowModal(false); // إغلاق النافذة بعد الاختيار
  };

  console.log(values.arReceiptSchedulesList);
  

  return (
    <>
      <div className="row ">
        <div className="col-md-3">
          <div className="row gx-4 mb-2" hidden={postedFlag}>
            <div className="col-lg-12 text-end">
              <button
                disabled={remainingAmount <= 0}
                type="button"
                className="btn btn-outline-theme w-100 mb-2"
                onClick={handleAddPayment}>
                <i className="fas fa-money-bill me-2"></i>
                إضافة دفعة
              </button>
              <button
                disabled={remainingAmount <= 0}
                hidden={transactionType === 'DebitMemo' || UnApplyPrepaymentsList.length === 0}
                type="button"
                className="btn btn-outline-theme w-100 mb-2"
                onClick={() => setShowModal(true)}>
                <i className="fas fa-donate me-2"></i>
                إضافة دفعة من دفعة مقدمة
              </button>
            </div>
          </div>
          <div className="col-lg-12 mb-2">
            <h6 className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
              إجمالي الفاتورة :
              <span className="ms-3 text-theme">{totalInvoiceLines?.toFixed(2) || '0.00'}</span>
            </h6>
          </div>
          <div className="col-lg-12 mb-2">
            <h6 className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
              إجمالي الجدولة :
              <span className="ms-3 text-theme">{totalReceiptSchedules?.toFixed(2) || '0.00'}</span>
            </h6>
          </div>
          <div className="col-lg-12 mb-2" hidden={postedFlag}>
            <h6 className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
              المتبقي :
              <span className={`ms-3 ${remainingAmount < 0 ? 'text-danger' : 'text-theme'}`}>
                {remainingAmount?.toFixed(2) || '0.00'}
              </span>
            </h6>
          </div>
        </div>

        <div className="col-md-9">
          <div className="table-responsive">
            <table
              border={1}
              className="table table-hover table-borderless text-nowrap text-center ">
              <thead className="bg-theme bg-opacity-30">
                <tr>
                  <th className="align-middle  small">#</th>
                  <th className="align-middle  small">رقم الدفعة المقدمة</th>

                  <th className="align-middle  small">المبلغ</th>

                  <th className="align-middle  small">تاريخ الإستحقاق</th>

                  <th className="align-middle text-end" hidden={postedFlag}></th>
                </tr>
              </thead>

              <tbody className="fw-bold">
                {values.arReceiptSchedulesList?.slice()?.map((line: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle small">{index + 1}</td>

                      <td className="align-middle small ">
                        <PopoverCustom
                          childern={
                            <Form.Control
                              hidden={!line.paymentCode}
                              className="text-center text-theme"
                              type="text"
                              autoComplete="off"
                              value={line.paymentCode || ''}
                              readOnly
                            />
                          }
                          Header="تفاصيل الدفعة"
                          Body={
                            <>
                              <div className="d-flex justify-content-between">
                                <span className="mt-1">تاريخ الدفعة</span>
                                <span className="text-danger fw-bold fs-6 mx-2 mt-1">
                                  {line.paymentDate}
                                </span>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span className="mt-1">مبلغ الدفعة</span>
                                <span className="text-success fw-bold fs-6 mx-2 mt-1">
                                  {Number(line.paymentAmount)}
                                </span>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span className="mt-1">المبلغ المتبقي</span>
                                <span className="text-success fw-bold fs-6 mx-2 mt-1">
                                  {Number(line.PaymentAmountUnApply)}
                                </span>
                              </div>
                            </>
                          }
                        />
                      </td>

                      <td className="align-middle small ">
                        <Form.Control
                          className="text-center  "
                          type="text"
                          autoComplete="off"
                          value={Number(line.amount) || ''}
                          onChange={(e) =>
                            handlePaymentsChange(index, 'amount', parseFloat(e.target.value))
                          }
                          onInput={(e) => {
                            const input = e.target as HTMLInputElement;

                            // السماح فقط بالأرقام والنقطة العشرية
                            input.value = input.value.replace(/[^0-9.]/g, '');

                            // السماح فقط بنقطة عشرية واحدة
                            if (input.value.split('.').length > 2) {
                              input.value = input.value.substring(0, input.value.length - 1);
                            }

                            // التحقق من القيمة المدخلة بالنسبة لحد المبلغ المتبقي
                            if (
                              line.paymentId !== null &&
                              parseFloat(input.value) > line.PaymentAmountUnApply
                            ) {
                              input.value = line.PaymentAmountUnApply.toString();
                            }
                          }}
                        />
                      </td>

                      <td className="align-middle small ">
                        <Form.Control
                          className="text-center "
                          type="date"
                          value={line.dueDate || ''}
                          onChange={(e) => handlePaymentsChange(index, 'dueDate', e.target.value)}
                        />
                      </td>

                      <td className="align-middle small " hidden={postedFlag}>
                        <button
                          type="button"
                          className="btn btn-danger w-100"
                          // className="btn btn-outline-danger w-100"
                          onClick={() => handleDeletePayment(index)}>
                          <i className="fas fa-trash-alt me-2"></i>
                          حذف الدفعة
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        size="lg"
        centered
        title="الدفعات المقدمة للمورد">
        <UnApplyPrepayments
          data={UnApplyPrepaymentsList?.filter(
            (prepayment: any) =>
              !values.arReceiptSchedulesList.some(
                (payment: any) => payment.paymentId === prepayment.id
              )
          )}
          onSelectPrepayment={handleSelectPrepayment} // تمرير دالة الاختيار
        />
      </NeatixModal>
    </>
  );
};

export default memo(ArReceiptSchedules);
