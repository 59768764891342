import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getLotDetailsListByItemId } from '../../../store/INV/MasterItems/LotItemSlice';
import { useTranslation } from 'react-i18next';

 
const useGetLotsItemByItemId = ( ItemId : string) => {
  const dispatch = useAppDispatch();

  const {i18n} = useTranslation();
  useEffect(() => {
    dispatch(getLotDetailsListByItemId(ItemId));
  }, [ItemId]);

  const { LotDetailsListByItem, loading, error } = useAppSelector((a) => a.LotItem);




  // const OptionsUnitsByItemId = LotDetailsListByItem?.map((item) => ({
  //   value: item.id,
  //   label: `${i18n.language === "en" ? item. : item.uomName2 }`,
  // }));OptionsUnitsByItemId




  return {  LotDetailsListByItem, loading, error };
};

export default useGetLotsItemByItemId;
