import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  GetPriceListItemsByPriceListId,
  PriceListItemsCleanUp,
} from '../../../store/INV/PriceList/PriceListSlice';

interface Props {
  priceListId: string;
}

const useGetPriceListItems = ({ priceListId }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (priceListId) {
      dispatch(GetPriceListItemsByPriceListId(priceListId));
    }
    return () => {
      dispatch(PriceListItemsCleanUp());
    };
  }, [dispatch, priceListId]);
  const { PriceListItemsByPriceListId, loading, error } = useAppSelector(
    (state) => state.PriceList
  );

  const PriceListItems = PriceListItemsByPriceListId;

  return { PriceListItems, loading, error };
};

export default useGetPriceListItems;
