import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Card, CardBody } from '../../../components/card/card';
import Breadcrumb from '../../../components/Shared/Breadcrumb';

import NtxTable from '../../../components/DataTable/NtxTable';
import useGetBranches from '../../../hooks/Finance/ChartAccountsDetails/useGetBranches';
import { getGlCodeCombinationsList } from '../../../store/Finance/CodeCombinations/CodeCombinationsSlice';
import Loading from '../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../components/Modal/NeatixModal';
import Control from './Control';
import { ControlCodeCombinationsModels } from '../../../store/Finance/CodeCombinations/CodeCombinationsModels';

const Index = () => {
  const [codeCombination, setCodeCombination] = useState<ControlCodeCombinationsModels>();
  const [show, setShow] = useState(false);
  const [branch, setBranch] = useState('');
  const [search, setSearch] = useState('');
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { OptionsBranches, loading: loading1 } = useGetBranches();

  useEffect(() => {
    if (branch) {
      dispatch(getGlCodeCombinationsList(branch));
    }
  }, [branch, dispatch]);
  const {
    GlCodeCombinationsList: data,
    loading,
    error,
  } = useAppSelector((s) => s.CodeCombinations);

  const filteredSearch = data?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item?.description?.toLowerCase().includes(searchLowerCase);
  });

  const columns = [
    { title: 'الشركة', key: 'company' },
    { title: 'الفرع', key: 'branch' },
    { title: 'الحساب', key: 'account' },
    { title: 'مركز التكلفة', key: 'costCenter' },
    { title: 'الوصف', key: 'description' },
    { title: 'الحالةس', key: 'enabledFlag' },
    { title: '', key: 'action', className: 'text-end', sortable: false },
  ];

  const tableData = filteredSearch?.map((item) => {
    let rowClass;

    const FlagBadge = (
      <span
        className={`badge d-flex align-items-center gap-1 ${
          item.enabledFlag === true ? 'bg-success' : 'bg-danger'
        }`}>
        <i
          className={`fa ${item.enabledFlag === true ? 'fa-check-circle' : 'fa-times-circle'}`}></i>
        {item.enabledFlag === true ? t('مفعل') : t('غير مفعل')}
      </span>
    );

    return {
      company: i18n.language === 'en' ? item.companyName : item.companyName2,
      branch: i18n.language === 'en' ? item.branchName : item.branchName2,
      account: i18n.language === 'en' ? item.accountName : item.accountName2,
      costCenter: i18n.language === 'en' ? item.costCenterName : item.costCenterName2,
      enabledFlag: FlagBadge,
      description: i18n.language === 'en' ? item.description : item.description2,

      action: (
        <button
          type="button"
          className={`btn  ${item.enabledFlag === true ? 'btn-warning ' : 'btn-success '} `}
          onClick={() => {
            setCodeCombination(item);
            setShow(true);
          }}>
          {item.enabledFlag !== true ? (
            <>
              <i className="fas fa-check-circle me-1"></i>
              {t('تفعيــل')}
            </>
          ) : (
            <>
              <i className="fas fa-times-circle me-1"></i>
              {t('إلغـاء التفعيـل')}
            </>
          )}
        </button>
      ),
    };
  });

  // ____________________________

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Control CodeCombinations',
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Control CodeCombinations" />
      </div>

      <Card>
        <CardBody>
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="input-group flex-nowrap">
                <span className="input-group-text fw-bold">{t('Search')}</span>
                <div className="input-group">
                  <div className="flex-fill position-relative">
                    <div className="input-group border-0 ">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: '10px' }}>
                        <i className="fa fa-search opacity-5 text-theme"></i>
                      </div>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control ps-35px"
                        // placeholder={t('Search')}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="input-group flex-nowrap">
                <span className="input-group-text fw-bold">{t('الفرع')}</span>
                <Select
                  classNamePrefix="react-select"
                  className={`w-100 ${true ? 'is-invalid' : ''}`}
                  isLoading={loading1}
                  isSearchable={true}
                  isClearable
                  options={OptionsBranches}
                  value={OptionsBranches.find((f) => f.value === branch)}
                  onChange={(e: any) => setBranch(e === null ? null : e.value)}
                  placeholder={i18n.language === 'ar' ? 'إختر الفرع' : 'Choose Branch'}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </div>
            </div>
          </div>
          <Loading loading={loading} error={error} Type="Dots">
            <NtxTable
              data={tableData}
              columns={columns}
              dataPerPage={10}
              theadClass=""
              tableClass="table text-nowrap table-hover "
            />
          </Loading>
        </CardBody>
      </Card>

      <NeatixModal
        show={show}
        setShow={setShow}
        size="md"
        centered={true}
        showHeader={false}
        bodyClassName="p-0">
        <Card>
          <CardBody>{codeCombination && <Control codeCombination={codeCombination} />}</CardBody>
        </Card>
      </NeatixModal>
    </>
  );
};

export default Index;
