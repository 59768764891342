import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  addAssets,
  getAssetsList,
  getAssetsById,
} from '../../../../../store/Finance/FixedAssets/Assets/assetsSlice';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import useGetFACategoriesList from '../../../../../hooks/Finance/FixedAssets/useGetFACategoriesList';
import useGetAccounts from '../../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import useGetGlLedgersList from '../../../../../hooks/Finance/useGetGlLedgersList';
import useGetDepreciationMethod from '../../../../../hooks/Lookups/use-Get_DEPRECIATION_METHOD';
import useGetBranchOrgWithPermission from '../../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetNewUsed from '../../../../../hooks/Lookups/use-Get_NEW_USED';
import useGetOwnedLeased from '../../../../../hooks/Lookups/use-Get_OWNED_LEASED';
import useGetAssetsList from '../../../../../hooks/Finance/FixedAssets/useGetAssetsList';
import { Card, CardBody } from '../../../../../components/card/card';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { AddAssetsSchema } from '../../../../ValidationForm/validationSchema';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Update = () => {
  
  const { assetsId } = useParams();

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { BranchOrgOptions: BranchsOptions, loading: loading3 } = useGetBranchOrgWithPermission();
  const { FACategoriesList, loading, error } = useGetFACategoriesList();
  const { OptionsAccounts } = useGetAccounts();
  const { LedgersList } = useGetGlLedgersList();
  const { DEPRECIATION_METHOD } = useGetDepreciationMethod();
  const { NEW_USED } = useGetNewUsed();
  const { OWNED_LEASED } = useGetOwnedLeased();
  // const { OptionsAssets } = useGetAssetsList();

  const { AssetsById: data } = useAppSelector((a) => a.Assets);

  useEffect(() => {
    if (assetsId) {
      dispatch(getAssetsById(assetsId));
    }
  }, [assetsId, dispatch]);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      name: data.name ?? '',
      name2: data.name2 ?? '',
      assetNumber: data.assetNumber ?? '',
      tagNumber: data.tagNumber ?? '',
      serialNumber: data.serialNumber ?? '',
      categoryId: data.categoryId ?? '',
      organizationId: data.organizationId ?? '',
      parentAssetId: data.parentAssetId ?? '',
      datePlacedInService: data.datePlacedInService?.toString()?.split('T')[0] ?? '',
      ownedLeased: data.ownedLeased ?? '',
      leasedId: data.leasedId ?? '',
      newUsed: data.newUsed ?? '',
      originalCost: data.originalCost ?? '',
      adjustedCost: data.adjustedCost ?? '',
      salvageValue: data.salvageValue ?? '',
      lifeInMonths: data.lifeInMonths ?? '',
      depreciationMethod: data.depreciationMethod ?? '',
      percentage: data.percentage ?? '',
      description: data.description ?? '',
      assetCostAccount: data.assetCostAccount ?? '',
      deprnExpenseAccount: data.deprnExpenseAccount ?? '',
      deprnReserveAccount: data.deprnReserveAccount ?? '',
      glLedgerId: data.glLedgerId ?? '',
    },

    enableReinitialize: true,
    validationSchema: AddAssetsSchema(t),

    onSubmit: (values) => {
      dispatch(
        addAssets({
          name: values.name,
          name2: values.name2,
          assetNumber: values.assetNumber,
          tagNumber: values.tagNumber,
          serialNumber: values.serialNumber,
          categoryId: values.categoryId,
          organizationId: values.organizationId,
          parentAssetId: null,
          // parentAssetId: values.parentAssetId  ,
          datePlacedInService: values.datePlacedInService,
          ownedLeased: values.ownedLeased,
          leasedId: null,
          newUsed: values.newUsed,
          originalCost: values.originalCost,
          adjustedCost: values.adjustedCost,
          salvageValue: values.salvageValue,
          lifeInMonths: values.lifeInMonths,
          depreciationMethod: values.depreciationMethod,
          percentage: values.percentage,
          description: values.description,
          assetCostAccount: values.assetCostAccount,
          deprnExpenseAccount: values.deprnExpenseAccount,
          deprnReserveAccount: values.deprnReserveAccount,
          glLedgerId: values.glLedgerId,
        })
      )
        .unwrap()
        .then((res) => {
          debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            dispatch(getAssetsList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  //____________________________

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="section mb-4">
          <h6 className="">
            <i className="fas fa-lg fa-fw me-2 fa fa-info-circle text-theme"></i>
            المعلومات الأساسية والتعريفية للأصل
          </h6>
          <hr />
          <CardBody>
            <div className="row">
              {/* LocalName */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('LocalName')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="name2"
                    onChange={handleChange}
                    value={values.name2}
                    // isInvalid={!!(touched.name2 && errors.name2)}
                  />
                  <div className="text-danger small">{touched.name2 && errors.name2}</div>
                </div>
              </div>

              {/* GlobalName */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('GlobalName')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    // isInvalid={!!(touched.name && errors.name)}
                  />
                  <div className="text-danger small">{touched.name && errors.name}</div>
                </div>
              </div>

              {/* categoryId */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('فئة الأصل')}</label>
                  <Form.Select
                    autoComplete="off"
                    className="form-select"
                    name="categoryId"
                    onChange={handleChange}
                    value={values.categoryId}
                    // isInvalid={!!(touched.categoryId && errors.categoryId)}
                    // disabled
                  >
                    <option value={''}>{i18n.language === 'en' ? 'رئيسي' : 'رئيسي'}</option>
                    {FACategoriesList &&
                      FACategoriesList?.map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                  </Form.Select>
                  <div className="text-danger small">{touched.categoryId && errors.categoryId}</div>
                </div>
              </div>
              {/* newUsed */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('نوع الأصل')}</label>
                  <Form.Select
                    className="form-select"
                    name="newUsed"
                    onChange={handleChange}
                    value={values.newUsed}
                    // isInvalid={touched.newUsed && errors.newUsed}
                  >
                    <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {NEW_USED?.map((item: any, idx: number) =>
                      item.list?.map((list: any) => (
                        <option key={++idx} value={list.lookupCode}>
                          {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                        </option>
                      ))
                    )}
                  </Form.Select>
                  <div className="text-danger small">{touched.newUsed && errors.newUsed}</div>
                </div>
              </div>

              {/* assetNumber */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('رقم الأصل')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="assetNumber"
                    onChange={handleChange}
                    value={values.assetNumber}
                    // isInvalid={!!(touched.assetNumber && errors.assetNumber)}
                  />
                  <div className="text-danger small">
                    {touched.assetNumber && errors.assetNumber}
                  </div>
                </div>
              </div>
              {/* tagNumber */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('tagNumber')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="tagNumber"
                    onChange={handleChange}
                    value={values.tagNumber}
                    // isInvalid={!!(touched.tagNumber && errors.tagNumber)}
                  />
                  <div className="text-danger small">{touched.tagNumber && errors.tagNumber}</div>
                </div>
              </div>

              {/* serialNumber */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الرقم التسلسلي')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="serialNumber"
                    onChange={handleChange}
                    value={values.serialNumber}
                    // isInvalid={!!(touched.serialNumber && errors.serialNumber)}
                  />
                  <div className="text-danger small">
                    {touched.serialNumber && errors.serialNumber}
                  </div>
                </div>
              </div>

              {/* datePlacedInService */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('تاريخ وضع الأصل في الخدمة')}</label>
                  <Form.Control
                    className="form-control "
                    type="date"
                    name="datePlacedInService"
                    onChange={handleChange}
                    value={values.datePlacedInService}
                  />
                  <div className="text-danger small">
                    {touched.datePlacedInService && errors.datePlacedInService}
                  </div>
                </div>
              </div>
              {/* الفرع */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('الفرع')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    value={BranchsOptions?.find(
                      (f: any) => f.value === formik.values.organizationId
                    )}
                    onChange={(option: any) =>
                      setFieldValue('organizationId', option === null ? null : option.value)
                    }
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {touched.organizationId && errors.organizationId}
                  </div>
                </div>
              </div>
              {/* description */}
              <div className="col-lg-9">
                <div className="form-group mb-3">
                  <label className="form-label">{t('description')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    // isInvalid={!!(touched.description && errors.description)}
                  />
                  {/* <div className="text-danger small">{touched.description && errors.description}</div> */}
                </div>
              </div>
            </div>
          </CardBody>
        </div>

        <div className="section mb-4">
          <h6>
            <i className="fas fa-lg fa-fw me-2 fa fa-money-bill-wave  text-theme"></i>
            المعلومات المالية والاستهلاك
          </h6>
          <hr />
          <CardBody>
            <div className="row">
              {/* التكلفة الأصلية  */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('التكلفة الأصلية ')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="originalCost"
                    onChange={handleChange}
                    value={values.originalCost}
                    // isInvalid={!!(touched.originalCost && errors.originalCost)}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value?.replace(/[^0-9]/g, '');
                    }}
                  />
                  <div className="text-danger small">
                    {touched.originalCost && errors.originalCost}
                  </div>
                </div>
              </div>
              {/* التكلفة المعدلة  */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('التكلفة المعدلة ')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="adjustedCost"
                    onChange={handleChange}
                    value={values.adjustedCost}
                    // isInvalid={!!(touched.adjustedCost && errors.adjustedCost)}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value?.replace(/[^0-9]/g, '');
                    }}
                  />
                  <div className="text-danger small">
                    {touched.adjustedCost && errors.adjustedCost}
                  </div>
                </div>
              </div>
              {/* قيمة الخردة' */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('قيمة الخردة')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    name="salvageValue"
                    onChange={handleChange}
                    value={values.salvageValue}
                    // isInvalid={!!(touched.salvageValue && errors.salvageValue)}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value?.replace(/[^0-9]/g, '');
                    }}
                  />
                  <div className="text-danger small">
                    {touched.salvageValue && errors.salvageValue}
                  </div>
                </div>
              </div>

              {/* ownedLeased */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('ownedLeased')}</label>
                  <Form.Select
                    className="form-select"
                    name="ownedLeased"
                    onChange={handleChange}
                    value={values.ownedLeased}
                    // isInvalid={touched.ownedLeased && errors.ownedLeased}
                  >
                    <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {OWNED_LEASED?.map((item: any, idx: number) =>
                      item.list?.map((list: any) => (
                        <option key={++idx} value={list.lookupCode}>
                          {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                        </option>
                      ))
                    )}
                  </Form.Select>
                  <div className="text-danger small">
                    {touched.ownedLeased && errors.ownedLeased}
                  </div>
                </div>
              </div>
              {/* leasedId */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('leasedId ')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    name="leasedId"
                    onChange={handleChange}
                    value={values.leasedId}
                    // isInvalid={!!(touched.leasedId && errors.leasedId)}
                  />
                  <div className="text-danger small">{touched.leasedId && errors.leasedId}</div>
                </div>
              </div>

              {/* lifeInMonths */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('lifeInMonths')}</label>
                  <Form.Control
                    className="form-control "
                    type="text"
                    autoComplete="off"
                    name="lifeInMonths"
                    onChange={handleChange}
                    value={values.lifeInMonths}
                    // isInvalid={!!(touched.lifeInMonths && errors.lifeInMonths)}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value?.replace(/[^0-9]/g, '');
                    }}
                  />
                  <div className="text-danger small">
                    {touched.lifeInMonths && errors.lifeInMonths}
                  </div>
                </div>
              </div>
              {/* طريقة الإستهلاك' */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('طريقة الإستهلاك')}</label>
                  <Form.Select
                    className="form-select"
                    name="depreciationMethod"
                    onChange={handleChange}
                    value={values.depreciationMethod}
                    // isInvalid={touched.depreciationMethod && errors.depreciationMethod}
                  >
                    <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {DEPRECIATION_METHOD?.map((item: any, idx: number) =>
                      item.list?.map((list: any) => (
                        <option key={++idx} value={list.lookupCode}>
                          {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                        </option>
                      ))
                    )}
                  </Form.Select>
                  <div className="text-danger small">
                    {touched.depreciationMethod && errors.depreciationMethod}
                  </div>
                </div>
              </div>
              {/* 'نسبة الإهلاك' */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('نسبة الإهلاك')}</label>
                  <div className="input-group flex-nowrap">
                    <Form.Control
                      className="form-control text-center"
                      type="text"
                      autoComplete="off"
                      name="percentage"
                      onChange={handleChange}
                      value={values.percentage}
                      // isInvalid={!!(touched.percentage && errors.percentage)}
                      onInput={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.value = input.value?.replace(/[^0-9]/g, '');
                      }}
                    />
                    <span className="input-group-text text-theme ">%</span>
                  </div>
                  <div className="text-danger small">{touched.percentage && errors.percentage}</div>
                </div>
              </div>
            </div>
          </CardBody>
        </div>

        <div className="section mb-2 ">
          <h6>
            <i className="fas fa-lg fa-fw me-2 fa fa-book text-theme"></i> معلومات الحسابات المالية
          </h6>
          <hr />

          <CardBody>
            <div className="row">
              {/* >دفتر الأستاذ العام */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">دفتر الأستاذ العام</label>
                  <Form.Select
                    className="form-select"
                    name="glLedgerId"
                    onChange={handleChange}
                    value={values.glLedgerId}
                    // isInvalid={!!(errors.glLedgerId && touched.glLedgerId)}
                  >
                    <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {LedgersList &&
                      LedgersList?.map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <div className="text-danger small">{touched.glLedgerId && errors.glLedgerId}</div>
                </div>
              </div>
              {/* حساب تكلفة الأصول' */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('حساب تكلفة الأصول')}</label>
                  <Select
                    classNamePrefix="react-select"
                    // className="text-center"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    options={OptionsAccounts}
                    value={OptionsAccounts?.find((f) => f.value === values.assetCostAccount)}
                    onChange={(option) => setFieldValue('assetCostAccount', option?.value)}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {touched.assetCostAccount && errors.assetCostAccount}
                  </div>
                </div>
              </div>
              {/* حساب مصاريف الاستهلاك' */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('حساب مصاريف الاستهلاك')}</label>
                  <Select
                    classNamePrefix="react-select"
                    // className="text-center"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    options={OptionsAccounts}
                    value={OptionsAccounts?.find((f) => f.value === values.deprnExpenseAccount)}
                    onChange={(option) => setFieldValue('deprnExpenseAccount', option?.value)}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {touched.deprnExpenseAccount && errors.deprnExpenseAccount}
                  </div>
                </div>
              </div>
              {/* حساب احتياطي الاستهلاك' */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('حساب احتياطي الاستهلاك')}</label>
                  <Select
                    classNamePrefix="react-select"
                    // className="text-center"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    options={OptionsAccounts}
                    value={OptionsAccounts?.find((f) => f.value === values.deprnReserveAccount)}
                    onChange={(option) => setFieldValue('deprnReserveAccount', option?.value)}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {touched.deprnReserveAccount && errors.deprnReserveAccount}
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme btn-lg  "
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          {/* <button type="button" className=" btn me-1 btn-default mb-1">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button> */}
        </div>
      </Form>
    </>
  );
};

export default Update;

// {/* تابع لـ  */}
// <div className="col-lg-4">
//   <div className="form-group mb-3">
//     <label className="form-label"> {t('تابع لـ ')}</label>
//     <Select
//       classNamePrefix="react-select"
//       // isLoading={loading3}
//       isSearchable={true}
//       isClearable
//       options={OptionsAssets}
//       value={OptionsAssets?.find((f: any) => f.value === formik.values.parentAssetId)}
//       onChange={(option: any) =>
//         setFieldValue('parentAssetId', option === null ? null : option.value)
//       }
//       placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
//     />
//   </div>
// </div>
