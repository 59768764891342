import arLanguage from './translateAR.json';
import enLanguage from './translateEN.json';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
DataTable.use(DT);

const DataTableComponent = ({ data, columns, options, tableClass = '', theadClass = '' }) => {
  const tableClassName = ` ${tableClass || 'table table-hover'}`;
  const theadClassName = ` ${theadClass || 'bg-theme bg-opacity-30'}`;

  return (
 
      <DataTable
        options={{
          ...options,
          language: localStorage.language === 'ar' ? arLanguage : enLanguage,
        }}
        className={tableClassName}>
        <thead className={theadClassName}>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className={row.rowClass}>
              {columns.map((column) => (
                <td key={column.key} className={'align-middle ' + column.className}>
                  {row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </DataTable>
    
  );
};

export default DataTableComponent;
