import React from 'react';
 import useGetCostCenters from '../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useGetBranches from '../../../hooks/Finance/ChartAccountsDetails/useGetBranches';
import { FormikProps } from 'formik';
import Loading from '../../../components/Shared/Loader/Loading';
import { useTranslation } from 'react-i18next';
import { TreeSelect } from 'antd';
import Select from 'react-select';
import useGetBudgetAccountsList from '../../../hooks/Finance/GlBudget/useGetBudgetAccountsList';

interface LinesProps {
  formik: FormikProps<any>;
}

const BudgetAssignmentsList = ({ formik }: LinesProps) => {
  const { values, setFieldValue } = formik;
  const { TreeCostCenters } = useGetCostCenters();
  const { OptionsBranches } = useGetBranches();
  const { OptionsGlBudget } = useGetBudgetAccountsList();

  const { t, i18n } = useTranslation();

  const handleAddLine = () => {
    const newLine = {
      id: null,
      costCenter: null,
      branch: null,
      account: null,
      amount: 0,
    };
    formik.setFieldValue('glBudgetAssignmentsList', [
      ...formik.values.glBudgetAssignmentsList,
      newLine,
    ]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.glBudgetAssignmentsList?.filter((_: any, i: number) => i !== index);
    setFieldValue('glBudgetAssignmentsList', updatedLines);
  };

  const handleChange = (index: number, fieldName: string, value: any) => {
    const updatedLines = [...values.glBudgetAssignmentsList];
    updatedLines[index] = {
      ...updatedLines[index],
      [fieldName]: value,
    };
    setFieldValue('glBudgetAssignmentsList', updatedLines);
  };

 
  return (
    <>
      <Loading loading={false} error={null} Type="Dots">
        <>
          <div className="table-responsive rounded-2 text-center">
            <table border={2} className="table table-hover table-borderless mb-2 ">
              <thead className="bg-theme bg-opacity-30">
                <tr>
                  <th className="align-middle  small" style={{ width: '2%' }}>
                    #
                  </th>

                  <th className="align-middle small" style={{ width: '28%' }}>
                    {t('الفرع')}
                  </th>
                  <th className="align-middle small" style={{ width: '28%' }}>
                    {t('الحســـاب')}
                  </th>
                  <th className="align-middle small" style={{ width: '28%' }}>
                    {t('مركز التكلفة')}
                  </th>
                  <th className="align-middle small" style={{ width: '12%' }}>
                    {t('الميزانيـة')}
                  </th>

                  <th className="align-middle text-end" style={{ width: '2%' }}></th>
                </tr>
              </thead>

              <tbody className="fw-bold">
                {values.glBudgetAssignmentsList
                  ?.slice()

                  ?.map((line: any, index: any) => {
                    const Index = index;

                    return (
                      <tr key={index}>
                        <td className="align-middle small">{index + 1}</td>

                        <td className={`align-middle small`}>
                          <Select
                            classNamePrefix="react-select"
                            className=" w-100"
                            isSearchable={true}
                            isClearable
                            options={OptionsBranches}
                            // placeholder='إختر'
                            value={OptionsBranches.find((f) => f.value === line.branch)}
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                            onChange={(option) => handleChange(index, 'branch', option?.value)}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </td>

                        <td className={`align-middle small`}>
                          <Select
                            classNamePrefix="react-select"
                            className=" w-100"
                            isSearchable={true}
                            isClearable
                            options={OptionsGlBudget}
                            value={OptionsGlBudget.find((f) => f.value === line.account)}
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                            onChange={(option) => handleChange(index, 'account', option?.value)}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </td>

                        <td className={`align-middle small`}>
                          <TreeSelect
                            showSearch
                            treeData={TreeCostCenters}
                            style={{ borderRadius: '1px !important' }}
                            className="custom-tree-select w-100  rounded-0"
                            treeLine
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                            allowClear
                            treeDefaultExpandAll
                            // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            getPopupContainer={() => document.body} // هنا يتم تحديد عرض القائمة على مستوى body وليس داخل table-responsive
                            onChange={(value) => handleChange(index, 'costCenter', value)}
                            value={line.costCenter}
                          />
                        </td>

                        <td className={`align-middle small`}>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control fw-bold text-theme text-center px-0"
                            value={line.amount}
                            onChange={(e) => handleChange(index, 'amount', e.target.value)}
                            onInput={(e) => {
                              const input = e.target as HTMLInputElement;
                              const numericValue = input.value.replace(/[^0-9]/g, '');
                              input.value = new Intl.NumberFormat('en-US').format(
                                Number(numericValue)
                              );
                            }}
                          />
                        </td>
                        <td className="align-middle small">
                          <button
                            type="button"
                            className="btn btn-danger w-100"
                            onClick={() => handleDeleteLine(Index)}>
                            <i className="fas fa-lg fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-lg-12 text-start ">
              <div className="text-start mb-2">
                <button type="button" className="btn btn-outline-theme" onClick={handleAddLine}>
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  {t('إضافة')}
                </button>
              </div>
            </div>
          </div>
        </>
      </Loading>
    </>
  );
};

export default BudgetAssignmentsList;
