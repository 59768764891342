import { FormikProps } from 'formik';
import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetSuppliersList from '../../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';
import useGetPaymentTypeList from '../../../../../hooks/Finance/ApPayments/useGetPaymentTypeList';
import useGetBranchOrgWithPermission from '../../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { paymentTypeEnum } from './Add';
import DataTableComponent from '../../../../../components/DataTable/DataTableComponent';
import { useAppDispatch } from '../../../../../store/hooks';
import { fetchUnpaidInvoices } from '../../../../../store/Finance/AP/ApInvoices/UnpaidInvoicesSlice';
import { UnPaymentSchedulesModels } from '../../../../../store/Finance/AP/ApInvoices/ApInvoicesModels';

interface Props {
  formik: FormikProps<any>;
  onClose: () => void;
}

const InitialAdd: React.FC<Props> = ({ formik, onClose }) => {
  const { values, setFieldValue } = formik;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState<UnPaymentSchedulesModels[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);

  const { BranchOrgOptions: BranchsOptions, loading, error } = useGetBranchOrgWithPermission();
  const { treeSuppliersData, error: error2, loading: loading2 } = useGetSuppliersList();
  const { PaymentTypeList, loading: loading3, error: error3 } = useGetPaymentTypeList();

  const handleGetUnpaidInvoices = async () => {
    try {
      const data = await dispatch(
        fetchUnpaidInvoices({
          peopleId: values.peopleId,
          organizationId: values.organizationId,
          invoiceType:
            values.paymentType === paymentTypeEnum.Standard
              ? 'Standard'
              : values.paymentType === paymentTypeEnum.Refund
              ? 'DebitMemo'
              : '',
        })
      ).unwrap();
      if (data.succeeded) {
        setInvoices(data.data);
      } else {
        console.error('فشل في جلب الفواتير');
      }
    } catch (error) {
      console.error('Error fetching unpaid invoices:', error);
    } finally {
      setIsLoading(false); // إنهاء حالة الإرسال
    }
  };

  const handleCheckboxChange = (paymentScheduleId: any) => {
    const updatedSelectedInvoices = new Set(selectedInvoices);
    if (updatedSelectedInvoices.has(paymentScheduleId)) {
      updatedSelectedInvoices.delete(paymentScheduleId);
    } else {
      updatedSelectedInvoices.add(paymentScheduleId);
    }
    setSelectedInvoices(updatedSelectedInvoices);
  };

  const handleSubmit = () => {
    // تحويل Set إلى مصفوفة للحصول على الفواتير المحددة
    const selectedArray = Array.from(selectedInvoices);
    // console.log('Selected invoices to pay:', selectedArray);

    // فلترة الفواتير المحددة وإضافة الخاصية الجديدة لكل كائن
    const filteredInvoices = invoices
      .filter((f: any) => selectedArray.includes(f.apPaymentScheduleId))
      .map((item: any) => ({
        // ...item,
        //__
        id: null,
        apPaymentScheduleId: item.apPaymentScheduleId,
        invoiceNum: item.invoiceNum,
        invoiceDate: item.invoiceDate,
        dueDate: item.dueDate,
        invoiceAmount: item.invoiceAmount,
        schadualAmount: item.amount,
        amount: item.remainingAmount,
        actualRemainingAmount: item.remainingAmount, // لمنع المستخدم من ادخال قيمة اكبر من المتبقي
      }));
    formik?.setFieldValue('scheduleList', filteredInvoices); // تعيين الفواتير المحددة

    onClose(); // إغلاق النموذج أو القيام بأي إجراء آخر
  };

  const columns = [
    { title: '', key: 'checkbox' },
    { title: t('PaymentNumber'), key: 'paymentNum' },
    { title: t('InvoiceNumber'), key: 'invoiceNum' },
    { title: t('InvoiceDate'), key: 'invoiceDate' },
    { title: t('InvoiceAmount'), key: 'invoiceAmount' },
    { title: t('RemainingAmount'), key: 'remainingAmount' },
    { title: t('DueDate'), key: 'dueDate' },
  ];

  const tableData = invoices.map((invoice) => ({
    checkbox: (
      <input
        type="checkbox"
        className="form-check-input"
        checked={selectedInvoices.has(invoice.apPaymentScheduleId)}
        onChange={() => handleCheckboxChange(invoice.apPaymentScheduleId)}
      />
    ),
    paymentNum: invoice.paymentNum,
    invoiceNum: invoice.invoiceNum,
    invoiceDate: invoice.invoiceDate,
    invoiceAmount: invoice.invoiceAmount.toString(),
    remainingAmount: invoice.remainingAmount.toString(),
    dueDate: invoice.dueDate,
  }));

  const options = {
    paging: true,
    pageLength: 6, // عرض 10 صفوف فقط في كل صفحة
    searching: true,
    ordering: true,
    lengthChange: false,
    // info: false, // إخفاء النص "Showing x to y of z entries"
    columnDefs: [
      { orderable: false, targets: 0 }, // منع الترتيب للحقل الأول (checkbox)
    ],
    order: [[2, 'asc']], // ترتيب تصاعدي للعمود الثاني (رقم الفاتورة)
  };

  return (
    <Loading
      loading={loading || loading2 || loading3}
      error={error || error2 || error3}
      Type="Dots">
      <>
        <div className="row align-items-end">
          {/* نوع الدفع */}
          <div className="col-lg-12">
            <div className="form-group mb-3 ">
              <label className="form-label">{t('PaymentType')}</label>
              <Form.Select
                // disabled
                className="form-select form-select-lg text-center"
                name="paymentType"
                onChange={(e) => {
                  selectedInvoices.clear();
                  setInvoices([]);
                  formik.handleChange(e);
                }}
                value={values.paymentType || ''}>
                {/* <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option> */}
                {PaymentTypeList?.map((item, idx) => (
                  <option key={idx} value={item.id}>
                    {i18n.language === 'en' ? item.name : item.name2}
                  </option>
                ))}
              </Form.Select>
              {/* <div className="text-danger small">{touched.paymentType && errors.paymentType}</div> */}
            </div>
          </div>

          {/* {t('المورد')} */}
          <div className="col-lg-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('Supplier')}</label>
              <TreeSelect
                loading={loading2}
                showSearch
                className="w-100"
                treeLine
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                allowClear
                treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                // loadData={} // يتم تحميل البيانات عند الطلب
                // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                value={values.peopleId}
                onChange={(option) => {
                  selectedInvoices.clear();
                  setInvoices([]);
                  formik.setFieldValue('peopleId', option === null ? null : option);
                }}
                treeData={treeSuppliersData}
                treeNodeFilterProp="title"
                getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
              />
              {/* <div className="text-danger small">{touched.peopleId && errors.peopleId}</div> */}
            </div>
          </div>

          {/* ('الفرع') */}
          <div className="col-lg-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('Branch')}</label>

              <Select
                classNamePrefix="react-select"
                isLoading={loading}
                isSearchable={true}
                isClearable
                options={BranchsOptions}
                value={BranchsOptions?.find((f: any) => f.value === formik.values.organizationId)}
                onChange={(option: any) => {
                  selectedInvoices.clear();
                  setInvoices([]);
                  setFieldValue('organizationId', option === null ? null : option.value);
                }}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
              {/* <div className="text-danger small">
                {touched.organizationId && errors.organizationId}
              </div> */}
            </div>
          </div>

          <div className="col-lg-2">
            <div className=" form-group mb-3  ">
              {values.paymentType === paymentTypeEnum.PrePayment ? (
                <>
                  <label className="form-label">{t('Amount')}</label>
                  <Form.Control
                    className="form-control text-center fs-5 fw-bold text-theme"
                    type="text"
                    autoComplete="off"
                    name="prePaymentTotal"
                    onChange={formik.handleChange}
                    value={values.prePaymentTotal || ''}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/[^0-9]/g, '');
                    }}
                  />
                </>
              ) : (
                <>
                  <button
                    disabled={isLoading || !values.organizationId || !values.peopleId}
                    type="button"
                    className="btn  btn-outline-theme  w-100 rounded-0 me-2"
                    // onClick={handleGetUnpaidInvoices2}
                    onClick={handleGetUnpaidInvoices}>
                    {isLoading ? (
                      <div className="spinner-border spinner-border-sm me-2"></div>
                    ) : (
                      <i className="fas fa-lg fa-fw me-2 fa-download"></i>
                    )}
                    {t('FetchInvoices')}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        {tableData.length > 0 && (
          <DataTableComponent data={tableData} columns={columns} options={options} />
        )}

        <hr />

        <div className=" text-end">
          <button
            disabled={selectedInvoices.size === 0}
            hidden={values.paymentType === paymentTypeEnum.PrePayment}
            className="btn  btn-outline-theme rounded-0 me-2"
            onClick={handleSubmit}>
            <i className="fas fa-lg fa-fw me-2 fa-money-bill"></i>
            {t('PaySelectedInvoices')}
          </button>

          <button
            disabled={!values.prePaymentTotal || values.prePaymentTotal <= 0}
            hidden={values.paymentType !== paymentTypeEnum.PrePayment}
            className="btn  btn-outline-theme rounded-0 me-2"
            onClick={handleSubmit}>
            <i className="fas fa-lg fa-fw me-2 fa-check"></i>
            {t('Ok')}
          </button>

          <button
            type="button"
            className="btn  btn-outline-default rounded-0 me-2"
            onClick={() => navigate('/Finance/Ap/ApPayments', { replace: true })}>
            <i className="fas fa-lg fa-fw me-2 fa-times-circle"></i>
            {t('Cancel')}
          </button>
        </div>
      </>
    </Loading>
  );
};

export default InitialAdd;
