import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlDailyRatesList } from '../../../store/Finance/DailyRates/DailyRatesSlice';

const useGetGlDailyRatesList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (DailyRatesList?.length === 0) {
      dispatch(GetGlDailyRatesList());
    }
  }, []);
  const { DailyRatesList, loading, error } = useAppSelector((state) => state.DailyRates);

  // const GetConversionRate = (organizationId: string, Date: string , currencyCode) => {

  //   let 
  //   const currencyCodeFrom = ledgar?.currencyCode;
  //   const currencyCodeTo = values.currencyCode;
  //   const glDate = new Date(values.glDate);
  //   if (!currencyCodeFrom || !currencyCodeTo || !glDate) {
  //     return 1;
  //   }
  //   // debugger
  //   if (currencyCodeFrom === currencyCodeTo) {
  //     return 1;
  //   } else {
  //     const foundRate = DailyRatesList?.find((rate) => {
  //       const startDate = new Date(rate.conversionStartDate);
  //       const endDate = rate.conversionEndDate
  //         ? new Date(rate.conversionEndDate)
  //         : null;
  //       return (
  //         rate.fromCurrencyCode === currencyCodeFrom &&
  //         rate.toCurrencyCode === currencyCodeTo &&
  //         startDate <= glDate &&
  //         (!endDate || endDate >= glDate)
  //       );
  //     });
  //     // Set conversionDate state to fromCurrencyCode
  //     if (foundRate) {
  //       setConversionDate(foundRate.conversionStartDate || "");
  //     }
  //     return Number(foundRate?.conversionRate) || 1;
  //   }
  // };GetConversionRate

  return { DailyRatesList, loading, error,  };
};

export default useGetGlDailyRatesList;
