import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PaymentTermsModels } from './PaymentTermsModels';

export const addPaymentTerms = createAsyncThunk(
  'PaymentTerms/addPaymentTerms',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('NtxSetting/AddPaymentTerms', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePaymentTerms = createAsyncThunk(
  'PaymentTerms/updatePaymentTerms',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('NtxSetting/UpdatePaymentTerms', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPaymentTermsList = createAsyncThunk(
  'PaymentTerms/getPaymentTermsList',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get('NtxSetting/GetPaymentTermsList');
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  PaymentTermsList: PaymentTermsModels[];
  loading: boolean;
  error: any | null;
} = {
  PaymentTermsList: [],
  loading: false,
  error: null,
};

const PaymentTermsSlice = createSlice({
  name: 'PaymentTerms',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getPaymentTermsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPaymentTermsList.fulfilled,
        (state, action: PayloadAction<PaymentTermsModels[]>) => {
          state.loading = false;
          state.PaymentTermsList = action.payload;
        }
      )
      .addCase(getPaymentTermsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default PaymentTermsSlice.reducer;
