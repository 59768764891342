import React, { useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../../../components/Modal/NeatixModal';
import AddCreateAccountArTransactions from './AddCreateAccountArTransactions';
import useGetArTransactionsList from '../../../../../hooks/AR/ArTransactions/useGetArTransactionsList';
import NtxTable from '../../../../../components/DataTable/NtxTable';

const ListArTransactions = () => {
  const [showModal, setShowModal] = useState(false);

  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const [selectedApInvoices, setSelectedApInvoices] = useState<string[] | any>([]);

  const { ArTransactionsList, loading, error } = useGetArTransactionsList();

  const columns = [
    { title: '', key: 'checkBox' },
    { title: 'رقم الفاتورة', key: 'invoiceNum' },
    { title: 'العميل', key: 'peopleName' },
    { title: 'تاريخ الفاتورة', key: 'invoiceDate' },
    { title: 'نوع الفاتورة', key: 'invoiceType' },
    { title: 'الفرغ', key: 'organizationName' },
    { title: 'المبلغ', key: 'invoiceAmount' },
    { title: 'حالة الترحيل', key: 'postedFlag', className: 'text-center' }, // إضافة حالة الترحيل
  ];

  const tableData = ArTransactionsList?.map((item) => {
    let rowClass;

    // if (item.invoiceAmount > 1000) {
    //   rowClass = 'table-success';
    // } else if (item.invoiceAmount < 500) {
    //   rowClass = 'table-warning';
    // }
    // تحديد الشارة بناءً على قيمة postedFlag
    const postedFlagBadge = item.postedFlag ? (
      <span className="badge bg-success">
        <i className="fas fa-check me-1"></i>
        مُرحلة
      </span>
    ) : (
      <span className="badge bg-danger">
        <i className="fas fa-times me-1"></i>
        غير مُرحلة
      </span>
    );
    return {
      checkBox: (
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`checkbox-${item.id}`}
            checked={selectedApInvoices?.some((row: any) => row.id === item.id)}
            onChange={() => handleCheckboxChange(item)}
          />
        </div>
      ),
      invoiceNum: item.transactionNum,
      peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      invoiceDate: item.transactionDate,
      invoiceType: item.transactionType,
      organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
      invoiceAmount: item.transactionAmount,
      postedFlag: postedFlagBadge, // استخدام الشارة هنا

      rowClass, // استخدم rowClass هنا
    };
  });

  //____________________________

  const handleCheckboxChange = (row: any) => {
    setSelectedApInvoices((prevSelectedRows: any) => {
      const isSelected = prevSelectedRows?.some((item: any) => item.id === row.id);

      if (isSelected) {
        return prevSelectedRows?.filter((item: any) => item.id !== row.id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const handleOpenAddCreateAccount = () => {
    if (selectedApInvoices?.length > 0) {
      setShowModal(true);
    }
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    // {
    //   name: 'إدارة المدفوعات',
    //   link: null,
    // },
    {
      name: 'ترحيــل معاملات الإستحقاق',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center"></div>

      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="ترحيــل معاملات الإستحقاق" />

        <div className="ms-auto">
          <button type="button" className="btn me-1 btn-theme" onClick={handleOpenAddCreateAccount}>
            <i className="fa fa-share-square fa-fw me-1"></i>
            ترحيــل المعاملات المحددة
          </button>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <NtxTable
                data={tableData}
                columns={columns}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch={true}
                showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        bodyClassName="p-0"
        size="xl"
        showHeader={false}>
        <Card>
          <CardBody>
            {selectedApInvoices?.length > 0 && (
              <AddCreateAccountArTransactions selectedApInvoices={selectedApInvoices} />
            )}
          </CardBody>
        </Card>
      </NeatixModal>
    </div>
  );
};

export default ListArTransactions;
