import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { TransactionsLinesList } from '../../../../store/Sales/SalesInvoices/SalesInvoicesModels';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import useGetPriceListItemsByPeopleIdAndInventoryId from '../../../../hooks/INV/PriceList/GetPriceListItemsByPeopleIdAndInventoryId';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import useGetTaxRateByOrganization from '../../../../hooks/Finance/TaxRate/useGetTaxRateByOrganization';

interface LinesProps {
  formik: FormikProps<any>;
  // isUpdate?: boolean;
}

const SalesInvoicesLines: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;
  //________________________________________________________________

  // const { taxRate } = useGetTaxRateByOrganization({
  //   dateActive: values.transactionDate,
  //   taxType: 'Sales',
  //   organizationId: values.organizationId,
  // });

  //________________________________________________________________
  const { ItemsOptions, loading, error } = useGetPriceListItemsByPeopleIdAndInventoryId({
    customerId: values.peopleId,
    inventoryId: values.inventoryId,
  });

  const { i18n, t } = useTranslation();

  // const handleLineChange = (index: number, field: string, value: any) => {
  //   const updatedLines = [...values.transactionsLinesList]; // نسخ قائمة السطور الحالية

  //   // عند تغيير 'itemId'
  //   if (field === 'itemId') {
  //     // جلب الوحدة الأساسية للصنف
  //     const Primaryuom = ItemsOptions?.find(
  //       (f) => f.value === value && f.isPrimaryUOM
  //     )?.unitOfMeasure;

  //     const selectedUOM = Primaryuom || updatedLines[index].uom; // استخدام الوحدة الأساسية إذا كانت موجودة

  //     const price =
  //       ItemsOptions?.find((f) => f.value === value && f.unitOfMeasure === selectedUOM)
  //         ?.unitPrice || 0;

  //     const total = Number(updatedLines[index].qty || 0) * Number(price);

  //     updatedLines[index] = {
  //       ...updatedLines[index],
  //       [field]: value, // تحديث 'itemId'
  //       uom: selectedUOM, // تحديث الوحدة الأساسية
  //       price: Number(price),
  //       total,
  //       qty: 0, // تعيين الكمية الافتراضية إلى 0
  //     };

  //     // تحديث الحقول في formik
  //     formik?.setFieldValue('transactionsLinesList', updatedLines);
  //     return; // الخروج من الدالة بعد تحديث 'itemId'
  //   }

  //   // عند تغيير 'uom'
  //   if (field === 'uom') {
  //     const price =
  //       ItemsOptions?.find(
  //         (f) => f.value === updatedLines[index].itemId && f.unitOfMeasure === value
  //       )?.unitPrice || 0;

  //     const total = Number(updatedLines[index].qty || 0) * Number(price);

  //     updatedLines[index] = {
  //       ...updatedLines[index],
  //       [field]: value, // تحديث 'uom'
  //       price: Number(price),
  //       total,
  //     };

  //     // تحديث الحقول في formik
  //     formik?.setFieldValue('transactionsLinesList', updatedLines);
  //     return; // الخروج من الدالة بعد تحديث 'uom'
  //   }

  //   // تحديث الحقول الأخرى مثل 'qty' أو 'price' أو 'vatRate'
  //   updatedLines[index] = {
  //     ...updatedLines[index],
  //     [field]: value, // تحديث الحقل المطلوب
  //   };

  //   // إعادة حساب إجمالي السعر وضريبة القيمة المضافة عند تغيير الكمية أو السعر أو نسبة الضريبة
  //   if (['qty', 'price', 'vatRate'].includes(field)) {
  //     const total = Number(updatedLines[index].qty || 0) * Number(updatedLines[index].price || 0);
  //     const vatAmount = (total * Number(updatedLines[index].vatRate || 0)) / 100;

  //     updatedLines[index] = {
  //       ...updatedLines[index],
  //       total,
  //       vatAmount,
  //     };
  //   }

  //   // تحديث الحقول في formik
  //   formik?.setFieldValue('transactionsLinesList', updatedLines);
  // };

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...values.transactionsLinesList]; // نسخ قائمة السطور الحالية

    // عند تغيير 'itemId'
    if (field === 'itemId') {
      const Primaryuom = ItemsOptions?.find(
        (f) => f.value === value && f.isPrimaryUOM
      )?.unitOfMeasure;

      const selectedUOM = Primaryuom || updatedLines[index].uom;

      const price =
        ItemsOptions?.find((f) => f.value === value && f.unitOfMeasure === selectedUOM)
          ?.unitPrice || 0;

      const total = Number(updatedLines[index].qty || 0) * Number(price);

      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
        uom: selectedUOM,
        price: Number(price),
        total,
        qty: 0,
      };

      formik?.setFieldValue('transactionsLinesList', updatedLines);
      return;
    }

    // عند تغيير 'uom'
    if (field === 'uom') {
      const price =
        ItemsOptions?.find(
          (f) => f.value === updatedLines[index].itemId && f.unitOfMeasure === value
        )?.unitPrice || 0;

      const total = Number(updatedLines[index].qty || 0) * Number(price);

      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
        price: Number(price),
        total,
      };

      formik?.setFieldValue('transactionsLinesList', updatedLines);
      return;
    }

    // تحديث الحقول الأخرى مثل 'qty' أو 'price' أو 'vatRate'
    updatedLines[index] = {
      ...updatedLines[index],
      [field]: value,
    };

    // إعادة حساب الإجمالي وقيمة الخصم أو نسبة الخصم بناءً على المدخلات
    if (['qty', 'price', 'vatRate', 'discountRate', 'discountAmount'].includes(field)) {
      const price = Number(updatedLines[index].price || 0);
      const qty = Number(updatedLines[index].qty || 0);
      const totalBeforeDiscount = price * qty;

      let discountRate = Number(updatedLines[index].discountRate || 0);
      let discountAmount = Number(updatedLines[index].discountAmount || 0);

      // حسابات الخصم
      if (field === 'discountRate') {
        // إذا تم تعديل نسبة الخصم
        discountAmount = (totalBeforeDiscount * discountRate) / 100;
      } else if (field === 'discountAmount') {
        // إذا تم تعديل قيمة الخصم
        discountRate = (discountAmount / totalBeforeDiscount) * 100;
      }

      // حساب الإجمالي بعد الخصم
      const total = totalBeforeDiscount - discountAmount;
      const vatAmount = (total * Number(updatedLines[index].vatRate || 0)) / 100;

      updatedLines[index] = {
        ...updatedLines[index],
        totalBeforeDiscount, // إجمالي قبل الخصم
        discountAmount, // قيمة الخصم
        discountRate, // نسبة الخصم
        total, // الإجمالي بعد الخصم
        vatAmount, // ضريبة القيمة المضافة
      };
    }

    // تحديث الحقول في formik
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine: TransactionsLinesList = {
      itemId: null,
      lineNumber: values?.transactionsLinesList?.length + 1,
      uom: '',
      price: 0,
      qty: 0,
      discountRate: 0,
      discountAmount: 0,
      // vatRate: taxRate || 0,
      vatRate: 15,
      total: 0,
      vatAmount: 0,
      description: '',
      id: null, //
      inventoryId: '', //
    };
    // setLines([...Lines, newLine]);
    formik.setFieldValue('transactionsLinesList', [
      ...formik.values.transactionsLinesList,
      newLine,
    ]);
  };

  const handleDeleteLine = (index: number) => {
    // const updatedLines = Lines?.filter((_, i) => i !== index);
    const updatedLines = values.transactionsLinesList?.filter((_: any, i: number) => i !== index);

    // setLines(updatedLines);
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.transactionsLinesList?.reduce(
      (sum: number, line: any) => sum + line.total,
      0
    );
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <div className="mb-3">
          <div className="row">
            <div className="col-3">
              <div className="mb-1 rounded-3 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-around">
                  <div className="fw-bold">{t('Total')} :</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
                  {/* <i className="bi bi-plus-square-dotted fa-lg me-2"></i> */}
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          {values.transactionsLinesList?.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  formik.errors.transactionsLinesList ? 'border-bottom border-2 border-danger' : ''
                } `}>
                <div style={{ width: '150%' }}>
                  <table className="table table-borderless  table-hover mb-0 ">
                    <thead className="bg-theme bg-opacity-30">
                      <tr>
                        <th className="align-middle" style={{ width: '2%' }}>
                          #
                        </th>
                        <th className="align-middle" style={{ width: '30%' }}>
                          {t('اسم الصنف')}
                        </th>
                        <th className="align-middle" style={{ width: '10%' }}>
                          {t('الوحدة')}
                        </th>
                        <th className="align-middle" style={{ width: '5%' }}>
                          {t('الكمية')}
                        </th>
                        <th className="align-middle" style={{ width: '5%' }}>
                          {t('السعر')}
                        </th>
                        <th className="align-middle" style={{ width: '5%' }}>
                          {t('نسبة الخصم')}
                        </th>
                        <th className="align-middle" style={{ width: '5%' }}>
                          {t('قيمة الخصم')}
                        </th>
                        <th className="align-middle" style={{ width: '5%' }}>
                          {t('VAT %')}
                        </th>
                        <th className="align-middle" style={{ width: '5%' }}>
                          {t('VAT')}
                        </th>
                        <th className="align-middle" style={{ width: '5%' }}>
                          {t('Total')}
                        </th>
                        <th className="align-middle" style={{ width: '18%' }}>
                          {t('الوصف')}
                        </th>
                        <th className="align-middle text-end" style={{ width: '2%' }}></th>
                      </tr>
                    </thead>
                    <tbody className="fw-bold">
                      {values.transactionsLinesList
                        ?.slice()
                        .reverse()
                        ?.map((line: any, index: any) => {
                          // const Index = Lines?.length - 1 - index;
                          const Index = values.transactionsLinesList?.length - 1 - index;

                          return (
                            <tr key={index}>
                              <td className="align-middle bg-theme bg-opacity-30 small">
                                {values.transactionsLinesList?.length - index}
                              </td>
                              <td className="align-middle small">
                                <Select
                                  classNamePrefix="react-select"
                                  className="w-100"
                                  isSearchable
                                  isClearable
                                  options={ItemsOptions?.filter(
                                    (f) =>
                                      !values.transactionsLinesList?.some(
                                        (line: any) => line.itemId === f.value
                                      )
                                  )?.filter(
                                    (item, index, self) =>
                                      index === self?.findIndex((i) => i.itemId === item.itemId)
                                  )}
                                  onChange={(option) =>
                                    handleLineChange(
                                      Index,
                                      'itemId',
                                      option === null ? null : option.value
                                    )
                                  }
                                  value={
                                    line.itemId === null
                                      ? null
                                      : ItemsOptions?.find((option) => option.value === line.itemId)
                                  }
                                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                  }}
                                  menuPlacement="top"
                                />
                              </td>

                              <td className="align-middle small">
                                <select
                                  className="form-select text-center"
                                  value={line.uom || ''}
                                  onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
                                  <option value="">
                                    {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                  </option>
                                  {ItemsOptions?.filter((f) => f.itemId === line.itemId)?.map(
                                    (item, idx) => (
                                      <option key={++idx} value={item.unitOfMeasure}>
                                        {item.unitOfMeasureName}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>

                              <td className="align-middle small">
                                <PopoverCustom
                                  childern={
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      className="form-control text-center px-0"
                                      value={line.qty}
                                      onChange={(e) =>
                                        handleLineChange(Index, 'qty', Number(e.target.value))
                                      }
                                      onInput={(e) => {
                                        const input = e.target as HTMLInputElement;
                                        input.value = input.value.replace(/[^0-9]/g, '');

                                        var QtyAvailable =
                                          ItemsOptions?.find(
                                            (f) =>
                                              f.itemId === line.itemId &&
                                              f.unitOfMeasure === line.uom
                                          )?.QtyAvailable ?? 0;

                                        if (Number(input.value) > QtyAvailable) {
                                          ToastSwal({
                                            title: `الكمية المتاحة في المخزن هي ${QtyAvailable} فقط`,
                                            timer: 1000,
                                            position: 'bottom-start',
                                          });
                                          input.value = QtyAvailable.toString();
                                        }
                                      }}
                                    />
                                  }
                                  Header="الكمية المتوفرة في المخـزن"
                                  Body={
                                    <span className="text-success fw-bold fs-5 mx-2">
                                      {
                                        ItemsOptions?.find(
                                          (f) =>
                                            f.itemId === line.itemId && f.unitOfMeasure === line.uom
                                        )?.QtyAvailable
                                      }
                                    </span>
                                  }
                                />
                              </td>
                              <td className="align-middle small">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control text-center px-0"
                                  // size={line.price.toString()?.length || 1}
                                  value={line.price}
                                  onChange={(e) =>
                                    handleLineChange(Index, 'price', Number(e.target.value))
                                  }
                                  onInput={(e) => {
                                    // إلغاء أي إدخال غير رقمي يدويًا
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                  }}
                                />
                              </td>
                              <td className="align-middle small">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control text-center px-0"
                                  value={line.discountRate}
                                  onChange={(e) =>
                                    handleLineChange(Index, 'discountRate', Number(e.target.value))
                                  }
                                  onInput={(e) => {
                                    // إلغاء أي إدخال غير رقمي يدويًا
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                  }}
                                />
                              </td>

                              <td className="align-middle small">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control text-center px-0"
                                  value={line.discountAmount}
                                  onChange={(e) =>
                                    handleLineChange(
                                      Index,
                                      'discountAmount',
                                      Number(e.target.value)
                                    )
                                  }
                                  onInput={(e) => {
                                    // إلغاء أي إدخال غير رقمي يدويًا
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                  }}
                                />
                              </td>

                              <td className="align-middle small">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control text-center px-0"
                                  value={line.vatRate}
                                  onChange={(e) =>
                                    handleLineChange(Index, 'vatRate', Number(e.target.value))
                                  }
                                  onInput={(e) => {
                                    // إلغاء أي إدخال غير رقمي يدويًا
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                  }}
                                />
                              </td>
                              <td className="align-middle small">{line.vatAmount}</td>
                              <td className="align-middle small">{line.total}</td>
                              <td className="align-middle small">
                                <Form.Control
                                  className="form-control"
                                  as="textarea"
                                  rows={1}
                                  value={line.description}
                                  onChange={(e) =>
                                    handleLineChange(Index, 'description', e.target.value)
                                  }
                                />
                              </td>
                              <td className="align-middle small">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => handleDeleteLine(Index)}>
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </Loading>
    </>
  );
};

export default SalesInvoicesLines;

// const handleLineChange = (index: number, field: string, value: any) => {
//   const updatedLines = [...values.transactionsLinesList]; // نسخ قائمة السطور الحالية

//   // عند تغيير 'itemId' أو 'uom'
//   if (field === 'itemId' || field === 'uom') {
//     const selectedUOM = field === 'itemId' ? updatedLines[index].uom : value;

//     const price =
//       ItemsOptions?.find(
//         (f) =>
//           f.value === (field === 'itemId' ? value : updatedLines[index].itemId) &&
//           f.unitOfMeasure === selectedUOM
//       )?.unitPrice || 0;

//     const total = Number(updatedLines[index].qty || 0) * Number(price);

//     updatedLines[index] = {
//       ...updatedLines[index],
//       [field]: value, // تحديث الحقل إما 'itemId' أو 'uom'
//       price: Number(price),
//       total,
//       qty: 0,
//     };

//     // تحديث الحقول في formik
//     formik?.setFieldValue('transactionsLinesList', updatedLines);
//     return; // الخروج من الدالة بعد تحديث 'itemId' أو 'uom'
//   }

//   // تحديث الحقول الأخرى مثل 'qty' أو 'price' أو 'vatRate'
//   updatedLines[index] = {
//     ...updatedLines[index],
//     [field]: value, // تحديث الحقل المطلوب
//   };

//   // إعادة حساب إجمالي السعر وضريبة القيمة المضافة عند تغيير الكمية أو السعر أو نسبة الضريبة
//   if (['qty', 'price', 'vatRate'].includes(field)) {
//     const total = Number(updatedLines[index].qty || 0) * Number(updatedLines[index].price || 0);
//     const vatAmount = (total * Number(updatedLines[index].vatRate || 0)) / 100;

//     updatedLines[index] = {
//       ...updatedLines[index],
//       total,
//       vatAmount,
//     };
//   }

//   // تحديث الحقول في formik
//   formik?.setFieldValue('transactionsLinesList', updatedLines);
// };
