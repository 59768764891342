import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import {
  UpdatNtxPeoples,
  AddNtxPeoples,
  GetNtxPeoplesByID,
  cleanPeoplesById,
  GetNtxPeoplesList,
} from '../../../store/NTX/Peoples/peoplesSlice';
import { useParams } from 'react-router-dom';
import { NtxPeopleSchema } from '../../ValidationForm/validationSchema';

import SupplierForm from './SupplierForm';
import CustomerForm from './CustomerForm';
import Loading from '../../../components/Shared/Loader/Loading';

interface Props {
  typeCode: string;
  isUpdate: boolean;
  parentId?: string | any;
}

const NtxPeoples2: React.FC<Props> = ({ isUpdate, typeCode, parentId }) => {
  const { Id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(cleanPeoplesById());
    if (Id && isUpdate) {
      dispatch(GetNtxPeoplesByID(Id));
    }
  }, [Id, dispatch, isUpdate]);

  const { PeoplesById, loading, error } = useAppSelector((state) => state.Peoples);

  const formik = useFormik({
    initialValues: {
      id: PeoplesById?.id ?? null,
      name: PeoplesById?.name ?? '',
      name2: PeoplesById?.name2 ?? '',
      personId: PeoplesById?.personId ?? null,
      typeCode: typeCode,
      customerNumber: PeoplesById?.customerNumber ?? '',
      parentId: PeoplesById?.parentId ?? parentId,
      priceListId: PeoplesById?.priceListId ?? '',
      shipToLocationId: PeoplesById?.shipToLocationId ?? '',
      billToLocationId: PeoplesById?.billToLocationId ?? '',
      termsId: PeoplesById?.termsId ?? '',
      setOfBooksId: PeoplesById?.setOfBooksId ?? '',
      creditLimit: PeoplesById?.creditLimit ?? 0,
      paymentPriority: PeoplesById?.paymentPriority ?? '',
      invoiceCurrencyCode: PeoplesById?.invoiceCurrencyCode ?? 'SR',
      paymentCurrencyCode: PeoplesById?.paymentCurrencyCode ?? 'SR',
      invoiceLimit: PeoplesById?.invoiceAmountLimit ?? '',
      paymentMethodLookupCode: PeoplesById?.paymentMethodLookupCode ?? '',
      bankAccountName: PeoplesById?.bankAccountName ?? '',
      bankAccountNum: PeoplesById?.bankAccountNum ?? '',
      bankNumber: PeoplesById?.bankNumber ?? '',
      bankAccountType: PeoplesById?.bankAccountType ?? '',
      vatCode: PeoplesById?.vatCode ?? 0,
      tradingName: PeoplesById?.tradingName ?? '',
      workReference: PeoplesById?.workReference ?? '',
      companyRegistrationNumber: PeoplesById?.companyRegistrationNumber ?? '',
      nationalInsuranceNumber: PeoplesById?.nationalInsuranceNumber ?? '',
      verificationNumber: PeoplesById?.verificationNumber ?? '',
      email: PeoplesById?.email ?? '',
      phone: PeoplesById?.phone ?? '',
      fax: PeoplesById?.fax ?? '',
      mobile: PeoplesById?.mobile ?? '',
      address: PeoplesById?.address ?? '',
      description: PeoplesById?.description ?? '',
      extraInformationId: PeoplesById?.extraInformationId ?? '',
      peopleType: PeoplesById?.peopleType ?? '',
      peopleCategory: PeoplesById?.peopleCategory ?? '',
      accountId: PeoplesById?.accountId ?? null,
      prepaymentAccountId: PeoplesById?.prepaymentAccountId ?? null,
    },
    enableReinitialize: true,
    validationSchema: NtxPeopleSchema(t),
    onSubmit: (values) => {
      const requestBody = {
        id: values.id,
        name: values.name,
        name2: values.name2,
        personId: values.personId || null,
        typeCode: values.typeCode,
        customerNumber: values.customerNumber,
        parentId: values.parentId || null,
        priceListId: values.priceListId || null,
        shipToLocationId: values.shipToLocationId || null,
        billToLocationId: values.billToLocationId || null,
        termsId: values.termsId || null,
        setOfBooksId: values.setOfBooksId || null,
        creditLimit: values.creditLimit,
        paymentPriority: values.paymentPriority || null,
        invoiceCurrencyCode: values.invoiceCurrencyCode || null,
        paymentCurrencyCode: values.paymentCurrencyCode,
        invoiceLimit: values.invoiceLimit || null,
        paymentMethodLookupCode: values.paymentMethodLookupCode,
        bankAccountName: values.bankAccountName,
        bankAccountNum: values.bankAccountNum,
        bankNumber: values.bankNumber,
        bankAccountType: values.bankAccountType,
        vatCode: values.vatCode,
        tradingName: values.tradingName,
        workReference: values.workReference,
        companyRegistrationNumber: values.companyRegistrationNumber,
        nationalInsuranceNumber: values.nationalInsuranceNumber,
        verificationNumber: values.verificationNumber,
        email: values.email,
        phone: values.phone,
        fax: values.fax,
        mobile: values.mobile,
        address: values.address,
        description: values.description,
        extraInformationId: null,
        integrationField: null,
        peopleType: values.peopleType,
        peopleCategory: values.peopleCategory,
        accountId: values.accountId || null,
        prepaymentAccountId: values.prepaymentAccountId ?? null,
        //
      };

      const actionToDispatch = isUpdate ? UpdatNtxPeoples(requestBody) : AddNtxPeoples(requestBody);

      dispatch(actionToDispatch)
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            if (isUpdate) {
              CustomAlert({ action: 'Edit' });
              dispatch(GetNtxPeoplesList(typeCode));
            } else {
              document.getElementById('closeModalForm')?.click();
              formik.resetForm();
              dispatch(GetNtxPeoplesList(typeCode));
              CustomAlert({ action: 'Add' });
            }
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <Loading loading={loading} error={error}>
      {(() => {
        switch (typeCode) {
          case 'SUPPLIERS':
            return <SupplierForm formik={formik} />;
          case 'CUSTOMERS':
            return <CustomerForm formik={formik} />;
          default:
            return null;
        }
      })()}
    </Loading>
  );
};

export default NtxPeoples2;
