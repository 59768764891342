import React, { useEffect } from 'react';
import { Card, CardBody } from '../../../../../components/card/card';
import Loading from '../../../../../components/Shared/Loader/Loading';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getApInvoicesById } from '../../../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';
import { useTranslation } from 'react-i18next';
import Lines from '../Lines';
import InvoicePayment from '../InvoicePayment';
import HeaderInvoice from './HeaderInvoice';
import ApInvoiceLines from '../../ApInvoices/ApInvoiceLines';

const ViewInvoice = () => {
  const { invoicesId } = useParams();
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (invoicesId) {
      dispatch(getApInvoicesById(invoicesId));
    }
  }, [dispatch, invoicesId]);

  const { ApInvoicesById: data } = useAppSelector((a) => a.ApInvoices);

  return (
    <div>
      <Card>
        <Loading loading={false} error={null}>
          <CardBody  >

             <HeaderInvoice data={data} />
            <ul className="nav nav-tabs nav-tabs-v2 p-0">
              <li className="nav-item me-3 ">
                <Link to="#InvoiceDetailes" className="nav-link active  " data-bs-toggle="tab">
                  <i className="fas fa-file-alt me-2 text-theme fs-5"></i>
                  بنود الفاتورة
                </Link>
              </li>
              <li className="nav-item me-3 ">
                <Link to="#InvoicePayment" className="nav-link  " data-bs-toggle="tab">
                  <i className="fas fa-undo me-2 text-theme fs-5"></i>
                  سداد لفاتورة
                </Link>
              </li>
            </ul>

            <div className="tab-content pt-3">
              <div className="tab-pane fade show active" id="InvoiceDetailes">

                 
                {Object.keys(data)?.length > 0 && <Lines data={data.apInvoiceLinesList} />}
              </div>

              <div className="tab-pane fade" id="InvoicePayment">
                {Object.keys(data)?.length > 0 && (
                  <InvoicePayment PaymentSchedulesList={data.apPaymentSchedulesList} />
                )}
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default ViewInvoice;
