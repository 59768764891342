import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAssetsList } from '../../../store/Finance/FixedAssets/Assets/assetsSlice';
import { useTranslation } from 'react-i18next';

const useGetAssetsList = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (AssetsList?.length === 0) {
      dispatch(getAssetsList());
    }
  }, []);
  const { AssetsList, loading, error } = useAppSelector((state) => state.Assets);

  var OptionsAssets = AssetsList?.map(({ id, name, name2 }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2}`,
  }));

  return { AssetsList, OptionsAssets, loading, error };
};

export default useGetAssetsList;
