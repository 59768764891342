import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../store/hooks";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { Form } from "react-bootstrap";
 import { UpdatGlJeCategory , GetGlJeCategoryList } from "../../../../store/Finance/JeCategory/categorySlice";
import useGetGlJeCategoryList from "../../../../hooks/Finance/useGetGlJeCategoryList";
import { CategoryModels } from "../../../../store/Finance/JeCategory/CategoryModels";

interface props {
  ID?: string;
}

const EditJeCategory: React.FC<props> = ({ ID }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { GlJeCategoryList } = useGetGlJeCategoryList()
  const [ JeCategoryById , setJeCategoryById ] = useState<CategoryModels>()

  useEffect(()=> {
    var dataById = GlJeCategoryList?.find((f) => f.id === ID);
    setJeCategoryById(dataById)
 
  },[GlJeCategoryList, ID])
 
 
 

  const formik = useFormik({
    initialValues: {
      id: JeCategoryById ? JeCategoryById.id : "",
      name2: JeCategoryById ? JeCategoryById.name2 : "",
      name: JeCategoryById ? JeCategoryById.name : "",
      enabledFlag: JeCategoryById ? JeCategoryById.enabledFlag : false,

    },
    enableReinitialize:true ,
    //   validationSchema: AddEquipmentsModelFLeetSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdatGlJeCategory({
          id : values.id , 
          name: values.name,
          name2: values.name2,
          enabledFlag: values.enabledFlag,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Edit" });
            document.getElementById("closeModalForm")?.click();
            formik.resetForm();
            dispatch(GetGlJeCategoryList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
      <div className="row">
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t("LocalName")}</label>
              <Form.Control
                className="form-control text-center"
                type="text" 
autoComplete='off'
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
            </div>
          </div>

          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t("GlobalName")}</label>
              <Form.Control
                className="form-control text-center"
                type="text" 
autoComplete='off'
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">{t("Active")}</label>
              <Form.Check
                style={{ margin: "10px 20px" }}
                type="switch"
                id="custom-switch"
                name="enabledFlag"
                // value={formik.values.enabledFlag}
                checked={formik.values.enabledFlag}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditJeCategory;
