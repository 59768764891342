import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const reportsMenu = [
  {
    System: 'REPORT',
    isActive: false,
    menu: [
      { is_header: true, title: 'إدارة التقارير وقوالب الطباعة', isActive: true }, // عنوان النظام العام

      {
        path: 'SystemDashboard',
        icon: 'fas fa-home', // أيقونة الصفحة الرئيسية
        title: 'HomePage',
        isActive: true,
      },

      {
        path: '/RPT/Reports',
        icon: 'fas fa-chart-line', // أيقونة إدارة التقارير
        title: 'Reports_Management',
        isActive: HasPermission('NtxViewReportslist'),
      },
      {
        path: '/NtxDashboardManagement',
        icon: 'fas fa-tachometer-alt', // أيقونة إدارة لوحة البيانات
        title: 'إدارة لوحة البيانات',
        isActive: true,
      },

      {
        path: '/RPT/PrintingForms',
        icon: 'fas fa-print', // أيقونة إدارة قوالب الطباعة
        title: 'إدارة قوالب الطباعة',
        isActive: true,
      },

      // {
      //   path: '/RPT/PrintingForms/Notes',
      //   icon: 'fas fa-print', // أيقونة إدارة قوالب الطباعة
      //   title: 'تعليمات',
      //   isActive: true,
      // },




      // { is_header: true, title: 'GeneralSystem', isActive: true },
      // {
      //   path: 'dashboard',
      //   icon: 'fas fa-home',
      //   title: 'HomePage',
      // },

      // {
      //   path: '/RPT',
      //   icon: 'bi bi-clipboard2-data-fill',
      //   title: 'التقارير وقوالب الطباعة',
      //   children: [
      //     {
      //       path: 'RPT/Reports',
      //       title: 'Reports_Management',
      //       // isActive:false,
      //       isActive: HasPermission('NtxViewReportslist'),
      //     },
      //     {
      //       path: 'RPT/PrintingForms',
      //       title: 'إدارة قوالب الطباعة',
      //       // isActive:false,
      //       isActive: true,
      //     },
      //   ],
      // },

      // {
      //   path: '/NtxDashboardManagement',
      //   icon: 'bi bi-speedometer2',
      //   title: 'إدارة لوحة البيانات',
      //   // isActive:false,
      //   isActive: true,
      // },
    ],
  },
];

export default reportsMenu;
