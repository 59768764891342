import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { getOrgClass } from "../../store/Lookup/lookupSlice";
import { useTranslation } from "react-i18next";
 


const useLookupOrgClass = ()=>{
  const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    
    useEffect(()=>{
        dispatch(getOrgClass())
      },[dispatch])
    const { ORG_CLASS } = useSelector((state) => state.Lookup); 

    const dataORGCLASS =
    ORG_CLASS &&
      ORG_CLASS?.map((item, idx) =>
        item.list?.map((list) => (
          {
             value: list.lookupCode,
              label: i18n.language === "ar" ? list.meaning2 : list.meaning
          }
        ))
      ).flat()


    return { ORG_CLASS , dataORGCLASS } ;
};

export default useLookupOrgClass ;