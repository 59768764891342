import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';

import useGetTransactionTypesList from '../../../../hooks/INV/TransactionTypes/useGetTransactionTypesList';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useNavigate } from 'react-router-dom';

 
const Index = () => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('');
  const Navigate = useNavigate();

  const { TransactionTypesList, loading, error } = useGetTransactionTypesList();

  const filteredSearch = TransactionTypesList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return item.description?.toLowerCase().includes(searchLowerCase);
    })
    ?.filter((f) => (selectedValue === '' ? true : f.category === selectedValue))
    ?.sort((a, b) => {
      // Cast code to string to avoid TypeScript error
      const codeA = a.code as string;
      const codeB = b.code as string;

      // Sort MII and MIR first
      if (codeA === 'MII' || codeA === 'MIR') return -1;
      if (codeB === 'MII' || codeB === 'MIR') return 1;
      return 0; // Keep the rest unchanged
    });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //_________________________________________
  const categoryMap: any = {
    '': { en: 'All', ar: 'الكل' },
    Issuing: { en: 'Issuing', ar: 'صرف' },
    shipment: { en: 'Shipment', ar: 'شحن' },
    Receive: { en: 'Receive', ar: 'استلام' },
    Move: { en: 'Move', ar: 'تحويل' },
    Allocate: { en: 'Allocate', ar: 'تخصيص' },
  };

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: ' أنواع الحركات المخزنية',
      link: null,
    },
  ];

  //___________________________________________________________________________
 
  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName=" أنواع الحركات المخزنية" />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody className='p-4'>
            <div className="row mb-3">
              <div className="col-lg-6 mb-2">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('Search')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px m-0"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text  fw-bold">{t('Type')}</span>

                  <select
                    className="form-select p-0 w-100 fw-bold text-center"
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.target.value)}>
                    <option className="dropdown-item" value={''}>
                      {i18n.language === 'en' ? 'All' : 'الكل'}
                    </option>
                    <option className="dropdown-item" value={'Issuing'}>
                      {i18n.language === 'en' ? 'Issuing' : 'صرف'}
                    </option>
                    <option className="dropdown-item" value={'shipment'}>
                      {i18n.language === 'en' ? 'Shipment' : 'شحن'}
                    </option>
                    <option className="dropdown-item" value={'Receive'}>
                      {i18n.language === 'en' ? 'Receive' : 'استلام'}
                    </option>
                    <option className="dropdown-item" value={'Move'}>
                      {i18n.language === 'en' ? 'Move' : 'تحويل'}
                    </option>
                    <option className="dropdown-item" value={'Allocate'}>
                      {i18n.language === 'en' ? 'Allocate' : 'تخصيص'}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-hover">
                <thead className="fw-bold text-theme">
                  <tr>
                    <th className="fw-bold text-theme">{t('Name')}</th>
                    <th className="fw-bold text-theme">{t('الوصف')}</th>
                    <th className="fw-bold text-theme">{t('Type')}</th>
                    <th className="fw-bold text-theme">{t('Code')}</th>
                    <th className="fw-bold text-theme"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={++index}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.name : item.name2}
                      </td>
                      <td className="align-middle">{item.description}</td>
                      <td className="align-middle">
                        {
                          categoryMap[item.category]
                            ? i18n.language === 'en'
                              ? categoryMap[item.category].en
                              : categoryMap[item.category].ar
                            : item.category // fallback if category is not in the map
                        }
                      </td>
                      <td className="align-middle">{item.code}</td>

                      <td className="align-middle ">
                        {['MII', 'MIR'].includes(item.code) && (
                          <button
                            type="button"
                            className="btn btn-outline-theme w-100"
                            // data-bs-toggle="modal"
                            // data-bs-target="#Reasons"
                            onClick={() => Navigate(`${item.id}/TypeReasons`)}>
                            <i className="fas fa-list-alt fa-lg fa-fw me-3"></i>
                            {t('الأنواع')}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        </Loading>

       </Card>

      {/* <ModalComponent id="Reasons" title="Add Reasons">
        <Reasons />
      </ModalComponent> */}
    </>
  );
};

export default Index;
