import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import {
  AddBankAccountsSchema,
  AddCashBoxesSchema,
} from '../../../ValidationForm/validationSchema';
import {
  getApBankAccountsList,
  getApBankAccountsById,
  updateApBankAccounts,
} from '../../../../store/Finance/AP/ApBankAccounts/ApBankAccountsSlice';
import Loading from '../../../../components/Shared/Loader/Loading';

interface props {
  ID?: string;
}

const Update: React.FC<props> = ({ ID }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { OptionsAccounts, loading, error } = useGetAccounts();

  useEffect(() => {
    if (ID) {
      dispatch(getApBankAccountsById(ID));
    }
  }, []);
  const { ApBankAccountsById: data } = useAppSelector((a) => a.ApBankAccounts);

  const formik = useFormik({
    initialValues: {
      ntxBankId: data.ntxBankId ?? '',
      name: data.name ?? '',
      name2: data.name2 ?? '',
      description: data.description ?? '',
      bankAccount: data.bankAccount ?? '',
      accountId: data.accountId ?? '',
      iban: data.iban ?? '',
    },
    enableReinitialize: true,
    validationSchema: AddCashBoxesSchema(t),
    onSubmit: (values) => {
      dispatch(
        updateApBankAccounts({
          id: data.id,
          name: values.name,
          name2: values.name2,
          ntxBankId: values.ntxBankId,
          bankAccount: values.bankAccount,
          iban: values.iban,
          description: values.description,

          accountId: values.accountId,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            document.getElementById('closeModalForm')?.click();
            dispatch(getApBankAccountsList());
            formik.resetForm();
          } else {
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          CustomAlert({ action: 'Error' });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });
  const { values, errors, touched } = formik;

  return (
    <Loading loading={loading} error={error}>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control  "
                type="text" 
autoComplete='off'
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <div className="text-danger small">{touched.name2 && errors.name2}</div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control  "
                type="text" 
autoComplete='off'
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <div className="text-danger small">{touched.name && errors.name}</div>
            </div>
          </div>

          <div className="col-xl-6 ">
            <div className="form-group mb-3">
              <label className="form-label">{t('Accounts')}</label>
              <Select
                classNamePrefix="react-select"
                className={`w-100 ${
                  !!(formik.touched.accountId && formik.errors.accountId)
                    ? 'border border-danger'
                    : ''
                } `}
                isLoading={loading}
                isSearchable={true}
                isClearable
                name="accountId"
                options={OptionsAccounts}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                onChange={(option: any) => {
                  formik.setFieldValue('accountId', option === null ? null : option.value);
                }}
                value={OptionsAccounts?.find((f) => f.value === formik.values.accountId)}
              />
              <div className="text-danger small">{touched.accountId && errors.accountId}</div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                className="form-control  "
                type="text" 
autoComplete='off'
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                isInvalid={!!(formik.touched.description && formik.errors.description)}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </Loading>
  );
};

export default Update;
