import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';

// جلب الكمية المصروفة او المضافة حسب المرجع ..
export const fetchDispensedQuantities = createAsyncThunk(
  'Inv/fetchDispensedQuantities',
  async (referenceId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `Inv/GetTransactionsListCountByReferenceId?ReferenceId=${referenceId}`
      );

      if (data?.succeeded) {
        const quantities: any = {};
        data.data.forEach((item: any) => {
          quantities[item.transactionItemId] = item.quantity;
        });
        return quantities;
      } else {
        return {}; // return an empty object if not successful
      }
    } catch (error) {
      console.error('Error fetching dispensed quantity:', error);
      return rejectWithValue('Failed to fetch dispensed quantities');
    }
  }
);

// جلب البيانات حسب المرجع ..
export const fetchDataByReferenceId = createAsyncThunk(
  'Inv/fetchDataByReferenceId',
  async (
    { referenceId, code }: { referenceId: string; code: string },
    { dispatch, rejectWithValue }
  ) => {
    const urls: Record<string, string> = {
      INV: `SalesInvoice/GetTransactionsInvoiceById?Id=${referenceId}`,
      SOI: `SalesOrderSalse/GetTransactionsOrderSalseById?Id=${referenceId}`,
      IOI: `InvMove/GetMoveTransactionsById?Id=${referenceId}`,
      SPR: `PurchaseBill/GetTransactionsById?Id=${referenceId}`,
      CRT: `Sals/GetTransactionsById?Id=${referenceId}`,
      BIL: `PurchaseBill/GetById?Id=${referenceId}`,
      POR: `PurchaseOrder/GetById?Id=${referenceId}`,
      IOR: `InvIssue/GetIssueTransactionsById?Id=${referenceId}`,
    };

    try {
      const dispensedQuantities = await dispatch(fetchDispensedQuantities(referenceId)).unwrap();
      // debugger
      const { data } = await ntxAPI.get(urls[code]);

      if (data?.succeeded) {
        const itemsList =
          data?.data?.transactionsLinesList || data?.data?.materialTransactionsLineList || [];

        // Create result with the available quantities
        const result = itemsList
          .map((m: any) => {
            const realQty = m.transactionQuantityIn || m.qty;
            const dispensedQuantity =
              dispensedQuantities[m.itemId] || dispensedQuantities[m.transactionItemId] || 0;
            const qtyAvailable = realQty - dispensedQuantity;

            return {
              transactionItemId: m.transactionItemId || m.itemId,
              transactionUom: m.transactionUom || m.uom,
              transactionQuantityIn: qtyAvailable,
              unitPrice: m.price,
              referenceIdMaster: data?.data.id,
              referenceIdLine: m.id,

              RealQty: realQty,
              dispensedQuantity: dispensedQuantity,
              QtyAvilable: qtyAvailable,
            };
          })
          .filter((f: any) => f.transactionItemId !== null); // فلترة الأصناف الغير مخزنية لعدم ظهورها

        return { result, data };
      }
      return {};
    } catch (error) {
      console.error('Error fetching data by reference ID:', error);
      return rejectWithValue('Failed to fetch data');
    }
  }
);

const initialState: {
  loading: boolean;
  error: any | null;
  itemsList: [];
} = {
  itemsList: [],
  loading: false,
  error: null,
};

const invSlice = createSlice({
  name: 'Inv',
  initialState,
  reducers: {
    cleanUpItemsList: (state) => {
      state.itemsList = []; // Resetting the itemsList to an empty array
    },
  },
  extraReducers(builder) {
    builder

      .addCase(fetchDataByReferenceId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDataByReferenceId.fulfilled, (state, action) => {
        state.loading = false;
        // debugger
        state.itemsList = action.payload.result;
      })
      .addCase(fetchDataByReferenceId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { cleanUpItemsList } = invSlice.actions;

export default invSlice.reducer;
