import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
// import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import { TransactionsLinesList } from '../../../../store/Sales/SalesInvoices/SalesInvoicesModels';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { GetItemConversionList } from '../../../../store/INV/MasterItems/ItemConversionSlice';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import useGetItemConversionList from '../../../../hooks/INV/ItemConversion/useGetItemConversionList';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';

interface LinesProps {
  formik: FormikProps<any>;
  // isUpdate?: boolean;
}

const TransactionsLines2: React.FC<LinesProps> = ({ formik }) => {
  //________________________________________________________________
  const { UnitsOfMeasureList: UomList } = useGetUnitsOfMeasureList();

  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsList();
  const dispatch = useAppDispatch();

  const [Lines, setLines] = useState<TransactionsLinesList[]>(
    formik?.values.transactionsLinesList || []
  );

  useEffect(() => {
    setLines(formik?.values.transactionsLinesList);
  }, [formik?.values.transactionsLinesList]);

  const { ItemConversionList, loading: loading2,  } = useGetItemConversionList();

  const handleLineChange = (index: number, field: string, value: any) => {
    if (field === 'itemId') {
      const updatedLines = [...Lines];

      let price = ItemsOptions?.find((f) => f.value === value)?.salesPrice;
      let total = Number(updatedLines[index].qty) * Number(price);
      updatedLines[index] = {
        ...updatedLines[index],
        itemId: value,
        price: Number(price),
        total,
      };

      setLines(updatedLines);
      formik?.setFieldValue('transactionsLinesList', updatedLines);

      return;
    }

    const updatedLines = [...Lines];
    updatedLines[index] = {
      ...updatedLines[index],
      [field]: value,
    };

    // Recalculate total and VAT if needed
    if (field === 'qty' || field === 'price' || field === 'vatRate') {
      const total = Number(updatedLines[index].qty) * Number(updatedLines[index].price);
      const vatAmount = (total * Number(updatedLines[index].vatRate)) / 100;
      updatedLines[index] = {
        ...updatedLines[index],
        total,
        vatAmount,
      };
    }

    setLines(updatedLines);
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine: TransactionsLinesList = {
      itemId: null,
      lineNumber: Lines?.length + 1,
      uom: '',
      price: 0,
      qty: 1,
      discountRate: 0,
      discountAmount: 0,
      vatRate: 15,
      total: 0,
      vatAmount: 0,
      description: '',
      id: null, //
      inventoryId: '', //
    };
    setLines([...Lines, newLine]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = Lines?.filter((_, i) => i !== index);
    setLines(updatedLines);
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = Lines.reduce((sum, line) => sum + line.total, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  const GetUom = (line: any) => {
    const uomIdForItem = ItemsOptions?.find((f) => f.value === line?.itemId)?.uom;

    // تصفية العناصر من قائمة التحويلات بناءً على itemId
    const filteredList = ItemConversionList?.filter((f) => f.itemId === line.itemId);

    // العثور على الوحدة المقابلة من قائمة UomList بناءً على uomId
    const filteredList2 = UomList?.find((f) => f.id === uomIdForItem);

    // دمج القائمتين في قائمة ثالثة
    const combinedList = [...filteredList];

    // إذا كانت filteredList2 موجودة وغير موجودة في filteredList، يتم إضافتها إلى القائمة
    if (filteredList2 && !filteredList.some((f) => f.uom === filteredList2.id)) {
      combinedList.push({
        id: filteredList2.id,
        itemId: line.itemId,
        uom: filteredList2.id,
        conversionValue: 1, // أو أي قيمة افتراضية مناسبة للتحويل
        barcode: '',
        uomName: filteredList2.name,
        uomName2: filteredList2.name2,
      });
    }

    return combinedList;
  };

  return (
    <>
      <Loading loading={loading || loading2} error={error }>
        <div className="mb-3">
          <div className="row">
            <div className="col-3">
              <div className="mb-1 rounded-3 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-around">
                  <div className="fw-bold">{t('Total')} :</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
                  {/* <i className="bi bi-plus-square-dotted fa-lg me-2"></i> */}
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          {Lines?.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  formik.errors.transactionsLinesList ? 'border border-2 border-danger' : ''
                } `}>
                <table className="table table-borderless  table-hover mb-0 ">
                  <thead className="bg-theme bg-opacity-30">
                    <tr>
                      <th className="align-middle" style={{ width: '2%' }}>
                        #
                      </th>
                      <th className="align-middle" style={{ width: '30%' }}>
                        {t('اسم الصنف')}
                      </th>
                      <th className="align-middle" style={{ width: '15%' }}>
                        {t('الوحدة')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('الكمية')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('السعر')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('نسبة الخصم')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('قيمة الخصم')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('VAT %')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('VAT')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('Total')}
                      </th>
                      <th className="align-middle" style={{ width: '18%' }}>
                        {t('الوصف')}
                      </th>
                      <th className="align-middle text-end" style={{ width: '2%' }}></th>
                    </tr>
                  </thead>
                  <tbody className="fw-bold">
                    {Lines.slice()
                      .reverse()
                      ?.map((line, index) => {
                        const Index = Lines?.length - 1 - index;

                        return (
                          <tr key={index}>
                            <td className="align-middle bg-theme bg-opacity-30 small">
                              {Lines?.length - index}
                            </td>
                            <td className="align-middle small">
                              <Select
                                classNamePrefix="react-select"
                                className="w-100"
                                isSearchable
                                isClearable
                                // options={ItemsOptions}
                                options={ItemsOptions?.filter(
                                  (f) => !Lines.some((line) => line.itemId === f.value)
                                )}
                                onChange={(option) =>
                                  handleLineChange(
                                    Index,
                                    'itemId',
                                    option === null ? null : option.value
                                  )
                                }
                                value={
                                  line.itemId === null
                                    ? null
                                    : ItemsOptions?.find((option) => option.value === line.itemId)
                                }
                                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                }}
                                menuPlacement='top'
                              />
                            </td>
                            <td className="align-middle small">
                              <select
                                className="form-select text-center"
                                value={line.uom || ''}
                                onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>
                                {GetUom(line)?.map((item, idx) => (
                                  <option key={++idx} value={item.uom}>
                                    {i18n.language === 'en' ? item.uomName : item.uomName2}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td className="align-middle small">
                              <input
                                type="text" 
autoComplete='off'
                                className="form-control text-center px-0"
                                value={line.qty}
                                onChange={(e) =>
                                  handleLineChange(Index, 'qty', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>
                            <td className="align-middle small">
                              <input
                                type="text" 
autoComplete='off'
                                className="form-control text-center px-0"
                                // size={line.price.toString()?.length || 1}
                                value={line.price}
                                onChange={(e) =>
                                  handleLineChange(Index, 'price', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>
                            <td className="align-middle small">
                              <input
                                type="text" 
autoComplete='off'
                                className="form-control text-center px-0"
                                value={line.discountRate}
                                onChange={(e) =>
                                  handleLineChange(Index, 'discountRate', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>
                            <td className="align-middle small">{line.discountAmount}</td>
                            <td className="align-middle small">
                              <input
                                type="text" 
autoComplete='off'
                                className="form-control text-center px-0"
                                value={line.vatRate}
                                onChange={(e) =>
                                  handleLineChange(Index, 'vatRate', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>
                            <td className="align-middle small">{line.vatAmount}</td>
                            <td className="align-middle small">{line.total}</td>
                            <td className="align-middle small">
                              <Form.Control
                                className="form-control"
                                as="textarea"
                                rows={1}
                                value={line.description}
                                onChange={(e) =>
                                  handleLineChange(Index, 'description', e.target.value)
                                }
                              />
                            </td>
                            <td className="align-middle small">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleDeleteLine(Index)}>
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Loading>
    </>
  );
};

export default TransactionsLines2;
