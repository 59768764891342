import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  GetTransactionTypeReasonsByTransactionTypeId,
  cleanUpTypeReasons,
} from '../../../store/INV/TransactionTypes/TransactionTypesSlice';
import { useTranslation } from 'react-i18next';

const useGetTypeReasonsByTransactionTypeId = (TransactionTypeId: string | undefined) => {
  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();

  useEffect(() => {
    if (TransactionTypeId) {
      dispatch(GetTransactionTypeReasonsByTransactionTypeId(TransactionTypeId));
    }

    return () => {
      dispatch(cleanUpTypeReasons());
    };
  }, [TransactionTypeId, dispatch]);
  const { TypeReasonsByTransactionTypeId, loading, error } = useAppSelector(
    (state) => state.TransactionTypes_Inv
  );

  return { TypeReasonsByTransactionTypeId, error, loading };
};

export default useGetTypeReasonsByTransactionTypeId;
