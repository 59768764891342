import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getJournalsByReferenceId } from '../../../../store/Finance/Journals/JournalsSlice';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Lines from './Lines';
import { Icon } from '@iconify/react';
import Loading from '../../../../components/Shared/Loader/Loading';

interface Props {
  referenceId: string;
}
const JournalsByReference = ({ referenceId }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getJournalsByReferenceId({
        ReferenceId: referenceId,
        JeCategory: '',
        JeSource: '',
      })
    );
  }, [dispatch, referenceId]);
  const { JournalsByReferenceId: data, loading, error } = useAppSelector((s) => s.Journals);

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('الأستاذ')}</label>
                <Form.Control
                  className="form-control text-center fw-bold"
                  value={i18n.language === 'en' ? data[0]?.ledgerName : data[0]?.ledgerName2 || ''}
                  readOnly
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('AccountingPeriod')}</label>
                <Form.Control
                  className="form-control text-center fw-bold"
                  value={i18n.language === 'en' ? data[0]?.periodName : data[0]?.periodName2 || ''}
                  readOnly
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('Date')}</label>
                <Form.Control
                  className="form-control fw-bold text-center"
                  value={data[0]?.glDate || ''}
                  readOnly
                />
              </div>
            </div>

            <div className="col-lg-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('Source')}</label>
                <Form.Control
                  className="form-control fw-bold text-center"
                  value={
                    i18n.language === 'en' ? data[0]?.jeSourceName : data[0]?.jeSourceName2 || ''
                  }
                  readOnly
                />
              </div>
            </div>

            <div className="col-lg-2 m-auto">
              <div className="form-group mb-3">
                <label className="form-label">{t('Currency')}</label>
                <Form.Control
                  className="form-control fw-bold text-center"
                  value={data[0]?.currencyCode}
                  readOnly
                />
              </div>
            </div>
          </div>
          <Accordion defaultActiveKey="0">
            {data?.map((journal, index) => (
              <Accordion.Item eventKey={index.toString()} key={journal.id || index}>
                <Accordion.Header>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Icon
                        icon="healthicons:finance-dept"
                        className="me-2 text-theme"
                        width="30"
                        height="30"
                      />
                      {i18n.language === 'en' ? journal.name : journal.name2}
                    </div>
                    ء
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {/* <div className="row mb-4">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الأستاذ')}</label>
                    <Form.Control
                      className="form-control text-center fw-bold"
                      value={
                        i18n.language === 'en' ? journal.ledgerName : journal.ledgerName2 || ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('AccountingPeriod')}</label>
                    <Form.Control
                      className="form-control text-center fw-bold"
                      value={
                        i18n.language === 'en' ? journal.periodName : journal.periodName2 || ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Date')}</label>
                    <Form.Control
                      className="form-control fw-bold text-center"
                      value={journal.glDate || ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Category')}</label>
                    <Form.Control
                      className="form-control fw-bold text-center"
                      value={
                        i18n.language === 'en' ? journal.jeCategoryName : journal.jeCategoryName2
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Source')}</label>
                    <Form.Control
                      className="form-control fw-bold text-center"
                      value={
                        i18n.language === 'en' ? journal.jeSourceName : journal.jeSourceName2 || ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-2 m-auto">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Currency')}</label>
                    <Form.Control
                      className="form-control fw-bold text-center"
                      value={journal.currencyCode}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-7 m-auto">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Note')}</label>
                    <Form.Control
                      className="form-control fw-bold text-center"
                      value={journal.description || ''}
                      readOnly
                    />
                  </div>
                </div>
              </div> */}

                  <Lines
                    JournalLine={journal.glJeLinesList}
                    CategoryName={
                      i18n.language === 'en' ? journal?.jeCategoryName : journal?.jeCategoryName2
                    }
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </>
      </Loading>
    </>
  );
};

export default JournalsByReference;

//    {/* <div className="row">
//         <div className="col-lg-3">
//           <div className="form-group mb-3">
//             <label className="form-label">{t('الأستاذ')}</label>
//             <Form.Control
//               className="form-control text-center fw-bold"
//               value={i18n.language === 'en' ? data[0]?.ledgerName : data[0]?.ledgerName2 || ''}
//               readOnly
//             />
//           </div>
//         </div>
//         <div className="col-lg-3">
//           <div className="form-group mb-3">
//             <label className="form-label">{t('AccountingPeriod')}</label>
//             <Form.Control
//               className="form-control text-center fw-bold"
//               value={i18n.language === 'en' ? data[0]?.periodName : data[0]?.periodName2 || ''}
//               readOnly
//             />
//           </div>
//         </div>
//         <div className="col-lg-3">
//           <div className="form-group mb-3">
//             <label className="form-label">{t('Date')}</label>
//             <Form.Control
//               className="form-control fw-bold text-center"
//               value={data[0]?.glDate || ''}
//               readOnly
//             />
//           </div>
//         </div>

//         <div className="col-lg-3">
//           <div className="form-group mb-3">
//             <label className="form-label">{t('Category')}</label>
//             <Form.Control
//               className="form-control fw-bold text-center"
//               value={i18n.language === 'en' ? data[0]?.jeCategoryName : data[0]?.jeCategoryName2}
//               readOnly
//             />
//           </div>
//         </div>
//         <div className="col-lg-3">
//           <div className="form-group mb-3">
//             <label className="form-label">{t('Source')}</label>
//             <Form.Control
//               className="form-control fw-bold text-center"
//               value={i18n.language === 'en' ? data[0]?.jeSourceName : data[0]?.jeSourceName2 || ''}
//               readOnly
//             />
//           </div>
//         </div>

//         <div className="col-lg-2 m-auto">
//           <div className="form-group mb-3">
//             <label className="form-label">{t('Currency')}</label>
//             <Form.Control
//               className="form-control fw-bold text-center"
//               value={data[0]?.currencyCode}
//               readOnly
//             />
//           </div>
//         </div>
//         <div className="col-lg-7 m-auto">
//           <div className="form-group mb-3">
//             <label className="form-label">{t('Note')}</label>
//             <Form.Control
//               className="form-control fw-bold text-center"
//               value={data[0]?.description || ''}
//               readOnly
//             />
//           </div>
//         </div>
//       </div>

//       <Lines JournalLine={data[0]?.glJeLinesList} /> */}
