import React, { useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../../components/card/card';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';

import useGetTransactionTypesList from '../../../../../hooks/INV/TransactionTypes/useGetTransactionTypesList';
import Loading from '../../../../../components/Shared/Loader/Loading';
 import { Link, useParams } from 'react-router-dom';
import AddReason from './AddReason';
import useGetTypeReasonsByTransactionTypeId from '../../../../../hooks/INV/TransactionTypes/useGetTypeReasonsByTransactionTypeId';
import MedModalComponent from '../../../../../components/Modal/MedModalComponent';
import { TypeReasonsModels } from '../../../../../store/INV/TransactionTypes/TransactionTypesModels';
import UpdateReason from './UpdateReason';
// import Reasons from './Reasons';

// MIR : "fc437ea7-caf1-46ad-85b3-9dea0c5accdb" صرف
// MII : "24e295ae-15d7-4f85-ad43-e7cfa4f8e9ad" اضافة

const TypeReasons = () => {
  const { transactionType } = useParams();
  const [details, setDetails] = useState<TypeReasonsModels>();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const { TransactionTypesList, loading, error } = useGetTransactionTypesList();
  const transaction = TransactionTypesList.find((f) => f.id === transactionType);
  const { TypeReasonsByTransactionTypeId: data } =
    useGetTypeReasonsByTransactionTypeId(transactionType);
  const filteredSearch = data?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item.description?.toLowerCase().includes(searchLowerCase);
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: ' أنواع الحركات المخزنية',
      link: '/INV/InventorySettings/TransactionTypes',
    },
    {
      name: transaction?.description,
      link: null,
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName="Type Reasons"
          subtitle={transaction?.description}
        />
        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#AddReasons"
            // onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('اضافة')}
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="row ">
              <div className="col-lg-6 mb-2">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('Search')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px m-0"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-hover">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('Name')}</th>
                    <th scope="col">{t('الوصف')}</th>
                    <th scope="col">{t('Type')}</th>
                    <th scope="col">{t('الحساب')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={++index}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.name : item.name2}
                      </td>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.description : item.description2}
                      </td>

                      <td className="align-middle">
                        {i18n.language === 'en'
                          ? item.transactionTypeName
                          : item.transactionTypeName2}
                      </td>
                      <td className="align-middle">{item.accountId} </td>

                      <td className="align-middle text-end">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#UpdateReasons"
                            onClick={() => setDetails(item)}>
                            <i className="far fa-lg me-2 fa-fw fa-edit"></i>
                            {t('Button.Edit')}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent id="AddReasons" title="Add Reasons">
        <AddReason
          type={transactionType === '24e295ae-15d7-4f85-ad43-e7cfa4f8e9ad' ? 'Issue' : 'receive'}
        />
      </MedModalComponent>

      <MedModalComponent id="UpdateReasons" title="Update Reasons">
        {details && <UpdateReason data={details} />}
      </MedModalComponent>
    </>
  );
};

export default TypeReasons;
