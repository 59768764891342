// import EditUser from '../../pages/NTX/Security/Users/EditUser.js';
// import Users from '../../pages/NTX/Security/Users/Users.js';
// import PermissionUser from '../../pages/NTX/Security/Users/PermissionUser';
// import AuthRules from '../../pages/NTX/Security/Rules/Index';
// import PermissionRule from '../../pages/NTX/Security/Rules/PermissionRule';
// import RulesUser from '../../pages/NTX/Security/RulesUser/RulesUser';
// import ChangePassword from '../../pages/NTX/Security/Users/ChangePassword';

// const securityRoutes = [
//   {
//     path: 'Security/*',
//     children: [
//       { path: 'Users', element: <Users /> },
//       { path: 'Users/:userId/Edit', element: <EditUser /> },
//       {
//         path: 'Users/ChangePassword',
//         element: <ChangePassword />,
//       },
//       {
//         path: 'Users/:userId/RulesUser',
//         element: <RulesUser />,
//       },
//       {
//         path: 'Users/:refrenceId/PermissionUser',
//         element: <PermissionUser />,
//       },
//       {
//         path: 'AuthRules/:refrenceId/PermissionRule',
//         element: <PermissionRule />,
//       },
//       {
//         path: 'AuthRules',
//         element: <AuthRules />,
//       },
//     ],
//   },
// ];

// export default securityRoutes;



import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';

// Lazy loading components
const EditUser = lazy(() => import('../../pages/NTX/Security/Users/EditUser'));
const Users = lazy(() => import('../../pages/NTX/Security/Users/Users'));
const PermissionUser = lazy(() => import('../../pages/NTX/Security/Users/PermissionUser'));
const AuthRules = lazy(() => import('../../pages/NTX/Security/Rules/Index'));
const PermissionRule = lazy(() => import('../../pages/NTX/Security/Rules/PermissionRule'));
const RulesUser = lazy(() => import('../../pages/NTX/Security/RulesUser/RulesUser'));
const ChangePassword = lazy(() => import('../../pages/NTX/Security/Users/ChangePassword'));

const securityRoutes = [
  {
    path: 'Security/*',
    children: [
      {
        path: 'Users',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Users />
          </Suspense>
        ),
      },
      {
        path: 'Users/:userId/Edit',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <EditUser />
          </Suspense>
        ),
      },
      {
        path: 'Users/ChangePassword',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ChangePassword />
          </Suspense>
        ),
      },
      {
        path: 'Users/:userId/RulesUser',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <RulesUser />
          </Suspense>
        ),
      },
      {
        path: 'Users/:refrenceId/PermissionUser',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <PermissionUser />
          </Suspense>
        ),
      },
      {
        path: 'AuthRules/:refrenceId/PermissionRule',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <PermissionRule />
          </Suspense>
        ),
      },
      {
        path: 'AuthRules',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AuthRules />
          </Suspense>
        ),
      },
    ],
  },
];

export default securityRoutes;

