import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPurchaseQuotationList } from '../../../store/purchases/PurchaseQuotation/purchaseQuotationSlice';

interface Props {
  PurchaseRequestId: string | null;
}

const useGetPurchasesQuotationList = ({ PurchaseRequestId }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (PurchaseRequestId) {
      dispatch(getPurchaseQuotationList(PurchaseRequestId));
    }
  }, [PurchaseRequestId, dispatch]);

  const { PurchaseQuotationList, loading, error } = useAppSelector(
    (state) => state.PurchaseQuotation
  );

  return { PurchaseQuotationList, loading, error };
};

export default useGetPurchasesQuotationList;
