import React from 'react';
import PeopleLocations from '../../../../NTX/Peoples/PeopleLocations/PeopleLocations';
import { useParams } from 'react-router-dom';

const CustomersLocations = () => {
  const { peopleId } = useParams();
  return (
    <div>
      <PeopleLocations peopleId={peopleId} />
    </div>
  );
};

export default CustomersLocations;
