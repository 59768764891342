import React, { useState } from 'react';
import { Card, CardBody, CardFooter } from '../../../../../components/card/card';
import { CardHeader } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';

interface Props {
  itemsByInv: any[]; // قائمة الأصناف المتوفرة
  selectedItems: any[]; // الأصناف المحددة
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>; // دالة لتحديث العناصر المحددة
  removeFromInventory: () => void; // دالة لإخراج الأصناف من المخزن
}

const ItemsInInventory = ({
  itemsByInv,
  selectedItems,
  setSelectedItems,
  removeFromInventory,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(8);

  const filteredSearch = itemsByInv?.slice()?.filter((item: any) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const handleCheckboxChange = (row: any) => {
    setSelectedItems((prevSelectedRows: any) => {
      const isSelected = prevSelectedRows.some((item: any) => item.id === row.id);
      if (isSelected) {
        return prevSelectedRows?.filter((item: any) => item.id !== row.id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  // دالة لتحديد الكل أو إلغاء تحديد الكل
  const toggleSelectAll = () => {
    const allSelected = currentData.every((item) =>
      selectedItems.some((selected) => selected.id === item.id)
    );

    if (allSelected) {
      // إلغاء تحديد جميع العناصر في الصفحة الحالية
      const deselectedItems = selectedItems?.filter(
        (selected) => !currentData.some((item) => item.id === selected.id)
      );
      setSelectedItems(deselectedItems);
    } else {
      // تحديد جميع العناصر في الصفحة الحالية
      const newSelectedItems = [
        ...selectedItems,
        ...currentData?.filter((item) => !selectedItems.some((selected) => selected.id === item.id)),
      ];
      setSelectedItems(newSelectedItems);
    }
  };

  console.log(selectedItems);

  return (
    <div className="col-lg-6 mb-1   ">
      <Card classNeme="h-100">
        <CardHeader className="fw-bold small bg-success bg-opacity-25">
          <h6 className="m-1">الأصناف المتوفرة في المخزن</h6>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12 d-flex justify-content-start">
              <div className="input-group mb-2">
                <div className="flex-fill position-relative">
                  <div className="input-group">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                      style={{ zIndex: 1020, right: '10px' }}>
                      <i className="fa fa-search opacity-5"></i>
                    </div>
                    <input
                      type="text" 
autoComplete='off'
                      className="form-control ps-35px"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                      placeholder={t('Search') + ' ...'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <tbody>
                {currentData?.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => handleCheckboxChange(item)} // تحديد الصف عند الضغط عليه
                    style={{ cursor: 'pointer' }} // إضافة مؤشر ليدل على إمكانية الضغط
                  >
                    <td style={{ width: '5%' }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`checkbox-inv-${item.id}`}
                        checked={selectedItems.some((row) => row.id === item.id)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </td>
                    <td style={{ width: '95%' }}>{item.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
          <CardFooter className="mt-2 ">
            <div className="d-flex justify-content-between ">
              <button onClick={removeFromInventory} className="btn btn-danger">
                <i className="fa fa-box me-2"></i> إخراج من المخزن
              </button>
              <button onClick={toggleSelectAll} className="btn btn-theme">
                {currentData.every((item) =>
                  selectedItems.some((selected) => selected.id === item.id)
                ) ? (
                  <>
                    <i className="fa fa-times-circle"></i> إلغاء تحديد الكل
                  </>
                ) : (
                  <>
                    <i className="fa fa-check-circle"></i> تحديد الكل
                  </>
                )}
              </button>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    </div>
  );
};

export default ItemsInInventory;
