import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NeatixModal from '../../Modal/NeatixModal';
import AppsGrid from './AppsGrid/AppsGrid';

const AppsMenu = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="menu-item dropdown  ">
        <Link
          to=""
          className="menu-link"
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}>
          <div className="menu-icon">
            <i className="bi bi-grid-3x3-gap nav-icon"></i>
          </div>
        </Link>
      </div>

      <NeatixModal
        show={show}
        setShow={setShow}
        size="xl"
        bodyClassName="p-3"
        showHeader={false}>
        <AppsGrid  onClose={() => setShow(false)}  />
      </NeatixModal>
    </>
  );
};

export default AppsMenu;
