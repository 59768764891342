import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const apMenu = [
  {
    System: 'AP',
    isActive: false,
    menu: [
      { is_header: true, title: 'إدارة المدفوعات', isActive: true },

      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'HomePage',
        isActive: true,
      },

      { is_header: true, title: 'Settings', isActive: true },

      {
        path: 'Finance/Ap/Suppliers',
        icon: 'fas fa-file-invoice-dollar',
        title: 'Suppliers',
        isActive: true,
      },
      {
        path: 'Finance/Ap/TypesOfExpenses',
        icon: 'fas fa-file-invoice-dollar',
        title: 'TypesOfExpenses',
        isActive: true,
      },
      {
        path: 'Finance/Ap/PaymentTerms',
        icon: 'fas fa-file-invoice-dollar',
        title: 'PaymentTerms',
        isActive: true,
      },
      {
        path: 'Finance/Ap/Currencies',
        icon: 'fas fa-file-invoice-dollar',
        title: 'Currencies',
        isActive: true,
      },

      { is_header: true, title: 'Operations', isActive: true },

      {
        path: 'Finance/Ap/ApInvoices',
        icon: 'fas fa-file-invoice-dollar',
        title: 'PaymentInvoices',
        isActive: true,
      },
      {
        path: 'Finance/Ap/CreateAccountApInvoices',
        icon: 'fas fa-file-invoice-dollar',
        title: 'PostPaymentInvoices',
        isActive: true,
      },

      {
        path: 'Finance/Ap/ApPayments',
        icon: 'fas fa-credit-card',
        title: 'PaymentVouchers',
        isActive: true,
      },
      {
        path: 'Finance/Ap/CreateAccountApPayments',
        icon: 'fas fa-file-invoice-dollar',
        title: 'PostPaymentVouchers',
        isActive: true,
      },

      {
        path: 'Finance/Ap/ApExpense',
        icon: 'fas fa-money-check-alt',
        title: 'Expenses',
        isActive: true,
      },

      {
        path: 'Finance/Ap/InvoicePayments',
        icon: 'fas fa-receipt',
        title: 'InvoicePayments',
        isActive: true,
      },

      { is_header: true, title: 'Reports', isActive: true },
      {
        path: 'Finance/Ap/ApReports',
        icon: 'fas fa-home',
        title: 'Reports',
        isActive: true,
      },
      {
        path: 'Finance/Ap/ApDashboards',
        icon: 'fas fa-home',
        title: 'Dashboard',
        isActive: true,
      },
    ],
  },
];

export default apMenu;
