import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderRequest = ({ data }) => {
  const { t, i18n } = useTranslation();
  const Profile = data?.profileApplicant;
  return (
    <div>
      <div className=" bg-inverse bg-opacity-10 p-1 mb-2">
        <div className="  d-flex align-items-center">
          <img
            src={Profile?.personImage?.filePath || '../../../assets/img/user/manEmp.png'}
            alt=""
            width="50"
            title={Profile?.name}
            className="rounded-sm ms-n2 img-portrait"
          />
          <div className="flex-fill px-3">
            <div>
              <div className=" fw-bold text-theme">
                {i18n.language === 'en' ? Profile?.name : Profile?.name2}
              </div>
            </div>
            <div className="text-inverse text-opacity-50 fs-13px">
              {i18n.language === 'en' ? Profile?.organizationName : Profile?.organizationName2}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <span className="text-inverse text-opacity-50 fw-bold">
                  {' '}
                  {t('EmployeeNumber')} :{' '}
                </span>
                <span className="text-theme h6">{Profile?.employeeNumber || '0000'}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0  ">
                <span className="text-inverse text-opacity-50 fw-bold text-start">
                  {t('SuperVisor')} :{' '}
                </span>
                <span className="text-theme h6">
                  {i18n.language === 'ar' ? Profile?.supervisorName2 : Profile?.supervisorName}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <span className="text-inverse text-opacity-50 fw-bold">{t('location')} : </span>
                <span className="text-theme h6">
                  {i18n.language === 'ar' ? Profile?.locationName2 : Profile?.locationName}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <span className="text-inverse text-opacity-50 fw-bold">{t('Job')} : </span>
                <span className="text-theme h6">
                  {i18n.language === 'ar' ? Profile?.jobName2 : Profile?.jobName}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <span className="text-inverse text-opacity-50 fw-bold">{t('Position')} : </span>
                <span className="text-theme h6">
                  {i18n.language === 'ar' ? Profile?.positionName2 : Profile?.positionName}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="row  ">
        <div className="col-md-3">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <span className="text-inverse text-opacity-50 fw-bold">
                  {' '}
                  {t('NumberOfRequest')} :{' '}
                </span>
                <span className="text-theme h6">{data?.requestNumber}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <span className="text-inverse text-opacity-50 fw-bold">
                  {' '}
                  {t('TypeOfRequest')} :{' '}
                </span>
                <span className="text-theme h6">
                  {i18n.language === 'en' ? data?.requestName : data?.requestName2}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <span className="text-inverse text-opacity-50 fw-bold">
                  {' '}
                  {t('DateOfRequest')} :{' '}
                </span>
                <span className="text-theme h6">
                  {new Date(data?.dateCreated).toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-6">
          <div className="input-group input-group flex-nowrap mb-2">
            <span className="input-group-text d-flex justify-content-center text-nowrap">
              {t('Applicant')}
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control"
              defaultValue={
                i18n.language === 'en' ? data?.selectPersonName : data?.selectPersonName2
              }
              disabled
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderRequest;
