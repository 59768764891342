import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ApExpenseCategoryModels } from './ApExpenseCategoryModels';
import { APIResponse } from '../../../Shared/shared';

export const addApExpenseCategory = createAsyncThunk(
  'ApExpenseCategory/addApExpenseCategory',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Expense/AddApExpenseCategory', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateApExpenseCategory = createAsyncThunk(
  'ApExpenseCategory/updateApExpenseCategory',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Expense/UpdateApExpenseCategory', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApExpenseCategoryList = createAsyncThunk(
  'ApExpenseCategory/getApExpenseCategoryList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApExpenseCategoryModels[]>>(
        'Expense/GetApExpenseCategoryList'
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApExpenseCategoryById = createAsyncThunk(
  'ApExpenseCategory/getApExpenseCategoryById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApExpenseCategoryModels>>(
        `Expense/GetApExpenseCategoryById?id=${Id}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApExpenseCategoryByJeCategory = createAsyncThunk(
  'ApExpenseCategory/getApExpenseCategoryByJeCategory',
  async (jeCategory: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApExpenseCategoryModels>>(
        `Expense/GetApExpenseCategoryByJeCategory?jeCategory=${jeCategory}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApExpenseCategoryByJeSource = createAsyncThunk(
  'ApExpenseCategory/getApExpenseCategoryByJeSource',
  async (JeSource: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApExpenseCategoryModels>>(
        `Expense/GetApExpenseCategoryByJJeSource?JeSource=${JeSource}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ApExpenseCategoryList: ApExpenseCategoryModels[];
  ApExpenseCategoryById: ApExpenseCategoryModels;
  ApExpenseCategoryByJeSource: ApExpenseCategoryModels;
  ApExpenseCategoryByjeCategory: ApExpenseCategoryModels;
  loading: boolean;
  loading2: boolean;
  error: any | null;
} = {
  ApExpenseCategoryList: [],
  ApExpenseCategoryById: {} as ApExpenseCategoryModels,
  ApExpenseCategoryByJeSource: {} as ApExpenseCategoryModels,
  ApExpenseCategoryByjeCategory: {} as ApExpenseCategoryModels,
  loading: false,
  loading2: false,
  error: null,
};

const ApExpenseCategorySlice = createSlice({
  name: 'ApExpenseCategory',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getApExpenseCategoryList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApExpenseCategoryList.fulfilled,
        (state, action: PayloadAction<ApExpenseCategoryModels[]>) => {
          state.loading = false;
          state.ApExpenseCategoryList = action.payload;
        }
      )
      .addCase(getApExpenseCategoryList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApExpenseCategoryById.pending, (state, action) => {
        state.loading2 = true;
      })
      .addCase(
        getApExpenseCategoryById.fulfilled,
        (state, action: PayloadAction<ApExpenseCategoryModels>) => {
          state.loading2 = false;
          state.ApExpenseCategoryById = action.payload;
        }
      )
      .addCase(getApExpenseCategoryById.rejected, (state, action: PayloadAction<any>) => {
        state.loading2 = false;
        state.error = action.payload;
      })

      .addCase(getApExpenseCategoryByJeCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApExpenseCategoryByJeCategory.fulfilled,
        (state, action: PayloadAction<ApExpenseCategoryModels>) => {
          state.loading = false;
          state.ApExpenseCategoryByjeCategory = action.payload;
        }
      )
      .addCase(getApExpenseCategoryByJeCategory.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApExpenseCategoryByJeSource.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApExpenseCategoryByJeSource.fulfilled,
        (state, action: PayloadAction<ApExpenseCategoryModels>) => {
          state.loading = false;
          state.ApExpenseCategoryByJeSource = action.payload;
        }
      )
      .addCase(getApExpenseCategoryByJeSource.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ApExpenseCategorySlice.reducer;
