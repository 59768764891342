import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetSubinventoryList } from '../../../store/INV/SubInventory/SubInventorySlice';
import { useTranslation } from 'react-i18next';

const useGetSubinventoryList = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (SubInventoryList?.length === 0) {
      dispatch(GetSubinventoryList());
    }
  }, []);
  const { SubInventoryList, loading, error } = useAppSelector((state) => state.SubInventory);

  const SubInventoryOptions = SubInventoryList?.map((item) => ({
    value: item.id,
    label: `${i18n.language === 'en' ? item.name : item.name2}`,
    parentId: item.parentId,
    subinventoryParentId: item.subinventoryParentId,
    id: item.id,
  }));

  //________________________________________________________________

  const convertToTreeData = () => {
    const map = new Map();
    const treeData: {parentId: string; title: string; value: string | undefined; children: never[] }[] = [];
    // const treeData: { title: string; value: string; children: never[] }[] = [];

    SubInventoryOptions?.forEach((item) => {
      const node = {
        parentId: item.parentId,
        title: item.label,
        value: item.id,
        children: [],
      };

      map.set(item.id, node);

      if (item.subinventoryParentId === null) {
        treeData.push(node);
      } else {
        const parent = map.get(item.subinventoryParentId);
        if (parent) {
          parent.children.push(node);
        }
      }
    });

    return treeData;
  };

  const TreeSubInventoryData = convertToTreeData();

  return { SubInventoryList, SubInventoryOptions, TreeSubInventoryData, loading, error };
};

export default useGetSubinventoryList;
