import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const arMenu = [
  {
    System: 'AR',
    isActive: false,
    menu: [
      { is_header: true, title: 'إدارة المستحقات', isActive: true }, // عنوان القائمة الرئيسي

      {
        path: 'SystemDashboard',
        icon: 'fas fa-home', // أيقونة الصفحة الرئيسية
        title: 'HomePage',
        isActive: true,
      },

      { is_header: true, title: 'الإعدادت', isActive: true },

      // {
      //   path: 'Finance/Ar/TypesOfExpenses',
      //   icon: 'fas fa-file-invoice-dollar',
      //   title: 'أنواع المصروفات',
      //   isActive: true,
      // },
      {
        path: 'Finance/Ar/Customers',
        icon: 'fas fa-file-invoice-dollar',
        title: 'العملاء',
        isActive: true,
      },
      {
        path: 'Finance/Ar/PaymentTerms',
        icon: 'fas fa-file-invoice-dollar',
        title: 'شروط الدفع',
        isActive: true,
      },
      {
        path: 'Finance/Ar/Currencies',
        icon: 'fas fa-file-invoice-dollar',
        title: 'العملات',
        isActive: true,
      },

      { is_header: true, title: 'العمليـات', isActive: true },
      {
        path: 'Finance/Ar/ArTransactions',
        icon: 'fas fa-file-invoice',  
        title: 'فواتيـر الإستحقاق',
        isActive: true,
      },

      {
        path: 'Finance/Ar/CreateAccountArTransactions',
        icon: 'fas fa-file-invoice',  
        title: 'ترحيـل معاملات الإستحقاق',
        isActive: true,
      },

      {
        path: 'Finance/Ar/ArReceipts',
        icon: 'fas fa-receipt',  
        title: 'سندات الإستلام',
        isActive: true,
      },
      {
        path: 'Finance/Ar/CreateAccountArReceipts',
        icon: 'fas fa-receipt',  
        title: 'ترحيــل سندات الإسنلام',
        isActive: true,
      },

      { is_header: true, title: 'التقارير', isActive: true },
      {
        path: 'Finance/Ar/ArReports',
        icon: 'fas fa-home',
        title: 'التقارير',
         isActive: true 
      },
      {
        path: 'Finance/Ar/ArDashboards',
        icon: 'fas fa-home',
        title: 'لوحة المعلومات',
         isActive: true 
      },

       
    ],
  },
];

export default arMenu;
