import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { CardExpandToggler } from "../../../components/card/card";

function BarChart({ data }) {
  var id = data.reportChartId;
  const chartRef = useRef(null);


  useEffect(() => {
    let newChart = null;

    if (chartRef.current) {
      // Destroy previous chart instance
      if (chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }

      // Create new chart instance
      newChart = new Chart(chartRef.current, {
        type: "bar",
        data: {
          labels: data?.kpiNames?.map((item) => item) || [],
          datasets: data.data?.map((item) => ({
            label: item.name,
            data: item.data?.map((subItem) => subItem.value),
            borderWidth: 1.5,
          })),
        },
      });

      // Save the chart instance in the ref
      chartRef.current.chartInstance = newChart;
    }

    // Cleanup function
    return () => {
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [data]);

  return (
    <div>
      <div className="d-flex fw-bold small p-3 bg-theme bg-opacity-20">
        <span className="flex-grow-1">{data?.title}</span>
        <CardExpandToggler />
      </div>
      <canvas id={id} ref={chartRef}></canvas>
    </div>
  );
}

export default BarChart;




  // useEffect(() => {
  //   // var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
  //   // var themeColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();
  //   // var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();

  //   if (chartRef.current) {
  //     // Destroy the previous chart instance
  //     chartRef.current.destroy();
  //   }

  //   new Chart(document.getElementById(id), {
  //     type: 'bar',
  //     data: {
  //       labels: data?.kpiNames?.map(item => item) || [], // ['Jan','Feb','Mar','Apr','May','Jun'],
  //       datasets:  data.data?.map((item) => ({
  //         label: item.name,
  //         data: item.data?.map((subItem) => subItem.value),
  //         // backgroundColor: 'rgba(0, 123, 255, 0.5)', // You can customize this color
  //         // borderColor: 'rgba(0, 123, 255, 1)', // You can customize this color
  //         borderWidth: 1.5,
  //       })),

  //       // data[0].data?.map((_, index) => ({
  //       //   label: data[0].data[index].label,
  //       //   data: data?.map(item => item.data[index].value),
  //       //   backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
  //       //     Math.random() * 256
  //       //   )}, ${Math.floor(Math.random() * 256)}, 0.5)`, // Random color for each dataset
  //       //   borderColor: 'rgba(0, 0, 0, 1)',
  //       //   borderWidth: 1.5,
  //       // }));
  //       // datasets: [{
  //       //   label: 'Total Visitors',
  //       //   data: [37,31,36,34,43,31],
  //       //   backgroundColor: 'rgba('+ themeColorRgb +', .5)',
  //       //   borderColor: themeColor,
  //       //   borderWidth: 1.5
  //       // }]
  //     }
  //   });

  //   // eslint-disable-next-line
  // }, []);