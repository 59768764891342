import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Get_PEOPLE_TYPE } from '../../store/Lookup/lookupSlice';

const useLookupPeopleType = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (PEOPLE_TYPE?.length === 0) {
      dispatch(Get_PEOPLE_TYPE());
    }
  }, []);

  const { PEOPLE_TYPE, loading, error } = useSelector((state) => state.Lookup);

  return { PEOPLE_TYPE, loading, error };
};

export default useLookupPeopleType;
