import Customers from '../../pages/Sales/SalesSettings/Customers/Index';
import AddCustomers from '../../pages/Sales/SalesSettings/Customers/Add';
import UpdateCustomers from '../../pages/Sales/SalesSettings/Customers/Update';
import BranchesCustomer from '../../pages/Sales/SalesSettings/Customers/Branches/Index';
import PeopleLocations from '../../pages/Sales/SalesSettings/Customers/CustomersLocations/CustomersLocations';

import PaymentTerms from '../../pages/Finance/Settings/PaymentTerms/Index';
import Currencies from '../../pages/Finance/Settings/Currencies/Index';

import ArTransactions from '../../pages/Finance/Ar/ArTransactions/Index';
import AddArTransactions from '../../pages/Finance/Ar/ArTransactions/Add';
import UpdateArTransactions from '../../pages/Finance/Ar/ArTransactions/Update';
import ArTransactionsCreateAccount from '../../pages/Finance/Ar/ArTransactions/CreateAccount/ListArTransactions';

import ArReceipts from '../../pages/Finance/Ar/ArReceipts/Index';
import AddArReceipts from '../../pages/Finance/Ar/ArReceipts/Add/Add';
import UpdateArReceipts from '../../pages/Finance/Ar/ArReceipts/Update/Update';
import ArReceiptsCreateAccount from '../../pages/Finance/Ar/ArReceipts/CreateAccount/ListArReceipts';


import ArReports from '../../pages/Finance/Ar/ArReports/ArReports';
import ReportsBuilder from '../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder';

import ArDashboards from '../../pages/Finance/Ar/ArDashboards/ArDashboards';
import BuilderDashboard from '../../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard';


 
const arRoutes = [
  {
    path: 'Finance/Ar',
    children: [
      { path: 'Customers', element: <Customers /> },
      { path: 'Customers/AddCustomers', element: <AddCustomers /> },
      { path: 'Customers/:Id/UpdateCustomers', element: <UpdateCustomers /> },
      { path: 'Customers/:id/BranchesCustomer', element: <BranchesCustomer /> },
      { path: 'Customers/:peopleId/PeopleLocations', element: <PeopleLocations /> },
      //~~~~~~~~~~~~~

      { path: 'PaymentTerms', element: <PaymentTerms /> },
      { path: 'Currencies', element: <Currencies /> },

      { path: 'ArTransactions', element: <ArTransactions /> },
      {
        path: 'ArTransactions/AddArTransactions',
        element: <AddArTransactions />,
      },
      {
        path: 'ArTransactions/:transactionId/UpdateArTransactions',
        element: <UpdateArTransactions />,
      },
      { path: 'CreateAccountArTransactions', element: <ArTransactionsCreateAccount /> },

      { path: 'ArReceipts', element: <ArReceipts /> },
      { path: 'ArReceipts/AddArReceipts', element: <AddArReceipts /> },
      { path: 'ArReceipts/:receiptId/UpdateArReceipts', element: <UpdateArReceipts /> },
      { path: 'CreateAccountArReceipts', element: <ArReceiptsCreateAccount /> },

      { path: 'ArReports', element: <ArReports /> },
      { path: 'ArReports/:ReportId/ReportsBuilder', element: <ReportsBuilder /> },

      { path: 'ArDashboards', element: <ArDashboards /> },
      { path: 'ArDashboards/:dashboardId/BuilderDashboard', element: <BuilderDashboard /> },
    ]
  },
];

export default arRoutes;
