import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../../../components/Shared/Alert/CustomAlert';
import useGetUnitsOfMeasureList from '../../../../../../hooks/INV/useGetUnitsOfMeasureList';
import { useParams } from 'react-router-dom';
import { LotItemSchema } from '../../../../../ValidationForm/validationSchema';
import {
  updateLotDetails,
  getLotDetailsListByItemId,
} from '../../../../../../store/INV/MasterItems/LotItemSlice';
import { LotItemModel } from '../../../../../../store/INV/MasterItems/MasterItemsModels';
import useGetItemConversionByItemId from '../../../../../../hooks/INV/ItemConversion/useGetItemConversionByItemId';

interface Props {
  data: LotItemModel;
  onClose: () => void;
}
const UpdateLotItem: React.FC<Props> = ({ data, onClose }) => {
  const { t, i18n } = useTranslation();
  const { ItemId } = useParams<{ ItemId: string }>();

  const dispatch = useAppDispatch();

  const { ItemConversionListByItem, error: error1 } = useGetItemConversionByItemId(ItemId ?? '');

  const formik = useFormik({
    initialValues: {
      id: data.id ?? '',
      itemId: data.itemId ?? '',
      lotNumber: data.lotNumber ?? '',
      unitOfMeasure: data.unitOfMeasure ?? '',
      expirationDate: data.expirationDate?.toString()?.split('T')[0] ?? '',
    },

    validationSchema: LotItemSchema(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        updateLotDetails({
          id: values.id,
          itemId: ItemId,
          lotNumber: values.lotNumber,
          unitOfMeasure: values.unitOfMeasure,
          expirationDate: values.expirationDate,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: 'Edit' });
            dispatch(getLotDetailsListByItemId(ItemId || ''));
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row align-items-end">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('lotNumber')}</label>
            <Form.Control
              autoComplete="off"
              className="form-control"
              type="text"
              name="lotNumber"
              onChange={formik.handleChange}
              value={formik.values.lotNumber}
              isInvalid={!!(formik.touched.lotNumber && formik.errors.lotNumber)}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.lotNumber}</Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label"> {t('الوحدة')}</label>
            <Form.Select
              className="form-select"
              name="unitOfMeasure"
              onChange={formik.handleChange}
              value={formik.values.unitOfMeasure || ''}
              isInvalid={!!(formik.touched.unitOfMeasure && formik.errors.unitOfMeasure)}>
              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
              {ItemConversionListByItem &&
                ItemConversionListByItem?.map((item, idx) => (
                  <option key={++idx} value={item.uom}>
                    {i18n.language === 'en' ? item.uomName : item.uomName2}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.unitOfMeasure}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('تاريخ الإنتهاء')}</label>
            <Form.Control
              className="form-control"
              type="date"
              name="expirationDate"
              onChange={formik.handleChange}
              value={formik.values.expirationDate || ''}
              isInvalid={!!(formik.touched.expirationDate && formik.errors.expirationDate)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.expirationDate}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6  ">
          <div className=" form-group   d-flex justify-content-end ">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn me-1 btn-theme form-control w-100 ">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-save fa-fw me-1"></i>
              )}
              {t('Save')}
            </button>
          </div>
        </div>
        <div className="col-xl-6">
          <button type="button" className=" btn btn-default w-100" onClick={onClose}>
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default UpdateLotItem;
