import React from 'react';
import { GlJeLinesList } from '../../../../store/Finance/Journals/JournalsModels';
import { useTranslation } from 'react-i18next';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';
import useGetCodeCombination from '../../../../hooks/Finance/ChartAccountsDetails/useGetCodeCombination';

interface Props {
  JournalLine: GlJeLinesList[];
  CategoryName: string;
}

const Lines = ({ JournalLine, CategoryName }: Props) => {
  const { t, i18n } = useTranslation();

  const totalAccountDr = JournalLine?.reduce(
    (total: any, line: any) => Number(total) + Number(line.enteredDr),
    0
  );
  const totalAccountCr = JournalLine?.reduce(
    (total: any, line: any) => Number(total) + Number(line.enteredCr),
    0
  );

  const { GetCodeCombination, GetCodeCombinationDetails } = useGetCodeCombination();

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-3 mb-2">
          <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
            <div className="d-flex justify-content-between">
              <div className="fw-bold">{t('إجمالي الدائن')}:</div>
              <div className="text-success fs-5 fw-bold">{totalAccountCr}</div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-2">
          <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
            <div className="d-flex justify-content-between">
              <div className="fw-bold">{t('إجمالي المدين')}:</div>
              <div className="text-danger fs-5 fw-bold">{totalAccountDr}</div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-2 text-end">
          <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
            <div className="d-flex justify-content-between">
              <div className="fw-bold">{t('تصنيف القيد')}:</div>
              <div className=" fs-5 fw-bold">{CategoryName}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={`table-responsive rounded-2 text-center `}>
        <table border={1} className="table table-border  table-hover   mb-2">
          {/* <thead className="bg-theme bg-opacity-30"> */}
          <thead className=" table-dark ">
            <tr>
              <th className="align-middle  small" style={{ width: '2%' }}>
                #
              </th>
              <th className="align-middle  small" style={{ width: '10%' }}>
                دائن
              </th>
              <th className="align-middle  small" style={{ width: '10%' }}>
                مدين
              </th>
              <th className="align-middle  small" style={{ width: '10%' }}>
                {/* {Currency?.find((f: any) => f?.currencyCode === ledgar?.currencyCode)?.description} */}
                الإجمالي
              </th>

              <th className="align-middle  small" style={{ width: '46%' }}>
                {t('الوصف')}
              </th>

              <th className="align-middle small" style={{ width: '30%' }}>
                {t('الحســـاب')}
              </th>
            </tr>
          </thead>

          <tbody className="fw-bold">
            {JournalLine?.slice()?.map((line, index) => {
              return (
                <tr key={index}>
                  <td
                    className={`align-middle small  bg-opacity-30 " ${
                      line.accountCr > 0 ? 'bg-success' : 'bg-danger'
                    }`}>
                    {index + 1}
                  </td>

                  <td className={`align-middle small`}>{line.accountCr}</td>
                  <td className={`align-middle small`}>{line.accountDr}</td>

                  <td
                    className={`align-middle small ${
                      line.accountCr > 0 ? 'text-success' : 'text-danger'
                    }`}>
                    {typeof line.accountCr === 'number'
                      ? line.accountCr.toFixed(2)
                      : typeof line.accountDr === 'number'
                      ? line.accountDr.toFixed(2)
                      : ''}
                  </td>

                  <td className={`align-middle small`}>{line.description || ''}</td>

                  <td className="align-middle small">
                    <PopoverCustom
                      placement="right"
                      //   childern={line.codeCombination || ''}
                      childern={GetCodeCombination(
                        line.companyId,
                        line.branchId,
                        line.accountId,
                        line.costCenterId
                      )}
                      Header="تفاصيل الحـــساب"
                      //   Body={'sss'}
                      Body={GetCodeCombinationDetails(
                        line.companyId,
                        line.branchId,
                        line.accountId,
                        line.costCenterId
                      )}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Lines;
