import { Card, CardBody, CardExpandToggler } from '../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BarChart from './BarChart.js';

const Employees = [
  { id: 1, name: 'محمد', nationality: 'سعودي' },
  { id: 2, name: 'أحمد', nationality: 'مصري' },
  { id: 3, name: 'سارة', nationality: 'أردني' },
  { id: 4, name: 'ليلى', nationality: 'سعودي' },
  { id: 5, name: 'علي', nationality: 'مصري' },
  { id: 6, name: 'خالد', nationality: 'لبناني' },
  { id: 7, name: 'فاطمة', nationality: 'سعودي' },
  { id: 8, name: 'يوسف', nationality: 'أردني' },
  { id: 9, name: 'عائشة', nationality: 'سوري' },
];

const EmployeeByNationality = () => {
  const { t } = useTranslation();

  // حساب توزيع الموظفين حسب الجنسية
  function countEmployeesByNationality(employees) {
    const nationalityCount = {};

    employees?.forEach((employee) => {
      const nationality = employee.nationality;
      if (nationalityCount[nationality]) {
        nationalityCount[nationality].count++;
      } else {
        nationalityCount[nationality] = {
          name: nationality,
          count: 1,
        };
      }
    });

    // تحويل النتيجة إلى مصفوفة من الكائنات
    const result = Object.values(nationalityCount);

    return result;
  }

  const [data, setData] = useState([]);

  useEffect(() => {
    const generatedData = countEmployeesByNationality(Employees);
    setData(generatedData);
  }, []);

  return (
    <div>
      <Card className="mb-3" style={{ height: '100%' }}>
        <CardBody className="">
          <div className="d-flex fw-bold small mb-3">
            <span className="flex-grow-1">توزيع الموظفين حسب الجنسية</span>
            <CardExpandToggler />
          </div>
          <div className="ratio ratio-21x9 mb-3">
            {data && data.length > 0 ? <BarChart data={data} /> : <p>لا توجد بيانات لعرضها.</p>}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default EmployeeByNationality;
