import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';

// تحميل المكونات بشكل كسول
const Conditions = lazy(() => import('../../pages/Ame/RequestSettings/Conditions/Conditions'));
const Rules = lazy(() => import('../../pages/Ame/RequestSettings/Rules/Rules'));
const AddAmeCondition = lazy(() => import('../../pages/Ame/RequestSettings/AddAmeCondition.js'));
const AddApprovalMember = lazy(() => import('../../pages/Ame/ApprovalMember/AddApprovalMember.js'));
const AddAmeRule = lazy(() => import('../../pages/Ame/AmeRule/AmeRule/AddAmeRule.js'));
const AddAmeConditionUsage = lazy(() =>
  import('../../pages/Ame/AmeRule/AmeConditionUsage/AddAmeConditionUsage.js')
);
const AddAmeAction = lazy(() => import('../../pages/Ame/AmeRule/AmeActionRule/AddAmeAction.js'));
const Forms = lazy(() => import('../../pages/NTX/FlexValue/AmeForms/Forms.js'));
const RequestSettings = lazy(() => import('../../pages/Ame/RequestSettings/RequestSettings.js'));
const ApprovalGroup = lazy(() => import('../../pages/Ame/ApprovalGroup/ApprovalGroup.js'));
const AllRequests = lazy(() => import('../../pages/Ame/AllRequests/AllRequests'));
const AmeRequests = lazy(() => import('../../pages/Ame/Requests/AmeRequests/AmeRequests.js'));
const MyRequests = lazy(() => import('../../pages/Ame/ViewRequests/MyRequests/MyRequests.js'));
const AddRequests = lazy(() => import('../../pages/Ame/Requests/AddRequests.js'));
const ViewRequests = lazy(() => import('../../pages/Ame/ViewRequests/ViewRequests.js'));

const ameRoutes = [
  {
    path: 'Ame/*',
    children: [
      {
        path: 'Forms',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Forms />
          </Suspense>
        ),
      },
      {
        path: 'RequestSetting',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <RequestSettings />
          </Suspense>
        ),
      },
      {
        path: 'RequestSetting/:id/AddAmeCondition',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddAmeCondition />
          </Suspense>
        ),
      },
      {
        path: 'RequestSetting/:id/AddAmeRule',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddAmeRule />
          </Suspense>
        ),
      },
      {
        path: 'RequestSetting/:id/AddAmeRule/:ruleId/AddAmeConditionUsage',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddAmeConditionUsage />
          </Suspense>
        ),
      },
      {
        path: 'RequestSetting/:id/AddAmeRule/:ruleId/AddAmeAction',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddAmeAction />
          </Suspense>
        ),
      },
      {
        path: 'Conditions',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Conditions />
          </Suspense>
        ),
      },
      {
        path: 'Conditions/:id/AddAmeCondition',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddAmeCondition />
          </Suspense>
        ),
      },
      {
        path: 'Rules',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Rules />
          </Suspense>
        ),
      },
      {
        path: 'ApprovalGroup',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ApprovalGroup />
          </Suspense>
        ),
      },
      {
        path: 'ApprovalGroup/:id/AddApprovalMember',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddApprovalMember />
          </Suspense>
        ),
      },
      {
        path: 'FollowRequests',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AllRequests />
          </Suspense>
        ),
      },
      //___________________ SelfService _____________________________________
      {
        path: 'SelfService/*',
        children: [
          {
            path: 'Requests',
            element: (
              <Suspense fallback={<LazyLoading />}>
                <AmeRequests />
              </Suspense>
            ),
          },
          {
            path: 'Requests/:FormId/AddRequest',
            element: (
              <Suspense fallback={<LazyLoading />}>
                <AddRequests />
              </Suspense>
            ),
          },
          {
            path: 'Requests/:TransactionHistoryId/ViewRequests',
            element: (
              <Suspense fallback={<LazyLoading />}>
                <ViewRequests />
              </Suspense>
            ),
          },
          {
            path: 'Requests/:TransactionHistoryId/OnlyViewRequests',
            element: (
              <Suspense fallback={<LazyLoading />}>
                <ViewRequests isAction={false} />
              </Suspense>
            ),
          },
          {
            path: 'MyRequests',
            element: (
              <Suspense fallback={<LazyLoading />}>
                <MyRequests />
              </Suspense>
            ),
          },
          {
            path: 'AllRequests',
            element: (
              <Suspense fallback={<LazyLoading />}>
                <AllRequests />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];

export default ameRoutes;
