import { useFormik } from 'formik';
import React from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import {
  addFACategories,
  getFACategoriesList,
} from '../../../../store/Finance/FixedAssets/FACategories/FACategoriesSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';

import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { DataIcons } from '../../../../assets/icons';
import useGetFACategoriesList from '../../../../hooks/Finance/FixedAssets/useGetFACategoriesList';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
import useGetDepreciationMethod from '../../../../hooks/Lookups/use-Get_DEPRECIATION_METHOD';

const AddFaCategory = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { FACategoriesList, loading, error } = useGetFACategoriesList();
  const { OptionsAccounts } = useGetAccounts();

  const { LedgersList } = useGetGlLedgersList();

  const { DEPRECIATION_METHOD } = useGetDepreciationMethod();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      name: '',
      name2: '',
      categoryId: '',
      description: '',
      icon: '',
      lifeInMonths: 0,
      depreciationMethod: '',
      percentage: 0,
      assetCostAccount: '',
      deprnExpenseAccount: '',
      deprnReserveAccount: '',
      glLedgerId: '',
    },

    enableReinitialize: true,
    // validationSchema: AddApPaymentsSchema(t),

    onSubmit:(values) => {
      dispatch(
        addFACategories({
          name: values.name,
          name2: values.name2,
          categoryId: values.categoryId || null,
          description: values.description,
          icon: values.icon,
          lifeInMonths: values.lifeInMonths,
          depreciationMethod: values.depreciationMethod,
          percentage: values.percentage,
          assetCostAccount: values.assetCostAccount,
          deprnExpenseAccount: values.deprnExpenseAccount,
          deprnReserveAccount: values.deprnReserveAccount,
          glLedgerId: values.glLedgerId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            dispatch(getFACategoriesList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  console.log(values);

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text" 
autoComplete='off'
                name="name2"
                onChange={handleChange}
                value={values.name2}
                isInvalid={!!(touched.name2 && errors.name2)}
              />
              <Form.Control.Feedback type="invalid">{errors.name2}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text" 
autoComplete='off'
                name="name"
                onChange={handleChange}
                value={values.name}
                isInvalid={!!(touched.name && errors.name)}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label"> {t('تابع لـ')}</label>
              <Form.Select
                className="form-select"
                name="categoryId"
                onChange={handleChange}
                value={values.categoryId}
                isInvalid={!!(touched.categoryId && errors.categoryId)}
                // disabled
              >
                <option value={''}>{i18n.language === 'en' ? 'رئيسي' : 'رئيسي'}</option>
                {FACategoriesList &&
                  FACategoriesList?.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === 'en' ? item.name : item.name2}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('Icon')}</label>

              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                options={DataIcons}
                onChange={(option) => setFieldValue('icon', option?.value)}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
              <>{errors.icon}</>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('lifeInMonths')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text" 
autoComplete='off'
                name="lifeInMonths"
                onChange={handleChange}
                value={values.lifeInMonths}
                isInvalid={!!(touched.lifeInMonths && errors.lifeInMonths)}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value?.replace(/[^0-9]/g, '');
                }}
              />
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('طريقة الإستهلاك')}</label>
              <Form.Select
                className="form-select"
                name="depreciationMethod"
                onChange={handleChange}
                value={values.depreciationMethod}
                // isInvalid={touched.depreciationMethod && errors.depreciationMethod}
              >
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {DEPRECIATION_METHOD?.map((item: any, idx: number) =>
                  item.list?.map((list: any) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                    </option>
                  ))
                )}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('نسبة الإهلاك')}</label>
              <div className="input-group flex-nowrap">
                <Form.Control
                  className="form-control text-center"
                  type="text" 
autoComplete='off'
                  name="percentage"
                  onChange={handleChange}
                  value={values.percentage}
                  isInvalid={!!(touched.percentage && errors.percentage)}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    input.value = input.value?.replace(/[^0-9]/g, '');
                  }}
                />
                <span className="input-group-text text-theme fw-bold">%</span>
              </div>
              <div className="text-danger small">{touched.percentage && errors.percentage}</div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">دفتر الأستاذ العام</label>
              <Form.Select
                className="form-select"
                name="glLedgerId"
                onChange={handleChange}
                value={values.glLedgerId}
                isInvalid={!!(errors.glLedgerId && touched.glLedgerId)}>
                <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {LedgersList &&
                  LedgersList?.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === 'ar' ? item.name2 : item.name}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('حساب تكلفة الأصول')}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                isLoading={loading}
                isSearchable={true}
                isClearable
                options={OptionsAccounts}
                value={OptionsAccounts?.find((f) => f.value === values.assetCostAccount)}
                onChange={(option) => setFieldValue('assetCostAccount', option?.value)}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('حساب مصاريف الاستهلاك')}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                isLoading={loading}
                isSearchable={true}
                isClearable
                options={OptionsAccounts}
                value={OptionsAccounts?.find((f) => f.value === values.deprnExpenseAccount)}
                onChange={(option) => setFieldValue('deprnExpenseAccount', option?.value)}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('حساب احتياطي الاستهلاك')}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                isLoading={loading}
                isSearchable={true}
                isClearable
                options={OptionsAccounts}
                value={OptionsAccounts?.find((f) => f.value === values.deprnReserveAccount)}
                onChange={(option) => setFieldValue('deprnReserveAccount', option?.value)}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text" 
autoComplete='off'
                name="description"
                onChange={handleChange}
                value={values.description}
                isInvalid={!!(touched.description && errors.description)}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button type="button" className=" btn me-1 btn-default mb-1">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </>
  );
};

export default AddFaCategory;
