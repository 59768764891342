import React from 'react';
import { JournalsUnPostModels } from '../../../../store/Finance/Journals/JournalsModels';
import { useTranslation } from 'react-i18next';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';
import { useAppDispatch } from '../../../../store/hooks';
import { postedGlJeHeaders } from '../../../../store/Finance/Journals/postingJournalsSlice';

interface Props {
  Journal: JournalsUnPostModels;
}

const ViewSingleJournals: React.FC<Props> = ({ Journal }) => {
  // console.log(Journal);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { Accounts, Branches, Companies, CostCenters } = useGetChartAccountsDetailsList();

  const totalDebt = Journal.glJeLinesList?.reduce((total, line) => total + line.accountDr, 0);

  const totalCredit = Journal.glJeLinesList?.reduce((total1, line) => total1 + line.accountCr, 0);

  const handlePostJournal = () => {
    showConfirmation('هل أنت متأكد من أنك تريد ترحيل هذا القيد ؟', () => {
      dispatch(
        postedGlJeHeaders({
          header: Journal,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add', msg: 'تمت عملية الترحيــل بنجاح' });
          } else {
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          CustomAlert({ action: 'Error' });
        });
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-3">
          <div className="form-group mb-3">
            <label className="form-label">{t('الأستاذ')}</label>
            <Form.Control
              className="form-control text-center fw-bold"
              value={i18n.language === 'en' ? Journal.ledgerName : Journal.ledgerName2 || ''}
              readOnly
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group mb-3">
            <label className="form-label">{t('AccountingPeriod')}</label>
            <Form.Control
              className="form-control text-center fw-bold"
              value={i18n.language === 'en' ? Journal.periodName : Journal.periodName2 || ''}
              readOnly
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group mb-3">
            <label className="form-label">{t('Date')}</label>
            <Form.Control
              className="form-control fw-bold text-center"
              value={Journal.glDate || ''}
              readOnly
            />
          </div>
        </div>

        <div className="col-lg-3">
          <div className="form-group mb-3">
            <label className="form-label">{t('Category')}</label>
            <Form.Control
              className="form-control fw-bold text-center"
              value={i18n.language === 'en' ? Journal.jeCategoryName : Journal.jeCategoryName2}
              readOnly
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group mb-3">
            <label className="form-label">{t('Source')}</label>
            <Form.Control
              className="form-control fw-bold text-center"
              value={i18n.language === 'en' ? Journal.jeSourceName : Journal.jeSourceName2 || ''}
              readOnly
            />
          </div>
        </div>

        <div className="col-lg-2 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">{t('Currency')}</label>
            <Form.Control
              className="form-control fw-bold text-center"
              value={Journal.currencyCode}
              readOnly
            />
          </div>
        </div>
        <div className="col-lg-7 m-auto">
          <div className="form-group mb-3">
            <label className="form-label">{t('Note')}</label>
            <Form.Control
              className="form-control fw-bold text-center"
              value={Journal.description || ''}
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-3 m-auto text-center ">
          <div className="form-group mb-3 rounded-4 p-1 bg-inverse bg-opacity-10">
            <label className=" fw-bold"> {t('Credit')} </label>
            <div className="text-theme fw-bold fs-4">{totalCredit} </div>
          </div>
        </div>

        <div className="col-lg-3 m-auto text-center  ">
          <div className="form-group mb-3 rounded-4 p-1 bg-inverse bg-opacity-10">
            <label className="fw-bold">{t('Debt')} </label>
            <div className="text-theme fs-4 fw-bold">{totalDebt}</div>
          </div>
        </div>

        <div className="col-lg-6 m-auto text-end pb-3  ">
          <button
            type="button"
            className="btn btn-lg btn-theme"
            onClick={() => handlePostJournal()}>
            ترحيــل القيـد
          </button>
        </div>
      </div>

      <div className="bg-inverse bg-opacity-10 rounded-3 ">
        <div className="table-responsive rounded-3 text-center">
          <table className="table  table-bordered mb-2px text-nowrap">
            <thead className=" table-dark">
              <tr>
                <th>#</th>
                <th>{t('Debt')}</th>
                <th>{t('Credit')}</th>
                <th>{t('Accounts')}</th>
                <th>{t('Description')}</th>
              </tr>
            </thead>
            <tbody className="fw-bold">
              {Journal?.glJeLinesList?.map((line: any, idx: number) => (
                <tr key={++idx}>
                  <th scope="row" className="bg-theme bg-opacity-25">
                    {line.jeLineNum}
                  </th>
                  <td>{line.enteredDr}</td>
                  <td>{line.enteredCr}</td>
                  <td>
                    {[
                      Companies?.find((f) => f.id === line.companyId)?.name2,
                      Branches?.find((f) => f.id === line.branchId)?.name2,
                      CostCenters?.find((f) => f.id === line.costCenterId)?.name2,
                      Accounts?.find((f) => f.id === line.accountId)?.name2,
                    ]
                      ?.filter(Boolean)
                      .join(' | ')}
                  </td>
                  <td>{line.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewSingleJournals;
