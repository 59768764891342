import ameMenu from './Menus/AmeMenu';
import apMenu from './Menus/ApMenu';
import arMenu from './Menus/ArMenu';
import fassetsMenu from './Menus/FAssetsMenu';
import financeMenu from './Menus/FinanceMenu';
import fleetMenu from './Menus/FleetMenu';
import hrMenu from './Menus/HrMenu';
import integrationMenu from './Menus/IntegrationMenu';
import inventoryMenu from './Menus/InventoryMenu';
import notificationsMenu from './Menus/NotificationsMenu';
import ntxMenu from './Menus/NtxMenu';
import payrollMenu from './Menus/PayrollMenu';
import posMenu from './Menus/PosMenu';
import purchasesMenu from './Menus/PurchasesMenu';
import raqeebMenu from './Menus/RaqeebMenu';
import reportsMenu from './Menus/ReportsMenu';
import salesMenu from './Menus/SalesMenu';
import suMenu from './Menus/SuMenu';
import userMenu from './Menus/UserMenu';

const Menu = [
  ...userMenu,
  ...payrollMenu,
  ...raqeebMenu,
  ...ameMenu,
  ...hrMenu,
  ...fassetsMenu,
  ...apMenu,
  ...arMenu,
  ...reportsMenu,
  ...suMenu,
  ...fleetMenu,
  ...ntxMenu,
  ...notificationsMenu,
  ...financeMenu,
  ...integrationMenu,
  ...salesMenu,
  ...inventoryMenu,
  ...purchasesMenu,
  ...posMenu,
];

export default Menu;
