import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import useGetNtxProfile from '../../../../hooks/NTX/useGetNtxProfile';
import QrCode from './QrCode';

const ProfileSidebar = () => {
  const { t, i18n } = useTranslation();
  const { UserProfile: Profile } = useGetNtxProfile();

  return (
    <>
      <div className="desktop-sticky-top">
        <div className="profile-img">
          {Profile?.personImage === null ? (
            <img src="../../../assets/img/user/manEmp.png" alt="" />
          ) : (
            <img
              src={Profile?.personImage?.filePath}
              alt=""
              // style={{ width: '200px', height: '200px' }}
            />
          )}
        </div>

        <h6>{i18n.language === 'ar' ? Profile?.name2 : Profile?.name}</h6>
        <div className="mb-3 text-inverse text-opacity-50 fw-bold mt-n2">
          {Profile?.emailAddress || '@Neatix'}
        </div>
        <hr className="mt-2 mb-2" />
        <QrCode />
      </div>
    </>
  );
};

export default ProfileSidebar;
