import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';

const initialState = {
  FormColumnList: [],
  loading: false,
  error: null,
  FormColumnByIdList: [],
  FormColumnByTableIdList: [],
};

export const GetFormColumn = createAsyncThunk(
  'FormColumn/GetFormColumn',
  async ({ id, withStatic }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    console.log(id , withStatic);
    try {
      const url = `DynamicForm/GetDynamicFormColumnList?DynamicFormId=${id}&withStatic=${withStatic}`;

      const { data } = await ntxAPI.get(url);
      return data.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetFormColumnById = createAsyncThunk(
  'FormColumn/GetFormColumnById',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`DynamicForm/GetDynamicFormColumnById?Id=${id}`);
      const { data } = await ntxAPI.get(`DynamicForm/GetDynamicFormColumnById?Id=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const insertFormColumn = createAsyncThunk(
  'FormColumn/insertFormColumn',
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.post('DynamicForm/AddDynamicFormColumn', item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteFormColumn = createAsyncThunk(
  'FormColumn/DeleteFormColumn',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(
        `DynamicForm/DeleteDynamicFormColumn?DynamicFormColumnId=${id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateFormColumn = createAsyncThunk(
  'FormColumn/UpdateFormColumn',
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.post('DynamicForm/UpdateDynamicFormColumn', item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetDynamicFormColumnListByTableId = createAsyncThunk(
  'FormColumn/GetDynamicFormColumnListByTableId',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`DynamicForm/GetDynamicFormColumnListByTableId?TableId=${id}`);
      const { data } = await ntxAPI.get(
        `DynamicForm/GetDynamicFormColumnListByTableId?TableId=${id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

const FormColumnSlice = createSlice({
  name: 'FormColumn',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all Dynamic Column Forms
    builder
      .addCase(GetFormColumn.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.FormColumnList = [];
      })
      .addCase(GetFormColumn.fulfilled, (state, action) => {
        state.loading = false;
        state.FormColumnList = action.payload;
      })
      .addCase(GetFormColumn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // get Dynamic Column Forms By Id
      .addCase(GetFormColumnById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.FormColumnByIdList = [];
      })
      .addCase(GetFormColumnById.fulfilled, (state, action) => {
        state.loading = false;
        state.FormColumnByIdList = action.payload;
      })
      .addCase(GetFormColumnById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // insert
      .addCase(insertFormColumn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insertFormColumn.fulfilled, (state, action) => {
        state.loading = false;
        state.FormColumnList.columnList.push(action.payload);
      })
      .addCase(insertFormColumn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete
      .addCase(DeleteFormColumn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteFormColumn.fulfilled, (state, action) => {
        state.loading = false;
        // state.FormColumnList = state.FormColumnList?.filter((el) => el.id !== action.payload);
      })
      .addCase(DeleteFormColumn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update Dynamic Forms by id
      .addCase(UpdateFormColumn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateFormColumn.fulfilled, (state, action) => {
        state.loading = false;
        // state.DynamicFormListByID = action.payload;
      })
      .addCase(UpdateFormColumn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // get Dynamic Column Forms By Table Id
      .addCase(GetDynamicFormColumnListByTableId.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.FormColumnByTableIdList = [];
      })
      .addCase(GetDynamicFormColumnListByTableId.fulfilled, (state, action) => {
        state.loading = false;
        state.FormColumnByTableIdList = action.payload.data;
      })
      .addCase(GetDynamicFormColumnListByTableId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default FormColumnSlice.reducer;
