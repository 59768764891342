import React, { useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetApInvoicesList from '../../../../../hooks/Finance/ApInvoices/useGetApInvoicesList';
import NeatixModal from '../../../../../components/Modal/NeatixModal';
import AddCreateAccountApInvoices from './AddCreateAccountApInvoices';
import DataTableComponent from '../../../../../components/DataTable/DataTableComponent';
import NtxTable from '../../../../../components/DataTable/NtxTable';

const ListApInvoices = () => {
  const [showModal, setShowModal] = useState(false);

  const [search, setSearch] = useState('');

  const { t, i18n } = useTranslation();

  const { ApInvoicesList, loading, error } = useGetApInvoicesList();

  //____________________________
  const [selectedApInvoices, setSelectedApInvoices] = useState<string[] | any>([]);
  const handleCheckboxChange = (row: any) => {
    setSelectedApInvoices((prevSelectedRows: any) => {
      const isSelected = prevSelectedRows?.some((item: any) => item.id === row.id);

      if (isSelected) {
        return prevSelectedRows?.filter((item: any) => item.id !== row.id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const handleOpenAddCreateAccount = () => {
    if (selectedApInvoices?.length > 0) {
      setShowModal(true);
    }
  };

  const columns = [
    { title: '', key: 'checkBox', className: 'text-center' },
    { title: t('InvoiceNumber'), key: 'invoiceNum' },
    { title: t('Supplier'), key: 'peopleName' },
    { title: t('InvoiceDate'), key: 'invoiceDate' },
    { title: t('InvoiceType'), key: 'invoiceType' },
    { title: t('Branch'), key: 'organizationName' },
    { title: t('Amount'), key: 'invoiceAmount' },
    { title: t('PostingStatus'), key: 'postedFlag', className: 'text-center' }, // إضافة حالة الترحيل
 
  ];

  const columns2 = [
    { title: '', key: 'checkBox', className: 'text-center' },
    { title: 'رقم الفاتورة', key: 'invoiceNum' },
    { title: 'المورد', key: 'peopleName' },
    { title: 'تاريخ الفاتورة', key: 'invoiceDate' },
    { title: 'نوع الفاتورة', key: 'invoiceType' },
    { title: 'الفرغ', key: 'organizationName' },
    { title: 'المبلغ', key: 'invoiceAmount' },
    { title: 'حالة الترحيل', key: 'postedFlag', className: 'text-center' }, // إضافة حالة الترحيل
  ];

  const tableData = ApInvoicesList?.filter((f) => !f.postedFlag).map((item) => {
    let rowClass;

    // تحديد الشارة بناءً على قيمة postedFlag
    const postedFlagBadge = item.postedFlag ? (
      <span className="badge bg-success">
        <i className="fas fa-check me-1"></i> {/* أيقونة للترحيل */}
        {t('Posted')}
      </span>
    ) : (
      <span className="badge bg-danger">
        <i className="fas fa-times me-1"></i> {/* أيقونة لعدم الترحيل */}
        {t('NotPosted')}
      </span>
    );
    return {
      checkBox: (
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`checkbox-${item.id}`}
            checked={selectedApInvoices?.some((row: any) => row.id === item.id)}
            onChange={() => handleCheckboxChange(item)}
          />
        </div>
      ),
      invoiceNum: item.invoiceNum,
      peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      invoiceDate: item.invoiceDate,
      invoiceType: item.invoiceType,
      organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
      invoiceAmount: item.invoiceAmount,
      postedFlag: postedFlagBadge, // استخدام الشارة هنا
    };
  });

  const options = {
    // paging: true,
    // pageLength: 10,
    // searching: true,
    // ordering: true,
    // lengthChange: true, //
    // info: false, //
    columnDefs: [{ orderable: false, targets: 0 }],
    order: [[1, 'desc']],
    // responsive: true,
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    // {
    //   name: 'إدارة المدفوعات',
    //   link: null,
    // },
    {
      name: 'PostPaymentInvoices',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="PostPaymentInvoices" />

        <div className="ms-auto">
          <button
            type="button"
            className="btn me-1 btn-theme "
            onClick={handleOpenAddCreateAccount}>
            <i className="fa fa-share-square fa-fw me-1"></i>
             {t('PostSelectedInvoices')}
          </button>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <NtxTable
                data={tableData}
                columns={columns}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch={true}
                showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        bodyClassName="p-0"
        size="xl"
        showHeader={false}>
        <Card>
          <CardBody>
            {selectedApInvoices?.length > 0 && (
              <AddCreateAccountApInvoices selectedApInvoices={selectedApInvoices} />
            )}
          </CardBody>
        </Card>
      </NeatixModal>
    </div>
  );
};

export default ListApInvoices;
