import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getApInvoicesListUnPaid } from '../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';

const useGetApInvoicesUnPaidList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (UnPaidInvoicesList?.length === 0) {
      dispatch(getApInvoicesListUnPaid());
    }
  }, []);
  const { UnPaidInvoicesList, loading, error } = useAppSelector((state) => state.ApInvoices);

  return { UnPaidInvoicesList, loading, error };
};

export default useGetApInvoicesUnPaidList;
