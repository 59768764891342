 


import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading.jsx';
 
// Lazy load payroll components
const Payroll = lazy(() => import('../../pages/HR/payroll/payroll/payroll.js'));
const ElementLink = lazy(() => import('../../pages/HR/payroll/payroll/ElementLink.js'));
const PayrollElement = lazy(() => import('../../pages/HR/payroll/payrollElement/PayrollElement.js'));
const EditElementType = lazy(() => import('../../pages/HR/payroll/payrollElement/EditElementType.js'));

const PayrollRun = lazy(() => import('../../pages/HR/payroll/PayrollRun/PayrollRun.js'));
const ActionList = lazy(() => import('../../pages/HR/payroll/PayrollRun/ActionList/ActionList.js'));
const SalaryReport = lazy(() => import('../../pages/HR/payroll/PayrollRun/ActionList/AssignmentAction.js'));
const QuickPay = lazy(() => import('../../pages/HR/payroll/PayrollRun/QuickPay.js'));

const PayrollElementEntry = lazy(() => import('../../pages/HR/payroll/PayrollElementEntry/PayrollElementEntry.js'));
const AddPayrollElementEntry = lazy(() => import('../../pages/HR/payroll/PayrollElementEntry/AddPayrollElementEntry.js'));
const AddPayProposal = lazy(() => import('../../pages/HR/payroll/PayProposal/AddPayProposal.js'));

// Define payroll routes with lazy loading
const payrollRoutes = [
  {
    path: 'Pay/*',
    children: [
      {
        path: 'Payrolls',
        element: (
          <Suspense fallback={<LazyLoading/>} >
            <Payroll />
          </Suspense>
        ),
      },
      {
        path: 'payrolls/:id/ElementLink',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <ElementLink />
          </Suspense>
        ),
      },
      {
        path: 'PayrollElement',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <PayrollElement />
          </Suspense>
        ),
      },
      {
        path: 'PayrollElement/:id/EditElementType',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <EditElementType />
          </Suspense>
        ),
      },
      {
        path: 'payrollsRun',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <PayrollRun />
          </Suspense>
        ),
      },
      {
        path: 'payrollsRun/:payrollId/ActionList',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <ActionList />
          </Suspense>
        ),
      },
      {
        path: 'payrollsRun/:payrollId/ActionList/:PayrollActionId/AssignmentActionList',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <SalaryReport />
          </Suspense>
        ),
      },
      {
        path: 'QuickPay',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <QuickPay />
          </Suspense>
        ),
      },
      {
        path: 'PayrollElementEntry',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <PayrollElementEntry />
          </Suspense>
        ),
      },
      {
        path: 'PayrollElementEntry/:assignmentId/AddPayrollElementEntry',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <AddPayrollElementEntry />
          </Suspense>
        ),
      },
      {
        path: 'PayrollElementEntry/:assignmentId/AddPayProposal',
        element: (
          <Suspense fallback={<LazyLoading/>}>
            <AddPayProposal />
          </Suspense>
        ),
      },
    ],
  },
];

export default payrollRoutes;
