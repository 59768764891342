import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { FormikProps } from 'formik';

interface Props {
  formik: FormikProps<any>;
}

interface Option {
  value: string;
  label: string;
}

const Reference: React.FC<Props> = ({ formik }) => {
  const { values } = formik;
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchUrl = useCallback((): string | null => {
    switch (values.code) {
      case 'INV': // فاتورة مبيعات
        return `SalesInvoice/GetTransactionsInvoiceListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`; // فاتورة مبيعات
      case 'SOI': // أمر بيع
        return `SalesOrderSalse/GetTransactionsOrderSalseListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`; // أمر بيع
      case 'IOI': // مستند تحويل
        return `InvMove/GetTransactionsMoveListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`; // مستند تحويل
      case 'SPR': // مردود مشتريات
        return `PurchasesReturns/GetListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`; // مردود مشتريات
      case 'BIL': // فاتورة مشتريات
        return `PurchaseBill/GetListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`;
      case 'POR': // أمر شـراء
        return `PurchaseOrder/GetListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`;
      case 'CRT': // مردود مبيعات
        return `SalesReturns/GetTransactionsReturnsListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`;
      case 'IOR': // استلام تحويــل
        return `InvIssue/GetTransactionsIssueListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`;

      case 'CCA':
      case 'MIR': // صرف
        return `Inv/GetInvTransactionTypeReasonsByTransactionTypeId?TransactionTypeId=fc437ea7-caf1-46ad-85b3-9dea0c5accdb`;

      case 'MII': // إضافة
        return `Inv/GetInvTransactionTypeReasonsByTransactionTypeId?TransactionTypeId=24e295ae-15d7-4f85-ad43-e7cfa4f8e9ad`;
      default:
        return null;
    }
  }, [values.code]);

  const GetData = useCallback(async () => {
    const url = fetchUrl();
    // const url = urlMap[values.code] || null;

    if (!url) {
      setData([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { data } = await ntxAPI.get(url);
      // debugger;

      if (data?.succeeded) {
        const formattedData = data.data
          ?.map((item: any) => {
            if (['MII', 'MIR'].includes(values.code)) {
              return {
                value: item.id,
                label: i18n.language === 'en' ? item.name : item.name2,
                transactionTypeId: item.transactionTypeId,
              };
            } else {
              return {
                value: item.id,
                label: item.code,
                transactionTypeId: item.transactionTypeId,
              };
            }
          })
          ?.filter((f: any) => {
            if (values.code === 'IOR') {
              return f.transactionTypeId === 'ae3fe2fd-f2cb-44f3-9ae2-a01e466df8b6';
            }
            return true;
          });
        setData(formattedData);
      } else {
        setError('Error !!');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error !!');
    } finally {
      setIsLoading(false);
    }
  }, [fetchUrl, i18n.language, values.code]);

  useEffect(() => {
    GetData();
  }, [GetData]);

  useEffect(() => {
    formik?.setFieldValue('referenceId', null);
  }, [values.code]);


   
  return (
    <>
      <label className="form-label">
        {(() => {
          switch (values.code) {
            case 'INV':
              return 'أختر فاتورة المبيعات';
            case 'SOI':
              return 'أختر أمر البيع';
            case 'IOI':
              return 'أختر مستند التحويل';
            case 'SPR':
              return 'اختر مردود المشتريات';
            case 'BIL':
              return 'اختر فاتورة المشتريات';
            case 'POR':
              return 'اختر أمر الشراء';
            case 'CRT':
              return 'اختر مردود المبيعات';
            case 'IOR':
              return 'اختر استلام التحويل';
            case 'MIR':
              return 'اختر سبب الإضافة';
            case 'MII':
              return 'اختر سبب الصرف';
            default:
              return 'اختر المرجع';
          }
        })()}
      </label>
      <Select
        isLoading={isLoading}
        classNamePrefix="react-select"
        isClearable
        isSearchable
        options={data}
        value={data?.find((f) => f.value === values?.referenceId) || null}
        onChange={(option: Option | null) => {
          formik.setFieldValue('referenceId', option ? option.value : null);
        }}
        placeholder={isLoading ? 'جاري تحميل البيانات' : error ? 'فشل تحميل البيانات' : 'اختر'}
        // className={`border ${isLoading ? ' border-theme ' : ''} ${error ? ' border-danger ' : ''}`}
      />
    </>
  );
};

export default Reference;
