import React, { useState } from 'react';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/Shared/Pagination/Pagination';
import Select from 'react-select';
import useGetGlLedgersList from '../../../hooks/Finance/useGetGlLedgersList';
import useGetJournalsList from '../../../hooks/Finance/Journals/useGetJournalsList';
import NtxTable from '../../../components/DataTable/NtxTable';

const Index = () => {
  const [selectedLedger, setSelectedLedger] = useState<any>(null);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const { OptionsLedgers, loading } = useGetGlLedgersList();
  const { JournalsList } = useGetJournalsList();

  const filteredSearch = JournalsList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return item.name?.toLowerCase().includes(searchLowerCase);
    })
    ?.filter((f) => (selectedLedger === null ? true : f.ledgerId === selectedLedger));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const columns = [
    { title: 'الوصف', key: 'name' },
    { title: 'دائن', key: 'Cr' },
    { title: 'مديـن', key: 'Dr' },
    { title: 'حالة القيد', key: 'status' },
    { title: '', key: 'action', className: 'text-end', sortable: false },
  ];

  const tableData = filteredSearch?.map((item) => {
    let rowClass;

    // تحديد الشارة بناءً على قيمة `status`
    const statusBadge = () => {
      switch (item.status) {
        case 'U':
          return (
            <span className="badge bg-danger text-dark">
              <i className="fas fa-times-circle me-1"></i> قيد غير مرحل
            </span>
          );
        case 'T':
          return (
            <span className="badge bg-warning text-dark">
              <i className="fas fa-clock me-1"></i> حفظ مؤقت
            </span>
          );
        case 'P':
          return (
            <span className="badge bg-success text-dark">
              <i className="fas fa-check-circle me-1"></i> قيد مرحل
            </span>
          );
        default:
          return '.';
          // return <span className="badge bg-secondary">غير معروف</span>;
      }
    };

    return {
      name: i18n.language === 'en' ? item.name : item.name2,
      Cr: item.accountedCr,
      Dr: item.accountedDr,
      status: statusBadge(),
      action: (
        <Link to={`${item.id}/UpdateJournals`} className="btn btn-outline-theme mx-2">
          <i className="fas fa-lg fa-fw fa-list me-2"></i>
          عرض تفاصيل القيد
        </Link>
      ),
    };
  });

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'القيود المالية',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="القيود المالية" />
      </div>

      <Card>
        <CardBody>
          <div className="p-1">
            <div className="row mb-2">
              <div className="col-lg-4">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">
                    {t('Search')}
                    {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                  </span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={selectedLedger ? 'col-lg-6' : 'col-lg-8'}>
                <div className="input-group flex-nowrap">
                  <span className="input-group-text  fw-bold">{t('الأستاذ العام')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className="w-100"
                    // isLoading={loading2}
                    value={OptionsLedgers.find((f) => f.value === selectedLedger)}
                    isSearchable={true}
                    isClearable
                    options={[
                      {
                        value: null,
                        label: t('All'),
                      },
                      ...OptionsLedgers,
                    ]}
                    onChange={(option) => setSelectedLedger(option === null ? null : option.value)}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    formatOptionLabel={(option) => (
                      <div className="d-flex justify-content-between">
                        <div className="">{option.label}</div>
                        <div className="text-theme fw-bold">{option.currencyCode}</div>
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-2 text-end">
                {selectedLedger && (
                  <Link to={`${selectedLedger}/AddJournals`} className="btn btn-theme   rounded-0">
                    <i className="fa fa-plus-circle fa-fw me-1 p-1"></i>
                    {t('إضافة قيـــد')}
                  </Link>
                )}
              </div>
            </div>

            {/* <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="bg-dark bg-opacity-75 ">
                  <tr>
                     <th className="pt-2 pb-2">{t('اسم القيـد')}</th>
                    <th className="pt-2 pb-2">{t('العملة')}</th>
                    <th className="pt-2 pb-2">{t('Debt')}</th>
                    <th className="pt-2 pb-2">{t('Credit')}</th>
                    <th className="pt-2 pb-2">{t('الحالة')}</th>
                    <th className="pt-2 pb-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.name : item.name2}
                      </td>
                      <td className="align-middle"> {item.currencyCode} </td>
                      <td className="align-middle"> {item.accountedDr} </td>
                      <td className="align-middle"> {item.accountedCr} </td>
                      <td className="align-middle"> {item.status} </td>

                      <td>
                        <Link
                          to={`${item.id}/UpdateJournals`}
                          className="btn btn-outline-theme mx-2">
                          <i className="far fa-lg fa-fw  fa-edit"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            /> */}

            <NtxTable
              data={tableData}
              columns={columns}
              dataPerPage={10}
              theadClass=""
              tableClass="table text-nowrap table-hover "
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Index;
