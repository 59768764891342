import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { consolidationSetModels } from './consolidationSetModels';

export const AddConsolidationSet = createAsyncThunk(
  'ConsolidationSet/AddConsolidationSet',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Finance/AddGlConsolidationSet', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const DeleteGlConsolidationSet = createAsyncThunk(
  'ConsolidationSet/DeleteGlConsolidationSet',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Finance/DeleteGlConsolidationSet', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrganizationToConsolidationSetList = createAsyncThunk(
  'ConsolidationSet/getOrganizationToConsolidationSetList',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // debugger
      const { data } = await ntxAPI.get('Finance/GetOrganizationToConsolidationSetList');
      if(data?.succeeded === true){
        return data.data;
      }else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ConsolidationSetList: consolidationSetModels[];
  loading: boolean;
  error: any | null;
} = {
  ConsolidationSetList: [],
  loading: false,
  error: null,
};

const ConsolidationSetSlice = createSlice({
  name: 'ConsolidationSet',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddConsolidationSet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddConsolidationSet.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        // state.DailyRatesList.push(action.payload.data);
      })
      .addCase(AddConsolidationSet.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getOrganizationToConsolidationSetList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getOrganizationToConsolidationSetList.fulfilled,
        (state, action: PayloadAction<consolidationSetModels[]>) => {
          state.loading = false;
          state.ConsolidationSetList = action.payload;
        }
      )
      .addCase(
        getOrganizationToConsolidationSetList.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default ConsolidationSetSlice.reducer;
