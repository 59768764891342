import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../../components/Shared/Pagination/Pagination.jsx';
import useLookupModule from '../../../../../hooks/Lookups/use-Lookup-Module';
import { Card } from '../../../../../components/card/card';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { GetReportsList } from '../../../../../store/NTX/Reports/reportSlice';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { usePermissions } from '../../../../../Helper/Permisstions/usePermissions';
import Loading from '../../../../../components/Shared/Loader/Loading';

interface Report {
  id: string;
  name: string;
  name2: string;
  moduleId: string;
  moduleName: string;
  moduleName2: string;
  tableName: string;
  icon: string;
  reportSourceName: string;
  reportSourceName2: string;
  // query: string;
  // description: string;
  // pageType: string;
  // reportType: string;
}

interface ReportsListState {
  ReportsList: Report[];
  loading: boolean;
  // Add any other properties you have in the ReportsList
}

interface LookupModule {
  Module: any[];
}

function Index() {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState<string>('');
  // const [sortBy, setSortBy] = useState<string>("");
  // const [sortOrder, setSortOrder] = useState<string>("asc");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPerPage] = useState<number>(8);
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  //__________________________________________________
  const { ReportsList, loading } = useAppSelector((state) => state.Reports) as ReportsListState;
  useEffect(() => {
    if (ReportsList?.length === 0) {
      dispatch(GetReportsList());
    }
  }, [dispatch]);

  //_____________________________________________________

  const [selectedValue, setSelectedValue] = useState('');
  const { Module }: LookupModule = useLookupModule();

  //_________________________________________________________________________________________________

  const filteredSearch = ReportsList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.tableName?.toLowerCase().includes(searchLowerCase) ||
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === '' ? true : f.moduleId === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Reports'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Reports_Management')} />

        <div className="ms-auto">
          {HasPermission('NtxAddReport') && (
            <button className="btn btn-theme" onClick={() => Navigate(`/RPT/Reports/AddReport`)}>
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('Add_Reports')}
            </button>
          )}
        </div>
      </div>
      <Card>
        <Loading loading={loading}>
          <div className="tab-content p-3">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group mb-2">
                      <select
                        className="btn btn-outline-default p-0 dropdown-toggle"
                        value={selectedValue}
                        onChange={(event) => setSelectedValue(event.target.value)}>
                        <option className="dropdown-item" value={''}>
                          {t('All')}
                        </option>
                        {Module &&
                          Module?.map((item) => (
                            <option value={item.id} key={item.id} className="dropdown-item">
                              {i18n.language === 'ar' ? item.name2 : item.name}
                            </option>
                          ))}
                      </select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text" 
autoComplete='off'
                            className="form-control ps-35px"
                            placeholder={t('Search')}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th style={{ width: '20%' }}>{t('Reports_Name')}</th>
                    <th style={{ width: '20%' }}>{t('Data_Source')}</th>
                    <th style={{ width: '20%' }}>{t('Application')}</th>
                    <th style={{ width: '20%' }}>{t('Icon')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.name : item.name2}
                      </td>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.reportSourceName : item.reportSourceName2}
                      </td>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.moduleName : item.moduleName2}
                      </td>
                      <td className="align-middle">
                        <div className="text-theme fs-4">
                          <i className={item.icon}></i>
                        </div>
                      </td>
                      <td>
                        <div className="btn-group">
                        {HasPermission('NtxReportCharts') && (
                          <button
                            type="button"
                            onClick={() => Navigate(`/RPT/Reports/${item.id}/AddReportShape`)}
                            className="btn btn-outline-theme ">
                            <i className="  fab fa-lg fa-fw me-1 bi bi-bar-chart-line"></i>
                            {/* <i className=" text-primary fab fa-lg fa-fw m-1 bi bi-pie-chart-fill"></i> */}
                            {/* <i className=" text-green  fab fa-lg fa-fw m-1 bi bi-graph-up-arrow"></i> */}
                            {t('Charts_Report')}
                          </button>
                        )}

                        {HasPermission('NtxEditReport') && (
                          <button
                            type="button"
                            className="btn btn-outline-theme "
                            onClick={() => {
                              const newLocation = `/RPT/Reports/${item.id}/UpdateReport`;
                              window.location.href = newLocation;
                            }}>
                            <i className="far fa-lg fa-fw me-1 fa-edit"></i>
                            {t('Edit_Reports')}
                          </button>
                        )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: number) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </Loading>
      </Card>
    </>
  );
}

export default Index;
