import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import { GetCurrenciesList, InsertCurrencies } from '../../../../store/NTX/Currency/currencySlice';

const Add = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      currencyCode: '',
      description: '',
      enabledFlag: false,
    },
    // validationSchema: AddCashBoxesSchema(t),
    onSubmit: (values) => {
      dispatch(
        InsertCurrencies({
          currencyCode: values.currencyCode,
          description: values.description,
          enabledFlag: values.enabledFlag,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            document.getElementById('closeModalForm')?.click();
            dispatch(GetCurrenciesList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const { values, errors, touched, handleChange } = formik;

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label">{t('CurrencyName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                autoComplete="off"
                name="description"
                onChange={handleChange}
                value={values.description}
                isInvalid={!!(touched.description && errors.description)}
              />
              <div className="text-danger small">{touched.description && errors.description}</div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label">{t('CurrencyCode')}</label>
              <Form.Control
                className="form-control"
                type="text"
                autoComplete="off"
                name="currencyCode"
                onChange={handleChange}
                value={values.currencyCode}
                isInvalid={!!(touched.currencyCode && errors.currencyCode)}
              />
              <div className="text-danger small">{touched.currencyCode && errors.currencyCode}</div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <Form.Check
                style={{ margin: '10px 20px' }}
                className="pt-1 pb-2 ms-auto"
                type="checkbox"
                label={t('Active')}
                name="enabledFlag"
                checked={formik.values.enabledFlag}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </>
  );
};

export default Add;
