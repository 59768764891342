import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTransactionTypesIssueList } from '../../../store/INV/Transactions/InvIssue/invIssueSlice';
import { useTranslation } from 'react-i18next';

const useGetTransactionTypesIssueList = () => {
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (TransactionTypes?.length === 0) {
      dispatch(getTransactionTypesIssueList());
    }
  }, []);
  const {
    TransactionTypesIssueList: TransactionTypes,
    loading,
    error,
  } = useAppSelector((state) => state.InvIssue);

  const OptionsTransactionTypes = TransactionTypes?.map((item) => ({
    value: item.code,
    label: i18n.language === 'en' ? item.name : item.name2,
    transTypeId: item.id,
    isDisabled: ['WOI', 'MOI'].includes(item.code),
  }));

  return { TransactionTypes, OptionsTransactionTypes, loading, error };
};

export default useGetTransactionTypesIssueList;
