import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Shared/Pagination/Pagination.jsx";
import useGetOrganizations from "../../../../hooks/HR/use-get-organizations.js";
import useGetLocations from "../../../../hooks/HRSetting/use-get-locations.js";

const TableSummaryList = ({ SummaryList, toDate, fromDate }) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const [orgName, setOrgName] = useState(null);
  const [LocationName, setLocationName] = useState(null);
  const { loading, OrganizationOptions } = useGetOrganizations();

  const { Locations } = useGetLocations();

  useEffect(() => {
    setCurrentPage(1);
  }, [SummaryList]);

  const filteredSearch = SummaryList?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.employeeNumber?.toLowerCase().includes(searchLowerCase) ||
      item.employeeName?.toLowerCase().includes(searchLowerCase) ||
      item.employeeName2?.toLowerCase().toString().includes(searchLowerCase)
    );
  })
    ?.filter((f) =>
      orgName === null
        ? true
        : f?.organizationName === orgName || f.organizationName2 === orgName
    )?.filter((f) =>
    LocationName === null
      ? true
      : f?.locationName === LocationName || f.locationName2 === LocationName
  )
    ?.sort((a, b) => a.absence - b.absence);

  //   ?.filter((f) =>
  //   selectedValue === "" ? true : f.moduleId === selectedValue
  // );

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    const headline = [
      ["Names of employees for Branch No. 7"], // Your headline goes here
      [], // Empty array for spacing
    ];

    // Add headline to worksheet
    // XLSX.utils.sheet_add_aoa(worksheet, headline, { origin: -1 });

    const csvContent = filteredSearch?.map((entry) => ({
      "Employee Number": entry.employeeNumber,
      "EmployeeName": entry.employeeName,
      // "EmployeeName 2": entry.employeeName2,
      "Location Name": entry.locationName,
      "Organization Name": entry.organizationName,
      "Rotation Plan Name": entry.rotationPlanName,
      "Duration": entry.durationS,
      "Actual Duration": entry.durationActS,
      "Delay": entry.delayS,
      "Extra Hours": entry.overTimeS,
      "Absence": entry.absence,
    }));

    const worksheet = XLSX.utils.json_to_sheet(csvContent);
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `${fromDate} => ${toDate}`
    );
    
    XLSX.writeFile(workbook, "Azzad_Summary_analysis_data.xlsx");
  };

  return (
    <div>
      <div className="tab-content p-1">
        <div className="row">
          <div className="col-lg-2 d-flex justify-content-start">
            <div className="input-group mb-4">
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                    style={{ zIndex: 1020, right: "10px" }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text" 
autoComplete='off'
                    className="form-control ps-35px"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t("Search") + " ..."}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group mb-3">
              <Select
                classNamePrefix="react-select"
                isLoading={loading}
                isSearchable={true}
                isClearable
                options={OrganizationOptions}
                onChange={(option) => {
                  setOrgName(option === null ? null : option.label);
                  setCurrentPage(1);
                }}
                placeholder={t("Department")}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group mb-3">
              <Select
                classNamePrefix="react-select"
                isLoading={loading}
                isSearchable={true}
                isClearable
                options={Locations?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(option) => {
                  setLocationName(option === null ? null : option.label);
                  setCurrentPage(1);
                }}
                placeholder={t("location")}
              />
            </div>
          </div>
          <div className="col-lg-2 ">
            <button
              onClick={exportToExcel}
              className="btn btn-outline-theme text-nowrap rounded-2"
              tabIndex={0}
              aria-controls="datatable"
              type="button"
            >
              <span>
                <i className="fa fa-file-excel fa-fw me-1" /> {t("ExportData")}
              </span>
            </button>
          </div>

          <div className="col-lg-2 text-end">
            <Link
              to=""
              className="btn me-1 btn-outline-secondary "
              data-bs-dismiss="modal"
            >
              {t("Cancel")}
            </Link>
          </div>
        </div>

      

        <div className=" bg-inverse bg-opacity-5 rounded-4  ">
          <div className="table-responsive rounded-4">
            <table className="table  ">
              <thead className="table-dark">
                <tr>
                  <th scope="col"> {t("Name")}</th>
                  <th scope="col">{t("EmployeeNumber")}</th>
                  <th scope="col">{t("location")}</th>
                  <th scope="col">{t("Organization")}</th>

                  <th scope="col">{t("Duration")}</th>
                  <th scope="col">{t("LATE")}</th>
                  <th scope="col">{t("ExtraHours")}</th>
                  <th scope="col">{t("DaysAbsence")}</th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index) => (
                  <tr
                    className={item.missPunch ? "bg-inverse bg-opacity-15" : ""}
                    key={++index}
                  >
                    <td className="align-middle">
                      {i18n.language === "en"
                        ? item.employeeName
                        : item.employeeName2}
                    </td>
                    <td className="align-middle">{item.employeeNumber}</td>

                    <td className="align-middle">
                      {i18n.language === "en"
                        ? item.locationName
                        : item.locationName2}
                    </td>
                    <td className="align-middle">
                      {i18n.language === "en"
                        ? item.organizationName
                        : item.organizationName2}
                    </td>

                    <td className="align-middle">{item.durationActS}</td>

                    <td className="align-middle">{item.delayS}</td>
                    <td className="align-middle">{item.overTimeS}</td>
                    <td className="align-middle">{item.absence}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TableSummaryList;

// <tr>
//   <th scope="col">Name</th>
//   <th scope="col">empNo</th>
//   <th scope="col"> transitionDate</th>
//   <th scope="col">fingerprintTimeAct1</th>
//   <th scope="col">fingerprintTimeAct2</th>
//   <th scope="col">durationAct1</th>
//   <th scope="col">dely1</th>
// </tr>
