import React, { useState } from 'react';
import Select from 'react-select';

import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';

import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import AddUpdateConsolidation from './AddUpdateConsolidation';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import useGetConsolidationSet from '../../../../hooks/Finance/ConsolidationSet/useGetConsolidationSet';
import Loading from '../../../../components/Shared/Loader/Loading';
import { consolidationSetModels } from '../../../../store/Finance/ConsolidationSet/consolidationSetModels';

const ConsolidationSet = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState<consolidationSetModels>();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const { ConsolidationSetList, loading, error } = useGetConsolidationSet();
  const [type, setType] = useState<string | null>('Company');

  // console.log(ConsolidationSetList);

  const filteredSearch = ConsolidationSetList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.organizationName?.toLowerCase().includes(searchLowerCase) ||
        item.organizationName2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => {
      if (type === 'Company') {
        return f.organizationType === '30';
      } else if (type === 'branch') {
        return f.organizationType === '150';
      } else if (type === 'CostCenter') {
        return f.organizationType !== '150' && f.organizationType !== '30';
      }
    })
    ?.sort((a, b) => {
      const aLinked = a.ledgerId && a.chartOfAccountsDetailsId ? 1 : 0;
      const bLinked = b.ledgerId && b.chartOfAccountsDetailsId ? 1 : 0;
      return bLinked - aLinked; // المرتبطين أولا
    });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: 'الربط بين الإدارات',
      link: null,
    },
  ];

  const titleModal = () => {
    return (
      <div className="">
        {t('ربط الجهة الإدارية بالحسابات')}
        {'  '}
        <span className="text-theme small">
          {i18n.language === 'en' ? data?.organizationName : data?.organizationName2}
        </span>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName="الربط بين الادارات"
          subtitle={options.find((f) => f.value === type)?.desc}
        />
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody className="p-3">
            <div className="row mb-2">
              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('Search')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text  fw-bold">{t('النوع')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className={`w-100 `}
                    isSearchable={true}
                    isClearable
                    options={options}
                    value={options.find((f) => f.value === type)}
                    onChange={(option: any) => {
                      setType(option === null ? null : option.value);
                      setCurrentPage(1);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th className="">{t('الجهة الإدارية')}</th>
                    <th className="">{t('الحساب المالي')}</th>
                    <th className="">{t('الحساب الرئيسي')}</th>
                    <th className="text-center">{t('الإجراءات')}</th>
                  </tr>
                </thead>

                <tbody>
                  {currentData?.map((item) => (
                    <tr key={item.organizationId}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.organizationName : item.organizationName2}
                      </td>

                      <td className="align-middle">
                        {item.chartOfAccountsDetailsId ? (
                          i18n.language === 'en' ? (
                            item.chartOfAccountsDetailsName
                          ) : (
                            item.chartOfAccountsDetailsName2
                          )
                        ) : (
                          <span className="badge bg-warning text-dark">{t('غير مرتبط')}</span>
                        )}
                      </td>
                      <td className="align-middle">
                        {item.ledgerId ? (
                          i18n.language === 'en' ? (
                            item.ledgerName
                          ) : (
                            item.ledgerName2
                          )
                        ) : (
                          <span className="badge bg-warning text-dark">{t('غير مرتبط')}</span>
                        )}
                      </td>
                      <td className="align-middle text-end">
                        <button
                          type="button"
                          onClick={() => {
                            setData(item);
                            setShow(true);
                            // setShow2(true);
                          }}
                          className="btn btn-outline-theme ms-2 w-100">
                          <i className="fa-lg bi bi-link-45deg me-1 "></i>
                          {t('ربط')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        setShow={setShow}
        show={Show}
        title={titleModal() as unknown as string}
        size="md">
        {data && type && <AddUpdateConsolidation data={data} type={type} />}
      </NeatixModal>
    </div>
  );
};

export default ConsolidationSet;

const options = [
  {
    desc: 'الشركات',
    value: 'Company',
    label: (
      <div className="d-fl">
        <i className="fa fa-building mx-2 fa-lg  text-theme"></i>
        الشركات
      </div>
    ),
  },
  {
    desc: 'الفروع',
    value: 'branch',
    label: (
      <div>
        <i className="fa fa-sitemap fa-lg  mx-2  text-theme"></i>
        الفروع
      </div>
    ),
  },
  {
    desc: ' مراكز التكلفة',
    value: 'CostCenter',
    label: (
      <div>
        <i className="fa fa-dollar-sign fa-lg mx-2  text-theme"></i>
        مراكز التكلفة
      </div>
    ),
  },
];
