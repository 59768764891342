import Select from 'react-select';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import { useTranslation } from 'react-i18next';

export const SelectAccount = ({ label, label2, value, onChange }) => {
  const { OptionsAccounts, loading } = useGetAccounts();
  const { t, i18n } = useTranslation();

  return (
    <div className="form-group mb-3">
      <label className="form-label">{i18n.language === 'en' ? label : label2}</label>
      <Select
        classNamePrefix="react-select"
        isLoading={loading}
        isSearchable={true}
        isClearable
        options={OptionsAccounts}
        value={  OptionsAccounts?.find((f) => f.value === value)}
        onChange={onChange}
        placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
      />
    </div>
  );
};
