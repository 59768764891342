import React from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetApExpenseList from '../../../../hooks/Finance/Expense/useGetApExpenseList';
import NtxTable from '../../../../components/DataTable/NtxTable';

const Index = () => {
  const { t, i18n } = useTranslation();

  const { ApExpenseList, loading, error } = useGetApExpenseList();

  const columns = [
    { title: t('ExpenseCategory'), key: 'ExpenseCategory', sortable: false },
    { title: t('ExpenseDate'), key: 'ExpenseDate' },
    { title: t('TotalAmount'), key: 'TotalAmount' },
    { title: t('Supplier'), key: 'Supplier' },
    { title: t('Branch'), key: 'organizationName' },

    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData = ApExpenseList.map((item) => {
    let rowClass;

    return {
      ExpenseCategory:
        i18n.language === 'en' ? item.expenseCategoryName : item.expenseCategoryName2,
      ExpenseDate: item.expenseDate?.toString(),
      TotalAmount: item.totalAmount,
      Supplier: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
      action: (
        <button type="button" className="btn btn-outline-theme mx-1">
          {t('عرض التفاصيل')}
        </button>
      ),
      rowClass, // استخدم rowClass هنا
    };
  });

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'تقرير المصروفات',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="تقرير المصروفات" />
        <div className="ms-auto">
          <Link to="AddApExpense" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            تقرير جديـد
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <NtxTable
                data={tableData}
                columns={columns}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch
                showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Index;
