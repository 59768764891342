import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { InventoryCountModels, EnteriesListModel } from './inventoryCountModels';

// https://neatixapi.aljown.com/api/InventoryCount/AddInventoryCount

export const addInventoryCount = createAsyncThunk(
  'InventoryCount/addInventoryCount',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InventoryCount/AddInventoryCount', item);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const addInventoryCountEntries = createAsyncThunk(
  'InventoryCount/addInventoryCountEntries',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InventoryCount/AddInventoryCountEntries', item);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const updatInventoryCount = createAsyncThunk(
  'InventoryCount/updatInventoryCount',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('InventoryCount/UpdatInventoryCount', item);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const getInventoryCountOpen = createAsyncThunk(
  'InventoryCount/getInventoryCountOpen',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`InventoryCount/GetInventoryCountOpen`);

      if (data?.succeeded) {
        return data.data;
      } else {
        // return data.data;
        return rejectWithValue('error');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const getInventoryCountClosed = createAsyncThunk(
  'InventoryCount/getInventoryCountClosed',
  async (ParentId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`InventoryCount/GetInventoryCountClosed`);
      if (data.succeeded === true) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const getInventoryCountById = createAsyncThunk(
  'InventoryCount/getInventoryCountById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`InventoryCount/GetInventoryCountById?Id=${Id}`);
      if (data.succeeded === true) {
        return data.data;
      } else {
        return data.data;
        // return rejectWithValue(error.message);

        // return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getEnteriesListByInventoryCountId = createAsyncThunk(
  'InventoryCount/getEnteriesListByInventoryCountId',
  async (InventoryCountId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `InventoryCount/GetEnteriesListByInventoryCountId?InventoryCountId=${InventoryCountId}`
      );
      if (data.succeeded === true) {
        return data.data;
      } else {
        // return data.data;
        return rejectWithValue(data.message);

        // return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  InventoryCountList: InventoryCountModels[];
  InventoryCountOpenList: InventoryCountModels[];
  InventoryCountClosedList: InventoryCountModels[];
  InventoryCountById: InventoryCountModels;
  EnteriesList: EnteriesListModel[];
  loading: boolean;
  error: any | null;
} = {
  InventoryCountList: [],
  InventoryCountClosedList: [],
  InventoryCountOpenList: [],
  InventoryCountById: {} as InventoryCountModels,
  EnteriesList: [],
  loading: false,
  error: null,
};

const SubInventorySlice = createSlice({
  name: 'InventoryCount',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(addInventoryCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addInventoryCount.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action?.payload?.succeeded === true) {
          state.InventoryCountList.push(action.payload.data);
        }
      })
      .addCase(addInventoryCount.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getInventoryCountOpen.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInventoryCountOpen.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.InventoryCountOpenList = action.payload;
      })
      .addCase(getInventoryCountOpen.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getInventoryCountById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getInventoryCountById.fulfilled,
        (state, action: PayloadAction<InventoryCountModels>) => {
          state.loading = false;
          state.InventoryCountById = action.payload;
        }
      )
      .addCase(getInventoryCountById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //________________________________________________________________
      .addCase(getEnteriesListByInventoryCountId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getEnteriesListByInventoryCountId.fulfilled,
        (state, action: PayloadAction<EnteriesListModel[]>) => {
          state.loading = false;
          state.EnteriesList = action.payload;
        }
      )
      .addCase(getEnteriesListByInventoryCountId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default SubInventorySlice.reducer;
