import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useGetInventoryCountById from '../../../../hooks/INV/InventoryCount/useGetInventoryCountById';
import { useParams } from 'react-router-dom';
import useGetInventoryItems from '../../../../hooks/INV/MasterItems/useGetInventoryItems';

interface LinesProps {
  formik: FormikProps<any>;
}

const CountLine: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;
  const { invCountId } = useParams();
  const { CountItemsOptions, data } = useGetInventoryCountById({ invCountId: invCountId });

  const { InventoryItemsOptions, loading, error } = useGetInventoryItems(data.inventoryId);

  // console.log(InventoryItemsOptions);

  const { i18n, t } = useTranslation();
  // const [Lines, setLines] = useState<any[]>(formik?.values.inventoryCountEntriesLineList || []);
  const [selectedItem, setSelectedItem] = useState<any>(null); // لحفظ الصنف المؤقت
  const [selectedUom, setSelectedUom] = useState<any>(null); // لحفظ الصنف المؤقت
  const [countQuantity, setCountQuantity] = useState<number | string>(''); // لحفظ الرصيد المؤقت
  // console.log(Lines);

  const handleAddLine = () => {
    if (selectedItem && countQuantity !== '') {
      const newLine = {
        ItemId: selectedItem.value,
        invCountItemsId: selectedItem.invCountItemsId,
        countQuantity: Number(countQuantity),
        unitOfMeasure: selectedUom,
        //
        itemName: InventoryItemsOptions.find((f) => f.itemId === selectedItem.value)?.itemName,
        UomName: InventoryItemsOptions.find((f) => f.uom === selectedUom)?.uomName,
      };

      const updatedLines = [...(values.inventoryCountEntriesLineList || []), newLine]; // Ensure inventoryCountEntriesLineList is always an array
      formik.setFieldValue('inventoryCountEntriesLineList', updatedLines);

      // إعادة تعيين الحقول بعد الإضافة
      setSelectedItem(null);
      setSelectedUom(null);
      setCountQuantity('');
    }
  };

  // دالة handleChange لتحديث جميع الحقول
  const handleChange = (field: string, value: any) => {
    // debugger
    switch (field) {
      case 'selectedItem':
        setSelectedItem(value);
        setSelectedUom(
          InventoryItemsOptions?.find((f) => f.itemId === value?.itemId && f.isPrimaryUOM)?.uom
        );
        break;
      case 'selectedUom':
        setSelectedUom(value);
        break;
      case 'countQuantity':
        setCountQuantity(value);
        break;
      default:
        break;
    }
  };
  console.log(selectedItem);

  return (
    <>
      <div className="mb-3">
        <div className="d-flex align-items-center mb-3">
          <div className="w-50 me-2">
            <Select
              classNamePrefix="react-select"
              className="w-100"
              isSearchable
              isClearable
              options={CountItemsOptions}
              // onChange={(option) => setSelectedItem(option)}
              onChange={(option) => handleChange('selectedItem', option)}
              value={selectedItem}
              placeholder={i18n.language === 'ar' ? 'إختر الصنف' : 'Choose item'}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              }}
              formatOptionLabel={(option) => (
                <div className="d-flex justify-content-between">
                  <div>{option.label}</div>
                  <div className="text-theme fw-bold">{option.systemQuantity}</div>
                </div>
              )}
            />
          </div>

          <div className="w-25 me-2">
            <select
              className="form-select text-center"
              value={selectedUom}
              onChange={(e) => handleChange('selectedUom', e.target.value)}
              // onChange={(e) => setSelectedUom(e.target.value)}
            >
              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
              {InventoryItemsOptions?.filter((f) => f.itemId === selectedItem?.itemId)?.map(
                (item, idx) => (
                  <option key={++idx} value={item.uom}>
                    {item.uomName}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="w-25 me-2">
            <input
              type="text" 
autoComplete='off'
              className="form-control text-center"
              placeholder={i18n.language === 'ar' ? 'أدخل الرصيد' : 'Enter quantity'}
              value={countQuantity}
              // onChange={(e) => setCountQuantity(e.target.value.replace(/[^0-9]/g, ''))}
              onChange={(e) => handleChange('countQuantity', e.target.value.replace(/[^0-9]/g, ''))}
            />
          </div>
          <button type="button" className="btn btn-theme" onClick={handleAddLine}>
            {i18n.language === 'ar' ? 'إضافة' : 'Add'}
          </button>
        </div>

        <div
          className={`table-responsive rounded-2 text-center mb-2 ${
            formik.errors.inventoryCountEntriesLineList ? 'border border-2 border-danger' : ''
          }`}>
          <table className="table table-bordered  table-hover mb-0 text-nowrap">
            <thead className="bg-theme bg-opacity-30">
              <tr>
                <th className="align-middle" style={{ width: '3%' }}>
                  #
                </th>
                <th className="align-middle" style={{ width: '59%' }}>
                  {t('اسم الصنف')}
                </th>
                <th className="align-middle" style={{ width: '59%' }}>
                  {t('الوحدة')}
                </th>
                <th className="align-middle " style={{ width: '10%' }}>
                  {t('رصيد الجرد')}
                </th>
              </tr>
            </thead>
            <tbody className="fw-bold">
              {values?.inventoryCountEntriesLineList?.slice()?.map((line: any, index: any) => (
                <tr key={index}>
                  <td className="align-middle bg-theme bg-opacity-30 small">{index + 1}</td>
                  <td className="align-middle small">{line.itemName}</td>
                  <td className="align-middle small">{line.UomName}</td>
                  <td className="align-middle text-theme fw-bold">{line.countQuantity}</td>{' '}
                  {/* عرض الرصيد */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CountLine;
