import Loading from '../../../../components/Shared/Loader/Loading';
import AttendanceAnalysis from './AttendanceAnalysis.js';

const RaqeebDashboard = () => {
  return (
    <div>
      <Loading loading={false} error={null}>
        <>
          <AttendanceAnalysis />
        </>
      </Loading>
    </div>
  );
};

export default RaqeebDashboard;
