import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getIncomeStatement } from '../../../../store/Finance/FinancialReports/FinancialReportsSlice';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/Shared/Loader/Loading';

const IncomeStatement = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState({ startData: '', endData: '', accountlevel: '1' });

  useEffect(() => {
    dispatch(
      getIncomeStatement({
        startDate: filter.startData,
        endDate: filter.endData,
        accountlevel: filter.accountlevel,
      })
    );
  }, []);

  const handleDispatch = () => {
    dispatch(
      getIncomeStatement({
        startDate: filter.startData,
        endDate: filter.endData,
        accountlevel: filter.accountlevel,
      })
    );
  };

  const { IncomeStatement: data, loading, error } = useAppSelector((s) => s.FinancialReports);

  const revenues = data?.list?.find((item) => item.accountNumber === '40000000');

  const expenses = data?.list?.find((item) => item.accountNumber === '50000000');

  const NetLoss = data?.list?.find((item) => item.accountNumber === '00000000');

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'قائمة الدخـــل',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="قائمة الدخــل" />
      </div>

      <Card>
        <CardBody>
          <div className="tab-content p-1">
            <div className="row mb-2">
              <div className="col-lg-3">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('من تاريـخ')}</span>
                  <div className="input-group">
                    <input
                      type="date"
                      autoComplete="off"
                      className="form-control"
                      value={filter.startData}
                      onChange={(e) => {
                        setFilter({ ...filter, startData: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('إلى تاريـخ')}</span>
                  <div className="input-group">
                    <input
                      type="date"
                      autoComplete="off"
                      className="form-control"
                      value={filter.endData}
                      onChange={(e) => {
                        setFilter({ ...filter, endData: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('مستوى الحساب')}</span>
                  <select
                    className="form-select"
                    value={filter.accountlevel}
                    onChange={(e) => {
                      setFilter({ ...filter, accountlevel: e.target.value });
                    }}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-2">
                <button className="btn btn-theme w-100" onClick={handleDispatch}>
                  {t('تحديث')}
                </button>
              </div>
            </div>

            <Loading loading={loading} error={error} Type="Dots">
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="bg-theme bg-opacity-40">
                    <tr>
                      <th>{t('رقم الحسـاب')}</th>
                      <th>{t('اسم الحســاب')}</th>
                      <th>{t('المدين (DR)')}</th>
                      <th>{t('الدائن (CR)')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenues?.children?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">{item.accountNumber}</td>
                        <td className="align-middle">
                          {i18n.language === 'en' ? item.name : item.name2}
                        </td>
                        <td className="align-middle">{item.amounT_DR}</td>
                        <td className="align-middle">{item.amounT_CR}</td>
                      </tr>
                    ))}
                    <tr className="bg-theme bg-opacity-15  fw-bold ">
                      <td className="align-middle text-center" colSpan={2}>
                        إجمالي الدخل
                      </td>
                      <td className="align-middle ">{revenues?.amounT_DR}</td>
                      <td className="align-middle ">{revenues?.amounT_CR}</td>
                    </tr>
                    <tr>
                      <td colSpan={4} className="bg-dark" style={{ height: '1px' }}></td>
                    </tr>
                    {/* فاصل */}
                    {expenses?.children?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">{item.accountNumber}</td>
                        <td className="align-middle">
                          {i18n.language === 'en' ? item.name : item.name2}
                        </td>
                        <td className="align-middle">{item.amounT_DR}</td>
                        <td className="align-middle">{item.amounT_CR}</td>
                      </tr>
                    ))}
                    <tr className="bg-theme bg-opacity-15 fw-bold  ">
                      <td className="align-middle text-center" colSpan={2}>
                        إجمالي المصاريـف
                      </td>
                      <td className="align-middle ">{expenses?.amounT_DR}</td>
                      <td className="align-middle ">{expenses?.amounT_CR}</td>
                    </tr>
                    <tr>
                      <td colSpan={4} className="bg-dark" style={{ height: '1px' }}></td>
                    </tr>
                    {/* فاصل */}
                    <tr className="bg-theme bg-opacity-15 fw-bold ">
                      <td className="align-middle text-center text-theme fs-5" colSpan={2}>
                        {i18n.language === 'en' ? NetLoss?.name : NetLoss?.name2}
                      </td>
                      <td className="align-middle text-theme fs-5">{NetLoss?.amounT_DR}</td>
                      <td className="align-middle text-theme fs-5">{NetLoss?.amounT_CR}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Loading>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default IncomeStatement;
