import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import Select from 'react-select';
import useGetPurchasesQuotationList from '../../../../hooks/PUR/PurchaseQuotation/useGetPurchasesQuotationList';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import PurchaseRequestList from '../PurchaseRequest/PurchaseRequestList';
import useGetPurchaseRequestList from '../../../../hooks/PUR/PurchaseRequest/useGetPurchaseRequestList';
import QuotationComparison from './QuotationComparison';
import { Card, CardBody } from '../../../../components/card/card';

const Index = () => {
  const [show, setShow] = useState(false);
  const [PurchaseRequesId, setPurchaseRequesId] = useState<any>(null);

  const [Quotations, setQuotations] = useState<any>();
  const [show2, setShow2] = useState(false);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const { PurchaseRequesOptions, loading: loading2, error: error2 } = useGetPurchaseRequestList();

  const { PurchaseQuotationList, loading, error } = useGetPurchasesQuotationList({
    PurchaseRequestId: PurchaseRequesId,
  });

  const filteredSearch = PurchaseQuotationList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.sort((a, b) => b?.no - a?.no);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'عروض الأسعار',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="عروض الأسعار" />
        <div className="ms-auto">
          <Link to="" className="btn me-1 btn-theme" onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            عرض سعر جديد
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <div className="row mb-2">
                <div className="col-lg-4">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">{t('Search')}</span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control ps-35px"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={PurchaseRequesId ? "col-lg-6" : "col-lg-8"}>
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text  fw-bold">{t('طلب الشراء')}</span>
                    <Select
                      classNamePrefix="react-select"
                      className={`w-100 `}
                      isLoading={loading2}
                      isSearchable={true}
                      isClearable
                      options={PurchaseRequesOptions}
                      value={PurchaseRequesOptions?.find((f) => f.value === PurchaseRequesId)}
                      onChange={(option) => {
                        setPurchaseRequesId(option?.value);
                        setCurrentPage(1);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <button
                    hidden={!PurchaseRequesId}
                    type="submit"
                    className="btn  me-1 btn-theme rounded-2 w-100"
                    onClick={() => {
                      setQuotations(PurchaseQuotationList);
                      setShow2(true);
                    }}>
                    <i className="fas fa-balance-scale me-2"></i>
                    {/* <i className="fas fa-chart-bar me-2"></i>
                    <i className="fas fa-exchange-alt me-2"></i> */}
                    {t('مقارنة عروض الأسعار')}
                  </button>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap text-center">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('الرقم')}</th>
                      <th scope="col">{t('التاريخ')}</th>
                      <th scope="col">{t('المورد')}</th>
                      <th scope="col">{t('الفرع ')}</th>
                      <th scope="col">{t('قيمة')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle"> {item.code} </td>
                        <td className="align-middle">
                          {item.transactionDate.toString()?.toString().split('T')[0]}
                        </td>
                        <td className="align-middle"> {item.peopleName2} </td>
                        <td className="align-middle"> {item.organizationName2} </td>
                        <td className="align-middle"> {item.grossAmount}</td>

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            onClick={() => Navigate(`${item.id}/UpdatePurchaseQuotation`)}>
                            {/* <i className="far fa-lg fa-fw me-2 fa-edit"></i> */}
                            {t('عرض التفاصيل')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal show={show} setShow={setShow} bodyClassName="p-0" size="lg" showHeader={false}>
        <PurchaseRequestList Route="Quotation" />
      </NeatixModal>

      <NeatixModal show={show2} setShow={setShow2} bodyClassName="p-0" size="xl" showHeader={false}>
        <Card>{Quotations && <QuotationComparison Quotations={Quotations} />}</Card>
      </NeatixModal>
    </div>
  );
};

export default Index;

// <div className="ms-auto">
// <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
//   <button
//     type="button"
//     className="btn btn-theme dropdown-toggle"
//     data-bs-toggle="dropdown"
//     aria-expanded="false">
//     {/* <i className="fa bi bi-printer-fill fa-fw ms-3 fs-5"></i> */}
//     {t('عرض سعر جديد')}
//   </button>
//   <ul className="dropdown-menu text-center">
//     <li>
//       <Link className="dropdown-item text-start" to="AddPurchaseQuotation">
//         عرض سعر جديد
//         <i className="fa fa-file-alt fa-fw me-2 text-theme"></i>
//       </Link>
//     </li>
//     <li>
//       <Link className="dropdown-item text-start" to="" onClick={() => setShow(true)}>
//         عرض سعر من طلب شراء
//         <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
//       </Link>
//     </li>
//   </ul>
// </div>
// </div>
