import { useEffect } from 'react';
 
import { useDispatch, useSelector } from 'react-redux';
import { GetDynamicFormAme } from '../../store/Ame/ameRequestSlice';

const useGetFormAme = () => {
  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GetDynamicFormAme());
  }, []);

  const { DynamicFormAme  , loading, error } = useSelector((a) => a.AmeRequest);

  return { DynamicFormAme, loading, error };
};

export default useGetFormAme;
