import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from '../../../../components/card/card';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import useGetPeopleLocationsList from '../../../../hooks/NTX/Peoples/useGetPeopleLocationsList';
import EditPeopleLocations from './UpdatePeopleLocations';
import { PeopleLocationsModels } from '../../../../store/NTX/Peoples/PeoplesModels';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import Add from './Add';
import NtxTable from '../../../../components/DataTable/NtxTable';
import Loading from '../../../../components/Shared/Loader/Loading';

type Props = {
  peopleId: string | undefined;
};

const PeopleLocations: React.FC<Props> = ({ peopleId }) => {
  const { t, i18n } = useTranslation();
  const [detailes, setDetailes] = useState<PeopleLocationsModels>();

  const navigate = useNavigate();

  const { data, loading, error } = useGetPeopleLocationsList({ peopleId: peopleId || '' });

  const [show, setShow] = useState(false);

  const columns = [
    { title: 'الأسم', key: 'name' },
    { title: 'العنوان', key: 'adress' },
    { title: 'يفوتر له / يشحن له', key: 'shipto' },
    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData =
    data?.map((item) => {
      let rowClass;

      return {
        name: i18n.language === 'en' ? item.name : item.name2,
        adress: item.addressLine,
        shipto: (
          <>
            {item.billToSiteFlag && <span className="badge bg-info me-1">للفوترة</span>}
            {item.billToSiteFlag && item.shipToSiteFlag && ' / '}
            {item.shipToSiteFlag && <span className="badge bg-success me-1">للشحن</span>}
          </>
        ),

        action: (
          <button
            type="button"
            className="btn btn-outline-theme me-1"
            onClick={() => {
              setDetailes(item);
              setShow(true);
            }}>
            <i className="far fa-lg fa-fw me-2 fa-eye"></i>

            {t('ViewDetails')}
          </button>
        ),
      };
    }) || [];

  //____________________________
  const location = useLocation();
  const { name } = location?.state || {};
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('العملاء'),
      link: '/SalesSettings/Customers',
    },
    {
      name: t('مواقع العملاء'),
      link: null,
    },
  ];

  return (
    <Fragment>
      <Loading loading={loading} error={error}>
        <>
          <div className="d-flex align-items-center">
            <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('مواقع العميـل')} subtitle={name} />
            <div className="ms-auto">
              <Link
                to=""
                className="btn me-1 btn-theme"
                data-bs-toggle="modal"
                data-bs-target="#modalAdd"
                // onClick={() => setShow(true)}
              >
                <i className="fa fa-plus-circle fa-fw me-1"></i>
                إضافة موقع جديد لـ ( {name} )
              </Link>
              <Link
                to=""
                className="btn me-1 btn-outline-secondary"
                onClick={() => navigate(-1)}
                replace>
                {t('Back')}
              </Link>
            </div>
          </div>

          <Card>
            <CardBody>
              <NtxTable
                data={tableData || []}
                columns={columns || []}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch={true}
                showRecordsSelector
              />
            </CardBody>
          </Card>
        </>
      </Loading>
      <NeatixModal show={show} setShow={setShow} size="xl" bodyClassName="p-0" showHeader={false}>
        <Card>
          <CardBody>{detailes && <EditPeopleLocations data={detailes} />}</CardBody>
        </Card>
      </NeatixModal>

      <ModalComponent title={t('إضافة موقع عميـل')} id="modalAdd">
        {peopleId && <Add peopleId={peopleId} />}
      </ModalComponent>
    </Fragment>
  );
};

export default PeopleLocations;
