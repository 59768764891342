// import AddReport from '../../pages/NTX/Reports/Report/Add/AddReport';
// import Reports from '../../pages/NTX/Reports/Report/Index/Index';
// import PrintingForms from '../../pages/NTX/Reports/PrintingForms/Index/Index';
// import AddPrintingForms from '../../pages/NTX/Reports/PrintingForms/Add/AddPrintingForms';
// import UpdatePrintingForms from '../../pages/NTX/Reports/PrintingForms/Update/UpdatePrintingForms';
// import UpdateReport from '../../pages/NTX/Reports/Report/Update/UpdateReport';
// import AddReportShape from '../../pages/NTX/Reports/ReportChart/Add/AddReportShape';
// import DashboardManagement from '../../pages/NTX/DashboardManagament/index/Index';
// import AddDashboard from '../../pages/NTX/DashboardManagament/AddDashboard/Add';
// import UpdateDashBoard from '../../pages/NTX/DashboardManagament/UpdateDashBoard/Update';
// import Notes from '../../pages/NTX/Reports/PrintingForms/Notes';

// const reportsRoutes = [
//   { path: 'NtxDashboardManagement', element: <DashboardManagement /> },
//   { path: 'NtxDashboardManagement/AddUpdate', element: <AddDashboard /> },
//   { path: 'NtxDashboardManagement/:DashboardId/UpdateDashBoard', element: <UpdateDashBoard /> },
//   {
//     path: 'RPT/Reports/*',
//     children: [
//       { path: '*', element: <Reports /> },
//       { path: 'AddReport', element: <AddReport /> },
//       { path: ':id/UpdateReport', element: <UpdateReport /> },
//       { path: ':id/AddReportShape', element: <AddReportShape /> },
//     ],
//   },

//   {
//     path: 'RPT/PrintingForms/*',
//     children: [
//       { path: '*', element: <PrintingForms /> },
//       { path: 'AddPrintingForms', element: <AddPrintingForms /> },
//       { path: ':id/UpdatePrintingForms', element: <UpdatePrintingForms /> },
//       { path: 'Notes', element: <Notes /> },
//     ],
//   },
// ];

// export default reportsRoutes;


import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';

// Lazy loading components
const DashboardManagement = lazy(() => import('../../pages/NTX/DashboardManagament/index/Index'));
const AddDashboard = lazy(() => import('../../pages/NTX/DashboardManagament/AddDashboard/Add'));
const UpdateDashBoard = lazy(() => import('../../pages/NTX/DashboardManagament/UpdateDashBoard/Update'));
const Reports = lazy(() => import('../../pages/NTX/Reports/Report/Index/Index'));
const AddReport = lazy(() => import('../../pages/NTX/Reports/Report/Add/AddReport'));
const UpdateReport = lazy(() => import('../../pages/NTX/Reports/Report/Update/UpdateReport'));
const AddReportShape = lazy(() => import('../../pages/NTX/Reports/ReportChart/Add/AddReportShape'));
const PrintingForms = lazy(() => import('../../pages/NTX/Reports/PrintingForms/Index/Index'));
const AddPrintingForms = lazy(() => import('../../pages/NTX/Reports/PrintingForms/Add/AddPrintingForms'));
const UpdatePrintingForms = lazy(() => import('../../pages/NTX/Reports/PrintingForms/Update/UpdatePrintingForms'));
const Notes = lazy(() => import('../../pages/NTX/Reports/PrintingForms/Notes'));

const reportsRoutes = [
  {
    path: 'NtxDashboardManagement',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <DashboardManagement />
      </Suspense>
    ),
  },
  {
    path: 'NtxDashboardManagement/AddUpdate',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <AddDashboard />
      </Suspense>
    ),
  },
  {
    path: 'NtxDashboardManagement/:DashboardId/UpdateDashBoard',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <UpdateDashBoard />
      </Suspense>
    ),
  },
  {
    path: 'RPT/Reports/*',
    children: [
      {
        path: '*',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Reports />
          </Suspense>
        ),
      },
      {
        path: 'AddReport',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddReport />
          </Suspense>
        ),
      },
      {
        path: ':id/UpdateReport',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <UpdateReport />
          </Suspense>
        ),
      },
      {
        path: ':id/AddReportShape',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddReportShape />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'RPT/PrintingForms/*',
    children: [
      {
        path: '*',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <PrintingForms />
          </Suspense>
        ),
      },
      {
        path: 'AddPrintingForms',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddPrintingForms />
          </Suspense>
        ),
      },
      {
        path: ':id/UpdatePrintingForms',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <UpdatePrintingForms />
          </Suspense>
        ),
      },
      {
        path: 'Notes',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Notes />
          </Suspense>
        ),
      },
    ],
  },
];

export default reportsRoutes;
