import { Card, CardBody, CardExpandToggler } from '../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BarChart from './BarChart.js';






const EmployeeByOrg = () => {
  const { t } = useTranslation();

  const { Employees } = useSelector((state) => state.Employees);

  function countEmployeesByOrganizationId(employees) {
    const organizationCount = {};

    employees?.forEach((employee) => {
      const orgId = employee.organizatioN_ID;
      const orgName = employee.organizatioN_NAME;
      if (organizationCount[orgId]) {
        organizationCount[orgId].count++;
      } else {
        organizationCount[orgId] = {
          organizatioN_ID: orgId,
          name: orgName,
          count: 1,
        };
      }
    });

    // تحويل النتيجة إلى مصفوفة من الكائنات
    const result = Object.values(organizationCount);

    return result;
  }

  const [data, setData] = useState([]);

  useEffect(() => {
    const generatedData = countEmployeesByOrganizationId(Employees);
    setData(generatedData);
  }, [Employees]);

  console.log(data);

  return (
    <div>
      <Card className="mb-3" style={{ height: '100%' }}>
        <CardBody className="">
          <div className="d-flex fw-bold small mb-3">
            <span className="flex-grow-1">توزيع الموظفين حسب الإدارة</span>
            <CardExpandToggler />
          </div>
          <div className="ratio ratio-21x9 mb-3">
            {data && data?.length > 0 && <BarChart data={data} />}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default EmployeeByOrg;
