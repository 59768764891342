import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
// import Select from 'react-select';

// import CustomAlert from "../../../components/Alert/CustomAlert";
import { Form } from 'react-bootstrap';
// import { Card, CardBody, CardHeader } from "../../../components/card/card";
import Loader from '../../../../components/Shared/Loader/Loader';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import useGetGlJeCategoryList from '../../../../hooks/Finance/useGetGlJeCategoryList';
import useGetGlJeSourceList from '../../../../hooks/Finance/useGetGlJeSourceList';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
import useGetGlDailyRatesList from '../../../../hooks/Finance/DailyRates/useGetGlDailyRatesList';
import useGetGlPeriodsList from '../../../../hooks/Finance/GlPeriods/useGetGlPeriodsList';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import { useNavigate, useParams } from 'react-router-dom';
import { LedgersModels } from '../../../../store/Finance/Ledgers/LedgersModels';
// import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import { LinesTypes, GlJeLinesList } from '../../../../store/Finance/Journals/JournalsModels';
import { UpdatGlJeHeaders } from '../../../../store/Finance/Journals/JournalsSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { AddGlJeHeaderSchema } from '../../../ValidationForm/validationSchema';
import useGetJournalsById from '../../../../hooks/Finance/Journals/useGetJournalsById';
import JournalLines from '../JournalLines/JournalLines';
import { Card, CardBody, CardFooter } from '../../../../components/card/card';
import JournalLines2 from '../JournalLines/JournalLines2';
import useConversionRateByLedgard from '../../../../hooks/Finance/DailyRates/useConversionRateByLedgard';
import useCheckDateOnPeriod from '../../../../hooks/Finance/GlPeriods/useCheckDateOnPeriod';
import useGetCostCenters from '../../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useGetCompanies from '../../../../hooks/Finance/ChartAccountsDetails/useGetCompanies';
import useGetBranches from '../../../../hooks/Finance/ChartAccountsDetails/useGetBranches';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';

const UpdateJournals = () => {
  const navigate = useNavigate();

  const { Id } = useParams();
  const { JournalsById: data, loading } = useGetJournalsById(Id);
  const { i18n, t } = useTranslation();
  const [stateJu, setStateJu] = useState<string>('T');
  const dispatch = useAppDispatch();
  const { Currency } = useLookupCurrency();
  const { GlJeCategoryList } = useGetGlJeCategoryList();
  const { GlJeSourceList } = useGetGlJeSourceList();
  // const { LedgersList } = useGetGlLedgersList();
  const { GlPeriods } = useGetGlPeriodsList();

  const { OptionsCostCenters } = useGetCostCenters();
  const { OptionsCompanies } = useGetCompanies();
  const { OptionsBranches } = useGetBranches();
  const { OptionsAccounts } = useGetAccounts();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      id: data ? data?.id : '',
      glDate: data ? data?.glDate : '',
      ledgerId: data ? data?.ledgerId : '',
      jeCategory: data ? data?.jeCategory : '',
      jeSource: data ? data?.jeSource : '',
      periodId: data ? data?.periodId : '',
      name: data ? data?.name : '',
      status: data ? data?.status : '',
      //تحويلات العملة
      currencyCode: data ? data?.currencyCode : '',
      conversionFlag: data ? data?.conversionFlag : false,
      currencyConversionRate: data ? data?.currencyConversionRate : '',
      conversionDate: data ? data?.conversionDate : '',
      ///
      jeBatchId: data ? data?.jeBatchId : '',
      parentJeHeaderId: data ? data?.parentJeHeaderId : '',
      postedDate: data ? data?.postedDate : '',
      description: data ? data?.description : '', // الوصف - البيان
      accountedDr: data ? data?.accountedDr : '',
      accountedCr: data ? data?.accountedCr : '',

      // JeLinesList: [] as GlJeLinesList[],
      JeLinesList:
        data?.glJeLinesList?.map((item) => ({
          id: item.id,
          jeLineNum: item.jeLineNum,
          enteredDr: item.enteredDr,
          enteredCr: item.enteredCr,
          accountDr: item.accountDr,
          accountCr: item.accountCr,
          description: item.description,
          accountId: item.accountId,
          companyId: item.companyId,
          branchId: item.branchId,
          costCenterId: item.costCenterId,
          //________للعرض وليس للبوست_______
          lineType: item.enteredCr === 0 ? 'debit' : 'credit',
          codeCombination: [
            OptionsCompanies.find((f) => f.value === item.companyId)?.accountNumber,
            OptionsBranches.find((f) => f.value === item.branchId)?.accountNumber,
            OptionsCostCenters.find((f) => f.value === item.costCenterId)?.accountNumber,
            OptionsAccounts.find((f) => f.value === item.accountId)?.accountNumber,
          ]
            .filter(Boolean)
            .join('  -  '),
        })) ?? ([] as GlJeLinesList[]),
    },
    enableReinitialize: true,
    // validationSchema: AddGlJeHeaderSchema(t),
    onSubmit: (values) => {
      if (!isDateValid) {
        SwalAlert({ text: 'التاريـخ ليس ضمن الفترة المحاسبية' });
        formik.setSubmitting(false);
        return;
      }

      if (stateJu === 'U' && Number(totalEnteredDr) !== Number(totalEnteredCr)) {
        SwalAlert({ text: 'يجب ان يتساوى الدائن والمديـن' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        UpdatGlJeHeaders({
          id: values.id,
          glDate: values.glDate,
          ledgerId: values.ledgerId,
          jeCategory: values.jeCategory,
          jeSource: values.jeSource,
          periodId: values.periodId,
          name: values.name,
          status: stateJu,
          currencyCode: values.currencyCode,
          conversionFlag: values.conversionFlag,
          currencyConversionRate: 1,
          conversionDate: values.conversionDate,
          ///
          jeBatchId: values.jeBatchId,
          parentJeHeaderId: values.parentJeHeaderId,
          // postedDate: values.postedDate,
          postedDate: new Date().toISOString().split('T')[0],
          description: values.description,

          accountedDr: Number(totalAccountedDr),
          accountedCr: Number(totalAccountedCr),
          enteredDr: Number(totalEnteredDr),
          enteredCr: Number(totalEnteredCr),

          glJeLinesList: values.JeLinesList?.map((item) => ({
            id: item.id,
            jeLineNum: Number(item.jeLineNum),
            enteredDr: Number(item.enteredDr) || 0,
            enteredCr: Number(item.enteredCr) || 0,
            accountDr: Number(item.accountDr) || 0,
            accountCr: Number(item.accountCr) || 0,
            description: item.description,
            accountId: item.accountId,
            companyId: item.companyId,
            branchId: item.branchId,
            costCenterId: item.costCenterId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add', msg: 'تم حفظ القيد بنجاح' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;

  // Calculate the total debit and credit amounts
  const totalEnteredDr = formik.values.JeLinesList.reduce(
    (total, line) => total + line.enteredDr,
    0
  );
  const totalEnteredCr = formik.values.JeLinesList.reduce(
    (total, line) => total + line.enteredCr,
    0
  );
  const totalAccountedDr = formik.values.JeLinesList.reduce(
    (total, line) => total + line.accountDr,
    0
  );
  const totalAccountedCr = formik.values.JeLinesList.reduce(
    (total, line) => total + line.accountCr,
    0
  );

  //#endregion

  const conversionRate = useConversionRateByLedgard({
    LedgarId: values.ledgerId || '',
    currencyCode: values.currencyCode,
    date: values.glDate,
  });

  const isDateValid = useCheckDateOnPeriod(values.glDate, values.periodId);

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'القيود المالية',
      link: '/Finance/Journals',
    },
    {
      name: 'تعديل قيـد',
      link: null,
    },
  ];
  //#endregion

  return (
    <div>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        // PageName="Journals"
        // subtitle={ledgar?.name2}
      />

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('AccountingPeriod')}</label>
                  <Form.Select
                    className="form-select "
                    name="periodId"
                    onChange={formikhandleChange}
                    value={values.periodId || ''}
                    isInvalid={!!(touched.periodId && errors.periodId)}
                    isValid={formik.touched.periodId && !formik.errors.periodId}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {GlPeriods &&
                      GlPeriods?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.periodId}</Form.Control.Feedback>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Date')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="glDate"
                    onChange={formikhandleChange}
                    value={values.glDate || ''}
                    isInvalid={!!(touched.glDate && errors.glDate)}
                    isValid={formik.touched.glDate && !formik.errors.glDate}
                  />
                  <Form.Control.Feedback type="invalid">{errors.glDate}</Form.Control.Feedback>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Source')}</label>
                  <Form.Select
                    className="form-select"
                    name="jeSource"
                    onChange={formikhandleChange}
                    value={values.jeSource || ''}
                    isInvalid={!!(touched.jeSource && errors.jeSource)}
                    isValid={formik.touched.jeSource && !formik.errors.jeSource}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {GlJeSourceList &&
                      GlJeSourceList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.jeSource}</Form.Control.Feedback>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Category')}</label>
                  <Form.Select
                    className="form-select "
                    name="jeCategory"
                    onChange={formikhandleChange}
                    value={values.jeCategory || ''}
                    isInvalid={!!(touched.jeCategory && errors.jeCategory)}
                    isValid={formik.touched.jeCategory && !formik.errors.jeCategory}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {GlJeCategoryList &&
                      GlJeCategoryList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.jeCategory}</Form.Control.Feedback>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('Currency')} </label>
                  <Form.Select
                    disabled
                    className="form-select"
                    name="currencyCode"
                    onChange={formikhandleChange}
                    value={values.currencyCode || ''}
                    isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    isValid={formik.touched.currencyCode && !formik.errors.currencyCode}>
                    {Currency &&
                      Currency?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.currencyCode}>
                          {item.description}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>

              <div className="col-lg-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('ExchangeRate')}</label>
                  <Form.Control
                    disabled
                    className="form-control text-center bg-inverse bg-opacity-15"
                    type="text"
                    autoComplete="off"
                    name="currencyConversionRate"
                    value={values.currencyConversionRate}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Note')}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    isValid={touched.description && !errors.description}
                  />
                  <div></div>
                </div>
              </div>
              {/* <div className="col-lg-2 ">
                <div className="form-group mb-3">
                  <label className="form-label">{t("conversionDate")}</label>
                  <Form.Control
                    disabled
                    className="form-control text-center bg-inverse bg-opacity-15"
                    type="date"
                    name="conversionDate"
                    value={formik.values.conversionDate || ""}
                  />
                </div>
              </div> */}
            </div>
          </Form>

          <CardFooter className=" p-1 mt-3">
            {/* <JournalLines
              formik={formik}
              currencyConversionRate={currencyConversionRate}
              isUpdate={true}
            /> */}

            <JournalLines2 formik={formik} />
          </CardFooter>
        </CardBody>
      </Card>

      <div className="text-center mt-4">
        <button
          disabled={formik.isSubmitting}
          type="button"
          className="btn btn-lg me-1 btn-warning mb-1"
          onClick={() => {
            setStateJu('T');
            formik.submitForm();
          }}>
          <i className="fa fa-save fa-fw me-1"></i>
          {t('TemporarySave')}
        </button>
        <button
          disabled={formik.isSubmitting}
          type="button"
          className="btn btn-lg me-1 btn-theme mb-1"
          onClick={() => {
            setStateJu('U');
            formik.submitForm();
          }}>
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fa fa-save fa-fw me-1"></i>
          )}

          {t('Save')}
        </button>
        <button
          type="button"
          className="btn btn-lg me-1 btn-default mb-1 rounded-2"
          onClick={() => navigate('/Finance/Journals', { replace: true })}>
          <i className="fa fa-times fa-fw me-1"></i>

          {t('Cancel')}
        </button>
      </div>
    </div>
  );
};

export default UpdateJournals;
