import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { WorkShop, WorkShopById } from "./workshopModels";
import { ntxAPI } from '../../../API/axiosNeatex';
import { Peoples } from './PeoplesModels';

export const GetNtxPeoplesList = createAsyncThunk(
  'Peoples/GetNtxPeoplesList',
  async (LookupType: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const endpoint = `Peoples/GetNtxPeoplesList${LookupType ? `?LookupType=${LookupType}` : ''}`;

      const { data } = await ntxAPI.get(endpoint);
      // const {data} = await ntxAPI.get('Peoples/GetNtxPeoplesList');
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetNtxPeoplesByID = createAsyncThunk(
  'Peoples/GetNtxPeoplesByID',
  async (id: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.get(`Peoples/GetNtxPeoplesById?Id=${id}`);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const AddNtxPeoples = createAsyncThunk(
  'Peoples/AddNtxPeoples',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Peoples/AddNtxPeoples', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatNtxPeoples = createAsyncThunk(
  'Peoples/UpdatNtxPeoples',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Peoples/UpdatNtxPeoples', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCustomersList = createAsyncThunk(
  'Peoples/getCustomersList',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const endpoint = `Peoples/GetNtxPeoplesList?LookupType=CUSTOMERS`;

      const { data } = await ntxAPI.get(endpoint);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSuppliersList = createAsyncThunk(
  'Peoples/getSuppliersList',
  async ( _, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const endpoint = `Peoples/GetNtxPeoplesList?LookupType=SUPPLIERS`;

      const { data } = await ntxAPI.get(endpoint);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  PeoplesList: Peoples[];
  CustomersList: Peoples[];
  SuppliersList: Peoples[];
  PeoplesById?: Peoples;
  loading: boolean;
  error: any | null;
} = {
  PeoplesList: [],
  CustomersList: [],
  SuppliersList: [],
  loading: false,
  error: null,
};

const peoplesSlice = createSlice({
  name: 'Peoples',
  initialState,
  reducers: {
    cleanPeoplesById: (state) => {
      state.PeoplesById = undefined; // or set it to null based on your preference
      // state.loading = false;
      // state.error = null;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(AddNtxPeoples.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddNtxPeoples.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        // state.PeoplesList.push(action.payload.data);
      })
      .addCase(AddNtxPeoples.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetNtxPeoplesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetNtxPeoplesList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.PeoplesList = action.payload;
      })
      .addCase(GetNtxPeoplesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetNtxPeoplesByID.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetNtxPeoplesByID.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.PeoplesById = action.payload;
      })
      .addCase(GetNtxPeoplesByID.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getCustomersList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomersList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.CustomersList = action.payload;
      })
      .addCase(getCustomersList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(getSuppliersList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSuppliersList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.SuppliersList = action.payload;
      })
      .addCase(getSuppliersList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanPeoplesById } = peoplesSlice.actions;
export default peoplesSlice.reducer;
