import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetApInvoicesList from '../../../../hooks/Finance/ApInvoices/useGetApInvoicesList';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import PurchaseRequestList from '../../../PUR/Transactions/PurchaseRequest/PurchaseRequestList';
import DataTableComponent from '../../../../components/DataTable/DataTableComponent';

const Index = () => {
  const [showPurchaseRequest, setShowPurchaseRequest] = useState(false);

  const { t, i18n } = useTranslation();

  const { ApInvoicesList: data, loading, error } = useGetApInvoicesList();

  const columns = [
    { title: t('InvoiceNumber'), key: 'invoiceNum' },
    { title: t('Supplier'), key: 'peopleName' },
    { title: t('InvoiceDate'), key: 'invoiceDate' },
    { title: t('InvoiceType'), key: 'invoiceType' },
    { title: t('Branch'), key: 'organizationName' },
    { title: t('Amount'), key: 'invoiceAmount' },
    { title: t('PostingStatus'), key: 'postedFlag', className: 'text-center' }, // إضافة حالة الترحيل

    { title: '', key: 'action', className: 'text-end' },
  ];
 
  const tableData = data.map((item) => {
    let rowClass;

    // if (item.invoiceAmount > 1000) {
    //   rowClass = 'table-success';
    // } else if (item.invoiceAmount < 500) {
    //   rowClass = 'table-warning';
    // }
    // تحديد الشارة بناءً على قيمة postedFlag
    const postedFlagBadge = item.postedFlag ? (
      <span className="badge bg-success">
        <i className="fas fa-check me-1"></i> {/* أيقونة للترحيل */}
        {t('Posted')}
      </span>
    ) : (
      <span className="badge bg-danger">
        <i className="fas fa-times me-1"></i> {/* أيقونة لعدم الترحيل */}
        {t('NotPosted')}
      </span>
    );
    return {
      invoiceNum: item.invoiceNum,
      peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      invoiceDate: item.invoiceDate,
      invoiceType: item.invoiceType,
      organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
      invoiceAmount: item.invoiceAmount,
      postedFlag: postedFlagBadge, // استخدام الشارة هنا
      action: (
        <Link to={`${item.id}/UpdateApInvoices`} className="btn btn-outline-theme mx-1">
          <i className="fas fa-info-circle me-2"></i>
          {t('Details')}
        </Link>
      ),
      rowClass, // استخدم rowClass هنا
    };
  });

  const options = {
    paging: true,
    pageLength: 10,
    searching: true,
    ordering: true,
    lengthChange: true, //
    // info: false, //
    columnDefs: [{ orderable: false, targets: 7 }],
    order: [[2, 'desc']],
    responsive: true,
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: 'PaymentInvoices',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="PaymentInvoices" />

        <div className="ms-auto">
          <Link to="AddApInvoices" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('NewInvoice')}
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <DataTableComponent
              data={tableData}
              columns={columns}
              options={options}
              theadClass=""
              tableClass="table table-hover text-nowrap"
            />
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showPurchaseRequest}
        setShow={setShowPurchaseRequest}
        bodyClassName="p-0"
        size="lg"
        showHeader={false}>
        <PurchaseRequestList Route="ApInvoice" />
      </NeatixModal>
    </div>
  );
};

export default Index;
