import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganizations } from '../../store/HR/Organizations/organizationSlice';
import { useTranslation } from 'react-i18next';

const useGetOrganizations = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { Organizations, loading, error } = useSelector((state) => state.Organizations);

  useEffect(() => {
    if (Organizations?.length === 0) {
      dispatch(getOrganizations());
    }
  }, [dispatch]);

  const OrganizationOptions =
    Organizations &&
    Organizations?.map((item) => ({
      value: item.id,
      label: i18n.language === 'en' ? item.name : item.name2,
      organizationInformation: item?.organizationInformation,
    }));

  const OrganizationInventory = Organizations && Organizations?.filter((f) => f.type === '130'); // Inventory

  const OrganizationBranch = Organizations && Organizations?.filter((f) => f.type === '150'); // Branch

  const OrganizationShop = Organizations && Organizations?.filter((f) => f.type === '80'); // Shob

  const InventoryOptions =
    Organizations &&
    OrganizationInventory?.map((item) => ({
      value: item.id,
      label: i18n.language === 'en' ? item.name : item.name2,
    }));

  const BranchsOptions =
    Organizations &&
    OrganizationBranch?.map((item) => ({
      value: item.id,
      label: i18n.language === 'en' ? item.name : item.name2,
    }));

  const ShopsOptions =
    Organizations &&
    OrganizationShop?.map((item) => ({
      value: item.id,
      label: i18n.language === 'en' ? item.name : item.name2,
    }));

  return {
    OrganizationInventory,
    OrganizationBranch,
    Organizations,
    OrganizationOptions,
    InventoryOptions,
    BranchsOptions,
    ShopsOptions,
    loading,
    error,
  };
};

export default useGetOrganizations;
