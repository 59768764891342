import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  GetPriceListItemsByPeopleIdAndInventoryId,
  // PriceListItemsCleanUp,
} from '../../../store/INV/PriceList/PriceListSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  customerId: string;
  inventoryId: string;
}

const useGetPriceListItemsByPeopleIdAndInventoryId = ({ customerId, inventoryId }: Props) => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (customerId && inventoryId) {
      dispatch(GetPriceListItemsByPeopleIdAndInventoryId({ customerId, inventoryId }));
    }
    // return () => {
    //   dispatch(PriceListItemsCleanUp());
    // };
  }, [customerId, dispatch, inventoryId]);
  const {
    PriceListItemsByCustomerAndInventory: ListItems,
    loading,
    error,
  } = useAppSelector((state) => state.PriceList);


  // الأصناف حسب العميل والمخزن
  const ItemsOptions = ListItems?.map((item) => ({
    value: item.itemId,
    label: i18n.language === 'en' ? item.name : item.name2,
    //________________________________________________________________
    itemId: item.itemId,
    itemName: `${i18n.language === 'en' ? item.name : item.name2}`,
    unitOfMeasureName: `${
      i18n.language === 'en' ? item.unitOfMeasureName : item.unitOfMeasureName2
    }`,
    unitPrice: item.unitPrice,
    QtyAvailable: item.qty,
    isPrimaryUOM: item.isPrimaryUOM,
    enabledFlag: item.enabledFlag,
    categoryId: item.categoryId,
    barcode: item.unitOfMeasure,
    unitOfMeasure: item.unitOfMeasure,
  }));

  return { ListItems, ItemsOptions, loading, error };
};

export default useGetPriceListItemsByPeopleIdAndInventoryId;
