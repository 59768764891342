import { memo } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import useGetItemsList from '../../../hooks/INV/MasterItems/useGetItemsList';
import { FormikProps } from 'formik';

interface Props {
  formik: FormikProps<any>;
}

const DiscountVouchersItemsList = ({ formik }: Props) => {
  const { t, i18n } = useTranslation();
  const { ItemsOptions, loading } = useGetItemsList();

  const handleChange = (selectedOptions: any) => {
    const selectedItems = selectedOptions
      ? selectedOptions?.map((option: any) => ({ itemId: option.value }))
      : [];

    formik.setFieldValue('discountVouchersItemsList', selectedItems);
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('حدد الأصناف')}</Form.Label>
        <Select
          isMulti
          classNamePrefix="react-select"
          // className="bg-inverse bg-opacity-10"
          className={`${
            formik.values.AllItemsAllCategories !== 'allItems' &&
            'bg-inverse bg-opacity-10'
          } `}
          isLoading={loading}
          isDisabled={formik.values.AllItemsAllCategories !== 'allItems'}
          isSearchable={true}
          isClearable
          options={ItemsOptions}
          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
          onChange={handleChange}
          value={formik.values.discountVouchersItemsList?.map((item: any) =>
            ItemsOptions?.find((option) => option.value === item.itemId)
          )}
        />
      </Form.Group>
    </>
  );
};

export default memo(DiscountVouchersItemsList);
