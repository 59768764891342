import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

interface PopoverCustomProps {
  childern: React.ReactNode; // محتوى العنصر الذي سيقوم بتفعيل الـ Popover
  Header?: string; // عنوان الـ Popover (اختياري)
  Body: React.ReactNode; // محتوى الـ Popover
  placement?: 'top' | 'right' | 'bottom' | 'left'; // تحديد موضع الـ Popover (افتراضيًا أعلى)
}

const PopoverCustom: React.FC<PopoverCustomProps> = ({
  childern: triggerContent,
  Header: popoverTitle,
  Body: popoverContent,
  placement = 'top',
}) => {
  return (
    <OverlayTrigger
      // flip={false} 
      container={document.body} // استخدام الـ body كحاوية للتراكب
      // offset={[0, 10]} // تعديل الموضع قليلاً
       // container={document.body}
      // rootClose={false}
      // offset={[0, 10]}
      // show={true}
      popperConfig={{
        modifiers : [
          {
            name:'preventOverflow',
            options :{
              boundary: 'viewport', // يمنع تجاوز حدود الشاشة

            }
          }
        ]
      }}
      placement={placement}
 
       overlay={
        <Popover className="text-center border border-theme shadow m-0">
          {popoverTitle && (
            <Popover.Header className="text-center fw-bold small bg-theme bg-opacity-25 m-0">
              <h6 className="m-0">{popoverTitle}</h6>
            </Popover.Header>
          )}
          <Popover.Body className="m-0">{popoverContent}</Popover.Body>
        </Popover>
      }
      trigger={['hover', 'focus', 'click']}
      
      >
      <span>{triggerContent}</span>
    </OverlayTrigger>
  );
};

export default PopoverCustom;
