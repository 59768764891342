import React, { useState } from 'react';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetPaymentTermsList';
import { PaymentTermsModels } from '../../../../store/Finance/PaymentTerms/PaymentTermsModels';
import AddPaymentTerms from './AddPaymentTerms';
import UpdatePaymentTerms from './UpdatePaymentTerms';
import NtxTable from '../../../../components/DataTable/NtxTable';

const Index = () => {
  const [Show, setShow] = useState(false);

  const { t, i18n } = useTranslation();
  const [dataById, setDataById] = useState<PaymentTermsModels>(); // for sent as props to Update component PeriodsModels

  const { PaymentTermsList, loading, error } = useGetPaymentTermsList();

  const columns = [
    { title: t('Name'), key: 'name' },
    { title: t('Period'), key: 'Period', className: 'text-theme fs-5 fw-bold' },

    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData =
    PaymentTermsList?.map((item) => {
      let rowClass;

      return {
        name: i18n.language === 'en' ? item.name : item.name2,
        Period: item.period,
        action: (
          <button
            type="button"
            className="btn btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalUpdate"
            onClick={() => setDataById(item)}>
            <i className="far fa-lg fa-fw fa-edit me-1"></i>
            {t('Button.Edit')}
          </button>
        ),
      };
    }) || [];

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'PaymentTerm',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('PaymentTerm')} />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAdd"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('AddPaymentTerm')}
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-1">
              <NtxTable
                data={tableData || []}
                columns={columns || []}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch={true}
                showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title={t('AddPaymentTerm')} id="modalAdd">
        {Show && <AddPaymentTerms />}
      </MedModalComponent>

      <MedModalComponent title="Edit" id="modalUpdate">
        {dataById && <UpdatePaymentTerms DataById={dataById} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
