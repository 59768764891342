import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { AssetsModels } from './assetsModels';
import { APIResponse } from '../../../Shared/shared';

export const addAssets = createAsyncThunk(
  'Assets/addAssets',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('FA/AddAssets', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateAssets = createAsyncThunk(
  'Assets/updateAssets',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('FA/UpdatAssets', item);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAssetsList = createAsyncThunk(
  'Assets/getAssetsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<AssetsModels[]>>('FA/GetAssetsList');
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAssetsById = createAsyncThunk(
  'Assets/GetAssetsById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<AssetsModels>>(`FA/GetAssetsById?Id=${id}`);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getAssetsByModelId = createAsyncThunk(
  'Assets/getAssetsByModelId',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<AssetsModels>>(`FA/GetAssetsByModelId?ModelId=${id}`);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  AssetsList: AssetsModels[];
  AssetsById: AssetsModels;
  AssetsByModelId: AssetsModels;
  loading: boolean;
  error: any | null;
} = {
  AssetsList: [],
  AssetsById: {} as AssetsModels,
  AssetsByModelId: {} as AssetsModels,
  loading: false,
  error: null,
};

const AssetsSlice = createSlice({
  name: 'Assets',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
 

      .addCase(getAssetsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAssetsList.fulfilled, (state, action: PayloadAction<AssetsModels[]>) => {
        state.loading = false;
        state.AssetsList = action.payload;
      })
      .addCase(getAssetsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
 

      .addCase(getAssetsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAssetsById.fulfilled, (state, action: PayloadAction<AssetsModels>) => {
        state.loading = false;
        state.AssetsById = action.payload;
      })
      .addCase(getAssetsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
 

      .addCase(getAssetsByModelId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAssetsByModelId.fulfilled, (state, action: PayloadAction<AssetsModels>) => {
        state.loading = false;
        state.AssetsByModelId = action.payload;
      })
      .addCase(getAssetsByModelId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default AssetsSlice.reducer;
