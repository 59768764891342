import { useFormik } from 'formik';
// import React, { useContext, useEffect } from "react";
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import {
  addInventoryCountEntries,
  getInventoryCountById,
} from '../../../../store/INV/InventoryCount/inventoryCountSlice';
import { useEffect, useState } from 'react';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import { getPersonId } from '../../../../store/Login/loginSlice';
import useGetInventoryCountById from '../../../../hooks/INV/InventoryCount/useGetInventoryCountById';
import CountLine from './CountLine';

const AddEntries = () => {
  const { invCountId } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { dataEmployees, loading } = useGetPersons();
  const personId = useAppSelector(getPersonId);

  const { CountItemsOptions, data } = useGetInventoryCountById({ invCountId: invCountId });

  //#region Formik
  const formik = useFormik({
    initialValues: {
      invCountId: invCountId,
      personId: personId,
      entryDate: '',
      inventoryCountEntriesLineList: [],
      // inventoryCountEntriesLineList: data?.inventoryCountItemsList?.map((m) => ({
      //   id: null,
      //   invCountItemsId: '',
      //   countQuantity: 0,
      //   unitOfMeasure: '',
      //   description: '',
      // })),
    },
    enableReinitialize: true,
    // validationSchema: AddGlJeHeaderSchema(t),
    onSubmit: (values) => {
      dispatch(
        addInventoryCountEntries({
          invCountId: values.invCountId,
          personId: values.personId,
          entryDate: values.entryDate,
          inventoryCountEntriesLineList: values.inventoryCountEntriesLineList.map((m: any) => ({
            invCountItemsId: m.invCountItemsId,
            countQuantity: m.countQuantity,
            unitOfMeasure: m.unitOfMeasure,
            description: m.itemName,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            // formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const { values, handleChange, errors, touched } = formik;
  //#endregion

  console.log(values);

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('personId - store keeper')}</label>
              <Select
                // isDisabled
                classNamePrefix="react-select"
                isLoading={loading}
                isSearchable={true}
                isClearable
                name="personId"
                options={dataEmployees}
                value={dataEmployees?.find((f: any) => f.value === values.personId)}
                onChange={(option: any) => {
                  formik.setFieldValue('personId', option === null ? null : option.value);
                }}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('تاريخ الإدخال')}</label>
              <Form.Control
                className="form-control  text-center"
                type="date"
                name="entryDate"
                onChange={handleChange}
                value={values.entryDate || ''}
                // isInvalid={!!(touched.entryDate && errors.entryDate)}
              />
              <div className="text-danger small">
                {formik.touched.entryDate && formik.errors.entryDate}
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0 p-0">
          <div className="bg-inverse bg-opacity-5 p-2 position-relative">
            <CountLine formik={formik} />
          </div>
        </div>

        <div className="text-center mt-2  ">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn btn-lg me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className="btn btn-lg  me-1 btn-default mb-1"
            onClick={() => Navigate(-1)}>
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddEntries;
