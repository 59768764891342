import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const ntxMenu = [
  {
    System: 'NTX',
    isActive: false,
    menu: [
      { is_header: true, title: 'GeneralSystem', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home', // أيقونة الصفحة الرئيسية
        title: 'HomePage',
        isActive: true,
      },
      { is_header: true, title: 'القوائم والنماذج', isActive: true },

      {
        path: 'Forms/ExtraInformationForm',
        icon: 'bi bi-file-earmark-text', // أيقونة معلومات إضافية
        title: 'ExtraInfoForm',
        isActive: HasPermission('NtxViewExtraInformation'),
      },

      {
        path: 'Forms/ExtraRecordsForm',
        icon: 'bi bi-file-earmark-post', // أيقونة سجلات إضافية
        title: 'ExtraRecordForm',
        isActive: HasPermission('NtxViewExtraInformationRecord'),
      },

      {
        path: 'NTX/ListSystem',
        icon: 'bi bi-layers', // أيقونة قوائم النظام
        title: 'SystemLists',
        isActive: HasPermission('NtxViewSystemlistD'),
      },

      // تصنيف إدارة المستخدمين
      { is_header: true, title: 'إدارة المستخدمين', isActive: true },
      {
        path: 'Security/Users',
        icon: 'bi bi-person', // أيقونة إدارة المستخدمين
        title: 'users',
        isActive: HasPermission('NtxViewuserslist'),
      },

      {
        path: 'Security/AuthRules',
        icon: 'bi bi-shield-lock', // أيقونة قواعد الأذونات
        title: 'RulesPermissions',
        isActive: HasPermission('NtxViewlistpermissionsRule'),
      },

      // تصنيف قوائم النظام العامة
      { is_header: true, title: 'قوائم النظام العامة', isActive: true },
      {
        path: 'NTX/Lookup',
        icon: 'bi bi-search', // أيقونة قوائم النظام العامة
        title: 'GeneralSystemLists',
        isActive: HasPermission('NtxViewgeneralsystemList'),
      },

      // { is_header: true, title: 'GeneralSystem', isActive: true },
      // {
      //   path: 'dashboard',
      //   icon: 'fas fa-home',
      //   title: 'HomePage',
      // },

      // {
      //   path: '/Forms',
      //   icon: 'bi bi-code-square',
      //   title: 'SystemListsAndForms',
      //   children: [
      //     {
      //       path: 'Forms/ExtraInformationForm',
      //       title: 'ExtraInfoForm',
      //       isActive: HasPermission('NtxViewExtraInformation'),
      //     },

      //     {
      //       path: 'Forms/ExtraRecordsForm',
      //       title: 'ExtraRecordForm',
      //       isActive: HasPermission('NtxViewExtraInformationRecord'),
      //     },

      //     {
      //       path: 'NTX/ListSystem',
      //       title: 'SystemLists',
      //       isActive: HasPermission('NtxViewSystemlistD'),
      //     },
      //   ],
      // },

      // {
      //   path: '/Security',
      //   icon: 'bi bi-person-fill-lock',
      //   title: 'UserManagement',
      //   children: [
      //     {
      //       path: 'Security/Users',
      //       title: 'users',
      //       isActive: HasPermission('NtxViewuserslist'),
      //       // isActive: true,
      //     },
      //     {
      //       path: 'Security/AuthRules',
      //       title: 'RulesPermissions',
      //       isActive: HasPermission('NtxViewlistpermissionsRule'),
      //     },
      //   ],
      // },

      // {
      //   path: 'NTX/Lookup',
      //   icon: 'bi bi-list-task',
      //   title: 'GeneralSystemLists',
      //   // isActive: true,
      //   isActive: HasPermission('NtxViewgeneralsystemList'),
      // },
    ],
  },
];

export default ntxMenu;

// {
//     path: '/NTX',
//     icon: 'bi bi-code-square',
//     title: 'الاعدادات العامة',
//     children: [
//       {
//         path: 'NTX/ExtraInformationForm',
//         title: 'البنوك',
//         isActive: false,
//       },

//       {
//         path: 'NTX/ExtraRecordsForm',
//         title: 'العملات',
//         isActive: false,
//       },
//     ],
//   },
