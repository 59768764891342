import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
// import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getNoneInventoryList } from '../../../../store/purchases/PurchaseBill/purchaseBillSlice';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import Receive from './Receive';

const Index = () => {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState('');
  const [item, setItem] = useState<any>();

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  // const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNoneInventoryList());
  }, [dispatch]);

  const { NoneInventoryList, loading, error } = useAppSelector((a) => a.PurchaseBill);

  const filteredSearch = NoneInventoryList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => {
      if (!date) return true; // إذا كانت قيمة التاريخ فارغة، اجلب جميع النتائج بدون تصفية
      const itemDate = new Date(f.transactionDate).toDateString(); // تحويل التاريخ إلى كائن Date واستخراج اليوم
      const selectedDate = new Date(date).toDateString(); // تحويل التاريخ المحدد إلى كائن Date واستخراج اليوم
      return itemDate === selectedDate; // مقارنة اليوم فقط (بدون الوقت)
    })
    ?.sort((a, b) => b?.no - a?.no);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const titleModal = () => {
    return (
      <div className="">
        {t('إستلام اللطلبات الغير مخزنية')}
        {'  '}
        <span className="text-theme">{item?.code}</span>
      </div>
    );
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'إستلام الطلبات الغير مخزنية',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="إستلام الطلبات الغير مخزنية" />
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="p-2">
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap m-0">
                    <span className="input-group-text fw-bold">
                      {t('Search')}
                      {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                    </span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control ps-35px m-0"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">
                      {t('Date')}
                      {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                    </span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <input
                            type="date"
                            className="form-control "
                            value={date}
                            onChange={(e) => {
                              setDate(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap text-center">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('رقم الطلب ')}</th>
                      <th scope="col">{t('تاريخ الطلب ')}</th>
                      <th scope="col">{t('الفرع')}</th>
                      <th scope="col">{t('المخزن')}</th>
                      {/* <th scope="col">{t('قيمة الطلب')}</th> */}
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle "> {item.code} </td>
                        <td className="align-middle">
                          {item.transactionDate.toString()?.split('T')[0]}
                        </td>
                        <td className="align-middle"> {item.organizationName2} </td>
                        <td className="align-middle">
                          {' '}
                          {i18n.language === 'en' ? item.inventoryName : item.inventoryName2}{' '}
                        </td>
                        {/* <td className="align-middle"> {item.grossAmount}</td> */}

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            onClick={() => {
                              setItem(item);
                              setShow(true);
                            }}>
                            <i className="far fa-lg fa-fw me-2 fa-eye"></i> {t('عرض التفاصيل')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal show={show} setShow={setShow} bodyClassName="p-3" size="xl" title={titleModal()}>
        {/* <Card> */}
        <CardBody>{item && <Receive id={item.id} />}</CardBody>
        {/* </Card> */}
      </NeatixModal>
    </div>
  );
};

export default Index;
