import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import {
  MyNotificationMessageListModal,
  NotificationsModels,
  NotificationListModal,
} from './notificationModels';

export const AddNtxNotification = createAsyncThunk(
  'Notifications/AddNotification',
  async (item: NotificationsModels, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Notification/AddNotification', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const UpdateNtxNotification = createAsyncThunk(
  'Notifications/UpdateNtxNotification',
  async (item: NotificationsModels, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Notification/UpdatNotification', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetNotificationList = createAsyncThunk(
  'Notifications/GetNotificationList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Notification/GetNotificationList');
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetMyNotificationMessageList = createAsyncThunk(
  'Notifications/GetMyNotificationMessageList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Notification/GetMyNotificationMessageList');
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const ChangeNotificationActive = createAsyncThunk(
  'Notifications/ChangeNotificationActive',
  async (notificationId: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Notification/ChangeNotificationActive', notificationId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetNtxNotificationById = createAsyncThunk(
  'Notifications/GetNtxNotificationById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Notification/GetNtxNotificationById?Id=${id}`);
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const ChangeIsRead = createAsyncThunk(
  'Notifications/ChangeIsRead',
  async (notificationMessageIds: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ntxAPI.post(
        'Notification/ChangeNotificationMessageIsRead',
        notificationMessageIds
      );
      if (data.succeeded) {
        dispatch(GetMyNotificationMessageList())
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  NotificationsList: NotificationListModal[];
  NotificationsById: NotificationsModels;
  MyNotificationMessageList: MyNotificationMessageListModal[];
  loading: boolean;
  changeActiveloading: boolean;
  error: any | null;
} = {
  NotificationsList: [],
  NotificationsById: {} as NotificationsModels,
  MyNotificationMessageList: [],
  loading: false,
  changeActiveloading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddNtxNotification.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(AddNtxNotification.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        // state.NotificationsList.push(action.payload);
      })
      .addCase(AddNtxNotification.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetNotificationList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetNotificationList.fulfilled,
        (state, action: PayloadAction<NotificationListModal[]>) => {
          state.loading = false;
          state.NotificationsList = action.payload;
        }
      )
      .addCase(GetNotificationList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetMyNotificationMessageList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetMyNotificationMessageList.fulfilled,
        (state, action: PayloadAction<MyNotificationMessageListModal[]>) => {
          state.loading = false;
          state.MyNotificationMessageList = action.payload;
        }
      )
      .addCase(GetMyNotificationMessageList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(ChangeNotificationActive.pending, (state, action) => {
        state.changeActiveloading = true;
      })
      .addCase(ChangeNotificationActive.fulfilled, (state, action: PayloadAction<any>) => {
        state.changeActiveloading = false;
      })
      .addCase(ChangeNotificationActive.rejected, (state, action: PayloadAction<any>) => {
        state.changeActiveloading = false;
        state.error = action.payload;
      })

      .addCase(GetNtxNotificationById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetNtxNotificationById.fulfilled,
        (state, action: PayloadAction<NotificationsModels>) => {
          state.loading = false;
          state.NotificationsById = action.payload;
        }
      )
      .addCase(GetNtxNotificationById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default notificationSlice.reducer;
