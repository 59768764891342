import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import './Printpdf.css'



const Printpdf = ({Data}) => {


  const { t, i18n } = useTranslation();

    useEffect(() => {
      const handlePrint = () => {
        let printContents = document.getElementById('printId').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
       document.body.innerHTML = originalContents; 


      };
  
      const printButton = document.getElementById('printButton');
      printButton.addEventListener('click', handlePrint);
  
      return () => {
        printButton.removeEventListener('click', handlePrint);
      };
    }, []);
    //_____________________________

    var Earning = Data?.filter(f=>f.isEarning === true)
    var Deduction = Data?.filter(f=>f.isEarning === false)


      const sumEarning = Earning?.reduce((sum, item) => {
        return sum + item.resultValue;
      }, 0);
      const sumDeduction = Deduction?.reduce((sum, item) => {
        return sum + item.resultValue;
      }, 0);

      //___________________________
      var personName = localStorage.personName;
      var personName2 = localStorage.personName2;
      
  return (
    <div>
     
    
    {/* <button id="printButton" className="ReportPP-print scrollButton" >Print</button> */}


    <button id="printButton" className="print-button"><span className="print-icon"></span></button>

    <div >

          <page id='printId' size1="A4" >
     
          <main className="ReportP" >
  <div className="ReportP-wrapper ReportP-header">
    <img
      src="https://gist.githubusercontent.com/mondenoir/bab1c3ffbc9e6a6939d47d702be9a63f/raw/91e20eed0549f41528689256f2364e34edaceec3/logo.svg"
      alt="logo"
      className="ReportP-logo"
    />
    <div className="ReportP-company">
      <h2 className="ReportP-company-name">حلول تخطيط الموارد نيتكس</h2>
      <p className="ReportP-company-address">
        <span>المملكة العربية السعودية </span>
        <span>الرياض</span>
        <span>helpdesk@aljown.com</span>
      </p>
    </div>
  </div>
            <div
              className="ReportP-row"
              style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
            >
              <div className="ReportP-col-2 "> </div>
              <div
                className="ReportP-col-8 title-p"
                style={{ backgroundColor: " rgba(0,0,0,0.2" }}
              >
              {i18n.language === "ar"
              ? personName2
              : personName}
              </div>
              <div className="ReportP-col-2 "></div>
            </div>


  <div className='ReportP-row'>
    <div className='ReportP-col-6'>
    <table className="ReportP-table" >
    <thead>
    <tr>
        <th colSpan="2">الإستحقاقات</th>
        
      </tr>
      
    </thead>
    <tbody>
    {Earning && Earning?.map((item) => (
                <tr key={item.id}>
                  <td >
                    { i18n.language === 'ar'? item.elementTypeName2 : item.elementTypeName }
                  </td>
                  <td >
                    { item.resultValue }
                  </td>
                </tr>
              ))}

      <tr>
        <td>-</td>
        <td className="ReportP-total">{sumEarning}</td>
      </tr>
    </tbody>
  </table>

    </div>
    <div className='ReportP-col-6'>
    <table className="ReportP-table" >
    <thead>
    <tr>
        <th colSpan="2">الإستقطاعات</th>
        
      </tr>
      
    </thead>
    <tbody>
    {Deduction && Deduction?.map((item) => (
                <tr key={item.id}>
                  <td >
                    { i18n.language === 'ar'? item.elementTypeName2 : item.elementTypeName }
                  </td>
                  <td >
                    { item.resultValue }
                  </td>
                </tr>
              ))}

      <tr>
        <td>-</td>
        <td className="ReportP-total">{sumDeduction}</td>
      </tr>
    </tbody>
  </table>

    </div>
  </div>
  <div className='ReportP-row' style={{border:"0.5px solid rgba(0,0,0,0.25)"}}>
    <div className='ReportP-col-4 title-p'>صافي الراتب</div>
    <div className='ReportP-col-4 title-p' style={{backgroundColor:" rgba(0,0,0,0.2"}}>{sumEarning - sumDeduction}</div>
    <div className='ReportP-col-4 title-p'>Net Salary</div>
 </div>
</main>



    </page>
    </div>

  </div>
  )
}

export default Printpdf