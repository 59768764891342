import React, { useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../../components/card/card';
import { useTranslation } from 'react-i18next';

import Loading from '../../../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../../../components/Modal/NeatixModal';
import AddCreateAccountApPayments from './AddCreateAccountApPayments';
import useGetPaymentsList from '../../../../../hooks/Finance/ApPayments/useGetPaymentsList';
import NtxTable from '../../../../../components/DataTable/NtxTable';

const ListApPayments = () => {
  const [showModal, setShowModal] = useState(false);

  const { t, i18n } = useTranslation();

  const { ApPaymentsList: data, loading, error } = useGetPaymentsList();

  const [selectedApInvoices, setSelectedApInvoices] = useState<string[] | any>([]);

  const handleCheckboxChange = (row: any) => {
    setSelectedApInvoices((prevSelectedRows: any) => {
      const isSelected = prevSelectedRows?.some((item: any) => item.id === row.id);

      if (isSelected) {
        return prevSelectedRows?.filter((item: any) => item.id !== row.id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const columns = [
    { title: '', key: 'checkbox', sortable: false },
    { title: t('VoucherNumber'), key: 'code' },
    { title: t('Date'), key: 'paymentDate' },
    { title: t('VoucherType'), key: 'paymentTypeName' },
    { title: t('Amount'), key: 'amount', className: 'text-theme' },
    { title: t('Supplier'), key: 'peopleName' },
    { title: t('Branch'), key: 'organizationName' },
    {
      title: t('PostingStatus'),
      key: 'postedFlagDisplay',
      className: 'text-center',
      sortable: false,
    }, // إضافة حالة الترحيل
  ];

  const tableData = data
    ?.filter((f) => !f.postedFlag)
    ?.map((item) => {
      let rowClass;

      const postedFlagBadge = item.postedFlag ? (
        <span className="badge bg-success">
          <i className="fas fa-check me-1"></i> {/* أيقونة للترحيل */}
          {'Posted'}
        </span>
      ) : (
        <span className="badge bg-danger">
          <i className="fas fa-times me-1"></i> {/* أيقونة لعدم الترحيل */}
          {'NotPosted'}
        </span>
      );
      return {
        checkbox: (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input  "
              id={`checkbox-${item.id}`}
              checked={selectedApInvoices?.some((row: any) => row.id === item.id)}
              onChange={() => handleCheckboxChange(item)}
            />
          </div>
        ),
        code: item.code,
        paymentDate: item.paymentDate,
        paymentTypeName: i18n.language === 'en' ? item.paymentTypeName : item.paymentTypeName2,
        amount: item.amount,
        peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
        organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
        postedFlag: item.postedFlag,
        postedFlagDisplay: postedFlagBadge,

        rowClass, // استخدم rowClass هنا
      };
    });

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    // {
    //   name: 'إدارة المدفوعات',
    //   link: null,
    // },
    {
      name: 'PostPaymentVouchers',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="PostPaymentVouchers" />
        <div className="ms-auto">
          <button
            hidden={selectedApInvoices?.length === 0}
            type="button"
            className="btn me-1 btn-theme"
            onClick={() => {
              if (selectedApInvoices?.length > 0) {
                setShowModal(true);
              }
            }}>
            <i className="fa fa-share-square fa-fw me-1"></i>
            {t('PostSelectedVouchers')}
          </button>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <NtxTable
                data={tableData}
                columns={columns}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch
                showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        bodyClassName="p-0"
        size="xl"
        showHeader={false}>
        <Card>
          <CardBody>
            {selectedApInvoices?.length > 0 && (
              <AddCreateAccountApPayments selectedApPayments={selectedApInvoices} />
            )}
          </CardBody>
        </Card>
      </NeatixModal>
    </div>
  );
};

export default ListApPayments;
