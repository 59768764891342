import * as Yup from 'yup';

const createImgValidation = () => {
  return Yup.mixed()
    .notRequired()
    .test(
      'fileSize',
      'File size must be less than 1 MB',
      (value) => !value || value.size <= 1000000
    )
    .test(
      'fileType',
      'Only PNG and JPEG images are allowed',
      (value) => !value || ['image/png', 'image/jpeg'].includes(value.type)
    );
};

export const formSchema = (t) => {
  return Yup.object().shape({
    NAME2: Yup.string()
      .trim()
      .required(() => t('Required')),
    NAME: Yup.string()
      .trim()
      .required(() => t('Required')),
    NATIONAL_IDENTIFIER: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required'))
      .max(9999999999, 'must be no more than 10 digits'),
    START_DATE: Yup.date().required(() => t('Required')),
    DATE_OF_BIRTH: Yup.date().required(() => t('Required')),
    // MARITAL_STATUS: Yup.string()
    //   .notOneOf(["إختر", "Choose"], "Required")
    //   .required(() => t("Required")),
    NATIONALITY: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    TITLE: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    // COUNTRY_OF_BIRTH: Yup.string()
    //   // .notOneOf(["إختر", "Choose"], "Required")
    //   .required(() => t("Required")),
    TOWN_OF_BIRTH: Yup.string().required('Required'),
    GENDER: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    Religion: Yup.string().required(() => t('Required')),
  });
};

export const EditPersonSchema = (t) => {
  return Yup.object().shape({
    NAME2: Yup.string()
      .trim()
      .required(() => t('Required')),
    NAME: Yup.string()
      .trim()
      .required(() => t('Required')),
    NATIONAL_IDENTIFIER: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required'))
      .max(9999999999, 'must be no more than 10 digits'),
    START_DATE: Yup.date().required(() => t('Required')),
    DATE_OF_BIRTH: Yup.date().required(() => t('Required')),
    // MARITAL_STATUS: Yup.string()
    //   .notOneOf(["إختر", "Choose"], "Required")
    //   .required(() => t("Required")),
    NATIONALITY: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    TITLE: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    // COUNTRY_OF_BIRTH: Yup.string()
    //   // .notOneOf(["إختر", "Choose"], "Required")
    //   .required(() => t("Required")),
    // TOWN_OF_BIRTH: Yup.string().required("Required"),
    GENDER: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    Religion: Yup.string().required(() => t('Required')),
  });
};

export const AssignmentFormSchema = (t) => {
  return Yup.object().shape({
    gradeId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required('Required'),

    positionId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required('Required'),

    jobId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required('Required'),

    assignmentStatusTypeId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required('Required'),

    payrollId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required('Required'),

    locationId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required('Required'),

    supervisorId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required('Required'),

    organizationId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required('Required'),

    personGroupId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required('Required'),

    startDate: Yup.date()
      .required(() => t('Required'))
      .typeError(() => t('Invalid date format')),

    // endDate: Yup.date()
    // .required(() => t("Required"))
    // .typeError(() => t("Invalid date format"))
    // // .min(Yup.ref('dateStart'), () => t("يجب ان يكون أكبر ....")),
  });
};

export const formJob = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      // .min(5, "الاسم صغير جدا | the name is too short")
      // .max(250, "الاسم كبير جدا | the name is too long")
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      // .min(5, "الاسم صغير جدا | the name is too long")
      // .max(50, "الاسم كبير جدا | the name is too long")
      .required(() => t('Required')),
    comments: Yup.string().trim(),
    // .min(2, "الاسم صغير جدا | the name is too long")
    // .max(50, "الاسم كبير جدا | the name is too long")
    // .required(() => t("Required")),
  });
};

export const formGpoupEmp = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      // .min(5, "الاسم صغير جدا | the name is too short")
      // .max(250, "الاسم كبير جدا | the name is too long")
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      // .min(5, "الاسم صغير جدا | the name is too long")
      // .max(50, "الاسم كبير جدا | the name is too long")
      .required(() => t('Required')),

    businessGroupId: Yup.string().notOneOf(['إختر', 'Choose'], 'Required').required(),

    comments: Yup.string().trim(),
    // .min(2, "الاسم صغير جدا | the name is too long")
    // .max(50, "الاسم كبير جدا | the name is too long")
    // .required(() => t("Required")),
  });
};

export const formOrganization = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    locationId: Yup.string().required(() => t('Required')),
    type: Yup.string().required(() => t('Required')),
    dateFrom: Yup.date()
      .required(() => t('Required'))
      .typeError(() => t('Invalid Date')),
  });
};

export const formLocation = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .min(5, 'الاسم صغير جدا | the name is too short')
      .max(250, 'الاسم كبير جدا | the name is too long')
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .min(5, 'الاسم صغير جدا | the name is too long')
      .max(50, 'الاسم كبير جدا | the name is too long')
      .required(() => t('Required')),

    // businessGroupId: Yup.string().required(() => t("Required")),

    // shipToLocationId: Yup.string().required(() => t("Required")),

    country: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const formPosition = (t) => {
  return Yup.object().shape({
    jobId: Yup.string()
      .required(() => t('Required'))
      .notOneOf(['إختر', 'Choose'], 'Required'),
    organizationId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    locationId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const FormPayrollElement = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    currencyCode: Yup.string().required(() => t('Required')),

    classificationId: Yup.string().required(() => t('Required')),

    uom: Yup.string().required(() => t('Required')),
  });
};

export const FormAddPayrollSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    // businessGroupId: Yup.string()
    // .notOneOf(["إختر", "Choose"], "Required")
    // .required(() => t("Required")),

    paymentMethodId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    periodType: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    startDate: Yup.date().required(() => t('Required')),

    endDate: Yup.date().required(() => t('Required')),
    cutOffDate: Yup.string().required(() => t('Required')),

    numberOfYears: Yup.number().required(() => t('Required')),

    accountId: Yup.string().required(() => t('Required')),
    ledgerId: Yup.string().required(() => t('Required')),
  });
};

export const DynamicFormSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    //  urlStaticForm: Yup.string()
    //     .required(() => t("Required")),

    id: Yup.string().when('isStatic', {
      is: true,
      then: () => Yup.string().required(() => t('Required')),
    }),
  });
};

export const DynamicFormRecordSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    // tableId: Yup.string().required(() => t("Required")),
    icon: Yup.string().required(() => t('Required')),

    tableId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    moduleId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};
export const DynamicFormExtraInfoSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    tableId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    moduleId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const DynamicFormAmeSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    moduleId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    IdStaticForm: Yup.string().when('isStatic', {
      is: true,
      then: () => Yup.string().required(() => t('Required')),
    }),
  });
};
export const AmeConditionSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string().required(() => t('Required')),
    name: Yup.string().required(() => t('Required')),
    Condition: Yup.string().required(() => t('Required')),
    // dynamicFormColumnID: Yup.string().required(() => 'مطلوب'),
    dynamicFormColumnID: Yup.string().when('conditionType', {
      is: (val) => val === 'STATIC',
      then: () => Yup.string().notRequired(),
      // otherwise: Yup.string().nullable(),
    }),

    multiSelect: Yup.string().when(['formatType', 'conditionType'], {
      is: (formatType, conditionType) =>
        formatType === 'T' || formatType === 'D' || conditionType === 'STATIC',
      then: () => Yup.string().required(() => t('Required')),
    }),

    includeLowerLimit: Yup.string().when(['validationType', 'conditionType'], {
      is: (val, val2) =>
        (val === 'TIME' || val === 'DATE' || val === 'NUMBER') && val2 !== 'STATIC',
      then: () => Yup.string().required(() => t('Required')),
    }),

    param1: Yup.string().when('includeLowerLimit', {
      is: (val) => val,
      then: () => Yup.string().required(() => t('Required')),
      otherwise: () => Yup.string().notRequired(),
    }),
  });
};

export const AddUpdateDashboardSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    moduleId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const EditDynamicFormSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      // .min(5, "الاسم صغير جدا | the name is too short")
      // .max(250, "الاسم كبير جدا | the name is too long")
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const AddAmeDelegateSchema = (t) => {
  return Yup.object().shape({
    selectedPersonId: Yup.string()
      .trim()
      .required(() => t('Required')),
    startDate: Yup.string()
      .trim()
      .required(() => t('Required')),
    endDate: Yup.string()
      .trim()
      .required(() => t('Required')),
    reason: Yup.string()
      .trim()
      .required(() => t('Required')),

    // creatorPersonId: '',
  });
};

export const AddFlexValueSetSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    // tableName: Yup.string()
    // .notOneOf(["إختر", "Choose"], "Required")
    // .required(() => t("Required")),
  });
};

export const AddFormColumnSchema = (t) => {
  return Yup.object().shape({
    lable: Yup.string()
      .trim()
      .required(() => t('Required')),
    lable2: Yup.string()
      .trim()
      .required(() => t('Required')),
    sequenceNum: Yup.number().required(() => t('Required')),
    flexValueSetId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const AddFlexValueDynamicSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    tableId: Yup.string().when('radioValue', {
      is: 'T',
      then: () => Yup.string().required(() => t('Required')),
    }),

    lookupType: Yup.string().when('radioValue', {
      is: 'L',
      then: () => Yup.string().required(() => t('Required')),
    }),

    lookupCode: Yup.string().when('tableId', {
      is: (value) =>
        ['045a58ed-782f-4b4f-a33e-4ffcee9e80f3', '6121dd5c-aa4c-4832-a561-3fdfd0e15ba4'].includes(
          value
        ),
      then: () => Yup.string().required(() => t('Required')),
    }),
  });
};

export const formAddUserSchema = (t) => {
  return Yup.object().shape({
    usersName: Yup.string()
      .trim()
      .required(() => t('Required')),

    // emailAddress: Yup.string().trim().email(() => t("ايمبل غير صالح")),

    referenceId: Yup.string().required(() => t('Required')),

    // usersMobile: Yup.string().matches(
    //   /^([+][0-9]{1,3}[-\s]?|[0][-\s]?|[0-9]{2,4}[-\s]?)[0-9]{7,14}$/,
    //   () => t("رقم جوال غير صالح")
    // ),
    userTypeId: Yup.string()
      .required(() => t('Required'))
      .notOneOf(['إختر', 'Choose'], 'Required'),

    // startDate: Yup.string().required(() => t("Required")),
    // endDate: Yup.string().required(() => t("Required")),

    startDate: Yup.date()
      .required(() => t('Required'))
      .typeError(() => t('Invalid date format')),

    // endDate: Yup.date()
    //   .required(() => t("Required"))
    //   .typeError(() => t("Invalid date format"))
    //   .min(Yup.ref("startDate"), () => t("يجب ان يكون اكبر من تاريخ البدء")),

    // startDate: Yup.date()
    // .required(() => t("Required"))
    // .typeError(() => t("Invalid date format")),

    // endDate: Yup.date()
    // .required(() => t("Required"))
    // .typeError(() => t("Invalid date format"))
    // .min(Yup.ref('startDate'), () => t("يجب ان يكون أكبر ....")),
  });
};

export const formAuthRulesSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    startDate: Yup.string().required(() => t('Required')),

    moduleId: Yup.string()
      .required(() => t('Required'))
      .notOneOf(['إختر', 'Choose'], 'Required'),
  });
};

export const AddPayProposalSchema = (t) => {
  return Yup.object().shape({
    proposedSalaryN: Yup.number()
      .typeError('Please enter a valid number')
      .required(() => t('Required')),

    proposalReason: Yup.string().required(() => t('Required')),

    changeDate: Yup.string().required(() => t('Required')),
  });
};

export const AddPayrollElementEntrySchema = (t) => {
  return Yup.object().shape({
    elementLinkId: Yup.string().required(() => t('Required')),

    // elementTypeId: Yup.string()
    // .required(() => t("Required"))
    // .notOneOf(["إختر", "Choose"], "Required"),

    screenEntryValue: Yup.string().required(() => t('Required')),

    startDate: Yup.date()
      .required(() => t('Required'))
      .typeError(() => t('Invalid date format')),

    endDate: Yup.date().when('isRecurring', {
      is: 'false',
      then: () =>
        Yup.date()
          .required(() => t('Required'))
          .typeError(() => t('Invalid date format'))
          .min(Yup.ref('startDate'), () => t('يجب ان يكون أكبر ....')),
    }),
  });
};

export const formAbsenseSchema = (t) => {
  return Yup.object().shape({
    // authorisingPersonId: Yup.string().required(() => t('Required')),

    // authorisingPersonId: Yup.string().when('isAme', {
    //   is: false,
    //   then:
    //     Yup.string()
    //       .notOneOf(['إختر', 'Choose'], 'Required')
    //       .required(() => t('Required')),
    // }),

    replacementPersonId: Yup.string().required(() => t('Required')),

    // absenceAttendanceTypeId: Yup.string().required("نوع الغياب مطلوب"), // Example validation rule for GUID select field

    absenceAttendanceTypeId: Yup.string().required(() => t('Required')),

    dateStart: Yup.date()
      .required(() => t('Required'))
      .typeError(() => t('Invalid date format')),

    dateEnd: Yup.date()
      .required(() => t('Required'))
      .typeError(() => t('Invalid date format'))
      .min(Yup.ref('dateStart'), () => t('يجب ان يكون أكبر ....')),
  });
};

export const EndOfServiceFormSchema = (t) => {
  return Yup.object().shape({
    personId: Yup.string().required(() => t('Required')),
    dateStart: Yup.string().required(() => t('Required')),
    acceptedTerminationDate: Yup.string().required(() => t('Required')),
    actualTerminationDate: Yup.string().required(() => t('Required')),
    finalProcessDate: Yup.string().required(() => t('Required')),
    leavingReason: Yup.string().required(() => t('Required')),
    notifiedTerminationDate: Yup.string().required(() => t('Required')),

    // periodsOfServiceTypeId: Yup.string().required(() => t('Required')),
    periodsOfServiceTypeId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    // dateStart: Yup.date()
    //   .required(() => t('Required'))
    //   .typeError(() => t('Invalid date format')),

    // dateEnd: Yup.date()
    //   .required(() => t('Required'))
    //   .typeError(() => t('Invalid date format'))
    //   .min(Yup.ref('dateStart'), () => t('يجب ان يكون أكبر ....')),
  });
};

export const formRaqeebSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    count_Fingerprint: Yup.string().required(() => t('Required')),

    allowIn: Yup.string().required(() => t('Required')),

    allowOut: Yup.string().required(() => t('Required')),

    startDate: Yup.date()
      .required(() => t('Required'))
      .typeError(() => t('Invalid date format')),

    endDate: Yup.date()
      .required(() => t('Required'))
      .typeError(() => t('Invalid date format'))
      .min(Yup.ref('startDate'), () => t('يجب ان يكون أكبر ....')),
  });
};
export const AddFlexValueSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    parentId: Yup.string().required(() => t('Required')),

    // .notOneOf(["إختر", "Choose"], "Required"),
  });
};

export const AddRealEstateSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    code: Yup.string(),

    annual_rent: Yup.number()
      .typeError('Capacity must be a valid number')
      .required(() => t('Required')),

    watermeter: Yup.string().required(() => t('Required')),

    gascounter: Yup.string().required(() => t('Required')),

    electricitymeter: Yup.string().required(() => t('Required')),

    attitudesmeter: Yup.string().required(() => t('Required')),

    number_meter: Yup.number()
      .typeError('Capacity must be a valid number')
      .required(() => t('Required')),

    capacity: Yup.number()
      .typeError('Capacity must be a valid number')
      .required(() => t('Required')),

    realEstateTypeId: Yup.string().required(() => t('Required')),

    parent_id: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    organizationId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const AddLookupSchema = (t) => {
  return Yup.object().shape({
    meaning2: Yup.string()
      .trim()
      .required(() => t('Required')),
    meaning: Yup.string()
      .trim()
      .required(() => t('Required')),
    lookupCode: Yup.string()
      .trim()
      .required(() => t('Required')),
    lookupType: Yup.string().required(() => t('Required')),
    description: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const AddContractSchema = (t) => {
  return Yup.object().shape({
    organization_id: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    contract_status: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    code: Yup.string().required(() => t('Required')),

    contrcat_date: Yup.date().required(() => t('Required')),

    purpose: Yup.string().required(() => t('Required')),

    annual_rent: Yup.number().required(() => t('Required')),

    amount_insurance: Yup.number().required(() => t('Required')),
    contract_period: Yup.string().required(() => t('Required')),
    period_from_date: Yup.date().required(() => t('Required')),
    period_to_date: Yup.date().required(() => t('Required')),

    payment_type: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    amount_services: Yup.number().required(() => t('Required')),
    fees_office: Yup.number().required(() => t('Required')),

    represented: Yup.string().required(() => t('Required')),
    installment_count: Yup.number().required(() => t('Required')),
  });
};

export const AddEquipmentsModelFLeetSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const AddWorkShopFLeetSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const EquipmentsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    chassisNo: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    code: Yup.string()
      .trim()
      .required(() => t('Required')),
    // barcode: Yup.string().required(() => t("Required")),
    serviceDate: Yup.string().required(() => t('Required')),
    dateOfExpiry: Yup.string().required(() => t('Required')),
    // lastmaintenanceDate: Yup.string().required(() => t("Required")),

    plateNo: Yup.string()
      .trim()
      .required(() => t('Required')),
    serialNo: Yup.string()
      .trim()
      .required(() => t('Required')),
    odometer: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required'))
      .max(9999999999, 'must be no more than 10 digits'),
    purchasePrice: Yup.number()
      .typeError('must be a number')
      // .required(() => t("Required"))
      .max(9999999999, 'must be no more than 10 digits'),

    fleetModelId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    organizationId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    engineStatus: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const EquipmentsAssignmentSchema = (t) => {
  return Yup.object().shape({
    deliveryDateFrom: Yup.string().required(() => t('Required')),
    // deliveryDateTo: Yup.string().required(() => t("Required")),

    peopleId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const EquipmentRoutesSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    startTime: Yup.string().required(() => t('Required')),
    endTime: Yup.string().required(() => t('Required')),
  });
};

export const EquipmentsOdometerSchema = (t) => {
  return Yup.object().shape({
    odometer: Yup.string()
      .trim()
      .required(() => t('Required')),
    readDate: Yup.string()
      .trim()
      .required(() => t('Required')),

    peopleId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};
export const NtxPeopleSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    vatCode: Yup.number()
      .typeError(t('VAT code must be a number'))
      .required(() => t('Required'))
      .test(
        'len',
        t('VAT code must be exactly 15 digits'),
        (value) => value && String(value).length === 15
      ),

    companyRegistrationNumber: Yup.number()
      .typeError(t('company Registration Number  must be a number'))
      .required(() => t('Required'))
      .test(
        'len',
        t('company RegistrationNumber must be exactly 10 digits'),
        (value) => value && String(value).length === 10
      ),
  });
};

export const EquipmentsWorkRequestSchema = (t) => {
  return Yup.object().shape({
    requestCode: Yup.string()
      .trim()
      .required(() => t('Required')),
    requestDate: Yup.string().required(() => t('Required')),
    requestTitle: Yup.string()
      .trim()
      .required(() => t('Required')),
    personId: Yup.string().required(() => t('Required')),
    // source: Yup.string().required(() => t("Required")),

    phone: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),

    priority: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    requestType: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    organizationId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    fleetEquipmentsId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const EquipmentsModelSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    barcode: Yup.string()
      .trim()
      .required(() => t('Required')),

    parentId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    manufYear: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    catagory: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    manufCountry: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    fuelType: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    motionVector: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    horsepowerTax: Yup.number().typeError('must be a number'),
    horsepower: Yup.number().typeError('must be a number'),
    doorsNo: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),
    seatsNo: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),

    Img: createImgValidation(),
  });
};

export const EquipmentsWorkOrderSchema = (t) => {
  return Yup.object().shape({
    orderCode: Yup.string()
      .trim()
      .required(() => t('Required')),
    startCompletionSugg: Yup.string().required(() => t('Required')),
    endCompletionSugg: Yup.string().required(() => t('Required')),

    totalItems: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),
    totalAsset: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),
    otherAmounts: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),

    fleetEquipmentsId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    workshopId: Yup.string().when('isInternal', {
      is: '1',
      then: () =>
        Yup.string()
          .notOneOf(['إختر', 'Choose'], 'Required')
          .required(() => t('Required')),
    }),

    supplierId: Yup.string().when('isInternal', {
      is: '0',
      then: () =>
        Yup.string()
          .notOneOf(['إختر', 'Choose'], 'Required')
          .required(() => t('Required')),
    }),
  });
};

export const UpdateWorkOrderSchema = (t) => {
  return Yup.object().shape({
    dateCompletion: Yup.string().required(() => t('Required')),
    startDateExecution: Yup.string().required(() => t('Required')),
    noteCompletion: Yup.string().required(() => t('Required')),
    endDateExecution: Yup.string().required(() => t('Required')),

    totalBudgetActual: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),
    otherAmountsActual: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),
    totalAssetActual: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),
    totalItemsActual: Yup.number()
      .typeError('must be a number')
      .required(() => t('Required')),
  });
};

export const InstallmentPaymentSchema = (t) => {
  return Yup.object().shape({
    paymentDate: Yup.string().required(() => t('Required')),

    PaymentMethodId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    bankReference: Yup.string().when('PaymentMethodId', {
      is: (val) => val !== '05fe55ee-ade9-4b4b-85f9-c1dbacf40a6b',
      then: () => Yup.string().required(() => t('Required')),
    }),

    // currencyId: Yup.string()
    // .notOneOf(["إختر", "Choose"], "Required")
    // .required(() => t("Required")),

    bankId: Yup.string().when('PaymentMethodId', {
      is: (val) => val !== '05fe55ee-ade9-4b4b-85f9-c1dbacf40a6b',
      then: () =>
        Yup.string()
          .notOneOf(['إختر', 'Choose'], 'Required')
          .required(() => t('Required')),
    }),
  });
};

export const AddFleetElementSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    description: Yup.string().required(() => t('Required')),
    description2: Yup.string().required(() => t('Required')),
    sort: Yup.string().required(() => t('Required')),

    catagory: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    priority: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const CategoriesINVSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    icon: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
    categoryId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};
export const EditCategoriesINVSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    icon: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const AddFleetInspectionSchema = (t) => {
  return Yup.object().shape({
    scanningDate: Yup.string().required(() => t('Required')),
    // description: Yup.string().required(() => t("Required")),

    driverId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    details: Yup.array().of(
      Yup.object({
        value: Yup.boolean().required(() => t('Required')),
      })
    ),
  });
};

export const SubInventorySchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t('Required')),
    name2: Yup.string().required(() => t('Required')),
    code: Yup.string().required(() => t('Required')),

    // parentId: '',
    // description: '',
  });
};

//#region  Finance Form Schema

export const AddGlJeHeaderSchema = (t) => {
  return Yup.object().shape({
    glDate: Yup.string().required(() => t('Required')),
    // jeBatchId: Yup.string().required(() => t("Required")),
    // parentJeHeaderId: Yup.string().required(() => t("Required")),

    // currencyConversionRate: Yup.string().trim().required(() => t("Required")),
    description: Yup.string()
      .trim()
      .required(() => t('Required')),

    jeCategory: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    jeSource: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    periodId: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),

    currencyCode: Yup.string()
      .notOneOf(['إختر', 'Choose'], 'Required')
      .required(() => t('Required')),
  });
};

export const AddApExpenseCategorySchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    jeCategory: Yup.string()
      .trim()
      .required(() => t('Required')),
    jeSource: Yup.string()
      .trim()
      .required(() => t('Required')),
    expenseAccountId: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};
export const AddApExpenseCategoryItemsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const AddBankAccountsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    iban: Yup.string()
      .trim()
      .required(() => t('Required')),

    bankAccount: Yup.string()
      .trim()
      .required(() => t('Required')),
    accountId: Yup.string()
      .trim()
      .required(() => t('Required')),
    ntxBankId: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};
export const AddCashBoxesSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    accountId: Yup.string()
      .trim()
      .required(() => t('Required')),
    ntxBankId: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const AddApPaymentsSchema = (t) => {
  return Yup.object().shape({
    paymentDate: Yup.date()
      .required(() => t('Required'))
      .nullable(),
    apBankAccountId: Yup.string()
      .trim()
      .required(() => t('Required')),
    paymentMethodId: Yup.string()
      .trim()
      .required(() => t('Required')),
    // amount: Yup.number()
    //   .required(() => t('Required'))
    //   .positive('يجب أن يكون المبلغ أكبر من الصفر')
    //   .min(1, 'يجب أن يكون المبلغ على الأقل 1'),
    currencyCode: Yup.string()
      .trim()
      .required(() => t('Required')),
    currencyConversionRate: Yup.number()
      .required(() => t('Required'))
      .positive('يجب أن يكون معدل التحويل أكبر من الصفر'),
    peopleId: Yup.string()
      .trim()
      .required(() => t('Required')),
    organizationId: Yup.string()
      .trim()
      .required(() => t('Required')),
    // accountId: Yup.string()
    //   .trim()
    //   .required(() => t('Required')),
    // costCenterId: Yup.string()
    //   .trim()
    //   .required(() => t('Required')),
    paymentType: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const AddPaymentSchedulesSchema = (t) => {
  return Yup.object().shape({
    paymentDate: Yup.date()
      .required(() => t('Required'))
      .nullable(),
    apBankAccountId: Yup.string()
      .trim()
      .required(() => t('Required')),
    paymentMethodId: Yup.string()
      .trim()
      .required(() => t('Required')),

    amount: Yup.number()
      .required(() => t('Required'))
      .positive('يجب أن يكون المبلغ أكبر من الصفر')
      .min(1, 'يجب أن يكون المبلغ على الأقل 1'),

    currencyCode: Yup.string()
      .trim()
      .required(() => t('Required')),
    currencyConversionRate: Yup.number()
      .required(() => t('Required'))
      .positive('يجب أن يكون معدل التحويل أكبر من الصفر'),

    paymentType: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const AddApInvoiceSchema = (t) => {
  return Yup.object().shape({
    invoiceNum: Yup.string()
      .trim()
      .required(() => t('Required')),
    peopleId: Yup.string()
      .trim()
      .required(() => t('Required')),
    organizationId: Yup.string().required(() => t('Required')),
    invoiceType: Yup.string().required(() => t('Required')),
    // referenceType: Yup.string().required(() => t('Required')),
    // referenceId: Yup.string().required(() => t('Required')),
    invoiceDate: Yup.string().required(() => t('Required')),
    paymentTermsId: Yup.string().required(() => t('Required')),
    paymentMethodId: Yup.string().required(() => t('Required')),
    currencyCode: Yup.string().required(() => t('Required')),
    currencyConversionRate: Yup.string().required(() => t('Required')),

    apInvoiceLinesList: Yup.array().of(
      Yup.object().shape({
        invItemId: Yup.string().when('lineType', {
          is: 'Item_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),
        uom: Yup.string().when('lineType', {
          is: 'Item_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),
        qty: Yup.string().when('lineType', {
          is: 'Item_Inv',
          then: () =>
            Yup.number()
              .min(1)
              .required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        description: Yup.string().when('lineType', {
          is: (value) => value !== 'Item_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        lineType: Yup.string().required(() => t('Required')),

        total: Yup.number()
          .positive(() => t('The value must be positive')) // للتأكد من أن القيمة موجبة
          .min(1, () => t('Quantity must be at least 1')) // للتأكد من أن القيمة على الأقل 1
          .required(() => t('Required')), // لجعل الحقل مطلوبًا        // : Yup.number().min(1, 'Quantity must be at least 1').required(),

        accountId: Yup.string().required(() => t('Required')),
        costCenterId: Yup.string().required(() => t('Required')),

        // discountRate: line.discountRate,
        // discountAmount: line.discountAmount,
        // vatRate: line.vatRate,
        // total: line.total,
        // vatAmount: line.vatAmount,
        // accountId: null,
        // costCenterId: null,
        // currencyCode: data.currencyCode ?? '',
        // currencyConversionRate: data.currencyConversionRate ?? '',
        // purHeaderId: data.id ?? '',
        // purLineId: line.id,
        // codeCombination: '',
      })
    ),

    apPaymentSchedulesList: Yup.array().of(
      Yup.object().shape({
        dueDate: Yup.string().required(() => t('Required')),

        amount: Yup.number()
          .positive(() => t('The value must be positive')) // للتأكد من أن القيمة موجبة
          .min(1, () => t('value must be at least 1')) // للتأكد من أن القيمة على الأقل 1
          .required(() => t('Required')), // لجعل الحقل مطلوبًا        // : Yup.number().min(1, 'Quantity must be at least 1').required(),
      })
    ),
  });
};

export const CreateAccountSchema = (t) => {
  return Yup.object().shape({
    glDate: Yup.string()
      .trim()
      .required(() => t('Required')),

    periodId: Yup.string()
      .trim()
      .required(() => t('Required')),

    ledgerId: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const AddApExpanseSchema = (t) => {
  return Yup.object().shape({
    expenseCategoryId: Yup.string()
      .trim()
      .required(() => t('Required')),
    organizationId: Yup.string()
      .trim()
      .required(() => t('Required')),
    peopleId: Yup.string()
      .trim()
      .required(() => t('Required')),
    personId: Yup.string()
      .trim()
      .required(() => t('Required')),
    expenseDate: Yup.date()
      .required(() => t('Required'))
      .nullable(),
    // description: Yup.string().required(t('حقل الوصف مطلوب')),
    totalAmount: Yup.number()
      .required(() => t('Required'))
      .positive(t('يجب أن يكون المبلغ أكبر من 0')),
    totalVat: Yup.number()
      .required(() => t('Required'))
      .min(0, t('يجب أن تكون الضريبة أكبر من أو تساوي 0')),
    currencyCode: Yup.string().required(() => t('Required')),
    currencyConversionRate: Yup.number()
      .required(() => t('Required'))
      .positive(t('يجب أن يكون معدل التحويل أكبر من 0')),
    apExpenseLinesList: Yup.array()
      .of(
        Yup.object().shape({
          expenseCategoryItemId: Yup.string().trim().required(t('حقل البند مطلوب')),
          description: Yup.string().trim().required(t('حقل الوصف مطلوب')),
          amount: Yup.number()
            .required(t('حقل المبلغ مطلوب'))
            .positive(t('يجب أن يكون المبلغ أكبر من 0')),
          vat: Yup.number()
            .required(t('حقل الضريبة مطلوب'))
            .min(0, t('يجب أن تكون الضريبة أكبر من أو تساوي 0')),
        })
      )
      .min(1, t('يجب إضافة سطر واحد على الأقل')),
  });
};

export const FiscalYearSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),

    startDate: Yup.string()
      .trim()
      .required(() => t('Required')),
    endDate: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const GlPeriodsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),

    startDate: Yup.string()
      .trim()
      .required(() => t('Required')),
    endDate: Yup.string()
      .trim()
      .required(() => t('Required')),

    periodYear: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const TaxManagersSchema = (t) => {
  return Yup.object().shape({
    taxType: Yup.string()
      .trim()
      .required(() => t('Required')),
    accountId: Yup.string()
      .trim()
      .required(() => t('Required')),

    startDateActive: Yup.date().required(() => t('Required')),
    taxRate: Yup.number()
      .typeError(t('الرجاء إدخال قيمة رقمية')) // رسالة توضح أن المدخل يجب أن يكون رقميًا
      .required(t('Required')), // رسالة توضح أن الحقل إلزامي
    ledgerId: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const PaymentTermsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t('Required')), // التحقق من أن الحقل ليس فارغًا
    name2: Yup.string().required(() => t('Required')),
    // description: Yup.string().required(() => t('Required')),
    period: Yup.number()
      .required(() => t('Required'))
      .min(1, 'يجب أن تكون الفترة أكبر من أو تساوي 1') // التحقق من الحد الأدنى للقيمة
      .typeError('الفترة يجب أن تكون رقمية'), // التحقق من أن المدخل رقم
  });
};

export const AddArTransactionSchema = (t) => {
  return Yup.object().shape({
    salesrepId: Yup.string()
      .trim()
      .required(() => t('Required')),
    billToCustomerId: Yup.string()
      .trim()
      .required(() => t('Required')),
    shipToCustomerId: Yup.string()
      .trim()
      .required(() => t('Required')),

    transactionNum: Yup.string()
      .trim()
      .required(() => t('Required')),

    peopleId: Yup.string()
      .trim()
      .required(() => t('Required')),
    organizationId: Yup.string().required(() => t('Required')),
    transactionType: Yup.string().required(() => t('Required')),

    transactionDate: Yup.string().required(() => t('Required')),
    paymentTermsId: Yup.string().required(() => t('Required')),
    paymentMethodId: Yup.string().required(() => t('Required')),
    currencyCode: Yup.string().required(() => t('Required')),
    currencyConversionRate: Yup.string().required(() => t('Required')),

    arTransactionLinesList: Yup.array().of(
      Yup.object().shape({
        invItemId: Yup.string().when('lineType', {
          is: 'Item_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),
        uom: Yup.string().when('lineType', {
          is: 'Item_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),
        qty: Yup.string().when('lineType', {
          is: 'Item_Inv',
          then: () =>
            Yup.number()
              .min(1)
              .required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        description: Yup.string().when('lineType', {
          is: (value) => value !== 'Item_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        lineType: Yup.string().required(() => t('Required')),

        total: Yup.number()
          .positive(() => t('The value must be positive')) // للتأكد من أن القيمة موجبة
          .min(1, () => t('Quantity must be at least 1')) // للتأكد من أن القيمة على الأقل 1
          .required(() => t('Required')), // لجعل الحقل مطلوبًا        // : Yup.number().min(1, 'Quantity must be at least 1').required(),

        accountId: Yup.string().required(() => t('Required')),
        costCenterId: Yup.string().required(() => t('Required')),

        // discountRate: line.discountRate,
        // discountAmount: line.discountAmount,
        // vatRate: line.vatRate,
        // total: line.total,
        // vatAmount: line.vatAmount,
        // accountId: null,
        // costCenterId: null,
        // currencyCode: data.currencyCode ?? '',
        // currencyConversionRate: data.currencyConversionRate ?? '',
        // purHeaderId: data.id ?? '',
        // purLineId: line.id,
        // codeCombination: '',
      })
    ),

    arReceiptSchedulesList: Yup.array().of(
      Yup.object().shape({
        dueDate: Yup.string().required(() => t('Required')),

        amount: Yup.number()
          .positive(() => t('The value must be positive')) // للتأكد من أن القيمة موجبة
          .min(1, () => t('value must be at least 1')) // للتأكد من أن القيمة على الأقل 1
          .required(() => t('Required')), // لجعل الحقل مطلوبًا        // : Yup.number().min(1, 'Quantity must be at least 1').required(),
      })
    ),
  });
};

//#endregion

export const AddIssueTransactionsSchema = (t) => {
  return Yup.object().shape({
    code: Yup.string().required(() => t('Required')),
    branchId: Yup.string().required(() => t('Required')),
    transactionDate: Yup.date().required(() => t('Required')),
    // personId: Yup.string().required(() => t('Required')),
    subInventoryId: Yup.string().required(() => t('Required')),
    inventoryId: Yup.string().required(() => t('Required')),

    // referenceId: Yup.string().required(() => t('Required')),
    // supplierId: Yup.string().required(() => t('Required')),
    // customerId: Yup.string().required(() => t('Required')),

    referenceId: Yup.string().when('code', {
      is: (value) => ['SPR', 'WOI', 'IOI', 'CCA', 'SOI', 'INV', 'MOI'].includes(value),
      then: () => Yup.string().required(() => t('Required')),
      otherwise: () => Yup.string().nullable(),
    }),

    supplierId: Yup.string().when('code', {
      is: (value) => ['SPR'].includes(value),
      then: () => Yup.string().required(() => t('Required')),
      otherwise: () => Yup.string().nullable(),
    }),

    customerId: Yup.string().when('code', {
      is: (value) => ['SOI', 'INV'].includes(value),
      then: () => Yup.string().required(() => t('Required')),
      otherwise: () => Yup.string().nullable(),
    }),

    materialTransactionsLineList: Yup.array().of(
      Yup.object().shape({
        // transactionQuantityOut: 0,

        transactionItemId: Yup.string().required(() => t('Required')),
        transactionUom: Yup.string().required(() => t('Required')),

        transactionQuantityIn: Yup.number().min(1, 'Quantity must be at least 1').required(),
      })
    ),
  });
};

export const AddMoveTransactionsSchema = (t) => {
  return Yup.object().shape({
    transactionDate: Yup.date().required(() => t('Required')),
    branchId: Yup.string().required(() => t('Required')),
    referenceType: Yup.string().required(() => t('Required')),
    referenceId: Yup.string().required(() => t('Required')),
    supplierId: Yup.string().required(() => t('Required')),
    customerId: Yup.string().required(() => t('Required')),
    personId: Yup.string().required(() => t('Required')),
    subInventoryId: Yup.string().required(() => t('Required')),
    inventoryId: Yup.string().required(() => t('Required')),
    code: Yup.string().required(() => t('Required')),
    branchTo: Yup.string().required(() => t('Required')),
    inventoryTo: Yup.string().required(() => t('Required')),
    // subInventoryId: Yup.string().required(() => t('Required')),
    subInventoryTo: Yup.string().required(() => t('Required')),

    transactionsLinesList: Yup.array().of(
      Yup.object().shape({
        // discountAmount: Yup.number().min(0, 'Discount amount cannot be negative').required(),
        // discountRate: Yup.number().min(0, 'Discount rate cannot be negative').required(),
        // itemId: Yup.string().required('Item ID is required'),
        // description: Yup.string().nullable(),
        // vatAmount: Yup.number().min(0, 'VAT amount cannot be negative').nullable(),
        // vatRate: Yup.number().min(0, 'VAT rate cannot be negative').nullable(),
        // lineNumber: Yup.number().min(1, 'Line number must be at least 1').required(),
        // price: Yup.number().min(0, 'Price cannot be negative').required(),
        // total: Yup.number().min(0, 'Total cannot be negative').required(),
        // uom: Yup.string().required(() => t('Required')),

        description: Yup.string().when('type', {
          is: 'Non_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string(),
        }),

        itemId: Yup.string().when('type', {
          is: 'Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        uom: Yup.string().when('type', {
          is: 'Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        qty: Yup.number().min(1, 'Quantity must be at least 1').required(),
        type: Yup.string().required(() => t('Required')),
      })
    ),
  });
};

export const MasterItemsSchema = (t) => {
  return Yup.object().shape({
    itemCode: Yup.string().required(() => t('Required')),
    barcode: Yup.string()
      .trim()
      .required(() => t('Required')),
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    // displayName: Yup.string().required(() => t('Required')),
    // displayName2: Yup.string().required(() => t('Required')),
    categoryId: Yup.string()
      .trim()
      .required(() => t('Required')),
    itemType: Yup.string()
      .trim()
      .required(() => t('Required')),
    // salesPrice: Yup.string().required(() => t('Required')),
    // purchasePrice: Yup.string().required(() => t('Required')),
    unitOfMeasure: Yup.string()
      .trim()
      .required(() => t('Required')),

    demandTimeUp: Yup.number()
      .typeError(() => t('Must be a number'))
      .positive(() => t('Must be positive')),
    // .required(() => t('Required'))
    demandTimeDown: Yup.number()
      .typeError(() => t('Must be a number'))
      .positive(() => t('Must be positive')),
    // .required(() => t('Required'))
    salesPrice: Yup.number()
      .typeError(() => t('Must be a number'))
      .positive(() => t('Must be positive'))
      .required(() => t('Required')),

    purchasePrice: Yup.number()
      .typeError(() => t('Must be a number'))
      .positive(() => t('Must be positive'))
      .required(() => t('Required')),
    discountRate: Yup.number()
      .typeError(() => t('Must be a number'))
      .min(0, () => t('Must be at least 0'))
      .max(100, () => t('Must be at most 100')),
    // .required(() => t('Required')),

    // image: "",

    // description: "",
  });
};
export const ItemConversionSchema = (t) => {
  return Yup.object().shape({
    uom: Yup.string()
      .trim()
      .required(() => t('Required')),
    barcode: Yup.string()
      .trim()
      .required(() => t('Required')),
    conversionValue: Yup.number()
      .typeError(() => t('Must be a number'))
      .positive(() => t('Must be positive'))
      .required(() => t('Required')),
  });
};
export const LotItemSchema = (t) => {
  return Yup.object().shape({
    expirationDate: Yup.date().required(() => t('Required')),
    lotNumber: Yup.string()
      .trim()
      .required(() => t('Required')),
    unitOfMeasure: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

export const TransactionTypeReasonsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(() => t('Required')),
    name2: Yup.string()
      .trim()
      .required(() => t('Required')),
    description: Yup.string()
      .trim()
      .required(() => t('Required')),
    description2: Yup.string()
      .trim()
      .required(() => t('Required')),
    accountId: Yup.string()
      .trim()
      .required(() => t('Required')),
    glLedgerId: Yup.string()
      .trim()
      .required(() => t('Required')),
  });
};

//#region _______ Sales
export const AddPriceListSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t('Required')),
    name2: Yup.string().required(() => t('Required')),
    currencyCode: Yup.string().required(() => t('Required')),
    startDateActive: Yup.date().required(() => t('Required')),
    endDateActive: Yup.date().required(() => t('Required')),

    // description: '',
    // typeCode: '',
  });
};

export const AddQuoteInvoiceSchema = (t) => {
  return Yup.object().shape({
    // no: Yup.number().required(() => t('Required')),
    // code: Yup.string().required(() => t('Required')),
    // name: Yup.string().required(() => t('Required')),
    // name2: Yup.string().required(() => t('Required')),
    // transTypeId: Yup.string().required(() => t('Required')),
    organizationId: Yup.string().required(() => t('Required')),
    peopleId: Yup.string().required(() => t('Required')),
    // recipientname: Yup.string().required(() => t('Required')),
    transactionDate: Yup.date().required(() => t('Required')),
    expirationDate: Yup.date().required(() => t('Required')),
    currencyCode: Yup.string().required(() => t('Required')),
    // deliveryDate: Yup.date().required(() => t('Required')),
    // dueDate: Yup.date().required(() => t('Required')),
    // paymentTermsId: Yup.string().required(() => t('Required')),
    personId: Yup.string().required(() => t('Required')),
    // inventoryId: Yup.string().required(() => t('Required')),
    // description: Yup.string(),
    // discountRate: Yup.number().min(0).max(100),
    // discountAmount: Yup.number().min(0),
    // vat: Yup.number().min(0),
    // netSale: Yup.number().min(0),
    // contractId: Yup.string(),
    // ameStatus: Yup.string(),
    // paymentTerms: Yup.string(),
    // deliveryTerm: Yup.string(),
    // waybill: Yup.string(),
    // phone: Yup.string(),
    // currencyConversionRate: Yup.number().min(0),
    // batchId: Yup.string(),

    transactionsLinesList: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().nullable(),
        discountAmount: Yup.number().nullable(),
        discountRate: Yup.number().nullable(),
        itemId: Yup.string().required('رقم الصنف مطلوب'),
        lineNumber: Yup.number().required('رقم السطر مطلوب'),
        price: Yup.number().required('السعر مطلوب').positive('يجب أن يكون السعر رقم إيجابي'),
        qty: Yup.number().required('الكمية مطلوبة').positive('يجب أن تكون الكمية رقم إيجابي'),
        total: Yup.number().nullable(),
        uom: Yup.string().required('الوحدة مطلوبة'),
        vatAmount: Yup.number().nullable(),
        vatRate: Yup.number().nullable(),
      })
    ),
  });
};

export const SellOrderSchema = (t) => {
  return Yup.object().shape({
    // no: Yup.number().required(() => t('Required')),
    // code: Yup.string().required(() => t('Required')),
    // name: Yup.string().required(() => t('Required')),
    // name2: Yup.string().required(() => t('Required')),
    // transTypeId: Yup.string().required(() => t('Required')),
    organizationId: Yup.string().required(() => t('Required')),
    peopleId: Yup.string().required(() => t('Required')),
    // recipientname: Yup.string().required(() => t('Required')),
    transactionDate: Yup.date().required(() => t('Required')),
    // expirationDate: Yup.date().required(() => t('Required')),
    currencyCode: Yup.string().required(() => t('Required')),
    // deliveryDate: Yup.date().required(() => t('Required')),
    // dueDate: Yup.date().required(() => t('Required')),
    // paymentTermsId: Yup.string().required(() => t('Required')),
    personId: Yup.string().required(() => t('Required')),
    inventoryId: Yup.string().required(() => t('Required')),
    // description: Yup.string(),
    // discountRate: Yup.number().min(0).max(100),
    // discountAmount: Yup.number().min(0),
    // vat: Yup.number().min(0),
    // netSale: Yup.number().min(0),
    // contractId: Yup.string(),
    // ameStatus: Yup.string(),
    // paymentTerms: Yup.string(),
    // deliveryTerm: Yup.string(),
    // waybill: Yup.string(),
    // phone: Yup.string(),
    // currencyConversionRate: Yup.number().min(0),
    // batchId: Yup.string(),

    transactionsLinesList: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().nullable(),
        discountAmount: Yup.number().nullable(),
        discountRate: Yup.number().nullable(),
        itemId: Yup.string().required('رقم الصنف مطلوب'),
        lineNumber: Yup.number().required('رقم السطر مطلوب'),
        price: Yup.number().required('السعر مطلوب').positive('يجب أن يكون السعر رقم إيجابي'),
        qty: Yup.number().required('الكمية مطلوبة').positive('يجب أن تكون الكمية رقم إيجابي'),
        total: Yup.number().nullable(),
        uom: Yup.string().required('الوحدة مطلوبة'),
        vatAmount: Yup.number().nullable(),
        vatRate: Yup.number().nullable(),
      })
    ),
  });
};

export const AddInvoiceSalesSchema = (t) => {
  return Yup.object().shape({
    // no: Yup.number().required(() => t('Required')),
    // code: Yup.string().required(() => t('Required')),
    // name: Yup.string().required(() => t('Required')),
    // name2: Yup.string().required(() => t('Required')),
    // transTypeId: Yup.string().required(() => t('Required')),
    organizationId: Yup.string().required(() => t('Required')),
    peopleId: Yup.string().required(() => t('Required')),
    recipientname: Yup.string().required(() => t('Required')),
    transactionDate: Yup.date().required(() => t('Required')),
    deliveryDate: Yup.date().required(() => t('Required')),
    dueDate: Yup.date().required(() => t('Required')),
    paymentTermsId: Yup.string().required(() => t('Required')),
    personId: Yup.string().required(() => t('Required')),
    inventoryId: Yup.string().required(() => t('Required')),
    // description: Yup.string(),
    // discountRate: Yup.number().min(0).max(100),
    // discountAmount: Yup.number().min(0),
    // vat: Yup.number().min(0),
    // netSale: Yup.number().min(0),
    // contractId: Yup.string(),
    // ameStatus: Yup.string(),
    // paymentTerms: Yup.string(),
    // deliveryTerm: Yup.string(),
    // waybill: Yup.string(),
    // phone: Yup.string(),
    // currencyCode: Yup.string(),
    // currencyConversionRate: Yup.number().min(0),
    // batchId: Yup.string(),
  });
};
export const AddPosSchema = (t) => {
  return Yup.object().shape({
    organizationId: Yup.string().required(() => t('Required')),
    peopleId: Yup.string().required(() => t('Required')),
    posType: Yup.string().required(() => t('Required')),
    name: Yup.string().required(() => t('Required')),
    name2: Yup.string().required(() => t('Required')),
    printTemplateId: Yup.string().required(() => t('Required')),

    inventoryId: Yup.string().required(() => t('Required')),
  });
};

export const AddSaleReturnsSchema = (t) => {
  return Yup.object().shape({
    // no: Yup.number().required(() => t('Required')),

    transactionDate: Yup.date().required(() => t('Required')),

    transactionsLinesList: Yup.array().of(
      Yup.object().shape({
        // qty: Yup.number().required('الكمية مطلوبة').positive('يجب أن تكون الكمية رقم إيجابي'),
        // ReturnQty: Yup.number()
        //   .required('الكمية المسترجعة مطلوبة')
        //   .positive('يجب أن تكون الكمية رقم إيجابي'),

        ReturnQty: Yup.string().when('selectedRow', {
          is: true,
          then: () =>
            Yup.number()
              .required('الكمية المسترجعة مطلوبة')
              .positive('يجب أن تكون الكمية رقم إيجابي'),
        }),
      })
    ),
  });
};

//#endregion

export const AddPurchaseRequestSchema = (t) => {
  return Yup.object().shape({
    // isAme: Yup.boolean(),

    categoryId: Yup.string().required(() => t('Required')),

    organizationId: Yup.string().required(() => t('Required')),

    // peopleId: Yup.string().required('People ID is required'),
    // recipientname: Yup.string().required('Recipient name is required'),
    // billToLocationId: Yup.string().nullable(),
    // shipToLocationId: Yup.string().required('Ship-to Location ID is required'),
    // referenceId: Yup.string().nullable(),
    transactionDate: Yup.date().required(() => t('Required')),
    deliveryDate: Yup.date().required(() => t('Required')),

    // expirationDate: Yup.date().nullable(),
    // dueDate: Yup.date().nullable(),
    // paymentTermsId: Yup.string().nullable(),
    // paymentMethodId: Yup.string().nullable(),
    // description: Yup.string().nullable(),
    // grossAmount: Yup.number().nullable(),
    // discountRate: Yup.number().min(0, 'Discount rate cannot be negative').nullable(),
    // discountAmount: Yup.number().min(0, 'Discount amount cannot be negative').nullable(),
    // vat: Yup.number().min(0, 'VAT cannot be negative').nullable(),
    // netAmount: Yup.number().nullable(),
    // contractId: Yup.string().nullable(),
    // ameStatus: Yup.string().nullable(),
    // personId: Yup.string().nullable(),
    // paymentTerms: Yup.string().nullable(),
    // deliveryTerm: Yup.string().nullable(),
    // inventoryId: Yup.string().required(() => t('Required')),
    // waybill: Yup.string().nullable(),
    // phone: Yup.string().matches(/^\d+$/, 'Phone must be a number').nullable(),
    // currencyCode: Yup.string().nullable(),
    // currencyConversionRate: Yup.number().nullable(),
    // batchId: Yup.string().nullable(),

    transactionsLinesList: Yup.array().of(
      Yup.object().shape({
        // discountAmount: Yup.number().min(0, 'Discount amount cannot be negative').required(),
        // discountRate: Yup.number().min(0, 'Discount rate cannot be negative').required(),
        // itemId: Yup.string().required('Item ID is required'),
        // description: Yup.string().nullable(),
        // vatAmount: Yup.number().min(0, 'VAT amount cannot be negative').nullable(),
        // vatRate: Yup.number().min(0, 'VAT rate cannot be negative').nullable(),
        // lineNumber: Yup.number().min(1, 'Line number must be at least 1').required(),
        // price: Yup.number().min(0, 'Price cannot be negative').required(),
        // total: Yup.number().min(0, 'Total cannot be negative').required(),
        // uom: Yup.string().required(() => t('Required')),

        description: Yup.string().when('type', {
          is: 'Non_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string(),
        }),

        itemId: Yup.string().when('type', {
          is: 'Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        uom: Yup.string().when('type', {
          is: 'Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        qty: Yup.number().min(1, 'Quantity must be at least 1').required(),
        type: Yup.string().required(() => t('Required')),
      })
    ),
  });
};

export const AddPurchaseOrderSchema = (t) => {
  return Yup.object().shape({
    // isAme: Yup.boolean(),

    // categoryId: Yup.string().required(() => t('Required')),
    transactionDate: Yup.date().required(() => t('Required')),
    deliveryDate: Yup.date().required(() => t('Required')),
    organizationId: Yup.string().required(() => t('Required')),
    // inventoryId: Yup.string().required(() => t('Required')),
    peopleId: Yup.string().required(() => t('Required')),
    currencyCode: Yup.string().required(() => t('Required')),
    paymentMethodId: Yup.string().required(() => t('Required')),
    // recipientname: Yup.string().required('Recipient name is required'),
    // billToLocationId: Yup.string().nullable(),
    // shipToLocationId: Yup.string().required('Ship-to Location ID is required'),
    // referenceId: Yup.string().nullable(),
    // expirationDate: Yup.date().nullable(),
    // dueDate: Yup.date().nullable(),
    // paymentTermsId: Yup.string().nullable(),
    // description: Yup.string().nullable(),
    // grossAmount: Yup.number().nullable(),
    // discountRate: Yup.number().min(0, 'Discount rate cannot be negative').nullable(),
    // discountAmount: Yup.number().min(0, 'Discount amount cannot be negative').nullable(),
    // vat: Yup.number().min(0, 'VAT cannot be negative').nullable(),
    // netAmount: Yup.number().nullable(),
    // contractId: Yup.string().nullable(),
    // ameStatus: Yup.string().nullable(),
    // personId: Yup.string().nullable(),
    // paymentTerms: Yup.string().nullable(),
    // deliveryTerm: Yup.string().nullable(),
    // waybill: Yup.string().nullable(),
    // phone: Yup.string().matches(/^\d+$/, 'Phone must be a number').nullable(),
    // currencyConversionRate: Yup.number().nullable(),
    // batchId: Yup.string().nullable(),

    transactionsLinesList: Yup.array().of(
      Yup.object().shape({
        // discountAmount: Yup.number().min(0, 'Discount amount cannot be negative').required(),
        // discountRate: Yup.number().min(0, 'Discount rate cannot be negative').required(),
        // itemId: Yup.string().required('Item ID is required'),
        // description: Yup.string().nullable(),
        // vatAmount: Yup.number().min(0, 'VAT amount cannot be negative').nullable(),
        // vatRate: Yup.number().min(0, 'VAT rate cannot be negative').nullable(),
        // lineNumber: Yup.number().min(1, 'Line number must be at least 1').required(),
        // total: Yup.number().min(0, 'Total cannot be negative').required(),
        // uom: Yup.string().required(() => t('Required')),
        type: Yup.string().required(() => t('Required')),

        price: Yup.number()
          .min(0, 'Price cannot be negative')
          .required(() => t('Required')),
        description: Yup.string().when('type', {
          is: 'Non_Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string(),
        }),

        itemId: Yup.string().when('type', {
          is: 'Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        uom: Yup.string().when('type', {
          is: 'Inv',
          then: () => Yup.string().required(() => t('Required')),
          otherwise: () => Yup.string().nullable(),
        }),

        qty: Yup.number().min(1, 'Quantity must be at least 1').required(),
      })
    ),
  });
};

export const AddAssetsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t('Required')),
    name2: Yup.string().required(() => t('Required')),
    assetNumber: Yup.string().required(() => t('Required')),
    tagNumber: Yup.string().required(() => t('Required')),
    serialNumber: Yup.string().required(() => t('Required')),
    categoryId: Yup.string().required(() => t('Required')),
    organizationId: Yup.string().required(() => t('Required')),

    ownedLeased: Yup.string().required(() => t('Required')),
    leasedId: Yup.string().required(() => t('Required')),
    newUsed: Yup.string().required(() => t('Required')),
    depreciationMethod: Yup.string().required(() => t('Required')),

    assetCostAccount: Yup.string().required(() => t('Required')),
    deprnExpenseAccount: Yup.string().required(() => t('Required')),
    deprnReserveAccount: Yup.string().required(() => t('Required')),
    glLedgerId: Yup.string().required(() => t('Required')),
    datePlacedInService: Yup.date().required(() => t('Required')),

    originalCost: Yup.number().required(() => t('Required')),
    adjustedCost: Yup.number().required(() => t('Required')),
    percentage: Yup.number().required(() => t('Required')),
    salvageValue: Yup.number().required(() => t('Required')),
    lifeInMonths: Yup.number().required(() => t('Required')),
  });
};

//#region _______ PUR

//#endregion
