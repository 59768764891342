import PurchaseQuotation from '../../pages/PUR/Transactions/Quotation/Index';
import AddPurchaseQuotation from '../../pages/PUR/Transactions/Quotation/Add';
import UpdatePurchaseQuotation from '../../pages/PUR/Transactions/Quotation/Update';

import PurchaseRequests from '../../pages/PUR/Transactions/PurchaseRequest/Index';
import AddPurchaseRequest from '../../pages/PUR/Transactions/PurchaseRequest/Add';
import UpdatePurchaseRequest from '../../pages/PUR/Transactions/PurchaseRequest/Update';

import PurchaseOrder from '../../pages/PUR/Transactions/PurchaseOrder/Index';
import AddPurchaseOrder from '../../pages/PUR/Transactions/PurchaseOrder/Add';
import UpdatePurchaseOrder from '../../pages/PUR/Transactions/PurchaseOrder/Update';

import PurchaseBill from '../../pages/PUR/Transactions/PurchaseBill/Index';
import AddPurchaseBill from '../../pages/PUR/Transactions/PurchaseBill/Add';
import UpdatePurchaseBill from '../../pages/PUR/Transactions/PurchaseBill/Update';

import PurchaseReturn from '../../pages/PUR/Transactions/PurchaseReturn/Index';
import AddUpdateViewPurchaseReturn from '../../pages/PUR/Transactions/PurchaseReturn/PurchaseReturn';

import ReceiveNoneInventoryItem from '../../pages/PUR/Transactions/ReceiveNoneInventoryItem/Index';

import PurchasesSuppliers from '../../pages/PUR/Settings/Suppliers/Index';
import AddSuppliers from '../../pages/PUR/Settings/Suppliers/Add';
import UpdateSupplier from '../../pages/PUR/Settings/Suppliers/Update';
import BranchesSupplier from '../../pages/PUR/Settings/Suppliers/Branches/Index';


import PurchaseReports from '../../pages/PUR/PurchaseReports/PurchaseReports';
import ReportsBuilder from '../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder';

import PurchaseDashboards from '../../pages/PUR/PurchaseDashboards/PurchaseDashboards';
import BuilderDashboard from '../../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard';


const purchasesRoutes = [
  {
    path: 'Purchases/*',
    children: [
      { path: 'PurchaseReports', element: <PurchaseReports /> },
      { path: 'PurchaseReports/:ReportId/ReportsBuilder', element: <ReportsBuilder /> },

      { path: 'PurchaseDashboards', element: <PurchaseDashboards /> },
      { path: 'PurchaseDashboards/:dashboardId/BuilderDashboard', element: <BuilderDashboard /> },


    ],
  },
  {
    path: 'PurchasesSettings/*',
    children: [
      { path: 'SUPPLIERS', element: <PurchasesSuppliers /> },
      { path: 'SUPPLIERS/AddSupplier', element: <AddSuppliers /> },
      { path: 'SUPPLIERS/:Id/UpdateSupplier', element: <UpdateSupplier /> },

      { path: 'SUPPLIERS/:id/BranchesSupplier', element: <BranchesSupplier /> },
    ],
  },

  {
    path: 'PurchasesTransactions/*',
    children: [
      { path: 'Quotation', element: <PurchaseQuotation /> },
      { path: 'Quotation/AddPurchaseQuotation/:id?', element: <AddPurchaseQuotation /> },
      { path: 'Quotation/:id/UpdatePurchaseQuotation', element: <UpdatePurchaseQuotation /> },

      { path: 'PurchaseRequests', element: <PurchaseRequests /> },
      { path: 'PurchaseRequests/AddPurchaseRequest', element: <AddPurchaseRequest /> },
      {
        path: 'PurchaseRequests/:id/UpdatePurchaseRequest',
        element: <UpdatePurchaseRequest />,
      },

      { path: 'PurchaseOrder', element: <PurchaseOrder /> },
      { path: 'PurchaseOrder/AddPurchaseOrder/:id?', element: <AddPurchaseOrder /> },
      { path: 'PurchaseOrder/:id/UpdatePurchaseOrder', element: <UpdatePurchaseOrder /> },

      { path: 'PurchaseBill', element: <PurchaseBill /> },
      { path: 'PurchaseBill/AddPurchaseBill', element: <AddPurchaseBill /> },
      { path: 'PurchaseBill/:id/UpdatePurchaseBill', element: <UpdatePurchaseBill /> },

      { path: 'PurchaseReturn', element: <PurchaseReturn /> },
      {
        path: 'PurchaseReturn/:BillId/AddUpdateViewPurchaseReturn',
        element: <AddUpdateViewPurchaseReturn />,
      },

      { path: 'ReceiveNoneInventoryItem', element: <ReceiveNoneInventoryItem /> },
    ],
  },
];

export default purchasesRoutes;
