import BankAccounts from '../../pages/Finance/Settings/BankAccounts/Index';
import TypesOfExpenses from '../../pages/Finance/Settings/TypesOfExpenses/ExpenseCategory/Index';
import CashBoxes from '../../pages/Finance/Settings/CashBoxes/Index';
import PaymentTerms from '../../pages/Finance/Settings/PaymentTerms/Index';
import Currencies from '../../pages/Finance/Settings/Currencies/Index';
import FiscalYear from '../../pages/Finance/Settings/FiscalYear/Index';
import AccountingPeriod from '../../pages/Finance/Settings/AccountingPeriod/Index';
import Ledgers from '../../pages/Finance/Settings/Ledgers/Index';
import DailyRates from '../../pages/Finance/Settings/DailyRates/Index';
import JeCategory from '../../pages/Finance/Settings/JeCategory/Index';
import JeSource from '../../pages/Finance/Settings/JeSource/Index';
import Vat from '../../pages/Finance/Settings/VAT/Index';
import ChartAccounts from '../../pages/Finance/ChartAccounts/Index';
import ChartAccountsDetails from '../../pages/Finance/ChartAccountsDetails/Accounts/Index';
import CompaniesAndBranches from '../../pages/Finance/ChartAccountsDetails/CompaniesAndBranches/Index';
import CostCenters from '../../pages/Finance/ChartAccountsDetails/CostCenters/Index';
import Journals from '../../pages/Finance/Journals/Index';
import AddJournals from '../../pages/Finance/Journals/AddJournals/AddJournals';
import UpdateJournals from '../../pages/Finance/Journals/UpdateJournals/UpdateJournals';
import UnPostedJournalsList from '../../pages/Finance/Journals/PostJournals/UnPostedJournalsList';
//_Finance Ap
import ApInvoices from '../../pages/Finance/Ap/ApInvoices/Index';
import AddApInvoices from '../../pages/Finance/Ap/ApInvoices/Add';
import UpdateApInvoices from '../../pages/Finance/Ap/ApInvoices/Update';
import ApInvoicesCreateAccount from '../../pages/Finance/Ap/ApInvoices/CreateAccount/ListApInvoices';

import ApPayments from '../../pages/Finance/Ap/ApPayments/Index';
import AddApPayments from '../../pages/Finance/Ap/ApPayments/Add/Add';
import UpdateApPayments from '../../pages/Finance/Ap/ApPayments/Update/Update';
import ApPaymentsCreateAccount from '../../pages/Finance/Ap/ApPayments/CreateAccount/ListApPayments';

import ApExpense from '../../pages/Finance/Ap/ApExpense/Index';
import AddApExpense from '../../pages/Finance/Ap/ApExpense/Add';

import InvoicePayments from '../../pages/Finance/Ap/InvoicePayments/Index';
import ViewInvoice from '../../pages/Finance/Ap/InvoicePayments/ViewInvoice/ViewInvoice';

import ArTransactions from '../../pages/Finance/Ar/ArTransactions/Index';
import AddArTransactions from '../../pages/Finance/Ar/ArTransactions/Add';
import UpdateArTransactions from '../../pages/Finance/Ar/ArTransactions/Update';
import ArTransactionsCreateAccount from '../../pages/Finance/Ar/ArTransactions/CreateAccount/ListArTransactions';

import ArReceipts from '../../pages/Finance/Ar/ArReceipts/Index';
import AddArReceipts from '../../pages/Finance/Ar/ArReceipts/Add/Add';
import UpdateArReceipts from '../../pages/Finance/Ar/ArReceipts/Update/Update';

import FaCategories from '../../pages/Finance/FA/FaCategories/Index';
import AddFaCategory from '../../pages/Finance/FA/FaCategories/AddFaCategory';

import Budget from '../../pages/Finance/Budget/Index';
import UpdateBudget from '../../pages/Finance/Budget/Update';

import ControlCodeCombinations from '../../pages/Finance/ControlCodeCombinations/Index';

import IncomeStatement from '../../pages/Finance/FinancialReports/IncomeStatement/IncomeStatement';
import BalanceSheet from '../../pages/Finance/FinancialReports/BalanceSheet/BalanceSheet';

//_ Faxid Assets

import FaAssets from '../../pages/Finance/FA/FaAssets/Index';
import AddAssets from '../../pages/Finance/FA/FaAssets/AddAssets';
import AssetsDetails from '../../pages/Finance/FA/FaAssets/AssetsDetails/AssetsDetails';

//

import ConsolidationSet from '../../pages/Finance/Settings/ConsolidationSet/ConsolidationSet';

const financeRoutes = [
  {
    path: 'FinanceSettings/*',
    children: [
      { path: 'FiscalYear', element: <FiscalYear /> },
      { path: 'DailyRates', element: <DailyRates /> },
      { path: 'JeCategory', element: <JeCategory /> },
      { path: 'JeSource', element: <JeSource /> },
      { path: 'VAT', element: <Vat /> },
      { path: 'AccountingPeriod', element: <AccountingPeriod /> },
      { path: 'ChartAccounts', element: <ChartAccounts /> },
      {
        path: 'ChartAccounts/:ChartAccountsId/ChartAccountsDetails',
        element: <ChartAccountsDetails />,
      },
      {
        path: 'ChartAccounts/:ChartAccountsId/CompaniesAndBranches',
        element: <CompaniesAndBranches />,
      },
      {
        path: 'ChartAccounts/:ChartAccountsId/CostCenters',
        element: <CostCenters />,
      },

      { path: 'Ledgers', element: <Ledgers /> },

      { path: 'ConsolidationSet', element: <ConsolidationSet /> },
      // { path: 'ConsolidationSet/:type', element: <IndexConsolidationSet /> },

      { path: 'TypesOfExpenses', element: <TypesOfExpenses /> },
      { path: 'BankAccounts', element: <BankAccounts /> },
      { path: 'CashBoxes', element: <CashBoxes /> },

      { path: 'PaymentTerms', element: <PaymentTerms /> },
    ],
  },

  {
    path: 'Finance/*',
    children: [
      { path: 'Journals', element: <Journals /> },
      { path: 'Journals/:ledgerId/AddJournals', element: <AddJournals /> },
      { path: 'Journals/:Id/UpdateJournals', element: <UpdateJournals /> },
      { path: 'PostingJournals', element: <UnPostedJournalsList /> },
    ],
  },

  // {
  //   path: 'Finance/Ap',
  //   children: [
  //     //الإعدادات
  //     // { path: 'Suppliers', element: <Suppliers /> },

  //     { path: 'TypesOfExpenses', element: <TypesOfExpenses /> },
  //     { path: 'PaymentTerms', element: <PaymentTerms /> },
  //     { path: 'Currencies', element: <Currencies /> },

  //     { path: 'ApInvoices', element: <ApInvoices /> },
  //     { path: 'ApInvoices/AddApInvoices/:referenceId?', element: <AddApInvoices /> },
  //     { path: 'ApInvoices/:IdInvoices/UpdateApInvoices', element: <UpdateApInvoices /> },
  //     { path: 'CreateAccountApInvoices', element: <ApInvoicesCreateAccount /> },

  //     { path: 'ApPayments', element: <ApPayments /> },
  //     { path: 'ApPayments/AddApPayments', element: <AddApPayments /> },
  //     { path: 'ApPayments/:paymentId/UpdateApPayments', element: <UpdateApPayments /> },
  //     { path: 'CreateAccountApPayments', element: <ApPaymentsCreateAccount /> },

  //     { path: 'ApExpense', element: <ApExpense /> },
  //     { path: 'ApExpense/AddApExpense/:referenceId?', element: <AddApExpense /> },

  //     { path: 'InvoicePayments', element: <InvoicePayments /> },
  //     { path: 'InvoicePayments/:invoicesId/ViewInvoice', element: <ViewInvoice /> },
  //   ],
  // },

  // {
  //   path: 'Finance/Ar',
  //   children: [
  //     { path: 'TypesOfExpenses', element: <TypesOfExpenses /> },
  //     { path: 'PaymentTerms', element: <PaymentTerms /> },
  //     { path: 'Currencies', element: <Currencies /> },

  //     { path: 'ArTransactions', element: <ArTransactions /> },
  //     {
  //       path: 'ArTransactions/AddArTransactions',
  //       element: <AddArTransactions />,
  //     },
  //     {
  //       path: 'ArTransactions/:transactionId/UpdateArTransactions',
  //       element: <UpdateArTransactions />,
  //     },
  //     { path: 'CreateAccountArTransactions', element: <ArTransactionsCreateAccount /> },

  //     { path: 'ArReceipts', element: <ArReceipts /> },
  //     { path: 'ArReceipts/AddArReceipts', element: <AddArReceipts /> },
  //     { path: 'ArReceipts/:receiptId/UpdateArReceipts', element: <UpdateArReceipts /> },
  //   ],
  // },

  {
    path: 'Finance/FA',
    children: [
      { path: 'FaCategories', element: <FaCategories /> },
      { path: 'FaCategories/AddFaCategory', element: <AddFaCategory /> },

      { path: 'FaAssets', element: <FaAssets /> },

      { path: 'FaAssets/AddAssets', element: <AddAssets /> },
      { path: 'FaAssets/:assetsId/AssetsDetails', element: <AssetsDetails /> },
    ],
  },

  {
    path: 'Finance/*',
    children: [
      { path: 'Budget', element: <Budget /> },
      { path: 'Budget/:id/UpdateBudget', element: <UpdateBudget /> },
    ],
  },

  {
    path: 'Finance/*',
    children: [
      { path: 'ControlCodeCombinations', element: <ControlCodeCombinations /> },
     ],
  },


  {
    path: 'Finance/*',
    children: [
      { path: 'IncomeStatement', element: <IncomeStatement /> },
      { path: 'BalanceSheet', element: <BalanceSheet /> },
     ],
  },
];

export default financeRoutes;
