import { Navigate, createBrowserRouter } from 'react-router-dom';
import App from '../App';

import UserDashboard from '../pages/dashboard/UserDashboard/UserDashboard';
import SystemDashboard from '../pages/dashboard/SystemDashboard/SystemDashboard ';

import Login from '../pages/Login/Login.js';
import PagesError from '../pages/PagesError/PagesError.js';
import PayrollForPerson from '../pages/HR/payroll/PayrollRun/Report/PayrollForPerson.js';
import PayrollForAll from '../pages/HR/payroll/PayrollRun/Report/PayrollForAll.js';
import ConfirmPassword from '../pages/Login/ConfirmPassword';
//________________________________________________________________
import payrollRoutes from './Routes/PayrollRoutes';
import raqeebRoutes from './Routes/RaqeebRoutes';
import hrRoutes from './Routes/HRRoutes';
import organizationRoutes from './Routes/OrganizationRoutes';
import ameRoutes from './Routes/AmeRoutes';
import fleetRoutes from './Routes/FleetRoutes';
import notificationsRoutes from './Routes/NotificationsRoutes';
import reportsRoutes from './Routes/ReportsRoutes';
import suRoutes from './Routes/SuRoutes';
import financeRoutes from './Routes/FinanceRoutes';
import salesRoutes from './Routes/SalesRoutes';
import purchasesRoutes from './Routes/purchasesRoutes';
import inventoryRoutes from './Routes/InventoryRoutes';
import posRoutes from './Routes/PosRoutes';
import ntxRoutes from './Routes/NtxRoutes';
import securityRoutes from './Routes/SecurityRoutes';
import apRoutes from './Routes/ApRoutes';
import arRoutes from './Routes/ArRoutes';

const AppRoute = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <PagesError />,

    children: [
      { path: '', element: <Navigate to="/login" /> },
      { path: 'UserDashboard', element: <UserDashboard /> },
      {
        path: 'SystemDashboard',
        element: <SystemDashboard />,
      },

      ...hrRoutes,
      ...organizationRoutes,
      ...payrollRoutes,
      ...raqeebRoutes,
      ...ameRoutes,
      ...fleetRoutes,
      ...notificationsRoutes,
      ...reportsRoutes,
      ...suRoutes,
      ...financeRoutes,
      ...apRoutes,
      ...arRoutes,
      ...salesRoutes,
      ...purchasesRoutes,
      ...inventoryRoutes,
      ...posRoutes,
      ...ntxRoutes,
      ...securityRoutes,
      // إضافة المسار الشامل (404) كأخر مسار في الأطفال
      { path: '*', element: <PagesError /> },
    ],
  },

  // #region outSide <Outlit> ـــ
  {
    path: '/Report/:AssignmentActionId/PayrollForPerson',
    element: <PayrollForPerson />,
  },
  {
    path: '/Report/:PayrollActionId/PayrollForAll',
    element: <PayrollForAll />,
  },

  {
    path: 'login/*',
    children: [
      {
        path: '',
        element: <Login />,
      },
      { path: 'ConfirmPassword', element: <ConfirmPassword /> },
    ],
  },
  // إضافة المسار الشامل (404) كأخر مسار في الأطفال
  { path: '*', element: <PagesError /> },
  //#endregion
]);

export default AppRoute;

// ما هو المسار الشامل (Wildcard Route *):
// المسار الشامل (*) هو مسار يتم تعريفه في التطبيق ليكون "الملاذ الأخير"، بمعنى أنه
//  سيتطابق مع أي مسار لا يوجد له تعريف في قائمة
//  المسارات الموجودة. إذا حاول المستخدم
// الوصول إلى مسار غير موجود، سيتم توجيهه إلى صفحة الخطأ.
