import React, { useEffect, useState } from "react";
import { Modal } from "bootstrap";
// import CustomAlert from "../../../../components/Alert/CustomAlert";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GetJournalsUnPostedList } from "../../../../store/Finance/Journals/postingJournalsSlice";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Shared/Pagination/Pagination";
import { JournalsUnPostModels } from "../../../../store/Finance/Journals/JournalsModels";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import ViewSingleJournals from "./ViewSingleJournals";

const UnPostedJournalsList = () => {
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [SingleJournals, setSingelJournals] = useState<JournalsUnPostModels>(
    {} as JournalsUnPostModels
  );
  const [MultiJournals, setMultiJournals] = useState<JournalsUnPostModels[]>(
    []
  );
  const { JournalsUnPostedList } = useAppSelector(
    (state) => state.PostingJournals
  );

  const filteredSearch = JournalsUnPostedList?.slice()?.filter((item) => {
    const itemDate = new Date(item.glDate);
    const searchDate = new Date(date);
    return (
      itemDate < searchDate &&
      (item.name.includes(search) || item.description.includes(search))
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setDate(formattedDate);
  }, []);

  useEffect(() => {
    dispatch(GetJournalsUnPostedList());
  }, []);

  const handleOpenAddPayment = () => {
    const modal: HTMLElement | null = document.getElementById(
      "modalِInstallmentPayment"
    );
    // Open the modal using Bootstrap's modal method
    if (modal) {
      const bootstrapModal = new Modal(modal);
      bootstrapModal.show();
    }
  };

  const handleCheckboxChange = (row: JournalsUnPostModels) => {
    setMultiJournals((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.some((item) => item.id === row.id);
      if (isSelected) {
        return prevSelectedRows?.filter((item) => item.id !== row.id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const handleOpenViewSingleJournals = async (item: JournalsUnPostModels) => {
    const modal: HTMLElement | null =
      document.getElementById("modalSingelPost");
    await setSingelJournals({} as JournalsUnPostModels); 
    await setSingelJournals(item);
    if (modal) {
      const bootstrapModal = new Modal(modal);
      bootstrapModal.show();
    }
  };
  //_____________________
  var BcrumbList = [
    {
      name: t("Home"),
      link: "/",
    },
    {
      name: "ترحيــل القيود",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={"ترحيــل القيود"} />

      <Card>
        <CardBody>
          <div className="row">
            <div className="col-10 d-flex justify-content-start">


              <div className="input-group mb-2">
                <div className="flex-fill position-relative">
                  <div className="row">
                    <div className="col-xl-4 mx-xl-auto">
                      <div className="form-group mb-2">
                        {/* <label className="form-label"> قبل تاريخ </label> */}
                        <Form.Control
                          className="form-control"
                          type="date"
                          value={date}
                          onChange={(e) => {
                            const selectedDate = e.target.value;
                            // Prevent clearing the date
                            if (selectedDate !== "") {
                              setDate(selectedDate);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 mx-xl-auto">
                      <div className="input-group mb-2">
                        <div className="flex-fill position-relative">
                          <div className="input-group">
                            <div
                              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                              style={{ zIndex: 1020, right: "10px" }}
                            >
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                            <input
                              type="text" 
autoComplete='off'
                              className="form-control ps-35px"
                              onChange={(event) =>
                                setSearch(event.target.value)
                              }
                              placeholder={t("Search") + " ..."}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 mx-xl-auto">
                      <Link
                        to=""
                        className={`btn me-1 btn-outline-theme ${
                          MultiJournals?.length === 0 ? "disabled" : ""
                        }`}
                        onClick={() => handleOpenAddPayment()}
                      >
                        <i className="far fa-lg fa-fw me-2 fa-money-bill-alt"></i>
                        ترحيل
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead className="table-dark">
                <tr>
                  <th className="pt-2 pb-2"></th>
                  {/* <th className="pt-0 pb-2">{t("JurmalName")}</th> */}
                  <th className="pt-2 pb-2">{t("اسم القيـد")}</th>
                  <th className="pt-2 pb-2">{t("العملة")}</th>
                  <th className="pt-2 pb-2">{t("Debt")}</th>
                  <th className="pt-2 pb-2">{t("Credit")}</th>
                  <th className="pt-2 pb-2">{t("الحالة")}</th>
                  <th className="pt-2 pb-2"></th>
                </tr>
              </thead>
              {/* onClick={() => handleCheckboxChange(item)} */}
              <tbody>
                {currentData?.map((item) => (
                  <tr key={item.id}>
                    <td className="w-10px align-middle">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`checkbox-${item.id}`}
                          checked={MultiJournals?.some(
                            (row) => row.id === item.id
                          )}
                          //   onChange={(e) => e.stopPropagation()}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      </div>
                    </td>

                    <td className="align-middle"> {item.name} </td>
                    <td className="align-middle"> {item.currencyCode} </td>
                    <td className="align-middle"> {item.accountedDr} </td>
                    <td className="align-middle"> {item.accountedCr} </td>
                    <td className="align-middle"> {item.status} </td>
                    <td className="align-middle">
                      <Link
                        to=""
                        className="btn me-1 btn-theme"
                        // data-bs-toggle="modal"
                        // data-bs-target="#modalSingelPost"
                        onClick={() => handleOpenViewSingleJournals(item)}
                      >
                        ترحيل
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </CardBody>
      </Card>

      <ModalComponent id="modalSingelPost" title="ترحيــل قيد">
        {Object.keys(SingleJournals)?.length > 0 && (
          <ViewSingleJournals Journal={SingleJournals} />
        )}
      </ModalComponent>
    </div>
  );
};

export default UnPostedJournalsList;
