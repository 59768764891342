import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getApPaymentsList } from '../../../store/Finance/AP/ApPayments/ApPaymentsSlice';

const useGetPaymentsList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ApPaymentsList?.length === 0) {
      dispatch(getApPaymentsList());
    }
  }, []);
  const { ApPaymentsList, loading, error } = useAppSelector((state) => state.ApPayments);

  return { ApPaymentsList, loading, error };
};

export default useGetPaymentsList;
