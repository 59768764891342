import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import AddVAT from './AddVAT';
import Update from './Update';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { GetTaxManagersList } from '../../../../store/Finance/TaxManagers/TaxManagerSlice';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import { TaxManagerModels } from '../../../../store/Finance/TaxManagers/TaxManagerModels';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [vatDetiles, setVatDetiles] = useState<TaxManagerModels>();

  const { TaxManagerList } = useAppSelector((a) => a.TaxManager);
  useEffect(() => {
    dispatch(GetTaxManagersList());
  }, []);

  const filteredSearch = TaxManagerList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item?.startDateActive?.toLowerCase().includes(searchLowerCase);
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'ضريبة الفيمة المضافة',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="ضريبة الفيمة المضافة" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddVat"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1 p-1"></i>
            {t('إضافة')}
          </Link>
        </div>
      </div>

      <Card>
        <CardBody>
          <div className="tab-content p-1">
            <div className="row mb-2">
              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">
                    {t('Search')}
                    {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                  </span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('نوع الصريبة ')}</th>
                    <th scope="col">{t('نسبة الضريبة')}</th>
                    <th scope="col">{t('من تاريخ')}</th>
                    <th scope="col">{t('إلى تاريخ')}</th>
                    <th scope="col">{t('الأستاذ العام')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle"> {item.taxType}</td>
                      <td className="align-middle fs-4 text-theme fw-bold"> {item.taxRate}</td>
                      <td className="align-middle">
                        {' '}
                        {item.startDateActive?.toString()?.split('T')[0]}{' '}
                      </td>
                      <td className="align-middle">
                        {' '}
                        {item.endDateActive?.toString()?.split('T')[0]}{' '}
                      </td>
                      <td className="align-middle">
                        {' '}
                        {i18n.language === 'en' ? item.ledgerName : item.ledgerName2}
                      </td>
                      <td className="align-middle text-end">
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#modalEditVat"
                          onClick={() => {
                            setVatDetiles(item);
                            setShow(true);
                          }}>
                          <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                          {t('Button.Edit')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </CardBody>
      </Card>

      <MedModalComponent title="اضافة" id="modalAddVat">
        {Show && <AddVAT />}
      </MedModalComponent>
      <MedModalComponent title="تعديـل" id="modalEditVat">
        {Show && <Update data={vatDetiles} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
