import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: any;
}

const HeaderInvoice = ({ data }: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col-4">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '140px' }}>
              رقم الفاتورة
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              value={data?.invoiceNum}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '140px' }}>
              تاريخ الفاتورة
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              value={data?.invoiceDate?.toString()}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '140px' }}>
              تاريخ الإستحقاق
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              value={''}
              disabled
            />
          </div>
        </div>

        <div className="col-4">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center"
              style={{ width: '140px' }}>
              العملة
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              value={data?.currencyCode}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '140px' }}>
              نوع الفاتورة
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              defaultValue={data?.invoiceType}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '140px' }}>
              peopleId
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              defaultValue={data?.peopleId}
              disabled
            />
          </div>
        </div>

        <div className="col-4">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '140px' }}>
              الفرع
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              defaultValue={i18n.language === 'en' ? data?.organizationId : data?.organizationId}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '140px' }}>
              paymentTermsId
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              defaultValue={i18n.language === 'en' ? data?.paymentTermsId : data?.paymentTermsId}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '140px' }}>
              paymentMethodId
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              value={data?.paymentMethodId}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '140px' }}>
              invoiceAmount
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              value={data?.invoiceAmount}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '140px' }}>
              vatAmount
            </span>
            <input
              type="text"
              autoComplete="off"
              className="form-control form-control-sm"
              value={data?.vatAmount}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderInvoice;
