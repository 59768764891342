import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetMyRequest } from '../../../../store/Ame/ameRequestSlice.js';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import Loading from '../../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../../components/Modal/NeatixModal.jsx';
import OnlyViewRequests from '../../../Ame/ViewRequests/OnlyViewRequests.js';
import AmeForm from './AmeForm/AmeForm.js';
import { Link } from 'react-router-dom';
import ModalComponent from '../../../../components/Modal/ModalComponent.jsx';
import { Card, CardBody } from '../../../../components/card/card.jsx';

const MyRequests = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [transactionHistoryId, setTransactionHistoryId] = useState('');

  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(7);
  const dispatch = useDispatch();
  const { MyRequestList, loading, error } = useSelector((state) => state.AmeRequest);
  const [search, setSearch] = useState('');

  const filteredSearch = MyRequestList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.requestName?.toLowerCase().includes(searchLowerCase) ||
      item.requestName2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  useEffect(() => {
    dispatch(GetMyRequest());
  }, [dispatch]);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <div className="row mb-2">
          <div className="col-lg-6">
            <div className="input-group flex-nowrap input-group-sm">
              <span className="input-group-text fw-bold">{t('Search')}</span>
              <div className="input-group">
                <div className="flex-fill position-relative">
                  <div className="input-group border-0 ">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                      style={{ zIndex: 1020, right: '10px' }}>
                      <i className="fa fa-search opacity-5 text-theme"></i>
                    </div>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control  form-control-sm  ps-35px"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <button
              className="btn me-1 btn-sm btn-outline-theme"
              // data-bs-toggle="modal"
              // data-bs-target="#modalAmeForm"
              onClick={() => setShowModal2(true)}>
              <i className="fas fa-lg fa-fw fa-plus-circle me-2"></i>
              {t('طلب جديـد')}
            </button>
          </div>
        </div>

        <div className="table-responsive  rounded-2">
          <table className="table  text-nowrap table-hover">
            <thead className="bg-theme bg-opacity-25  p-1 mb-1">
              <tr>
                <th>{t('NumberOfRequest')}</th>
                <th>{t('TypeOfRequest')}</th>
                <th>{t('DateOfRequest')}</th>
                <th>{t('Status')}</th>
                <th>{t('')}</th>
              </tr>
            </thead>
            <tbody>
              {currentData?.map((item, index) => (
                <tr key={item.transectionHistoryId}>
                  <td className="align-middle">{++index}</td>
                  <td className="align-middle">
                    {i18n.language === 'ar' ? item.requestName2 : item.requestName}{' '}
                  </td>

                  <td className="align-middle">{item.dateCreated}</td>

                  <td className="align-middle">
                    {item.status === 'APPROVED' ? (
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="fa fa-check-circle fs-18px fa-fw me-5px"></i>
                        {t('APPROVED')}
                      </span>
                    ) : item.status === 'IN PROGRESS' ? (
                      <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="fa fa-spinner fs-18px fa-fw me-5px"></i>
                        {t('INPROGRESS')}
                      </span>
                    ) : item.status === 'REJECTED' ? (
                      <span className="badge border border-danger  text-danger  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        <i className="fa fa-times-circle fs-18px fa-fw me-5px "></i>
                        {t('REJECTED')}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="align-middle text-end">
                    {/* <button
                      className="btn me-1 btn-sm btn-outline-theme"
                      onClick={() => {
                        setTransactionHistoryId(item.transectionHistoryId);
                        setShowModal1(true);
                      }}>
                      <i className="fas fa-lg fa-fw fa-info-circle me-2"></i>
                      {t('ViewRequest')}
                    </button> */}
                    <Link
                      to=""
                      onClick={() => {
                        setTransactionHistoryId(item.transectionHistoryId);
                        setShowModal1(true);
                      }}>
                      <i
                        className="fas fa-lg fa-fw fa-info-circle cursor-pointer"
                        style={{ color: 'var(--theme-color)' }}></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          filteredData={filteredSearch}
          dataPerPage={dataPerPage}
          handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
          currentPage={currentPage}
        />
      </Loading>

      <NeatixModal
        show={showModal1}
        setShow={setShowModal1}
        size="xl"
        bodyClassName="p-0"
        showHeader={true}>
        {transactionHistoryId && <OnlyViewRequests TransactionHistoryId={transactionHistoryId} />}
      </NeatixModal>

      <NeatixModal
        show={showModal2}
        setShow={setShowModal2}
        size="xl"
        title="طلبات الخدمة الذاتية"
        bodyClassName="p-0"
        showHeader={true}>
        {/* <Card> */}
        <CardBody className="p-3">
          <AmeForm />
        </CardBody>
        {/* </Card> */}
      </NeatixModal>

      {/* <ModalComponent id="modalAmeForm" title="طلبــات الخدمة الذاتية">
        <AmeForm />
      </ModalComponent> */}
    </>
  );
};

export default MyRequests;
