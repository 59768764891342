import React, { useState } from 'react';
// import { unwrapResult } from '@reduxjs/toolkit';
import useGetPosList from '../../../hooks/POS/useGetPosList';
// import { Modal } from 'bootstrap';
import { useTranslation } from 'react-i18next';
// import MedModalComponent from '../../../components/Modal/MedModalComponent';
import OpenSession from './OpenSession';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

import { useAppSelector } from '../../../store/hooks';
import { getPersonId } from '../../../store/Login/loginSlice';

import { Col, Row } from 'react-bootstrap';
import NeatixModal from '../../../components/Modal/NeatixModal';
import Loading from '../../../components/Shared/Loader/Loading';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { PosModel } from '../../../store/Sales/Pos/PosModels';
import { Card } from '../../../components/card/card';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import styles from './styles.module.css';
const { cardHoverEffect, iconHover } = styles;

const PosCard = () => {
  const [show, setShow] = useState(false);

  const personId = useAppSelector(getPersonId);

  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const [PosModel, setPosModel] = useState<PosModel>();

  const { PosList, error, loading: posListLoading } = useGetPosList();

  const handleButtonClick = async (item: PosModel) => {
    if (item.sessionOpen) {
      item.posType === 'shop'
        ? navigate(`/Pos/${item.id}/${item.sessionId}/PointOfSaleShop`)
        : navigate(`/Pos/${item.id}/${item.sessionId}/PointOfSaleRestaurant`);
    } else {
      setPosModel(item);
      setShow(true);
    }
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'نقاط البيع',
      link: null,
    },
  ];

  //________________________________________________________________

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('نقاط البيع')} />
      <hr />

      <Loading loading={posListLoading} error={error}>
        {/* <Loading loading={posListLoading || Object.keys(sessionData)?.length === 0} error={error}> */}
        <div className="p-2">
          <Row className="gx-4 gy-4">
            {PosList &&
              PosList?.map((item, index) => (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                  <Card
                    className={
                      'h-100 d-flex flex-column justify-content-center align-items-center text-center p-3  position-relative ' +
                      cardHoverEffect
                    }>
                    {/* الشارة في الركن العلوي الأيمن */}
                    {item.isActive ? (
                      <span className="badge bg-primary position-absolute top-0 end-0 m-2 z-0">
                        {t('isActive')}
                      </span>
                    ) : (
                      <span className="badge bg-danger position-absolute top-0 end-0 m-2 z-0">
                        {t('InActive')}
                      </span>
                    )}
                    <Icon
                      className={'iconify fs-30px text-theme ' + iconHover}
                      icon="mdi:network-point-of-sale"
                      width="70"
                      height="70"
                      // style={{ color: '#e91e63' }}
                      aria-label={item.name + ' icon'}
                    />

                    <h6 className="mt-3 small lh-lg">{item.name}</h6>
                    {/* قسم الأزرار في أسفل البطاقة */}
                    <div className="mt-auto d-flex w-100 pt-3 gap-2">
                      <button
                        type="button"
                        className="btn btn-theme rounded-1 flex-grow-1"
                        onClick={() => handleButtonClick(item)}>
                        {item.sessionOpen ? 'متابعة البيـع' : 'فتح الكاشير'}
                      </button>

                      <DropdownButton title="خيارات أخرى" variant="secondary">
                        <Dropdown.Item href="">
                          <Icon icon="mdi:logout" className="me-2" /> {/* إضافة أيقونة قبل النص */}
                          إغلاق الجلســة
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </Card>
                </div>
              ))}
          </Row>
        </div>
      </Loading>

      <NeatixModal
        setShow={setShow}
        show={show}
        size={'md'}
        showHeader={true}
        bodyClassName="p-2"
        title="الرصيد الإفتتاحي">
        {PosModel && <OpenSession PosModel={PosModel} />}
      </NeatixModal>
    </>
  );
};

export default PosCard;
