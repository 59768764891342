import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { FinancialReportsModels, GeneralLedgerModels } from './FinancialReportsModels';

export const getIncomeStatement = createAsyncThunk(
  'FinancialReports/getIncomeStatement',
  async (
    {
      startDate,
      endDate,
      accountlevel,
    }: { startDate?: string; endDate?: string; accountlevel?: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await ntxAPI.get('Reports/GetIncomeStatement', {
        params: {
          ...(startDate && { StartDate: startDate }),
          ...(endDate && { EndDate: endDate }),
          ...(accountlevel && { Accountlevel: accountlevel }),
        },
      });
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getBalanceSheet = createAsyncThunk(
  'FinancialReports/getBalanceSheet',
  async ({ startDate, endDate }: { startDate: string; endDate: string }, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Reports/GetBalanceSheet', {
        params: {
          StartDate: startDate,
          EndDate: endDate,
        },
      });
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getGeneralLedger = createAsyncThunk(
  'FinancialReports/getGeneralLedger',
  async ({ startDate, endDate }: { startDate: string; endDate: string }, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Reports/GetGeneralLedger', {
        params: {
          StartDate: startDate,
          EndDate: endDate,
        },
      });
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  IncomeStatement: FinancialReportsModels;
  BalanceSheet: FinancialReportsModels;
  GeneralLedger: GeneralLedgerModels[];
  loading: boolean;
  error: any | null;
} = {
  IncomeStatement: {} as FinancialReportsModels,
  BalanceSheet: {} as FinancialReportsModels,
  GeneralLedger: [],
  loading: false,
  error: null,
};

const FinancialReportsSlice = createSlice({
  name: 'FinancialReports',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getIncomeStatement.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getIncomeStatement.fulfilled,
        (state, action: PayloadAction<FinancialReportsModels>) => {
          state.loading = false;
          state.IncomeStatement = action.payload;
        }
      )
      .addCase(getIncomeStatement.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //~~~~~~~~~~~~

      .addCase(getBalanceSheet.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getBalanceSheet.fulfilled,
        (state, action: PayloadAction<FinancialReportsModels>) => {
          state.loading = false;
          state.BalanceSheet = action.payload;
        }
      )
      .addCase(getBalanceSheet.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //~~~~~~~~~~~~

      .addCase(getGeneralLedger.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getGeneralLedger.fulfilled,
        (state, action: PayloadAction<GeneralLedgerModels[]>) => {
          state.loading = false;
          state.GeneralLedger = action.payload;
        }
      )
      .addCase(getGeneralLedger.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
    //~~~~~~~~~~~~
  },
});

export default FinancialReportsSlice.reducer;
