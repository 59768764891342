import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PosModel, PosDataBySessionModel } from './PosModels';

export const addPos = createAsyncThunk('Pos/AddPos', async (item: any, { rejectWithValue }) => {
  try {
    const { data } = await ntxAPI.post('Pos/AddPos', item);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updatePos = createAsyncThunk(
  'Pos/AddPos',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Pos/UpdatePos', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPosList = createAsyncThunk('Pos/GetPosList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await ntxAPI.get('Pos/GetPosList');
    if (data?.succeeded) {
      //===
      return data.data;
    } else {
      return rejectWithValue(data?.message || 'هناك خطأ');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const GetPosById = createAsyncThunk(
  'Pos/GetPosById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Pos/GetPosById?Id=${id}`);
      if (data?.succeeded) {
        //===
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPosDataBySessionId = createAsyncThunk(
  'Pos/getPosDataBySessionId',
  async (sessionId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Pos/GetPosDataBySessionId?sessionId=${sessionId}`);
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



const initialState: {
  PosList: PosModel[];
  PosById: PosModel;
  PosDataBySession: PosDataBySessionModel;
  loading: boolean;
  error: any | null;
} = {
  PosList: [],
  PosById: {} as PosModel,
  PosDataBySession: {} as PosDataBySessionModel,
  loading: false,
  error: null,
};

const PosSlice = createSlice({
  name: 'Pos',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPosList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPosList.fulfilled, (state, action: PayloadAction<PosModel[]>) => {
        state.loading = false;
        state.PosList = action.payload;
      })
      .addCase(getPosList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //

      .addCase(GetPosById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetPosById.fulfilled, (state, action: PayloadAction<PosModel>) => {
        state.loading = false;
        state.PosById = action.payload;
      })
      .addCase(GetPosById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //

      .addCase(getPosDataBySessionId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPosDataBySessionId.fulfilled,
        (state, action: PayloadAction<PosDataBySessionModel>) => {
          state.loading = false;
          state.PosDataBySession = action.payload;
        }
      )
      .addCase(getPosDataBySessionId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
    //
  },
});

export default PosSlice.reducer;
