import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { GlBudgetModels , BudgetAccountsModels } from './GlBudgetModels';

export const addGlBudgets = createAsyncThunk(
  'GlBudget/addGlBudgets',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Budget/AddGlBudgets', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateGlBudgets = createAsyncThunk(
  'GlBudget/updateGlBudgets',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Budget/UpdateGlBudgets', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getGlBudgetsById = createAsyncThunk(
  'GlBudget/getGlBudgetsById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Budget/GetGlBudgetsById?id=${id}`);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getBudgetAccountsList = createAsyncThunk(
  'GlBudget/getBudgetAccountsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Budget/GetBudgetAccountsList');
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getGlBudgetsList = createAsyncThunk(
  'GlBudget/getGlBudgetsList',
  async (params: { LedgerId: string; PeriodId?: string }, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Budget/GetGlBudgetsList`, {
        params,
      });
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  GlBudgetList: GlBudgetModels[];
  GlBudgetById: GlBudgetModels;
  BudgetAccountsList: BudgetAccountsModels[];
  loading: boolean;
  error: any | null;
} = {
  GlBudgetList: [],
  GlBudgetById: {} as GlBudgetModels,
  BudgetAccountsList: [],
  loading: false,
  error: null,
};

const GlBudgetSlice = createSlice({
  name: 'GlBudget',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getGlBudgetsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGlBudgetsList.fulfilled, (state, action: PayloadAction<GlBudgetModels[]>) => {
        state.loading = false;
        state.GlBudgetList = action.payload;
      })
      .addCase(getGlBudgetsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //~~~~~~~~~~~~~~~~

      .addCase(getGlBudgetsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGlBudgetsById.fulfilled, (state, action: PayloadAction<GlBudgetModels>) => {
        state.loading = false;
        state.GlBudgetById = action.payload;
      })
      .addCase(getGlBudgetsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //~~~~~~~~~~~~~~~~

      .addCase(getBudgetAccountsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBudgetAccountsList.fulfilled, (state, action: PayloadAction<BudgetAccountsModels[]>) => {
        state.loading = false;
        state.BudgetAccountsList = action.payload;
      })
      .addCase(getBudgetAccountsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default GlBudgetSlice.reducer;
