import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useParams } from 'react-router-dom';
import { getEnteriesListByInventoryCountId } from '../../../../store/INV/InventoryCount/inventoryCountSlice';
import { useTranslation } from 'react-i18next';

const EnteryList = () => {
  const { invCountId } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { EnteriesList: data } = useAppSelector((a) => a.InventoryCount);

  useEffect(() => {
    if (invCountId) {
      dispatch(getEnteriesListByInventoryCountId(invCountId));
    }
  }, []);
  return (
    <>
      <div className={`table-responsive `}>
        <table className='table table-bordered  table-hover mb-0 text-nowrap'>
          <thead className="bg-theme bg-opacity-30">
            <tr>
              <th>الموظف</th>
              <th>تاريخ الإدخال</th>
              <th>وصف الصنف</th>
              <th>كميةالجرد</th>
              <th>وحدة القياس</th>
            </tr>
          </thead>
          <tbody>
            {data.map((entry) => (
              <React.Fragment key={entry.id}>
                {entry.lines.map((line) => (
                  <tr key={line.id}>
                    <td>{i18n.language === 'en' ? entry.personName : entry.personName2}</td>
                    <td>{entry.entryDate.toString()?.split('T')[0]}</td>
                    <td>{line.description}</td>
                    <td>{line.countQuantity}</td>
                    <td>
                      {line.unitsOfMeasureName} / {line.unitsOfMeasureName2}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EnteryList;
