import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { LotItemModel } from './MasterItemsModels';

export const addLotDetails = createAsyncThunk(
  'LotItem/addLotDetails',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddLotDetails', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateLotDetails = createAsyncThunk(
  'LotItem/updateLotDetails',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/UpdateLotDetails', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getLotDetailsListByItemId = createAsyncThunk(
  'LotItem/getLotDetailsListByItemId',
  async (ItemId: string, { rejectWithValue }) => {
    try {
      //
      const { data } = await ntxAPI.get(`Inv/GetLotDetailsListByItemId?ItemId=${ItemId}`);
      if (data.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  LotDetailsListByItem: LotItemModel[];
  loading: boolean;
  error: any | null;
} = {
  LotDetailsListByItem: [],
  loading: false,
  error: null,
};

const LotItemSlice = createSlice({
  name: 'LotItem',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      // .addCase(addLotDetails.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(addLotDetails.fulfilled, (state, action: PayloadAction<any>) => {
      //   state.loading = false;
      //   if (action?.payload?.succeeded === true) {
      //     state.LotDetailsListByItem.push(action.payload.data);
      //   }
      // })
      // .addCase(addLotDetails.rejected, (state, action: PayloadAction<any>) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      //_______________________

      .addCase(getLotDetailsListByItemId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getLotDetailsListByItemId.fulfilled,
        (state, action: PayloadAction<LotItemModel[]>) => {
          state.loading = false;
          state.LotDetailsListByItem = action.payload;
        }
      )
      .addCase(getLotDetailsListByItemId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default LotItemSlice.reducer;
