import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { PeopleLocationsModels } from './PeoplesModels';

export const AddPeopleLocations = createAsyncThunk(
  'PeopleLocations/AddPeopleLocations',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Peoples/AddPeopleLocations', item);
      if (data?.succeeded) {
        return data;
      } else {
        // return rejectWithValue(error.message);
        return {} as PeopleLocationsModels;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatePeopleLocations = createAsyncThunk(
  'PeopleLocations/UpdatePeopleLocations',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Peoples/UpdatePeopleLocations', item);
      if (data?.succeeded) {
        return data;
      } else {
        return {} as PeopleLocationsModels;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetPeopleLocationsByPeopleId = createAsyncThunk(
  'PeopleLocations/GetPeopleLocationsByPeopleId',
  async (peopleId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `Peoples/GetPeopleLocationsByPeopleId?PeopleId=${peopleId}`
      );
      if (data?.succeeded) {
        return data.data;
      } else if (!data?.succeeded && data.httpStatusCode === 404) {
        return [];
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetPeopleLocationsById = createAsyncThunk(
  'PeopleLocations/GetPeopleLocationsById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Peoples/GetPeopleLocationsById?id=${Id}`);
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  // PeopleLocationsList: PeopleLocationsModels[];
  PeopleLocationsListByPeopleId: PeopleLocationsModels[];
  PeopleLocationsById: PeopleLocationsModels;
  loading: boolean;
  error: any | null;
} = {
  // PeopleLocationsList: [],
  PeopleLocationsListByPeopleId: [],
  PeopleLocationsById: {} as PeopleLocationsModels,
  loading: false,
  error: null,
};

const PeopleLocationsSlice = createSlice({
  name: 'PeopleLocations',
  initialState,
  reducers: {
    clearPeopleLocationsByPeopleId: (state) => {
      state.PeopleLocationsListByPeopleId = [];
    },
  },
  extraReducers(builder) {
    builder

      // .addCase(AddPeopleLocations.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(AddPeopleLocations.fulfilled, (state, action: PayloadAction<any>) => {
      //   state.loading = false;
      //   state.PeopleLocationsListByPeopleId.push(action.payload.data);
      // })
      // .addCase(AddPeopleLocations.rejected, (state, action: PayloadAction<any>) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })

      .addCase(GetPeopleLocationsByPeopleId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetPeopleLocationsByPeopleId.fulfilled,
        (state, action: PayloadAction<PeopleLocationsModels[]>) => {
          state.loading = false;
          state.PeopleLocationsListByPeopleId = action.payload;
        }
      )
      .addCase(GetPeopleLocationsByPeopleId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetPeopleLocationsById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        GetPeopleLocationsById.fulfilled,
        (state, action: PayloadAction<PeopleLocationsModels>) => {
          state.loading = false;
          state.error = null;
          state.PeopleLocationsById = action.payload;
        }
      )
      .addCase(GetPeopleLocationsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearPeopleLocationsByPeopleId } = PeopleLocationsSlice.actions;
export default PeopleLocationsSlice.reducer;
