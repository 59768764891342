import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const userMenu = [
  {
    System: 'USER',
    isActive: true,
    menu: [
      { is_header: true, title: 'USER', isActive: true },
      {
        path: 'UserDashboard',
        icon: 'fas fa-home',
        title: 'HomePage',
      },
    ],
  },
];

export default userMenu;
