import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { SubInventoryModels } from './SubInventoryModels';

export const AddSubinventory = createAsyncThunk(
  'SubInventory/AddSubinventory',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddSubinventory', item);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatSubinventory = createAsyncThunk(
  'SubInventory/UpdatSubinventory',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/UpdatSubinventory', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetSubinventoryByParentId = createAsyncThunk(
  'SubInventory/GetSubinventoryByParentId',
  async (ParentId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetSubinventoryByParentId?ParentId=${ParentId}`);
      if (data.succeeded === true) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetSubinventoryList = createAsyncThunk(
  'SubInventory/GetSubinventoryList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetSubinventoryList`);
      if (data.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  SubInventoryList: SubInventoryModels[];
  SubInventoryListByParentId: SubInventoryModels[];
  loading: boolean;
  error: any | null;
} = {
  SubInventoryList: [],
  SubInventoryListByParentId: [],
  loading: false,
  error: null,
};

const SubInventorySlice = createSlice({
  name: 'SubInventory',
  initialState,
  reducers: {
    cleanUpSubInventoryListByParentId: (state) => {
      state.SubInventoryListByParentId = [];
    },
  },
  extraReducers(builder) {
    builder

      .addCase(AddSubinventory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddSubinventory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action?.payload?.succeeded === true) {
          state.SubInventoryListByParentId.push(action.payload.data);
        }
      })
      .addCase(AddSubinventory.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



      .addCase(GetSubinventoryList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetSubinventoryList.fulfilled,
        (state, action: PayloadAction<SubInventoryModels[]>) => {
          state.loading = false;
          state.SubInventoryList = action.payload;
        }
      )
      .addCase(GetSubinventoryList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



      .addCase(GetSubinventoryByParentId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetSubinventoryByParentId.fulfilled,
        (state, action: PayloadAction<SubInventoryModels[]>) => {
          state.loading = false;
          state.SubInventoryListByParentId = action.payload;
        }
      )
      .addCase(GetSubinventoryByParentId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanUpSubInventoryListByParentId } = SubInventorySlice.actions;
export default SubInventorySlice.reducer;
