import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ApExpenseCategoryItemsModels } from './ExpenseCategoryItemsModels';
import { APIResponse } from '../../../Shared/shared';

export const addApExpenseCategoryItems = createAsyncThunk(
  'ApExpenseCategoryItems/addApExpenseCategoryItems',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>(
        'Expense/AddApExpenseCategoryItems',
        item
      );
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateApExpenseCategoryItems = createAsyncThunk(
  'ApExpenseCategoryItems/updateApExpenseCategoryItems',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>(
        'Expense/UpdateApExpenseCategoryItems',
        item
      );
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetApExpenseCategoryItemsByExpenseCategoryId = createAsyncThunk(
  'ApExpenseCategoryItems/GetApExpenseCategoryItemsByExpenseCategoryId',
  async (ExpenseCategoryId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApExpenseCategoryItemsModels[]>>(
        `Expense/GetApExpenseCategoryItemsByExpenseCategoryId?ExpenseCategoryId=${ExpenseCategoryId}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ApExpenseCategoryItemsList: ApExpenseCategoryItemsModels[];
  loading: boolean;
  error: any | null;
} = {
  ApExpenseCategoryItemsList: [],
  loading: false,
  error: null,
};

const ApExpenseCategoryItemsSlice = createSlice({
  name: 'ApExpenseCategoryItems',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(GetApExpenseCategoryItemsByExpenseCategoryId.pending, (state, action) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(
        GetApExpenseCategoryItemsByExpenseCategoryId.fulfilled,
        (state, action: PayloadAction<ApExpenseCategoryItemsModels[]>) => {
          state.loading = false;
          state.ApExpenseCategoryItemsList = action.payload;
        }
      )
      .addCase(
        GetApExpenseCategoryItemsByExpenseCategoryId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default ApExpenseCategoryItemsSlice.reducer;
