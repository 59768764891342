import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getApBankAccountsList } from '../../../store/Finance/AP/ApBankAccounts/ApBankAccountsSlice';
import { useTranslation } from 'react-i18next';

const useGetCashBoxesList = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (ApBankAccountsList?.length === 0) {
      dispatch(getApBankAccountsList());
    }
  }, []);
  const { ApBankAccountsList, loading, error } = useAppSelector((state) => state.ApBankAccounts);

  const CashBoxesList = ApBankAccountsList?.filter(
    (f) => f.ntxBankId.toUpperCase() === 'F700CF22-AF84-4CA2-AC8C-C3BEF5B88325'
  );

  const OptionsCashBoxes =
  CashBoxesList &&
  CashBoxesList?.map((item) => ({
      value: item.id,
      label: i18n.language === 'ar' ? item.name2 : item.name,
    }));

  return { CashBoxesList,   OptionsCashBoxes, loading, error };
};

export default useGetCashBoxesList;
