import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from '../../../components/card/card';
import Loading from '../../../components/Shared/Loader/Loading';
import { ModuleCard } from './ModuleCard';
import useLookupModule from '../../../hooks/Lookups/use-Lookup-Module';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useGetDynamicForm from '../../../hooks/Forms/useGetDynamicForm';
import useGetPrintTemplateList from '../../../hooks/NTX/useGetPrintTemplateList';
import useGetReportsList from '../../../hooks/NTX/useGetReportsList';
import useGetUsers from '../../../hooks/NTX/useGetUsers';
import useGetNotificationList from '../../../hooks/NTX/useGetNotificationList';
import useGetDashboardsList from '../../../hooks/NTX/useGetDashboardsList';
import { UsersCard } from './UsersCard';

const NtxDashboard = () => {
  const [moduleId, setModuleId] = useState('0a6c6b89-1d52-4eaa-8d2c-010c74b1f6e0'); // الموارد البشرية افتراضي

  const { i18n } = useTranslation();
  const { Module } = useLookupModule();

  const { DynamicExtraInformation, DynamicExtraRecord, DynamicFormAme } = useGetDynamicForm();

  const { PrintTemplateList } = useGetPrintTemplateList();

  const { ReportsList } = useGetReportsList();

  const { Users } = useGetUsers();

  const { NotificationsList } = useGetNotificationList();

  const { DashBoardList, loading } = useGetDashboardsList();

  const excludedIds = [
    '0a6c6b89-1d52-4eaa-8d2c-060c74b1f6e0',
    '0a6c6b89-1d52-4eaa-8d2c-000c74b1f6e0',
  ];
  const ModuleFilter = Module?.filter((f: any) => !excludedIds.includes(f.id));

  return (
    <div>
      <Loading loading={loading}>
        <Card className="mb-2">
          <CardHeader className="px-3 py-0">
            <ul className="nav nav-pills card-header-pills  d-flex m-0 p-0 w-100 ">
              {ModuleFilter?.map((m: any) => (
                <li className="nav-item flex-fill m-1 " key={m.id}>
                  <Link
                    className={` nav-link text-center ${m.id === moduleId ? 'active' : ''}`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    to="#pills-home"
                    onClick={() => setModuleId(m.id)}>
                    {i18n.language === 'en' ? m.name : m.name2}
                  </Link>
                </li>
              ))}
            </ul>
          </CardHeader>
        </Card>

        <CardBody>
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <UsersCard title="المستخدمين" icon="bi-file-earmark-plus" List={Users} />
            </div>
            <div className="col-xl-3 col-lg-6">
              <ModuleCard
                title="نماذج البيانات الإضافية"
                icon="bi-file-earmark-plus"
                List={DynamicExtraInformation?.filter((f: any) => f.moduleId === moduleId)}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <ModuleCard
                title="نماذج السجلات الإضافية"
                icon="bi-journal-plus "
                List={DynamicExtraRecord?.filter((f: any) => f.moduleId === moduleId)}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <ModuleCard
                title="نماذج الطلبات"
                icon="bi-file-earmark-text"
                List={DynamicFormAme?.filter((f: any) => f.moduleId === moduleId)}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <ModuleCard
                title="التقارير"
                icon="bi-file-earmark-bar-graph"
                List={ReportsList?.filter((f: any) => f.moduleId === moduleId)}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <ModuleCard
                title="قوالب الطباعة"
                icon="bi-printer"
                List={PrintTemplateList?.filter((f: any) => f.moduleId === moduleId)}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <ModuleCard
                title="الإشعارات"
                icon="bi-bell"
                List={NotificationsList?.filter((f: any) => f.moduleId === moduleId)}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <ModuleCard
                title="لوحات البيانات"
                icon="bi bi-pie-chart"
                List={DashBoardList?.filter((f: any) => f.moduleId === moduleId)}
              />
            </div>
          </div>
        </CardBody>
      </Loading>
    </div>
  );
};

export default NtxDashboard;

// {/* <CardHeader className="px-3 py-0">
// <ul className="nav nav-pills card-header-pills m-0 px-0 d-flex justify-content-center">
//   {ModuleFilter?.map((m: any) => (
//     <li className="nav-item m-1 " key={m.id}>
//       <Link
//         className={`nav-link ${m.id === moduleId ? 'active' : ''}`}
//         id="pills-home-tab"
//         data-bs-toggle="pill"
//         to="#pills-home"
//         onClick={() => setModuleId(m.id)}>
//         {i18n.language === 'en' ? m.name : m.name2}
//       </Link>
//     </li>
//   ))}
// </ul>
// </CardHeader> */}
