import { lazy, Suspense } from 'react';

const LoadingSpinner = lazy(() => import('./LoadingSpinner'));

const LazyLoading = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LoadingSpinner />
    </Suspense>
  );
};

export default LazyLoading;
