import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { Card, CardBody } from '../../../../components/card/card';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import AddMasterItems from './AddMasterItems';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import i18n from '../../../../i18n';
import useGetCategoriesList from '../../../../hooks/INV/useGetCategoriesList';
import Select from 'react-select';

import Loading from '../../../../components/Shared/Loader/Loading';

const Index = () => {
  const [Show, setShow] = useState(false);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();

  const [categoryId, setCategoryId] = useState<any>();

  const { CategoriesOptions, loading: loading2 } = useGetCategoriesList();

  const { MasterItemsList, loading, error } = useGetItemsList();

  const filteredSearch = MasterItemsList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    const matchesSearch =
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase) ||
      item.barcode?.toLowerCase().includes(searchLowerCase);
    const matchesSource = categoryId ? item.categoryId === categoryId : true;

    return matchesSearch && matchesSource;
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //________________________________

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الأصناف',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('الأصناف')} />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddMasterItems"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('إضــافة صنف جديـد')}
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="row mb-3">
              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('Search')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px m-0"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text  fw-bold">{t('الفئة')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className={`w-100 `}
                    isLoading={loading2}
                    isSearchable={true}
                    isClearable
                    options={CategoriesOptions}
                    value={CategoriesOptions?.find((f) => f.value === categoryId)}
                    onChange={(option) => {
                      setCategoryId(option?.value);
                      setCurrentPage(1);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover">
                <thead className="table-dark">
                  <tr>
                    <th className="align-middle">اسم الصنف</th>
                    <th className="align-middle">الباركود</th>
                    <th className="align-middle">الفئة</th>
                    <th className="align-middle"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData &&
                    currentData?.map((item) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'en' ? item.name : item.name2}
                        </td>
                        <td className="align-middle">{item.barcode}</td>
                        <td className="align-middle">
                          {i18n.language === 'en' ? item.categoryName : item.categoryName2}
                        </td>

                        <td className="align-middle text-end text-nowrap">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            onClick={() => Navigate(`${item.id}/ItemsDetails`)}>
                            <i className="far fa-lg fa-fw me-2 fa-eye"></i> {t('عرض التفاصيل')}
                          </button>
                          <button type="button" className="btn btn-outline-theme mx-1">
                            <i className="fas fa-lg fa-fw me-2 fa-camera"></i> {t('عرض صور المنتج')}
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        </Loading>
      </Card>

      <ModalComponent title={t('إضــافة صنف جديـد')} id="modalAddMasterItems">
        {Show && <AddMasterItems />}
      </ModalComponent>
    </div>
  );
};

export default Index;
