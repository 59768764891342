import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ApInvoicesModels } from './ApInvoicesModels';
import { APIResponse } from '../../../Shared/shared';

export const addApInvoices = createAsyncThunk(
  'ApInvoices/addApInvoices',
  async (item: any, { rejectWithValue }) => {
    try {
      // console.log(JSON.stringify(item));
      // debugger;

      const { data } = await ntxAPI.post<APIResponse<any>>('Ap/AddApInvoices', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateApInvoices = createAsyncThunk(
  'ApInvoices/updateApInvoices',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ap/UpdatApInvoices', item);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addApPaymentSchedules = createAsyncThunk(
  'ApInvoices/addApPaymentSchedules',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ap/AddApPaymentSchedules', item);
      return data;
      // if (data?.succeeded) {
      // } else {
      //   return rejectWithValue(data.message);
      // }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApInvoicesList = createAsyncThunk(
  'ApInvoices/getApInvoicesList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApInvoicesModels[]>>('Ap/GetApInvoicesList');
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApInvoicesById = createAsyncThunk(
  'ApInvoices/getApInvoicesById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApInvoicesModels>>(
        `Ap/GetApInvoicesById?Id=${Id}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApInvoicesListByOrganizationId = createAsyncThunk(
  'ApInvoices/getApInvoicesListByOrganizationId',
  async (OrganizationId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApInvoicesModels[]>>(
        `Ap/GetApInvoicesListByOrganizationId?OrganizationId=${OrganizationId}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApInvoicesListByPeopleId = createAsyncThunk(
  'ApInvoices/getApInvoicesListByPeopleId',
  async (PeopleId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApInvoicesModels[]>>(
        `Ap/GetApInvoicesListByPeopleId?PeopleId=${PeopleId}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApInvoicesListUnPaid = createAsyncThunk(
  'ApInvoices/getApInvoicesListUnPaid',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApInvoicesModels[]>>(
        'Ap/GetApInvoicesListUnPaid'
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApInvoicesListPaid = createAsyncThunk(
  'ApInvoices/getApInvoicesListPaid',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApInvoicesModels[]>>(
        'Ap/GetApInvoicesListPaid'
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const invoicesCreateAccount = createAsyncThunk(
  'ApInvoices/invoicesCreateAccount',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ap/InvoicesCreateAccount', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ApInvoicesList: ApInvoicesModels[];
  ApInvoicesById: ApInvoicesModels;
  ApInvoicesListByPeopleId: ApInvoicesModels[];
  ApInvoicesListByOrganizationId: ApInvoicesModels[];

  UnPaidInvoicesList: ApInvoicesModels[];
  PaidInvoicesList: ApInvoicesModels[];

  loading: boolean;
  error: any | null;
} = {
  ApInvoicesList: [],
  ApInvoicesById: {} as ApInvoicesModels,
  ApInvoicesListByPeopleId: [],
  ApInvoicesListByOrganizationId: [],

  UnPaidInvoicesList: [],
  PaidInvoicesList: [],

  loading: false,
  error: null,
};

const ApInvoicesSlice = createSlice({
  name: 'ApInvoices',
  initialState,
  reducers: {
    cleanApInvoicesById: (state) => {
      state.ApInvoicesById = {} as ApInvoicesModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getApInvoicesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getApInvoicesList.fulfilled, (state, action: PayloadAction<ApInvoicesModels[]>) => {
        state.loading = false;
        state.ApInvoicesList = action.payload;
      })
      .addCase(getApInvoicesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApInvoicesById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getApInvoicesById.fulfilled, (state, action: PayloadAction<ApInvoicesModels>) => {
        state.loading = false;
        state.ApInvoicesById = action.payload;
      })
      .addCase(getApInvoicesById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApInvoicesListByPeopleId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApInvoicesListByPeopleId.fulfilled,
        (state, action: PayloadAction<ApInvoicesModels[]>) => {
          state.loading = false;
          state.ApInvoicesListByPeopleId = action.payload;
        }
      )
      .addCase(getApInvoicesListByPeopleId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApInvoicesListByOrganizationId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApInvoicesListByOrganizationId.fulfilled,
        (state, action: PayloadAction<ApInvoicesModels[]>) => {
          state.loading = false;
          state.ApInvoicesListByOrganizationId = action.payload;
        }
      )
      .addCase(getApInvoicesListByOrganizationId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApInvoicesListUnPaid.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApInvoicesListUnPaid.fulfilled,
        (state, action: PayloadAction<ApInvoicesModels[]>) => {
          state.loading = false;
          state.UnPaidInvoicesList = action.payload;
        }
      )
      .addCase(getApInvoicesListUnPaid.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApInvoicesListPaid.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApInvoicesListPaid.fulfilled,
        (state, action: PayloadAction<ApInvoicesModels[]>) => {
          state.loading = false;
          state.PaidInvoicesList = action.payload;
        }
      )
      .addCase(getApInvoicesListPaid.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanApInvoicesById } = ApInvoicesSlice.actions;
export default ApInvoicesSlice.reducer;
