// import Departments from '../../pages/HR/Departments/Departments.js';
// import OrganizationStructure from '../../pages/HR/Departments/Structure/OrganizationStructure';

import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading.jsx';

// تحميل مكونات المسارات بشكل كسول
const Departments = lazy(() => import('../../pages/HR/Departments/Departments.js'));
const OrganizationStructure = lazy(() =>
  import('../../pages/HR/Departments/Structure/OrganizationStructure')
);

const organizationRoutes = [
  {
    path: 'Org/*',
    children: [
      {
        path: 'Departments',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Departments />
          </Suspense>
        ),
      },
      {
        path: 'OrganizationStructure',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <OrganizationStructure />
          </Suspense>
        ),
      },
    ],
  },
];

export default organizationRoutes;
