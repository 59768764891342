/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../../../components/card/card';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { AppSettings } from '../../../../../config/app-settings';
import Loading from '../../../../../components/Shared/Loader/Loading';

import styles from './style.module.css';
import NoData from '../../../../../components/NoData/NoData';

interface Props {
  ReportsList: any[];
  loading?: boolean;
}

const Index: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const context = useContext(AppSettings);

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Reports'),
      link: null,
    },
  ];

  useEffect(() => {
    context.setAppContentFullHeight(true);
    return function cleanUp() {
      context.setAppContentFullHeight(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Reports')} />
      <hr/>

      <Loading loading={props.loading || false}>
        {props.ReportsList && props.ReportsList.length > 0 ? (
          <div className="row gx-4 gy-4">
            {props.ReportsList.map((item) => (
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={item.id}>
                <Card
                  className={
                    'h-100 d-flex flex-column justify-content-center align-items-center text-center p-3 position-relative ' +
                    styles.card
                  }>
                  <div className="d-flex align-items-center justify-content-between w-100 mb-4 text-theme">
                    <i
                      className={
                        item.icon
                          ? item.icon.replace('fs-1 fa-lg fa-fw me-2', '')
                          : 'fas fs-1 fa-lg fa-fw me-2 bi bi-file-earmark-bar-graph-fill '
                      }
                      style={{ fontSize: '3.25em' }}></i>
                    <button
                      type="button"
                      className={'btn btn-outline-theme rounded-0'}
                      onClick={() => Navigate(`${item.id}/ReportsBuilder`)}>
                      عرض التقرير
                    </button>
                  </div>
                  <h5 className="mt-2">{item.name}</h5>
                </Card>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center mt-5">
            <NoData message='No reports available'/>
           </div>
        )}
      </Loading>
    </>
  );
};

export default Index;

// <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={item.id}>
// <div className="card-conditions d-flex flex-column justify-content-between">
//   {/* <div className=''> */}
//   <h6 className="text-center m-0">{item.name}</h6>
//   {/* </div> */}

//   <div className="d-flex  justify-content-between align-items-center">
//     <button
//       type="button"
//       onClick={() => Navigate(`${item.id}/ReportsBuilder`)}
//       className=" btn btn-outline-theme fw-bold">
//       {t('عرض التقرير')}
//     </button>
//     <i
//       className={
//         item.icon
//           ? item.icon.replace('fs-1 fa-lg fa-fw me-2', '')
//           : 'fas fs-1 fa-lg fa-fw me-2 bi bi-file-earmark-bar-graph-fill '
//       }></i>
//   </div>
// </div>
// </div>
