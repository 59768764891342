import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getArTransactionsList } from '../../../store/Finance/AR/ArTransactions/ArTransactionsSlice';

const useGetArTransactionsList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ArTransactionsList?.length === 0) {
      dispatch(getArTransactionsList());
    }
  }, []);
  const { ArTransactionsList, loading, error } = useAppSelector((state) => state.ArTransactions);

  return { ArTransactionsList, loading, error };
};

export default useGetArTransactionsList;
