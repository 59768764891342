import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
 import { paymentTypeEnum } from './Add/Add';

interface LinesProps {
  formik: FormikProps<any>;
}

const ArScheduleList: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;

  console.log(values.scheduleList);
  

  const { i18n, t } = useTranslation();

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...values.scheduleList];

    updatedLines[index] = {
      ...updatedLines[index],
      amount: value,
    };

    formik?.setFieldValue('scheduleList', updatedLines);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.scheduleList?.filter((_: any, i: number) => i !== index);
    formik?.setFieldValue('scheduleList ', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.scheduleList?.reduce((sum: any, line: any) => sum + line.amount, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

 
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-3 mb-2">
          <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
            <div className="d-flex justify-content-between">
              <div className="fw-bold">{t('Total')}:</div>
              <div className="text-theme fs-5 fw-bold">
                {/* {values.paymentType === paymentTypeEnum.PrePayment
                  ? values.prePaymentTotal
                  : 
                } */}
                {calculateTotalSum()}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3"></div>
        <div className="col-lg-6 text-end ">
          <div className="text-end mb-2">
            {/* <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
               {t('إضافة صنف')}
            </button> */}
          </div>
        </div>
      </div>

      {values.paymentType !== paymentTypeEnum.PrePayment && (
        <div className={`table-responsive rounded-2 text-center  `}>
          <table className="table table-borderless  text-nowrap  table-hover mb-0">
            <thead className="bg-theme bg-opacity-30">
              <tr>
                <th className="align-middle small">{t('إجمالي الفاتورة')}</th>
                <th className="align-middle small">{t('رقم الفاتورة')}</th>
                <th className="align-middle small">{t('تاريخ الفاتورة')}</th>
                <th className="align-middle small">{t('تاريخ الإستحقاق')}</th>

                <th className="align-middle small">{t('قيمة الدفعة')}</th>
                <th className="align-middle small">{t('القيمة المراد تسديدها')}</th>

                <th className="align-middle text-end"></th>
              </tr>
            </thead>

            <tbody className=" ">
              {values.scheduleList
                ?.slice()

                ?.map((line: any, index: any) => {
                  const Index = index;

                  return (
                    <tr key={index}>
                      <td className="align-middle small">{line.transactionAmount}</td>
                      <td className="align-middle small">{line.transactionNum}</td>
                      <td className="align-middle small">{line.transactionDate}</td>
                      <td className="align-middle small">{line.dueDate}</td>
                      <td className="align-middle small">{line.schadualAmount}</td>

                      <td className="align-middle small">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control text-center text-theme fw-bold fs-6"
                          value={line.amount || 0}
                          onChange={(e) =>
                            handleLineChange(Index, 'amount', Number(e.target.value))
                          }
                          onInput={(e) => {
                            const input = e.target as HTMLInputElement;

                            // السماح فقط بالأرقام والنقطة العشرية
                            input.value = input.value.replace(/[^0-9.]/g, '');

                            // السماح فقط بنقطة عشرية واحدة
                            if (input.value.split('.').length > 2) {
                              input.value = input.value.substring(0, input.value.length - 1);
                            }

                            // التحقق من القيمة المدخلة بالنسبة لحد المبلغ المتبقي
                            if (parseFloat(input.value) > line.actualRemainingAmount) {
                              input.value = line.actualRemainingAmount.toString();
                            }
                          }}
                        />
                      </td>

                      <td className="align-middle small">
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => handleDeleteLine(Index)}>
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ArScheduleList;
