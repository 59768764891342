// import Notifications from '../../pages/NTX/Notifications/Index/Index';
// import AddNotifications from '../../pages/NTX/Notifications/Add/AddNotifications';
// import UpdateNotifications from '../../pages/NTX/Notifications/Update/UpdateNotifications';

// const notificationsRoutes = [
//   {
//     path: 'NTF/*',
//     children: [
//       { path: 'Notifications', element: <Notifications /> },
//       {
//         path: 'Notifications/AddNotifications',
//         element: <AddNotifications />,
//       },
//       {
//         path: 'Notifications/:id/UpdateNotifications',
//         element: <UpdateNotifications />,
//       },
//     ],
//   },

// ];

// export default notificationsRoutes;


import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';

// Lazy loading components
const Notifications = lazy(() => import('../../pages/NTX/Notifications/Index/Index'));
const AddNotifications = lazy(() => import('../../pages/NTX/Notifications/Add/AddNotifications'));
const UpdateNotifications = lazy(() => import('../../pages/NTX/Notifications/Update/UpdateNotifications'));

const notificationsRoutes = [
  {
    path: 'NTF/*',
    children: [
      { path: 'Notifications', element: <Suspense fallback={<LazyLoading />}><Notifications /></Suspense> },
      { path: 'Notifications/AddNotifications', element: <Suspense fallback={<LazyLoading />}><AddNotifications /></Suspense> },
      { path: 'Notifications/:id/UpdateNotifications', element: <Suspense fallback={<LazyLoading />}><UpdateNotifications /></Suspense> },
    ],
  },
];

export default notificationsRoutes;