import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import ModalComponent from '../../../../components/Modal/ModalComponent';
// import Add from './Add';

import PeoplesContacts from '../../../NTX/Peoples/PeoplesContacts/PeoplesContacts';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import NtxTable from '../../../../components/DataTable/NtxTable';

const Index = () => {
  const [peopleData, setPeopleData] = useState<any>('');

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { SuppliersList } = useGetSuppliersList();

  const columns = [
    { title: t('SupplierName'), key: 'name' },
    { title: t('SupplierNumber'), key: 'num' },
    { title: t('TradingName'), key: 'name2' },
    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData =
    SuppliersList?.filter((f) => f.parentId === null)?.map((item) => {
      let rowClass;

      return {
        name: i18n.language === 'en' ? item.name : item.name2,
        num: '000',
        name2: item.tradingName,

        action: (
          <Dropdown as={ButtonGroup}>
            <Button
              className="btn-theme"
              onClick={() =>
                navigate(`${item.id}/UpdateSupplier`, {
                  state: {
                    name: i18n.language === 'en' ? item.name : item.name2,
                  },
                })
              }>
              {t('SupplierDetails')}
            </Button>

            <Dropdown.Toggle className=" btn-theme ms-1" split />

            <Dropdown.Menu>
              <Dropdown.Item
                className="text-start"
                onClick={() =>
                  navigate(`${item.id}/BranchesSupplier`, {
                    state: {
                      name: i18n.language === 'en' ? item.name : item.name2,
                    },
                  })
                }>
                <i className="fas fa-store fa-lg me-2 text-theme"></i>
                {t('Branches')}
              </Dropdown.Item>
              <Dropdown.Item
                className="text-start"
                href="#/"
                onClick={() => setPeopleData(item)}
                data-bs-toggle="modal"
                data-bs-target="#CustomersContactsTest">
                <i className="fas fa-address-book fa-lg me-2 text-theme"></i>{' '}
                {t('ContactInformation')}
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3" className="text-start">
                <i className="fas fa-map-marker-alt fa-lg me-2 text-theme"></i> {t('Locations')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      };
    }) || [];

  const titleModal = () => {
    return (
      <div className="">
        {t('ContactInformationForSupplier')}:{'  '}
        <span className="text-theme">
          {`(${i18n.language === 'en' ? peopleData?.name : peopleData?.name2})`}
        </span>
      </div>
    );
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Suppliers'),
      link: null,
    },
  ];
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Suppliers')} />
        {/* <Loader loading={loading} /> */}
        <div className="ms-auto">
          <Link
            to="AddSupplier"
            className="btn me-1 btn-outline-theme"
            // data-bs-toggle="modal"
            // data-bs-target="#modalAddSupplier"
            // onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('AddNewSupplier')}
          </Link>
        </div>
      </div>

      <Card>
        <CardBody>
          <NtxTable
            data={tableData || []}
            columns={columns || []}
            dataPerPage={10}
            theadClass=""
            tableClass="table text-nowrap table-hover "
            enableSearch={true}
            showRecordsSelector
          />
        </CardBody>
      </Card>

      {/* <ModalComponent title={t('إضافة مورد جديد')} id="modalAddSupplier">
        {<Add />}
      </ModalComponent> */}
      <ModalComponent title={titleModal()} id="CustomersContactsTest">
        {peopleData?.id && <PeoplesContacts peopleId={peopleData.id} />}
      </ModalComponent>
    </Fragment>
  );
};

export default Index;
