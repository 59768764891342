import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const financeMenu = [
  {
    System: 'FIN',
    isActive: false,
    menu: [
      { is_header: true, title: 'النظام المالي', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'HomePage',
      },

      {
        path: '/FinanceSettings',
        icon: 'bi bi-gear',
        title: 'Settings',
        children: [
          {
            path: 'FinanceSettings/FiscalYear',
            title: 'السنة المالية',
            isActive: true,
          },
          {
            path: 'FinanceSettings/AccountingPeriod',
            title: 'الفترات المحاسبية',
            isActive: true,
          },
          {
            path: 'FinanceSettings/ChartAccounts',
            title: 'الدليل المحاسبي',
            isActive: true,
          },
          {
            path: 'FinanceSettings/Ledgers',
            title: 'الأستاذ العام',
            isActive: true,
          },
          {
            path: 'FinanceSettings/DailyRates',
            title: 'المعدل اليومي للعملات',
            isActive: true,
          },
          {
            path: 'FinanceSettings/JeCategory',
            title: 'تصنيفات القيود',
            isActive: true,
          },
          {
            path: 'FinanceSettings/JeSource',
            title: 'مصادر القيود',
            isActive: true,
          },
          {
            path: 'FinanceSettings/VAT',
            title: 'ضريبة القيمة المضافة',
            isActive: true,
          },
          {
            path: 'FinanceSettings/ConsolidationSet',
            title: 'الربط بين الإدارات',
            isActive: true,
          },
          {
            path: 'FinanceSettings/TypesOfExpenses',
            title: 'أنواع المصروفات',
            isActive: true,
          },
          {
            path: 'FinanceSettings/BankAccounts',
            title: 'الحسابات البنكية',
            isActive: true,
          },
          {
            path: 'FinanceSettings/CashBoxes',
            title: 'صناديق النقديــة',
            isActive: true,
          },
        ],
      },

      {
        path: 'Finance',
        icon: 'bi bi-cash-coin',
        title: 'القيود',
        children: [
          {
            path: 'Finance/Journals',
            title: 'القيود اليومية',
            isActive: true,
          },
          {
            path: 'Finance/PostingJournals',
            title: 'ترحيــل القيود',
            isActive: true,
          },
        ],
      },

      {
        path: 'Finance/Budget',
        icon: 'fas fa-home',
        title: 'Budget',
        isActive: true,
      },
      {
        path: 'Finance/ControlCodeCombinations',
        icon: 'fas fa-home',
        title: 'Control CodeCombinations ',
        isActive: true,
      },

      {
        path: 'Finance',
        icon: 'bi bi-cash-coin',
        title: 'التقاريــر الماليــة',
        children: [
          {
            path: 'Finance/IncomeStatement',
            title: 'قائمة الدخل',
            isActive: true,
          },
          {
            path: 'Finance/BalanceSheet',
            title: 'المركـر المالي',
            isActive: true,
          },
          {
            path: 'Finance/AuditBalance',
            title: 'ميزان المراجعة',
            isActive: true,
          },
        ],
      },

    
    
    ],
  },
];

export default financeMenu;
