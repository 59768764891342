import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getInventoryItemsByInventoryId,
  cleanUpInventoryItems,
} from '../../../store/INV/MasterItems/MasterItemsSlice';
import { useTranslation } from 'react-i18next';

const useGetInventoryItems = (InventoryId: string | undefined) => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    if (InventoryId) {
      dispatch(getInventoryItemsByInventoryId(InventoryId));
    }
    return () => {
      dispatch(cleanUpInventoryItems());
    };
  }, [InventoryId, dispatch]);
  const { InventoryItems, loading, error } = useAppSelector((state) => state.MasterItemsINV);

  const InventoryItemsOptions = InventoryItems?.map((item) => ({
    value: item.itemId,
    label: i18n.language === 'en' ? item.name : item.name2,
    //________________________________________________________________
    itemId: item.itemId,
    barcode: item.barcode,
    itemName: `${i18n.language === 'en' ? item.name : item.name2}`,
    uom: item.unitOfMeasure,
    uomName: `${i18n.language === 'en' ? item.unitOfMeasureName : item.unitOfMeasureName2}`,
    // QtyAvailable: item.qty,
    isPrimaryUOM: item.isPrimaryUOM,
    categoryId: item.categoryId,
    ///
    qtyByLot: item.qtyByLot?.map((m) => ({
      lotId: m.lotId,
      lotNumber: m.lotNumber,
      qty: m.qty,
      expirationDate: m.expirationDate,
    })),
  }));

  return { InventoryItemsOptions, InventoryItems, loading, error };
};

export default useGetInventoryItems;
