import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { GetUnitsOfMeasureList } from "../../store/INV/UnitsOfMeasure/UnitsOfMeasureSlice";
// import { GetUnitsOfMeasureList } from "../../store/INV/MasterItems/MasterItemsSlice";

const useGetUnitsOfMeasureList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (UnitsOfMeasureList?.length === 0) {
      dispatch(GetUnitsOfMeasureList());
    }
  }, []);
  const { UnitsOfMeasureList, loading , error} = useAppSelector(
    (state) => state.UnitsOfMeasure
  );

  return { UnitsOfMeasureList, loading , error };
};

export default useGetUnitsOfMeasureList;
