import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import Add from './Add';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getAttributeList } from '../../../../store/INV/Attributes/AttributesSlice';
import Update from './Update';
import NtxTable from '../../../../components/DataTable/NtxTable';

const Index = () => {
  const [attributeId, setAttributeId] = useState('');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAttributeList());
  }, []);

  const { AttributesList, error, loading } = useAppSelector((s) => s.Attributes);

  const filteredSearch = AttributesList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const columns = [
    { title: 'الأســم', key: 'name' },
    { title: 'النوع', key: 'type' },
    { title: 'الوصف', key: 'description' },
    { title: '', key: 'action', className: 'text-end', sortable: false },
  ];

  const tableData = filteredSearch?.map((item) => {
    let rowClass;

    return {
      name: i18n.language === 'en' ? item.name : item.name2,
      type: item.attType,
      description: item.description,
      action: (
        <>
          <button
            type="button"
            className="btn btn-outline-theme me-2"
            // onClick={() => Navigate(`${item.id}/UpdateAttributes`)}
            data-bs-toggle="modal"
            data-bs-target="#Upfate"
            onClick={() => setAttributeId(item.id)}>
            <i className="fas fa-lg fa-briefcase me-2"></i>
            AssignmentAttributeToMultiItems
          </button>
          <button
            type="button"
            className="btn btn-outline-theme"
            // onClick={() => Navigate(`${item.id}/UpdateAttributes`)}
            data-bs-toggle="modal"
            data-bs-target="#Upfate"
            onClick={() => setAttributeId(item.id)}>
            <i className="fas fa-lg fa-edit me-2"></i>
            تعديـــل
          </button>
        </>
      ),
    };
  });

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'سمات الأصناف',
      link: null,
    },
  ];

  return (
    <>
      <div>
        <div className="d-flex align-items-center">
          <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('سمـات الأًصناف')} />

          <div className="ms-auto">
            <Link
              to=""
              className="btn me-1 btn-outline-theme"
              data-bs-toggle="modal"
              data-bs-target="#Add">
              <i className="fa fa-lg fa-plus-circle fa-fw me-1"></i>
              {t('سمة جديدة')}
            </Link>
          </div>
        </div>
        <Card>
          {/* <Loader loading={loading} /> */}
          <Loading loading={loading} error={error}>
            <CardBody>
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">{t('Search')}</span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control ps-35px"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <NtxTable
                data={tableData}
                columns={columns}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
              />
            </CardBody>
          </Loading>
        </Card>

        <ModalComponent title={'إضافة سمة جديــدة'} id="Add">
          {true && <Add />}
        </ModalComponent>

        <ModalComponent title={'تعديـل سمة'} id="Upfate">
          {attributeId && <Update attributId={attributeId} />}
        </ModalComponent>
      </div>
    </>
  );
};

export default Index;

// <button
// type="button"
// className="btn btn-outline-theme"
// data-bs-toggle="modal"
// data-bs-target="#ModalEditPos"
// // onClick={() => setId(item.id)}
// >
// <i className="far fa-lg fa-fw me-2 fa-edit"></i>
// {t('Button.Edit')}
// </button>
// <button
// type="button"
// className="btn btn-outline-theme"
// data-bs-toggle="modal"
// data-bs-target="#ModalPosCashier"
// onClick={() =>
//   setPosId(item.id||"")
// }
// >
// {/* <i className="far fa-lg me-2 bi bi-list-stars"></i> */}
// Pos Cashier
// </button>
