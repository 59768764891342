import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader } from '../../../../../components/card/card';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  GetMasterItemsById,
  cleanUpMasterItemsById,
} from '../../../../../store/INV/MasterItems/MasterItemsSlice';
import ItemConversion from './ItemConversion';
import Loading from '../../../../../components/Shared/Loader/Loading';
import AssignmentItemToMultiInventories from './AssignmentItemToMultiInventories';
import LotItem from './LotItems/LotItem';
import AlternativeItems from './AlternativeItems';
import Update from './Update';
import Attributes from './Attributes';

const MasterItemsDetails = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { ItemId } = useParams();

  useEffect(() => {
    if (ItemId) {
      dispatch(GetMasterItemsById(ItemId));
    }
    return () => {
      dispatch(cleanUpMasterItemsById());
    };
  }, []);
  const { MasterItemsById, loading, error } = useAppSelector((a) => a.MasterItemsINV);

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الأصناف',
      link: '/INV/InventorySettings/MasterItems',
    },
    {
      name: 'تفاصيل الصنف',
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName={t('تفاصيل الصنف')}
          subtitle={i18n.language === 'en' ? MasterItemsById.name : MasterItemsById.name2}
        />
      </div>

      <Loading loading={false} error={null}>
        <>
          <div className="row gx-4">
            <div className="col-lg-12">
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center justify-content-between fw-bold small bg-theme bg-opacity-25">
                  <div className="fw-bold">
                    <i className="fas fa-info-circle me-2"></i>
                    تفاصيل المنتج
                  </div>
                </CardHeader>
                <CardBody>
                  <Update />
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row gx-4">
            <div className="col-lg-8">
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
                  <i className="fas fa-boxes me-2"></i>
                  وحدات الصنف
                </CardHeader>
                <CardBody>
                  <ItemConversion />
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-4">
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
                  <i className="fas fa-warehouse me-2"></i>
                  المخزن
                </CardHeader>
                <CardBody>
                  <AssignmentItemToMultiInventories />
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row gx-4">
            <div className="col-lg-8">
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
                  <i className="fas fa-hashtag me-2"></i>
                  أرقام التشغيل
                </CardHeader>
                <CardBody>
                  <LotItem />
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-4">
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
                  <i className="fas fa-retweet me-2"></i>
                  الأصناف البديـلة
                </CardHeader>
                <CardBody>
                  <AlternativeItems />
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-4">
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
                  <i className="fas fa-retweet me-2"></i>
                  الـخصائص
                </CardHeader>
                <CardBody>
                  <Attributes />
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </>
      </Loading>
    </>
  );
};

export default MasterItemsDetails;
