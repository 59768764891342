import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from "react-router-dom";
import { GetAbsenceAttendanceView } from '../../../../../store/HR/Person/absenceSlice';
import Loading from '../../../../../components/Shared/Loader/Loading';

const ViewAbsence = ({ ReferenceId }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  // const { Id } = useParams();

  // var ID = isAme ? ReferenceId : Id

  useEffect(() => {
    if (ReferenceId) {
      dispatch(GetAbsenceAttendanceView(ReferenceId));
    }
  }, [ReferenceId, dispatch]);

  const data = useSelector((state) => state.Absence?.AbsenceAttendanceViewList?.data);
  const loading = useSelector((state) => state.Absence.loading2);
  const error = useSelector((state) => state.Absence.error2);
 


 
    
  return (
    <>
      <Loading loading={loading} error={error}>
        <div className="row m-0">
      

          <div className="col-xl-12">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th className="bg-theme bg-opacity-25" style={{ maxWidth: '50px', textAlign: 'center' }}>
                    {t('Absence_Type')}
                  </th>
                  <td>{i18n.language === 'ar' ? data?.absenceName2 : data?.absenceName}</td>
                </tr>

                <tr>
                  <th
                    colSpan={1}
                    scope="row"
                    className="bg-theme bg-opacity-25"
                    style={{ maxWidth: '50px', textAlign: 'center' }}>
                    {t('Start Date')}
                  </th>
                  <td colSpan={4}>{data?.dateStart}</td>
                </tr>

                <tr>
                  <th
                    colSpan={1}
                    scope="row"
                    className="bg-theme bg-opacity-25 "
                    style={{ maxWidth: '50px', textAlign: 'center' }}>
                    {t('Date_TO')}
                  </th>
                  <td colSpan={4}>{data?.dateEnd}</td>
                </tr>

                <tr>
                  <th
                    colSpan={1}
                    scope="row"
                    className="bg-theme bg-opacity-25"
                    style={{ maxWidth: '50px', textAlign: 'center' }}>
                    {t('Days')}
                  </th>
                  <td colSpan={4}>{data?.absenceDays}</td>
                </tr>

                <tr>
                  <th
                    colSpan={1}
                    scope="row"
                    className="bg-theme bg-opacity-25"
                    style={{ maxWidth: '50px', textAlign: 'center' }}>
                    {t('Authorising_Person')}
                  </th>
                  <td colSpan={4}>
                    {i18n.language === 'ar' ? data?.authorisingName2 : data?.authorisingName}
                  </td>
                </tr>

                <tr>
                  <th
                    colSpan={1}
                    scope="row"
                    className="bg-theme bg-opacity-25"
                    style={{ maxWidth: '50px', textAlign: 'center' }}>
                    {t('replacement')}
                  </th>
                  <td colSpan={4}>
                    {i18n.language === 'ar' ? data?.replacemenName2 : data?.replacemenName}
                  </td>
                </tr>

                <tr>
                  <th
                    colSpan={1}
                    scope="row"
                    className="bg-theme bg-opacity-25"
                    style={{ maxWidth: '50px', textAlign: 'center' }}>
                    {t('Notes')}
                  </th>
                  <td colSpan={4}>{data?.comments}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <div className="col-xl-2"></div> */}
        </div>
      </Loading>
    </>
  );
};

export default ViewAbsence;
