 
import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';
 
// Lazy loading components
const RotationPlan = lazy(() => import('../../pages/HR/Raqeeb/RotationPlan'));
const RotationSetting = lazy(() => import('../../pages/HR/Raqeeb/RotationSetting/RotationSetting'));
const AttendanceAnalysis = lazy(() =>
  import('../../pages/HR/Raqeeb/AttendanceAnalysis/AttendanceAnalysis')
);
const AnalysisTable = lazy(() => import('../../pages/HR/Raqeeb/AttendanceAnalysis/AnalysisTable'));
const UpdateRaqeebData = lazy(() => import('../../pages/HR/Raqeeb/UpdateData/UpdateRaqeebData'));

const raqeebRoutes = [
  {
    path: 'RAQ/*',
    children: [
      {
        path: 'RotationPlan',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <RotationPlan />
          </Suspense>
        ),
      },
      {
        path: 'RotationPlan/:id/RotationSetting',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <RotationSetting />
          </Suspense>
        ),
      },
      {
        path: 'AttendancAnalysis', // Fixed spelling
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AttendanceAnalysis />
          </Suspense>
        ),
      },
      {
        path: 'AttendanceAnalysis/AnalysisTable',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AnalysisTable />
          </Suspense>
        ),
      },
      {
        path: 'UpdateRaqeebData',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <UpdateRaqeebData />
          </Suspense>
        ),
      },
    ],
  },
];

export default raqeebRoutes;
