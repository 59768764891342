import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/index';
import AppRoute from './config/app-route.jsx';
// import './scss/stylesAR.scss';
// import './scss/stylesEN.scss';
 



const App = () => {
  React.useEffect(() => {
    const loadStyles = async () => {
      const language = localStorage.language ?? 'ar';
      await import(`./scss/styles${language === 'ar' ? 'AR' : 'EN'}.scss`);
      root.render(
        // <React.StrictMode>
          <Provider store={store}>
            <RouterProvider router={AppRoute} />
          </Provider>
          // {/* </React.StrictMode> */}
      );
    };

    loadStyles();
  }, []);
  return null;
};
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App/>);


// root.render(
//   // <React.StrictMode>
//   <Provider store={store}>
//     <RouterProvider router={AppRoute} />
//   </Provider>
//   //  {/* </React.StrictMode> */}
// );

reportWebVitals();

// root.render(<App/>);
// const App = () => {
//   React.useEffect(() => {
//     const loadStyles = async () => {
//       const language = localStorage.language ?? 'ar';
//       await import(`./scss/styles${language === 'ar' ? 'AR' : 'EN'}.scss`);
//       root.render(
//         // <React.StrictMode>
//           <Provider store={store}>
//             <RouterProvider router={AppRoute} />
//           </Provider>
//         //  {/* </React.StrictMode> */}
//       );
//     };

//     loadStyles();
//   }, []);
//   return null;
// };
