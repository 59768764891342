import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import NeatixModal from '../../../../components/Modal/NeatixModal';
import useGetFACategoriesList from '../../../../hooks/Finance/FixedAssets/useGetFACategoriesList';
import AddFaCategory from './AddFaCategory';

const Index = () => {
  const [showAddModal, setShowAddModal] = useState(false);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  // const Navigate = useNavigate();

  const { FACategoriesList, loading, error } = useGetFACategoriesList();

  const filteredSearch = FACategoriesList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Fa Categories',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Fa Categories" />

        <div className="ms-auto">
          <Link
            // to="AddFaCategory"
            to=""
            className="btn me-1 btn-theme"
            onClick={() => setShowAddModal(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            Add FA Categories
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">
                      {t('Search')}
                      {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                    </span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text" 
autoComplete='off'
                            className="form-control ps-35px"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover text-nowrap text-center">
                  <thead className="table-dark">
                    <tr>
                      <th>الاسم</th>
                      <th>الفئة الرئيسية</th>
                      <th>الأيقونة</th>
                      <th>العمر بالشهور</th>
                      <th>طريقة الإهلاك</th>
                      <th>نسبة الإهلاك</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'en' ? item.name : item.name2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === 'en'
                            ? item.categoryName
                              ? item.categoryName
                              : 'Main Category'
                            : item.categoryName2
                            ? item.categoryName2
                            : 'فئة رئيسية'}
                        </td>
                        <td className="align-middle">
                          <div className="text-theme fs-4">
                            <i className={item.icon}></i>
                          </div>
                        </td>
                        <td className="align-middle"> {item.lifeInMonths} </td>
                        <td className="align-middle"> {item.depreciationMethod} </td>
                        <td className="align-middle"> {item.percentage} </td>

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            // onClick={() => Navigate(`${item.id}/UpdatePurchaseBill`)}
                          >
                            {/* <i className="far fa-lg fa-fw me-2 fa-edit"></i> */}
                            {t('عرض التفاصيل')}
                          </button>

                          {/* <DynamicPrint
                            PrintTemplateTypeId="868E12B7-A5D3-41F9-AA87-45BB552B3AAB" // قوالب عرض السعر
                            ReferenceId={item.id || ''}
                          /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showAddModal}
        setShow={setShowAddModal}
        // bodyClassName="p-0"
        title="Add Fa Category"
        size="xl"
        // showHeader={false}
      >
        <AddFaCategory />
      </NeatixModal>
    </div>
  );
};

export default Index;
