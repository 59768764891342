import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const integrationMenu = [
   {
    System: 'INT',
    isActive: true,
    menu: [
      { is_header: true, title: 'التكــامل مع الأنظمة', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'Dashboard',
      },

      {
        path: '/Integration',
        icon: 'fas fa-home',
        title: 'التكامل',
        isActive: true,
      },
    ],
  },
];

export default integrationMenu;
