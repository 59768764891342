import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getUnApplyPrepaymentsListByPeopleId,
  cleanUpUnApplyPrepaymentsList,
} from '../../../store/Finance/AP/ApPayments/ApPaymentsSlice';

interface Props {
  PeopleId?: string | undefined;
}

const useGetUnApplyPrepayments = ({ PeopleId }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (PeopleId) {
      dispatch(getUnApplyPrepaymentsListByPeopleId(PeopleId));
    }
    return () => {
      dispatch(cleanUpUnApplyPrepaymentsList());
    };
  }, [PeopleId, dispatch]);
  const {
    UnApplyPrepaymentsList,
    loading2: loading,
    error,
  } = useAppSelector((state) => state.ApPayments);

  const hasPrepayments = UnApplyPrepaymentsList && UnApplyPrepaymentsList.length > 0;

  // حساب مجموع حقل amount في UnApplyPrepaymentsList
  const total = UnApplyPrepaymentsList
    ? UnApplyPrepaymentsList.reduce((sum, prepayment) => sum + (prepayment.amountUnApply || 0), 0)
    : 0;

  // تنسيق المجموع لإظهاره مع الفواصل
  const totalPrepaymentAmount = total.toLocaleString();

  return { UnApplyPrepaymentsList, loading, error, hasPrepayments, totalPrepaymentAmount };
};

export default useGetUnApplyPrepayments;
