import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import AddPaymentSchedules from './AddPaymentSchedules';

interface Props {
  PaymentSchedulesList: any;
}

const InvoicePayment = ({ PaymentSchedulesList }: Props) => {
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const [schedulesDetails, setSchedulesDetails] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <h6>سداد الدفعات</h6>
      </div>

      {PaymentSchedulesList?.map((payment: any, index: any) => (
        <div key={index} className="row mb-3">
          <div className="col-lg-5">
            <div className="input-group">
              <span className="input-group-text">{t('المبلغ')}</span>
              <Form.Control type="text" 
autoComplete='off' value={payment.amount} />
            </div>
          </div>

          <div className="col-lg-5  ">
            <div className="input-group">
              <span className="input-group-text ">{t('تاريخ الاستحقاق')}</span>
              <Form.Control type="date" value={payment.dueDate?.split('T')[0]} />
            </div>
          </div>

          <div className="col-lg-2 text-end">
            <button
              type="button"
              className="btn btn-outline-theme w-100"
              onClick={() => {
                setSchedulesDetails(payment);
                setShow(true);
              }}>
              <i className="fas fa-credit-card me-2"></i>
              ســداد
            </button>
          </div>
        </div>
      ))}

      <NeatixModal show={show} setShow={setShow} size="lg" bodyClassName="p-0" showHeader={false}>
        <AddPaymentSchedules schedulesDetails={schedulesDetails} />
      </NeatixModal>
    </>
  );
};

export default React.memo(InvoicePayment);
