import { useEffect, useState } from 'react';
import useGetGlPeriodsList from './useGetGlPeriodsList';

const useCheckDateOnPeriod = (GlDate: string, periodId: string) => {
    
  const { PeriodsList } = useGetGlPeriodsList();
  const [isValid, setIsValid] = useState<boolean | null>(null); // القيمة الافتراضية null

  useEffect(() => {
    // Retrieve the startDate and endDate from PeriodsList based on the selected periodId
    let startDate = PeriodsList?.find((f) => f.id === periodId)?.startDate;
    let endDate = PeriodsList?.find((f) => f.id === periodId)?.endDate;

    // Convert the dates to JavaScript Date objects
    let startDateObj = startDate ? new Date(startDate) : null;
    let endDateObj = endDate ? new Date(endDate) : null;
    let GlDateObj = GlDate ? new Date(GlDate) : null;

    if (startDateObj && endDateObj && GlDateObj) {
      if (GlDateObj >= startDateObj && GlDateObj <= endDateObj) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false); // في حالة تواريخ غير صالحة أو غياب GlDate
    }
  }, [GlDate, PeriodsList, periodId]);

  return isValid;
};

export default useCheckDateOnPeriod;
