import { Card, CardBody } from '../../../../components/card/card.jsx';
import { Icon } from '@iconify/react';

import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getEmployees } from '../../../../store/HR/Person/employeeSlice.js';
import SalaryByOrg from './SalaryByOrg.jsx';
import Loading from '../../../../components/Shared/Loader/Loading';

const PayrollDashboard = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      if (Employees?.length === 0) {
        await dispatch(getEmployees());
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const { Employees, loading, error } = useSelector((state) => state.Employees);
  var totalEmployees = Employees?.length;

  return (
    <div>
      <Loading loading={loading} error={error}>
        <>
          <div className="row">
            <div className="col-lg-3">
              <Card className="mb-3 border-theme">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1 text-theme">عدد الموظفين</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4 text-success">
                        <CountUp end={totalEmployees} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="ph:users-duotone"
                          className="me-2 text-success "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-3">
              <Card className="mb-3 border-danger">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1 text-theme">متوسط الرواتب لكل موظف</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4 text-danger">
                        <CountUp end={200} duration={2.5} decimals={2} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="ph:currency-dollar-duotone"
                          className="me-2 text-danger "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-3">
              <Card className="mb-3 border-indigo">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1 text-theme">إجمالي الخصومات</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4 text-indigo">
                        <CountUp end={2300} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="ph:percent-duotone"
                          className="me-2 text-indigo "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-3">
              <Card className="mb-3 border-purple">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1 text-theme">إجمالي البدلات</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4 text-purple">
                        <CountUp end={5600} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="ph:gift-duotone"
                          className="me-2 text-purple "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <SalaryByOrg />
            </div>
          </div>
        </>
      </Loading>
    </div>
  );
};

export default PayrollDashboard;
