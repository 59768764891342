import React from 'react';
import { Icon } from '@iconify/react';

interface NoDataProps {
  message?: string;
}

const NoData: React.FC<NoDataProps> = ({ message }) => {
  return (
    <div className="text-center my-5 ">
      {/* <i className="fa fa-info-circle fa-3x text-muted mb-3  "></i> */}
      <Icon icon="ph:info-duotone" className='text-theme mb-3'  width="60" height="60" />
      <h5 className="text-muted">{message || 'لا توجد بيانات لعرضها'}</h5>
    </div>
  );
};

export default NoData;
