import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { JournalsModels, JournalsByReferenceIdModel } from './JournalsModels';

export const AddGlJeHeaders = createAsyncThunk(
  'Journals/AddGlJeHeaders',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Finance/AddGlJeHeaders', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatGlJeHeaders = createAsyncThunk(
  'Journals/UpdatGlJeHeaders',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Finance/UpdatGlJeHeaders', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetGlJeHeadersList = createAsyncThunk(
  'Journals/GetGlJeHeadersList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Finance/GetGlJeHeadersList');
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetGlJeHeadersById = createAsyncThunk(
  'Journals/GetGlJeHeadersById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Finance/GetGlJeHeadersById?Id=${id}`);
      if (data?.succeeded) {
        return data.data;
      } else {
        return {} as JournalsModels;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// export const GetGlJeHeadersByPeriodId = createAsyncThunk('Journals/GetGlJeHeadersByPeriodId',
// async ( PeriodId : string , {rejectWithValue} ) => {
//   try {

//     const {data} = await ntxAPI.get(`Finance/GetGlJeHeadersByPeriodId?PeriodId=${PeriodId}`)
//     return data;
//   } catch (error : any) {
//       return rejectWithValue(error.message);
//   }
// });
// export const GetGlJeLinesById = createAsyncThunk('Journals/GetGlJeLinesById',
// async ( Id : string , {rejectWithValue} ) => {
//   try {

//     const {data} = await ntxAPI.get(`Finance/GetGlJeLinesById?Id=${Id}`)
//     return data;
//   } catch (error : any) {
//       return rejectWithValue(error.message);
//   }
// });
// export const GetGlJeLinesByJeHeaderId = createAsyncThunk('Journals/GetGlJeLinesByJeHeaderId',
// async ( JeHeaderId : string , {rejectWithValue} ) => {
//   try {
//     const {data} = await ntxAPI.get(`Finance/GetGlJeLinesByJeHeaderId?JeHeaderId=${JeHeaderId}`)
//     return data;
//   } catch (error : any) {
//       return rejectWithValue(error.message);
//   }
// });

// setMaxLineNum(
//   formik.values.JeLinesList.reduce((max: number, item: any) => {
//     return item.jeLineNum > max ? item.jeLineNum : max;
//   }, 0)
// );

export const getJournalsByReferenceId = createAsyncThunk(
  'Journals/getJournalsByReferenceId',
  async (
    params: { ReferenceId: string; JeCategory?: string; JeSource?: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await ntxAPI.get(`Finance/GetJournalsByReferenceId`, {
        params,
      });
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  JournalsList: JournalsModels[];
  JournalsListByPeriodId: JournalsModels[];
  JournalsById: JournalsModels;

  JournalsByReferenceId: JournalsByReferenceIdModel[];

  MaxLineNum: number;
  loading: boolean;
  error: any | null;
} = {
  JournalsList: [],
  JournalsListByPeriodId: [],
  JournalsById: {} as JournalsModels,

  JournalsByReferenceId: [],

  MaxLineNum: 1,
  loading: false,
  error: null,
};

const JournalsSlice = createSlice({
  name: 'Journals',
  initialState,
  reducers: {
    clearJournalsById: (state) => {
      state.JournalsById = {} as JournalsModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(GetGlJeHeadersList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetGlJeHeadersList.fulfilled, (state, action: PayloadAction<JournalsModels[]>) => {
        state.loading = false;
        state.JournalsList = action.payload;
      })
      .addCase(GetGlJeHeadersList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      // setMaxLineNum(
      //   formik.values.JeLinesList.reduce((max: number, item: any) => {
      //     return item.jeLineNum > max ? item.jeLineNum : max;
      //   }, 0)
      // );

      .addCase(GetGlJeHeadersById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetGlJeHeadersById.fulfilled, (state, action: PayloadAction<JournalsModels>) => {
        state.loading = false;
        state.JournalsById = action.payload;
        const max = action.payload.glJeLinesList.reduce((max, item) => {
          return item.jeLineNum > max ? item.jeLineNum : max;
        }, 0);
        state.MaxLineNum = max;
      })
      .addCase(GetGlJeHeadersById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`
      .addCase(getJournalsByReferenceId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getJournalsByReferenceId.fulfilled, (state, action: PayloadAction<JournalsByReferenceIdModel[]>) => {
        state.loading = false;
        state.JournalsByReferenceId = action.payload;
         
       })
      .addCase(getJournalsByReferenceId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearJournalsById } = JournalsSlice.actions;

export default JournalsSlice.reducer;
