import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetItemConversionByItemId } from '../../../store/INV/MasterItems/ItemConversionSlice';
import { useTranslation } from 'react-i18next';

const useGetItemConversionByItemId = (ItemId: string) => {
  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();
  useEffect(() => {
    dispatch(GetItemConversionByItemId(ItemId));
  }, [ItemId, dispatch]);

  const { ItemConversionListByItem, loading, error } = useAppSelector((a) => a.ItemConversion);

  const OptionsUnitsByItemId = ItemConversionListByItem?.map((item) => ({
    value: item.id,
    label: `${i18n.language === 'en' ? item.uomName : item.uomName2}`,
  }));

  return { OptionsUnitsByItemId, ItemConversionListByItem, loading, error };
};

export default useGetItemConversionByItemId;
