import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import {
  updateApInvoices,
  getApInvoicesById,
  cleanApInvoicesById,
} from '../../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetPaymentTermsList';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import ApPaymentSchedules from './ApPaymentSchedules';
import ApInvoiceLines from './ApInvoiceLines';
import { AddApInvoiceSchema } from '../../../ValidationForm/validationSchema';
import { useEffect, useState } from 'react';
import useGetUnApplyPrepayments from '../../../../hooks/Finance/ApPayments/useGetUnApplyPrepayments';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetCodeCombination from '../../../../hooks/Finance/ChartAccountsDetails/useGetCodeCombination';
import { UnApplyPrepaymentsModels } from '../../../../store/Finance/AP/ApPayments/ApPaymentsModels';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import JournalsByReference from '../../Journals/JournalsByReference/JournalsByReference';

const Update = () => {
  const [show, setShow] = useState(false);
  const { IdInvoices } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading1, error: error1 } = useLookupCurrency();
  const { BranchOrgOptions, loading: loading2, error: error2 } = useGetBranchOrgWithPermission();
  const { treeSuppliersData, loading: loading3, error: error3 } = useGetSuppliersList();
  const { PaymentTermsList, loading: loading4, error: error4 } = useGetPaymentTermsList();
  const { PaymentMethodList, loading: loading5, error: error5 } = useGetPaymentMethod();
  const { GetCodeCombination, loading: loading6, error: error6 } = useGetCodeCombination();

  useEffect(() => {
    if (IdInvoices) {
      dispatch(getApInvoicesById(IdInvoices));
    }
    return () => {
      dispatch(cleanApInvoicesById());
    };
  }, [IdInvoices]);

  const { ApInvoicesById: data, loading, error } = useAppSelector((a) => a.ApInvoices);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      invoiceNum: data.invoiceNum ?? '',
      peopleId: data.peopleId ?? '',
      invoiceType: data.invoiceType ?? '',
      referenceType: data.referenceType ?? '',
      referenceId: data.referenceId ?? '',
      invoiceDate: data.invoiceDate?.split('T')[0] ?? '',
      organizationId: data.organizationId ?? '',
      paymentTermsId: data.paymentTermsId ?? '',
      paymentMethodId: data.paymentMethodId ?? '',
      currencyCode: data.currencyCode ?? '',
      currencyConversionRate: data.currencyConversionRate ?? '',
      includeVatFlag: data.includeVatFlag ?? false,
      postedFlag: data.postedFlag ?? false,

      apInvoiceLinesList:
        data.apInvoiceLinesList?.map((item) => ({
          id: item.id,
          lineNumber: item.lineNumber,
          lineType: item.lineType,
          invItemId: item.invItemId,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          currencyCode: data.currencyCode ?? '',
          currencyConversionRate: data.currencyConversionRate ?? '',
          purHeaderId: null,
          purLineId: null,
          accountId: item.accountId,
          costCenterId: item.costCenterId,
          branchId: item.glBrunchId,
          companyId: item.glCompanyId,
          // branchId: GetGlBrancheId(data.organizationId),
          // companyId: GlCompanyId,
          //
          codeCombination: GetCodeCombination(
            item.glCompanyId,
            item.glBrunchId,
            item.accountId,
            item.costCenterId
          ),
        })) ?? [],
      apPaymentSchedulesList:
        data.apPaymentSchedulesList?.map((item) => ({
          id: item.id,
          amount: item.amount,
          dueDate: item.dueDate?.split('T')[0],
          paymentId: item.paymentId,
          invoicePaymentId: item.invoicePaymentId,

          paymentCode: item.codePayment, // رقم الدفعة
          paymentDate: new Date(item.paymentDate).toLocaleDateString(), // تريخ الدقعة
          paymentAmount: item.amountPayment,
          // ~~ الحقول ادناة في حال كانت الدفعة عادية (غير مقدمة) وهي للعرض فقط ~~~~~~~
          AmountPaid: item.paymentId === null ? item.amountPayment : 0, // المبلغ المدفوع
          RemainingAmount: item.paymentId === null ? item.amount - item.amountPayment : 0, // المبلغ المتبقي
        })) ?? [],
    },

    enableReinitialize: true,
    validationSchema: AddApInvoiceSchema(t),
    onSubmit: (values) => {
      const { apInvoiceLinesList, apPaymentSchedulesList } = values;

      if (apInvoiceLinesList?.length === 0) {
        SwalAlert({ text: 'لا يمكن متابعة العملية بدون إضافة بنود للفاتورة، يرجى إضافة البنود.' });
        formik.setSubmitting(false);
        return;
      }

      const totalInvoiceLines = apInvoiceLinesList?.reduce(
        (sum: number, item: any) => sum + item.total,
        0
      );
      const totalPaymentSchedules = apPaymentSchedulesList?.reduce(
        (sum: number, payment: any) => sum + Number(payment.amount),
        0
      );

      if (apPaymentSchedulesList?.length > 0 && totalInvoiceLines !== totalPaymentSchedules) {
        SwalAlert({ text: 'لا يتطابق مجموع الفاتورة مع مجموع الجدولة. يرجى المراجعة.' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        updateApInvoices({
          id: data.id,

          invoiceNum: values.invoiceNum,
          invoiceDate: values.invoiceDate,
          paymentTermsId: values.paymentTermsId,
          paymentMethodId: values.paymentMethodId,

          apInvoiceLinesList: values.apInvoiceLinesList?.map((line: any, idx) => ({
            id: line.id,

            lineNumber: ++idx,
            lineType: line.lineType,
            invItemId: line.invItemId,
            uom: line.uom || null,
            description: line.description,
            price: line.price || 0,
            qty: line.qty || 0,
            discountRate: line.discountRate || 0,
            discountAmount: line.discountAmount || 0,
            vatRate: line.vatRate || 0,
            total: line.total,
            vatAmount: line.vatAmount || 0,
            accountId: line.accountId,
            costCenterId: line.costCenterId,
          })),
          apPaymentSchedulesList:
            values.apPaymentSchedulesList?.map((item: any) => ({
              id: item.id,
              amount: item.amount,
              dueDate: item.dueDate,
              paymentId: item.paymentId,

              invoicePaymentId: item.invoicePaymentId,
            })) || null,
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          console.log(res);

          if (res.succeeded) {
            CustomAlert({ action: 'Edit' });
          } else {
            CustomAlert({ action: 'Error', msg: res.message });
            formik.setSubmitting(false);
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  const { hasPrepayments, totalPrepaymentAmount, UnApplyPrepaymentsList } =
    useGetUnApplyPrepayments({
      PeopleId: values.peopleId,
    });

  // حساب المبلغ المتبقي للدفعة المقدمة إن وجدت
  useEffect(() => {
    const updatedPayments = formik.values.apPaymentSchedulesList.map((item) => {
      const paymentAmountUnApply = item.paymentId
        ? UnApplyPrepaymentsList?.find((f) => f.id === item.paymentId)?.amountUnApply ?? 0
        : 0;

      return {
        ...item,
        // PaymentAmountUnApply: paymentAmountUnApply,
        PaymentAmountUnApply: !data.postedFlag
          ? paymentAmountUnApply + (item.amount ?? 0)
          : paymentAmountUnApply,
        // يساوي المبلغ المبتقي + المبلغ المدخل سابقا
        // واذا كان قد رحلت الفاتورة يساوي المبلغ المتبقي فقط نظرا لأنه لا يستطيع التعديل
      };
    });

    formik.setFieldValue('apPaymentSchedulesList', updatedPayments);
  }, [UnApplyPrepaymentsList]);

  // تحديث القيمة المتبقية من قائمة الدفعات المتبقية ان وجدت
  const [unApplyPrepayments, setUnApplyPrepayments] = useState<UnApplyPrepaymentsModels[]>([]);
  useEffect(() => {
    const updatedPrepayments = UnApplyPrepaymentsList?.map((prepayment) => {
      const matchingItem = data?.apPaymentSchedulesList?.find(
        (item) => item.paymentId === prepayment.id
      );

      if (matchingItem) {
        const paymentAmountUnApply = matchingItem.paymentId ? prepayment.amountUnApply ?? 0 : 0;

        // تعديل القيمة بناءً على paymentAmountUnApply و amount
        return {
          ...prepayment,
          amountUnApply: paymentAmountUnApply + (matchingItem.amount ?? 0),
        };
      }

      return prepayment;
    });

    // تعيين القائمة المعدلة
    setUnApplyPrepayments(updatedPrepayments);
  }, [UnApplyPrepaymentsList, data?.apPaymentSchedulesList]);

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'PaymentInvoices',
      link: '/Finance/Ap/ApInvoices',
    },
    {
      name: 'InvoiceDetails',
      link: null,
    },
  ];
  //#endregion

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="InvoiceDetails" />
        {data.postedFlag && (
          <div className="ms-auto">
            <Link to="" className="btn me-1 btn-theme" onClick={() => setShow(true)}>
              <i className="fa fa-info-circle fa-fw me-1"></i>
              تفاصيـل القيد
            </Link>
          </div>
        )}
      </div>

      <Card>
        <Loading
          loading={
            loading ||
            loading1 ||
            loading2 ||
            loading3 ||
            loading4 ||
            loading5 ||
            loading5 ||
            loading6
          }
          error={error || error1 || error2 || error3 || error4 || error5 || error6}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('InvoiceDate')}</label>
                    <Form.Control
                      className="form-control "
                      type="date"
                      name="invoiceDate"
                      onChange={formikhandleChange}
                      value={values.invoiceDate || ''}
                      // isInvalid={!!(touched.invoiceDate && errors.invoiceDate)}
                    />

                    <div className="text-danger small">
                      {touched.invoiceDate && errors.invoiceDate}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('InvoiceType')} </label>
                    <Form.Select
                      disabled
                      className="form-select"
                      name="invoiceType"
                      onChange={formikhandleChange}
                      value={values.invoiceType || ''}
                      // isInvalid={!!(touched.invoiceType && errors.invoiceType)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      <option value="Standard">
                        {i18n.language === 'en' ? 'Standard' : 'قياسي'}
                      </option>
                      <option value="DebitMemo">
                        {i18n.language === 'en' ? 'Debit Memo' : 'إشعار مدين'}
                      </option>
                      {/* <option value="Recurring">
                        {i18n.language === 'en' ? 'Recurring' : 'دوري'}
                      </option> */}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.invoiceType && errors.invoiceType}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Branch')}</label>

                    <Select
                      isDisabled
                      classNamePrefix="react-select"
                      isLoading={false}
                      isSearchable={true}
                      isClearable
                      options={BranchOrgOptions}
                      value={BranchOrgOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      // onChange={(option: any) => handleChangeOrganizationId(option)}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.organizationId && errors.organizationId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Supplier')}</label>
                    <TreeSelect
                      disabled
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger small">{touched.peopleId && errors.peopleId}</div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group flex-nowrap mb-3">
                    <label className="form-label">{t('SupplierInvoiceNumber')}</label>

                    <div className="input-group flex-nowrap">
                      <Form.Control
                        autoComplete="off"
                        className="form-control  text-center"
                        type="text"
                        name="invoiceNum"
                        onChange={formikhandleChange}
                        value={values.invoiceNum || ''}
                        // isInvalid={!!(touched.invoiceNum && errors.invoiceNum)}
                      />
                      {/* <div className="text-danger small">{formik.errors.invoiceNum}</div> */}
                    </div>
                    <div className="text-danger small">
                      {touched.invoiceNum && errors.invoiceNum}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentTerms')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentTermsId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentTermsId || ''}
                      // isInvalid={!!(formik.errors.paymentTermsId && formik.touched.paymentTermsId)}
                    >
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentTermsId && errors.paymentTermsId}
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId || ''}
                      isInvalid={
                        !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      }>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentMethodId && errors.paymentMethodId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      disabled
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.currencyCode && errors.currencyCode}
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('CurrencyConversionRate')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="text"
                      autoComplete="off"
                      readOnly
                      // name="currencyConversionRate"
                      // onChange={formikhandleChange}
                      value={values.currencyConversionRate || ''}
                    />
                    <div className="text-danger small">
                      {touched.currencyConversionRate && errors.currencyConversionRate}
                    </div>
                  </div>
                </div>
              </div>

              <div className="alert alert-primary" hidden={!hasPrepayments}>
                <i className="fas fa-lg fa-exclamation-circle me-2"></i>
                <strong> {t('Reminder')}</strong> {t('PrepaymentMessage')}
                <span className="ms-2 text-success  fw-bold fs-5">{totalPrepaymentAmount}</span>
              </div>

              <CardFooter className="mt-2 mb-2">
                <ul className="nav nav-tabs nav-tabs-v2 p-0">
                  <li className="nav-item me-3">
                    <Link to="#apInvoiceLines" className="nav-link active" data-bs-toggle="tab">
                      <i className="fas fa-file-invoice me-2 text-theme"></i>
                      {t('InvoiceLines')}
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link to="#apPaymentSchedules" className="nav-link" data-bs-toggle="tab">
                      <i className="fas fa-calendar-alt me-2 text-theme"></i>
                      {t('InvoiceSchedule')}
                    </Link>
                  </li>
                </ul>

                <div className="tab-content py-4 px-0">
                  <div className="tab-pane fade show active" id="apInvoiceLines">
                    <ApInvoiceLines formik={formik} />
                  </div>
                  <div className="tab-pane fade" id="apPaymentSchedules">
                    <ApPaymentSchedules
                      formik={formik}
                      UnApplyPrepaymentsList={unApplyPrepayments}
                    />
                  </div>
                </div>
                {formik.errors.apInvoiceLinesList?.length && (
                  <div className="text-danger"> {t('InvoiceLinesRequired')}</div>
                )}
                {formik.errors.apPaymentSchedulesList?.length && (
                  <div className="text-danger"> {t('InvoiceScheduleRequired')}</div>
                )}
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fa fa-save fa-fw me-1"></i>
                  )}
                  {t('Save')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/Finance/Ap/ApInvoices', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal show={show} setShow={setShow} size="xl">
        {IdInvoices && <JournalsByReference referenceId={IdInvoices} />}
      </NeatixModal>
    </>
  );
};

export default Update;
