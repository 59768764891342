import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { useTranslation } from 'react-i18next';
import { GetCurrenciesList } from '../../store/NTX/Currency/currencySlice';

const useLookupCurrency = () => {
  const dispatch = useDispatch();

  // const {} = useTranslation();
  useEffect(() => {
    if (CurrencyList?.length === 0) {
      dispatch(GetCurrenciesList());
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch]);

  const { CurrencyList, loading, error } = useSelector((state) => state.Currency);

  var Currency = CurrencyList.filter((f) => f.enabledFlag);

  // const CurrencyList = Currency.map((m) => ({
  //   id: m.id,
  //   currencyCode: m.currencyCode,
  //   description: m,
  //   enabledFlag: '',
  // }));

  return { Currency , CurrencyList,  loading, error };
};

export default useLookupCurrency;
