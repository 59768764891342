import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import useGetInvIssueList from '../../../../hooks/INV/InvIssue/useGetInvIssueList';
import Loading from '../../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../../components/Modal/NeatixModal';
// import CreateAccount from './CreateAccount';
import useGetTransactionTypesIssueList from '../../../../hooks/INV/InvIssue/useGetTransactionTypesIssueList';
import useMaterialTransactions from '../../../../hooks/INV/InvIssue/useMaterialTransactions';
import ServerPagination from '../../../../components/Shared/Pagination/ServerPagination';

const Index = () => {
  // const [Show, setShow] = useState(false);
  const [transTypeId, setTransTypeId] = useState<any>();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const [show, setShow] = useState(false);

  const { OptionsTransactionTypes, loading: loading2 } = useGetTransactionTypesIssueList();
  // const { InvIssueList, error, loading } = useGetInvIssueList();

  const { data, totalItems, pageIndex, pageSize, handlePageChange, loading , error} =
    useMaterialTransactions();

  // فلترة البيانات بناءً على البحث، ونوع الحركة
  const filteredSearch = data?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();

    const matchesSearch = item.code?.toLowerCase().includes(searchLowerCase);

    const transType = transTypeId ? item.transactionTypeId === transTypeId : true;

    return matchesSearch && transType;
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Update Cost',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Update Cost" />

        <div className="ms-auto">
          <Link to="AddUpdateCost" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('Add UpdateCost')}
          </Link>{' '}
        </div>
      </div>

      <Card>
        {/* <Loading loading={loading} error={error}> */}
          <CardBody>
            <div className="row mb-2">
              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('Search')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text  fw-bold">{t('نوع الحركـة')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className={`w-100 `}
                    isLoading={loading2}
                    isSearchable={true}
                    isClearable
                    options={OptionsTransactionTypes}
                    onChange={(option) => {
                      setTransTypeId(option?.transTypeId);
                      setCurrentPage(1);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('الرقم')}</th>

                    <th scope="col">{t('التاريخ')}</th>
                    <th scope="col">{t('نوع الحركة')}</th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index: number) => (
                    <tr key={++index}>
                      <td className="align-middle">{item.code}</td>
                      <td className="align-middle">{item.transactionDate?.split('T')[0]}</td>
                      <td className="align-middle">{item.transactionTypeName}</td>

                      <td className="align-middle text-end">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            onClick={() => Navigate(`${item.id}/UpdateInvIssue`)} //Departments/:id/Edit
                          >
                            <i className="far fa-lg me-2 fa-fw fa-edit"></i>
                            {t('Button.Edit')}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            /> */}

            <ServerPagination
              currentPage={pageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              loading={loading}
            />
          </CardBody>
        {/* </Loading> */}
      </Card>

      <NeatixModal show={show} setShow={setShow} size="md" showHeader={false} bodyClassName="p-0">
        <Card>
          <CardBody>{/* <CreateAccount /> */}</CardBody>
        </Card>
      </NeatixModal>
    </div>
  );
};

export default Index;
