import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Card } from '../../../../../components/card/card';
import Loading from '../../../../../components/Shared/Loader/Loading';
import { CardBody } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store/hooks';
import useGetItemsList from '../../../../../hooks/INV/MasterItems/useGetItemsList';
// import useGetCategoriesList from '../../../../../hooks/INV/useGetCategoriesList';
import { Transfer, ConfigProvider } from 'antd';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { assignmentItems } from '../../../../../store/INV/AssignmentItems/assignmentItemsSlice';
import useGetItemsListByInventoryId from '../../../../../hooks/INV/MasterItems/useGetItemsListByInventoryId';
import i18n from '../../../../../i18n';
import ItemsNotInInventory from './ItemsNotInInventory';
import ItemsInInventory from './ItemsInInventory';

const AssignmentItems = () => {
  const { inventoryId } = useParams<{ inventoryId: string }>();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useAppDispatch();

  // حالات لتخزين القوائم
  const [ItemsByInv, setItemsByInv] = useState<any[]>([]);
  const [ItemsNotInInv, setItemsNotInInv] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  // جلب الأصناف المتوفرة في المخزن
  const { MasterItemsListByInventoryId } = useGetItemsListByInventoryId(inventoryId);
  const { MasterItemsList } = useGetItemsList();

  // عند جلب البيانات، نقوم بتحديث القوائم
  useEffect(() => {
    if (MasterItemsList && MasterItemsListByInventoryId) {
      setItemsByInv(MasterItemsListByInventoryId);
      const notInInv = MasterItemsList?.filter(
        (f) => !MasterItemsListByInventoryId?.map((inv) => inv.id).includes(f.id)
      );
      setItemsNotInInv(notInInv);
    }
  }, [MasterItemsList, MasterItemsListByInventoryId]);

  // دالة لنقل الأصناف إلى المخزن
  const moveToInventory = () => {
    const updatedItemsByInv = [...ItemsByInv, ...selectedItems];
    const updatedItemsNotInInv = ItemsNotInInv?.filter(
      (item) => !selectedItems?.map((sel) => sel.id).includes(item.id)
    );

    // تحديث القوائم
    setItemsByInv(updatedItemsByInv);
    setItemsNotInInv(updatedItemsNotInInv);
    setSelectedItems([]);

    // هنا يمكنك إضافة منطق لإرسال البيانات إلى الخادم
    console.log('الأصناف التي تم إضافتها إلى المخزن:', updatedItemsByInv);
  };

  // دالة لإخراج الأصناف من المخزن
  const removeFromInventory = () => {
    const updatedItemsByInv = ItemsByInv?.filter(
      (item) => !selectedItems?.map((sel) => sel.id).includes(item.id)
    );
    const updatedItemsNotInInv = [...ItemsNotInInv, ...selectedItems];

    // تحديث القوائم
    setItemsByInv(updatedItemsByInv);
    setItemsNotInInv(updatedItemsNotInInv);
    setSelectedItems([]);

    // هنا يمكنك إضافة منطق لإرسال البيانات إلى الخادم
    console.log('الأصناف التي تم إخراجها من المخزن:', updatedItemsByInv);
  };

  const onSubmit = () => {
    setIsSubmitting(true);

    let body = {
      itemIds: ItemsByInv?.map((m) => m.id),
      inventoryId: inventoryId,
    };

    dispatch(assignmentItems(body))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Add' });
      })
      .catch((error: any) => {
        setIsSubmitting(false);

        CustomAlert({ action: 'Error' });
      });
  };

  const location = useLocation();
  const { name } = location?.state;
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'المخازن',
      link: '/INV/InventorySettings/Inventory',
    },
    {
      name: 'تعيين الأصناف',
      link: null,
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('تعيين الأصناف ')} subtitle={name} />

        <div className="ms-auto">
          <button
            disabled={isSubmitting}
            onClick={() => onSubmit()}
            type="submit"
            className="btn me-1 btn-lg btn-theme rounded-1"
            id="SaveSubmit">
            {isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              // <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
              <i className="fa fa-save btn-lg fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
        </div>
      </div>
      <>
        <div className="row gx-3">
          <ItemsNotInInventory
            itemsNotInInv={ItemsNotInInv}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            moveToInventory={moveToInventory}
          />
          <ItemsInInventory
            itemsByInv={ItemsByInv}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            removeFromInventory={removeFromInventory}
          />
        </div>
      </>
    </>
  );
};

export default AssignmentItems;

// <div className="text-center">
// <button
//   disabled={isSubmitting}
//   onClick={() => onSubmit()}
//   type="submit"
//   className="btn me-1 btn-lg btn-theme mb-1"
//   id="SaveSubmit">
//   {isSubmitting ? (
//     <div className="spinner-border spinner-border-sm me-2"></div>
//   ) : (
//     // <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
//     <i className="fa fa-save btn-lg fa-fw me-1"></i>
//   )}
//   {t('Save')}
// </button>
// <Link
//   to="/INV/InventorySettings/Inventory"
//   replace
//   className="btn me-1 btn-lg btn-default mb-1">
//   <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
// </Link>
// </div>
