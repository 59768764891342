import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { addApInvoices } from '../../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetPaymentTermsList';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import ApPaymentSchedules from './ApPaymentSchedules';
import ApInvoiceLines from './ApInvoiceLines';
import { AddApInvoiceSchema } from '../../../ValidationForm/validationSchema';
import useConversionRateByOrganization from '../../../../hooks/Finance/DailyRates/useConversionRateByOrganization';
import { useState } from 'react';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import useGetUnApplyPrepayments from '../../../../hooks/Finance/ApPayments/useGetUnApplyPrepayments';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';

const Add = () => {
  const [showModal, setShowModal] = useState(true);
  const [peopleId, setPeopleId] = useState<any>(null);
  const [organizationId, setOrganizationId] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const { Currency, loading, error } = useLookupCurrency();
  const { BranchOrgOptions, loading: loading1, error: error1 } = useGetBranchOrgWithPermission();
  const { treeSuppliersData, loading: loading2, error: error2 } = useGetSuppliersList();
  const { PaymentTermsList, loading: loading3, error: error3 } = useGetPaymentTermsList();
  const { PaymentMethodList, loading: loading4, error: error4 } = useGetPaymentMethod();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      invoiceNum: '',
      peopleId: '',
      invoiceType: '',
      referenceType: '', // نوع المرجع - حسب ما يأتي
      referenceId: '',
      invoiceDate: new Date().toISOString().split('T')[0], //'',
      organizationId: '',
      paymentTermsId: '',
      paymentMethodId: '',
      currencyCode: 'SR',
      currencyConversionRate: 1,
      includeVatFlag: true,
      apInvoiceLinesList: [
        // {
        //   lineNumber: '',
        //   lineType: 'Item',
        //   invItemId: null,
        //   uom: null,
        //   description: '',
        //   price: 0,
        //   qty: 0,
        //   discountRate: 0,
        //   discountAmount: 0,
        //   vatRate: 0,
        //   total: 0,
        //   vatAmount: 0,
        //   accountId: null,
        //   costCenterId: null,
        //   currencyCode: data.currencyCode ?? '',
        //   currencyConversionRate: data.currencyConversionRate ?? '',
        //   purHeaderId: null,
        //   purLineId: null,
        //   codeCombination: '',
        // },
      ],
      apPaymentSchedulesList: [],
    },

    enableReinitialize: true,
    validationSchema: AddApInvoiceSchema(t),
    onSubmit: (values) => {
      const { apInvoiceLinesList, apPaymentSchedulesList } = values;

      if (apInvoiceLinesList?.length === 0) {
        SwalAlert({ text: 'لا يمكن متابعة العملية بدون إضافة بنود للفاتورة، يرجى إضافة البنود.' });
        formik.setSubmitting(false);
        return;
      }

      const totalInvoiceLines = apInvoiceLinesList?.reduce(
        (sum: number, item: any) => sum + item.total,
        0
      );
      const totalPaymentSchedules = apPaymentSchedulesList?.reduce(
        (sum: number, payment: any) => sum + Number(payment.amount),
        0
      );

      if (apPaymentSchedulesList?.length > 0 && totalInvoiceLines !== totalPaymentSchedules) {
        SwalAlert({ text: 'لا يتطابق مجموع الفاتورة مع مجموع الجدولة. يرجى المراجعة.' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addApInvoices({
          invoiceNum: values.invoiceNum,
          peopleId: values.peopleId,
          invoiceType: values.invoiceType,
          referenceType: values.referenceType,
          referenceId: values.referenceId || null,
          invoiceDate: values.invoiceDate,
          organizationId: values.organizationId,
          paymentTermsId: values.paymentTermsId,
          paymentMethodId: values.paymentMethodId,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 1,
          apInvoiceLinesList: values.apInvoiceLinesList?.map((line: any, idx) => ({
            // lineNumber: line.lineNumber ,
            lineNumber: ++idx,
            lineType: line.lineType,
            invItemId: line.invItemId,
            uom: line.uom || null,
            description: line.description,
            price: line.price || 0,
            qty: line.qty || 0,
            discountRate: line.discountRate || 0,
            discountAmount: line.discountAmount || 0,
            vatRate: line.vatRate || 0,
            total: line.total,
            vatAmount: line.vatAmount || 0,
            accountId: line.accountId,
            costCenterId: line.costCenterId,
            currencyCode: line.currencyCode,
            currencyConversionRate: line.currencyConversionRate || 1,
            purHeaderId: line.purHeaderId,
            purLineId: line.purLineId,
          })),
          apPaymentSchedulesList:
            values.apPaymentSchedulesList?.map((item: any) => ({
              amount: item.amount,
              dueDate: item.dueDate,
              paymentId: item.paymentId,
            })) || null,
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          // console.log(res);

          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const {
    hasPrepayments,
    totalPrepaymentAmount,
    UnApplyPrepaymentsList,
    loading: loading5,
    error: error5,
  } = useGetUnApplyPrepayments({
    PeopleId: values.peopleId,
  });

  const conversionRate = useConversionRateByOrganization({
    organizationId: values.organizationId,
    currencyCode: values.currencyCode,
    date: values.invoiceDate,
  });

  const handleSubmitModal = async () => {
    if (peopleId && organizationId && values.invoiceType) {
      setShowModal(false);
      await formik.setFieldValue('peopleId', peopleId);
      await formik.setFieldValue('organizationId', organizationId);
    } else {
      ToastSwal({ title: 'يرجى اختيار الفرع والمورد', timer: 1000 });
    }
  };

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'PaymentInvoices',
      link: '/Finance/Ap/ApInvoices',
    },
    {
      name: 'AddNewInvoice',
      link: null,
    },
  ];
  //#endregion

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="AddNewInvoice" />
      </div>

      <Card>
        <Loading
          loading={loading || loading1 || loading2 || loading3 || loading4}
          error={error || error1 || error2 || error3 || error4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Date')}</label>
                    <Form.Control
                      className="form-control "
                      type="date"
                      name="invoiceDate"
                      onChange={formikhandleChange}
                      value={values.invoiceDate || ''}
                      // isInvalid={!!(touched.invoiceDate && errors.invoiceDate)}
                    />

                    <div className="text-danger small">
                      {touched.invoiceDate && errors.invoiceDate}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('InvoiceType')} </label>
                    <Form.Select
                      disabled
                      className="form-select"
                      name="invoiceType"
                      onChange={formikhandleChange}
                      value={values.invoiceType || ''}
                      // isInvalid={!!(touched.invoiceType && errors.invoiceType)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      <option value="Standard">
                        {i18n.language === 'en' ? 'Standard' : 'قياسي'}
                      </option>
                      <option value="DebitMemo">
                        {i18n.language === 'en' ? 'Debit Memo' : 'إشعار مدين'}
                      </option>
                      {/* <option value="Recurring">
                        {i18n.language === 'en' ? 'Recurring' : 'دوري'}
                      </option> */}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.invoiceType && errors.invoiceType}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Branch')}</label>

                    <Select
                      isDisabled
                      classNamePrefix="react-select"
                      isLoading={loading1}
                      isSearchable={true}
                      isClearable
                      options={BranchOrgOptions}
                      value={BranchOrgOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      // onChange={(option: any) => handleChangeOrganizationId(option)}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.organizationId && errors.organizationId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Supplier')}</label>
                    <TreeSelect
                      loading={loading2}
                      disabled
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger small">{touched.peopleId && errors.peopleId}</div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group flex-nowrap mb-3">
                    <label className="form-label">{t('SupplierInvoiceNumber')}</label>

                    <div className="input-group flex-nowrap">
                      <Form.Control
                        autoComplete="off"
                        className="form-control  text-center"
                        type="text"
                        name="invoiceNum"
                        onChange={formikhandleChange}
                        value={values.invoiceNum || ''}
                        // isInvalid={!!(touched.invoiceNum && errors.invoiceNum)}
                      />
                      {/* <div className="text-danger small">{formik.errors.invoiceNum}</div> */}
                    </div>
                    <div className="text-danger small">
                      {touched.invoiceNum && errors.invoiceNum}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentTerms')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentTermsId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentTermsId || ''}
                      // isInvalid={!!(formik.errors.paymentTermsId && formik.touched.paymentTermsId)}
                    >
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentTermsId && errors.paymentTermsId}
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId || ''}
                      isInvalid={
                        !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      }>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentMethodId && errors.paymentMethodId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.currencyCode && errors.currencyCode}
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('CurrencyConversionRate')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="text"
                      autoComplete="off"
                      readOnly
                      // name="currencyConversionRate"
                      // onChange={formikhandleChange}
                      // value={values.currencyConversionRate || ''}
                      value={conversionRate}
                    />
                    <div className="text-danger small">
                      {touched.currencyConversionRate && errors.currencyConversionRate}
                    </div>
                  </div>
                </div>
              </div>

              <Loading loading={loading5} error={error5} Type="Dots">
                <div className="alert alert-primary" hidden={!hasPrepayments}>
                  <i className="fas fa-exclamation-circle me-2"></i>
                  <strong> {t('Reminder')}</strong> {t('PrepaymentMessage')}
                  <span className="ms-2 text-success  fw-bold fs-5">{totalPrepaymentAmount}</span>
                </div>
              </Loading>

              <CardFooter className="mt-2 mb-2">
                <ul className="nav nav-tabs nav-tabs-v2 p-0">
                  <li className="nav-item me-3">
                    <Link to="#apInvoiceLines" className="nav-link active" data-bs-toggle="tab">
                      <i className="fas fa-file-invoice me-2 text-theme"></i>
                      {t('InvoiceLines')}
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link to="#apPaymentSchedules" className="nav-link" data-bs-toggle="tab">
                      <i className="fas fa-calendar-alt me-2 text-theme"></i>
                      {t('InvoiceSchedule')}
                    </Link>
                  </li>
                </ul>

                <div className="tab-content py-4 px-0">
                  <div className="tab-pane fade show active" id="apInvoiceLines">
                    <ApInvoiceLines formik={formik} />
                  </div>
                  <div className="tab-pane fade" id="apPaymentSchedules">
                    <ApPaymentSchedules
                      formik={formik}
                      UnApplyPrepaymentsList={UnApplyPrepaymentsList}
                    />
                  </div>
                </div>
                {formik.errors.apInvoiceLinesList?.length && (
                  <div className="text-danger"> {t('InvoiceLinesRequired')} </div>
                )}
                {formik.errors.apPaymentSchedulesList?.length && (
                  <div className="text-danger"> {t('InvoiceScheduleRequired')} </div>
                )}
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/Finance/Ap/ApInvoices', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        size="md"
        bodyClassName="p-0"
        showHeader={false}
        keyboard={false}
        backdrop={true}>
        <Card>
          <Loading loading={loading2 || loading1} error={error1 || error2} Type="Dots">
            <CardBody>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Branch')}</label>
                    <Select
                      classNamePrefix="react-select"
                      // isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchOrgOptions}
                      value={BranchOrgOptions?.find((f: any) => f.value === organizationId)}
                      onChange={(option: any) => {
                        setOrganizationId(option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Supplier')}</label>
                    <TreeSelect
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={peopleId}
                      onChange={(option) => {
                        setPeopleId(option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      // treeNodeFilterProp="title"
                      getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
                    />
                    <div className="text-danger small">{touched.peopleId && errors.peopleId}</div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('InvoiceType')} </label>
                    <Form.Select
                      className="form-select"
                      name="invoiceType"
                      onChange={formikhandleChange}
                      value={values.invoiceType || ''}
                      // isInvalid={!!(touched.invoiceType && errors.invoiceType)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      <option value="Standard">
                        {i18n.language === 'en' ? 'Standard' : 'قياسي'}
                      </option>
                      <option value="DebitMemo">
                        {i18n.language === 'en' ? 'Debit Memo' : 'إشعار مدين'}
                      </option>
                      {/* <option value="Recurring">
                        {i18n.language === 'en' ? 'Recurring' : 'دوري'}
                      </option> */}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.invoiceType && errors.invoiceType}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-lg btn-theme  w-100 rounded-0"
                    onClick={handleSubmitModal}>
                    <i className="fas fa-lg fa-fw me-2 fa-check-circle"></i>
                    {t('Choose')}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-lg  btn-default  w-100 rounded-0"
                    onClick={() => navigate('/Finance/Ap/ApInvoices', { replace: true })}>
                    <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              </div>
            </CardBody>
          </Loading>
        </Card>
      </NeatixModal>
    </>
  );
};

export default Add;
