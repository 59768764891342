import React, { useState } from 'react';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import AddJeCategory from './AddJeCategory';
import EditJeCategory from './EditJeCategory';
import useGetGlJeCategoryList from '../../../../hooks/Finance/useGetGlJeCategoryList';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

const Index = () => {
  const [ID, setID] = useState<string>('');

  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t } = useTranslation();

  const { GlJeCategoryList, loading, error } = useGetGlJeCategoryList();

  const filteredSearch = GlJeCategoryList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'التصنيفات',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="التصنيفات" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddDailyRates"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            اضافة
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-1">
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">{t('Search')}</span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text" 
autoComplete='off'
                            className="form-control ps-35px"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('LocalName')}</th>
                      <th scope="col">{t('GlobalName')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle"> {item.name2} </td>
                        <td className="align-middle"> {item.name} </td>

                        <td className="align-middle text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#modalEditDailyRates"
                            onClick={() => setID(item.id)}>
                            <i className="far fa-lg fa-fw fa-edit me-1"></i>
                            {t('Button.Edit')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title="اضافة" id="modalAddDailyRates">
        <AddJeCategory />
      </MedModalComponent>

      <MedModalComponent title="تعديل" id="modalEditDailyRates">
        {ID && <EditJeCategory ID={ID} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
