import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getApBankAccountsList } from '../../../store/Finance/AP/ApBankAccounts/ApBankAccountsSlice';
import { useTranslation } from 'react-i18next';

const useGetBankAccountsList = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (ApBankAccountsList?.length === 0) {
      dispatch(getApBankAccountsList());
    }
  }, []);
  const { ApBankAccountsList, loading, error } = useAppSelector((state) => state.ApBankAccounts);

  const BankAccountsList = ApBankAccountsList;
 

  const OptionsBankAccounts =
    BankAccountsList &&
    BankAccountsList?.map((item) => ({
      value: item.id,
      label: i18n.language === 'ar' ? item.name2 : item.name,
    }));

  const grouped = BankAccountsList?.reduce((groups: any, bank: any) => {
    const bankGroup = groups?.find((group: any) => group.label === bank.ntxBankName);
    if (bankGroup) {
      // If the bank group already exists, add the branch to it
      bankGroup?.options?.push({ value: bank.id, label: bank.name });
    } else {
      // If the bank group does not exist, create a new group
      groups?.push({
        ntxBankId: bank.ntxBankId,
        label: bank.ntxBankName,
        options: [{ value: bank.id, label: bank.name }],
      });
    }
    return groups;
  }, []);

  
  // ترتيب القائمة لتأتي الصناديق النقدية في البداية
  const groupedOptions = [
    grouped?.find((f: any) => f.ntxBankId === 'f700cf22-af84-4ca2-ac8c-c3bef5b88325'),
    ...grouped?.filter((f: any) => f.ntxBankId !== 'f700cf22-af84-4ca2-ac8c-c3bef5b88325'),
  ];

  return { BankAccountsList, OptionsBankAccounts, groupedOptions, loading, error };
};

export default useGetBankAccountsList;
