import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { GetApExpenseCategoryItemsByExpenseCategoryId } from '../../../store/Finance/Expense/ApExpenseCategoryItems/ExpenseCategoryItemsSlice';

type Props = {
  expenseCategoryId: string;
};

const useGetExpenseCategoryItemsList = ({ expenseCategoryId }: Props) => {
  const dispatch = useAppDispatch();

  const { i18n, t } = useTranslation();
  useEffect(() => {
    dispatch(GetApExpenseCategoryItemsByExpenseCategoryId(expenseCategoryId));
  }, [dispatch, expenseCategoryId]);

  const { ApExpenseCategoryItemsList, loading, error } = useAppSelector(
    (state) => state.ApExpenseCategoryItems
  );

  const OptionsExpenseCategoryItems =
    ApExpenseCategoryItemsList &&
    ApExpenseCategoryItemsList?.map((item) => ({
      value: item.id,
      label: i18n.language === 'ar' ? item.name2 : item.name,
    }));

  return { ApExpenseCategoryItemsList, OptionsExpenseCategoryItems, loading, error };
};

export default useGetExpenseCategoryItemsList;
