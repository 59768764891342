import { useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Get_DEPRECIATION_METHOD } from '../../store/Lookup/lookupSlice';
// use-Get_DEPRECIATION_METHOD
const useGetDepreciationMethod = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (DEPRECIATION_METHOD.length === 0) {
      dispatch(Get_DEPRECIATION_METHOD());
    }
  }, []);

  const { DEPRECIATION_METHOD, loading, error } = useSelector((state) => state.Lookup);

  return { DEPRECIATION_METHOD, loading, error };
};

export default useGetDepreciationMethod;
