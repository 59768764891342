import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import ModalComponent from '../../../../components/Modal/ModalComponent';

import PeoplesContacts from '../../../NTX/Peoples/PeoplesContacts/PeoplesContacts';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import Loading from '../../../../components/Shared/Loader/Loading';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import NtxTable from '../../../../components/DataTable/NtxTable';

const Index = () => {
  const [peopleData, setPeopleData] = useState<any>('');
  // const [search, setSearch] = useState('');
  // const [currentPage, setCurrentPage] = useState(1);
  // const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { CustomersList, loading, error } = useGetCustomersList();

  // const filteredSearch = CustomersList?.slice()
  //   ?.filter((f) => {
  //     const searchLowerCase = search.toLowerCase();
  //     return (
  //       f?.name?.toLowerCase()?.includes(searchLowerCase) ||
  //       f?.name2?.toLowerCase()?.toString()?.includes(searchLowerCase)
  //     );
  //   })
  //   ?.filter((f) => f.parentId === null);

  // const indexOfLastData = currentPage * dataPerPage;
  // const indexOfFirstData = indexOfLastData - dataPerPage;
  // const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const columns = [
    { title: 'اسم المورد', key: 'name' },
    { title: 'رقم المورد', key: 'num' },
    { title: 'الأسم التجاري', key: 'name2' },
    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData =
    CustomersList?.map((item) => {
      let rowClass;

      return {
        name: i18n.language === 'en' ? item.name : item.name2,
        num: '000',
        name2: item.tradingName,

        action: (
          <>
            <Dropdown as={ButtonGroup}>
              <Button
                className="btn-theme"
                onClick={() =>
                  navigate(`${item.id}/UpdateCustomers`, {
                    state: {
                      name: i18n.language === 'en' ? item.name : item.name2,
                    },
                  })
                }>
                تفاصيـل العميـل
              </Button>

              <Dropdown.Toggle className=" btn-theme ms-1" split />

              <Dropdown.Menu>
                <Dropdown.Item
                  className="text-start"
                  onClick={() =>
                    navigate(`${item.id}/BranchesCustomer`, {
                      state: {
                        name: i18n.language === 'en' ? item.name : item.name2,
                      },
                    })
                  }>
                  <i className="fas fa-store fa-lg me-2 text-theme"></i>
                  الفروع
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-start"
                  href="#/"
                  onClick={() => setPeopleData(item)}
                  data-bs-toggle="modal"
                  data-bs-target="#CustomersContactsTest">
                  <i className="fas fa-address-book fa-lg me-2 text-theme"></i> بيانات التواصل
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-start"
                  onClick={() =>
                    navigate(`${item.id}/PeopleLocations`, {
                      state: {
                        name: i18n.language === 'en' ? item.name : item.name2,
                      },
                    })
                  }>
                  <i className="fas fa-map-marker-alt fa-lg me-2 text-theme"></i> المواقع
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ),
      };
    }) || [];

  const titleModal = () => {
    return (
      <div className="">
        معلومات الاتصال للعميــل :{'  '}
        <span className="text-theme">
          {`(${i18n.language === 'en' ? peopleData?.name : peopleData?.name2})`}
        </span>
      </div>
    );
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('العملاء'),
      link: null,
    },
  ];
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('العملاء')} />
        <div className="ms-auto">
          {/* <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddCustomers"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('إضافة عميل جديد')}
          </Link> */}
          <Link
            to="AddCustomers"
            className="btn me-1 btn-theme"
            // onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('إضافة عميل جديد')}
          </Link>
       
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <NtxTable
              data={tableData || []}
              columns={columns || []}
              dataPerPage={10}
              theadClass=""
              tableClass="table text-nowrap table-hover "
              enableSearch={true}
              showRecordsSelector
            />
          </CardBody>
        </Loading>
      </Card>

      {/* <ModalComponent title={t('إضافة عميل جديد')} id="modalAddCustomers">
        {Show && <Add />}
      </ModalComponent> */}

      <ModalComponent title={titleModal()} id="CustomersContactsTest">
        {peopleData?.id && <PeoplesContacts peopleId={peopleData.id} />}
      </ModalComponent>
    </Fragment>
  );
};

export default Index;

// <div className="table-responsive">
// <table className="table table-hover text-nowrap">
//   <thead className="table-dark">
//     <tr>
//       <th scope="col">{t('Name')}</th>
//       <th scope="col">{t('رقم العميل')}</th>
//       <th scope="col">{t('الأسم التجاري')}</th>

//       <th scope="col"></th>
//     </tr>
//   </thead>
//   <tbody>
//     {currentData?.map((item, index) => (
//       <tr key={item.id}>
//         <td className="align-middle">
//           {i18n.language === 'en' ? item.name : item.name2}
//         </td>
//         <td className="align-middle">{item.customerNumber || '0000'}</td>
//         <td className="align-middle">{item.tradingName}</td>

//         <td className="text-end">
// <div className="btn-group">
//   <Link
//     to={`${item.id}/BranchesCustomer`}
//     className="btn btn-outline-theme m-0  btn-sm"
//     state={{
//       name: i18n.language === 'en' ? item.name : item.name2,
//     }}>
//     <i className="far fa-lg me-1  bi bi-diagram-2 fs-4 "></i>
//     {t('الفروع')}
//   </Link>

//   <Link
//     to={``}
//     data-bs-toggle="modal"
//     data-bs-target="#CustomersContactsTest"
//     className="btn btn-outline-theme m-0  btn-sm"
//     onClick={() => setPeopleData(item)}>
//     <i className="far fa-lg me-1 bi bi-chat"></i>
//     {t('معلومات الاتصال')}
//   </Link>
//   <Link
//     to={`${item.id}/PeopleLocations`} // رابط صفحة المواقع
//     className="btn btn-outline-theme m-0 btn-sm"
//     state={{
//       name: i18n.language === 'en' ? item.name : item.name2,
//     }}>
//     <i className="far fa-lg fa-fw me-1 bi bi-geo"></i>
//     {t('المواقع')}
//   </Link>
//   <Link
//     to={`${item.id}/UpdateCustomers`}
//     className="btn btn-outline-theme m-0  btn-sm"
//     state={{
//       name: i18n.language === 'en' ? item.name : item.name2,
//     }}>
//     <i className="far fa-lg fa-fw me-1 bi bi-info-circle"></i>
//     {t('التفاصيــل')}
//   </Link>
// </div>
//         </td>
//       </tr>
//     ))}
//   </tbody>
// </table>
// </div>
// <Pagination
// filteredData={filteredSearch}
// dataPerPage={dataPerPage}
// handlePagination={(pageNumber: number) => setCurrentPage(pageNumber)}
// currentPage={currentPage}
// />
