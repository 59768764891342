import { useFormik } from 'formik';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetSuppliersList from '../../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';
import useGetPaymentMethod from '../../../../../hooks/Payrolls/use-Get-PaymentMethod';
import confirmAction from '../../../../../components/Shared/Alert/confirmAction';
import {
  updateArReceipts,
  getArReceiptsById,
} from '../../../../../store/Finance/AR/ArReceipts/ArReceiptsSlice';
import useGetBankAccountsList from '../../../../../hooks/Finance/BankAccounts/useGetBankAccountsList';
import useGetPaymentTypeList from '../../../../../hooks/Finance/ApPayments/useGetPaymentTypeList';
import { AddApPaymentsSchema } from '../../../../ValidationForm/validationSchema';
import useGetBranchOrgWithPermission from '../../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import { useEffect } from 'react';
import ArScheduleList from '../ArScheduleList';
import useGetCustomersList from '../../../../../hooks/NTX/Peoples/useGetCustomersList';

export enum paymentTypeEnum {
  Quick = '0464fc34-11f9-490f-b469-7f5d4d7150f1',
  Refund = 'b600351c-8d3c-43df-83c4-9120a79c74aa', // DebitMemo
  Standard = '52ea7dc3-92b7-406d-9f79-f12e0fb8fdb2', // Standard
  PrePayment = 'e232d787-c1d1-4d97-be39-ff52f9010683',
}

const Update = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { receiptId } = useParams();

  const { Currency, loading: loading4 } = useLookupCurrency();
  const { BranchOrgOptions: BranchsOptions, loading: loading3 } = useGetBranchOrgWithPermission();
  const { treeCustomerData, error: error2, loading: loading2 } = useGetCustomersList();

  const { PaymentMethodList } = useGetPaymentMethod();
  const { groupedOptions, loading: loading5, error: error5 } = useGetBankAccountsList();
  const { PaymentTypeList, loading: loading6, error: error6 } = useGetPaymentTypeList();

  useEffect(() => {
    if (receiptId) {
      dispatch(getArReceiptsById(receiptId));
    }
  }, []);
  const { ArReceiptsById: data } = useAppSelector((a) => a.ArReceipts);

  // console.log(data);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      prePaymentTotal: data.paymentType === paymentTypeEnum.PrePayment ? data.amount || 0 : 0, // ليس في البوست
      //

      receiptType: data.paymentType ?? '',
      depositDate: data.depositDate?.toString()?.split('T')[0] ?? '',
      bankAccountId: data.bankAccountId ?? '',
      depositMethodId: data.depositMethodId ?? '',
      amount: data.amount ?? '',
      currencyCode: data.currencyCode ?? '',
      currencyConversionRate: data.currencyConversionRate ?? '',
      peopleId: data.peopleId ?? '',
      organizationId: data.organizationId ?? '',
      scheduleList:
        // [].map((item: any) => ({
        data?.transactionReceiptList?.map((item) => ({
          id: item.id,
          apPaymentScheduleId: item.arReceiptScheduleId,
          amount: item.amount,
          transactionNum: item.transectionNum,
          transactionDate: item.transectionDate?.toString()?.split('T')[0],
          dueDate: item.dueDate?.toString()?.split('T')[0],
          transactionAmount: item.transectionAmount,
          schadualAmount: item.schadualAmount,
          actualRemainingAmount: item.schadualAmount, // هنا يجب جلب المبلغ المتبقي لهذه الدفعة !! مازالت غير مهندلة
        })) ?? [],
    },

    enableReinitialize: true,
    // validationSchema: AddApPaymentsSchema(t),

    onSubmit: async (values) => {
      let isConfirmation = await confirmAction('هل أنت متأكد من أنك تريد تعديل هذا السداد؟');
      if (!isConfirmation) {
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        updateArReceipts({
          //
          id: data.id,
          bankAccountId: values.bankAccountId,
          depositMethodId: values.depositMethodId,
          depositDate: values.depositDate,
          amount:
            values.receiptType === paymentTypeEnum.PrePayment
              ? values.prePaymentTotal
              : values.amount,

          transactionReceiptList:
            values.receiptType === paymentTypeEnum.PrePayment
              ? []
              : values.scheduleList.map((item) => ({
                  id: item.id,
                  arReceiptScheduleId: item.apPaymentScheduleId,
                  amount: item.amount,
                })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          // console.log(res);

          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const {
    values,
    handleChange: formikhandleChange,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = formik;
  //#endregion

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'سندات الدفع',
      link: '/Finance/Ap/ApPayments',
    },
    {
      name: 'تفاصيــل سند الدفع',
      link: null,
    },
  ];
  //#endregion

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="تفاصيــل سند الدفع" />
      </div>

      <Card>
        <Loading loading={loading5 || loading2 || loading3 || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* ('الفرع') */}
                <div className="col-lg-3  ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      isDisabled
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) =>
                        setFieldValue('organizationId', option === null ? null : option.value)
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.organizationId && errors.organizationId}
                    </div>
                  </div>
                </div>
                {/* نوع الدفع */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('نوع الدفع')}</label>
                    <Form.Select
                      disabled
                      className="form-select"
                      name="receiptType"
                      onChange={formikhandleChange}
                      value={values.receiptType || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTypeList?.map((item, idx) => (
                        <option key={idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.receiptType && formik.errors.receiptType}
                    </div>
                  </div>
                </div>
                {/* {t('العميـل')} */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميـل')}</label>
                    <TreeSelect
                      disabled
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeCustomerData}
                      // treeNodeFilterProp="title"
                      getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
                    />
                  </div>
                </div>
                {/* طريقة الدفع */}
                <div className="col-lg-3 ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="depositMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.depositMethodId || ''}
                      // isInvalid={
                      //   !!(formik.touched.depositMethodId && formik.errors.depositMethodId)
                      // }
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.depositMethodId && errors.depositMethodId}
                    </div>
                  </div>
                </div>

                {/* التاريخ */}
                <div className="col-lg-3  ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control"
                      type="date"
                      name="depositDate"
                      onChange={formikhandleChange}
                      value={values.depositDate || ''}
                      // isInvalid={!!(touched.depositDate && errors.depositDate)}
                    />
                    <div className="text-danger small">
                      {touched.depositDate && errors.depositDate}
                    </div>
                  </div>
                </div>

                {/* apBankAccountId */}
                <div className="col-lg-3 ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('البنك / الصندوق')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading5}
                      isSearchable={true}
                      isClearable
                      options={groupedOptions}
                      // value={groupedOptions?.find(
                      //   (f: any) => f?.value === formik.values.bankAccountId
                      // )}
                      value={groupedOptions?.find((item: any) =>
                        item?.options?.some(
                          (option: any) => option.value === formik.values.bankAccountId
                        )
                      )}
                      onChange={(option: any) =>
                        setFieldValue('bankAccountId', option === null ? null : option?.value)
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.bankAccountId && errors.bankAccountId}
                    </div>
                  </div>
                </div>
                {/* ('Currency') */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      disabled
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.currencyCode && errors.currencyCode}
                    </div>
                  </div>
                </div>
                {/* ('معدل العملة') */}
                <div className="col-lg-3 ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('معدل العملة')}</label>
                    <Form.Control
                      disabled
                      readOnly
                      className="form-control  text-center"
                      type="text"
                      autoComplete="off"
                      name="currencyConversionRate"
                      // onChange={formikhandleChange}
                      // value={values.currencyConversionRate || ''}
                      value={values.currencyConversionRate}
                      // isInvalid={!!(touched.currencyConversionRate && errors.currencyConversionRate)}
                    />
                    <div className="text-danger small">
                      {touched.currencyConversionRate && errors.currencyConversionRate}
                    </div>
                  </div>
                </div>

                {values.receiptType === paymentTypeEnum.PrePayment && (
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('مبلغ الدفعة المقدمة')}</label>
                      <Form.Control
                        className="form-control text-center fs-5 fw-bold text-theme"
                        type="text"
                        autoComplete="off"
                        name="prePaymentTotal"
                        onChange={formikhandleChange}
                        value={values.prePaymentTotal || ''}
                        onInput={(e) => {
                          const input = e.target as HTMLInputElement;
                          input.value = input.value.replace(/[^0-9]/g, '');
                        }}
                      />
                      <div className="text-danger small">{errors.amount}</div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row" hidden={values.receiptType === paymentTypeEnum.PrePayment}>
                <ArScheduleList formik={formik} />
              </div>

              <div className="col-12 text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-credit-card"></i>
                  )}
                  {t('Save')}
                </button>
                <button
                  type="button"
                  className="btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/Ar/ArReceipts', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default Update;
