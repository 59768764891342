import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Add from './Add';
import Update from './Update';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import SmallModalComponent from '../../../../components/Modal/SmallModalComponent';
import NtxTable from '../../../../components/DataTable/NtxTable';

const Index = () => {
  const [data, setData] = useState<any>();

  const [Show, setShow] = useState(false);

  const { t } = useTranslation();

  const { CurrencyList, loading, error } = useLookupCurrency();

  const columns = [
    { title: t('CurrencyName'), key: 'name' },
    { title: t('CurrencyCode'), key: 'code' },
    { title: t('State'), key: 'State' },
    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData =
    CurrencyList?.map((item: any) => {
      let rowClass;

      return {
        name: item.description,
        code: item.currencyCode,

        State: (
          <td className="align-middle text-center">
            {item.enabledFlag ? (
              <span className="badge bg-success text-dark">{t('Active')}</span>
            ) : (
              <span className="badge bg-warning text-dark">{t('InActive')}</span>
            )}
          </td>
        ),

        action: (
          <button
            type="button"
            className="btn btn-outline-theme ms-1"
            data-bs-toggle="modal"
            data-bs-target="#modalEdit"
            onClick={() => setData(item)}>
            <i className="far fa-lg fa-fw fa-edit me-1"></i>
            {t('Button.Edit')}
          </button>
        ),
      };
    }) || [];

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Currencies',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t("Currencies")} />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAdd"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('AddCurrency')}
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-1">
              <NtxTable
                data={tableData || []}
                columns={columns || []}
                dataPerPage={10}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                enableSearch={true}
                showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <SmallModalComponent title={t('AddCurrency')} id="modalAdd">
        {Show && <Add />}
      </SmallModalComponent>

      <SmallModalComponent title={t('Edit')}id="modalEdit">
        {data && <Update data={data} />}
      </SmallModalComponent>
    </div>
  );
};

export default Index;
