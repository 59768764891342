import React, { useContext } from 'react';
import { Icon } from '@iconify/react';
import { Card } from '../../../card/card';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { AppSettings } from '../../../../config/app-settings';
import { useTranslation } from 'react-i18next';
 import useListApplications from '../../../../hooks/Applications/useListApplications';
const { appsCardHover } = styles;

interface Props {
  onClose: () => void;
}

const AppsGrid = ({ onClose }: Props) => {
  const ListApplications = useListApplications();
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const changeSystem = async (Code: string) => {
    localStorage.setItem('CurrentSystem', Code);
    await context.setsysCode(Code);
    navigate('/SystemDashboard', { replace: true });
    await new Promise((resolve) => setTimeout(resolve, 200));

    onClose();
  };

  return (
    <>
      <div className="mb-4 p-3 border border-theme   ">
        <h5 className="fw-bold text-center m-0">{t('التطبيقـــات')}</h5>
      </div>
      <div className="row g-4 ">
        {ListApplications?.filter((f) => f.Code !== 'USER' && f.active)?.map((app, index) => (
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
            <Link
              to="/SystemDashboard"
              onClick={() => changeSystem(app.Code)}
              className="text-decoration-none">
              <Card
                className={
                  'h-100 d-flex flex-column justify-content-center align-items-center text-center p-3 ' +
                  appsCardHover
                }>
                {app.isImage ? (
                  <img
                    style={{ width: '50%' }}
                    src={app.imageSrc} // استخدام الصورة للنظام العام
                    alt={app.name}
                  />
                ) : (
                  <Icon
                    className="iconify fs-30px"
                    icon={app.icon}
                    width="50"
                    height="50"
                    style={{ color: app.color }}
                    aria-label={app.name + ' icon'}
                  />
                )}
                <h6 className="mt-3 small lh-lg">{app.name}</h6>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default AppsGrid;

// <>
// <div className="row g-4">
//   {applications.map((app, index) => (
//     <div className="col-lg-2 col-md-6 col-sm-6" key={index}>
//       <Link
//         to="/SystemDashboard"
//         onClick={() => localStorage.setItem('currentSystem', '')} // حفظ النظام الحالي في localStorage
//         className="text-decoration-none">
//         <Card
//           className={
//             'h-100 d-flex flex-column justify-content-center align-items-center text-center p-3 ' +
//             appsCardHover
//           }>
//           {app.isImage ? (
//             <img
//               style={{ width: '28%', height: '110%' }}
//               src={app.imageSrc} // استخدام الصورة للنظام العام
//               alt={app.name}
//             />
//           ) : (
//             <Icon
//               className="iconify fs-30px"
//               icon={app.icon}
//               width="60"
//               height="60"
//               style={{ color: app.color }}
//               aria-label={app.name + ' icon'}
//             />
//           )}
//           <h6 className="mt-3">{app.name}</h6>
//         </Card>
//       </Link>
//     </div>
//   ))}
// </div>
// </>
