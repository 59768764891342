import { Card, CardBody, CardExpandToggler } from '../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import BarChart from './BarChart.js';

const Transactions = [
  { id: 1, source: 'مشتريات', amount: 3000 },
  { id: 2, source: 'مبيعات', amount: 1500 },
  { id: 3, source: 'مبيعات', amount: 2500 },
  { id: 3, source: 'الرواتب', amount: 2500 },
  { id: 3, source: 'المدفوعات', amount: 2500 },
  { id: 3, source: 'أخرى', amount: 2500 },
];

const JournalsBySource = () => {
  const { t } = useTranslation();

  function calculateTotalAmountBySource(transactions) {
    const sourceDistribution = {};

    transactions?.forEach((transaction) => {
      const source = transaction.source;
      if (sourceDistribution[source]) {
        sourceDistribution[source].totalAmount += transaction.amount;
      } else {
        sourceDistribution[source] = {
          name: source,
          count: transaction.amount,
        };
      }
    });

    return Object.values(sourceDistribution);
  }

  const [data, setData] = useState([]);

  useEffect(() => {
    const generatedData = calculateTotalAmountBySource(Transactions);
    setData(generatedData);
  }, []);

  return (
    <div>
      <Card className="mb-3" style={{ height: '100%' }}>
        <CardBody>
          <div className="d-flex fw-bold small mb-3">
            <span className="flex-grow-1">{t('توزيع القيود حسب المصادر')}</span>
            <CardExpandToggler />
          </div>
          <div className="ratio ratio-21x9 mb-3">
            {data && data.length > 0 ? (
              <BarChart data={data} />
            ) : (
              <p>{t('لا توجد بيانات لعرضها.')}</p>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default JournalsBySource;
