import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetPurchaseOrdertList from '../../../../hooks/PUR/useGetPurchaseOrderList';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import PurchaseRequestList from '../PurchaseRequest/PurchaseRequestList';
import PurchaseQuotationList from '../Quotation/PurchaseQuotationList';
import { Card, CardBody } from '../../../../components/card/card';
import Pagination from '../../../../components/Shared/Pagination/Pagination';

const Index = () => {
  const [showPurchaseRequest, setShowPurchaseRequest] = useState(false);
  const [showPurchaseQuotation, setShowPurchaseQuotation] = useState(false);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { PurchaseOrderList, loading, error } = useGetPurchaseOrdertList();

  const filteredSearch = PurchaseOrderList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.sort((a, b) => b?.no - a?.no);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'أوامر الشــراء',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="أوامر الشــراء" />
        <div className="ms-auto">
          {/* <Link to="AddPurchaseOrder" className="btn me-1 btn-outline-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
           أمر شراء جديد
          </Link> */}
          <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
            <button
              type="button"
              className="btn btn-theme dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {/* <i className="fa bi bi-printer-fill fa-fw ms-3 fs-5"></i> */}
              {t('أمر شراء جديد')}
            </button>
            <ul className="dropdown-menu text-center">
              <li>
                <Link className="dropdown-item text-start" to="AddPurchaseOrder">
                  أمر شراء جديد
                  <i className="fa fa-file-alt fa-fw me-2 text-theme"></i>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item text-start"
                  to=""
                  onClick={() => setShowPurchaseRequest(true)}>
                  أمر شراء من طلب شراء
                  <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
                </Link>
              </li>
              {/* <li>
                <Link
                  className="dropdown-item text-start"
                  to=""
                  onClick={() => setShowPurchaseQuotation(true)}>
                  أمر شراء من عرض سعـر
                  <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap  ">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('الرقم')}</th>
                      <th scope="col">{t('التاريخ')}</th>
                      <th scope="col">{t('المورد')}</th>
                      <th scope="col">{t('الفرع')}</th>
                      <th scope="col">{t('القيمة')}</th>
                      <th scope="col">{t('حالة الإستلام')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle"> {item.code} </td>
                        <td className="align-middle">
                          {item.transactionDate.toString()?.split('T')[0]}
                        </td>
                        <td className="align-middle">
                          {i18n.language === 'en' ? item.peopleName : item.peopleName2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === 'en' ? item.organizationName : item.organizationName2}
                        </td>
                        <td className="align-middle"> {item.grossAmount}</td>

                        <td className="align-middle">
                          <span
                            className={`badge border ${
                              item.inventoryFlag
                                ? 'border-success text-success'
                                : 'border-danger text-danger'
                            } px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center me-2`}>
                            <i
                              className={`fa ${
                                item.inventoryFlag ? 'fa-check-circle' : 'fa-clock'
                              } fs-15px fa-fw me-5px`}></i>
                            {item.inventoryFlag
                              ? t('مخزني: تم استلامه')
                              : t('مخزني: لم يتم استلامه')}
                          </span>

                          <span
                            className={`badge border ${
                              item.noneInventoryFlag
                                ? 'border-success text-success'
                                : 'border-danger text-danger'
                            } px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}>
                            <i
                              className={`fa ${
                                item.noneInventoryFlag ? 'fa-check-circle' : 'fa-clock'
                              } fs-15px fa-fw me-5px`}></i>
                            {item.noneInventoryFlag
                              ? t('غير مخزني: تم استلامه')
                              : t('غير مخزني: لم يتم استلامه')}
                          </span>
                        </td>

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            onClick={() => Navigate(`${item.id}/UpdatePurchaseOrder`)}>
                            {/* <i className="far fa-lg fa-fw me-2 fa-edit"></i> */}
                            {t('عرض التفاصيل')}
                          </button>

                          {/* <DynamicPrint
                            PrintTemplateTypeId="868E12B7-A5D3-41F9-AA87-45BB552B3AAB" // قوالب عرض السعر
                            ReferenceId={item.id || ''}
                          /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showPurchaseRequest}
        setShow={setShowPurchaseRequest}
        bodyClassName="p-0"
        size="lg"
        showHeader={false}>
        <PurchaseRequestList Route="PurchaseOrder" />
      </NeatixModal>

      <NeatixModal
        show={showPurchaseQuotation}
        setShow={setShowPurchaseQuotation}
        bodyClassName="p-0"
        size="lg"
        showHeader={false}>
        <PurchaseQuotationList Route="PurchaseOrder" />
      </NeatixModal>
    </div>
  );
};

export default Index;
