import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useTranslation } from 'react-i18next';

interface LinesProps {
  formik: FormikProps<any>;
}
const AttributeValuesList = ({ formik }: LinesProps) => {
  const { values, setFieldValue } = formik;

  const { t, i18n } = useTranslation();

  const handleChange = (index: number, field: string, value: any) => {
    const updatedLines = values.attributeValuesList?.map((line: any, i: any) =>
      i === index ? { ...line, [field]: value } : line
    );
    setFieldValue('attributeValuesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine = {
      id: null,
      name: '',
      name2: '',
      extraPrice: 0,
      color: '#348fe2',
      description: '',
    };
    formik.setFieldValue('attributeValuesList', [...formik.values.attributeValuesList, newLine]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.attributeValuesList?.filter((_: any, i: number) => i !== index);
    setFieldValue('attributeValuesList', updatedLines);
  };

  return (
    <>
      <Loading loading={false} error={null} Type="Dots">
        {/* <div className="table-responsive rounded-2 text-center"> */}
        <table className="table table-hover table-borderless mb-2 ">
          <thead className="bg-theme bg-opacity-30">
            <tr>
              <th className="align-middle  small" style={{ width: '2%' }}>
                #
              </th>
              <th className="align-middle small" style={{ width: '22%' }}>
                {t('LocalName')}
              </th>
              <th className="align-middle small" style={{ width: '22%' }}>
                {t('GlobalName')}
              </th>
              {values.attType === 'Color' && (
                <th className="align-middle small" style={{ width: '15%' }}>
                  {t('اللون')}
                </th>
              )}
              <th className="align-middle small" style={{ width: '8%' }}>
                {t('سعر إضافي')}
              </th>
              <th className="align-middle small" style={{ width: '30%' }}>
                {t('الوصف')}
              </th>

              <th className="align-middle text-end" style={{ width: '2%' }}></th>
            </tr>
          </thead>

          <tbody className="fw-bold">
            {values.attributeValuesList
              ?.slice()

              ?.map((line: any, index: any) => {
                const Index = index;

                return (
                  <tr key={index}>
                    <td className="align-middle small">{index + 1}</td>

                    <td className={`align-middle small`}>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control fw-bold text-theme text-center px-0"
                        value={line.name}
                        onChange={(e) => handleChange(index, 'name', e.target.value)}
                      />
                    </td>

                    <td className={`align-middle small`}>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control fw-bold text-theme text-center px-0"
                        value={line.name2}
                        onChange={(e) => handleChange(index, 'name2', e.target.value)}
                      />
                    </td>

                    {values.attType === 'Color' && (
                      <td className={`align-middle small`}>
                        <div className="input-group">
                          <input type="text" className="form-control" readOnly value={line.color} />
                          <div className="dropdown-menu dropdown-menu-end p-0 border-0 ">
                            <SketchPicker
                              color={line.color}
                              onChangeComplete={(color) => handleChange(index, 'color', color.hex)}
                            />
                          </div>
                          <span
                            className="input-group-text"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside">
                            <i
                              style={{
                                width: '16px',
                                height: '16px',
                                display: 'block',
                                background: line.color,
                              }}></i>
                          </span>
                        </div>
                      </td>
                    )}

                    <td className={`align-middle small`}>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control fw-bold text-theme text-center px-0"
                        value={line.extraPrice}
                        onInput={(e) => {
                          const input = e.target as HTMLInputElement;
                          const numericValue = input.value.replace(/[^0-9]/g, '');
                          handleChange(index, 'extraPrice', numericValue);
                          input.value = new Intl.NumberFormat('en-US').format(Number(numericValue));
                        }}
                      />
                    </td>

                    <td className={`align-middle small`}>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control fw-bold text-theme text-center px-0"
                        value={line.description}
                        onChange={(e) => handleChange(index, 'description', e.target.value)}
                      />
                    </td>

                    <td className="align-middle small">
                      <button
                        type="button"
                        className="btn btn-danger w-100"
                        onClick={() => handleDeleteLine(Index)}>
                        <i className="fas fa-lg fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* </div> */}

        <div className="row">
          <div className="col-lg-12 text-start ">
            <div className="text-start mb-2">
              <button type="button" className="btn btn-outline-theme" onClick={handleAddLine}>
                <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                {t('إضافة')}
              </button>
            </div>
          </div>
        </div>
      </Loading>
    </>
  );
};

export default AttributeValuesList;
