import { Fragment, useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../../components/card/card';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import useGetPeoplesList from '../../../../../hooks/NTX/useGetPeoplesList';
import AddBranchesCustomer from './AddBranchesCustomer';
import PeoplesContacts from '../../../../NTX/Peoples/PeoplesContacts/PeoplesContacts';
import NtxTable from '../../../../../components/DataTable/NtxTable';

const Index = () => {
  const [peopleData, setPeopleData] = useState<any>('');

  const [Show, setShow] = useState(false);

  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const { CustomerList, loading } = useGetPeoplesList({ LookupType: '' });

  const filteredSearch = CustomerList?.slice()?.filter((f) => f.parentId === id);

  const columns = [
    { title: 'اسم المورد', key: 'name' },
    { title: 'رقم المورد', key: 'num' },
    { title: 'الأسم التجاري', key: 'name2' },
    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData =
    filteredSearch?.map((item) => {
      let rowClass;

      return {
        name: i18n.language === 'en' ? item.name : item.name2,
        num: '000',
        name2: item.tradingName,

        action: (
          <div className="btn-group">
            <Link
              to={``}
              data-bs-toggle="modal"
              data-bs-target="#CustomersContactsTest"
              className="btn btn-outline-theme"
              onClick={() => setPeopleData(item)}>
              <i className="far fa-lg me-2  bi bi-telephone"></i>
              {t('بيانات التواصل')}
            </Link>

            <Link
              to={`/SalesSettings/Customers/${item.id}/UpdateCustomers`}
              className="btn btn-outline-theme"
              state={{
                name: i18n.language === 'en' ? item.name : item.name2,
              }}>
              <i className="far fa-lg fa-fw  me-2 fa-edit"></i>
              {t('Button.Edit')}
            </Link>
          </div>
        ),
      };
    }) || [];

  //_______________________________________

  const location = useLocation();
  const { name } = location?.state;

  const titleModal = () => {
    return (
      <div className="">
        بيانات التواصل للعميــل :{'  '}
        <span className="text-theme">
          {`(${i18n.language === 'en' ? peopleData?.name : peopleData?.name2})`}
        </span>
      </div>
    );
  };
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('العملاء'),
      link: '/SalesSettings/Customers',
    },
    {
      name: t('الفروع'),
      link: null,
    },
  ];
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('فروع العميل')} subtitle={name} />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddCustomers"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            إضافة فرع للعميــل {name}
          </Link>
          <Link to="/SalesSettings/Customers" className="btn me-1 btn-outline-secondary" replace>
            {t('Back')}
          </Link>
        </div>
      </div>

      <Card>
        <CardBody>
          <NtxTable
            data={tableData || []}
            columns={columns || []}
            dataPerPage={10}
            theadClass=""
            tableClass="table text-nowrap table-hover "
            enableSearch={true}
            showRecordsSelector
          />
        </CardBody>
      </Card>

      <ModalComponent title={` إضافة فرع للعميــل ${name}`} id="modalAddCustomers">
        {Show && <AddBranchesCustomer />}
      </ModalComponent>

      <ModalComponent title={titleModal()} id="CustomersContactsTest">
        {peopleData?.id && <PeoplesContacts peopleId={peopleData.id} />}
      </ModalComponent>
    </Fragment>
  );
};

export default Index;
