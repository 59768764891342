import { useEffect, useState } from 'react';
import { Card } from '../../../../components/card/card';
import { CardBody } from 'react-bootstrap';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link, useParams } from 'react-router-dom';
import {
  getTransactionsReturnsById,
  cleanUpTransactionsReturnsById,
} from '../../../../store/Sales/SalesReturns/salesReturnsSlice';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import AddReturn from './Add/AddReturn';
import Loading from '../../../../components/Shared/Loader/Loading';
import UpdateReturn from './Update/UpdateReturn';
import InvoiceDetails from './InvoiceDetails/InvoiceDetails';

const SalesReturn = () => {
  const [show, setShow] = useState(false);
  const { InvoiceId } = useParams();
  const dispatch = useAppDispatch();

  const {
    SalesReturnsById: data,
    loading: loadingByInvoiceId,
    error,
  } = useAppSelector((s) => s.SalesReturns);

  useEffect(() => {
    if (InvoiceId) {
      dispatch(getTransactionsReturnsById(InvoiceId));
    }
    return () => {
      dispatch(cleanUpTransactionsReturnsById());
    };
  }, [InvoiceId]);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'مردود مبيعات',
      link: '',
    },
    {
      name: 'مردود جديد',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName={`مردود مبيعات `}
          subtitle={`فاتورة رقم ${data?.code ?? ''}`}
        />
      </div>

      <Card>
        <Loading loading={loadingByInvoiceId} error={error}>
          <CardBody className="pt-1">
            <ul className="nav nav-tabs nav-tabs-v2 p-0">
              <li className="nav-item me-3">
                <Link to="#homev2" className="nav-link active" data-bs-toggle="tab">
                  <i className="fas fa-file-alt me-2 text-theme fs-5"></i>
                  تفاصيل الفاتورة
                </Link>
              </li>
              <li className="nav-item me-3">
                <Link to="#profilev2" className="nav-link" data-bs-toggle="tab">
                  <i className="fas fa-undo me-2 text-theme fs-5"></i>
                  مردود جديد
                </Link>
              </li>

              <li className="nav-item me-3 dropdown">
                <Link to="" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                  <i className="fas fa-history me-2 text-theme fs-5"></i>
                  المردودات السابقة
                </Link>

                <div className="dropdown-menu text-center">
                  {data?.salTransactionsReturns?.length > 0 ? (
                    data.salTransactionsReturns?.map((m: any) => (
                      <Link
                        to={`#${m.id}`}
                        className="dropdown-item"
                        data-bs-toggle="tab"
                        key={m.id}>
                        <i className="fas fa-file-invoice me-2 text-theme"></i>
                        {m.code}
                      </Link>
                    ))
                  ) : (
                    <div className="dropdown-item text-warning">
                      <i className="bi bi-exclamation-circle-fill me-2"></i>
                      لا توجد مردودات لهذه الفاتورة
                    </div>
                  )}
                </div>
              </li>
            </ul>

            <div className="tab-content pt-3">
              <div className="tab-pane fade show active" id="homev2">
                <InvoiceDetails data={data} />
              </div>

              <div className="tab-pane fade" id="profilev2">
                {data&&  Object.keys(data)?.length > 0 && <AddReturn data={data} />}
              </div>

              {data?.salTransactionsReturns?.map((m: any) => {
                return (
                  <div className="tab-pane fade" id={m.id} key={m.id}>
                    {data &&Object.keys(data)?.length > 0 && <UpdateReturn data={m} />}
                  </div>
                );
              })}

              <div className="tab-pane fade" id="dropdown2v2"></div>
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal show={show} setShow={setShow} bodyClassName="p-0" size="xl" showHeader={false}>
        {data&& Object.keys(data)?.length > 0 && <AddReturn data={data} />}
      </NeatixModal>
    </div>
  );
};

export default SalesReturn;
