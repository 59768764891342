import React from 'react';
import { SelectAccount } from './SelectAccount';

// المكون الرئيسي الذي يعيد حقول الإدخال بناءً على نوع الحقل
export const DynamicInput = ({ param, onChange }) => {
  const { type, lable, lable2, value } = param;


  console.log(param);
  
  

  switch (type) {
    case 'Account':
      return (
        <SelectAccount
          label={lable}
          label2={lable2}
          value={value}
          onChange={onChange}
         />
      );
    default:
      return null;
  }
};
