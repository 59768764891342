import { Icon } from '@iconify/react';

import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';

// import SalaryByOrg from './SalaryByOrg.jsx';
import { Card, CardBody } from '../../../components/card/card.jsx';
import Loading from '../../../components/Shared/Loader/Loading';
import JournalsBySource from './JournalsBySource.jsx';
import JournalsByCategory from './JournalsByCategory.jsx';

const FinancialDashboard = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Loading loading={false} error={null}>
        <>
          <div className="row">
            <div className="col-lg-6">
              <Card className="mb-3 ">
                <CardBody>
                  <div className="d-flex fw-bold  mb-3">
                    <span className="flex-grow-1 t">السنة الماليـة الحالية</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4 text-primary ">2024</h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="mdi:calendar"
                          className="me-2 text-primary  "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-6">
              <Card className="mb-3 ">
                <CardBody>
                  <div className="d-flex fw-bold  mb-3">
                    <span className="flex-grow-1 "> الفترة المحاسبية المفتوحة</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h5 className="mb-0 ps-4 text-info   ">من 2024-01-01 -الى- 2024-12-31</h5>
                    </div>
                    <div className="col-4 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="ph:calendar-duotone"
                          className="me-2 text-info    "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Card className="mb-3 ">
                <CardBody>
                  <div className="d-flex fw-bold  mb-3">
                    <span className="flex-grow-1 t">إجمالي عدد القيود</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4 text-purple ">
                        <CountUp end={94} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="line-md:check-list-3-twotone"
                          className="me-2 text-purple  "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-4">
              <Card className="mb-3 ">
                <CardBody>
                  <div className="d-flex fw-bold  mb-3">
                    <span className="flex-grow-1 "> القيود المرحلة</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4 text-success ">
                        <CountUp end={76} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="ph:check-circle-duotone"
                          className="me-2 text-success  "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-4">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold  mb-3">
                    <span className="flex-grow-1 ">القيود الغير مرحلة</span>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4 text-danger">
                        <CountUp end={18} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon
                          icon="humbleicons:exclamation"
                          className="me-2 text-danger "
                          style={{ fontSize: '5rem' }}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          
          <div className="row">
            <div className="col-lg-6">
              <JournalsBySource />
            </div>
            <div className="col-lg-6">
              <JournalsByCategory />
            </div>
          </div>
        </>
      </Loading>
    </div>
  );
};

export default FinancialDashboard;
