import React  from "react";
//  import "jsvectormap/dist/maps/world.js";
// import "./sa_map.js";
// import "jsvectormap/dist/css/jsvectormap.min.css";
 import {
  Card,
  CardBody,
  CardExpandToggler,
} from "../../../components/card/card.jsx";
 import RequestList from "../../Ame/Requests/RequestList.js";
import SUDashBoardData from "./SUDashBoardData";

const SuDahboard = () => {
 
 

  const {  DashBoardList } = SUDashBoardData();

  return (
    <div>
      <div className="row">
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">  الوحدات العقارية</span>
                </div>
                <div className="row align-items-center ">
                  <div className="col-7">
                    <h1 className="mb-0 ps-4">
                      {DashBoardList?.realEstateCount}
                    </h1>
                  </div>
                  <div className="col-5">
                    <div className="mt-n2 ps-4">
                      <i className="bi bi-buildings fa-3x text-theme"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-4 col-lg-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">  العقود </span>
                </div>
                <div className="row align-items-center ">
                  <div className="col-7">
                    <h1 className="mb-0 ps-4">
                      {DashBoardList?.contractLeaseCount}
                    </h1>
                  </div>
                  <div className="col-5">
                    <div className="mt-n2 ps-4">
                      <i className="bi bi-newspaper fa-3x text-theme"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-4 col-lg-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">  العقارات المستأجرة</span>
                </div>
                <div className="row align-items-center ">
                  <div className="col-7">
                    <h1 className="mb-0 ps-4">
                      {DashBoardList?.contractLeaseRealEstateCount}
                    </h1>
                  </div>

                  <div className="col-5">
                    <div className="mt-n2 ps-4">
                      <i className="bi bi-building-lock fa-3x text-theme"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>


        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">موظفين تم تسكينهم</span>
                </div>
                <div className="row align-items-center ">
                  <div className="col-7">
                    <h1 className="mb-0 ps-4">
                      {DashBoardList?.realEstateAssignmentCount}
                    </h1>
                  </div>
                  <div className="col-5">
                    <div className="mt-n2 ps-4">
                      <i className="bi bi-person-fill-up fa-3x text-theme"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-3 col-lg-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">نسبة الشاغر</span>
                </div>
                <div className="row align-items-center ">
                  <div className="col-7">
                    <h1 className="mb-0 ps-4">
                      {DashBoardList?.realEstateAssignmentPercentage} %
                    </h1>
                  </div>
                  <div className="col-5">
                    <div className="mt-n2 ps-4">
                      <i className="bi bi-person-fill-dash fa-3x text-theme"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-3 col-lg-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">إخلاء العقارات  </span>
                </div>
                <div className="row align-items-center ">
                  <div className="col-7">
                    <h1 className="mb-0 ps-4">
                      {DashBoardList?.contractLeaseLeavingCount}
                    </h1>
                  </div>
                  <div className="col-5">
                    <div className="mt-n2 ps-4">
                      <i className="bi bi-building-x fa-3x text-theme"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-3 col-lg-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1"> العقارات الغير مستأجره</span>
                </div>
                <div className="row align-items-center ">
                  <div className="col-7">
                    <h1 className="mb-0 ps-4">
                      {/* {DashBoardList?.realEstateCount} */}
                      {
                        (DashBoardList?.realEstateCount) - (DashBoardList?.contractLeaseRealEstateCount)

                      }
                    </h1>
                  </div>
                  <div className="col-5">
                    <div className="mt-n2 ps-4">
                      <i className="bi bi-building-fill-exclamation fa-3x text-theme"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>




        <div className="col-xl-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">بيانات تحليلية</span>
                <CardExpandToggler />
              </div>
              <div className="ratio-21x9 mb-3">
                <div
                  id="world-map"
                  style={{ height: "100%" }}
                  className="jvectormap-without-padding"
                ></div>
              </div>
              <div className="row gx-4">
                {/* <div className="col-lg-6 mb-3 mb-lg-0">
                <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
                  <thead>
                    <tr className="text-inverse text-opacity-75">
                      <th className="w-50">الموقع</th>
                      <th className="w-25 text-end">حضور</th>
                      <th className="w-25 text-end">غياب%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {countryData && countryData?.length > 0 ? (
                      countryData?.map((country, index) => (
                        <tr key={index} className={country.class}>
                          <td>{country.name}</td>
                          <td className="text-end">{country.visits}</td>
                          <td className="text-end">{country.pct}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div> */}
                {/* <div className="col-lg-6">
                <Card>
                  <CardBody className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="w-70px">
                        {sourceChartData && sourceChartData?.length > 0 && (
                          <Chart
                            type="donut"
                            height="70"
                            options={chartOptions["donut"]}
                            series={sourceChartData}
                          />
                        )}
                      </div>
                      <div className="flex-1 ps-2">
                        <table className="w-100 small mb-0 text-inverse text-opacity-60">
                          <tbody>
                            {sourceData && sourceData?.length > 0 ? (
                              sourceData?.map((source, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div
                                        className={
                                          "w-6px h-6px rounded-pill me-2 " +
                                          source.class
                                        }
                                      ></div>
                                      {source.name}
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    {source.percentage}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="2">No records found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div> */}
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-6">
          <Card className="mb-3">
            {/* <Loader loading={loading} /> */}
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">الطلبــــات</span>
                <CardExpandToggler />
              </div>
              <RequestList />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SuDahboard;

// {Data &&
//   Data?.length > 0 &&
//   Data?.map((stat, index) => (
//     <div className="col-xl-4 col-lg-6" key={index}>
//       <Card className="mb-3">
//         <CardBody>
//           <div className="d-flex fw-bold small mb-3">
//             <span className="flex-grow-1" >{stat.title}</span>
//             {/* <CardExpandToggler /> */}
//           </div>
//           <div className="row align-items-center mb-2">
//             <div className="col-7">
//               <h3 className="mb-0">{stat.total}</h3>
//             </div>
//             <div className="col-5">
//               <div className="mt-n2">
//                 <Chart
//                   type={stat.chartType}
//                   height={stat.chartHeight}
//                   options={chartOptions[stat.chartType]}
//                   series={stat.chartData}
//                 />
//               </div>
//             </div>
//           </div>
//           {/* <div className="small text-inverse text-opacity-50 text-truncate">
//             {stat.info?.length > 0 &&
//               stat.info?.map((info, index) => (
//                 <div key={index}>
//                   <i className={info.icon}></i> {info.text}
//                 </div>
//               ))}
//           </div> */}
//         </CardBody>
//       </Card>
//     </div>
//   ))}
