import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';

export const assignmentItems = createAsyncThunk(
  'AssignmentItems/assignmentItems',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/assignmentItems', item);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const assignmentItemToMultiInventories = createAsyncThunk(
  'AssignmentItems/assignmentItemToMultiInventories',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/assignmentItemToMultiInventories', item);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteAssignmentItem = createAsyncThunk(
  'AssignmentItems/DeleteAssignmentItem',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/DeleteAssignmentItem', item);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);





const initialState: {
  loading: boolean;
  error: any | null;
} = {
  loading: false,
  error: null,
};

const AssignmentItemsSlice = createSlice({
  name: 'AssignmentItems',
  initialState,
  reducers: {},
  extraReducers(builder) {},
});

export default AssignmentItemsSlice.reducer;
