import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const raqeebMenu = [
  {
    System: 'RAQEEB',
    isActive: false,
    menu: [
      { is_header: true, title: 'RAQEEB', isActive: true }, // عنوان القائمة الرئيسي

      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'HomePage',
        isActive: true,
      },

      {
        path: 'RAQ/RotationPlan',
        icon: 'fas fa-sync-alt',
        title: 'RotationPlan',
        isActive: HasPermission('ViewListRaqRotationPlans'),
      },

      {
        path: 'RAQ/AttendancAnalysis',
        icon: 'fas fa-chart-bar',
        title: 'AttendanceAnalysis',
        isActive: HasPermission('AttendanceDataAnalysisReport'),
      },

      {
        path: 'RAQ/UpdateRaqeebData',
        icon: 'fas fa-database',
        title: 'UpdateAttendanceData',
        isActive: HasPermission('RAQAddUpdateAnalysisResults'),
      },

      // { is_header: true, title: 'RAQEEB', isActive: true },
      // {
      //   path: 'dashboard',
      //   icon: 'fas fa-home',
      //   title: 'HomePage',
      // },

      // {
      //   path: '/RAQ',
      //   icon: 'far fa-clock',
      //   title: 'attendancePlan',
      //   children: [
      //     {
      //       path: 'RAQ/RotationPlan',
      //       title: 'RotationPlan',
      //       isActive: HasPermission('ViewListRaqRotationPlans'),
      //     },
      //     {
      //       path: 'RAQ/AttendancAnalysis',
      //       title: 'AttendanceAnalysis',
      //       isActive: HasPermission('AttendanceDataAnalysisReport'),
      //     },
      //     {
      //       path: 'RAQ/UpdateRaqeebData',
      //       title: 'UpdateAttendanceData',
      //       isActive: HasPermission('RAQAddUpdateAnalysisResults'),
      //     },
      //   ],
      // },
    ],
  },
];

export default raqeebMenu;
