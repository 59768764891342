import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetLookupPRIORITY } from '../../store/Lookup/lookupSlice';

const useLookupPRIORITY = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (PRIORITY.length === 0) {
      dispatch(GetLookupPRIORITY());
    }
  }, []);
  const { PRIORITY } = useSelector((state) => state.Lookup);

  return { PRIORITY };
};

export default useLookupPRIORITY;
