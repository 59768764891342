import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { getTITLE } from "../../store/Lookup/lookupSlice";
import { useTranslation } from "react-i18next";

const useLookupTitle = ()=>{

    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    
    useEffect(()=>{
      if(TITLE?.length === 0){
        dispatch(getTITLE())
      }
      },[])

    const { TITLE , loading , error} = useSelector((state) => state.Lookup); 


    const OptionsTitle =
    TITLE &&
      TITLE?.map((item, idx) =>
        item.list?.map((list) => (
          {
              value: list.lookupCode,
              label: i18n.language === "en" ? list.meaning : list.meaning2
          }
        ))
      ).flat()


    return { TITLE , OptionsTitle , loading , error } ;
};

export default useLookupTitle ;