import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { MasterItemsModels, UnitsOfMeasureModels, InventoryItemsModels } from './MasterItemsModels';
import { APIResponse } from '../../Shared/shared';

export const AddMasterItemsINV = createAsyncThunk(
  'MasterItemsINV/AddMasterItems',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddMasterItems', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatMasterItems = createAsyncThunk(
  'MasterItemsINV/UpdatMasterItems',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/UpdatMasterItems', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetMasterItemsList = createAsyncThunk(
  'MasterItemsINV/GetMasterItemsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetMasterItemsList');
      if (data.succeeded === true) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetMasterItemsById = createAsyncThunk(
  'MasterItemsINV/GetMasterItemsById',
  async (ItemId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetMasterItemsById?Id=${ItemId}`);
      if (data.succeeded === true) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetMasterItemsListByInventoryId = createAsyncThunk(
  'MasterItemsINV/GetMasterItemsListByInventoryId',
  async (InventoryId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `Inv/GetMasterItemsListByInventoryId?InventoryId=${InventoryId}`
      );

      if (data.succeeded) {
        return data.data;
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getInventoriesListByItemId = createAsyncThunk(
  'MasterItemsINV/getInventoriesListByItemId',
  async (ItemId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetInventoriesListByItemId?ItemId=${ItemId}`);

      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue('data.message');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// جلب الأصناف مع الوحدات
export const getInventoryItemsByInventoryId = createAsyncThunk(
  'MasterItemsINV/getInventoryItemsByInventoryId',
  async (InventoryId: string, { rejectWithValue }) => {
    try {
      // Inv/GetInventoryItemsByInventoryId?InventoryId=3cb95d6a-d52d-4cad-885c-bbe7b4183619
      const { data } = await ntxAPI.get<APIResponse<InventoryItemsModels[]>>(
        `Inv/GetInventoryItemsByInventoryId?InventoryId=${InventoryId}`
      );

      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


///___________________________________________________________
// جلب الأصناف البديلة على حسب الصنف
export const getItemAlternativeByItemId = createAsyncThunk(
  'MasterItemsINV/getItemAlternativeByItemId',
  async (ItemId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetItemAlternativeByItemId?ItemId=${ItemId}`);

      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue('data.message');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const AddUpdateItemAlternative = createAsyncThunk(
  'MasterItemsINV/AddUpdateItemAlternative',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddUpdateItemAlternative', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);





const initialState: {
  MasterItemsList: MasterItemsModels[];
  MasterItemsById: MasterItemsModels;
  MasterItemsListByInventoryId: MasterItemsModels[];
  UnitsOfMeasureList: UnitsOfMeasureModels[];
  InventoriesListByItemId: [];
  InventoryItems: InventoryItemsModels[]; //
  //
  loadingInventoriesByItemId: boolean;

  loading: boolean;
  error: any | null;
  //
  AlternativeListByItemId: any[];
  AlternativeLoading: boolean;
  AlternativeError: any | null;
} = {
  MasterItemsList: [],
  MasterItemsById: {} as MasterItemsModels,
  MasterItemsListByInventoryId: [],
  UnitsOfMeasureList: [],
  InventoryItems: [],
  InventoriesListByItemId: [],
  loading: false,
  error: null,
  loadingInventoriesByItemId: false,
  //
  AlternativeListByItemId: [],
  AlternativeError: null,
  AlternativeLoading: false,
};

const MasterItemsSlice = createSlice({
  name: 'MasterItemsINV',
  initialState,
  reducers: {
    cleanUpMasterItemsListByInventoryId: (state) => {
      state.MasterItemsListByInventoryId = [];
    },
    cleanUpMasterItemsById: (state) => {
      state.MasterItemsById = {} as MasterItemsModels;
    },
    cleanUpInventoryItems: (state) => {
      state.InventoryItems = [];
    },
  },
  extraReducers(builder) {
    builder

      .addCase(AddMasterItemsINV.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddMasterItemsINV.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action?.payload?.succeeded === true) {
          state.MasterItemsList.push(action.payload.data);
        }
      })
      .addCase(AddMasterItemsINV.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //________________________________________________________________
      .addCase(GetMasterItemsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetMasterItemsList.fulfilled,
        (state, action: PayloadAction<MasterItemsModels[]>) => {
          state.loading = false;
          state.MasterItemsList = action.payload;
        }
      )
      .addCase(GetMasterItemsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //________________________________________________________________
      .addCase(GetMasterItemsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetMasterItemsById.fulfilled, (state, action: PayloadAction<MasterItemsModels>) => {
        state.loading = false;
        state.MasterItemsById = action.payload;
      })
      .addCase(GetMasterItemsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //________________________________________________________________
      .addCase(GetMasterItemsListByInventoryId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetMasterItemsListByInventoryId.fulfilled,
        (state, action: PayloadAction<MasterItemsModels[]>) => {
          state.loading = false;
          if (action.payload === null) {
            state.MasterItemsListByInventoryId = [];
          } else {
            state.MasterItemsListByInventoryId = action.payload;
          }
        }
      )
      .addCase(GetMasterItemsListByInventoryId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //________________________________________________________________
      .addCase(getInventoriesListByItemId.pending, (state, action) => {
        state.loadingInventoriesByItemId = true;
        state.error = null;
      })
      .addCase(getInventoriesListByItemId.fulfilled, (state, action: PayloadAction<any>) => {
        state.loadingInventoriesByItemId = false;
        state.InventoriesListByItemId = action.payload;
      })
      .addCase(getInventoriesListByItemId.rejected, (state, action: PayloadAction<any>) => {
        state.loadingInventoriesByItemId = false;
        state.error = action.payload;
      })
      //________________________________________________________________
      .addCase(getInventoryItemsByInventoryId.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getInventoryItemsByInventoryId.fulfilled,
        (state, action: PayloadAction<InventoryItemsModels[]>) => {
          state.loading = false;
          state.InventoryItems = action.payload;
        }
      )
      .addCase(getInventoryItemsByInventoryId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //________________________________________________________________
      .addCase(getItemAlternativeByItemId.pending, (state, action) => {
        state.AlternativeLoading = true;
        state.AlternativeError = null;
      })
      .addCase(
        getItemAlternativeByItemId.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.AlternativeLoading = false;
          state.AlternativeListByItemId = action.payload;
        }
      )
      .addCase(getItemAlternativeByItemId.rejected, (state, action: PayloadAction<any>) => {
        state.AlternativeLoading = false;
        state.AlternativeError = action.payload;
      })
      ;
  },
});

export const {
  cleanUpMasterItemsListByInventoryId,
  cleanUpMasterItemsById,
  cleanUpInventoryItems,
} = MasterItemsSlice.actions;
export default MasterItemsSlice.reducer;
