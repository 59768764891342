import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetInventoryItems from '../../../../hooks/INV/MasterItems/useGetInventoryItems';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';

interface LinesProps {
  formik: FormikProps<any>;
}

const MoveLines: React.FC<LinesProps> = ({ formik }) => {
  //#region Handle InvLines
  const { values, setFieldValue, errors } = formik;
  const { i18n, t } = useTranslation();
  const { InventoryItemsOptions, loading, error } = useGetInventoryItems(values.inventoryId);

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...values.materialTransactionsLineList];

    if (field === 'transactionItemId') {
      // const unitPrice = InventoryItemsOptions?.find((f) => f.value === value)?.purchasePrice || 0;
      const transactionUom = InventoryItemsOptions?.find(
        (f) => f.itemId === value && f.isPrimaryUOM
      )?.uom;

      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
        transactionUom,
        unitPrice: Number(0),
        total: Number(updatedLines[index].transactionQuantityIn) * Number(0),
      };
    } else {
      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
      };
    }

    formik.setFieldValue('materialTransactionsLineList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine: any = {
      // lineNumber: Lines?.length + 1,
      id: null, //
      transactionItemId: null,
      transactionUom: '',
      unitPrice: 0,
      transactionQuantityIn: 0,
      transactionQuantityOut: 0,

      // inventoryId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      // discount: 0,
      // codeCombinationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      // currencyCode: 'string',
      // currencyConversionRate: 0,
      // referenceIdMaster: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      // referenceIdLine: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      // transactionTypeId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      // equivalent: 0,
      // expensesAmount: 0,
      // batchId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    };
    formik.setFieldValue('materialTransactionsLineList', [
      ...formik.values.materialTransactionsLineList,
      newLine,
    ]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.materialTransactionsLineList?.filter(
      (_: any, i: number) => i !== index
    );
    formik?.setFieldValue('materialTransactionsLineList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.materialTransactionsLineList?.reduce(
      (sum: any, line: { total: any }) => sum + line.total,
      0
    );
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <div className="mb-3">
          <div className="row">
            <div className="col-3">
              <div className="mb-1 rounded-3 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-around">
                  <div className="fw-bold">{t('Total')} :</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-6"></div>
            <div className="col-lg-3 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-theme" onClick={handleAddLine}>
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          <div className="">
            <div
              className={`table-responsive rounded-2 text-center ${
                formik.errors.materialTransactionsLineList
                  ? 'border-bottom border-2 border-danger'
                  : ''
              } `}>
              <table className="table table-borderless  table-hover mb-0 ">
                <thead className="bg-theme bg-opacity-30">
                  <tr>
                    <th className="align-middle" style={{ width: '3%' }}>
                      #
                    </th>
                    <th className="align-middle" style={{ width: '62%' }}>
                      {t('اسم الصنف')}
                    </th>
                    <th className="align-middle" style={{ width: '16%' }}>
                      {t('الوحدة')}
                    </th>
                    <th className="align-middle" style={{ width: '8%' }}>
                      {t('الكمية')}
                    </th>
                    <th className="align-middle" style={{ width: '8%' }}>
                      {t('السعر')}
                    </th>

                    <th className="align-middle text-end" style={{ width: '3%' }}></th>
                  </tr>
                </thead>

                <tbody className="fw-bold">
                  {values.materialTransactionsLineList
                    ?.slice()
                    ?.reverse()
                    ?.map((line: any, index: any) => {
                      const Index = values.materialTransactionsLineList?.length - 1 - index;

                      return (
                        <tr key={index}>
                          <td className="align-middle bg-theme bg-opacity-30 small">
                            {values.materialTransactionsLineList?.length - index}
                          </td>
                          <td className="align-middle small">
                            <Select
                              classNamePrefix="react-select"
                              className="w-100"
                              isSearchable
                              isClearable
                              options={InventoryItemsOptions?.filter(
                                (item, index, self) =>
                                  index === self?.findIndex((i) => i.itemId === item.itemId)
                              )}
                              onChange={(option) =>
                                handleLineChange(
                                  Index,
                                  'transactionItemId',
                                  option === null ? null : option.value
                                )
                              }
                              value={
                                line.transactionItemId === null
                                  ? null
                                  : InventoryItemsOptions?.find(
                                      (option) => option.value === line.transactionItemId
                                    )
                              }
                              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                              }}
                              formatOptionLabel={(option) => (
                                <div className="d-flex justify-content-between   ">
                                  <div className="">{option.label}</div>
                                  <div className="text-theme fw-bold">{option.barcode}</div>
                                </div>
                              )}
                            />
                          </td>

                          <td className="align-middle small">
                            <select
                              className="form-select text-center"
                              value={line.transactionUom || ''}
                              onChange={(e) =>
                                handleLineChange(Index, 'transactionUom', e.target.value)
                              }>
                              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                              {InventoryItemsOptions?.filter(
                                (f) => f.itemId === line.transactionItemId
                              )?.map((item, idx) => (
                                <option key={++idx} value={item.uom}>
                                  {item.uomName}
                                </option>
                              ))}
                            </select>
                          </td>

                          <td className="align-middle small">
                            <PopoverCustom
                              childern={
                                <input
                                  type="text" 
autoComplete='off'
                                  className="form-control text-center px-0"
                                  value={line.transactionQuantityIn}
                                  onChange={(e) =>
                                    handleLineChange(
                                      Index,
                                      'transactionQuantityIn',
                                      Number(e.target.value)
                                    )
                                  }
                                  onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');

                                    var QtyAvailable =
                                      InventoryItemsOptions?.find(
                                        (f) =>
                                          f.itemId === line.transactionItemId &&
                                          f.uom === line.transactionUom
                                      )?.QtyAvailable ?? 0;

                                    if (Number(input.value) > QtyAvailable) {
                                      input.value = QtyAvailable.toString();
                                    }
                                  }}
                                />
                              }
                              Header="الكمية المتوفرة في المخـزن"
                              Body={
                                <span className="text-success fw-bold fs-5 mx-2">
                                  {
                                    InventoryItemsOptions?.find(
                                      (f) =>
                                        f.itemId === line.transactionItemId &&
                                        f.uom === line.transactionUom
                                    )?.QtyAvailable
                                  }
                                </span>
                              }
                            />
                          </td>

                          <td className="align-middle small">
                            <input
                              type="text" 
autoComplete='off'
                              className="form-control text-center px-0"
                              value={line.unitPrice}
                              onChange={(e) =>
                                handleLineChange(Index, 'unitPrice', Number(e.target.value))
                              }
                              onInput={(e) => {
                                const input = e.target as HTMLInputElement;
                                input.value = input.value.replace(/[^0-9]/g, '');
                              }}
                            />
                          </td>

                          <td className="align-middle small">
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={() => handleDeleteLine(Index)}>
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Loading>
    </>
  );
};

export default MoveLines;
