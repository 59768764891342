import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const ameMenu = [
  {
    System: 'AME',
    isActive: true,
    menu: [
      { is_header: true, title: 'ApprovalsManagement', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'HomePage',
      },

      // {
      //   path: "/NTX",
      //   icon: "bi bi-code-square",
      //   title: "RequestForms",
      //   children: [
      //     {
      //       path: "NTX/Forms",
      //       title: "RequestForms",
      //       isActive: HasPermission('AmeViewListApplicationForms'),
      //     },
      //   ],
      // },

      {
        path: 'Ame/Forms',
        icon: 'fas fa-file-alt',
        title: 'RequestForms',
        isActive: HasPermission('AmeViewListApplicationForms'),
      },

      // { is_header: true, title: "RequestSettings" , isActive:true},

      // {
      //   path: "Ame/Conditions",
      //   icon: "fas fa-tasks",
      //   title: "الشروط",
      //   //  isActive: HasPermission('AmeOrderSettingsList'),
      //    isActive: true,
      // },
      // {
      //   path: "Ame/Rules",
      //   icon: "fas fa-gavel",
      //   title: "القواعد",
      //   //  isActive: HasPermission('AmeOrderSettingsList'),
      //    isActive: true,
      // },

      {
        path: 'Ame/RequestSetting',
        icon: 'fas fa-cogs',
        title: 'RequestSettings',
        isActive: HasPermission('AmeOrderSettingsList'),
      },
      {
        path: 'Ame/ApprovalGroup',
        icon: 'fas fa-users',
        title: 'ApprovalGroup',
        isActive: HasPermission('AmeListApprovalGroups'),
      },
      {
        path: 'Ame/FollowRequests',
        icon: 'fas fa-clipboard-list',
        title: 'FollowRequests',
        isActive: true,
      },

      // {
      //   path: "",
      //   icon: "bi bi-clipboard-data-fill",
      //   title: "Reports",
      //   isActive: true,
      // },

      // {
      //   path: "",
      //   icon: "bi bi-cpu",
      //   title: "لوحة البيانات",
      //   isActive: true,
      // },
    ],
  },
];

export default ameMenu;
