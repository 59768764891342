import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from '../../../components/card/card';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CountUp from 'react-countup';

type Props = {
  title: string;
  icon: string;
  List?: any[];
};
export const ModuleCard = ({ title, icon, List }: Props) => {
  const { i18n } = useTranslation();

  return (
    <Card className="mb-3">
      <CardHeader className="fw-bold small p-1 px-2  bg-theme bg-opacity-15">
        <div className="d-flex justify-content-between">
          <div className="pt-2">{title}</div>
          <i className={`bi ${icon} fa-2x text-theme`}></i>
        </div>
      </CardHeader>

      <CardBody>
        <div className="row">
          <div className="col-12 col-md-4 m-auto">
            {/* <div className="pt-1 mb-2 text-center">العدد</div> */}
            <h1 className="mb-0 text-theme text-center" style={{ fontSize: '55px' }}>
              {/* {List?.length} */}
              <CountUp end={List?.length || 0} duration={2.5} />
            </h1>
          </div>

          <div className="col-12 col-md-8">
            <PerfectScrollbar
              options={{ useBothWheelAxes: false, suppressScrollX: true }}
              style={{ maxHeight: '11rem', minHeight: '11rem' }}>
              {/* <div
              style={{
                maxHeight: '11rem',
                minHeight: '11rem',
                overflowY: 'auto', // لتمكين التمرير العمودي
              }}> */}
              <>
                {List?.length === 0 ? (
                  <div className="text-center bg-inverse bg-opacity-15 rounded-2 p-3 ">
                    <span> لا يوجد بيانات</span>
                    <br />
                    {/* <span className="text-theme fw-bold mt-2">{title}</span> */}
                  </div>
                ) : (
                  <div className="row g-1">
                    {List?.map((m: any, index: number) => (
                      <div className="col-12 " key={++index}>
                        <div className="d-flex p-0 m-0 bg-theme bg-opacity-10 rounded-2 p-1 ">
                          <div className="text-theme">
                            <i className={m.icon}></i>
                          </div>

                          <div
                            className="text-center "
                            style={{
                              fontSize: '13px',
                            }}>
                            {i18n.language === 'en' ? m.name : m.name2}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
              {/* </div> */}
            </PerfectScrollbar>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
