import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../hooks/HR/use-get-persons';
import { useNavigate } from 'react-router-dom';
import useLookupGetCustomerType from '../../../hooks/Lookups/use-Lookup-CustomerType';
import useGetPaymentTermsList from '../../../hooks/Sales/useGetPaymentTermsList';
import useGetGlLedgersList from '../../../hooks/Finance/useGetGlLedgersList';
import useLookupCurrency from '../../../hooks/Lookups/use-Lookup-Currency';
import useGetPriceList from '../../../hooks/INV/PriceList/useGetPriceList';
import useGetChartAccountsDetailsList from '../../../hooks/Finance/useGetChartAccountsDetailsList';
import { Card, CardBody } from '../../../components/card/card';
import useLookupPRIORITY from '../../../hooks/Lookups/use-Lookup-PRIORITY';
import Loading from '../../../components/Shared/Loader/Loading';

const CustomerForm = ({ formik }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { dataEmployees, loading: personLoading } = useGetPersons();
  const { PaymentTermsList, loading, error } = useGetPaymentTermsList();
  const { Currency, loading: loading1, error: error1 } = useLookupCurrency();
  const { CUSTOMER_TYPE, loading: loading2, error: error2 } = useLookupGetCustomerType();
  const { LedgersList, loading: loading3, error: error3 } = useGetGlLedgersList();
  const { OptionsAccounts, loading: loading4, error: error4 } = useGetChartAccountsDetailsList();
  const { PriceList, loading: loading5, error: error5 } = useGetPriceList();
  const { PRIORITY, loading: loading6, error: error6 } = useLookupPRIORITY();

  return (
    <Loading>
      <Form onSubmit={formik.handleSubmit}>
        <div className="mb-5">
          <h5>
            <i className="bi bi-info-circle fa-lg  text-theme"></i> المعلومات الأساسية
          </h5>
          <p> </p>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('LocalName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="name2"
                      onChange={formik.handleChange}
                      value={formik.values.name2}
                      isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('GlobalName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      isInvalid={!!(formik.touched.name && formik.errors.name)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name2}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* .customerNumber  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">رقم العميل</label>
                    <Form.Control
                      disabled
                      className="form-control"
                      type="number"
                      name="customerNumber"
                      onChange={formik.handleChange}
                      value={formik.values.customerNumber}
                      isInvalid={!!(formik.touched.customerNumber && formik.errors.customerNumber)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.customerNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* peopleCategory  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التصنيف')}</label>
                    <Form.Select
                      className="form-select"
                      name="peopleCategory"
                      onChange={formik.handleChange}
                      value={formik.values.peopleCategory}
                      // isInvalid={formik.touched.peopleCategory && formik.errors.peopleCategory}
                    >
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {CUSTOMER_TYPE?.map((item, idx) =>
                        item.list?.map((list) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                          </option>
                        ))
                      )}
                    </Form.Select>
                  </div>
                </div>

                {/* tradingName   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">الاسم التجاري</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="tradingName"
                      onChange={formik.handleChange}
                      value={formik.values.tradingName}
                      isInvalid={!!(formik.touched.tradingName && formik.errors.tradingName)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.tradingName}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('EmployeeName')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={personLoading}
                      isSearchable={true}
                      isClearable
                      name="personId"
                      options={dataEmployees}
                      onChange={(option) => {
                        formik.setFieldValue('personId', option === null ? null : option.value);
                      }}
                      value={
                        formik.values.personId
                          ? dataEmployees?.find((option) => option.value === formik.values.personId)
                          : null
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />

                    {formik.errors.personId && formik.touched.personId && (
                      <div style={{ color: 'red' }}>{formik.errors.personId}</div>
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="mb-5">
          <h5>
            <i className="bi bi-person-lines-fill fa-lg  text-theme"></i> المعلومات الشخصية والاتصال
          </h5>
          <p> </p>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Email')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      isInvalid={!!(formik.touched.email && formik.errors.email)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Phone')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="phone"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      isInvalid={!!(formik.touched.phone && formik.errors.phone)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.phone}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Fax')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="fax"
                      onChange={formik.handleChange}
                      value={formik.values.fax}
                      isInvalid={!!(formik.touched.fax && formik.errors.fax)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.fax}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('MobileNumber')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="mobile"
                      onChange={formik.handleChange}
                      value={formik.values.mobile}
                      isInvalid={!!(formik.touched.mobile && formik.errors.mobile)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.mobile}
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/* workReference   */}
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">اسم الشخص</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="workReference"
                      onChange={formik.handleChange}
                      value={formik.values.workReference}
                      isInvalid={!!(formik.touched.workReference && formik.errors.workReference)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.workReference}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-8">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Address')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                      isInvalid={!!(formik.touched.address && formik.errors.address)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.address}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="mb-5">
          <h5>
            <i className="bi bi-wallet2 fa-lg  text-theme"></i> المعلومات المالية والمصرفية
          </h5>
          <p> </p>
          <Card>
            <CardBody>
              <div className="row">
                {/* invoiceCurrencyCode  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">عملة الفاتورة</label>
                    <Form.Select
                      className="form-select"
                      name="invoiceCurrencyCode"
                      onChange={formik.handleChange}
                      value={formik.values.invoiceCurrencyCode || ''}
                      isInvalid={
                        !!(formik.touched.invoiceCurrencyCode && formik.errors.invoiceCurrencyCode)
                      }
                      // isValid={
                      //   formik.touched.invoiceCurrencyCode && !formik.errors.invoiceCurrencyCode
                      // }
                    >
                      {Currency &&
                        Currency?.map((item, idx) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>

                {/* paymentCurrencyCode  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">عملة الدفع</label>
                    <Form.Select
                      className="form-select"
                      name="paymentCurrencyCode"
                      onChange={formik.handleChange}
                      value={formik.values.paymentCurrencyCode || ''}
                      isInvalid={
                        !!(formik.touched.paymentCurrencyCode && formik.errors.paymentCurrencyCode)
                      }
                      // isValid={
                      //   formik.touched.paymentCurrencyCode && !formik.errors.paymentCurrencyCode
                      // }
                    >
                      {Currency &&
                        Currency?.map((item, idx) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.paymentCurrencyCode}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* invoiceLimit   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">حد مبلغ الفاتورة</label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      name="invoiceLimit"
                      onChange={formik.handleChange}
                      value={formik.values.invoiceLimit}
                      isInvalid={!!(formik.errors.invoiceLimit && formik.touched.invoiceLimit)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.invoiceLimit}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* creditLimit   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">الحد الائتماني</label>
                    <Form.Control
                      className="form-control"
                      type="number"
                      name="creditLimit"
                      onChange={formik.handleChange}
                      value={formik.values.creditLimit}
                      isInvalid={!!(formik.touched.creditLimit && formik.errors.creditLimit)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.creditLimit}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* paymentMethodLookupCode   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">طريقة الدفع</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="paymentMethodLookupCode"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodLookupCode}
                      isInvalid={
                        !!(
                          formik.touched.paymentMethodLookupCode &&
                          formik.errors.paymentMethodLookupCode
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.paymentMethodLookupCode}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* bankAccountName   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">اسم الحساب البنكي</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="bankAccountName"
                      onChange={formik.handleChange}
                      value={formik.values.bankAccountName}
                      isInvalid={
                        !!(formik.touched.bankAccountName && formik.errors.bankAccountName)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.bankAccountName}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* bankAccountNum   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">رقم الحساب البنكي</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="bankAccountNum"
                      onChange={formik.handleChange}
                      value={formik.values.bankAccountNum}
                      isInvalid={!!(formik.touched.bankAccountNum && formik.errors.bankAccountNum)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.bankAccountNum}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* bankNumber  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">رقم البنك</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="bankNumber"
                      onChange={formik.handleChange}
                      value={formik.values.bankNumber}
                      isInvalid={!!(formik.touched.bankNumber && formik.errors.bankNumber)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.bankNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* bankAccountType   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">نوع الحساب البنكي</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="bankAccountType"
                      onChange={formik.handleChange}
                      value={formik.values.bankAccountType}
                      isInvalid={
                        !!(formik.touched.bankAccountType && formik.errors.bankAccountType)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.bankAccountType}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* priceListId   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('قائمة الأسعار')}</label>
                    <Form.Select
                      className="form-select"
                      name="priceListId"
                      onChange={formik.handleChange}
                      value={formik.values.priceListId}
                      isInvalid={!!(formik.touched.priceListId && formik.errors.priceListId)}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PriceList &&
                        PriceList?.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>

                {/* accountId    */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الحساب')}</label>
                    <Select
                      classNamePrefix="react-select"
                      // className="text-center"
                      isLoading={loading4}
                      isSearchable={true}
                      isClearable
                      options={OptionsAccounts}
                      value={OptionsAccounts?.find((f) => f.value === formik.values.accountId)}
                      onChange={(option) => formik.setFieldValue('accountId', option?.value)}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div>

                {/* setOfBooksId  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">دفتر الحسابات</label>
                    <Form.Select
                      className="form-select"
                      name="setOfBooksId"
                      onChange={formik.handleChange}
                      value={formik.values.setOfBooksId}
                      isInvalid={!!(formik.errors.setOfBooksId && formik.touched.setOfBooksId)}>
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {LedgersList &&
                        LedgersList?.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.setOfBooksId}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="mb-5">
          <h5>
            <i className="bi bi-building fa-lg  text-theme"></i> الهوية المؤسسية/القانونية
          </h5>
          <p> </p>
          <Card>
            <CardBody>
              <div className="row">
                {/* companyRegistrationNumber  */}
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">رقم السجل التجاري</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="companyRegistrationNumber"
                      onChange={formik.handleChange}
                      value={formik.values.companyRegistrationNumber}
                      isInvalid={
                        !!(
                          formik.touched.companyRegistrationNumber &&
                          formik.errors.companyRegistrationNumber
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.companyRegistrationNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* nationalInsuranceNumber  الرقم الوطني (للمؤسسات). */}

                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">رقم الضمان الوطني</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="nationalInsuranceNumber"
                      onChange={formik.handleChange}
                      value={formik.values.nationalInsuranceNumber}
                      isInvalid={
                        !!(
                          formik.touched.nationalInsuranceNumber &&
                          formik.errors.nationalInsuranceNumber
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.nationalInsuranceNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/* vatCode  */}
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">الرقم الضريبي</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="vatCode"
                      onChange={formik.handleChange}
                      value={formik.values.vatCode}
                      isInvalid={!!(formik.touched.vatCode && formik.errors.vatCode)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.vatCode}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/*   verificationNumber  */}
                {/* <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">verificationNumber</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="verificationNumber"
                      onChange={formik.handleChange}
                      value={formik.values.verificationNumber}
                      isInvalid={
                        !!(formik.touched.verificationNumber && formik.errors.verificationNumber)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.verificationNumber}
                    </Form.Control.Feedback>
                  </div>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="mb-5">
          <h5>
            <i className="bi bi-geo-alt fa-lg  text-theme"></i> الموقع والدفع
          </h5>
          <p> </p>
          <Card>
            <CardBody>
              <div className="row">
                {/* termsId   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">شروط الدفع</label>
                    <Form.Select
                      className="form-select"
                      name="termsId"
                      onChange={formik.handleChange}
                      value={formik.values.termsId}
                      isInvalid={!!(formik.errors.termsId && formik.touched.termsId)}>
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList?.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.termsId}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* paymentPriority  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">أولوية الدفع</label>
                    <Form.Select
                      className="form-select"
                      name="paymentPriority"
                      onChange={formik.handleChange}
                      value={formik.values.paymentPriority}
                      isInvalid={
                        !!(formik.errors.paymentPriority && formik.touched.paymentPriority)
                      }>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PRIORITY &&
                        PRIORITY?.map((item, idx) =>
                          item?.list?.map((list) => (
                            <option key={++idx} value={list.lookupCode}>
                              {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                            </option>
                          ))
                        )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.paymentPriority}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* <div className="mb-5">
          <h5>
            <i className="bi bi-plus-circle fa-lg  text-theme"></i> معلومات إضافية
          </h5>
          <p> </p>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Note')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                    <div></div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div> */}

        <div className="text-center">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-2"></i>
            )}
            {t('Save')}
          </button>
          <button type="button" className="btn me-1 btn-default mb-1" onClick={() => navigate(-1)}>
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </Loading>
  );
};

export default CustomerForm;
