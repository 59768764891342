import { useEffect, useState } from 'react';
import { Card } from '../../../../components/card/card';
import { CardBody } from 'react-bootstrap';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link, useParams } from 'react-router-dom';
import {
  getPurchasesReturnsById,
  cleanUpPurchasesReturnsById,
} from '../../../../store/purchases/PurchaseReturn/purchasesReturnsSlice';
import InvoiceLines from './BillDetails/Lines';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import AddReturn from './Add/AddReturn';
import Loading from '../../../../components/Shared/Loader/Loading';
import UpdateReturn from './Update/UpdateReturn';
import i18n from '../../../../i18n';
import BillDetails from './BillDetails/BillDetails';

const SalesReturn = () => {
  const [show, setShow] = useState(false);
  const { BillId } = useParams();
  const dispatch = useAppDispatch();

  const {
    PurchaseReturnById: data,
    loading: loadingById,
    error,
  } = useAppSelector((s) => s.PurchaseReturn);

  useEffect(() => {
    if (BillId) {
      dispatch(getPurchasesReturnsById(BillId));
    }
    return () => {
      dispatch(cleanUpPurchasesReturnsById());
    };
  }, [BillId]);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'مردود مشتريات',
      link: '/PurchasesTransactions/PurchaseReturn',
    },
    {
      name: 'مردود جديد',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName={`مردود مشتريات `}
          subtitle={`فاتورة مشتريات رقم ${data.code ?? ''}`}
        />
      </div>

      <Card>
        <Loading loading={loadingById} error={error}>
          <CardBody className="pt-1">
            <ul className="nav nav-tabs nav-tabs-v2 p-0">
              <li className="nav-item me-3">
                <Link to="#homev2" className="nav-link active" data-bs-toggle="tab">
                  <i className="fas fa-file-alt me-2 text-theme fs-5"></i>
                  تفاصيل الفاتورة
                </Link>
              </li>

              <li className="nav-item me-3">
                <Link to="#profilev2" className="nav-link" data-bs-toggle="tab">
                  <i className="fas fa-undo me-2 text-theme fs-5"></i>
                  مردود جديد
                </Link>
              </li>
              <li className="nav-item me-3 dropdown">
                <Link to="" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                  <i className="fas fa-history me-2 text-theme fs-5"></i>
                  المردودات السابقة
                </Link>

                <div className="dropdown-menu text-center">
                  {data?.purTransactionsDtoList?.length > 0 ? (
                    data.purTransactionsDtoList?.map((m: any) => (
                      <Link
                        to={`#${m.id}`}
                        className="dropdown-item"
                        data-bs-toggle="tab"
                        key={m.id}>
                        <i className="fas fa-file-invoice me-2 text-theme"></i>
                        {m.code}
                      </Link>
                    ))
                  ) : (
                    <div className="dropdown-item text-warning">
                      <i className="bi bi-exclamation-circle-fill me-2"></i>
                      لا توجد مردودات لهذه الفاتورة
                    </div>
                  )}
                </div>
              </li>
            </ul>

            <div className="tab-content pt-3">
              <div className="tab-pane fade show active" id="homev2">
                <BillDetails data={data} />
              </div>

              <div className="tab-pane fade" id="profilev2">
                {Object.keys(data)?.length > 0 && <AddReturn data={data} />}
              </div>

              {data?.purTransactionsDtoList?.map((m: any) => {
                return (
                  <div className="tab-pane fade" id={m.id} key={m.id}>
                    {Object.keys(data)?.length > 0 && <UpdateReturn data={m} />}
                  </div>
                );
              })}
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal show={show} setShow={setShow} bodyClassName="p-0" size="xl" showHeader={false}>
        {Object.keys(data)?.length > 0 && <AddReturn data={data} />}
      </NeatixModal>
    </div>
  );
};

export default SalesReturn;
