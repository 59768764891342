import React from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import useGetPaymentsList from '../../../../hooks/Finance/ApPayments/useGetPaymentsList';
import DataTableComponent from '../../../../components/DataTable/DataTableComponent';
 
const Index = () => {
 
  const { t, i18n } = useTranslation();
 
  const { ApPaymentsList: data, loading, error } = useGetPaymentsList();

  const columns = [
    { title: t('VoucherNumber'), key: 'code', sortable: false },
    { title: t('Date'), key: 'paymentDate' },
    { title:  t('PaymentType'), key: 'paymentTypeName' },
    { title: t('Amount') , key: 'amount', className: 'text-theme' },
    { title: t('Supplier') , key: 'peopleName' },
    { title: t('Branch') , key: 'organizationName' },
    { title: t('PostingStatus') , key: 'postedFlagDisplay', className: 'text-center', sortable: false }, // إضافة حالة الترحيل

    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData = data.map((item) => {
    let rowClass;

    const postedFlagBadge = item.postedFlag ? (
      <span className="badge bg-success">
        <i className="fas fa-check me-1"></i> {/* أيقونة للترحيل */}
        {('Posted')}
      </span>
    ) : (
      <span className="badge bg-danger">
        <i className="fas fa-times me-1"></i> {/* أيقونة لعدم الترحيل */}
         {('NotPosted')}
      </span>
    );
    return {
      code: item.code,
      paymentDate: item.paymentDate,
      paymentTypeName: i18n.language === 'en' ? item.paymentTypeName : item.paymentTypeName2,
      amount: item.amount,
      peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
      postedFlag: item.postedFlag,
      postedFlagDisplay: postedFlagBadge,
      action: (
        <Link to={`${item.id}/UpdateApPayments`} className="btn btn-outline-theme mx-1">
          <i className="fas fa-info-circle me-2"></i>
          {t('Details')}
        </Link>
      ),
      rowClass, // استخدم rowClass هنا
    };
  });

  const options = {
    paging: true,
    pageLength: 10,
    searching: true,
    ordering: true,
    lengthChange: true, //
    // info: false, //
    columnDefs: [{ orderable: false, targets: 7 }],
    order: [[1, 'desc']],
    responsive: true,
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Payments',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Payments" />
        <div className="ms-auto">
          <Link to="AddApPayments" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('NewPayment')}
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <DataTableComponent
              data={tableData}
              columns={columns}
              options={options}
              theadClass=""
              tableClass="table text-nowrap"
            />

          
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Index;
