import React from 'react';
import AddAbsence from '../../HR/Employees/Absence/AddAbsence';
import WorkRequestForm from '../../FLEET/WorkRequests/WorkRequestForm';
import AddApExpense from '../../Finance/Ap/ApExpense/Add2';
import AddPurchaseOrder from '../../PUR/Transactions/PurchaseOrder/Add2';
import AddPurchaseRequest from '../../PUR/Transactions/PurchaseRequest/AddAme';

const AddStaticForm = ({ StaticFormId, setReferenceId }) => {
  let Component = null;

  switch (StaticFormId) {
    case '19b2d5d2-8864-4e5d-b07e-4c1713d07a3c': // Absence Form ID from Seeding
      Component = <AddAbsence isAme={true} setReferenceId={setReferenceId} />;
      break;
    case 'd9e918b3-b706-4281-a6e6-5101be1bcd63': // WorkRequest Form ID from Seeding
      Component = <WorkRequestForm isAme={true} setReferenceId={setReferenceId} />;
      break;
    case '34347b6e-32ca-4ffb-aa27-82498da81411': //
      Component = <AddApExpense />;
      break;
    case '733ad7ac-b2ff-4b2a-814a-78c71153447f': //
      Component = <AddPurchaseOrder />;
      break;
    case '62d18267-8f18-4907-b38a-494aa53de0af': //
      Component = <AddPurchaseRequest />;
      break;
    default:
      <div> .. Neatix ERP .. </div>;
  }

  return <div>{Component}</div>;
};

export default AddStaticForm;
