import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ntxAPI } from "../../../../API/axiosNeatex";
import SkeletonLoader from "../../../../components/Shared/SkeletonLoader/SkeletonLine";

const TypeL = ({ item, handleSelectChange }) => {
  const { t, i18n } = useTranslation();

  const [lookupList, setLookupList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      async function GetTableData() {
        try {
          const { data } = await ntxAPI.get(
            `Lookup/GetByTypes?LookupTypes=${item.lookupType}`
          );
          let DataList = data?.map((item, idx) =>
            item.list?.map((list) => ({
              value: list.lookupCode,
              label: i18n.language === "ar" ? list.meaning : list.meaning2,
            })).flat()
          );
  
          setLookupList(DataList[0]);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
  
      GetTableData();
    }, [item.lookupType]);
    

  return (
    <>
      <div className="form-group mb-3">
        <label className="form-label">
          {i18n.language === "ar" ? item.lable2 : item.lable}
        </label>
        <div className="row">
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            lookupList?.length > 0 && (
              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                options={lookupList}
                name={item.id}
                required={item.requiredFlag}
                onChange={handleSelectChange}
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

export default TypeL;
