import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlChartOfAccountsDetailsList } from '../../../store/Finance/ChartAccounts/ChartAccountsDetailsSlice';
import { useTranslation } from 'react-i18next';

const useGetCostCenters = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ChartAccountsDetailsList?.length === 0) {
      dispatch(GetGlChartOfAccountsDetailsList());
    }
  }, []);

  const { ChartAccountsDetailsList, loading, error } = useAppSelector(
    (state) => state.ChartAccountsDetails
  );

  var CostCenters = ChartAccountsDetailsList?.filter(
    (f) => f.accountType === 'CostCenter' && f.accountParant !== null
  );

  var OptionsCostCenters = CostCenters?.map(({ id, name, name2, accountNumber, accountParant }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2} | ${accountNumber}`,
    accountParant: accountParant,
    accountNumber: accountNumber,
    accountShow: (
      <div className="d-flex justify-content-between">
        <div>{i18n.language === 'en' ? name : name2}</div>
        <div className="text-theme ms-2">{accountNumber}</div>
      </div>
    ),
  }));

  //#region  // ___Start TreeSelect CostCenter _____
  const BildCostCentersTree = (list: any, parentId: any) => {
    return list
      ?.filter((item: any) => item.accountParant === parentId)
      ?.map((item: any) => ({
        title: `${i18n.language === 'en' ? item.name : item.name2} | ${item.accountNumber}`,
        value: item.id,
        accountNumber: item.accountNumber,
        children: BildCostCentersTree(list, item.id),
      }));
  };
  const TreeCostCenters = BildCostCentersTree(
    ChartAccountsDetailsList,
    '7a5a0a0d-00d9-4b8f-9f6a-8ebe1f851b47'
  );
  //#endregion

  // function findAccountNumberById(data: any, id: any) {
  //   for (let item of data) {
  //     if (item.value === id) {
  //       return item.accountNumber;
  //     }
  //     if (item.children && item.children?.length > 0) {
  //       const result: any = findAccountNumberById(item.children, id);
  //       if (result) {
  //         return result;
  //       }
  //     }
  //   }
  //   return null; // Return null if not found
  // }

  function findAccountNumberById(id: any) {
    function search(data: any, id: any) {
      for (let item of data) {
        if (item.value === id) {
          return item.accountNumber;
        }
        if (item.children && item.children?.length > 0) {
          const result: any = search(item.children, id);
          if (result) {
            return result;
          }
        }
      }
      return null; // Return null if not found
    }

    return search(TreeCostCenters, id); // Use TreeCostCenters directly
  }

  return {
    // ChartAccountsDetailsList,
    CostCenters,
    OptionsCostCenters,
    TreeCostCenters,
    loading,
    error,
    //
    findAccountNumberById,
  };
};

export default useGetCostCenters;
