import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import NtxPeoples2 from '../../../NTX/Peoples/NtxPeoples2';

const Add = () => {
  const { t, i18n } = useTranslation();
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('العملاء'),
      link: '/SalesSettings/Customers',
    },
    {
      name: t('إضافة عميل جديد'),
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('إضافة عميل جديد')} />
      </div>

      <hr className="mb-3" />

      <NtxPeoples2 isUpdate={false} typeCode="CUSTOMERS" parentId={null} />
    </div>
  );
};

export default Add;
