import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
 import { useTranslation } from 'react-i18next';
import { getApExpenseCategoryList } from '../../../store/Finance/Expense/ApExpenseCategory/ApExpenseCategorySlice';

const useGetApExpenseCategoryList = () => {
  // getApExpenseCategoryList
    const dispatch = useAppDispatch();

    const {i18n , t} = useTranslation();
    useEffect(() => {
      if(ApExpenseCategoryList?.length ===0){
        dispatch(getApExpenseCategoryList());
      }
    }, []);
    const { ApExpenseCategoryList , loading , error} = useAppSelector(
      (state) => state.ApExpenseCategory
    );
   
    const OptionsExpenseCategory  =
    ApExpenseCategoryList &&
    ApExpenseCategoryList?.map((item) => ({
      value: item.id,
      label: i18n.language === 'ar' ? item.name2 : item.name,
    }));


  return {  ApExpenseCategoryList, OptionsExpenseCategory ,loading , error }
}

export default useGetApExpenseCategoryList
