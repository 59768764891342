import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { FACategoriesModels } from './FACategoriesModels';
import { APIResponse } from '../../../Shared/shared';

export const addFACategories = createAsyncThunk(
  'FACategories/addFACategories',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('FA/AddCategories', item);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateFACategories = createAsyncThunk(
  'FACategories/updateFACategories',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('FA/UpdatCategories', item);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getFACategoriesList = createAsyncThunk(
  'FACategories/getFACategoriesList',
  async (_, { rejectWithValue }) => {
    try {
       const { data } = await ntxAPI.get<APIResponse<FACategoriesModels[]>>('FA/GetCategoriesList');
      //  debugger
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getFACategoriesById = createAsyncThunk(
  'FACategories/getFACategoriesById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<FACategoriesModels>>(`FA/GetCategoriesById?Id=${id}`);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getFACategoriesByCategoryId = createAsyncThunk(
  'FACategories/getFACategoriesByCategoryId',
  async (CategoryId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<FACategoriesModels>>(`FA/GetCategoriesByCategoryId?CategoryId=${CategoryId}`);
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  FACategoriesList: FACategoriesModels[];
  FACategoriesById: FACategoriesModels;
  FACategoriesByCategoryId: FACategoriesModels;
  loading: boolean;
  error: any | null;
} = {
  FACategoriesList: [],
  FACategoriesById: {} as FACategoriesModels,
  FACategoriesByCategoryId: {} as FACategoriesModels,
  loading: false,
  error: null,
};

const FACategoriesSlice = createSlice({
  name: 'FACategories',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
 

      .addCase(getFACategoriesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFACategoriesList.fulfilled, (state, action: PayloadAction<FACategoriesModels[]>) => {
        state.loading = false;
        state.FACategoriesList = action.payload;
      })
      .addCase(getFACategoriesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
 

      .addCase(getFACategoriesById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFACategoriesById.fulfilled, (state, action: PayloadAction<FACategoriesModels>) => {
        state.loading = false;
        state.FACategoriesById = action.payload;
      })
      .addCase(getFACategoriesById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
 

      .addCase(getFACategoriesByCategoryId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFACategoriesByCategoryId.fulfilled, (state, action: PayloadAction<FACategoriesModels>) => {
        state.loading = false;
        state.FACategoriesByCategoryId = action.payload;
      })
      .addCase(getFACategoriesByCategoryId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default FACategoriesSlice.reducer;
