import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const suMenu = [
   {
    System: 'SU',
    isActive: true,
    menu: [
      { is_header: true, title: 'SupportingUnits', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'HomePage',
      },

      {
        path: '/SU/Contracts',
        icon: 'bi bi-newspaper',
        title: 'RealEstateContracts',
        isActive: true,
      },

      {
        path: '/SU/RealEstate',
        icon: 'bi bi-buildings',
        title: 'RealEstate',
        isActive: true,
      },

      {
        path: '/SU/SU_ExtraInformationDynamicType',
        icon: 'bi bi-building-gear',
        title: 'Types_Real_Estate',
        isActive: true,
      },

      {
        path: '/SU/FollowPayments',
        icon: 'bi bi-building-check',
        title: 'متابعة الإيجارات',
        children: [
          {
            path: '/SU/FollowPayments/InstallmentUnPayment',
            title: 'دفع الأقساط',
            isActive: true,
          },
          {
            path: '/SU/FollowPayments/InstallmentPayment',
            title: 'عرص الأقساط المدفوعة',
            isActive: true,
          },
        ],
      },

      {
        path: '/SUSettings',
        icon: 'bi bi-gear',
        title: 'Settings',
        children: [
          {
            path: 'SUSettings/Branches',
            // icon: "bi bi-sign-intersection-y-fill",
            title: 'الفروع',
            isActive: true,
          },

          {
            path: 'SUSettings/Locations',
            title: 'Locations',
            isActive: true,
          },
        ],
      },

      {
        path: 'SU/Reports',
        icon: 'bi bi-people',
        title: 'Reports',
        isActive: true,
      },
      //  {
      //     path: "#/",
      //     icon: "bi bi-cpu",
      //     title: "لوحة البيانات",
      //     // isActive:false,
      //     isActive: true,
      //   },
    ],
  },
];

export default suMenu;
