import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetInventoryItems from '../../../../../hooks/INV/MasterItems/useGetInventoryItems';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import { Form } from 'react-bootstrap';

interface LinesProps {
  formik: FormikProps<any>;
}

const ItemsList: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;
  const { InventoryItemsOptions, loading, error } = useGetInventoryItems(values.inventoryId);
  const { i18n, t } = useTranslation();

  const handleAddLine = () => {
    const newLine: any = {
      id: null,
      lineNumber: values.inventoryCountItemsList?.length + 1,

      inventoryItemId: null,
      systemQuantity: 0,
      description: '',
    };

    formik.setFieldValue('inventoryCountItemsList', [
      ...formik.values.inventoryCountItemsList,
      newLine,
    ]);
  };

  const handleLineChange = (index: number, field: string, value: any) => {
    // const updatedLines = [...Lines];
    const updatedLines = [...values.inventoryCountItemsList];
    // debugger;
    if (field === 'inventoryItemId') {
      // الحصول على الكمية من المخزون
      const systemQuantity =
        InventoryItemsOptions?.find((f) => f.value === value && f.isPrimaryUOM)?.qtyByLot[0]?.qty ||
        0;

      // // الحصول على الوحدة الأساسية
      // const unitOfMeasure = InventoryItemsOptions?.find(
      //   (f) => f.value === value && f.isPrimaryUOM
      // )?.uom;

      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
        systemQuantity,
        // unitOfMeasure,
      };
    } else {
      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
      };
    }

    // setLines(updatedLines);
    formik.setFieldValue('inventoryCountItemsList', updatedLines);
  };

  // const handleLineChange = (inventoryItemId: number, field: string, value: any) => {
  //   const updatedLines = [...values.inventoryCountItemsList];

  //   const lineIndex = updatedLines.findIndex((line) => line.inventoryItemId === inventoryItemId);
  //   if (lineIndex === -1) return; // إذا لم يتم العثور على السطر، يمكنك إيقاف العملية

  //   if (field === 'inventoryItemId') {
  //     const systemQuantity =
  //       InventoryItemsOptions?.find((f) => f.value === value && f.isPrimaryUOM)?.qtyByLot[0]?.qty ||
  //       0;

  //     updatedLines[lineIndex] = {
  //       ...updatedLines[lineIndex],
  //       [field]: value,
  //       systemQuantity,
  //     };
  //   } else {
  //     updatedLines[lineIndex] = {
  //       ...updatedLines[lineIndex],
  //       [field]: value,
  //     };
  //   }

  //   formik.setFieldValue('inventoryCountItemsList', updatedLines);
  // };

  const handleAddAllItems = () => {
    if (!InventoryItemsOptions?.length) return;

    // جمع المعرفات الحالية مع الوحدة
    const existingItems = values.inventoryCountItemsList?.map((line: any) => ({
      inventoryItemId: line?.inventoryItemId,
    }));

    // تصفية العناصر الجديدة التي لا تحتوي على معرف موجود  بالفعل
    const newItems = InventoryItemsOptions.filter(
      (item) =>
        !existingItems.some((existingItem: any) => existingItem?.inventoryItemId === item.itemId) &&
        item.isPrimaryUOM === true
    ).map((item, idx) => ({
      lineNumber: ++idx,
      // lineNumber: values.inventoryCountItemsList?.length + 1,
      id: null,
      inventoryItemId: item.value,
      // systemQuantity: item.qtyByLot?.[0]?.qty || 0,
      systemQuantity: item.qtyByLot?.reduce((sum, lot) => sum + (lot.qty || 0), 0) || 0, // جمع الكميات

      description: item.itemName,
    }));

    if (newItems.length > 0) {
      const updatedLines = [...values.inventoryCountItemsList, ...newItems];
      formik.setFieldValue('inventoryCountItemsList', updatedLines);
    }
  };
  const handleDeleteLine = (index: number) => {
    const updatedLines = values.inventoryCountItemsList?.filter((_: any, i: number) => i !== index);
    formik?.setFieldValue('inventoryCountItemsList', updatedLines);
  };

  ///______________________________________________________________

  // const handleDeleteLine = (inventoryItemId: number) => {
  //   const updatedLines = values.inventoryCountItemsList?.filter(
  //     (line: any) => line.inventoryItemId !== inventoryItemId
  //   );
  //   formik.setFieldValue('inventoryCountItemsList', updatedLines);
  // };

  // const [currentPage, setCurrentPage] = useState(1);
  // const [dataPerPage] = useState(6);

  // const indexOfLastData = currentPage * dataPerPage;
  // const indexOfFirstData = indexOfLastData - dataPerPage;
  // const currentData = values.inventoryCountItemsList?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <div className="mb-3">
          <div className="row">
            <div className="col-3"></div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button
                  type="button"
                  className="btn btn-outline-theme me-1 "
                  onClick={handleAddLine}>
                  {t('إضافة صنف')}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-theme me-1"
                  onClick={handleAddAllItems}>
                  {t('إضافة جميع الأصناف')}
                </button>
              </div>
            </div>
          </div>

          <>
            <div
              className={`table-responsive rounded-2 text-center mb-2 ${
                formik.errors.inventoryCountItemsList ? 'border border-2 border-danger' : ''
              } `}>
              <table className="table table-borderless  table-hover mb-0 ">
                <thead className="bg-theme bg-opacity-30 text-nowrap">
                  <tr>
                    <th className="align-middle" style={{ width: '3%' }}>
                      #
                    </th>
                    <th className="align-middle" style={{ width: '58%' }}>
                      {t('اسم الصنف')}
                    </th>

                    <th className="align-middle" style={{ width: '6%' }}>
                      {t('الرصيد في النظام')}
                    </th>
                    {/* <th className="align-middle" style={{ width: '30%' }}>
                      {t('الوصف')}
                    </th> */}

                    <th className="align-middle text-end" style={{ width: '3%' }}></th>
                  </tr>
                </thead>
                <tbody className="fw-bold">
                  {values.inventoryCountItemsList
                    ?.slice()
                    ?.reverse()
                    ?.map((line: any, index: any) => {
                      // const Index = values.inventoryCountItemsList?.length + 1;
                      const Index = values.inventoryCountItemsList?.length - 1 - index;

                      return (
                        <tr key={index}>
                          <td className="align-middle bg-theme bg-opacity-30 small">
                            {values.inventoryCountItemsList?.length - index}
                            {/* {Index} */}
                             {/* {Index} */}
                            {/* {line.lineNumber} */}
                          </td>
                          <td className="align-middle small">
                            <Select
                              classNamePrefix="react-select"
                              className="w-100"
                              isSearchable
                              isClearable
                              options={InventoryItemsOptions?.filter(
                                (item, index, self) =>
                                  index === self?.findIndex((i) => i.itemId === item.itemId)
                              )?.filter(
                                (f) =>
                                  !values.inventoryCountItemsList?.some(
                                    (line: any) => line.inventoryItemId === f.value
                                  )
                              )}
                              onChange={(option) =>
                                handleLineChange(
                                  Index,
                                  'inventoryItemId',
                                  option === null ? null : option.value
                                )
                              }
                              value={
                                line.inventoryItemId === null
                                  ? null
                                  : InventoryItemsOptions?.find(
                                      (option) => option.value === line.inventoryItemId
                                    )
                              }
                              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                              }}
                              formatOptionLabel={(option) => (
                                <div className="d-flex justify-content-between   ">
                                  <div className="">{option.label}</div>
                                  <div className="text-theme fw-bold">{option.barcode}</div>
                                </div>
                              )}
                            />
                          </td>

                          <td className="align-middle small">
                            <input
                              type="text" 
autoComplete='off'
                              className="form-control text-center px-0 text-theme fw-bold"
                              value={line.systemQuantity}
                              // onChange={(e) =>
                              //   handleLineChange(
                              //     line.inventoryItemId,
                              //     'systemQuantity',
                              //     Number(e.target.value)
                              //   )
                              // }
                            />
                          </td>

                          {/* <td className="align-middle small">
                          <Form.Control
                            className="form-control"
                            as="textarea"
                            rows={1}
                            value={line.description}
                            onChange={(e) =>
                              handleLineChange(line.inventoryItemId, 'description', e.target.value)
                            }
                          />
                        </td> */}

                          <td className="align-middle small text-end">
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={() => handleDeleteLine(Index)}>
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {/* <Pagination
              filteredData={values?.inventoryCountItemsList}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            /> */}
          </>
        </div>
      </Loading>
    </>
  );
};

export default ItemsList;

// <td className="align-middle small">
// <input
//   type="text" 
 //   className="form-control text-center px-0"
//   // size={line.price.toString()?.length || 1}
//   value={line.price}
//   onChange={(e) =>
//     handleLineChange(Index, 'price', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>

// // التحقق من عدم وجود صنف بنفس inventoryItemId والوحدة
// const isDuplicate = updatedLines.some(
//   (line, i) =>
//     i !== index && // تجاهل السطر الحالي
//     line.inventoryItemId === updatedLines[index].inventoryItemId &&
//     line.unitOfMeasure === updatedLines[index].unitOfMeasure
// );
// if (isDuplicate) {
//   alert('تم إضافة هذا الصنف بالفعل مع نفس الوحدة.');
//   return; // منع التكرار
// }
