import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ArReceiptsModels, UnApplyPrepaymentsModels , ArReceiptsByIdModels} from './ArReceiptsModels';
import { APIResponse } from '../../../Shared/shared';

export const addArReceipts = createAsyncThunk(
  'ArReceipts/addArReceipts',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ar/AddArReceipts', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateArReceipts = createAsyncThunk(
  'ArReceipts/updateArReceipts',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ar/UpdateArReceipts', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArReceiptsList = createAsyncThunk(
  'ArReceipts/getArReceiptsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ArReceiptsModels[]>>('Ar/GetArReceiptsList');
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArReceiptsById = createAsyncThunk(
  'ArReceipts/getArReceiptsById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ArReceiptsByIdModels>>(
        `Ar/GetArReceiptsById?Id=${Id}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// قائمة الدفعات المقدمة للعميـل   /////
export const getUnApplyPrepaymentsListByPeopleId = createAsyncThunk(
  'ArReceipts/getUnApplyPrepaymentsListByPeopleId',
  async (PeopleId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<UnApplyPrepaymentsModels[]>>(
        `Ar/GetArUnApplyPrepaymentsListByPeopleId?PeopleId=${PeopleId}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const ReceiptsCreateAccount = createAsyncThunk(
  'ArReceipts/ReceiptsCreateAccount',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ar/ReceiptsCreateAccount', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


const initialState: {
  ArReceiptsList: ArReceiptsModels[];
  ArReceiptsById: ArReceiptsByIdModels;

  UnApplyPrepaymentsList: UnApplyPrepaymentsModels[]; // قائمة الدفعات المقدمة للعميـل

  loading: boolean;
  loading2: boolean;
  error: any | null;
} = {
  ArReceiptsList: [],
  ArReceiptsById: {} as ArReceiptsByIdModels,
  UnApplyPrepaymentsList: [],
  loading: false,
  loading2: false,
  error: null,
};

const ArReceiptsSlice = createSlice({
  name: 'ArReceipts',
  initialState,
  reducers: {
    cleanUpUnApplyPrepaymentsList: (state) => {
      state.UnApplyPrepaymentsList = [];
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getArReceiptsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArReceiptsList.fulfilled, (state, action: PayloadAction<ArReceiptsModels[]>) => {
        state.loading = false;
        state.ArReceiptsList = action.payload;
      })
      .addCase(getArReceiptsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getArReceiptsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArReceiptsById.fulfilled, (state, action: PayloadAction<ArReceiptsByIdModels>) => {
        state.loading = false;
        state.ArReceiptsById = action.payload;
      })
      .addCase(getArReceiptsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///

      .addCase(getUnApplyPrepaymentsListByPeopleId.pending, (state, action) => {
        state.loading2 = true;
      })
      .addCase(
        getUnApplyPrepaymentsListByPeopleId.fulfilled,
        (state, action: PayloadAction<UnApplyPrepaymentsModels[]>) => {
          state.loading2 = false;
          state.UnApplyPrepaymentsList = action.payload;
        }
      )
      .addCase(
        getUnApplyPrepaymentsListByPeopleId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading2 = false;
          state.error = action.payload;
        }
      );
  },
});

export const { cleanUpUnApplyPrepaymentsList } = ArReceiptsSlice.actions;

export default ArReceiptsSlice.reducer;
