import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const payrollMenu = [
  {
    System: 'PAYROLL',
    isActive: false,
    menu: [
      { is_header: true, title: 'PAYROLL', isActive: true }, // عنوان القائمة الرئيسي

      {
        path: 'SystemDashboard',
        icon: 'fas fa-tachometer-alt',
        title: 'HomePage',
        isActive: true,
      },

      {
        path: 'Pay/payrolls',
        icon: 'fas fa-cogs', 
        title: 'Settings',
        isActive: HasPermission('PayViewPayrollList'),
      },

      {
        path: 'Pay/PayrollElement',
        icon: 'fas fa-list-alt', 
        title: 'PayrollElements',
        isActive: HasPermission('PayViewListPayrollElement'),
      },

      {
        path: 'Pay/PayrollElementEntry',
        icon: 'fas fa-file-alt', 
        title: 'PayrollElementEntry',
        isActive: HasPermission('PayPayrollElementEntries'),
      },
      {
        path: 'Pay/payrollsRun',
        icon: 'fas fa-play', 
        title: 'RunPayrolls',
        isActive: HasPermission('PayCreatRunPayroll'),
      },

      {
        path: 'Pay/QuickPay',
        icon: 'fas fa-bolt',
        title: 'QuickPay',
        isActive: HasPermission('PayCreatePayrollQuickPay'),
      },
    ],
  },
];

export default payrollMenu;

// { is_header: true, title: 'PAYROLL', isActive: true },
// {
//   path: 'dashboard',
//   icon: 'fas fa-home',
//   title: 'HomePage',
// },

// {
//   path: '/Pay',
//   icon: 'fas fa-money-bill-alt',
//   title: 'PayrollManagement',
//   children: [
//     {
//       path: 'Pay/payrolls',
//       title: 'PayrollsSettings',
//       isActive: HasPermission('PayViewPayrollList'),
//     },

//     {
//       path: 'Pay/PayrollElement',
//       title: 'PayrollElements',
//       isActive: HasPermission('PayViewListPayrollElement'),
//     },

//     {
//       path: 'Pay/payrollsRun',
//       title: 'RunPayrolls',
//       isActive: HasPermission('PayCreatRunPayroll'),
//     },
//     {
//       path: 'Pay/QuickPay',
//       title: 'QuickPay',
//       isActive: HasPermission('PayCreatePayrollQuickPay'),
//     },

//     {
//       path: 'Pay/PayrollElementEntry',
//       title: 'PayrollElementEntry',
//       isActive: HasPermission('PayPayrollElementEntries'),
//     },
//   ],
// },
