import React from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import useGetGlPeriodsList from '../../../hooks/Finance/GlPeriods/useGetGlPeriodsList';
import useGetGlLedgersList from '../../../hooks/Finance/useGetGlLedgersList';
import { addGlBudgets } from '../../../store/Finance/GlBudget/GlBudgetSlice';
import Select from 'react-select';
 
const Add = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  const { OptionsGlPeriods } = useGetGlPeriodsList();
  const { OptionsLedgers } = useGetGlLedgersList();

  const formik = useFormik({
    initialValues: {
      periodId: '',
      ledgerId: '',
      name: '',
      name2: '',
      description: '',
      status: false,
    },
    //   validationSchema: AddEquipmentsModelFLeetSchema(t),
    onSubmit: (values) => {
      dispatch(
        addGlBudgets({
          periodId: values.periodId,
          ledgerId: values.ledgerId,
          name: values.name,
          name2: values.name2,
          description: values.description,
          status: values.status,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                autoComplete="off"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control "
                type="text"
                autoComplete="off"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('الإستاذ العام')} </label>
              <Select
                classNamePrefix="react-select"
                className="w-100  "
                isSearchable={true}
                isClearable
                options={OptionsLedgers}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                // value={formik.values.ledgerId}
                onChange={(option) => formik.setFieldValue('ledgerId', option ? option.value : '')}
              />
              {formik.errors.ledgerId && formik.touched.ledgerId && (
                <div className="text-danger">{formik.errors.ledgerId}</div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('الفترة المحاسبية')} </label>
              <Select
                classNamePrefix="react-select"
                className="w-100"
                isSearchable={true}
                isClearable
                options={OptionsGlPeriods}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                // value={formik.values.ledgerId}
                onChange={(option) => formik.setFieldValue('periodId', option ? option.value : '')}
              />
              {formik.errors.periodId && formik.touched.periodId && (
                <div className="text-danger">{formik.errors.periodId}</div>
              )}
            </div>
          </div>
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label">{t('الوصف')}</label>
              <Form.Control
                className="form-control"
                type="text"
                autoComplete="off"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                isInvalid={!!(formik.touched.description && formik.errors.description)}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Add;

// {/* <div className="col-xl-6">
//   <div className="form-group mb-3">
//     <label className="form-label">{t("endDate")}</label>
//     <Form.Control
//       className="form-control text-center"
//       type="date"
//       name="conversionEndDate"
//       onChange={formik.handleChange}
//       value={formik.values.conversionEndDate}
//       isInvalid={!!(formik.touched.conversionEndDate && formik.errors.conversionEndDate)}
//     />
//   </div>
// </div> */}
