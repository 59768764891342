// import SUExtraInformationDynamicType from '../../pages/SU/ExtraInformationDynamicType/SU_ExtraInformationDynamicType.js';
// import RealEstate from '../../pages/SU/RealEstate/index/RealEstate';
// import Accomodation from '../../pages/SU/RealEstate/Accomodation/Accomodation';
// import EditExtraInformationDynamicType from '../../pages/NTX/FlexValue/ExtraInformationDynamicType/EditExtraInformationDynamicType.js';
// import UpdateRealEstate from '../../pages/SU/RealEstate/Update/UpdateRealEstate.js';
// import AddContracts from '../../pages/SU/Contracts/Add/AddContracts.js';
// import Contracts from '../../pages/SU/Contracts/index/Contracts.js';
// import Branches from '../../pages/SU/Branches/Index';
// import UpdateContract from '../../pages/SU/Contracts/Update/UpdateContract.js';
// import SUReports from '../../pages/SU/SUReports/SUReports';
// import ReportsBuilder from '../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder';
// import FleetLocations from '../../pages/FLEET/FleetSettings/Locations/Index';
// import UppdateLocations from '../../pages/FLEET/FleetSettings/Locations/UppdateLocations';
// import UpdateBranches from '../../pages/SU/Branches/UpdateBranches';
// import InstallmentUnPaymentList from '../../pages/SU/FollowPayments/InstallmentUnPayment/InstallmentUnPaymentList';
// import InstallmentPaymentList from '../../pages/SU/FollowPayments/InstallmentPayment/InstallmentPaymentList';
// import ExtraRecordRealEstate from '../../pages/SU/RealEstate/ExtraRecord/ExtraRecordRealEstate';

// const suRoutes = [
//   {
//     path: 'SU/*',
//     children: [
//       { path: 'Contracts', element: <Contracts /> },
//       { path: 'Contracts/AddContarcts', element: <AddContracts /> },
//       { path: 'Contracts/:id/UpdateContract', element: <UpdateContract /> },
//       { path: 'RealEstate', element: <RealEstate /> },

//       {
//         path: 'RealEstate/:Id/UpdateRealEstate',
//         element: <UpdateRealEstate />,
//       },
//       {
//         path: 'RealEstate/:RealEstateId/ExtraRecordRealEstate',
//         element: <ExtraRecordRealEstate />,
//       },
//       {
//         path: 'RealEstate/:Id/Accomodation',
//         element: <Accomodation />,
//       },

//       {
//         path: 'FollowPayments/InstallmentUnPayment',
//         element: <InstallmentUnPaymentList />,
//       },
//       {
//         path: 'FollowPayments/InstallmentPayment',
//         element: <InstallmentPaymentList />,
//       },

//       {
//         path: 'SU_ExtraInformationDynamicType',
//         element: <SUExtraInformationDynamicType />,
//       },
//       {
//         path: 'SU_ExtraInformationDynamicType/:FormId/Edit',
//         element: <EditExtraInformationDynamicType />,
//       }, // ستكون لكل موديول صفحة تعديل خاصة بمسار خاص ممكن نهندله بطريقة أفضل

//       { path: 'Reports', element: <SUReports /> },
//       {
//         path: 'Reports/:ReportId/ReportsBuilder',
//         element: <ReportsBuilder />,
//       },
//     ],
//   },

//   { path: 'SUSettings/Branches', element: <Branches /> },
//   { path: 'SUSettings/Branches/:id/Edit', element: <UpdateBranches /> },
//   { path: 'SUSettings/Locations', element: <FleetLocations /> },
//   { path: 'SUSettings/Locations/:id/Edit', element: <UppdateLocations /> },
// ];

// export default suRoutes;


import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';

// Lazy loading components
const SUExtraInformationDynamicType = lazy(() => import('../../pages/SU/ExtraInformationDynamicType/SU_ExtraInformationDynamicType.js'));
const RealEstate = lazy(() => import('../../pages/SU/RealEstate/index/RealEstate'));
const Accomodation = lazy(() => import('../../pages/SU/RealEstate/Accomodation/Accomodation'));
const EditExtraInformationDynamicType = lazy(() => import('../../pages/NTX/FlexValue/ExtraInformationDynamicType/EditExtraInformationDynamicType.js'));
const UpdateRealEstate = lazy(() => import('../../pages/SU/RealEstate/Update/UpdateRealEstate.js'));
const AddContracts = lazy(() => import('../../pages/SU/Contracts/Add/AddContracts.js'));
const Contracts = lazy(() => import('../../pages/SU/Contracts/index/Contracts.js'));
const Branches = lazy(() => import('../../pages/SU/Branches/Index'));
const UpdateContract = lazy(() => import('../../pages/SU/Contracts/Update/UpdateContract.js'));
const SUReports = lazy(() => import('../../pages/SU/SUReports/SUReports'));
const ReportsBuilder = lazy(() => import('../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder'));
const FleetLocations = lazy(() => import('../../pages/FLEET/FleetSettings/Locations/Index'));
const UpdateLocations = lazy(() => import('../../pages/FLEET/FleetSettings/Locations/UppdateLocations'));
const UpdateBranches = lazy(() => import('../../pages/SU/Branches/UpdateBranches'));
const InstallmentUnPaymentList = lazy(() => import('../../pages/SU/FollowPayments/InstallmentUnPayment/InstallmentUnPaymentList'));
const InstallmentPaymentList = lazy(() => import('../../pages/SU/FollowPayments/InstallmentPayment/InstallmentPaymentList'));
const ExtraRecordRealEstate = lazy(() => import('../../pages/SU/RealEstate/ExtraRecord/ExtraRecordRealEstate'));

const suRoutes = [
  {
    path: 'SU/*',
    children: [
      {
        path: 'Contracts',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Contracts />
          </Suspense>
        ),
      },
      {
        path: 'Contracts/AddContracts',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddContracts />
          </Suspense>
        ),
      },
      {
        path: 'Contracts/:id/UpdateContract',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <UpdateContract />
          </Suspense>
        ),
      },
      {
        path: 'RealEstate',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <RealEstate />
          </Suspense>
        ),
      },
      {
        path: 'RealEstate/:Id/UpdateRealEstate',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <UpdateRealEstate />
          </Suspense>
        ),
      },
      {
        path: 'RealEstate/:RealEstateId/ExtraRecordRealEstate',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ExtraRecordRealEstate />
          </Suspense>
        ),
      },
      {
        path: 'RealEstate/:Id/Accomodation',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Accomodation />
          </Suspense>
        ),
      },
      {
        path: 'FollowPayments/InstallmentUnPayment',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <InstallmentUnPaymentList />
          </Suspense>
        ),
      },
      {
        path: 'FollowPayments/InstallmentPayment',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <InstallmentPaymentList />
          </Suspense>
        ),
      },
      {
        path: 'SU_ExtraInformationDynamicType',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <SUExtraInformationDynamicType />
          </Suspense>
        ),
      },
      {
        path: 'SU_ExtraInformationDynamicType/:FormId/Edit',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <EditExtraInformationDynamicType />
          </Suspense>
        ),
      },
      {
        path: 'Reports',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <SUReports />
          </Suspense>
        ),
      },
      {
        path: 'Reports/:ReportId/ReportsBuilder',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ReportsBuilder />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'SUSettings/Branches',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <Branches />
      </Suspense>
    ),
  },
  {
    path: 'SUSettings/Branches/:id/Edit',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <UpdateBranches />
      </Suspense>
    ),
  },
  {
    path: 'SUSettings/Locations',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <FleetLocations />
      </Suspense>
    ),
  },
  {
    path: 'SUSettings/Locations/:id/Edit',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <UpdateLocations />
      </Suspense>
    ),
  },
];

export default suRoutes;
