import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useGetAssetsList from '../../../../../../hooks/Finance/FixedAssets/useGetAssetsList';
import Pagination from '../../../../../../components/Shared/Pagination/Pagination';
import NoData from '../../../../../../components/NoData/NoData';
import NeatixModal from '../../../../../../components/Modal/NeatixModal';
import Add from '../../AddAssets';

const Additions = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const { assetsId } = useParams();

  const { AssetsList, loading, error } = useGetAssetsList();

  const filteredSearch = AssetsList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => f.parentAssetId === assetsId);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="row mb-2">
        <div className="col-lg-6">
          <div className="input-group flex-nowrap">
            <span className="input-group-text fw-bold">
              {t('Search')}
              {/* <i className="fa fa-search  text-theme ms-3"></i> */}
            </span>
            <div className="input-group">
              <div className="flex-fill position-relative">
                <div className="input-group border-0 ">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                    style={{ zIndex: 1020, right: '10px' }}>
                    <i className="fa fa-search opacity-5 text-theme"></i>
                  </div>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control ps-35px"
                    // placeholder={t('Search')}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 text-end">
          <div className="ms-auto">
            <Link
              to=""
              // to="AddAssets"
              className="btn me-1 btn-outline-theme"
              onClick={() => setShowAddModal(true)}>
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              إضـافة أصل تابع
            </Link>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        {currentData?.length === 0 ? (
          <NoData message={t('لا توجد بيانات لعرضها')} /> // عرض مكون NoData هنا عند عدم وجود بيانات
        ) : (
          <table className="table table-hover text-nowrap text-center">
            <thead className="table-active">
              <tr>
                <th>{t('الاسم')}</th>
                <th>{t('رقم الأصل')}</th>
                <th>{t('الفرع')}</th>
                <th>{t('القيمة الأصلية')}</th>
                <th>{t('ناريخ بدء الخدمة')}</th>

                <th></th>
              </tr>
            </thead>

            <tbody>
              {currentData?.map((item, index) => (
                <tr key={item.id}>
                  <td className="align-middle">
                    {i18n.language === 'en' ? item.name : item.name2}
                  </td>
                  <td className="align-middle">{item.assetNumber}</td>
                  <td className="align-middle">
                    {i18n.language === 'en' ? item.organizationName : item.organizationName2}
                  </td>
                  <td className="align-middle">{item.originalCost.toFixed(2)}</td>
                  <td className="align-middle">
                    {new Date(item.datePlacedInService).toLocaleDateString()}
                  </td>

                  <td className="text-end">
                    <button
                      type="button"
                      className="btn btn-outline-theme mx-1"
                      onClick={() => Navigate(`${item.id}/AssetsDetails`)}>
                      {t('تعديل الأصل')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Pagination
        filteredData={filteredSearch}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
      />

      <NeatixModal
        show={showAddModal}
        setShow={setShowAddModal}
        // bodyClassName="p-0"
        title="إضـافة أصل جديـد"
        size="xl"
        // showHeader={false}
      >
        <Add assetsId={assetsId} />
      </NeatixModal>
    </>
  );
};

export default Additions;
