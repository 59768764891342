import React, { Fragment, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { AppSettings } from '../../../../config/app-settings.js';
// import AccountsForm from "./AccountsForm";
import Loader from '../../../../components/Shared/Loader/Loader';
import useGetCategoriesList from '../../../../hooks/INV/useGetCategoriesList';
import { CategoriesModels } from '../../../../store/INV/Categories/CategoriesModels';
import EditCategoryInv from './EditCategoryInv';
import AddCategoryInv from './AddCategoryInv';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import { Card, CardExpandToggler, CardHeader } from '../../../../components/card/card';
import Loading from '../../../../components/Shared/Loader/Loading';

const Index = () => {
  const [categoryId, setCategoryId] = useState<any>(null);
  const { t, i18n } = useTranslation();
  // const { ChartAccountsId } = useParams();
  const [categoryDetails, setCategoryDetails] = useState<CategoriesModels | undefined>();
  const { CategoriesList: data, loading, error } = useGetCategoriesList();

  //#region updateBreadcrumb

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الفئـات',
      link: null,
    },
  ];
  //#endregion

  //#region context tree file-node.has-sub
  const context = useContext(AppSettings);
  useEffect(() => {
    const handleFileArrowClick = (event: any) => {
      event.preventDefault();
      const node = event.target.closest('.file-node.has-sub');
      if (node) {
        node.classList.toggle('expand');
      }
    };

    const handleFileInfoClick = (event: any) => {
      event.preventDefault();
      const node = event.target.closest('.file-node');
      if (node) {
        const fileInfoNodes = document.querySelectorAll('.file-node');
        fileInfoNodes.forEach((otherNode) => {
          if (otherNode !== node) {
            otherNode.classList.remove('selected');
          }
        });
        node.classList.add('expand');
        node.classList.add('selected');
      }
    };

    const fileHasSubNodes = document.querySelectorAll('.file-node.has-sub');
    fileHasSubNodes.forEach((node) => {
      const fileArrow = node.querySelector('.file-link > .file-arrow');
      fileArrow?.addEventListener('click', handleFileArrowClick);
    });

    const fileInfoNodes = document.querySelectorAll('.file-node');
    fileInfoNodes.forEach((node) => {
      const fileInfo = node.querySelector('.file-link > .file-info');
      fileInfo?.addEventListener('click', handleFileInfoClick);
    });

    context.setAppContentFullHeight(true);

    return function cleanUp() {
      context.setAppContentFullHeight(false);
      fileHasSubNodes.forEach((node) => {
        const fileArrow = node.querySelector('.file-link > .file-arrow');
        fileArrow?.removeEventListener('click', handleFileArrowClick);
      });
      fileInfoNodes.forEach((node) => {
        const fileInfo = node.querySelector('.file-link > .file-info');
        fileInfo?.removeEventListener('click', handleFileInfoClick);
      });
    };
  }, [data, context]);

  //#endregion

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="الفئـات" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddCategoriesINV"
            onClick={() => setCategoryId(null)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('اضاف فئة رئيسية')}
          </Link>
        </div>
      </div>

      {/* <Loader loading={loading} /> */}
      {/* <hr /> */}

      <div className="row">
        <div className="col-lg-5">
          <Card className="">
            <Loading loading={loading} error={error}>
              <CardHeader className="d-flex justify-content-between align-items-center ">
                <span className=" fw-bold m-1">الفئـات</span>

                <CardExpandToggler />

                {/* <div className=" m-0 bg-theme bg-opacity-20 rounded-0 p-1">
                {i18n.language === 'en' ? categoryDetails?.name : categoryDetails?.name}
              </div> */}

                {/* <a href="#/" className="text-inverse text-opacity-50 text-decoration-none"><i className="fa fa-fw fa-trash"></i></a> */}
              </CardHeader>

              <div className="file-manager-sidebar-content">
                <PerfectScrollbar className="h-100 p-2">
                  <div className="file-tree  ">
                    {data
                      ?.filter((f) => f.categoryId === null)
                      ?.map((tree, idx) => {
                        return (
                          <ItemTree
                            key={tree.id}
                            tree={tree}
                            setTreeDetails={setCategoryDetails}
                            setCategoryId={setCategoryId}
                          />
                        );
                      })}
                  </div>
                </PerfectScrollbar>
              </div>
            </Loading>
          </Card>
        </div>

        <div className="col-lg-7">
          {categoryDetails && <EditCategoryInv categoryDetails={categoryDetails} />}
        </div>
      </div>

      <MedModalComponent title={t('اضافة فئة')} id="modalAddCategoriesINV">
        <AddCategoryInv CategoryId={categoryId} />
      </MedModalComponent>
    </div>
  );
};
export default Index;
//_______________________________________________________________________________________________

type PropsItemTree = {
  tree: CategoriesModels;
  setTreeDetails: React.Dispatch<SetStateAction<CategoriesModels | undefined>>;
  setCategoryId: React.Dispatch<React.SetStateAction<any>>;
};
const ItemTree: React.FC<PropsItemTree> = ({ tree, setTreeDetails, setCategoryId }) => {
  const { CategoriesList: data } = useGetCategoriesList();

  const { i18n } = useTranslation();
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <Fragment key={tree.id}>
      <div className="d-flex justify-content-between p-1">
        <div
          className={`file-node ${
            data?.filter((f) => f.categoryId === tree?.id)?.length && 'has-sub'
          }`}>
          <Link to="" className="file-link">
            <span className="file-arrow"></span>
            <span
              ref={linkRef}
              className="file-info ms-1"
              onClick={() => {
                setTreeDetails(tree);
                setCategoryId(tree.id);
              }}>
              <span className="file-icon">
                <i className={` ${tree.icon ? tree.icon : ' bi bi-app fa-lg'} text-theme`}></i>
              </span>
              <span className="file-text fs-6">
                {i18n.language === 'en' ? tree.name : tree.name2}
              </span>
            </span>
          </Link>

          <div className="file-tree">
            {data
              ?.filter((node) => node.categoryId === tree.id)
              ?.map((node, idx) => {
                return (
                  <ItemTree
                    key={node.id}
                    tree={node}
                    setTreeDetails={setTreeDetails}
                    // setCategoryId={node.id}
                    setCategoryId={setCategoryId}
                  />
                );
              })}
          </div>
        </div>
        <Link
          to=""
          data-bs-toggle="modal"
          data-bs-target="#modalAddCategoriesINV"
          // className="ms-4 btn btn-sm me-1 btn-outline-theme h-25"
          onClick={() => {
            linkRef?.current?.click();
            setCategoryId(tree.id);
          }}>
          <i className="fas fa-lg fa-fw  fs-4 bi bi-plus-square-fill"></i>
        </Link>
      </div>
    </Fragment>
  );
};
