import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
// import { getData, postData } from "../../Axios/axiosData";

const initialState = {
  PeriodTimeList: [],
  PersonlListWithPayrol: [],
  PayrollActionList: [],
  AssignmentActionList: [],
  RunResultList: [],
  loading: false,
  error: null,
};

export const ActinRunPyroll = createAsyncThunk(
  'RunPyroll/ActinRunPyroll',
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData('Payroll/RunPayroll' , item);
      const { data } = await ntxAPI.post('Payroll/RunPayroll', item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetPeriodTimeByPayrollId = createAsyncThunk(
  'RunPyroll/GetPeriodTimeByPayrollId',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Payroll/GetPeriodTimeByPayrollId?PayrollId=${id}`);
      const { data } = await ntxAPI.get(`Payroll/GetPeriodTimeByPayrollId?PayrollId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetPersonlListWithPayrol = createAsyncThunk(
  'RunPyroll/GetPersonlListWithPayrol',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Payroll/GetPersonlListWithPayrol`);
      const { data } = await ntxAPI.get(`Payroll/GetPersonlListWithPayrol`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetPayrollActionList = createAsyncThunk(
  'RunPyroll/GetPayrollActionList',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await getData(`Payroll/GetPayrollActionListByPayrollId?PayrollId=${id}`);
      const { data } = await ntxAPI.get(`Payroll/GetPayrollActionListByPayrollId?PayrollId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetAssignmentActionList = createAsyncThunk(
  'RunPyroll/GetAssignmentActionList',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await getData(`Payroll/GetAssignmentActionListByPayrollActionId?PayrollActionId=${id}`);
      const { data } = await ntxAPI.get(
        `Payroll/GetAssignmentActionListByPayrollActionId?PayrollActionId=${id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetRunResultList = createAsyncThunk(
  'RunPyroll/GetRunResultList',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await getData(`Payroll/GetRunResultListByAssignmentActionId?AssignmentActionId=${id}`);
      const { data } = await ntxAPI.get(
        `Payroll/GetRunResultListByAssignmentActionId?AssignmentActionId=${id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const DeletePayrollAction = createAsyncThunk(
  'RunPyroll/DeletePayrollAction',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await postData(`Payroll/DeletePayrollAction?PayrollActionId=${id}`);
      const { data } = await ntxAPI.post(`Payroll/DeletePayrollAction?PayrollActionId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const ConfirmPayrollAction = createAsyncThunk(
  'RunPyroll/ConfirmPayrollAction',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.post(`Payroll/ConfirmPayrollAction?PayrollActionId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const sendPayrollActionToAme = createAsyncThunk(
  'RunPyroll/sendPayrollActionToAme',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ntxAPI.post(`Payroll/SendPayrollActionToAme?PayrollActionId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createAccountPayroll = createAsyncThunk('RunPyroll/createAccount', async (item, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const { data } = await ntxAPI.post(`Payroll/CreateAccount` , item);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

const RunPayrollSlice = createSlice({
  name: 'RunPyroll',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /////// get GetPeriodTimeByPayrollId
      .addCase(GetPeriodTimeByPayrollId.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.PeriodTimeList = [];
      })
      .addCase(GetPeriodTimeByPayrollId.fulfilled, (state, action) => {
        state.loading = false;
        state.PeriodTimeList = action.payload;
      })
      .addCase(GetPeriodTimeByPayrollId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      /////// GetPersonlListWithPayrol
      .addCase(GetPersonlListWithPayrol.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.PersonlListWithPayrol = [];
      })
      .addCase(GetPersonlListWithPayrol.fulfilled, (state, action) => {
        state.loading = false;
        state.PersonlListWithPayrol = action.payload;
      })
      .addCase(GetPersonlListWithPayrol.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      /////// Get Payroll Action List
      .addCase(GetPayrollActionList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.PayrollActionList = [];
      })
      .addCase(GetPayrollActionList.fulfilled, (state, action) => {
        state.loading = false;
        state.PayrollActionList = action.payload;
      })
      .addCase(GetPayrollActionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      /////// Get Assignment Action List
      .addCase(GetAssignmentActionList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.AssignmentActionList = [];
      })
      .addCase(GetAssignmentActionList.fulfilled, (state, action) => {
        state.loading = false;
        state.AssignmentActionList = action.payload;
      })
      .addCase(GetAssignmentActionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      /////// Get Run Result List
      .addCase(GetRunResultList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.RunResultList = [];
      })
      .addCase(GetRunResultList.fulfilled, (state, action) => {
        state.loading = false;
        state.RunResultList = action.payload;
      })
      .addCase(GetRunResultList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default RunPayrollSlice.reducer;
