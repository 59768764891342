import React, { useEffect, useState } from 'react';
import useGetOrganizations from '../../../../../hooks/HR/use-get-organizations';
import Select from 'react-select';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { assignmentItemToMultiInventories } from '../../../../../store/INV/AssignmentItems/assignmentItemsSlice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getInventoriesListByItemId } from '../../../../../store/INV/MasterItems/MasterItemsSlice';
import Loading from '../../../../../components/Shared/Loader/Loading';
//

const AssignmentItemToMultiInventories = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const [InventoryIds, setInventoryIds] = useState<any[]>([]);
  const { InventoryOptions, loading: loading1, error } = useGetOrganizations();
  const { ItemId } = useParams();

  useEffect(() => {
    if (ItemId) {
      dispatch(getInventoriesListByItemId(ItemId));
    }
  }, [ItemId, dispatch]);

  const {
    InventoriesListByItemId: data,
    loadingInventoriesByItemId: loading,
    error: error1,
  } = useAppSelector((a) => a.MasterItemsINV);

  useEffect(() => {
    if (data && data?.length > 0) {
      const selectedInventoryIds = data?.map((inv: any) => inv.id); // استخرج الـ IDs من البيانات
      setInventoryIds(selectedInventoryIds); // ضبط القيم في InventoryIds
    }
  }, [data]);

  
  const onSubmit = () => {
    setIsSubmitting(true);

    let body = {
      inventoryIds: InventoryIds,
      itemId: ItemId,
    };

    dispatch(assignmentItemToMultiInventories(body))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Add' });
      })
      .catch((error: any) => {
        setIsSubmitting(false);

        CustomAlert({ action: 'Error' });
      });
  };

  return (
    <div>
      <Loading loading={loading || loading1} error={error || error1}>
        <div className="col-12">
          <label className="form-label">المخازن</label>
          <div className="input-group flex-nowrap">
            <Select
              classNamePrefix="react-select"
              className=" w-100"
              required
              isMulti
              options={InventoryOptions}
              value={InventoryOptions?.filter((option: any) => InventoryIds.includes(option.value))} // تحديد القيم الافتراضية
              onChange={(e) => {
                setInventoryIds(e?.map((m: any) => m.value));
              }}
            />
          </div>
        </div>
        <div className="text-end mt-3">
          <button
            disabled={isSubmitting}
            onClick={() => onSubmit()}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              // <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
              <i className="fa fa-save btn-lg fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
        </div>
      </Loading>
    </div>
  );
};

export default AssignmentItemToMultiInventories;
