import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlChartOfAccountsDetailsList } from '../../../store/Finance/ChartAccounts/ChartAccountsDetailsSlice';
import { useTranslation } from 'react-i18next';
import useGetAccounts from './useGetAccounts';
import useGetCompanies from './useGetCompanies';
import useGetBranches from './useGetBranches';
import useGetCostCenters from './useGetCostCenters';

const useGetCodeCombination = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { OptionsAccounts } = useGetAccounts();
  const { OptionsCompanies } = useGetCompanies();
  const { OptionsBranches } = useGetBranches();
  const { OptionsCostCenters } = useGetCostCenters();

  useEffect(() => {
    if (ChartAccountsDetailsList?.length === 0) {
      dispatch(GetGlChartOfAccountsDetailsList());
    }
  }, []);
  const { ChartAccountsDetailsList, loading, error } = useAppSelector(
    (state) => state.ChartAccountsDetails
  );

  const GetCodeCombination = (
    GlCompanyId: any,
    GlBrancheId: any,
    AccountId: any,
    costCenterId: any
  ) => {
    let CompanyNumber = ChartAccountsDetailsList.find((f) => f.id === GlCompanyId)?.accountNumber;
    let BranchNumber = ChartAccountsDetailsList.find((f) => f.id === GlBrancheId)?.accountNumber;
    let AccountNumber = ChartAccountsDetailsList.find((f) => f.id === AccountId)?.accountNumber;
    let CostCenterNumber = ChartAccountsDetailsList.find(
      (f) => f.id === costCenterId
    )?.accountNumber;

    var codeCombination = [
      CompanyNumber || '00',
      BranchNumber || '0000',
      AccountNumber || '00000000',
      CostCenterNumber || '00000',
    ]
      .filter(Boolean)
      .join('  -  ');

    return codeCombination;
  };

  const GetCodeCombinationDetails = (
    GlCompanyId: any,
    GlBrancheId: any,
    AccountId: any,
    costCenterId: any
  ) => {
    return (
      <div className="text-start">
        <p className="m-0">
          {OptionsCompanies?.find((f) => f.value === GlCompanyId)?.accountShow || (
            <div className="d-flex justify-content-between">
              <div>########</div>
              <div className="text-theme ms-2">00</div>
            </div>
          )}
        </p>
        <p className="m-0">
          {OptionsBranches?.find((f) => f.value === GlBrancheId)?.accountShow || (
            <div className="d-flex justify-content-between">
              <div>########</div>
              <div className="text-theme ms-2">0000</div>
            </div>
          )}
        </p>
        <p className="m-0">
          {OptionsAccounts?.find((f) => f.value === AccountId)?.accountShow || (
            <div className="d-flex justify-content-between">
              <div>########</div>
              <div className="text-theme ms-2">00000000</div>
            </div>
          )}
        </p>
        <p className="m-0">
          {OptionsCostCenters?.find((f) => f.value === costCenterId)?.accountShow || (
            <div className="d-flex justify-content-between">
              <div>########</div>
              <div className="text-theme ms-2">00000</div>
            </div>
          )}
        </p>
      </div>
    );
  };

  //_______

  return {
    GetCodeCombination,
    GetCodeCombinationDetails,
    loading,
    error,
  };
};

export default useGetCodeCombination;
