import React from 'react';

interface UnApplyPrepaymentsModalProps {
  data: Array<any>;
  onSelectPrepayment: (selectedPayment: any) => void; // Add this prop
}

const UnApplyPrepayments: React.FC<UnApplyPrepaymentsModalProps> = ({
  data,
  onSelectPrepayment,
}) => {
  return (
    <>
      <div className="table-responsive rounded-2">
        <table className="table table-borderless table-hover text-nowrap ">
          <thead className="bg-theme bg-opacity-30">
            <tr>
              <th>رقم الدفعة</th>
              <th>تاريخ الدفعة</th>
              <th>المبلع</th>
              <th>amountUnApply</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((payment, index) => {
              return (
                <tr key={index}>
                  <td className="align-middle">{payment.code}</td>
                  <td className="align-middle">
                    {new Date(payment.depositDate).toLocaleDateString()}
                  </td>
                  <td className="align-middle"> {payment.amount}</td>
                  <td className="align-middle">{payment.amountUnApply}</td>
                  <td className="align-middle ">
                    <button
                      // disabled={isAlreadySelected}
                      className="btn btn-outline-theme w-100"
                      onClick={() => onSelectPrepayment(payment)} // استدعاء دالة الاختيار
                    >
                      إختيــــار
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UnApplyPrepayments;
