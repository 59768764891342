import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { AttributesModels } from './AttributesModels';

export const addAttribute = createAsyncThunk(
  'Attributes/addAttribute',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddAttribute', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAttribute = createAsyncThunk(
  'Attributes/updateAttribute',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/UpdateAttribute', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAttributeList = createAsyncThunk(
  'Attributes/getAttributeList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetAttributeList');
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAttributeById = createAsyncThunk(
  'Attributes/getAttributeById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetAttributeById', { params: { id: id } });
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const assignmentAttributes = createAsyncThunk(
  'Attributes/assignmentAttributes',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AssignmentAttributes', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const assignmentAttributeToMultiItems = createAsyncThunk(
  'Attributes/assignmentAttributeToMultiItems',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AssignmentAttributeToMultiItems', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);





const initialState: {
  AttributesList: AttributesModels[];
  AttributesById: AttributesModels;
  loading: boolean;
  loadingById: boolean;
  error: any | null;
} = {
  AttributesList: [],
  AttributesById: {} as AttributesModels,
  loading: false,
  loadingById: false,
  error: null,
};

const AttributesSlice = createSlice({
  name: 'Attributes',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getAttributeList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAttributeList.fulfilled, (state, action: PayloadAction<AttributesModels[]>) => {
        state.loading = false;
        state.AttributesList = action.payload;
      })
      .addCase(getAttributeList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getAttributeById.pending, (state, action) => {
        state.loadingById = true;
      })
      .addCase(getAttributeById.fulfilled, (state, action: PayloadAction<AttributesModels>) => {
        state.loadingById = false;
        state.AttributesById = action.payload;
      })
      .addCase(getAttributeById.rejected, (state, action: PayloadAction<any>) => {
        state.loadingById = false;
        state.error = action.payload;
      })
  },
});

export default AttributesSlice.reducer;
