import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import { PaymentTermsModels } from '../../../../store/Finance/PaymentTerms/PaymentTermsModels';
import {
  updatePaymentTerms,
  getPaymentTermsList,
} from '../../../../store/Finance/PaymentTerms/PaymentTermsSlice';
import { PaymentTermsSchema } from '../../../ValidationForm/validationSchema';

interface Props {
  DataById: PaymentTermsModels;
}

const UpdatePaymentTerms = ({ DataById }: Props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      id: DataById.id ?? '',
      name: DataById.name ?? '',
      name2: DataById.name2 ?? '',
      description: DataById.description ?? '',
      period: DataById.period ?? '',
    },
    enableReinitialize: true,
    validationSchema: PaymentTermsSchema(t),
    onSubmit: (values) => {
      dispatch(
        updatePaymentTerms({
          id: values.id,
          name: values.name,
          name2: values.name,
          description: values.description,
          period: values.period,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
            dispatch(getPaymentTermsList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('LocalName')}</label>
            <Form.Control
              className="form-control"
              type="text"
              autoComplete="off"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
            />
            <div className="text-danger small">{formik.touched.name2 && formik.errors.name2}</div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('GlobalName')}</label>
            <Form.Control
              className="form-control "
              type="text"
              autoComplete="off"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={!!(formik.touched.name && formik.errors.name)}
            />
            <div className="text-danger small">{formik.touched.name && formik.errors.name}</div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('Period')}</label>
            <Form.Control
              className="form-control "
              type="text"
              autoComplete="off"
              name="period"
              onChange={formik.handleChange}
              value={formik.values.period}
              isInvalid={!!(formik.touched.period && formik.errors.period)}
            />
            <div className="text-danger small">{formik.touched.period && formik.errors.period}</div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('Note')}</label>
            <Form.Control
              className="form-control "
              type="text"
              autoComplete="off"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-outline-theme mb-1">
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fas fa-lg fa-fw me-2 fa fa-save"></i>
          )}
          {t('Save')}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-outline-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalForm">
          <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
        </button>
      </div>
    </Form>
  );
};

export default UpdatePaymentTerms;
