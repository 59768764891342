import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPaymentMethod } from '../../store/HR/Payroll/PayrollSlice';

const useGetPaymentMethod = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (PaymentMethodList?.length === 0) {
      dispatch(GetPaymentMethod());
    }
  }, []);
  const { PaymentMethodList, loading, error } = useSelector((state) => state.Payrolls);

  return { PaymentMethodList, loading, error };
};

export default useGetPaymentMethod;
