import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const fassetsMenu = [
  {
    System: 'ASSETS',
    isActive: false,
    menu: [

      { is_header: true, title: 'نظام إدارة الأصـول', isActive: true }, // عنوان إدارة الأصول

      {
        path: 'SystemDashboard',
        icon: 'fas fa-home', // أيقونة الصفحة الرئيسية
        title: 'HomePage',
        isActive: true,
      },

      {
        path: 'Finance/FA/FaCategories',
        icon: 'fas fa-tags', // أيقونة فئات الأصول
        title: 'فئات الأصول',
        isActive: true,
      },

      {
        path: 'Finance/FA/FaAssets',
        icon: 'fas fa-boxes', // أيقونة الأصول
        title: 'الأصول',
        isActive: true,
      },
      // { is_header: true, title: 'نظام إدارة الأصـول', isActive: true },
      // {
      //   path: 'dashboard',
      //   icon: 'fas fa-home',
      //   title: 'HomePage',
      // },

      // {
      //   path: 'Finance/FA/',
      //   icon: 'bi bi-aspect-ratio-fill',
      //   title: 'الأصول',
      //   children: [
      //     {
      //       path: 'Finance/FA/FaCategories',
      //       title: 'فئات الأصول',
      //       isActive: true,
      //     },
      //     {
      //       path: 'Finance/FA/FaAssets',
      //       title: 'الأصول',
      //       isActive: true,
      //     },
      //   ],
      // },
    ],
  },
];

export default fassetsMenu;
