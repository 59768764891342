import React from 'react';
 import RequestsInProgress from './RequestsInProgress';
import Leaves from './Leaves';
import SalaryDetails from './SalaryDetails';
import './UserDashboardStyle.css';
import UserProfile from './UserProfile/UserProfile';
import { AppSettings } from '../../../config/app-settings';

// container mt-5
const UserDashboard = () => {
  const context = React.useContext(AppSettings);

  React.useEffect(() => {
    context.setAppSidebarNone(true);
    context.setsysCode('USER');
    return function cleanUp() {
      context.setAppSidebarNone(false);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row d-flex align-items-stretch">
        <div className="col-md-7 mb-4">
          <UserProfile />
        </div>
        <div className="col-md-5 mb-4">
          {/* <SalaryDetails /> */}
          <RequestsInProgress />
        </div>
      </div>

      <div className="row d-flex align-items-stretch">
        {/* بروفايل الموظف */}
        {/* <div className="col-md-5 mb-4">
          <div className="h-100">
            <EmployeeProfile />
          </div>
        </div> */}

        {/* الطلبات تحت الإجراء */}
        {/* <div className="col-md-7 mb-4">
          <div className="h-100">
            <RequestsInProgress />
          </div>
        </div> */}

        {/* الإجازات */}
        {/* <div className="col-md-5 mb-4">
          <div className="h-100">
            <Leaves />
          </div>
        </div> */}
      </div>

      <div className="row d-flex align-items-stretch"></div>

      {/* <div className="row d-flex align-items-stretch">
         <div className="col-md-12 mb-4">
          <div className="h-100">
            <ServiceDuration />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default UserDashboard;
