 

 
//_ INV
import Inventory from '../../pages/INV/INVSettings/Inventory/Inventory/Index';
import SubInventory from '../../pages/INV/INVSettings/Inventory/SubInventory/SubInventory';
import AssignmentItems from '../../pages/INV/INVSettings/Inventory/AssignmentItems/AssignmentItems';
import CategoriesINV from '../../pages/INV/INVSettings/Categories/Index';
import MasterItems from '../../pages/INV/INVSettings/MasterItems/Index';
import SuppliersInv from '../../pages/INV/INVSettings/Suppliers/Index';
import EditSuppliersInv from '../../pages/INV/INVSettings/Suppliers/EditSuppliers';

import MasterItemsDetails from '../../pages/INV/INVSettings/MasterItems/MasterItemsDetails/MasterItemsDetails';
import UnitsOfMeasure from '../../pages/INV/INVSettings/UnitsOfMeasure/Index';

import Attributes from '../../pages/INV/INVSettings/Attributes/Index';
import UpdateAttributes from '../../pages/INV/INVSettings/Attributes/Update';

import TransactionTypes from '../../pages/INV/INVSettings/TransactionTypes/Index';
import TypeReasons from '../../pages/INV/INVSettings/TransactionTypes/TypeReasons/TypeReasons';

import InvIssue from '../../pages/INV/Transactions/InvIssue/Index';
import AddInvIssue from '../../pages/INV/Transactions/InvIssue/Add';
import UpdateInvIssue from '../../pages/INV/Transactions/InvIssue/Update';

import InvReceive from '../../pages/INV/Transactions/InvReceive/Index';
import AddInvReceive from '../../pages/INV/Transactions/InvReceive/Add';

import InvMove from '../../pages/INV/Transactions/InvMove/Index';
import AddInvMove from '../../pages/INV/Transactions/InvMove/Add';

import UpdateCost from '../../pages/INV/Transactions/UpdateCost/Index';
import AddUpdateCost from '../../pages/INV/Transactions/UpdateCost/Add';

import InventoryCount from '../../pages/INV/InventoryCount/InventoryCount/Index';
import AddInventoryCount from '../../pages/INV/InventoryCount/InventoryCount/Add';
import InventoryCountEntries from '../../pages/INV/InventoryCount/InventoryCountEntries/InventoryCountEntries';

 


const inventoryRoutes = [
  {
    path: 'INV/InventorySettings/*',
    children: [
      { path: 'Inventory', element: <Inventory /> },
      { path: 'Inventory/:Id/SubInventory', element: <SubInventory /> },
      { path: 'Inventory/:inventoryId/AssignmentItems', element: <AssignmentItems /> },
      { path: 'Categories', element: <CategoriesINV /> },
      { path: 'MasterItems', element: <MasterItems /> },
      { path: 'MasterItems/:ItemId/ItemsDetails', element: <MasterItemsDetails /> },

      { path: 'Suppliers', element: <SuppliersInv /> },
      { path: 'Suppliers/:Id/Edit', element: <EditSuppliersInv /> },

      { path: 'UnitsOfMeasure', element: <UnitsOfMeasure /> },
      
      { path: 'TransactionTypes', element: <TransactionTypes /> },
      { path: 'TransactionTypes/:transactionType/TypeReasons', element: <TypeReasons /> },

      { path: 'Attributes', element: <Attributes /> },
      { path: 'Attributes/:attributId/UpdateAttributes', element: <UpdateAttributes /> },
    ],
  },

  {
    path: 'INV/Transactions/*',
    children: [
      { path: 'InvIssue', element: <InvIssue /> },
      { path: 'InvIssue/AddInvIssue', element: <AddInvIssue /> },
      { path: 'InvIssue/:Id/UpdateInvIssue', element: <UpdateInvIssue /> },

      { path: 'InvReceive', element: <InvReceive /> },
      { path: 'InvReceive/AddInvReceive', element: <AddInvReceive /> },

      { path: 'InvMove', element: <InvMove /> },
      { path: 'InvMove/AddInvMove', element: <AddInvMove /> },

      { path: 'UpdateCost', element: <UpdateCost /> },
      { path: 'UpdateCost/AddUpdateCost', element: <AddUpdateCost /> },
    ],
  },

  {
    path: 'INV/InventoryCount/*',
    children: [
      { path: '*', element: <InventoryCount /> },
      { path: 'AddInventoryCount', element: <AddInventoryCount /> },
      { path: ':invCountId/InventoryCountEntries', element: <InventoryCountEntries /> },
    ],
  },
];

export default inventoryRoutes;
