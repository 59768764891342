import { useFormik } from 'formik';
import React, { Fragment, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  AddNtxPeoplesContacts,
  clearContactsListByPeopleId,
  GetPeoplesContactsByPeopleId,
} from '../../../../store/NTX/Peoples/PeoplesContactSlice';
import { Form } from 'react-bootstrap';
import useLookupTitle from '../../../../hooks/Lookups/use-Lookup-Title';
import { useTranslation } from 'react-i18next';
import PeoplesContactsList from './PeoplesContactsList';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Loading from '../../../../components/Shared/Loader/Loading';

type Props = {
  peopleId: string | undefined;
};

const PeoplesContacts: React.FC<Props> = ({ peopleId }) => {
  const dispatch = useAppDispatch();
  const { TITLE } = useLookupTitle();
  const { t, i18n } = useTranslation();
  // const Navigate = useNavigate();

  useEffect(() => {
    dispatch(GetPeoplesContactsByPeopleId(peopleId ?? ''));
    return () => {
      dispatch(clearContactsListByPeopleId());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleId]);

  const { loading, error } = useAppSelector((state) => state.PeoplesContacts);

  const formik = useFormik({
    initialValues: {
      id: '',
      peopleId: peopleId,
      peopleSiteId: '',
      title: '',
      name: '',
      name2: '',
      prefix: '',
      phone: '',
      mobile: '',
      department: '',
      emailAddress: '',
      url: '',
      fax: '',
      extraInformationId: '',
    },
    // validationSchema:  ,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        AddNtxPeoplesContacts({
          //   id: "",
          peopleId: peopleId || null,
          peopleSiteId: null, // values.peopleSiteId ,
          name: values.name,
          name2: values.name2,
          prefix: values.prefix,
          title: values.title,
          phone: values.phone,
          mobile: values.mobile,
          department: values.department,
          emailAddress: values.emailAddress,
          url: values.url,
          fax: values.fax,
          extraInformationId: null,
        })
      )
        .unwrap()
        .then((res) => {
          // console.log(res);
          // if (res?.succeeded) {
          formik.resetForm();
          CustomAlert({ action: 'Add' });
          // } else {
          formik.setSubmitting(false);
          // CustomAlert({ action: 'Error' });
          // }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  //____________________________

  return (
    <Fragment>
      <Loading loading={loading} error={error}>
        <>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('title')}</label>
                  <Form.Select
                    className="form-select"
                    name="title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    //   isInvalid={formik.touched.title && formik.errors.title}
                  >
                    <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {TITLE &&
                      TITLE?.map((item: any, idx: number) =>
                        item.list?.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.title}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('LocalName')}</label>
                  <Form.Control
                    className="form-control text-center"
                    type="text"
                    autoComplete="off"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                </div>
              </div>
              <div className="col-xl-4 ">
                <div className="form-group mb-3">
                  <label className="form-label">{t('GlobalName')}</label>
                  <Form.Control
                    className="form-control text-center"
                    type="text"
                    autoComplete="off"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Phone')}</label>
                  <Form.Control
                    className="form-control text-center"
                    type="text"
                    autoComplete="off"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    isInvalid={!!(formik.touched.phone && formik.errors.phone)}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('MobileNumber')}</label>
                  <Form.Control
                    className="form-control text-center"
                    type="text"
                    autoComplete="off"
                    name="mobile"
                    onChange={formik.handleChange}
                    value={formik.values.mobile}
                    isInvalid={!!(formik.touched.mobile && formik.errors.mobile)}
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('WebsiteLink')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    name="url"
                    onChange={formik.handleChange}
                    value={formik.values.url}
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Email')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    name="emailAddress"
                    onChange={formik.handleChange}
                    value={formik.values.emailAddress}
                  />
                </div>
              </div>

              <div className="col-xl-3 text-end">
                <div className="form-group pt-4">
                  <div className="">
                    <button
                      disabled={formik.isSubmitting}
                      type="submit"
                      className="btn me-1 btn-theme mb-1">
                      {formik.isSubmitting ? (
                        <div className="spinner-border spinner-border-sm me-2"></div>
                      ) : (
                        <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                      )}
                      {t('Add')}
                    </button>
                    <button
                      type="button"
                      className=" btn me-1 btn-default mb-1"
                      data-bs-dismiss="modal"
                      id="closeModalCustomersContacts"
                      // onClick={() => Navigate(-1)}
                    >
                      <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>

          <div className="">{peopleId && <PeoplesContactsList peopleId={peopleId} />}</div>
        </>
      </Loading>
    </Fragment>
  );
};

export default PeoplesContacts;
