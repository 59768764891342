import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { formOrganization } from '../../ValidationForm/validationSchema';
import {
  getOrganizations,
  insertOrganization,
} from '../../../store/HR/Organizations/organizationSlice';
import useLookupOrgType from '../../../hooks/Lookups/use-Lookup-OrgType';
import useLookupOrgClass from '../../../hooks/Lookups/use-Lookup-OrgClass';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import useGetLocations from '../../../hooks/HRSetting/use-get-locations';
import useGetChartAccountsDetailsList from '../../../hooks/Finance/useGetChartAccountsDetailsList';
import SwalAlert from '../../../components/Shared/Alert/SwalAlert';

const AddDepartment = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { ORG_TYPE } = useLookupOrgType();

  const { dataORGCLASS } = useLookupOrgClass();

  const { Locations } = useGetLocations();

  const { OptionsAccounts, loading: loadingAccounts } = useGetChartAccountsDetailsList();

  const formik = useFormik({
    initialValues: {
      // ID: "",
      name2: '',
      name: '',
      locationId: '',
      externalFlag: false,
      dateFrom: '',
      description: '',
      accountId: '',
      type: props.type ?? '',

      organizationInformation: props.type === '150' ? 'HR_ORG' : '',

      InternalOrExternal: '', // ليس للبوست
    },

    validationSchema: formOrganization(t),

    onSubmit: (values) => {
      if (values.type === '30') {
        SwalAlert({
          icon: 'info',
          text: 'يرجى التواصل مع الدعم الفني لإتمام عملية إضافة شركة جديدة.',
        });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        insertOrganization({
          businessGroupId: null,
          locationId: values.locationId,
          dateFrom: values.dateFrom,
          name: values.name,
          name2: values.name2,
          // externalFlag:  values.externalFlag,
          externalFlag: values.InternalOrExternal === 'External' ? true : false,
          description: values.description,
          type: values.type,
          organizationInformation: values.organizationInformation,
          dateTo: null,
          integrationField: '',
          accountId: values.accountId,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          document.getElementById('closeModalDepartment').click();
          CustomAlert({ action: 'Add' });
          dispatch(getOrganizations());
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
      // formik.setSubmitting(false);
    },
  });

  console.log(formik.values.InternalOrExternal);

  // handel multiSelect to this data ship (value1,value2,value3,value4)
  const handleMultiSelectChange = (selectedOptions) => {
    const formattedOptions = selectedOptions?.map((option) => option.value).join(',');
    formik.setFieldValue('organizationInformation', formattedOptions);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('LocalName')}</label>
            <Form.Control
              className="form-control"
              type="text"
              autoComplete="off"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={formik.touched.name2 && formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('GlobalName')}</label>
            <Form.Control
              className="form-control"
              type="text"
              autoComplete="off"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('location')}</label>
            <Form.Select
              className="form-select"
              name="locationId"
              onChange={formik.handleChange}
              value={formik.values.locationId}
              isInvalid={formik.touched.locationId && formik.errors.locationId}>
              <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
              {Locations?.map((item, idx) => (
                <option key={++idx} value={item.id}>
                  {i18n.language === 'ar' ? item.name2 : item.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{formik.errors.locationId}</Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('InternalOrExternal')}</label>
            <Form.Select
              className="form-select"
              name="InternalOrExternal"
              onChange={formik.handleChange}
              value={formik.values.InternalOrExternal}
              isInvalid={formik.touched.InternalOrExternal && formik.errors.InternalOrExternal}>
              <option key={1} value="">
                {i18n.language === 'ar' ? 'إختر' : 'Choose'}
              </option>
              <option key={2} value="Internal">
                {i18n.language === 'ar' ? 'داخلي' : 'Internal'}
              </option>
              <option key={3} value="External">
                {i18n.language === 'ar' ? 'خارجي' : 'External'}
              </option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.InternalOrExternal}
            </Form.Control.Feedback>
          </div>
        </div>

        {/* <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('InternalOrExternal')}</label>
            <Form.Check
              style={{ margin: '10px 20px' }}
              className="form-check"
              type="switch"
              name="externalFlag"
              onChange={formik.handleChange}
              value={formik.values.externalFlag}
            />
          </div>
        </div> */}
      </div>

      <div className="row">
        {!props.type && (
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Type')}</label>
              <Form.Select
                disabled={props.type !== undefined}
                className="form-select"
                name="type"
                onChange={formik.handleChange}
                value={formik.values.type}
                isInvalid={formik.touched.type && formik.errors.type}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {ORG_TYPE?.map((item) =>
                  item.list?.map((list) => (
                    <option key={list.lookupCode} value={list.lookupCode}>
                      {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                    </option>
                  ))
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{formik.errors.type}</Form.Control.Feedback>
            </div>
          </div>
        )}

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('Start Date')}</label>
            <Form.Control
              type="date"
              id="START_DATE"
              className="form-control"
              name="dateFrom"
              onChange={formik.handleChange}
              value={formik.values.dateFrom}
              isInvalid={formik.touched.dateFrom && formik.errors.dateFrom}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.dateFrom}</Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('الحساب')}</label>
            <Select
              classNamePrefix="react-select"
              // className="text-center"
              isLoading={loadingAccounts}
              isSearchable={true}
              isClearable
              options={OptionsAccounts}
              // value={OptionsAccounts?.find((f) => f.value === formik.values.accountId)}
              onChange={(option) => formik.setFieldValue('accountId', option?.value)}
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
            />
          </div>
        </div>

        {props.type === undefined && (
          <div className="col-lg-12  mb-3">
            <label className="form-label ">
              {t('Classification')} <span className="text-danger">*</span>
            </label>
            <Select
              required
              isMulti
              options={dataORGCLASS}
              classNamePrefix="react-select"
              name="organizationInformation"
              onChange={handleMultiSelectChange}
            />
          </div>
        )}

        <div className="col-xl-12  ">
          <div className="form-group mb-3">
            <label className="form-label">{t('Note')}</label>
            <Form.Control
              className="form-control"
              type="text"
              autoComplete="off"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </div>
        </div>
      </div>

      <div className="my-3" />
      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit">
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
          )}
          {t('Add')}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalDepartment">
          <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
        </button>
      </div>
    </Form>
  );
};

export default AddDepartment;
