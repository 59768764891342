import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTaxRate } from '../../../store/Finance/TaxManagers/TaxManagerSlice';
import useGetConsolidationSet from '../ConsolidationSet/useGetConsolidationSet';

interface UseGetTaxRateProps {
  organizationId: string;
  taxType: string;
  dateActive: string;
}

const useGetTaxRateByOrganization = ({
  organizationId,
  taxType,
  dateActive,
}: UseGetTaxRateProps) => {
  const dispatch = useAppDispatch();

  const { GetLedgarId } = useGetConsolidationSet();
  const ledgerId = GetLedgarId(organizationId);

  useEffect(() => {
    if (ledgerId && taxType && dateActive) {
      dispatch(getTaxRate({ ledgerId, taxType, dateActive }));
    }
  }, [dispatch, ledgerId, taxType, dateActive, organizationId]);

  const { taxRate, loading, error } = useAppSelector((a) => a.TaxManager);

  return { taxRate, loading, error };
};

export default useGetTaxRateByOrganization;
