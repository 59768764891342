import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getUsers } from '../../store/NTX/Security/usersSlice';
import { useTranslation } from 'react-i18next';

const useGetUsers = () => {
  const dispatch = useAppDispatch();
  const { Users, loading, error } = useAppSelector((state) => state.Users);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (Users?.length === 0) {
      dispatch(getUsers());
    }
  }, []);

  var optionUsers = Users?.map((item: any) => ({
    value: item?.id,
    label: i18n.language === 'en' ? item?.refName1 : item?.refName2,
  }));

  return { Users, optionUsers, loading, error };
};

export default useGetUsers;
