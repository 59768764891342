/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getBudgetAccountsList } from '../../../store/Finance/GlBudget/GlBudgetSlice';
import { useTranslation } from 'react-i18next';

const useGetBudgetAccountsList = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (BudgetAccountsList?.length === 0) {
      dispatch(getBudgetAccountsList());
    }
  }, [dispatch]);
  const { BudgetAccountsList, loading, error } = useAppSelector((state) => state.GlBudget);

  const OptionsGlBudget =
    BudgetAccountsList &&
    BudgetAccountsList?.map((item) => ({
      value: item.id,
      label: i18n.language === 'ar' ? item.name2 : item.name,
    }));

  return { BudgetAccountsList, loading, error, OptionsGlBudget };
};

export default useGetBudgetAccountsList;
