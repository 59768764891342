import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLocations } from "../../store/HR/HRSetting/locationSlice";
import { useTranslation } from "react-i18next";

const useGetLocations = () => {

  const {i18n , t} = useTranslation()
  const dispatch = useDispatch();
  const { Locations, loading, error } = useSelector((state) => state.Locations);

  useEffect(() => {
    if (Locations?.length === 0) {
      dispatch(getLocations());
    }
  }, [dispatch]);

  var shipToLocation = Locations?.filter (location => location.shipToSiteFlag === true)
  var billToLocation = Locations?.filter (location => location.billToSiteFlag === true)


  const LocationsOptions =
  Locations &&
  Locations?.map((item) => ({
    value: item.id,
    label: i18n.language === "en" ? item.name : item.name2,
  }));

  const LocationsOptionsWithAll = [
    {
      label: t('جميع المواقع'),
      value: 'All',
    },
    ...LocationsOptions,
  ];
 

  return { loading, error, Locations , LocationsOptionsWithAll, LocationsOptions ,shipToLocation , billToLocation };
};

export default useGetLocations;
