import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getMyDelegation } from '../../../../store/Ame/ameDelegateSlice';
import { useEffect } from 'react';
import Loading from '../../../../components/Shared/Loader/Loading';
import NoData from '../../../../components/NoData/NoData';

const MyDelegate = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { MyDelegationList, error, loading } = useAppSelector((s) => s.AmeDelegate);

  useEffect(() => {
    dispatch(getMyDelegation());
  }, []);

  return (
    <div className="p-3">
      <Loading loading={loading} error={error}>
        <>
          <div className="table-responsive">
            <table className="table table-sm text-nowrap table-hover text-center">
              <thead className="bg-theme bg-opacity-25">
                <tr>
                  <th>{t('From')}</th>
                  <th>{t('To')}</th>
                  <th>{t('Name')}</th>
                  <th>{t('Reason')}</th>
                </tr>
              </thead>
              <tbody>
                {MyDelegationList?.map((item) => (
                  <tr key={item.id}>
                    <td className="align-middle"> {item.startDate?.split('T')[0]} </td>
                    <td className="align-middle">{item.endDate?.split('T')[0]}</td>

                    <td className="align-middle">
                      {i18n.language === 'en' ? item.selectedPersonName : item.selectedPersonName2}
                    </td>
                    <td className="align-middle">{item.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>

        {MyDelegationList.length === 0 && <NoData />}
      </Loading>
    </div>
  );
};

export default MyDelegate;
