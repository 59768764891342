import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const posMenu = [
  {
    System: 'POS',
    isActive: true,
    menu: [
      { is_header: true, title: 'نقاط البيـــع', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'Dashboard',
      },

      {
        path: 'Pos/PosSettings',
        icon: 'bi bi-gear',
        title: 'إعدادات نقاط البيع',
        isActive: true,
      },

      {
        path: 'Pos/PosCard',
        icon: 'bi bi-bag-check',
        title: 'نقـاط البيــع',
        isActive: true,
      },
    ],
  },
];

export default posMenu;
