import { useTranslation } from 'react-i18next';
import useGetNtxProfile from '../../../../hooks/NTX/useGetNtxProfile';

import { Icon } from '@iconify/react';

const MyInformations = () => {
  const { t, i18n } = useTranslation();
  const { UserProfile: Profile } = useGetNtxProfile();

  return (
    <>
      <div className="row">
        {/* Employee Number */}
        <div className="col-md-6">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <Icon
                  icon="ph:identification-badge-duotone"
                  className="me-2 text-theme"
                  width="25"
                  height="25"
                />
                <span className="text-inverse text-opacity-50 fw-bold">
                  {t('EmployeeNumber')} :{' '}
                </span>
                <span className="text-theme h6">{Profile?.employeeNumber || '0000'}</span>
              </div>
            </div>
          </div>
        </div>
        {/* Job */}
        <div className="col-md-6">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <Icon
                  icon="ph:briefcase-duotone"
                  className="me-2 text-theme"
                  width="25"
                  height="25"
                />
                <span className="text-inverse text-opacity-50 fw-bold">{t('Job')} : </span>
                <span className="text-theme h6">
                  {i18n.language === 'ar' ? Profile?.jobName2 : Profile?.jobName}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Supervisor */}
        <div className="col-md-12">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0">
                <Icon icon="ph:user-duotone" className="me-2 text-theme" width="25" height="25" />
                <span className="text-inverse text-opacity-50 fw-bold">{t('SuperVisor')} : </span>
                <span className="text-theme h6">
                  {i18n.language === 'ar' ? Profile?.supervisorName2 : Profile?.supervisorName}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Location */}
        <div className="col-md-12">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <Icon
                  icon="ph:map-pin-duotone"
                  className="me-2 text-theme"
                  width="25"
                  height="25"
                />
                <span className="text-inverse text-opacity-50 fw-bold">{t('location')} : </span>
                <span className="text-theme h6">
                  {i18n.language === 'ar' ? Profile?.locationName2 : Profile?.locationName}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Position */}
        <div className="col-md-12">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <Icon icon="ph:medal-duotone" className="me-2 text-theme" width="25" height="25" />
                <span className="text-inverse text-opacity-50 fw-bold">{t('Position')} : </span>
                <span className="text-theme h6">
                  {i18n.language === 'ar' ? Profile?.positionName2 : Profile?.positionName}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Service Duration */}
        {/* <div className="col-md-12">
          <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-0 text-center">
                <Icon icon="ph:clock-duotone" className="me-2 text-theme" width="25" height="25" />
                <span className="text-inverse text-opacity-50 fw-bold">مدة الخدمة : </span>
                <span className="text-theme h6">
                  {Profile?.serviceDuration || '2'} {t('سنة')}
                </span>
              </div>
            </div>
          </div>
        </div> */}
     
      </div>
    </>
  );
};

export default MyInformations;

// <>
//     <div className="row">
//       <div className="col-md-12">
//         <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
//           <div className="d-flex justify-content-between align-items-center">
//             <div className="mb-0 text-center">
//               <span className="text-inverse text-opacity-50 fw-bold">
//                 {' '}
//                 {t('EmployeeNumber')} :{' '}
//               </span>
//               <span className="text-theme h6">{Profile?.employeeNumber || '0000'}</span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="col-md-12">
//         <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1 border-bottom border-theme">
//           <div className="d-flex justify-content-between align-items-center">
//             <div className="mb-0  ">
//               <span className="text-inverse text-opacity-50 fw-bold text-start">
//                 {t('SuperVisor')} :{' '}
//               </span>
//               <span className="text-theme h6">
//                 {i18n.language === 'ar' ? Profile?.supervisorName2 : Profile?.supervisorName}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="col-md-12">
//         <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1 border-bottom border-theme">
//           <div className="d-flex justify-content-between align-items-center">
//             <div className="mb-0 text-center">
//               <span className="text-inverse text-opacity-50 fw-bold">{t('location')} : </span>
//               <span className="text-theme h6">
//                 {i18n.language === 'ar' ? Profile?.locationName2 : Profile?.locationName}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="col-md-12">
//         <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1 border-bottom border-theme">
//           <div className="d-flex justify-content-between align-items-center">
//             <div className="mb-0 text-center">
//               <span className="text-inverse text-opacity-50 fw-bold">{t('Job')} : </span>
//               <span className="text-theme h6">
//                 {i18n.language === 'ar' ? Profile?.jobName2 : Profile?.jobName}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="col-md-12">
//         <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-1 border-bottom border-theme">
//           <div className="d-flex justify-content-between align-items-center">
//             <div className="mb-0 text-center">
//               <span className="text-inverse text-opacity-50 fw-bold">{t('Position')} : </span>
//               <span className="text-theme h6">
//                 {i18n.language === 'ar' ? Profile?.positionName2 : Profile?.positionName}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </>



//    {/* Available Leave */}
//    <div className="col-md-6">
//    <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
//      <div className="d-flex justify-content-between align-items-center">
//        <div className="mb-0 text-center">
//          <Icon
//            icon="ph:calendar-duotone"
//            className="me-2 text-theme"
//            width="25"
//            height="25"
//          />
//          <span className="text-inverse text-opacity-50 fw-bold">رصيد الإجازات : </span>
//          <span className="text-theme h6">{Profile?.availableLeaves || '0'}</span>
//        </div>
//      </div>
//    </div>
//  </div>

//  {/* Used Leave */}
//  <div className="col-md-6">
//    <div className="bg-inverse bg-opacity-10 rounded-2 p-2 mb-3 border-bottom border-theme">
//      <div className="d-flex justify-content-between align-items-center">
//        <div className="mb-0 text-center">
//          <Icon
//            icon="ph:calendar-x-duotone"
//            className="me-2 text-theme"
//            width="25"
//            height="25"
//          />
//          <span className="text-inverse text-opacity-50 fw-bold">الإجازات المستعملة : </span>
//          <span className="text-theme h6">{Profile?.usedLeaves || '0'}</span>
//        </div>
//      </div>
//    </div>
//  </div>