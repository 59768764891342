/* eslint-disable no-mixed-operators */
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import { AppSettings } from '../../../../config/app-settings.js';
import { ChartAccountsDetailsModels } from '../../../../store/Finance/ChartAccounts/ChartAccountsModels';
import CostCentersForm from './CostCentersForm';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import { Card, CardBody } from '../../../../components/card/card';

const Index = () => {
  const [show, setShow] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { ChartAccountsId } = useParams();
  const [TreeDetails, setTreeDetails] = useState<ChartAccountsDetailsModels>();
  const [breadcrumb, setBreadcrumb] = useState<any>([]);
  const { ChartAccountsDetailsList: data, loading } = useGetChartAccountsDetailsList();
  const filteredData = data
    ?.slice()
    ?.filter((f) => f.accountParant === ChartAccountsId)
    ?.sort((a, b) => a.sequenceNum - b.sequenceNum);

  const accountParant = data?.slice()?.find((f) => f.id === ChartAccountsId);

  //#region updateBreadcrumb
  const updateBreadcrumb = (clickedItem: any) => {
    // Find the clicked item in the list
    const findItem = (itemId: string, items: any[]): any => {
      for (const item of items) {
        if (item.id === itemId) {
          return item;
        }
        if (item.children) {
          const found = findItem(itemId, item.children);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    const findParentItems = (item: any, parents: any[] = []) => {
      if (item.accountParant) {
        const parentItem = findItem(item.accountParant, data);
        if (parentItem) {
          parents.unshift(parentItem);
          findParentItems(parentItem, parents);
        }
      }
      return parents;
    };

    const clickedItemData = findItem(clickedItem.id, data);

    if (clickedItemData) {
      const parents = findParentItems(clickedItemData);
      const breadcrumbData = parents?.map((parent: any) => ({
        id: parent.id,
        name: i18n.language === 'en' ? parent.name : parent.name2,
        link: null, // You can set the appropriate link here if available
      }));

      // Add the clicked item to the breadcrumb
      breadcrumbData.push({
        id: clickedItemData.id,
        name: i18n.language === 'en' ? clickedItemData.name : clickedItemData.name2,
        link: null, // You can set the appropriate link here if available
      });

      // Update the breadcrumb state
      setBreadcrumb(breadcrumbData);
    }
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الدليل المحاسبي',
      link: '/FinanceSettings/ChartAccounts',
    },
    {
      name: 'مراكز التكلفة',
      link: null,
    },
  ];
  //#endregion

  //#region context tree
  const context = useContext(AppSettings);
  useEffect(() => {
    const handleFileArrowClick = (event: any) => {
      event.preventDefault();
      const node = event.target.closest('.file-node.has-sub');
      if (node) {
        node.classList.toggle('expand');
      }
    };

    const handleFileInfoClick = (event: any) => {
      event.preventDefault();
      const node = event.target.closest('.file-node');
      if (node) {
        const fileInfoNodes = document.querySelectorAll('.file-node');
        fileInfoNodes.forEach((otherNode) => {
          if (otherNode !== node) {
            otherNode.classList.remove('selected');
          }
        });
        node.classList.add('expand');
        node.classList.add('selected');
      }
    };

    const fileHasSubNodes = document.querySelectorAll('.file-node.has-sub');
    fileHasSubNodes.forEach((node) => {
      const fileArrow = node.querySelector('.file-link > .file-arrow');
      fileArrow?.addEventListener('click', handleFileArrowClick);
    });

    const fileInfoNodes = document.querySelectorAll('.file-node');
    fileInfoNodes.forEach((node) => {
      const fileInfo = node.querySelector('.file-link > .file-info');
      fileInfo?.addEventListener('click', handleFileInfoClick);
    });

    context.setAppContentFullHeight(true);

    return function cleanUp() {
      context.setAppContentFullHeight(false);
      fileHasSubNodes.forEach((node) => {
        const fileArrow = node.querySelector('.file-link > .file-arrow');
        fileArrow?.removeEventListener('click', handleFileArrowClick);
      });
      fileInfoNodes.forEach((node) => {
        const fileInfo = node.querySelector('.file-link > .file-info');
        fileInfo?.removeEventListener('click', handleFileInfoClick);
      });
    };
  }, [data, context]);

  //#endregion

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName=" مراكز التكلفة" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn btn-theme"
            onClick={() => {
              setTreeDetails(accountParant);
              setIsAdd(true);
              setBreadcrumb([]);
              setShow(true);
            }}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('Add')}
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <Card>
            <CardBody>
              <PerfectScrollbar className="h-50 p-2">
                <div className="file-tree mb-3">
                  {filteredData?.map((tree, idx) => {
                    return (
                      <ItemTree
                        key={tree.id}
                        tree={tree}
                        setTreeDetails={setTreeDetails}
                        updateBreadcrumb={updateBreadcrumb}
                        setIsAdd={setIsAdd}
                      />
                    );
                  })}
                </div>
              </PerfectScrollbar>
            </CardBody>
          </Card>
        </div>

        <div className="col-lg-7">
          {TreeDetails?.accountType === 'CostCenter' && (
            <CostCentersForm treeDetails={TreeDetails} breadcrumb={breadcrumb} isAdd={isAdd} />
          )}
        </div>
      </div>

      <NeatixModal show={show} setShow={setShow} bodyClassName="p-0" size="lg" showHeader={false}>
        {TreeDetails?.accountType === 'CostCenters' && (
          <CostCentersForm treeDetails={TreeDetails} breadcrumb={breadcrumb} isAdd={isAdd} />
        )}
      </NeatixModal>
    </div>
  );
};
export default Index;
//_______________________________________________________________________________________________

type PropsItemTree = {
  tree: any;
  setTreeDetails: any;
  updateBreadcrumb: (item: any) => void;
  setIsAdd: React.Dispatch<React.SetStateAction<boolean>>;
};
const ItemTree: React.FC<PropsItemTree> = ({
  tree,
  setTreeDetails,
  updateBreadcrumb,
  setIsAdd,
}) => {
  const { ChartAccountsDetailsList: data } = useGetChartAccountsDetailsList();
  const { i18n } = useTranslation();
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <Fragment key={tree.id}>
      <div className="d-flex justify-content-between p-1 border-1 border-end border-theme ">
        <div
          className={`file-node ${
            data?.filter((f) => f.accountParant === tree?.id)?.length && 'has-sub expand'
          }`}>
          <Link to="" className="file-link">
            <span className="file-arrow"></span>
            <span
              ref={linkRef}
              className="file-info"
              onClick={() => {
                setTreeDetails(tree);
                updateBreadcrumb(tree);
                setIsAdd(false);
              }}>
              <span className="file-icon">
                <i className={`bi bi-bar-chart fa-lg text-theme`}></i>
              </span>
              <span className="file-text fs-5">
                {i18n.language === 'en' ? tree.name : tree.name2}
              </span>
            </span>
          </Link>

          <div className="file-tree">
            {data
              ?.filter((node: any) => node.accountParant === tree.id)
              ?.sort((a, b) => a.sequenceNum - b.sequenceNum)
              ?.map((node, idx) => {
                return (
                  <ItemTree
                    key={node.id}
                    tree={node}
                    setTreeDetails={setTreeDetails}
                    updateBreadcrumb={updateBreadcrumb}
                    setIsAdd={setIsAdd} // Pass updateBreadcrumb to the child components recursively
                  />
                );
              })}
          </div>
        </div>
        <div className='pt-2'>
          <Link
            to=""
            // .charAt(accountNumber?.length - 1) === '0'
            hidden={tree.accountNumber % 10 !== 0}
            className="pt-1 ms-4 text-theme"
            onClick={() => {
              linkRef?.current?.click();
              setIsAdd(true);
            }}>
            <i className="fas fa-lg fa-fw  fs-4 bi bi-plus-square-fill"></i>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};
