import { useFormik } from 'formik';
import React from 'react';
import Select from 'react-select';

import { useAppDispatch } from '../../../../../store/hooks';
import { updateInvTransactionTypeReasons } from '../../../../../store/INV/TransactionTypes/TransactionTypesSlice';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import useGetAccounts from '../../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import useGetGlLedgersList from '../../../../../hooks/Finance/useGetGlLedgersList';
import { TransactionTypeReasonsSchema } from '../../../../ValidationForm/validationSchema';
import { TypeReasonsModels } from '../../../../../store/INV/TransactionTypes/TransactionTypesModels';

interface Props {
  data: TypeReasonsModels;
}

const UpdateReason = ({ data }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { OptionsAccounts } = useGetAccounts();
  const { LedgersList } = useGetGlLedgersList();

  const formik = useFormik({
    initialValues: {
      id: data.id ?? '',
      name: data.name ?? '',
      name2: data.name2 ?? '',
      description: data.description ?? '',
      description2: data.description2 ?? '',
      accountId: data.accountId ?? '',
      glLedgerId: data.glLedgerId ?? '',
    },
    enableReinitialize: true,
    validationSchema: TransactionTypeReasonsSchema(t),
    onSubmit: (values, { resetForm }) => {
      const body = {
        id: data.id,
        name: values.name,
        name2: values.name2,
        description: values.description,
        description2: values.description2,
        accountId: values.accountId,
        glLedgerId: values.glLedgerId,
      };

      dispatch(updateInvTransactionTypeReasons(body))
        .unwrap()
        .then((res) => {
          if (res.succeeded) {
            CustomAlert({ action: 'Edit' });
            formik.resetForm();
          } else {
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          CustomAlert({ action: 'Error' });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });
  const { values, errors, touched, setFieldValue, handleChange } = formik;

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2 || ''}
                // isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <div className="text-danger small">{touched.name2 && errors.name2}</div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="name"
                onChange={handleChange}
                value={values.name || ''}
                // isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <div className="text-danger small">{touched.name && errors.name}</div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('description')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description || ''}
                // isInvalid={!!(formik.touched.description && formik.errors.description)}
              />
              <div className="text-danger small">{touched.description && errors.description}</div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('description2')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="description2"
                onChange={formik.handleChange}
                value={formik.values.description2 || ''}
                // isInvalid={!!(formik.touched.description2 && formik.errors.description2)}
              />
              <div className="text-danger small">{touched.description2 && errors.description2}</div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">الأستاذ العام</label>
              <Form.Select
                className="form-select"
                name="glLedgerId"
                onChange={handleChange}
                value={values.glLedgerId}>
                <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {LedgersList &&
                  LedgersList?.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === 'ar' ? item.name2 : item.name}
                    </option>
                  ))}
              </Form.Select>
              <div className="text-danger small">{touched.glLedgerId && errors.glLedgerId}</div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('الحساب')}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                // isLoading={loading}
                isSearchable={true}
                isClearable
                options={OptionsAccounts}
                value={OptionsAccounts?.find((f) => f.value === values.accountId)}
                onChange={(option) => setFieldValue('accountId', option?.value)}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
              <div className="text-danger small">{touched.accountId && errors.accountId}</div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme px-5">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default "
            data-bs-dismiss="modal"
            id="close">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateReason;
