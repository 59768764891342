import React from 'react';
import InvoiceLines from './Lines';
import { useTranslation } from 'react-i18next';

interface Props {
  data: any;
}
const BillDetails = ({ data }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="row mb-2">
        <div className="col-12 col-md-6">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '130px' }}>
              رقم الفاتورة
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control-sm"
              value={data.code}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              تاريخ الفاتورة
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control-sm"
              value={data.transactionDate?.split('T')[0]}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center"
              style={{ width: '130px' }}>
              العملة
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control-sm"
              value={data.currencyCode}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              الفرع
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control-sm"
              defaultValue={i18n.language === 'en' ? data.organizationName : data.organizationName2}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              المخزن
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control-sm"
              // defaultValue={''}
              defaultValue={i18n.language === 'en' ? data.inventoryName : data.inventoryName2}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              المورد
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control-sm"
              // defaultValue=""
              defaultValue={i18n.language === 'en' ? data.peopleName : data.peopleName2}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="input-group input-group-sm flex-nowrap">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              الإجـمالي
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control-sm"
              value={data.grossAmount}
              disabled
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="input-group input-group-sm flex-nowrap">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              ملاحظات
            </span>
            <input
              type="text" 
autoComplete='off'
              className="form-control form-control-sm"
              value={data.description}
              disabled
            />
          </div>
        </div>
      </div>

      <InvoiceLines LinesList={data.transactionsLinesList} />
    </>
  );
};

export default BillDetails;
