import { useAppDispatch } from '../../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import useGetAccounts from '../../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import Select from 'react-select';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetGlJeCategoryList from '../../../../../hooks/Finance/useGetGlJeCategoryList';
import useGetGlJeSourceList from '../../../../../hooks/Finance/useGetGlJeSourceList';
import { addApExpenseCategory } from '../../../../../store/Finance/Expense/ApExpenseCategory/ApExpenseCategorySlice';
import { AddApExpenseCategorySchema } from '../../../../ValidationForm/validationSchema';

const Add = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { OptionsAccounts, loading, error } = useGetAccounts();

  const { OptionsJeCategory, loading: loading2, error: error2 } = useGetGlJeCategoryList();
  const { OptionsJeSource, loading: loading3, error: error3 } = useGetGlJeSourceList();

  const formik = useFormik({
    initialValues: {
      name: '',
      name2: '',
      description: '',
      isActive: true,
      jeCategory: '',
      jeSource: '',
      expenseAccountId: '',
    },
    validationSchema: AddApExpenseCategorySchema(t),
    onSubmit: (values) => {
      dispatch(
        addApExpenseCategory({
          name: values.name,
          name2: values.name2,
          description: values.description,
          isActive: true,
          jeCategory: values.jeCategory,
          jeSource: values.jeSource,
          expenseAccountId: values.expenseAccountId,
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  // console.log(formik.errors);

  return (
    <>
      <Loading loading={loading || loading2 || loading3} error={error || error2 || error3}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('LocalName')}</label>
                <Form.Control
                  className="form-control  "
                  type="text"
                  autoComplete="off"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2}
                  isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                />
                {/* <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback> */}
              </div>
            </div>
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('GlobalName')}</label>
                <Form.Control
                  className="form-control  "
                  type="text"
                  autoComplete="off"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isInvalid={!!(formik.touched.name && formik.errors.name)}
                />
                {/* <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback> */}
              </div>
            </div>

            <div className="col-xl-6 ">
              <div className="form-group mb-3">
                <label className="form-label">{t('Source')}</label>
                <Select
                  classNamePrefix="react-select"
                  className={`w-100 ${
                    !!(formik.touched.jeSource && formik.errors.jeSource)
                      ? 'border border-danger'
                      : ''
                  } `}
                  isLoading={loading3}
                  isSearchable={true}
                  isClearable
                  options={OptionsJeSource}
                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  onChange={(option: any) => {
                    formik.setFieldValue('jeSource', option === null ? null : option.value);
                  }}
                  value={OptionsJeSource?.find((f) => f.value === formik.values.jeSource)}
                />
              </div>
            </div>
            <div className="col-xl-6 ">
              <div className="form-group mb-3">
                <label className="form-label">{t('Category')}</label>
                <Select
                  classNamePrefix="react-select"
                  className={`w-100 ${
                    !!(formik.touched.jeCategory && formik.errors.jeCategory)
                      ? 'border border-danger'
                      : ''
                  } `}
                  isLoading={loading2}
                  isSearchable={true}
                  isClearable
                  options={OptionsJeCategory}
                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  onChange={(option: any) => {
                    formik.setFieldValue('jeCategory', option === null ? null : option.value);
                  }}
                  value={OptionsAccounts?.find((f) => f.value === formik.values.jeCategory)}
                />
              </div>
            </div>
            <div className="col-xl-6 ">
              <div className="form-group mb-3">
                <label className="form-label">{t('Accounts')}</label>
                <Select
                  classNamePrefix="react-select"
                  className={`w-100 ${
                    !!(formik.touched.expenseAccountId && formik.errors.expenseAccountId)
                      ? 'border border-danger'
                      : ''
                  } `}
                  isLoading={loading}
                  isSearchable={true}
                  isClearable
                  options={OptionsAccounts}
                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  onChange={(option: any) => {
                    formik.setFieldValue('expenseAccountId', option === null ? null : option.value);
                  }}
                  value={OptionsAccounts?.find((f) => f.value === formik.values.expenseAccountId)}
                />
              </div>
            </div>
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('Note')}</label>
                <Form.Control
                  className="form-control  "
                  type="text"
                  autoComplete="off"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  isInvalid={!!(formik.touched.description && formik.errors.description)}
                />
              </div>
            </div>
          </div>

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn me-1 btn-theme mb-1">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
              )}
              {t('Add')}
            </button>
            <button
              type="button"
              className=" btn me-1 btn-default mb-1"
              data-bs-dismiss="modal"
              id="closeModalForm">
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div>
        </Form>
      </Loading>
    </>
  );
};

export default Add;
