import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import {
  updateAttribute,
  getAttributeById,
} from '../../../../store/INV/Attributes/AttributesSlice';
import AttributeValuesList from './AttributeValuesList';
// import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
// import Breadcrumb from '../../../../components/Shared/Breadcrumb';
// import { Card, CardBody } from '../../../../components/card/card';
import Loading from '../../../../components/Shared/Loader/Loading';

interface Props {
  attributId: string;
}

const Update = ({ attributId }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  // const { attributId } = useParams();

  useEffect(() => {
    if (attributId) {
      dispatch(getAttributeById(attributId));
    }
  }, [attributId, dispatch]);
  const { AttributesById: data, error, loadingById } = useAppSelector((s) => s.Attributes);

  const formik = useFormik({
    initialValues: {
      attType: data.attType ?? '',
      name: data.name ?? '',
      name2: data.name2 ?? '',
      description: data.description ?? '',
      attributeValuesList:
        data.attributeValuesList?.map((item) => ({
          id: item.id,
          // attributeId: '',
          name: item.name,
          name2: item.name2,
          extraPrice: item.extraPrice,
          color: item.color,
          description: item.description,
        })) ?? [],
    },

    // validationSchema: formOrganization(t),
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(
        updateAttribute({
          id: data.id ?? null,
          attType: values.attType,
          name: values.name,
          name2: values.name2,
          description: values.description,
          attributeValuesList: values.attributeValuesList?.map((item) => ({
            id: item.id,
            name: item.name,
            name2: item.name2,
            extraPrice: Number(item.extraPrice),
            color: item.color,
            description: item.description,
          })),
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: 'Edit' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        })
        .finally(() => formik.setSubmitting(false));
    },
  });

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Attributes',
      link: null,
    },
  ];

  return (
    <>
      <Loading loading={loadingById} Type="Dots">
        <Form onSubmit={formik.handleSubmit}>
          <div className="row mb-3">
            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('LocalName')}</label>
                <Form.Control
                  className="form-control fw-bold"
                  type="text"
                  autoComplete="off"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2}
                  isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('GlobalName')}</label>
                <Form.Control
                  className="form-control fw-bold"
                  type="text"
                  autoComplete="off"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isInvalid={!!(formik.touched.name && formik.errors.name)}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label"> {t('Type')} </label>
                <Form.Select
                  className="form-select"
                  name="attType"
                  onChange={formik.handleChange}
                  value={formik.values.attType}
                  isInvalid={!!(formik.touched.attType && formik.errors.attType)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  <option value="Multi_Select">
                    {i18n.language === 'ar' ? 'اختيار متعدد' : 'Multi Select'}
                  </option>
                  <option value="Select">
                    {i18n.language === 'ar' ? 'اختيار فردي' : 'Select'}
                  </option>
                  <option value="Color">{i18n.language === 'ar' ? 'لون' : 'Color'}</option>
                </Form.Select>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('description')}</label>
                <Form.Control
                  className="form-control fw-bold"
                  type="text"
                  autoComplete="off"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  isInvalid={!!(formik.touched.description && formik.errors.description)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.description}
                </Form.Control.Feedback>
              </div>
            </div>

            <AttributeValuesList formik={formik} />
          </div>

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn btn-lg me-1 btn-theme mb-1"
              >
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-save fa-fw me-1"></i>
              )}
              {t('Save')}
            </button>
            <button
              type="button"
              className=" btn btn-lg me-1 btn-default mb-1"
              data-bs-dismiss="modal"
              id="closeModalForm">
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div>
        </Form>
      </Loading>
    </>
  );
};

export default Update;
