import React, { useState } from 'react';
// import { LinesTypes } from '../../../../store/Finance/Journals/JournalsModels';
import { GlJeLinesList } from '../../../../store/Finance/Journals/JournalsModels';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import Loading from '../../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import CodeCombination from './CodeCombination';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import useGetBranches from '../../../../hooks/Finance/ChartAccountsDetails/useGetBranches';
import useGetCompanies from '../../../../hooks/Finance/ChartAccountsDetails/useGetCompanies';
import useGetCostCenters from '../../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useConversionRateByLedgard from '../../../../hooks/Finance/DailyRates/useConversionRateByLedgard';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';

interface formikvalues {
  glDate: string;
  ledgerId: string;
  jeCategory: string;
  jeSource: string;
  periodId: string;
  name: string;
  status: string;
  currencyCode: string;
  conversionFlag: boolean;
  conversionDate: string;
  reverseFlag: boolean;
  jeBatchId: string | null;
  parentJeHeaderId: string | null;
  postedDate: string;
  description: string;
  accountedDr: 0;
  accountedCr: 0;
  JeLinesList: GlJeLinesList[];
}

interface LinesProps {
  formik: FormikProps<formikvalues | any>;
  ledgar?: any;
}

const JournalLines2: React.FC<LinesProps> = ({ formik, ledgar }) => {
  const { values, setFieldValue } = formik;

  const conversionRate = useConversionRateByLedgard({
    LedgarId: values.ledgerId || '',
    currencyCode: values.currencyCode,
    date: values.glDate,
  });

  const { Currency } = useLookupCurrency();

  const { i18n, t } = useTranslation();

  const handleAddLine = (lineType: 'credit' | 'debit') => {
    const newLine = {
      jeLineNum: values.JeLinesList.length + 1,
      enteredDr: 0,
      enteredCr: 0,
      accountDr: '',
      accountCr: '',
      description: '',
      accountId: null,
      companyId: null,
      branchId: null,
      costCenterId: null,
      ///
      lineType: lineType,
      codeCombination: '',
    };
    formik.setFieldValue('JeLinesList', [...formik.values.JeLinesList, newLine]);
  };

  const handleLineChange = (index: number, fieldName: string, value: any) => {
    const updatedLines = [...values.JeLinesList];

    // تحويل القيمة إلى رقم إذا كان الحقل هو enteredCr أو enteredDr
    const numericValue = Number(value);

    // تحديث السطر بناءً على اسم الحقل
    updatedLines[index] = {
      ...updatedLines[index],
      [fieldName]: fieldName === 'enteredCr' || fieldName === 'enteredDr' ? numericValue : value,
      // accountCr: 0,
      // accountDr: 0,
      accountCr:
        fieldName === 'enteredCr'
          ? numericValue * Number(conversionRate)
          : updatedLines[index].accountCr,
      accountDr:
        fieldName === 'enteredDr'
          ? numericValue * Number(conversionRate)
          : updatedLines[index].accountDr,
    };

    setFieldValue('JeLinesList', updatedLines);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.JeLinesList?.filter((_: any, i: number) => i !== index);
    setFieldValue('JeLinesList', updatedLines);
  };

  //#region CodeCombantion

  const { OptionsCostCenters } = useGetCostCenters();
  const { OptionsCompanies } = useGetCompanies();
  const { OptionsBranches } = useGetBranches();
  const { OptionsAccounts } = useGetAccounts();

  const [shawModalAcaount, setShawModalAcaount] = useState(false);
  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null);
  const [accountDetails, setAccountDetails] = useState({
    companyId: '',
    branchId: '',
    costCenterId: '',
    accountId: '',
  });

  const handleAccountClick = (index: number) => {
    // if (!values.organizationId) {
    //   SwalAlert({ text: 'حدد الفرع' });
    //   return;
    // }

    const selectedLine = values.JeLinesList[index];
    // إعداد القيم لتمريرها إلى المكون
    const companyId = selectedLine.companyId || '';
    const branchId = selectedLine.branchId || '';
    const accountId = selectedLine.accountId || '';
    const costCenterId = selectedLine.costCenterId || '';

    // تمرير القيم إلى المكون وفتح الـ Modal
    setSelectedLineIndex(index);
    setShawModalAcaount(true);
    setAccountDetails({ accountId, costCenterId, companyId, branchId });
  };
  const handleAccountSelect = (account: any) => {
    // debugger;
    if (selectedLineIndex !== null) {
      const updatedLines = [...values.JeLinesList];

      updatedLines[selectedLineIndex].companyId = account?.company?.id || '';
      updatedLines[selectedLineIndex].branchId = account?.branch?.id || '';
      updatedLines[selectedLineIndex].costCenterId = account?.costCenter?.id || '';
      updatedLines[selectedLineIndex].accountId = account?.account?.id || '';

      // var codeCombination = `${account?.company?.accountNumber} | ${account?.branch?.accountNumber} |${account?.costCenter?.accountNumber} |${account?.account?.accountNumber}`;
      const codeCombination = [
        account?.company?.accountNumber,
        account?.branch?.accountNumber,
        account?.costCenter?.accountNumber,
        account?.account?.accountNumber,
      ]
        .filter(Boolean)
        .join('  -  ');

      updatedLines[selectedLineIndex].codeCombination = codeCombination || '';

      formik.setFieldValue('JeLinesList', updatedLines);
    }
    setShawModalAcaount(false); // Close modal after selection
  };

  const handleShowAccountDetails = (index: number) => {
    const selectedLine = values.JeLinesList[index];
    const { companyId, branchId, accountId, costCenterId } = selectedLine;

    return (
      <div className="text-start">
        <p className="m-0">{OptionsCompanies?.find((f) => f.value === companyId)?.accountShow}</p>
        <p className="m-0">{OptionsBranches?.find((f) => f.value === branchId)?.accountShow}</p>
        <p className="m-0">{OptionsAccounts?.find((f) => f.value === accountId)?.accountShow}</p>
        <p className="m-0">
          {OptionsCostCenters?.find((f) => f.value === costCenterId)?.accountShow}
        </p>
      </div>
    );
  };
  //#endregion

  //#region Calculate the total debit and credit amounts

  const totalAccountDr = formik.values.JeLinesList?.reduce(
    (total: any, line: any) => Number(total) + Number(line.enteredDr),
    0
  );
  const totalAccountCr = formik.values.JeLinesList?.reduce(
    (total: any, line: any) => Number(total) + Number(line.enteredCr),
    0
  );

  //#endregion

 
  return (
    <>
      <Loading loading={false} error={null} Type="Dots">
        <>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <div className="d-flex text-start">
                <button
                  type="button"
                  className="btn btn-success mx-1 "
                  onClick={() => handleAddLine('credit')}>
                  <i className="bi bi-plus-circle fa-lg me-2"></i> {t('إضافة دائن')}
                </button>
                <button
                  type="button"
                  className="btn btn-danger mx-1 "
                  onClick={() => handleAddLine('debit')}>
                  <i className="bi bi-dash-circle fa-lg me-2"></i>
                  {t('إضافة مدين')}
                </button>
              </div>
            </div>
 
            <div className="col-12 col-md-3 mb-2">
              <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{t('إجمالي الدائن')}:</div>
                  <div className="text-success fs-5 fw-bold">{totalAccountCr}</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-2">
              <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{t('إجمالي المدين')}:</div>
                  <div className="text-danger fs-5 fw-bold">{totalAccountDr}</div>
                </div>
              </div>
            </div>
          </div>

          <div className={`table-responsive rounded-2 text-center `}>
            <table border={1} className="table table-borderless table-hover text-nowrap   mb-2">
              <thead className="bg-theme bg-opacity-30">
                {/* <thead className=" "> */}
                <tr>
                  <th className="align-middle  small" style={{ width: '2%' }}>
                    #
                  </th>
                  <th className="align-middle  small" style={{ width: '10%' }}>
                    دائن
                  </th>
                  <th className="align-middle  small" style={{ width: '10%' }}>
                    مدين
                  </th>
                  <th className="align-middle  small" style={{ width: '10%' }}>
                    {
                      Currency?.find((f: any) => f?.currencyCode === ledgar?.currencyCode)
                        ?.description
                    }
                  </th>

                  <th className="align-middle  small" style={{ width: '46%' }}>
                    {t('الوصف')}
                  </th>

                  <th className="align-middle small" style={{ width: '30%' }}>
                    {t('الحســـاب')}
                  </th>
                  <th className="align-middle text-end" style={{ width: '2%' }}></th>
                </tr>
              </thead>

              <tbody className="fw-bold">
                {values.JeLinesList?.slice()?.map((line: any, index: any) => {
                  const Index = index;

                  return (
                    <tr key={index}>
                      <td
                        className={`align-middle small  bg-opacity-30 " ${
                          line.lineType === 'credit' ? 'bg-success' : 'bg-danger'
                        }`}>
                        {index + 1}
                      </td>

                      <td className={`align-middle small`}>
                        <input
                          autoComplete="off"
                          type="text" 
                           disabled={line.lineType === 'debit'}
                          className={`form-control text-center px-0 fw-bold text-success`}
                          name="enteredCr"
                          value={line.enteredCr}
                          onChange={(e) =>
                            handleLineChange(
                              index,
                              'enteredCr',
                              e.target.value.replace(/[^0-9]/g, '')
                            )
                          }
                          onInput={(e) => {
                            const input = e.target as HTMLInputElement;
                            input.value = input.value.replace(/[^0-9]/g, '');
                          }}
                        />
                      </td>
                      <td className={`align-middle small`}>
                        <input
                          autoComplete="off"
                          disabled={line.lineType === 'credit'} // تعطيل حقل مدين إذا كان السطر دائن
                          type="text" 
                           className={`form-control text-center px-0 fw-bold text-danger `}
                          name="enteredDr"
                          value={line.enteredDr}
                          onChange={(e) =>
                            handleLineChange(
                              index,
                              'enteredDr',
                              e.target.value.replace(/[^0-9]/g, '')
                            )
                          }
                          onInput={(e) => {
                            const input = e.target as HTMLInputElement;
                            input.value = input.value.replace(/[^0-9]/g, '');
                          }}
                        />
                      </td>

                      <td className={`align-middle small`}>
                        <input
                          autoComplete="off"
                          disabled={line.lineType === 'credit'} // تعطيل حقل مدين إذا كان السطر دائن
                          type="text" 
                           className={`form-control text-center px-0 fw-bold ${
                            line.lineType === 'credit' ? 'text-success' : 'text-danger'
                          } `}
                          // value={line.accountCr?.toFixed(2) ?? line.accountDr?.toFixed(2)}
                          value={
                            typeof line.accountCr === 'number'
                              ? line.accountCr.toFixed(2)
                              : typeof line.accountDr === 'number'
                              ? line.accountDr.toFixed(2)
                              : ''
                          }
                        />
                      </td>

                      <td className={`align-middle small`}>
                        <Form.Control
                          className={`form-control`}
                          as="textarea"
                          rows={1}
                          value={line.description || ''}
                          onChange={(e) => handleLineChange(index, 'description', e.target.value)}
                        />
                      </td>

                      <td className="align-middle small">
                        {line.accountId ? (
                          <PopoverCustom
                            placement="right"
                            childern={
                              <Form.Control
                                readOnly
                                className="form-control text-center"
                                type="text" 
autoComplete='off'
                                // onChange={() => true}
                                onClick={() => handleAccountClick(index)}
                                value={line.codeCombination || ''}
                              />
                            }
                            Header="تفاصيل الحـــساب"
                            Body={handleShowAccountDetails(index)}
                          />
                        ) : (
                          <Form.Control
                            readOnly
                            className="form-control text-center"
                            type="text" 
autoComplete='off'
                            // onChange={() => true}
                            onClick={() => handleAccountClick(index)}
                            value={line.codeCombination || ''}
                          />
                        )}
                      </td>

                      <td className="align-middle small">
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => handleDeleteLine(Index)}>
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      </Loading>

      <NeatixModal
        show={shawModalAcaount}
        setShow={setShawModalAcaount}
        size="md"
        title="الحســــاب"
        headerClassName="bg-theme bg-opacity-25 rounded-top-3 p-2"
        bodyClassName="p-0">
        <div className="p-2">
          <CodeCombination
            onAccountSelect={handleAccountSelect}
            accountDetails={accountDetails}
            // organizationId={null} // الفرع
          />
        </div>
      </NeatixModal>
    </>
  );
};

export default JournalLines2;
