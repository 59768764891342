import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import {
  PriceListModels,
  PriceListItemsModels,
  PriceListItemsByCustomerAndInventoryModels,
} from './PriceListModels';

export const _AddPriceList = createAsyncThunk(
  'PriceList/AddPriceList',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddPriceList', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatPriceList = createAsyncThunk(
  'PriceList/UpdatPriceList',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Inv/UpdatPriceList', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetPriceListList = createAsyncThunk(
  'PriceList/GetPriceListList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetPriceListList');
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetPriceListItemsByPriceListId = createAsyncThunk(
  'PriceList/GetPriceListItemsByPriceListId',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetPriceListItemsByPriceListId?PriceListId=${Id}`);
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
        // return {} as PriceListModels;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetPriceListById = createAsyncThunk(
  'PriceList/GetPriceListById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetPriceListById?Id=${Id}`);
      return data.succeeded ? data.data : ({} as PriceListModels);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetPriceListItemsByPeopleIdAndInventoryId = createAsyncThunk(
  'PriceList/GetPriceListItemsByPeopleIdAndInventoryId',
  async (
    { customerId, inventoryId }: { customerId: string; inventoryId: string },
    { rejectWithValue }
  ) => {
    try {
      // https://neatixapi.aljown.com/api/Inv/GetPriceListItemsByPeopleIdAndInventoryId?PeopleId=d595442b-572f-431f-b7ff-08dc2f463ff5&InventoryId=3cb95d6a-d52d-4cad-885c-bbe7b4183619
      // https://neatixapi.aljown.com/api/Inv/GetPriceListItemsByPriceListId?PeopleId=d595442b-572f-431f-b7ff-08dc2f463ff5&InventoryId=3cb95d6a-d52d-4cad-885c-bbe7b4183619

      const { data } = await ntxAPI.get(
        `Inv/GetPriceListItemsByPeopleIdAndInventoryId?PeopleId=${customerId}&InventoryId=${inventoryId}`
      );
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
        // return {} as PriceListModels;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  PriceList: PriceListModels[];
  PriceListById: PriceListModels;
  PriceListItemsByPriceListId: PriceListItemsModels[];
  PriceListItemsByCustomerAndInventory: PriceListItemsByCustomerAndInventoryModels[];
  loading: boolean;
  error: any | null;
} = {
  PriceList: [],
  PriceListById: {} as PriceListModels,
  PriceListItemsByPriceListId: [],
  PriceListItemsByCustomerAndInventory: [],
  loading: false,
  error: null,
};

const PriceListSlice = createSlice({
  name: 'PriceList',
  initialState,
  reducers: {
    PriceListByIdCleanUp: (state) => {
      state.PriceListById = {} as PriceListModels;
    },
    PriceListItemsCleanUp: (state) => {
      state.PriceListItemsByPriceListId = [];
    },
  },
  extraReducers(builder) {
    builder

      .addCase(_AddPriceList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(_AddPriceList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action?.payload?.succeeded === true) {
          state.PriceList.push(action.payload.data);
        }
      })
      .addCase(_AddPriceList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //___
      .addCase(GetPriceListList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetPriceListList.fulfilled, (state, action: PayloadAction<PriceListModels[]>) => {
        state.loading = false;
        state.PriceList = action.payload;
      })
      .addCase(GetPriceListList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //___

      .addCase(GetPriceListById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetPriceListById.fulfilled, (state, action: PayloadAction<PriceListModels>) => {
        state.loading = false;
        state.PriceListById = action.payload;
      })
      .addCase(GetPriceListById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //___

      .addCase(GetPriceListItemsByPriceListId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetPriceListItemsByPriceListId.fulfilled,
        (state, action: PayloadAction<PriceListItemsModels[]>) => {
          state.loading = false;
          state.PriceListItemsByPriceListId = action.payload;
        }
      )
      .addCase(GetPriceListItemsByPriceListId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //___

      .addCase(GetPriceListItemsByPeopleIdAndInventoryId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetPriceListItemsByPeopleIdAndInventoryId.fulfilled,
        (state, action: PayloadAction<PriceListItemsByCustomerAndInventoryModels[]>) => {
          state.loading = false;
          state.PriceListItemsByCustomerAndInventory = action.payload;
        }
      )
      .addCase(
        GetPriceListItemsByPeopleIdAndInventoryId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export const { PriceListByIdCleanUp, PriceListItemsCleanUp } = PriceListSlice.actions;
export default PriceListSlice.reducer;
