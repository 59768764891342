export const systemModules = {
  GeneralSystem: {
    id: '0a6c6b89-1d52-4eaa-8d2c-000c74b1f6e0',
    name2: 'النظام العام',
    name: 'General System',
  },
  ReportsManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-001c74b1f6e0',
    name2: 'إدارة التقارير وقوالب الطباعة',
    name: 'Reports Management',
  },
  NotificationsSystem: {
    id: '0a6c6b89-1d52-4eaa-8d2c-002c74b1f6e0',
    name2: 'إدارة الإشعارات',
    name: 'Notifications System',
  },
  HumanResources: {
    id: '0a6c6b89-1d52-4eaa-8d2c-010c74b1f6e0',
    name2: 'إدارة الموظفين',
    name: 'Human Resources',
  },
  PayrollManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-011c74b1f6e0',
    name2: 'إدارة الرواتب',
    name: 'Payroll Management',
  },
  AttendanceManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-012c74b1f6e0',
    name2: 'إدارة الحضور',
    name: 'Attendance Management',
  },
  ApprovalsManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-020c74b1f6e0',
    name2: 'إدارة الموافقات',
    name: 'Approvals Management',
  },
  SupportingUnits: {
    id: '0a6c6b89-1d52-4eaa-8d2c-030c74b1f6e0',
    name2: 'الخدمات المساندة',
    name: 'Supporting Units',
  },
  FleetManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-040c74b1f6e0',
    name2: 'إدارة الاسطول',
    name: 'Fleet Management',
  },
  FinanceManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-050c74b1f6e0',
    name2: 'إدارة المالية',
    name: 'Finance Management',
  },
  FixedAssetsManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-051c74b1f6e0',
    name2: 'إدارة الأصول',
    name: 'Fixed Assets Management',
  },
  PayablesManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-052c74b1f6e0',
    name2: 'إدارة المدفوعات',
    name: 'Payables Management',
  },
  ReceivablesManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-053c74b1f6e0',
    name2: 'إدارة المستحقات',
    name: 'Receivables Management',
  },
  IntegrationManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-060c74b1f6e0',
    name2: 'إدارة التكامل',
    name: 'Integration Management',
  },
  InventoryManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-070c74b1f6e0',
    name2: 'ادارة المخزون',
    name: 'Inventory Management',
  },
  SalesManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-080c74b1f6e0',
    name2: 'ادارة المبيعات',
    name: 'Sales Management',
  },
  PointOfSalesManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-081c74b1f6e0',
    name2: 'ادارة نقاط البيع',
    name: 'Point Of Sales Management',
  },
  PurchaseManagement: {
    id: '0a6c6b89-1d52-4eaa-8d2c-090c74b1f6e0',
    name2: 'ادارة المشتريات',
    name: 'Purchase Management',
  },
} as const;

// type SystemModule = keyof typeof systemModules;

export function getModuleDetails(module: keyof typeof systemModules) {
  return systemModules[module];
}
