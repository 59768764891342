import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  GetMaterialTransactionsPaged,
  setPageIndex,
} from '../../../store/INV/MaterialTransactions/MaterialTransactionsSlice';

const useMaterialTransactions = () => {
  const dispatch = useAppDispatch();

  const { data, totalItems, pageIndex, pageSize, loading, error } = useAppSelector(
    (state) => state.MaterialTransactions
  );

  useEffect(() => {
    dispatch(GetMaterialTransactionsPaged({ pageIndex, pageSize }));
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [dispatch, pageIndex, pageSize]);

  const handlePageChange = (newPageIndex: number) => {
    dispatch(setPageIndex(newPageIndex));
  };

  return { data, totalItems, pageIndex, pageSize, handlePageChange, loading, error };
};

export default useMaterialTransactions;
