import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../components/card/card.jsx';
import { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings.js';

function PagesError() {
  const navigate = useNavigate();

  function handleBackBtn() {
    navigate(-1, { replace: true });
  }

  // const context = useContext(AppSettings);

  // useEffect(() => {
  //   context.setAppHeaderNone(true);
  //   context.setAppSidebarNone(true);
  //   context.setAppContentClass('p-0');

  //   return function cleanUp() {
  //     context.setAppHeaderNone(false);
  //     context.setAppSidebarNone(false);
  //     context.setAppContentClass('');
  //   };

  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className="error-page">
      <div className="error-page-content">
        <Card className="mb-5 mx-auto" style={{ maxWidth: '320px' }}>
          <CardBody>
            <Card>
              <div className="error-code">404</div>
            </Card>
          </CardBody>
        </Card>
        <h1>Oops!</h1>
        <h3>We can't seem to find the page you're looking for</h3>
        <hr />
       
     
        <button onClick={handleBackBtn} className="btn btn-outline-theme px-3 rounded-pill">
          <i className="fa fa-arrow-left me-1 ms-n1"></i> Go Back
        </button>
      </div>
    </div>
  );
}

export default PagesError;
