import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const inventoryMenu = [
  {
    System: 'INV',
    isActive: true,
    menu: [
      { is_header: true, title: 'المخــازن', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'Dashboard',
      },

      { is_header: true, title: 'الإعدادات', isActive: true },

      {
        path: 'INV/InventorySettings/Inventory',
        title: 'المخازن',
        icon: 'fas fa-warehouse',
        isActive: true,
      },
      {
        path: 'INV/InventorySettings/Categories',
        title: 'الفئات',
        icon: 'fas fa-list',
        isActive: true,
      },
      {
        path: 'INV/InventorySettings/MasterItems',
        title: 'الأصناف',
        icon: 'fas fa-boxes',
        isActive: true,
      },

      {
        path: 'INV/InventorySettings/UnitsOfMeasure',
        title: 'وحدات القياس',
        icon: 'fas fa-ruler',
        isActive: true,
      },
      {
        path: 'INV/InventorySettings/TransactionTypes',
        title: 'أنواع الحركات المخزنية',
        icon: 'fas fa-exchange-alt',
        isActive: true,
      },
      {
        path: 'INV/InventorySettings/MasterItems',
        title: 'الباتشات وتواريخ الصلاحيــة',
        icon: 'fas fa-calendar-alt',
        isActive: false,
      },
      {
        path: 'INV/InventorySettings/Attributes',
        title: 'Attributes',
        icon: 'fas fa-sliders-h',
        isActive: true,
      },

      { is_header: true, title: 'العمليــات', isActive: true },

      {
        path: 'INV/Transactions/InvIssue',
        title: 'إذن صرف',
        icon: 'fas fa-sign-out-alt',
        isActive: true,
      },
      {
        path: 'INV/Transactions/InvReceive',
        title: 'إذن إضافة',
        icon: 'fas fa-sign-in-alt',
        isActive: true,
      },
      {
        path: 'INV/Transactions/InvMove',
        title: 'مستندات التحويل',
        icon: 'fas fa-arrows-alt-h',
        isActive: true,
      },
      {
        path: 'INV/Transactions/UpdateCost',
        title: 'تحديث الأسعار',
        icon: 'fas fa-arrows-alt-h',
        isActive: true,
      },
      {
        path: 'INV/Transactions/Allocate',
        title: 'حجز',
        icon: 'fas fa-arrows-alt-h',
        isActive: true,
      },

      // {
      //   path: "INV/Transactions/PermissionReceive",
      //   title: "إذن إستلام",
      //   isActive: true,
      // },
      // {
      //   path: "/",
      //   title: "تسليم الفواتير",
      //   isActive: true,
      // },
      // {
      //   path: "/",
      //   title: "إعادة إحتساب التكاليف",
      //   isActive: true,
      // },
      // {
      //   path: "/",
      //   title: "إغلاق المخزون",
      //   isActive: true,
      // },
      // {
      //   path: "/",
      //   title: "الحجــز",
      //   isActive: true,
      // },
      // {
      //   path: "/",
      //   title: "الفرز / الفـك",
      //   isActive: true,
      // },

      // {
      //   path: "InventorySettings",
      //   icon: "bi bi-shop",
      //   title: "التحويلات المخزنية",
      //   children: [
      //     {
      //       path: "/",
      //       title: "مستندات التحويل",
      //       isActive: true,
      //     },
      //     {
      //       path: "/",
      //       title: "طلبات تحويل بإنتظار الصرف",
      //       isActive: true,
      //     },
      //     {
      //       path: "/",
      //       title: "طلبات تحويل بإنتظار الإضافة",
      //       isActive: true,
      //     },
      //   ]
      // },
      { is_header: true, title: 'جرد المخزون', isActive: true },

      {
        path: 'INV/InventoryCount',
        title: 'جرد المخزن',
        icon: 'fas fa-snowflake',
        isActive: true,
      },
      {
        path: '/qw',
        title: 'الجرد الفعلي',
        icon: 'fas fa-clipboard-check',
        isActive: true,
      },
      {
        path: '/we',
        title: 'نتائج الجرد',
        icon: 'fas fa-chart-bar',
        isActive: true,
      },
    ],
  },
];

export default inventoryMenu;
