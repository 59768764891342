import React, { useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../../components/card/card';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import AddInventory from './AddInventory';
import UpdateInventory from './UpdateInventory';
import { Link, useNavigate } from 'react-router-dom';
import MedModalComponent from '../../../../../components/Modal/MedModalComponent';
// import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import Loading from '../../../../../components/Shared/Loader/Loading';
// import useGetOrganizationWithPermissionByType from '../../../../../hooks/NTX/Organization/useGetOrganizationWithPermissionByType';
import useGetOrganizations from '../../../../../hooks/HR/use-get-organizations';

 

const Index = () => {
  const [Show, setShow] = useState(false);
  const [inventoryId, setInventoryId] = useState('');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(8);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { OrganizationInventory: data, loading, error } = useGetOrganizations();

  // const { data , loading, error } = useGetOrganizationWithPermissionByType('130');

  const filteredSearch = data
    ?.slice()
    ?.filter((item: any) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f: any) => f.type === '130');

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'المخازن',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="المخازن" />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalِAddInventory"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('اضافة مخزن')}
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: '10px' }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control ps-35px"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('Search') + ' ...'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('Name')}</th>

                    <th scope="col">{t('location')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item: any, index: number) => (
                    <tr key={++index}>
                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.name2 : item.name}
                      </td>
                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.locationName2 : item.locationName}
                      </td>
                      <td className="align-middle text-end">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-outline-theme m-0"
                            data-bs-toggle="modal"
                            data-bs-target="#modalUpdateInventory"
                            onClick={() => setInventoryId(item.id)}>
                            <i className="far fa-lg me-2 fa-fw fa-edit"></i>
                            {t('Button.Edit')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-theme m-0"
                            onClick={() =>
                              Navigate(`${item.id}/SubInventory`, {
                                state: {
                                  name: i18n.language === 'en' ? item.name : item.name2,
                                },
                              })
                            }>
                            <i className="far fa-lg me-2 bi bi-shop-window "></i>
                            المخازن الفرعية
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-theme m-0"
                            onClick={() =>
                              Navigate(`${item.id}/AssignmentItems`, {
                                state: {
                                  name: i18n.language === 'en' ? item.name : item.name2,
                                },
                              })
                            }>
                            <i className="far fa-lg me-2 bi bi-box-seam"></i>
                            الأصناف
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title={t('اضافة مخزن')} id="modalِAddInventory">
        {Show && <AddInventory />}
      </MedModalComponent>

      <MedModalComponent title={t('تعديل مخزن')} id="modalUpdateInventory">
        {inventoryId && <UpdateInventory inventoryId={inventoryId} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
