import { memo } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import useGetPeoplesList from '../../../hooks/NTX/useGetPeoplesList';
import { FormikProps } from 'formik';

interface Props {
  formik: FormikProps<any>;
}

const DiscountVouchersPeoplesList = ({ formik }: Props) => {
  const { t, i18n } = useTranslation();
  const { OptionCustomers, loading } = useGetPeoplesList({ LookupType: '' });

  const handleChange = (selectedOptions: any) => {
    const selectedItems = selectedOptions
      ? selectedOptions?.map((option: any) => ({ peopleId: option.value }))
      : [];

    formik.setFieldValue('discountVouchersPeoplesList', selectedItems);
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('حدد العملاء')}</Form.Label>
        <Select
          classNamePrefix="react-select"
          // className="w-100 border border-theme p-0 text-center"
          className={`${
            formik.values.AllPeoplesAllPeopleCategory !== 'allPeoples' &&
            'bg-inverse bg-opacity-10'
          } `}
          isDisabled={formik.values.AllPeoplesAllPeopleCategory !== 'allPeoples'}

          isLoading={loading}
          isMulti={formik.values.voucherTypeId !== '1a0eb9fb-b164-4a60-a6d0-16008fcb43f8'}
          isSearchable={true}
          isClearable
          options={OptionCustomers}
          onChange={handleChange}
          value={formik.values.discountVouchersPeoplesList?.map((item: any) =>
            OptionCustomers?.find((option) => option.value === item.peopleId)
          )}
          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
        />
      </Form.Group>
    </>
  );
};

export default memo(DiscountVouchersPeoplesList);
