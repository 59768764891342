import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import {
  AddGlPeriods,
  GetGlPeriodsList,
  UpdatGlPeriods,
} from '../../../../store/Finance/Periods/PeriodsSlice';
import { PeriodsModels } from '../../../../store/Finance/Periods/PeriodsModels';
import { FiscalYearSchema } from '../../../ValidationForm/validationSchema';

interface Props {
  DataById: PeriodsModels;
}

const UpdateFiscalYear = ({ DataById }: Props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const years = Array.from({ length: 10 }, (_, index) => 2023 + index);

  // useEffect()

  const formik = useFormik({
    initialValues: {
      name: DataById.name ?? '',
      name2: DataById.name2 ?? '',
      startDate: DataById.startDate ?? '',
      endDate: DataById.endDate ?? '',
      periodYear: DataById.periodYear ?? '',
      adjustmentPeriodFlag: false,
      description: DataById.description ?? '',
    },
    enableReinitialize: true,
    validationSchema: FiscalYearSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdatGlPeriods({
          id: DataById.id,
          name: values.name,
          name2: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
          periodYear: null,
          adjustmentPeriodFlag: false,
          description: '',
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
            dispatch(GetGlPeriodsList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const handleYearChange = (selectedYear: string) => {
    const year = parseInt(selectedYear, 10);
    if (isNaN(year)) {
      // console.error("Invalid year provided");
      return;
    }

    const firstDayOfYear = new Date(parseInt(selectedYear, 10), 0, 1);
    const lastDayOfYear = new Date(parseInt(selectedYear, 10), 11, 31);

    firstDayOfYear.setDate(firstDayOfYear.getDate() + 1);
    lastDayOfYear.setDate(lastDayOfYear.getDate() + 1);

    formik.setValues({
      ...formik.values,
      startDate: formatDate(firstDayOfYear),
      endDate: formatDate(lastDayOfYear),
      name: selectedYear,
    });
  };

  const formatDate = (date: Date) => {
    // Format the date as "YYYY-MM-DD" (required for the input type="date")
    return date?.toISOString()?.split('T')[0];
  };

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">السنة المالية</label>
              <Form.Select
                className="form-select text-center"
                name="name"
                value={formik.values.name || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleYearChange(e.target.value);
                }}
                isInvalid={!!(formik.touched.name && formik.errors.name)}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {years?.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
              <div className="text-danger small">{formik.touched.name && formik.errors.name}</div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('بداية السنة')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="startDate"
                onChange={formik.handleChange}
                value={formik.values.startDate}
                isInvalid={!!(formik.touched.startDate && formik.errors.startDate)}
              />
              <div className="text-danger small">
                {formik.touched.startDate && formik.errors.startDate}
              </div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('نهاية السنة')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="endDate"
                onChange={formik.handleChange}
                value={formik.values.endDate}
                isInvalid={!!(formik.touched.endDate && formik.errors.endDate)}
              />
              <div className="text-danger small">
                {formik.touched.endDate && formik.errors.endDate}
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa fa-save"></i>
            )}
            {t('Save')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateFiscalYear;
