import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { addReceiveTransactions } from '../../../../store/INV/Transactions/InvReceive/invReceiveSlice';
import { useNavigate } from 'react-router-dom';
import useGetTransactionTypesReceiveList from '../../../../hooks/INV/InvReceive/useGetTransactionTypesReceiveList';
import Reference from '../Reference/Reference';
import { TreeSelect } from 'antd';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
//  import InvReceiveLines from './InvReceiveLines';
import ReceiveLines from './ReceiveLines';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetInventoryWithPermission from '../../../../hooks/NTX/Organization/useGetInventoryWithPermission';
import useGetSubinventoryList from '../../../../hooks/INV/Inventories/useGetSubinventoryList';
import { getPersonId } from '../../../../store/Login/loginSlice';
import { useState } from 'react';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import NeatixModal from '../../../../components/Modal/NeatixModal';

const Add = () => {
  const [showModal, setShowModal] = useState(true);
  const [inventory, setInventory] = useState<any>(null);
  const [transType, setTransType] = useState<any>(null);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { dataEmployees, loading, error: error4 } = useGetPersons();
  const { BranchOrgOptions: BranchsOptions, loading: loading6 } = useGetBranchOrgWithPermission();
  const { InventoryOptions, loading: loading4 } = useGetInventoryWithPermission();
  const { loading: loading1, TreeSubInventoryData } = useGetSubinventoryList();
  const { treeCustomerData, error: error2, loading: loading2 } = useGetCustomersList();
  const { treeSuppliersData, error: error3, loading: loading3 } = useGetSuppliersList();
  const { OptionsTransactionTypes, loading: loading5, error } = useGetTransactionTypesReceiveList();

  const personId = useAppSelector(getPersonId);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      supplierInvoiceNum: '',
      code: '',
      transactionDate: '',
      description: '',
      branchId: '',
      transactionAmount: 0,
      referenceType: '',
      referenceId: '',
      supplierId: '',
      customerId: '',
      personId: personId,
      inventoryId: '',
      subInventoryId: '',
      address: '',
      note: '',
      transactionYear: 0,
      materialTransactionsLineList: [] as any[],
    },
    enableReinitialize: true,
    // validationSchema: AddGlJeHeaderSchema(t),
    onSubmit: (values) => {
      dispatch(
        addReceiveTransactions({
          code: values.code,
          transactionDate: values.transactionDate,
          description: values.description,
          branchId: values.branchId,
          transactionAmount: 0,
          referenceType: values.referenceType,
          referenceId: values.referenceId || null,
          supplierId: values.supplierId || null,
          customerId: values.customerId || null,
          personId: values.personId || null,
          inventoryId: values.inventoryId,
          address: values.address,
          note: values.note,
          no: 0,
          transactionYear: 0,

          materialTransactionsLineList: formik.values.materialTransactionsLineList?.map((item) => ({
            transactionItemId: item.transactionItemId,
            transactionUom: item.transactionUom,
            unitPrice: 0, // item.unitPrice
            transactionQuantityIn: item.transactionQuantityIn,
            transactionQuantityOut: 0,
            inventoryId: null,
            discount: 0,
            codeCombinationId: null,
            currencyCode: '',
            currencyConversionRate: 0,
            referenceIdMaster: null,
            referenceIdLine: null,
            transactionTypeId: null,
            equivalent: 0,
            expensesAmount: 0,
            batchId: null,
            lotId: item.lotId || null,
            // lotId: "8a0c424f-5244-48f8-6e53-08dce4412f5a",
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const handleSubmitModal = async () => {
    if (values.branchId && inventory && transType) {
      setShowModal(false);
      await formik.setFieldValue('inventoryId', inventory);
      await formik.setFieldValue('code', transType);
    } else {
      ToastSwal({ title: 'يرجى اختيار الفرع والمخزن', timer: 1000 });
    }
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'إذن إضـافة',
      link: '',
    },
    {
      name: 'إذن إضـافة جديد',
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="إذن إضـافة جديد" />

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row ">
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('التاريـخ')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="transactionDate"
                    onChange={formikhandleChange}
                    value={values.transactionDate || ''}
                    // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                  />
                  <div className="text-danger small">
                    {formik.touched.transactionDate && formik.errors.transactionDate}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع')}</label>
                  <Select
                    isDisabled
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    value={BranchsOptions?.find((f: any) => f.value === formik.values.branchId)}
                    // onChange={(option: any) => {
                    //   formik.setFieldValue('branchId', option === null ? null : option.value);
                    // }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {formik.touched.branchId && formik.errors.branchId}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن')}</label>
                  <Select
                    isDisabled
                    classNamePrefix="react-select"
                    isLoading={loading4}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    value={InventoryOptions?.find((f: any) => f.value === values.inventoryId)}
                    // onChange={(option: any) => {
                    //   formik.setFieldValue('inventoryId', option === null ? null : option.value);
                    //   // formik.setFieldValue('subInventoryId', null);
                    // }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {formik.touched.inventoryId && formik.errors.inventoryId}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن الفرعي')}</label>
                  <TreeSelect
                    showSearch
                    className="w-100"
                    treeLine
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    allowClear
                    treeDefaultExpandAll={false}
                    value={values.subInventoryId}
                    onChange={(option: any) => {
                      formik.setFieldValue('subInventoryId', option === null ? null : option);
                    }}
                    treeData={TreeSubInventoryData?.filter(
                      (f) => f.parentId === values.inventoryId
                    )}
                    treeNodeFilterProp="title"
                  />
                  <div className="text-danger small">
                    {formik.touched.subInventoryId && formik.errors.subInventoryId}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('نوع الحركة')}</label>
                  <Select
                    isDisabled
                    classNamePrefix="react-select"
                    // isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={OptionsTransactionTypes}
                    value={OptionsTransactionTypes.find((f) => f.value === values.code)}
                    onChange={(option: any) => {
                      formik.setFieldValue('code', option === null ? null : option.value);
                      formik.setFieldValue('materialTransactionsLineList', []);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {formik.touched.code && formik.errors.code}
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <Reference formik={formik} />
                  <div className="text-danger small">
                    {formik.touched.referenceId && formik.errors.referenceId}
                  </div>
                </div>
              </div>

              {['SPR', 'POR'].includes(values.code) && (
                <>
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('المورد')}</label>
                      <TreeSelect
                        showSearch
                        className="w-100"
                        treeLine
                        placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                        allowClear
                        treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                        // loadData={} // يتم تحميل البيانات عند الطلب
                        // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                        value={values.supplierId}
                        onChange={(option: any) => {
                          formik.setFieldValue('supplierId', option === null ? null : option);
                        }}
                        treeData={treeSuppliersData}
                        treeNodeFilterProp="title"
                      />
                      <div className="text-danger small">
                        {formik.touched.supplierId && formik.errors.supplierId}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('رقم فاتورة المورد')}</label>
                      <Form.Control
                        className="form-control  text-center"
                        type="text"
                        name="supplierInvoiceNum"
                        onChange={formikhandleChange}
                        value={values.supplierInvoiceNum || ''}
                        // isInvalid={!!(touched.supplierInvoiceNum && errors.supplierInvoiceNum)}
                      />
                      <div className="text-danger small">
                        {formik.touched.supplierInvoiceNum && formik.errors.supplierInvoiceNum}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {['INV', 'SOI'].includes(values.code) && (
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميل')}</label>

                    <TreeSelect
                      showSearch
                      className="w-100"
                      treeLine
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      // value={formik.values.supplierId ? { value: formik.values.supplierId, label: selectedSupplierLabel } : undefined}
                      value={values.customerId}
                      onChange={(option: any) => {
                        formik.setFieldValue('customerId', option === null ? null : option);
                      }}
                      treeData={treeCustomerData}
                      treeNodeFilterProp="title"
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {formik.touched.customerId && formik.errors.customerId}
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('personId - store keeper')}</label>
                  <Select
                    isDisabled
                    classNamePrefix="react-select"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    name="personId"
                    options={dataEmployees}
                    value={dataEmployees?.find((f: any) => f.value === values.personId)}
                    onChange={(option: any) => {
                      formik.setFieldValue('personId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  {/* <div className="text-danger small">
                      {formik.touched.personId && formik.errors.personId}
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group mb-3">
                <label className="form-label">{t('البيــان')}</label>
                <Form.Control
                  autoComplete="off"
                  className="form-control"
                  as="textarea"
                  rows={1}
                  name="description"
                  onChange={formik.handleChange}
                  value={values.description}
                  // isInvalid={!!(touched.description && errors.description)}
                  // isValid={touched.description && !errors.description}
                />
              </div>
            </div>

            <div className="row m-0 p-0">
              <div className="bg-inverse bg-opacity-5 p-2">
                <ReceiveLines formik={formik} />
s              </div>
            </div>

            <div className="text-center mt-3">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn btn-lg me-1 btn-theme mb-1">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t('Add')}
              </button>
              <button
                type="button"
                className="btn btn-lg  me-1 btn-default mb-1"
                onClick={() => Navigate(-1)}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        size="md"
        bodyClassName="p-0"
        showHeader={false}
        keyboard={false}
        backdrop={true}>
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    value={BranchsOptions?.find((f: any) => f.value === formik.values.branchId)}
                    onChange={(option: any) => {
                      formik.setFieldValue('branchId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading4}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    value={InventoryOptions?.find((f: any) => f.value === inventory)}
                    onChange={(option: any) => {
                      // formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      setInventory(option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('نوع الحركة')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isSearchable={true}
                    isClearable
                    options={OptionsTransactionTypes}
                    onChange={(option: any) => {
                      // formik.setFieldValue('code', option === null ? null : option.value);
                      setTransType(option === null ? null : option.value);
                      // formik.setFieldValue('materialTransactionsLineList', []);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {formik.touched.code && formik.errors.code}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-lg btn-theme  w-100 rounded-0"
                  onClick={handleSubmitModal}>
                  <i className="fas fa-lg fa-fw me-2 fa-check-circle"></i>
                  {t('إختيار')}
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-lg  btn-default  w-100 rounded-0"
                  onClick={() => Navigate('/INV/Transactions/InvReceive', { replace: true })}>
                  <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </NeatixModal>
    </div>
  );
};

export default Add;
