import React, { useState } from 'react';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/card/card';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetPurchaseRequestList from '../../../../hooks/PUR/PurchaseRequest/useGetPurchaseRequestList';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';

const SendToSuppliers = () => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);
  const { t, i18n } = useTranslation();
  // const Navigate = useNavigate();

  const { SuppliersList, loading, error } = useGetSuppliersList();

  const filteredSearch = SuppliersList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <Loading loading={loading} error={error} Type="Dots">
      <div>
        <div className="text-center bg-inverse bg-opacity-10 rounded-0 p-2 fw-bold">
          إرسال طلب الشراء الى المورديـــن
        </div>
        <div className="table-responsive">
          <table className="table table-hover text-nowrap">
            <thead className="bg-theme bg-opacity-30 rounded-0 p-1">
              <tr>
                <th scope="col">
                  {/* <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      // onChange={(e) => handleCheckboxAll(e)}
                    />
                  </div> */}
                </th>
                <th scope="col">{t('اسم المورد')}</th>
                {/* <th scope="col">{t('رقم المورد')}</th> */}
              </tr>
            </thead>
            <tbody>
              {currentData?.map((item, index) => (
                <tr key={item.id}>
                  <td className="align-middle">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        // onChange={(e) => handleCheckboxAll(e)}
                      />
                    </div>
                  </td>
                  <td className="align-middle">
                    {i18n.language === 'en' ? item.name : item.name2}
                  </td>
                  {/* <td className="align-middle">
                      { item.}
                    </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="p-3"> */}
        {/* <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          /> */}
        {/* </div> */}

        <div className="row">
          <div className="col-12">
            <button type="submit" className="btn btn-lg btn-theme  w-100 rounded-0">
              <i className="far fa-lg fa-fw me-2 fa-paper-plane"></i>
              {t('إرسال')}
            </button>
          </div>
          {/* <div className="col-6">
            <button type="button" className="btn btn-lg  btn-default   w-100 rounded-0">
              <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div> */}
        </div>
      </div>
    </Loading>
  );
};

export default SendToSuppliers;
