import { Card, CardBody, CardExpandToggler } from '../../../components/card/card.jsx';
import RequestList from '../../Ame/Requests/RequestList.js';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';

import BarChart from './BarChart.js';
import Loading from '../../../components/Shared/Loader/Loading';
import useGetQuotationList from '../../../hooks/Sales/useGetQuotationList';
import useGetInvoiceList from '../../../hooks/Sales/useGetInvoiceList';
import useGetSalesOrderList from '../../../hooks/Sales/useGetSalesOrderList';
// import useGetTransactionsList from '../../../hooks/Sales/useGetTransactionsList';

const Home = () => {
  // const { t } = useTranslation();

  // const { QuoteInvoiceList, SalesInvoicesList, SellOrderList, loading, error } =
    // useGetTransactionsList();

    const {SalesQuotationList , loading , error} = useGetQuotationList();
    const {SalesInvoicesList , } = useGetInvoiceList();
    const {SalesOrderList} = useGetSalesOrderList();
    

  return (
    <div>
      <Loading loading={loading} error={error}>
        <>
          <div className="row">
            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">عدد عروض الأسعار</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={SalesQuotationList?.length} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <i className="bi bi-file-earmark-text-fill fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">عدد أوامر البيع</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={SalesOrderList?.length} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <i className="bi bi-cart-fill fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">عدد الفواتيــر</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={SalesInvoicesList?.length} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <i className="bi bi-receipt-cutoff  fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">عدد مردود المبيعات</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">0</h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                         {/* <i className="bi bi-person-check-fill fa-4x text-theme"></i> */}
                        <i className="bi bi-arrow-counterclockwise fa-4x text-theme"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="col-xl-12">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-1 align-items-center">
                      <i className="bi bi-clock-history fa-2x me-2 text-warning"></i>
                      <span className="flex-grow-1  ">الطلبات تحت الإجراء</span>
                      <CardExpandToggler />
                    </div>

                    {/* <PerfectScrollbar> */}
                    <RequestList />
                    {/* </PerfectScrollbar> */}
                  </CardBody>
                </Card>
              </div>
            </div>

            {/* <div className="col-xl-6">
              <Card className="mb-3" style={{ height: '100%' }}>
                <CardBody className="">
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">توزيع الموظفين حسب الإدارة
                    </span>
                    <CardExpandToggler />
                  </div>
                  <div className="ratio ratio-21x9 mb-3">
                    {data && data?.length > 0 && <BarChart data={data} />}
                  </div>
                </CardBody>
              </Card>
            </div> */}
          </div>
         </>
       </Loading> 
    </div>
  );
};

export default Home;
