import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/Shared/Loader/Loading';
// import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import useGetConsolidationSet from '../../../../hooks/Finance/ConsolidationSet/useGetConsolidationSet';
import useGetCostCenters from '../../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useGetCompanies from '../../../../hooks/Finance/ChartAccountsDetails/useGetCompanies';
import useGetBranches from '../../../../hooks/Finance/ChartAccountsDetails/useGetBranches';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';

interface Props {
  onAccountSelect: (account: any) => void;
  accountDetails: any;
  // organizationId: string | null;organizationId
}

const CodeCombination = ({ onAccountSelect, accountDetails  }: Props) => {
  const { t, i18n } = useTranslation();
  const {
    GlCompanyId: Gl_company_id,
    // GetGlBrancheId,
    loading: loading2,
    error: error2,
  } = useGetConsolidationSet();

  const { TreeCostCenters, findAccountNumberById } = useGetCostCenters();
  const { OptionsCompanies } = useGetCompanies();
  const { OptionsBranches } = useGetBranches();
  const { OptionsAccounts, loading, error } = useGetAccounts();

  const GlCompany = OptionsCompanies?.filter((f) => f.value === Gl_company_id);
  // const GlBranch = OptionsBranches?.filter((f) => f.value === GetGlBrancheId(organizationId ||''));

  const [selectedAccounts, setSelectedAccounts] = useState({
    company: {
      id: accountDetails?.companyId ?? null,
      accountNumber:
        OptionsCompanies?.find((f) => f.value === accountDetails?.companyId)?.accountNumber ?? '',
    },
    branch: {
      id: accountDetails?.branchId ?? null,
      accountNumber:
        OptionsBranches?.find((f) => f.value === accountDetails?.branchId)?.accountNumber ?? '',
    },

    costCenter: {
      id: accountDetails?.costCenterId ?? null,
      accountNumber: findAccountNumberById(accountDetails?.costCenterId) ?? '',
    },

    account: {
      id: accountDetails?.accountId ?? null,
      accountNumber:
        OptionsAccounts?.find((f) => f.value === accountDetails?.accountId)?.accountNumber ?? '',
    },
  });

  useEffect(() => {
    // تحديث بيانات الشركة
    const company = GlCompany[0];
    if (company?.value && !selectedAccounts.company.id) {
      setSelectedAccounts((prevState) => ({
        ...prevState,
        company: {
          id: company.value ?? null,
          accountNumber: company.accountNumber ?? '',
        },
      }));
    }

    // تحديث بيانات الفرع
    // const branch = GlBranch[0];
    // if (branch?.value && !selectedAccounts.branch.id) {
    //   setSelectedAccounts((prevState) => ({
    //     ...prevState,
    //     branch: {
    //       id: branch.value ?? null,
    //       accountNumber: branch.accountNumber ?? '',
    //     },
    //   }));
    // }
  }, [ GlCompany, selectedAccounts.branch.id, selectedAccounts.company.id]);

  const handleChange = (name: string, option: any) => {
    if (name === 'costCenter') {
      var accountNumber = findAccountNumberById(option);
      setSelectedAccounts((prevValues) => ({
        ...prevValues,
        [name]: { id: option, accountNumber: accountNumber },
      }));
    } else {
      setSelectedAccounts((prevValues) => ({
        ...prevValues,
        [name]: { id: option?.value || null, accountNumber: option?.accountNumber || '' },
      }));
    }
  };

  const ChooseAccount = () => {
    const { company, branch, costCenter, account } = selectedAccounts;

    if (!company.id || !branch.id || !costCenter.id || !account.id) {
      ToastSwal({
        title: i18n.language === 'ar' ? 'يرجى إدخال جميع الحقول' : 'Please fill out all fields',
        position: 'top-start',
      });

      return;
    }

    onAccountSelect(selectedAccounts); // Send the selected account back to parent
  };

  return (
    <Loading loading={loading || loading2} error={error || error2} Type="Dots">
      <div className="row">
        <div className="col-lg-12  mb-4">
          <div className="input-group flex-nowrap ">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '140px' }}>
              {t('Company')}
            </span>
            {/* {GlCompany?.length > 0 && ( */}
            <Select
              isDisabled
              classNamePrefix="react-select"
              className=" w-100"
              isLoading={loading}
              isSearchable={true}
              options={GlCompany}
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              onChange={(option) => handleChange('company', option)}
              value={GlCompany?.find((f) => f.value === selectedAccounts.company.id)}
              // defaultValue={GlCompany[0]}
            />
            {/* // )} */}
          </div>
        </div>

        <div className="col-lg-12 mb-4 ">
          <div className="input-group flex-nowrap">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '140px' }}>
              {t('Branch')}
            </span>
            <Select
              // isDisabled
              classNamePrefix="react-select"
              className=" w-100"
              isLoading={loading}
              isSearchable={true}
              isClearable
              options={OptionsBranches?.filter(
                (f) => f.accountParant === selectedAccounts.company.id
              )}
              // options={GlBranch?.filter((f) => f.accountParant === selectedAccounts.company.id)}
              name="branchId"
              // placeholder='إختر'
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              onChange={(option) => handleChange('branch', option)}
              value={OptionsBranches?.find((f) => f.value === selectedAccounts.branch.id)}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-4 ">
          <div className="input-group flex-nowrap">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '140px' }}>
              {t('CostCenter')}
            </span>
            <TreeSelect
              showSearch
              treeData={TreeCostCenters}
              style={{ borderRadius: '1px !important' }}
              className="custom-tree-select w-100  rounded-0"
              treeLine
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              allowClear
              treeDefaultExpandAll
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(option) => handleChange('costCenter', option)}
              value={selectedAccounts.costCenter.id}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-4 ">
          <div className="input-group flex-nowrap">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '140px' }}>
              {t('Accounts')}
            </span>
            <Select
              classNamePrefix="react-select"
              className=" w-100"
              isLoading={loading}
              isSearchable={true}
              isClearable
              name="accountId"
              options={OptionsAccounts}
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              onChange={(option) => handleChange('account', option)}
              value={OptionsAccounts?.find((f) => f.value === selectedAccounts.account.id)}
            />
          </div>
        </div>
      </div>

      <div className="text-center text-end">
        <button type="button" className="btn btn-theme w-25" onClick={() => ChooseAccount()}>
          {t('إختيــــار')}
        </button>
      </div>
    </Loading>
  );
};

export default CodeCombination;
