import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import useGetPersons from '../../../hooks/HR/use-get-persons';
import {
  UpdatNtxPeoples,
  AddNtxPeoples,
  GetNtxPeoplesByID,
  cleanPeoplesById,
  GetNtxPeoplesList,
} from '../../../store/NTX/Peoples/peoplesSlice';
import { useNavigate, useParams, NavigateFunction } from 'react-router-dom';
import { NtxPeopleSchema } from '../../ValidationForm/validationSchema';
import Loader from '../../../components/Shared/Loader/Loader';
import useGetLocations from '../../../hooks/HRSetting/use-get-locations';
import useLookupGetCustomerType from '../../../hooks/Lookups/use-Lookup-CustomerType';
import useGetPaymentTermsList from '../../../hooks/Sales/useGetPaymentTermsList';
import useGetGlLedgersList from '../../../hooks/Finance/useGetGlLedgersList';
import useLookupCurrency from '../../../hooks/Lookups/use-Lookup-Currency';
import useGetPriceList from '../../../hooks/INV/PriceList/useGetPriceList';
import { GetLookupPRIORITY } from '../../../store/Lookup/lookupSlice';
import useGetChartAccountsDetailsList from '../../../hooks/Finance/useGetChartAccountsDetailsList';
import { Card, CardBody } from '../../../components/card/card';

interface Props {
  fieldList?: string[];
  typeCode: string;
  isUpdate: boolean;
  parentId?: string | any;
}

const NtxPeoples: React.FC<Props> = (props) => {
  const { Id } = useParams();
  // const navigate = useNavigate()
  const navigate: NavigateFunction = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { dataEmployees, loading: personLoading } = useGetPersons();

  const { PaymentTermsList } = useGetPaymentTermsList();

  const { Currency } = useLookupCurrency();

  const { CUSTOMER_TYPE } = useLookupGetCustomerType();

  const { LedgersList } = useGetGlLedgersList();

  const { Locations, shipToLocation, billToLocation } = useGetLocations();

  const { OptionsAccounts, loading: loadingAccounts } = useGetChartAccountsDetailsList();

  const { PriceList } = useGetPriceList();

  useEffect(() => {
    dispatch(GetLookupPRIORITY());
  }, []);
  const { PRIORITY } = useAppSelector((state) => state.Lookup);

  useEffect(() => {
    dispatch(cleanPeoplesById());
    if (Id && props.isUpdate) {
      dispatch(GetNtxPeoplesByID(Id));
    }
  }, [Id, dispatch, props.isUpdate]);

  const { PeoplesById, loading } = useAppSelector((state) => state.Peoples);

  const formik = useFormik({
    initialValues: {
      id: PeoplesById?.id ?? null,
      name: PeoplesById?.name ?? '',
      name2: PeoplesById?.name2 ?? '',
      personId: PeoplesById?.personId ?? null,
      typeCode: props.typeCode,
      customerNumber: PeoplesById?.customerNumber ?? '',
      parentId: PeoplesById?.parentId ?? props.parentId,
      priceListId: PeoplesById?.priceListId ?? '',
      shipToLocationId: PeoplesById?.shipToLocationId ?? '',
      billToLocationId: PeoplesById?.billToLocationId ?? '',
      termsId: PeoplesById?.termsId ?? '',
      setOfBooksId: PeoplesById?.setOfBooksId ?? '',
      creditLimit: PeoplesById?.creditLimit ?? 0,
      paymentPriority: PeoplesById?.paymentPriority ?? '',
      invoiceCurrencyCode: PeoplesById?.invoiceCurrencyCode ?? '',
      paymentCurrencyCode: PeoplesById?.paymentCurrencyCode ?? '',
      invoiceLimit: PeoplesById?.invoiceAmountLimit ?? '',
      paymentMethodLookupCode: PeoplesById?.paymentMethodLookupCode ?? '',
      bankAccountName: PeoplesById?.bankAccountName ?? '',
      bankAccountNum: PeoplesById?.bankAccountNum ?? '',
      bankNumber: PeoplesById?.bankNumber ?? '',
      bankAccountType: PeoplesById?.bankAccountType ?? '',
      vatCode: PeoplesById?.vatCode ?? 0,
      tradingName: PeoplesById?.tradingName ?? '',
      workReference: PeoplesById?.workReference ?? '',
      companyRegistrationNumber: PeoplesById?.companyRegistrationNumber ?? '',
      nationalInsuranceNumber: PeoplesById?.nationalInsuranceNumber ?? '',
      verificationNumber: PeoplesById?.verificationNumber ?? '',
      email: PeoplesById?.email ?? '',
      phone: PeoplesById?.phone ?? '',
      fax: PeoplesById?.fax ?? '',
      mobile: PeoplesById?.mobile ?? '',
      address: PeoplesById?.address ?? '',
      description: PeoplesById?.description ?? '',
      extraInformationId: PeoplesById?.extraInformationId ?? '',
      peopleType: PeoplesById?.peopleType ?? '',
      peopleCategory: PeoplesById?.peopleCategory ?? '',
      accountId: PeoplesById?.accountId ?? '',
    },
    enableReinitialize: true,
    validationSchema: NtxPeopleSchema(t),
    onSubmit: (values) => {
      const requestBody = {
        id: values.id,
        name: values.name,
        name2: values.name2,
        personId: values.personId || null,
        typeCode: values.typeCode,
        customerNumber: values.customerNumber,
        parentId: values.parentId || null,
        priceListId: values.priceListId || null,
        shipToLocationId: values.shipToLocationId || null,
        billToLocationId: values.billToLocationId || null,
        termsId: values.termsId || null,
        setOfBooksId: values.setOfBooksId || null,
        creditLimit: values.creditLimit,
        paymentPriority: values.paymentPriority || null,
        invoiceCurrencyCode: values.invoiceCurrencyCode || null,
        paymentCurrencyCode: values.paymentCurrencyCode,
        invoiceLimit: values.invoiceLimit || null,
        paymentMethodLookupCode: values.paymentMethodLookupCode,
        bankAccountName: values.bankAccountName,
        bankAccountNum: values.bankAccountNum,
        bankNumber: values.bankNumber,
        bankAccountType: values.bankAccountType,
        vatCode: values.vatCode,
        tradingName: values.tradingName,
        workReference: values.workReference,
        companyRegistrationNumber: values.companyRegistrationNumber,
        nationalInsuranceNumber: values.nationalInsuranceNumber,
        verificationNumber: values.verificationNumber,
        email: values.email,
        phone: values.phone,
        fax: values.fax,
        mobile: values.mobile,
        address: values.address,
        description: values.description,
        extraInformationId: null,
        integrationField: null,
        peopleType: values.peopleType,
        peopleCategory: values.peopleCategory,
        accountId: values.accountId || null,
      };

      const actionToDispatch = props.isUpdate
        ? UpdatNtxPeoples(requestBody)
        : AddNtxPeoples(requestBody);

      dispatch(actionToDispatch)
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            if (props.isUpdate) {
              CustomAlert({ action: 'Edit' });
              dispatch(GetNtxPeoplesList(props.typeCode));
            } else {
              document.getElementById('closeModalForm')?.click();
              formik.resetForm();
              dispatch(GetNtxPeoplesList(props.typeCode));
              CustomAlert({ action: 'Add' });
            }
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  // var fieldList = ["typeCode", "customerNumber", "personId"];
  const FieldExist = {
    name: true,
    name2: true,
    typeCode: '',
    personId: props.fieldList?.some((x) => x === 'personId'),
    customerNumber: props.fieldList?.some((x) => x === 'customerNumber'),
    parentId: props.fieldList?.some((x) => x === 'parentId'),
    priceListId: props.fieldList?.some((x) => x === 'priceListId'),
    shipToLocationId: props.fieldList?.some((x) => x === 'shipToLocationId'),
    billToLocationId: props.fieldList?.some((x) => x === 'billToLocationId'),
    termsId: props.fieldList?.some((x) => x === 'termsId'),
    setOfBooksId: props.fieldList?.some((x) => x === 'setOfBooksId'),
    accountId: props.fieldList?.some((x) => x === 'accountId'),
    creditLimit: props.fieldList?.some((x) => x === 'creditLimit'),
    paymentPriority: props.fieldList?.some((x) => x === 'paymentPriority'),
    invoiceCurrencyCode: props.fieldList?.some((x) => x === 'invoiceCurrencyCode'),
    paymentCurrencyCode: props.fieldList?.some((x) => x === 'paymentCurrencyCode'),
    invoiceLimit: props.fieldList?.some((x) => x === 'invoiceLimit'),
    paymentMethodLookupCode: props.fieldList?.some((x) => x === 'paymentMethodLookupCode'),
    bankAccountName: props.fieldList?.some((x) => x === 'bankAccountName'),
    bankAccountNum: props.fieldList?.some((x) => x === 'bankAccountNum'),
    bankNumber: props.fieldList?.some((x) => x === 'bankNumber'),
    bankAccountType: props.fieldList?.some((x) => x === 'bankAccountType'),
    vatCode: props.fieldList?.some((x) => x === 'vatCode'),
    tradingName: props.fieldList?.some((x) => x === 'tradingName'),
    workReference: props.fieldList?.some((x) => x === 'workReference'),
    companyRegistrationNumber: props.fieldList?.some((x) => x === 'companyRegistrationNumber'),
    nationalInsuranceNumber: props.fieldList?.some((x) => x === 'nationalInsuranceNumber'),
    verificationNumber: props.fieldList?.some((x) => x === 'verificationNumber'),
    email: '',
    phone: '',
    fax: '',
    mobile: '',
    address: '',
    description: '',
    extraInformationId: '',

    peopleType: props.fieldList?.some((x) => x === 'peopleType'),
    peopleCategory: props.fieldList?.some((x) => x === 'peopleCategory'),
  };

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>

        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('EmployeeName')}</label>
              <Select
                classNamePrefix="react-select"
                isLoading={personLoading}
                isSearchable={true}
                isClearable
                name="personId"
                options={dataEmployees}
                onChange={(option: any) => {
                  formik.setFieldValue('personId', option === null ? null : option.value);
                }}
                value={
                  formik.values.personId
                    ? dataEmployees?.find((option: any) => option.value === formik.values.personId)
                    : null
                }
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                // value={formik.values?.personId}
                // value={dataEmployees?.find(
                //   (f: any) => f?.value === formik.values?.personId
                // )}
                // defaultValue={dataEmployees?.find(
                //   (f: any) => f?.value === PeoplesById?.personId
                // )}
              />

              {formik.errors.personId && formik.touched.personId && (
                <div style={{ color: 'red' }}>{formik.errors.personId}</div>
              )}
            </div>
          </div>

          {FieldExist.parentId && (
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">parentId</label>
                <Form.Select
                  className="form-select"
                  name="parentId"
                  onChange={formik.handleChange}
                  value={formik.values.parentId}
                  isInvalid={!!(formik.errors.parentId && formik.touched.parentId)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                </Form.Select>
                {/* <Form.Control.Feedback type="invalid">
                  {formik.errors.parentId}
                </Form.Control.Feedback> */}
              </div>
            </div>
          )}

          <>
            {FieldExist.customerNumber && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">رقم العميل</label>
                  <Form.Control
                    disabled
                    className="form-control"
                    type="number"
                    name="customerNumber"
                    onChange={formik.handleChange}
                    value={formik.values.customerNumber}
                    isInvalid={!!(formik.touched.customerNumber && formik.errors.customerNumber)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.customerNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.peopleCategory && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('التصنيف')}</label>
                  <Form.Select
                    className="form-select"
                    name="peopleCategory"
                    onChange={formik.handleChange}
                    value={formik.values.peopleCategory}
                    // isInvalid={formik.touched.peopleCategory && formik.errors.peopleCategory}
                  >
                    <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {CUSTOMER_TYPE?.map((item: any, idx: number) =>
                      item.list?.map((list: any) => (
                        <option key={++idx} value={list.lookupCode}>
                          {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                        </option>
                      ))
                    )}
                  </Form.Select>
                </div>
              </div>
            )}

            {FieldExist.priceListId && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('قائمة الأسعار')}</label>
                  <Form.Select
                    className="form-select"
                    name="priceListId"
                    onChange={formik.handleChange}
                    value={formik.values.priceListId}
                    isInvalid={!!(formik.touched.priceListId && formik.errors.priceListId)}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {PriceList &&
                      PriceList?.map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>
            )}

            {FieldExist.shipToLocationId && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">shipToLocationId - موقع الشحن</label>
                  <Form.Select
                    className="form-select"
                    name="shipToLocationId"
                    onChange={formik.handleChange}
                    value={formik.values.shipToLocationId || ''}
                    isInvalid={
                      !!(formik.errors.shipToLocationId && formik.touched.shipToLocationId)
                    }>
                    <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {Locations &&
                      shipToLocation?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>
            )}

            {FieldExist.billToLocationId && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">billToLocationId - موقع الفوترة</label>
                  <Form.Select
                    className="form-select"
                    name="billToLocationId"
                    onChange={formik.handleChange}
                    value={formik.values.billToLocationId || ''}
                    isInvalid={
                      !!(formik.errors.billToLocationId && formik.touched.billToLocationId)
                    }>
                    <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {Locations &&
                      billToLocation?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>
            )}

            {FieldExist.termsId && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">شروط الدفع</label>
                  <Form.Select
                    className="form-select"
                    name="termsId"
                    onChange={formik.handleChange}
                    value={formik.values.termsId}
                    isInvalid={!!(formik.errors.termsId && formik.touched.termsId)}>
                    <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {PaymentTermsList &&
                      PaymentTermsList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.termsId}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.setOfBooksId && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">دفتر الحسابات</label>
                  <Form.Select
                    className="form-select"
                    name="setOfBooksId"
                    onChange={formik.handleChange}
                    value={formik.values.setOfBooksId}
                    isInvalid={!!(formik.errors.setOfBooksId && formik.touched.setOfBooksId)}>
                    <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {LedgersList &&
                      LedgersList?.map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.setOfBooksId}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}
            {FieldExist.accountId && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الحساب')}</label>
                  <Select
                    classNamePrefix="react-select"
                    // className="text-center"
                    isLoading={loadingAccounts}
                    isSearchable={true}
                    isClearable
                    options={OptionsAccounts}
                    value={OptionsAccounts?.find((f) => f.value === formik.values.accountId)}
                    onChange={(option) => formik.setFieldValue('accountId', option?.value)}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
            )}

            {FieldExist.creditLimit && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">الحد الائتماني</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="creditLimit"
                    onChange={formik.handleChange}
                    value={formik.values.creditLimit}
                    isInvalid={!!(formik.touched.creditLimit && formik.errors.creditLimit)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.creditLimit}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}
            {FieldExist.paymentPriority && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">أولوية الدفع</label>
                  <Form.Select
                    className="form-select"
                    name="paymentPriority"
                    onChange={formik.handleChange}
                    value={formik.values.paymentPriority}
                    isInvalid={!!(formik.errors.paymentPriority && formik.touched.paymentPriority)}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {PRIORITY &&
                      PRIORITY?.map((item: any, idx) =>
                        item?.list?.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.paymentPriority}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.invoiceCurrencyCode && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">عملة الفاتورة</label>
                  <Form.Select
                    className="form-select"
                    name="invoiceCurrencyCode"
                    onChange={formik.handleChange}
                    value={formik.values.invoiceCurrencyCode || ''}
                    isInvalid={
                      !!(formik.touched.invoiceCurrencyCode && formik.errors.invoiceCurrencyCode)
                    }
                    // isValid={
                    //   formik.touched.invoiceCurrencyCode && !formik.errors.invoiceCurrencyCode
                    // }
                  >
                    {Currency &&
                      Currency?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.currencyCode}>
                          {item.description}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>
            )}
            {FieldExist.paymentCurrencyCode && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">عملة الدفع</label>
                  <Form.Select
                    className="form-select"
                    name="paymentCurrencyCode"
                    onChange={formik.handleChange}
                    value={formik.values.paymentCurrencyCode || ''}
                    isInvalid={
                      !!(formik.touched.paymentCurrencyCode && formik.errors.paymentCurrencyCode)
                    }
                    // isValid={
                    //   formik.touched.paymentCurrencyCode && !formik.errors.paymentCurrencyCode
                    // }
                  >
                    {Currency &&
                      Currency?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.currencyCode}>
                          {item.description}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.paymentCurrencyCode}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.invoiceLimit && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">حد مبلغ الفاتورة</label>
                  <Form.Control
                    type="number"
                    className="form-control"
                    name="invoiceLimit"
                    onChange={formik.handleChange}
                    value={formik.values.invoiceLimit}
                    isInvalid={!!(formik.errors.invoiceLimit && formik.touched.invoiceLimit)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.invoiceLimit}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.paymentMethodLookupCode && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">طريقة الدفع</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="paymentMethodLookupCode"
                    onChange={formik.handleChange}
                    value={formik.values.paymentMethodLookupCode}
                    isInvalid={
                      !!(
                        formik.touched.paymentMethodLookupCode &&
                        formik.errors.paymentMethodLookupCode
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.paymentMethodLookupCode}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.bankAccountName && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">اسم الحساب البنكي</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="bankAccountName"
                    onChange={formik.handleChange}
                    value={formik.values.bankAccountName}
                    isInvalid={!!(formik.touched.bankAccountName && formik.errors.bankAccountName)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bankAccountName}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.bankAccountNum && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">رقم الحساب البنكي</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="bankAccountNum"
                    onChange={formik.handleChange}
                    value={formik.values.bankAccountNum}
                    isInvalid={!!(formik.touched.bankAccountNum && formik.errors.bankAccountNum)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bankAccountNum}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.bankNumber && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">رقم البنك</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="bankNumber"
                    onChange={formik.handleChange}
                    value={formik.values.bankNumber}
                    isInvalid={!!(formik.touched.bankNumber && formik.errors.bankNumber)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bankNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.bankAccountType && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">نوع الحساب البنكي</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="bankAccountType"
                    onChange={formik.handleChange}
                    value={formik.values.bankAccountType}
                    isInvalid={!!(formik.touched.bankAccountType && formik.errors.bankAccountType)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bankAccountType}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.vatCode && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">الرقم الضريبي</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="vatCode"
                    onChange={formik.handleChange}
                    value={formik.values.vatCode}
                    isInvalid={!!(formik.touched.vatCode && formik.errors.vatCode)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.vatCode}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.tradingName && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">الاسم التجاري</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="tradingName"
                    onChange={formik.handleChange}
                    value={formik.values.tradingName}
                    isInvalid={!!(formik.touched.tradingName && formik.errors.tradingName)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.tradingName}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.workReference && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">اسم الشخص</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="workReference"
                    onChange={formik.handleChange}
                    value={formik.values.workReference}
                    isInvalid={!!(formik.touched.workReference && formik.errors.workReference)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.workReference}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.companyRegistrationNumber && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">رقم تسجيل الشركة</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="companyRegistrationNumber"
                    onChange={formik.handleChange}
                    value={formik.values.companyRegistrationNumber}
                    isInvalid={
                      !!(
                        formik.touched.companyRegistrationNumber &&
                        formik.errors.companyRegistrationNumber
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.companyRegistrationNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}

            {FieldExist.nationalInsuranceNumber && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">رقم الضمان الوطني</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="nationalInsuranceNumber"
                    onChange={formik.handleChange}
                    value={formik.values.nationalInsuranceNumber}
                    isInvalid={
                      !!(
                        formik.touched.nationalInsuranceNumber &&
                        formik.errors.nationalInsuranceNumber
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.nationalInsuranceNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}
            {FieldExist.verificationNumber && (
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">verificationNumber</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="verificationNumber"
                    onChange={formik.handleChange}
                    value={formik.values.verificationNumber}
                    isInvalid={
                      !!(formik.touched.verificationNumber && formik.errors.verificationNumber)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.verificationNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
            )}
          </>
        </div>

        <div className="row">
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Email')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                isInvalid={!!(formik.touched.email && formik.errors.email)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Phone')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                isInvalid={!!(formik.touched.phone && formik.errors.phone)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Fax')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="fax"
                onChange={formik.handleChange}
                value={formik.values.fax}
                isInvalid={!!(formik.touched.fax && formik.errors.fax)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.fax}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('MobileNumber')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="mobile"
                onChange={formik.handleChange}
                value={formik.values.mobile}
                isInvalid={!!(formik.touched.mobile && formik.errors.mobile)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.mobile}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Address')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                isInvalid={!!(formik.touched.address && formik.errors.address)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.address}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={1}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <div></div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-2"></i>
            )}
            {t('Save')}
          </button>
          <button
            type="button"
            className="btn me-1 btn-default mb-1"
            data-bs-dismiss={props.isUpdate ? null : 'modal'}
            onClick={props.isUpdate ? () => navigate(-1) : undefined}>
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default NtxPeoples;
