import React, { useEffect } from 'react';
import {
  getNoneInventoryById,
  receiveNoneInventoryItem,
} from '../../../../store/purchases/PurchaseBill/purchaseBillSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import Loading from '../../../../components/Shared/Loader/Loading';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface Props {
  id: string;
}

const validationSchema = Yup.object({
  receiptDate: Yup.date().required('مطلوب').nullable(),
});

const Receive = ({ id }: Props) => {
  const { UnitsOfMeasureList, loading: loading2, error: error2 } = useGetUnitsOfMeasureList();

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getNoneInventoryById(id));
  }, [dispatch, id]);
  const {
    NoneInventoryById: data,
    loadingNoneInventoryById,
    error,
  } = useAppSelector((a) => a.PurchaseBill);

  const formik = useFormik({
    initialValues: {
      id: '',
      receiptNote: '',
      receiptDate: '',
    },

    validationSchema,

    onSubmit: (values) => {
      dispatch(
        receiveNoneInventoryItem({
          id: data.id,
          receiptNote: values.receiptNote,
          receiptDate: values.receiptDate,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  return (
    <>
      <Loading loading={loadingNoneInventoryById || loading2} error={error || error2} Type="Dots">
        <div className={`table-responsive rounded-2 text-center  mb-4`}>
          <table border={1} className="table table-borderless  text-nowrap  table-hover mb-0">
            <thead className="bg-theme bg-opacity-30">
              <tr>
                <th className="align-middle  small" style={{ width: '2%' }}>
                  #
                </th>

                <th className="align-middle small" style={{ width: '30%' }}>
                  {t('الوصف')}
                </th>
                <th className="align-middle  small" style={{ width: '10%' }}>
                  {t('الوحدة')}
                </th>
                <th className="align-middle  small" style={{ width: '5%' }}>
                  {t('الكمية')}
                </th>
              </tr>
            </thead>

            <tbody className="fw-bold">
              {data?.transactionsLinesList?.slice()?.map((line, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle text-theme">{++index}</td>

                    <td className="align-middle ">{line.description}</td>

                    <td className="align-middle ">
                      {i18n.language === 'en'
                        ? UnitsOfMeasureList.find((f) => f.id === line.uom)?.name
                        : UnitsOfMeasureList.find((f) => f.id === line.uom)?.name2}
                    </td>

                    <td className="align-middle ">{line.qty}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <div className="row mb-3">
            <div className="col-lg-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('تاريخ الإستلام')}</label>
                <Form.Control
                  className="form-control fw-bold"
                  type="date"
                  autoComplete="off"
                  name="receiptDate"
                  onChange={formik.handleChange}
                  value={formik.values.receiptDate}
                  isInvalid={!!(formik.touched.receiptDate && formik.errors.receiptDate)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.receiptDate}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="form-group mb-3">
                <label className="form-label">{t('Note')}</label>
                <Form.Control
                  className="form-control fw-bold"
                  type="text"
                  autoComplete="off"
                  name="receiptNote"
                  onChange={formik.handleChange}
                  value={formik.values.receiptNote}
                  isInvalid={!!(formik.touched.receiptNote && formik.errors.receiptNote)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.receiptNote}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn btn-lg me-1 btn-theme mb-1 ">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw me-2 fa-receipt"></i>
              )}
              {t('إستـــلام')}
            </button>
            <button type="button" className=" btn  btn-lg me-1 btn-default mb-1">
              <i className="fas fa-times fa-fw me-1"></i>
              {t('Cancel')}
            </button>
          </div>
        </Form>
      </Loading>
    </>
  );
};

export default Receive;
