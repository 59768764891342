import { Card, CardBody } from '../../../../../components/card/card';

import { useTranslation } from 'react-i18next';

import AddExpenseCategoryItems from './AddExpenseCategoryItems';
import useGetExpenseCategoryItemsList from '../../../../../hooks/Finance/Expense/useGetExpenseCategoryItemsList';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import { useState } from 'react';
import Loading from '../../../../../components/Shared/Loader/Loading';
import UpdateExpenseCategoryItems from './UpdateExpenseCategoryItems';

interface Props {
  expenseCategoryId?: string;
}

const ExpenseCategoryItems = ({ expenseCategoryId }: Props) => {
  const { t, i18n } = useTranslation();
  const [categoryItems, setCategoryItems] = useState<any>();
  const {
    ApExpenseCategoryItemsList: data,
    loading,
    error,
  } = useGetExpenseCategoryItemsList({
    expenseCategoryId: expenseCategoryId ?? '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(4);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <Loading loading={loading} error={error}>
        <AddExpenseCategoryItems expenseCategoryId={expenseCategoryId} />

        <div className="table-responsive">
          <table className="table table-hover text-nowrap">
            <thead className="table-dark">
              <tr>
                <th scope="col">{t('Name')}</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {currentData?.map((item) => (
                <tr key={item.id}>
                  <td className="align-middle">
                    {categoryItems && categoryItems.id === item.id ? (
                      <UpdateExpenseCategoryItems
                      setCategoryItems={setCategoryItems}
                        CategoryItems={categoryItems}
                      />
                    ) : (
                      <>{i18n.language === 'en' ? item.name : item.name2}</>
                    )}
                  </td>

                  <td className="text-end align-middle">
                    {!categoryItems || categoryItems.id !== item.id ? (
                      <button
                        type="button"
                        className="btn btn-outline-theme ms-1"
                        onClick={() => setCategoryItems(item)}>
                        <i className="far fa-lg fa-fw fa-edit me-1"></i>
                        {t('Button.Edit')}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className=" btn me-1 btn-default"
                        onClick={() => setCategoryItems(null)}>
                        <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          filteredData={data}
          dataPerPage={dataPerPage}
          handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
          currentPage={currentPage}
        />
      </Loading>
    </>
  );
};

export default ExpenseCategoryItems;

// {/* <tbody>
//               {currentData?.map((item, index) => (
//                 <tr key={item.id}>
//                   <td className="align-middle">
//                     {i18n.language === 'en' ? item.name : item.name2}{' '}
//                   </td>

//                   <td className="text-end">
//                     <button
//                       type="button"
//                       className="btn btn-outline-theme ms-1"
//                       onClick={() => {
//                         setCategoryItems(item);
//                       }}>
//                       <i className="far fa-lg fa-fw fa-edit me-1"></i>
//                       {t('Button.Edit')}
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody> */}
