import { useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Get_NEW_USED } from '../../store/Lookup/lookupSlice';
// use-Get_DEPRECIATION_METHOD
const useGetNewUsed = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (NEW_USED.length === 0) {
      dispatch(Get_NEW_USED());
    }
  }, []);

  const { NEW_USED, loading, error } = useSelector((state) => state.Lookup);

  return { NEW_USED, loading, error };
};

export default useGetNewUsed;
