import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../hooks/HR/use-get-persons';

import { useNavigate } from 'react-router-dom';
import useGetLocations from '../../../hooks/HRSetting/use-get-locations';
import useLookupGetCustomerType from '../../../hooks/Lookups/use-Lookup-CustomerType';
import useGetPaymentTermsList from '../../../hooks/Sales/useGetPaymentTermsList';
import useGetGlLedgersList from '../../../hooks/Finance/useGetGlLedgersList';
import useLookupCurrency from '../../../hooks/Lookups/use-Lookup-Currency';
import useGetChartAccountsDetailsList from '../../../hooks/Finance/useGetChartAccountsDetailsList';
import { Card, CardBody } from '../../../components/card/card';
import useLookupPRIORITY from '../../../hooks/Lookups/use-Lookup-PRIORITY';
import useLookupPeopleType from '../../../hooks/Lookups/use-Lookup-PeopleType';

const SupplierForm = ({ formik }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { dataEmployees, loading: personLoading } = useGetPersons();
  const { PaymentTermsList } = useGetPaymentTermsList();
  const { Currency } = useLookupCurrency();
  const { CUSTOMER_TYPE } = useLookupGetCustomerType();
  const { LedgersList } = useGetGlLedgersList();
  const { Locations, shipToLocation, billToLocation } = useGetLocations();
  const { OptionsAccounts, loading: loadingAccounts } = useGetChartAccountsDetailsList();
  const { PRIORITY } = useLookupPRIORITY();

  const { PEOPLE_TYPE } = useLookupPeopleType();

  return (
    <>
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="mb-5">
              <h5 className="border-bottom border-1 border-theme pb-2 d-inline-block">
                <i className="bi bi-info-circle fa-lg  text-theme"></i>
                {t('BasicInformation')}
              </h5>

              <div className="row">
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('LocalName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="name2"
                      onChange={formik.handleChange}
                      value={formik.values.name2}
                      isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('GlobalName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      isInvalid={!!(formik.touched.name && formik.errors.name)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name2}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* .customerNumber  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('SupplierNumber')}</label>
                    <Form.Control
                      disabled
                      className="form-control"
                      type="number"
                      name="customerNumber"
                      onChange={formik.handleChange}
                      value={formik.values.customerNumber}
                      isInvalid={!!(formik.touched.customerNumber && formik.errors.customerNumber)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.customerNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* tradingName   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('TradingName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="tradingName"
                      onChange={formik.handleChange}
                      value={formik.values.tradingName}
                      isInvalid={!!(formik.touched.tradingName && formik.errors.tradingName)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.tradingName}
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/* peopleCategory  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Category')}</label>
                    <Form.Select
                      className="form-select"
                      name="peopleCategory"
                      onChange={formik.handleChange}
                      value={formik.values.peopleCategory}
                      // isInvalid={formik.touched.peopleCategory && formik.errors.peopleCategory}
                    >
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {CUSTOMER_TYPE?.map((item, idx) =>
                        item.list?.map((list) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                          </option>
                        ))
                      )}
                    </Form.Select>
                  </div>
                </div>

                {/* PEOPLE_TYPE  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('SupplierType')}</label>
                    <Form.Select
                      className="form-select"
                      name="peopleType"
                      onChange={formik.handleChange}
                      value={formik.values.peopleType}
                      isInvalid={!!(formik.errors.peopleType && formik.touched.peopleType)}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PEOPLE_TYPE &&
                        PEOPLE_TYPE?.map((item, idx) =>
                          item?.list?.map((list) => (
                            <option key={++idx} value={list.lookupCode}>
                              {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                            </option>
                          ))
                        )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.peopleType}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('EmployeeName')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={personLoading}
                      isSearchable={true}
                      isClearable
                      name="personId"
                      options={dataEmployees}
                      onChange={(option) => {
                        formik.setFieldValue('personId', option === null ? null : option.value);
                      }}
                      value={
                        formik.values.personId
                          ? dataEmployees?.find((option) => option.value === formik.values.personId)
                          : null
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      // value={formik.values?.personId}
                      // value={dataEmployees?.find(
                      //   (f: any) => f?.value === formik.values?.personId
                      // )}
                      // defaultValue={dataEmployees?.find(
                      //   (f: any) => f?.value === PeoplesById?.personId
                      // )}
                    />

                    {formik.errors.personId && formik.touched.personId && (
                      <div style={{ color: 'red' }}>{formik.errors.personId}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <h5 className="border-bottom border-1 border-theme pb-2 d-inline-block">
                <i className="bi bi-person-lines-fill fa-lg  text-theme"></i>
                {t('PersonalAndContactInformation')}
              </h5>

              <div className="row">
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Email')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      isInvalid={!!(formik.touched.email && formik.errors.email)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Phone')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="phone"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      isInvalid={!!(formik.touched.phone && formik.errors.phone)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.phone}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Fax')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="fax"
                      onChange={formik.handleChange}
                      value={formik.values.fax}
                      isInvalid={!!(formik.touched.fax && formik.errors.fax)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.fax}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('MobileNumber')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="mobile"
                      onChange={formik.handleChange}
                      value={formik.values.mobile}
                      isInvalid={!!(formik.touched.mobile && formik.errors.mobile)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.mobile}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Address')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                      isInvalid={!!(formik.touched.address && formik.errors.address)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.address}
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/* companyRegistrationNumber  */}
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('CompanyRegistrationNumber')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="companyRegistrationNumber"
                      onChange={formik.handleChange}
                      value={formik.values.companyRegistrationNumber}
                      isInvalid={
                        !!(
                          formik.touched.companyRegistrationNumber &&
                          formik.errors.companyRegistrationNumber
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.companyRegistrationNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* nationalInsuranceNumber  الرقم الوطني (للمؤسسات). */}
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('NationalInsuranceNumber')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="nationalInsuranceNumber"
                      onChange={formik.handleChange}
                      value={formik.values.nationalInsuranceNumber}
                      isInvalid={
                        !!(
                          formik.touched.nationalInsuranceNumber &&
                          formik.errors.nationalInsuranceNumber
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.nationalInsuranceNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <h5 className="border-bottom border-1 border-theme pb-2 d-inline-block">
                <i className="bi bi-wallet2 fa-lg  text-theme"></i>{' '}
                {t('FinancialAndBankingInformation')}
              </h5>

              <div className="row">
                {/* invoiceCurrencyCode  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('InvoiceCurrency')}</label>
                    <Form.Select
                      className="form-select"
                      name="invoiceCurrencyCode"
                      onChange={formik.handleChange}
                      value={formik.values.invoiceCurrencyCode || ''}
                      isInvalid={
                        !!(formik.touched.invoiceCurrencyCode && formik.errors.invoiceCurrencyCode)
                      }
                      // isValid={
                      //   formik.touched.invoiceCurrencyCode && !formik.errors.invoiceCurrencyCode
                      // }
                    >
                      {Currency &&
                        Currency?.map((item, idx) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>

                {/* paymentCurrencyCode  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentCurrency')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentCurrencyCode"
                      onChange={formik.handleChange}
                      value={formik.values.paymentCurrencyCode || ''}
                      isInvalid={
                        !!(formik.touched.paymentCurrencyCode && formik.errors.paymentCurrencyCode)
                      }
                      // isValid={
                      //   formik.touched.paymentCurrencyCode && !formik.errors.paymentCurrencyCode
                      // }
                    >
                      {Currency &&
                        Currency?.map((item, idx) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.paymentCurrencyCode}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* invoiceLimit   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('InvoiceLimit')}</label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      name="invoiceLimit"
                      onChange={formik.handleChange}
                      value={formik.values.invoiceLimit}
                      isInvalid={!!(formik.errors.invoiceLimit && formik.touched.invoiceLimit)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.invoiceLimit}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* creditLimit   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('CreditLimit')}</label>
                    <Form.Control
                      className="form-control"
                      type="number"
                      name="creditLimit"
                      onChange={formik.handleChange}
                      value={formik.values.creditLimit}
                      isInvalid={!!(formik.touched.creditLimit && formik.errors.creditLimit)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.creditLimit}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* paymentMethodLookupCode   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="paymentMethodLookupCode"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodLookupCode}
                      isInvalid={
                        !!(
                          formik.touched.paymentMethodLookupCode &&
                          formik.errors.paymentMethodLookupCode
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.paymentMethodLookupCode}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* bankAccountName   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('BankAccountName')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="bankAccountName"
                      onChange={formik.handleChange}
                      value={formik.values.bankAccountName}
                      isInvalid={
                        !!(formik.touched.bankAccountName && formik.errors.bankAccountName)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.bankAccountName}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* bankAccountNum   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('BankAccountNumber')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="bankAccountNum"
                      onChange={formik.handleChange}
                      value={formik.values.bankAccountNum}
                      isInvalid={!!(formik.touched.bankAccountNum && formik.errors.bankAccountNum)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.bankAccountNum}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* bankNumber  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('BankNumber')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="bankNumber"
                      onChange={formik.handleChange}
                      value={formik.values.bankNumber}
                      isInvalid={!!(formik.touched.bankNumber && formik.errors.bankNumber)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.bankNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* bankAccountType   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('BankAccountType')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="bankAccountType"
                      onChange={formik.handleChange}
                      value={formik.values.bankAccountType}
                      isInvalid={
                        !!(formik.touched.bankAccountType && formik.errors.bankAccountType)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.bankAccountType}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* priceListId   */}
                {/* <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('قائمة الأسعار')}</label>
                    <Form.Select
                      className="form-select"
                      name="priceListId"
                      onChange={formik.handleChange}
                      value={formik.values.priceListId}
                      isInvalid={!!(formik.touched.priceListId && formik.errors.priceListId)}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PriceList &&
                        PriceList?.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div> */}

                {/* accountId    */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Account')}</label>
                    <Select
                      classNamePrefix="react-select"
                      // className="text-center"
                      isLoading={loadingAccounts}
                      isSearchable={true}
                      isClearable
                      options={OptionsAccounts}
                      value={OptionsAccounts?.find((f) => f.value === formik.values.accountId)}
                      onChange={(option) => formik.setFieldValue('accountId', option?.value)}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div>

                {/*  prepaymentAccountId   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PrepaymentAccount')}</label>
                    <Select
                      classNamePrefix="react-select"
                      // className="text-center"
                      isLoading={loadingAccounts}
                      isSearchable={true}
                      isClearable
                      options={OptionsAccounts}
                      value={OptionsAccounts?.find(
                        (f) => f.value === formik.values.prepaymentAccountId
                      )}
                      onChange={(option) =>
                        formik.setFieldValue('prepaymentAccountId', option?.value)
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div>

                {/* setOfBooksId  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('SetOfBooks')}</label>
                    <Form.Select
                      className="form-select"
                      name="setOfBooksId"
                      onChange={formik.handleChange}
                      value={formik.values.setOfBooksId}
                      isInvalid={!!(formik.errors.setOfBooksId && formik.touched.setOfBooksId)}>
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {LedgersList &&
                        LedgersList?.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.setOfBooksId}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <h5 className="border-bottom border-1 border-theme pb-2 d-inline-block">
                <i className="bi bi-building fa-lg  text-theme"></i>{' '}
                {t('InstitutionalLegalIdentity')}
              </h5>

              <div className="row">
                {/* vatCode  */}
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('VATCode')}</label>
                    <Form.Control
                      className="form-control"
                      type="number"
                      name="vatCode"
                      onChange={formik.handleChange}
                      value={formik.values.vatCode}
                      isInvalid={!!(formik.touched.vatCode && formik.errors.vatCode)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.vatCode}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/*   verificationNumber  */}
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('VerificationNumber')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="verificationNumber"
                      onChange={formik.handleChange}
                      value={formik.values.verificationNumber}
                      isInvalid={
                        !!(formik.touched.verificationNumber && formik.errors.verificationNumber)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.verificationNumber}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* workReference   */}
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('WorkReference')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      name="workReference"
                      onChange={formik.handleChange}
                      value={formik.values.workReference}
                      isInvalid={!!(formik.touched.workReference && formik.errors.workReference)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.workReference}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <h5 className="border-bottom border-1 border-theme pb-2 d-inline-block">
                <i className="bi bi-geo-alt fa-lg  text-theme"></i> {t('LocationAndPayment')}
              </h5>

              <div className="row">
                {/* shipToLocationId  */}
                {/* <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">shipToLocationId - موقع الشحن</label>
                    <Form.Select
                      className="form-select"
                      name="shipToLocationId"
                      onChange={formik.handleChange}
                      value={formik.values.shipToLocationId || ''}
                      isInvalid={
                        !!(formik.errors.shipToLocationId && formik.touched.shipToLocationId)
                      }>
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {Locations &&
                        shipToLocation?.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div> */}

                {/* billToLocationId   */}
                {/* <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">billToLocationId - موقع الفوترة</label>
                    <Form.Select
                      className="form-select"
                      name="billToLocationId"
                      onChange={formik.handleChange}
                      value={formik.values.billToLocationId || ''}
                      isInvalid={
                        !!(formik.errors.billToLocationId && formik.touched.billToLocationId)
                      }>
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {Locations &&
                        billToLocation?.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div> */}

                {/* termsId   */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentTerms')}</label>
                    <Form.Select
                      className="form-select"
                      name="termsId"
                      onChange={formik.handleChange}
                      value={formik.values.termsId}
                      isInvalid={!!(formik.errors.termsId && formik.touched.termsId)}>
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList?.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.termsId}
                    </Form.Control.Feedback>
                  </div>
                </div>

                {/* paymentPriority  */}
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentPriority')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentPriority"
                      onChange={formik.handleChange}
                      value={formik.values.paymentPriority}
                      isInvalid={
                        !!(formik.errors.paymentPriority && formik.touched.paymentPriority)
                      }>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PRIORITY &&
                        PRIORITY?.map((item, idx) =>
                          item?.list?.map((list) => (
                            <option key={++idx} value={list.lookupCode}>
                              {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                            </option>
                          ))
                        )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.paymentPriority}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn btn-lg me-1 btn-theme mb-1 rounded-2">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t('Save')}
              </button>
              <button
                type="button"
                className="btn btn-lg me-1 btn-default mb-1 rounded-2"
                onClick={() => navigate(-1)}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default SupplierForm;
