import { useFormik } from 'formik';
// import React, { useContext, useEffect } from "react";
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link, useNavigate } from 'react-router-dom';

import { addInventoryCount } from '../../../../store/INV/InventoryCount/inventoryCountSlice';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import { useState } from 'react';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import useGetInventoryWithPermission from '../../../../hooks/NTX/Organization/useGetInventoryWithPermission';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import { getPersonId } from '../../../../store/Login/loginSlice';
import AddEntries from './AddEntries';
import EnteryList from './EnteryList';

const InventoryCountEntries = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { dataEmployees, loading } = useGetPersons();
  const personId = useAppSelector(getPersonId);

  // const { loading: loading1, TreeSubInventoryData } = useGetSuppliersList();

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'جرد المخزون',
      link: '',
    },
    {
      name: 'إدخالات الجرد',
      link: null,
    },
  ];

  // console.log(values);

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="إدخالات الجـرد" />

      <Card>
        <CardBody className="pt-1">
          <ul className="nav nav-tabs nav-tabs-v2 px-2">
            <li className="nav-item me-3">
              <Link to="#add" className="nav-link active" data-bs-toggle="tab">
                <i className="fas fa-clipboard-list me-2 text-theme fs-5"></i>
                ادخال الجرد
              </Link>
            </li>
            <li className="nav-item me-3">
              <Link to="#profilev2" className="nav-link" data-bs-toggle="tab">
                <i className="fas fa-folder-open me-2 text-theme fs-5"></i> الإدخالات السابقة
              </Link>
            </li>
          </ul>

          <div className="tab-content pt-3">
            <div className="tab-pane fade show active" id="add">
              <AddEntries />
            </div>

            <div className="tab-pane fade" id="profilev2">
              <EnteryList />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default InventoryCountEntries;
