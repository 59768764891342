import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import Loader from '../../../../components/Shared/Loader/Loader';
import useLookupUomClass from '../../../../hooks/Lookups/use-Lookup-Uom_Class';

const Index = () => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('');

  const { UOM_CLASS } = useLookupUomClass();

  const { UnitsOfMeasureList, loading } = useGetUnitsOfMeasureList();

  const filteredSearch = UnitsOfMeasureList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === '' ? true : f.class === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'وحدات القياس',
      link: null,
    },
  ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="وحدات القياس" />

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="input-group mb-2">
                <div className="flex-fill position-relative">
                  <div className="input-group ">
                    <select
                      className="btn btn-outline-default dropdown-toggle p-0 w-25"
                      value={selectedValue}
                      onChange={(e) => setSelectedValue(e.target.value)}>
                      <option className="dropdown-item" value={''}>
                        {t('All')}
                      </option>
                      {UOM_CLASS &&
                        UOM_CLASS?.map((item: any, idx: any) =>
                          item.list?.map((list: any) => (
                            <option key={++idx} value={list.lookupCode}>
                              {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                            </option>
                          ))
                        )}
                    </select>

                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px"
                          placeholder={t('Search')}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* //  START TABLE   */}
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className="table-dark">
                <tr>
                  <th scope="col">{t('Name')}</th>
                  <th scope="col">{t('Code')}</th>
                  {/* <th scope="col">{t("class")}</th> */}
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index) => (
                  <tr key={++index}>
                    <td className="align-middle">
                      {i18n.language === 'en' ? item.name : item.name2}
                    </td>
                    <td className="align-middle">{item.code}</td>
                    {/* <td className="align-middle">{item.class}</td> */}

                    <td className="align-middle">
                      <div className="btn-group">
                        {/* <button
                            type="button"
                            className="btn btn-outline-theme"
                            //  data-bs-toggle="modal"
                            //  data-bs-target="#modalUpdateCategory"
                            //  onClick={() =>  setCategoryId(item.id)}
                          >
                            <i className="far fa-lg fa-fw fa-edit"></i>
                            {t("Button.Edit")}
                          </button> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </CardBody>
        {/* </Loading> */}
      </Card>
    </div>
  );
};

export default Index;
