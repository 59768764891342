import React from 'react';
import ViewStaticForm from './ViewStaticForm';
import BuilderValue from './BuilderValue';

const ContentRequest = ({ data }) => {
  return (
    <>
      <div className="my-3">
        <div className="col-xl-12 m-auto ">
          {data?.is_Static && (
            <>
              <ViewStaticForm data={data}  />
            </>
          )}

          {data?.values?.length > 0 && <BuilderValue data={data.values} />}
        </div>
      </div>
    </>
  );
};

export default ContentRequest;
