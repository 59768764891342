import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetGlJeCategoryList } from '../../store/Finance/JeCategory/categorySlice';
import { useTranslation } from 'react-i18next';

const useGetGlJeCategoryList = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (GlJeCategoryList?.length === 0) {
      dispatch(GetGlJeCategoryList());
    }
  }, []);
  const { GlJeCategoryList, loading, error } = useAppSelector((state) => state.JeCategory);

  
  var OptionsJeCategory = GlJeCategoryList?.map(({ id, name, name2 }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2} `,
  }));

  return { GlJeCategoryList, OptionsJeCategory, loading, error };
};

export default useGetGlJeCategoryList;
