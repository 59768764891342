import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { UnPaymentSchedulesModels } from './ApInvoicesModels';

interface UnpaidInvoicesState {
  UnPaymentSchedules: UnPaymentSchedulesModels[];
  isLoading: boolean;
}

const initialState: UnpaidInvoicesState = {
  UnPaymentSchedules: [],
  isLoading: false,
};

// مستخدمة في Ap Payment لجلب فواتيـر المورد الغير مدفوعة
export const fetchUnpaidInvoices = createAsyncThunk(
  'unpaidInvoices/fetchUnpaidInvoices',
  async (params: { peopleId: string; organizationId: string; invoiceType: string }) => {
    const { data } = await ntxAPI.get(`Ap/GetUnPaymentSchedulesApInvoicesList`, {
      params,
    });
    return data;
  }
);


// مستخدمة في Ar Receipt لجلب فواتيـر العميـل الغير مدفوعة
export const fetchUnpaidTransactions = createAsyncThunk(
  'unpaidInvoices/fetchUnpaidTransactions',
  async (params: { peopleId: string; organizationId: string; TransactionType: string }) => {
    const { data } = await ntxAPI.get(`Ar/GetUnReceiptSchedulesTransactionsList`, {
      params,
    });
    return data;
  }
);



const unpaidInvoicesSlice = createSlice({
  name: 'unpaidInvoices',
  initialState,
  reducers: {},
});

export default unpaidInvoicesSlice.reducer;
