import React, { memo, useEffect, useState } from "react";
import useGetCategoriesList from "../../../hooks/INV/useGetCategoriesList";
import { useTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link } from "react-router-dom";
import SwalAlert from "../../../components/Shared/Alert/SwalAlert";
import { Card, CardBody, CardHeader } from "../../../components/card/card";
import { Form, Tooltip } from "react-bootstrap";
import { FormikProps } from "formik";

const voucherCode = require("voucher-code-generator");

interface listModels {
  NumberOfCoupons: string;
  expiryDate: string;
}

interface Props {
  formik: FormikProps<any>;
}

const Coupons = ({ formik }: Props) => {
  const [expiryDate, setExpiryDate] = useState("");
  const [numberOfCoupons, setNumberOfCoupons] = useState(1);

  const { t, i18n } = useTranslation();
  const [list, setList] = useState<listModels[]>([]);

  //   const handleAddToList = () => {
  //     if (list.some((s) => s.id === selected)) {
  //       SwalAlert({ text: "تم إضافة هذه الفئة مسبقاً", icon: "warning" });
  //       return;
  //     }

  //     if (selected) {
  //       setList((prevList) => [...prevList, { id: selected }]);
  //       //   setSelected("")
  //     }
  //   };

  const handleDelete = (id: string) => {
    const updatedLines = list?.filter((f) => f.NumberOfCoupons !== id);

    setList(updatedLines);
  };

    useEffect(() => {
      formik?.setFieldValue("discountVouchersCouponsList", list);
    }, [list]);



  const generateMultipleCoupons = () => {
    if(!numberOfCoupons || !expiryDate){
        return;
    }

    const codes = voucherCode.generate({
      length: 10,
      count: numberOfCoupons,
    });

    // setList(codes?.map((code:any) => ({ NumberOfCoupons: code })));

    const generatedCoupons = codes?.map((code: any) => ({
      NumberOfCoupons: code,
      expiryDate: expiryDate,
    }));

    // Add the generated coupons to the existing list state
    setList([...list, ...generatedCoupons]);

  };

  

  return (
    <>
      <div className="row d-flex align-items-center">
        <div className="col-lg-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("عدد الكوبونات")}</label>
            <Form.Control
              className="form-control "
              type="number"
              value={numberOfCoupons}
              onChange={(e) => setNumberOfCoupons(parseInt(e.target.value))}
            />
          </div>
        </div>

        <div className="col-lg-5">
          <div className="form-group mb-3">
            <label className="form-label">{t("تاريخ الإنتهاء")}</label>
            <Form.Control
              className="form-control text-center "
              type="date"
              name="expiryDate"
              onChange={(e) => setExpiryDate(e.target.value)}
              value={expiryDate}
            />
          </div>
        </div>

        <div className="col-lg-4 pt-3 text-end">
          <button
            type="button"
            className="btn btn-sm me-1 btn-outline-theme mb-1"
            onClick={generateMultipleCoupons}
          >
            توليد الكوبونات
          </button>
        </div>
      </div>

      {list?.length ? (
        <div className="mt-1">
          <div className="col-lg-12 m-auto">
            <div className="table-responsive rounded-2 text-center border border-theme ">
              <table className="table table-sm  table-hover text-nowrap mb-0">
                <thead className="bg-theme bg-opacity-60">
                  <tr>
                    <th scope="col">{t("الكوبون")}</th>
                    <th scope="col">{t("تاريخ الإنتهاء")}</th>
                    <th scope="col" style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {list?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.NumberOfCoupons}</td>
                      <td>{item.expiryDate}</td>
                      <td className="ps-4" style={{ width: "5%" }}>
                        <OverlayTrigger
                          placement="left-end"
                          overlay={
                            <Tooltip id="button-tooltip-2">Delete</Tooltip>
                          }
                        >
                          <Link
                            to=""
                            className="text-danger"
                            onClick={() => handleDelete(item.NumberOfCoupons)}
                          >
                            <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                          </Link>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Coupons;
