// import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card, CardBody, CardExpandToggler } from '../../../components/card/card.jsx';

import RequestList from '../../Ame/Requests/RequestList.js';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { useEffect } from 'react';
// import useGetOrganizations from '../../../hooks/HR/use-get-organizations.js';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getEmployees } from '../../../store/HR/Person/employeeSlice.js';
import Loading from '../../../components/Shared/Loader/Loading';
import EmployeeByOrg from './EmployeeByOrg.jsx';
import EmployeeByNationality from './EmployeeByNationality.jsx';
import { Icon } from '@iconify/react';

const HrDashBoard = () => {
  const { t } = useTranslation();

  //#region Employees Counts
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      if (Employees?.length === 0) {
        await dispatch(getEmployees());
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const { Employees, loading, error } = useSelector((state) => state.Employees);
  var totalEmployees = Employees?.length;
  var employeesCurrentlyWorking = Employees?.filter((f) => f.endOfServiceDate === null)?.length;
  var employeesEndedService = Employees?.filter((f) => f.endOfServiceDate !== null)?.length;
  //#endregion

  return (
    <div>
      <Loading loading={loading} error={error}>
        <>
          <div className="row">
            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">إجمالي الموظفين</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={totalEmployees} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon icon="mdi:account-group" className="fa-4x text-theme" />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">الموظفين على رأس لعمل</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={employeesCurrentlyWorking} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon icon="mdi:account-tie" className="fa-4x text-theme" />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">الموظفين المنتهية خدمتهم</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">
                        <CountUp end={employeesEndedService} duration={2.5} />
                      </h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon icon="mdi:account-remove" className="fa-4x text-theme" />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="  col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">موظفين في إجازة</span>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-7">
                      <h1 className="mb-0 ps-4">0</h1>
                    </div>
                    <div className="col-5 text-center">
                      <div className="mt-n2 ps-4">
                        <Icon icon="mdi:calendar-check" className="fa-4x text-theme" />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <EmployeeByNationality />
            </div>

            <div className="col-xl-6">
              <EmployeeByOrg />
            </div>
          </div>
        </>
      </Loading>
    </div>
  );
};

export default HrDashBoard;
