import React from 'react';
import NtxPeoples from '../../../NTX/Peoples/NtxPeoples';
import { Card, CardBody } from '../../../../components/card/card';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NtxPeoples2 from '../../../NTX/Peoples/NtxPeoples2';

const Update = () => {
  const { t } = useTranslation();
  // var fieldList = [
  //   'typeCode',

  //   'personId',
  //   'billToLocationId',
  //   'shipToLocationId',
  //   'termsId', //المعرف الفريد لشروط الدفع.
  //   'setOfBooksId', //  الاستاذ العام
  //   'creditLimit', // حد الائتمان للمورد.
  //   // "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل  ??
  //   'invoiceCurrencyCode', //
  //   'paymentCurrencyCode', //
  //   'invoiceLimit', // موجود في المورد وليس في العميل ونوعه رقم   حد الفاتورة للمورد ??
  //   'paymentMethodLookupCode', //  ??
  //   'bankAccountName', // اسم الحساب البنكي للمورد.
  //   'bankAccountNum', //  : رقم الحساب البنكي.
  //   'bankNumber', //  رقم البنك.
  //   'bankAccountType', // نوع الحساب البنكي.
  //   'vatCode', //  الرقم الضريبي للمورد
  //   'tradingName', // الاسم التجاري للمورد
  //   // "workReference", // اسم الشخص
  //   'companyRegistrationNumber', // السجل التجاري للمورد
  //   'peopleCategory',
  //   'priceListId',
  //   'accountId',
  // ];
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('الموردين'),
      link: '/PurchasesSettings/SUPPLIERS',
    },
    {
      name: t('تعديل المورد'),
      link: null,
    },
  ];

  const location = useLocation();
  const { name } = location?.state;

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="تعديل بيانات المورد" subtitle={name} />

      {/* <Card> */}
        {/* <CardBody> */}
          {/* <NtxPeoples typeCode="SUPPLIERS" isUpdate={true} fieldList={fieldList} /> */}

          <NtxPeoples2 isUpdate={true} typeCode="SUPPLIERS" parentId={null} />
        {/* </CardBody> */}
      {/* </Card> */}
    </div>
  );
};

export default Update;
