import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetApInvoicesUnPaidList from '../../../../hooks/Finance/ApInvoices/useGetApInvoicesUnPaidList';
import DataTableComponent from '../../../../components/DataTable/DataTableComponent';

const Index = () => {
  const { t, i18n } = useTranslation();

  const { UnPaidInvoicesList, loading, error } = useGetApInvoicesUnPaidList();

  const columns = [
    { title: 'رقم الفاتورة', key: 'invoiceNum' },
    { title: 'المورد', key: 'peopleName' },
    { title: 'تاريخ الفاتورة', key: 'invoiceDate' },
    { title: 'نوع الفاتورة', key: 'invoiceType' },
    { title: 'الفرغ', key: 'organizationName' },
    { title: 'المبلغ', key: 'invoiceAmount' },
    { title: 'حالة الترحيل', key: 'postedFlag', className: 'text-center' }, // إضافة حالة الترحيل

    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData = UnPaidInvoicesList.map((item) => {
    let rowClass;

    // if (item.invoiceAmount > 1000) {
    //   rowClass = 'table-success';
    // } else if (item.invoiceAmount < 500) {
    //   rowClass = 'table-warning';
    // }
    // تحديد الشارة بناءً على قيمة postedFlag
    const postedFlagBadge = item.postedFlag ? (
      <span className="badge bg-success">
        <i className="fas fa-check me-1"></i> {/* أيقونة للترحيل */}
        مُرحلة
      </span>
    ) : (
      <span className="badge bg-danger">
        <i className="fas fa-times me-1"></i> {/* أيقونة لعدم الترحيل */}
        غير مُرحلة
      </span>
    );
    return {
      invoiceNum: item.invoiceNum,
      peopleName: i18n.language === 'en' ? item.peopleName : item.peopleName2,
      invoiceDate: item.invoiceDate,
      invoiceType: item.invoiceType,
      organizationName: i18n.language === 'en' ? item.organizationName : item.organizationName2,
      invoiceAmount: item.invoiceAmount,
      postedFlag: postedFlagBadge,
      action: (
        <Link to={`${item.id}/ViewInvoice`} className="btn btn-outline-theme mx-1">
          <i className="fas fa-info-circle me-2"></i>
          {t('سداد الفاتورة')}
        </Link>
      ),
      rowClass, // استخدم rowClass هنا
    };
  });

  const options = {
    paging: true,
    pageLength: 10,
    searching: true,
    ordering: true,
    lengthChange: true, //
    // info: false, //
    columnDefs: [{ orderable: false, targets: 7 }],
    order: [[2, 'desc']],
    responsive: true,
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'سداد الفواتيــر',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="سداد الفواتيــر" />
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <DataTableComponent
              data={tableData}
              columns={columns}
              options={options}
              theadClass=""
              tableClass="table table-hover text-nowrap"
            />
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Index;
