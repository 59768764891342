import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetGlJeSourceList } from '../../store/Finance/JeSource/sourceSlice';
import { useTranslation } from 'react-i18next';

const useGetGlJeSourceList = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (GlJeSourceList?.length === 0) {
      dispatch(GetGlJeSourceList());
    }
  }, []);
  const { GlJeSourceList, loading, error } = useAppSelector((state) => state.JeSource);

  var OptionsJeSource = GlJeSourceList?.map(({ id, name, name2 }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2}`,
  }));

  return { GlJeSourceList, OptionsJeSource, loading, error };
};

export default useGetGlJeSourceList;
