import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getFACategoriesList  } from '../../../store/Finance/FixedAssets/FACategories/FACategoriesSlice';
import { useTranslation } from 'react-i18next';

const useGetFACategoriesList = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (FACategoriesList?.length === 0) {
      dispatch(getFACategoriesList());
    }
  }, []);
  const { FACategoriesList, loading, error } = useAppSelector((state) => state.FACategories);

  var OptionsFACategories = FACategoriesList?.map(({ id, name, name2 }) => ({
    value: id,
    label: `${i18n.language === 'en' ? name : name2}`,
  }));

  return { FACategoriesList, OptionsFACategories, loading, error };
};

export default useGetFACategoriesList;
