import React, { useEffect, useState } from 'react';
import Header from './components/header/header.jsx';
import TopNav from './components/top-nav/top-nav.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
import ThemePanel from './components/theme-panel/theme-panel.jsx';
import { AppSettings } from './config/app-settings.js';
import { Navigate } from 'react-router-dom';
import ModalNotifications from './components/ModalNotifications/ModalNotifications.jsx';
import * as signalR from '@microsoft/signalr';
import { variables } from './API/variables.js';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetMyNotificationMessageList } from './store/NTX/Notifications/notificationSlice';
import { IsTokenValid } from './Helper/Helper';

function App() {
  const Token = useSelector((s) => s.userLogin?.AuthUser?.token);

  //#region App Code

  var defaultOptions = {
    appMode: 'dark',
    appTheme: localStorage.appTheme,
    // appTheme: 'theme-pink',
    appCover: '',
    appHeaderNone: false,
    // appSidebarNone: true,
    appSidebarNone: false,
    appSidebarCollapsed: false,
    appContentNone: false,
    appContentClass: '',
    appContentFullHeight: false,
    appBoxedLayout: false,
    appFooter: false,
    appTopNav: false,
    // appTopNav: true,
  };
  const [themeClass, setThemeClass] = useState(
    localStorage.appTheme ? localStorage.appTheme : 'theme-info'
  );
  const [appHeaderNone, setAppHeaderNone] = useState(defaultOptions.appHeaderNone);
  const [appSidebarNone, setAppSidebarNone] = useState(defaultOptions.appSidebarNone);
  const [appSidebarCollapsed, setAppSidebarCollapsed] = useState(
    defaultOptions.appSidebarCollapsed
  );
  const [appContentNone, setAppContentNone] = useState(defaultOptions.appContentNone);
  const [appContentClass, setAppContentClass] = useState(defaultOptions.appContentClass);
  const [appContentFullHeight, setAppContentFullHeight] = useState(
    defaultOptions.appContentFullHeight
  );
  const [appBoxedLayout, setAppBoxedLayout] = useState(defaultOptions.appBoxedLayout);
  const [appFooter, setAppFooter] = useState(defaultOptions.appFooter);
  const [appTopNav, setAppTopNav] = useState(defaultOptions.appTopNav);
  //________________________________________________________________
  const [sysCode, setsysCode] = useState(
    localStorage.CurrentSystem ? localStorage.CurrentSystem : 'NTX'
  );

  //________________________________________________________________
  const providerValue = {
    setAppHeaderNone,
    setAppSidebarNone,
    setAppSidebarCollapsed,
    setAppContentNone,
    setAppContentClass,
    setAppContentFullHeight,
    setAppBoxedLayout,
    setAppFooter,
    setAppTopNav,
    appTopNav,
    appSidebarNone,
    //
    setsysCode,
    sysCode,
  };

  var handleSetAppTheme = (value) => {
    if (value) {
      var newTheme = value;
      for (var x = 0; x < document.body.classList?.length; x++) {
        if (
          document.body.classList[x].indexOf('theme-') > -1 &&
          document.body.classList[x] !== newTheme
        ) {
          document.body.classList.remove(document.body.classList[x]);
        }
      }

      if (localStorage && value) {
        localStorage.appTheme = value;
      }

      document.body.classList.add(newTheme);
      document.dispatchEvent(new Event('theme-reload'));
    }
  };

  var handleSetAppMode = (value) => {
    if (value) {
      document.documentElement.setAttribute('data-bs-theme', value);
    }
  };

  var handleSetAppCover = (value) => {
    if (value) {
      var htmlElm = document.querySelector('html');
      for (var x = 0; x < document.documentElement.classList?.length; x++) {
        var targetClass = document.documentElement.classList[x];
        if (targetClass.search('bg-cover-') > -1) {
          htmlElm.classList.remove(targetClass);
        }
      }
      htmlElm.classList.add(value);

      if (localStorage && value) {
        localStorage.appCover = value;
      }
    }
  };

  useEffect(() => {
    if (defaultOptions.appMode) {
      handleSetAppMode(defaultOptions.appMode);
    }
    if (defaultOptions.appTheme) {
      handleSetAppTheme(defaultOptions.appTheme);
    }
    if (defaultOptions.appCover) {
      handleSetAppCover(defaultOptions.appCover);
    }

    if (localStorage) {
      if (typeof localStorage.appMode !== 'undefined') {
        handleSetAppMode(localStorage.appMode);
      }
      if (typeof localStorage.appTheme !== 'undefined') {
        handleSetAppTheme(localStorage.appTheme);
      }
      if (typeof localStorage.appCover !== 'undefined') {
        handleSetAppCover(localStorage.appCover);
      }
    }

    // eslint-disable-next-line
  }, []);

  //#endregion

  //#region Nitifactions
  // const [resultNotify, setResultNotify] = useState([]);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const establishConnection = async () => {
  //     try {
  //       let connection = new signalR.HubConnectionBuilder()
  //         .withUrl(`${variables.URL_NOTIFICATION}`, {
  //           accessTokenFactory: () => Token,
  //         })
  //         // .configureLogging(signalR.LogLevel.Information)
  //         .withAutomaticReconnect()
  //         .build();

  //       connection.on('receivedNotifications', async (resultNotifications) => {
  //         setResultNotify([]);
  //         await new Promise((resolve) => setTimeout(resolve, 100));
  //         setResultNotify(resultNotifications);
  //         dispatch(GetMyNotificationMessageList());
  //         setResultNotify(resultNotifications);
  //       });

  //       await connection.start();
  //       await connection.invoke('GetNotifications');

  //       console.log('SignalR connection established successfully.');
  //     } catch (error) {
  //       console.error('Failed to establish SignalR connection:', error);
  //     }
  //   };

  //   if (Token && IsTokenValid()) {
  //     establishConnection();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [Token]);

  //#endregion

  // //#region  change Systems
  //  useEffect(() => {
  //   setsysCode(localStorage.CurrentSystem);
  // }, [sysCode]);

  // useEffect(() => {
  //   const handleStorageChange = (event) => {
  //     if (event.key === 'CurrentSystem') {
  //       // تنفيذ أي عملية عند تغيير النظام، مثل تحديث الحالة أو إعادة تحميل الصفحة
  //       window.location.replace('/dashboard');
  //       // navigate('/dashboard', { replace: true });
  //     }
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);

  // //#endregion

  //_________________________________________
  if (!Token) {
    return <Navigate to="/login" />;
  }
  return (
    <AppSettings.Provider value={providerValue}>
      <div
        className={
          'app ' +
          (appBoxedLayout ? 'app-boxed-layout ' : '') +
          (appContentFullHeight ? 'app-content-full-height ' : '') +
          (appHeaderNone ? 'app-without-header ' : '') +
          (appSidebarNone ? 'app-without-sidebar ' : '') +
          (appSidebarCollapsed ? 'app-sidebar-collapsed ' : '') +
          (appFooter ? 'app-footer-fixed ' : '') +
          (appTopNav ? 'app-with-top-nav ' : '')
        }>
        {/* {resultNotify?.length > 0 && <ModalNotifications message={resultNotify} />} */}
        {/* {resultNotify && <ModalNotifications message={resultNotify} />} */}
        {!appHeaderNone && <Header themeClass={themeClass} />}
        {appTopNav && <TopNav key={sysCode}  />}
        {!appSidebarNone && <Sidebar key={sysCode} />}  {/* إعادة تصيير sysCode اذا تغير الـ */}
        {!appContentNone && <Content className={appContentClass} />}
        {appFooter && <Footer />}
        <ThemePanel updateThemeClass={(tc) => setThemeClass(tc)} />
        {/* <ThemePanel /> */}
      </div>
    </AppSettings.Provider>
  );
}

export default App;
