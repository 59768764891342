import React, { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { ControlCodeCombinationsModels } from '../../../store/Finance/CodeCombinations/CodeCombinationsModels';
import { useAppDispatch } from '../../../store/hooks';
import {
  ControlCodeCombinations,
  getGlCodeCombinationsList,
} from '../../../store/Finance/CodeCombinations/CodeCombinationsSlice';
import { useTranslation } from 'react-i18next';
import confirmAction from '../../../components/Shared/Alert/confirmAction';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';

interface Props {
  codeCombination: ControlCodeCombinationsModels;
}

const Control = ({ codeCombination }: Props) => {
  const [loading, setLoading] = useState(false); // حالة التحميل

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [enabledFlag, setEnabledFlag] = useState(codeCombination.enabledFlag || false);

  const radios = [
    { name: 'تفعيـــل', value: true },
    { name: 'إلغاء التفعيــل', value: false },
  ];

  const handleToggleChange = (newValue: boolean) => {
    setEnabledFlag(newValue);
  };

  const handlePostRequest = async () => {
    const confirmationMessage = enabledFlag
      ? 'هل أنت متأكد من تفعيل إعداد الحساب المرتبط؟ سيتم استئناف جميع العمليات المالية المرتبطة بهذا الحساب وفقًا للتركيبة المحددة.'
      : 'هل أنت متأكد من إيقاف إعداد الحساب المرتبط؟ سيتم تعطيل جميع العمليات المالية المرتبطة بهذا الحساب، ولن تكون التركيبة متاحة للاستخدام.';

    const isConfirm = await confirmAction(confirmationMessage);

    if (!isConfirm) {
      return;
    }
    const item = {
      codeCombinationId: codeCombination.id,
      enabledFlag: enabledFlag,
    };
    setLoading(true); // بدء التحميل

    dispatch(ControlCodeCombinations(item))
      .unwrap()
      .then((res: any) => {
        if (res?.succeeded === true) {
          CustomAlert({ action: 'Add', msg: 'تمت العملية بنجــاح !' });
          dispatch(getGlCodeCombinationsList(codeCombination.branch));
        } else {
          CustomAlert({ action: 'Error', msg: res?.message });
        }
      })
      .catch((error: any) => {
        CustomAlert({ action: 'Error' });
      })
      .finally(() => setLoading(false)); // إيقاف التحميل;
  };

  return (
    <>
      <div className="text-center bg-inverse bg-opacity-10 rounded-0 p-2 fw-bold fs-6">
        {i18n.language === 'en' ? codeCombination.description : codeCombination.description2}
      </div>
      <div className="row">
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              className=" mt-2 p-2 rounded-0"
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={'outline-theme'}
              value={''}
              checked={enabledFlag === radio.value}
              //   checked={codeCombination.enabledFlag === radio.value}
              onChange={() => handleToggleChange(radio.value)}>
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>

        <hr className="my-4" />

        <button
          disabled={loading}
          type="button"
          className="btn btn-lg btn-theme rounded-1"
          onClick={handlePostRequest}>
          {loading ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fa fa-save fa-fw me-1"></i>
          )}

          {t('Save')}
        </button>
      </div>
    </>
  );
};

export default Control;
