import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

const Map = ({ setLng, setLat, setCenter, lng, lat, center }) => {
 
 
 
  const [markerPosition, setMarkerPosition] = useState(center);
  const [search, setSearch] = useState('');
  const [mapType, setMapType] = useState('satellite');

  const handleMapClick = async ({ lat, lng }) => {
    setMarkerPosition({ lat, lng });
    if (!isNaN(lat) && !isNaN(lng)) {
      await setLat(lat);
      await setLng(lng);
    }
  };

  const handleMarkerDrag = async ({ lat, lng }) => {
    if (!isNaN(lat) && !isNaN(lng)) {
      setMarkerPosition({ lat, lng });
      await setLat(lat);
      await setLng(lng);
    }
  };

  const handleMarkerChange = async ({ x, y, lat, lng, event }) => {
    if (!isNaN(lat) && !isNaN(lng)) {
      setMarkerPosition({ lat, lng });
      await setLat(lat);
      await setLng(lng);
    }
  };

  const handleSearchMap = async () => {
    let newCenter = { ...center };
    if (search !== '') {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${search}&key=AIzaSyCG3Kt_jO8o7x67EsdV9RuTJP5IxSEwmeo`
      );
      const data = await response.json();
      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        newCenter = { lat, lng };
        setLat(lat);
        setLng(lng);
        setMarkerPosition({ lat, lng });
      } else {
        alert('Invalid address');
      }
    } else if (lat && lng) {
      newCenter = { lat, lng };
      setMarkerPosition({ lat, lng });
    } else {
      alert('Please enter an address or lat and lng values');
    }
    await setCenter(newCenter);
  };

  const options = {
    mapTypeId: mapType,
  };

  const toggleMapType = () => {
    if (mapType === 'roadmap') {
      setMapType('satellite');
    } else {
      setMapType('roadmap');
    }
  };

  return (
    <div>
      <div className="row mb-2">
        <div className="col-xl-6">
          <input
            className="form-control form-control-sm"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="col-xl-6 text-end">
          <div className="btn-group">
            <button
              onClick={handleSearchMap}
              type="button"
              className="btn btn-sm btn-outline-secondary">
              Search map
            </button>

            <button
              onClick={toggleMapType}
              type="button"
              className="btn btn-sm btn-outline-secondary">
              Toggle Map Type
            </button>
          </div>
        </div>
      </div>

      <div className="h-300px">
        <GoogleMapReact
          draggable={true}
          bootstrapURLKeys={{
            key: 'AIzaSyCG3Kt_jO8o7x67EsdV9RuTJP5IxSEwmeo',
          }}
          center={{ lat, lng }}
          defaultZoom={10}
          onClick={handleMapClick}
          onChildMouseDown={handleMarkerChange}
          onChildMouseUp={handleMarkerChange}
          onChildMouseMove={handleMarkerChange}
          options={options}>
          <Marker
            lat={markerPosition.lat}
            lng={markerPosition.lng}
            draggable
            onDrag={handleMarkerDrag}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default Map;

const Marker2 = ({ text }) => (
  <div style={{ position: 'absolute', color: 'red', fontSize: 16 }}>{text}</div>
);
