import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import Loading from '../../../../components/Shared/Loader/Loading';
// import { ntxAPI } from '../../../../API/axiosNeatex';
import useGetInventoryItems from '../../../../hooks/INV/MasterItems/useGetInventoryItems';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';
import { MaterialTransactionsLineList } from '../../../../store/INV/Transactions/SharedModels';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import { Card, CardBody } from '../../../../components/card/card';
import AddLotItem from '../../INVSettings/MasterItems/MasterItemsDetails/LotItems/AddLotItem';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { fetchDataByReferenceId } from '../../../../store/INV/Transactions/InvIssue/invSlice';

interface LinesProps {
  formik: FormikProps<any>;
}

const IssueReceiveLines: React.FC<LinesProps> = ({ formik }) => {
  const { values, setFieldValue, errors } = formik;
  const { i18n, t } = useTranslation();
  const { InventoryItemsOptions, loading, error } = useGetInventoryItems(values.inventoryId);

  const [showModal, setShowModal] = useState(false);
  const [lineData, setLineData] = useState<any>();

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...values.materialTransactionsLineList];

    if (field === 'transactionItemId') {
      // const unitPrice = InventoryItemsOptions?.find((f) => f.value === value)?.purchasePrice || 0;
      const transactionUom = InventoryItemsOptions?.find(
        (f) => f.itemId === value && f.isPrimaryUOM
      )?.uom;

      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
        transactionUom,
        unitPrice: Number(0),
        total: Number(updatedLines[index].transactionQuantityIn) * Number(0),
      };
    } else {
      updatedLines[index] = {
        ...updatedLines[index],
        [field]: value,
      };
    }

    formik.setFieldValue('materialTransactionsLineList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine: MaterialTransactionsLineList = {
      // lineNumber: Lines?.length + 1,
      id: null, //
      transactionItemId: null,
      transactionUom: '',
      unitPrice: 0,
      transactionQuantityIn: 0,
      transactionQuantityOut: 0,
      lotId: null,
      //________________________
      inventoryId: '',
      discount: 0,
      codeCombinationId: '',
      currencyCode: '',
      currencyConversionRate: 0,
      referenceIdMaster: '',
      referenceIdLine: '',
      transactionTypeId: '',
      equivalent: 0,
      expensesAmount: 0,
      batchId: '',
    };

    const currentLines = formik.values.materialTransactionsLineList || [];

    // تأكد من أن `currentLines` هو مصفوفة قبل إضافة السطر الجديد
    formik.setFieldValue('materialTransactionsLineList', [...currentLines, newLine]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.materialTransactionsLineList?.filter(
      (_: any, i: number) => i !== index
    );
    formik?.setFieldValue('materialTransactionsLineList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.materialTransactionsLineList?.reduce(
      (sum: any, line: { total: any }) => sum + line.total,
      0
    );
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  // #region  جلب الأصناف بناء على الـ referenceId
  const dispatch = useAppDispatch();
  const { itemsList, loading: loading1 } = useAppSelector((state) => state.Inv);
  useEffect(() => {
    if (['MII', 'MIR'].includes(values.code)) {
      return;
    }

    if (values.referenceId) {
      dispatch(fetchDataByReferenceId({ referenceId: values.referenceId, code: values.code }))
        .unwrap()
        .then(({ data }) => {
          switch (values.code) {
            case 'INV':
              setFieldValue('customerId', data?.data?.peopleId ?? null);
              setFieldValue('inventoryId', data?.data?.inventoryId);
              break;
            case 'SOI':
              setFieldValue('customerId', data?.data?.peopleId ?? null);
              setFieldValue('supplierId', null);
              break;
            case 'IOI':
              setFieldValue('subInventoryId', data?.data?.subInventoryId);
              setFieldValue('inventoryId', data?.data?.inventoryId);
              break;
            case 'SPR':
              setFieldValue('supplierId', data?.data?.peopleId ?? null);
              setFieldValue('customerId', null);
              break;
            case 'POR':
              setFieldValue('supplierId', data?.data?.peopleId ?? null);
              setFieldValue('customerId', null);
              break;
            case 'BIL':
              setFieldValue('supplierId', data?.data?.peopleId);
              setFieldValue('customerId', null);
              break;
            case 'CRT':
              setFieldValue('supplierId', null);
              setFieldValue('customerId', data?.data?.peopleId);
              break;
            case 'IOR':
              setFieldValue('branchId', data?.data?.branchId);
              break;
            default:
              break;
          }
        });
    }
  }, [values.referenceId]);

  useEffect(() => {
    formik?.setFieldValue('materialTransactionsLineList', itemsList);
  }, [itemsList]);
  //#endregion




  
  const IsEnabledByCode = () => {
    return [
      'SPR',
      'WOI',
      'IOI',
      'CCA',
      'SOI',
      'INV',
      'MOI',
      'MOR',
      'IOR',
      'BIL',
      'POR',
      'PIA',
      'CRT',
      '',
    ].includes(values.code);
  };

  return (
    <>
      <Loading loading={loading || loading1} error={error} Type="Dots">
        <div className="mb-3">
          <div className="row">
            <div className="col-3">
              <div className="mb-1 rounded-3 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-around">
                  <div className="fw-bold">{t('Total')} :</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-6"></div>
            <div className="col-lg-3 text-end ">
              <div className="text-end mb-2">
                {!IsEnabledByCode() && (
                  <button type="button" className="btn btn-theme" onClick={handleAddLine}>
                    {t('إضافة صنف')}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="">
            <div
              className={`table-responsive rounded-2 text-center ${
                formik.errors.materialTransactionsLineList
                  ? 'border-bottom border-2 border-danger'
                  : ''
              } `}>
              <table className="table table-borderless  table-hover mb-0 ">
                <thead className="bg-theme bg-opacity-30">
                  <tr>
                    <th className="align-middle" style={{ width: '3%' }}>
                      #
                    </th>
                    <th className="align-middle" style={{ width: '48%' }}>
                      {t('اسم الصنف')}
                    </th>
                    <th className="align-middle" style={{ width: '15%' }}>
                      {t('الوحدة')}
                    </th>
                    <th className="align-middle" style={{ width: '15%' }}>
                      {t('Lot Number')}
                    </th>
                    <th className="align-middle" style={{ width: '8%' }}>
                      {t('الكمية')}
                    </th>
                    <th className="align-middle" style={{ width: '8%' }}>
                      {t('السعر')}
                    </th>

                    <th className="align-middle text-end" style={{ width: '3%' }}></th>
                  </tr>
                </thead>

                <tbody className="fw-bold">
                  {values.materialTransactionsLineList
                    ?.slice()
                    ?.reverse()
                    ?.map((line: any, index: any) => {
                      const Index = values.materialTransactionsLineList?.length - 1 - index;

                      return (
                        <tr key={index}>
                          <td className="align-middle bg-theme bg-opacity-30 small">
                            {values.materialTransactionsLineList?.length - index}
                          </td>
                          <td className="align-middle small">
                            <Select
                              isDisabled={IsEnabledByCode()}
                              classNamePrefix="react-select"
                              className="w-100"
                              isSearchable
                              isClearable
                              options={InventoryItemsOptions?.filter(
                                (item, index, self) =>
                                  index === self?.findIndex((i) => i.itemId === item.itemId)
                              )}
                              onChange={(option) =>
                                handleLineChange(
                                  Index,
                                  'transactionItemId',
                                  option === null ? null : option.value
                                )
                              }
                              value={
                                line.transactionItemId === null
                                  ? null
                                  : InventoryItemsOptions?.find(
                                      (option) => option.value === line.transactionItemId
                                    )
                              }
                              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                              }}
                              formatOptionLabel={(option) => (
                                <div className="d-flex justify-content-between   ">
                                  <div className="">{option.label}</div>
                                  <div className="text-theme fw-bold">{option.barcode}</div>
                                </div>
                              )}
                            />
                          </td>

                          <td className="align-middle small">
                            <select
                              disabled={IsEnabledByCode()}
                              className="form-select text-center"
                              value={line.transactionUom || ''}
                              onChange={(e) =>
                                handleLineChange(Index, 'transactionUom', e.target.value)
                              }>
                              {/* <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option> */}
                              {InventoryItemsOptions?.filter(
                                (f) => f.itemId === line.transactionItemId
                              )?.map((item, idx) => (
                                <option key={++idx} value={item.uom}>
                                  {item.uomName}
                                </option>
                              ))}
                            </select>
                          </td>

                          <td className="align-middle small">
                            <PopoverCustom
                              childern={
                                <select
                                  // disabled={IsEnabledByCode()}
                                  className="form-select text-center"
                                  value={line.lotId || ''}
                                  onChange={(e) => {
                                    if (e.target.value === 'add-new') {
                                      setLineData(line);
                                      setShowModal(true);
                                    } else {
                                      handleLineChange(Index, 'lotId', e.target.value);
                                    }
                                  }}>
                                  <option value="">
                                    {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                  </option>
                                  {InventoryItemsOptions?.find(
                                    (f) =>
                                      f.itemId === line.transactionItemId &&
                                      f.uom === line.transactionUom
                                  )?.qtyByLot?.map((m) => (
                                    <option key={m.lotId} value={m.lotId || ''}>
                                      {m.lotNumber}
                                    </option>
                                  ))}
                                  <option value="add-new">
                                    {i18n.language === 'ar' ? 'LotNumber جديد' : 'New LotNumber'}
                                  </option>
                                </select>
                              }
                              Header="تفاصيل الصنف"
                              Body={
                                <>
                                  <div className="d-flex justify-content-between align-content-center">
                                    <span className=" ">الكميـة</span>

                                    <span className="text-theme fw-bold fs-6 mx-2">
                                      {InventoryItemsOptions?.find(
                                        (f) =>
                                          f.itemId === line.transactionItemId &&
                                          f.uom === line.transactionUom
                                      )?.qtyByLot?.find((f) => f.lotId === line.lotId)?.qty ?? 0}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between align-content-center">
                                    <span className=" ">تاريخ الإنتهاء</span>
                                    <span className="text-theme fw-bold fs-6 mx-2">
                                      {
                                        InventoryItemsOptions?.find(
                                          (f) =>
                                            f.itemId === line.transactionItemId &&
                                            f.uom === line.transactionUom
                                        )
                                          ?.qtyByLot?.find((f) => f.lotId === line.lotId)
                                          ?.expirationDate?.toString()
                                          ?.split('T')[0]
                                      }
                                    </span>
                                  </div>
                                </>
                              }
                            />
                          </td>

                          <td className="align-middle small">
                            <PopoverCustom
                              childern={
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control text-center px-0"
                                  value={line.transactionQuantityIn}
                                  onChange={(e) =>
                                    handleLineChange(
                                      Index,
                                      'transactionQuantityIn',
                                      Number(e.target.value)
                                    )
                                  }
                                  onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value?.replace(/[^0-9]/g, '');

                                    // var QtyAvailable =
                                    //   InventoryItemsOptions?.find(
                                    //     (f) =>
                                    //       f.itemId === line.transactionItemId &&
                                    //       f.uom === line.transactionUom
                                    //   )?.qtyByLot?.find((f) => f.lotId === line.lotId)?.qty ?? 0;

                                    if (Number(input.value) > line.QtyAvilable) {
                                      input.value = line.QtyAvilable?.toString();
                                    }
                                  }}
                                />
                              }
                              Header="الكمية المضافة سابقاً"
                              Body={
                                <>
                                  <div className="d-flex justify-content-between">
                                    <span className="mt-1">الكمية المضافة</span>
                                    <span className="text-danger fw-bold fs-6 mx-2 mt-1">
                                      {line.dispensedQuantity}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <span className="mt-1">الكمية المتبقية</span>
                                    <span className="text-success fw-bold fs-6 mx-2 mt-1">
                                      {Number(line.RealQty) - Number(line.dispensedQuantity)}
                                    </span>
                                  </div>
                                </>
                              }
                            />
                          </td>
                          <td className="align-middle small">
                            <input
                              disabled={IsEnabledByCode()}
                              type="text"
                              autoComplete="off"
                              className="form-control text-center px-0"
                              value={line.unitPrice}
                              onChange={(e) =>
                                handleLineChange(Index, 'unitPrice', Number(e.target.value))
                              }
                              onInput={(e) => {
                                const input = e.target as HTMLInputElement;
                                input.value = input.value.replace(/[^0-9]/g, '');
                              }}
                            />
                          </td>

                          <td className="align-middle small">
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={() => handleDeleteLine(Index)}>
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Loading>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        size="md"
        bodyClassName="p-0"
        showHeader={false}>
        <Card>
          <CardBody>
            {lineData && <AddLotItem lineData={lineData} onClose={() => setShowModal(false)} />}
          </CardBody>
        </Card>
      </NeatixModal>
    </>
  );
};

export default IssueReceiveLines;
