import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

import { useTranslation } from 'react-i18next';

import { Card, CardBody } from '../../../../../components/card/card';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Link, useParams } from 'react-router-dom';
import Update from './Update';
import Additions from './Additions/Additions';

const AssetsDetails = () => {
  const { t, i18n } = useTranslation();
  const { AssetsById: data } = useAppSelector((a) => a.Assets);

  // //#region Formik
  // const formik = useFormik({
  //   initialValues: {
  //     name: '',
  //     name2: '',
  //     assetNumber: '',
  //     tagNumber: '',
  //     serialNumber: '',
  //     categoryId: '',
  //     organizationId: '',
  //     parentAssetId: '',
  //     datePlacedInService: '',
  //     ownedLeased: '',
  //     leasedId: '',
  //     newUsed: '',
  //     originalCost: '',
  //     adjustedCost: '',
  //     salvageValue: '',
  //     lifeInMonths: '',
  //     depreciationMethod: '',
  //     percentage: '',
  //     description: '',
  //     assetCostAccount: '',
  //     deprnExpenseAccount: '',
  //     deprnReserveAccount: '',
  //     glLedgerId: '',
  //   },

  //   // enableReinitialize: true,
  //   validationSchema: AddAssetsSchema(t),

  //   onSubmit: (values) => {
  //     dispatch(
  //       addAssets({
  //         name: values.name,
  //         name2: values.name2,
  //         assetNumber: values.assetNumber,
  //         tagNumber: values.tagNumber,
  //         serialNumber: values.serialNumber,
  //         categoryId: values.categoryId,
  //         organizationId: values.organizationId,
  //         parentAssetId: null,
  //         // parentAssetId: values.parentAssetId  ,
  //         datePlacedInService: values.datePlacedInService,
  //         ownedLeased: values.ownedLeased,
  //         leasedId: null,
  //         newUsed: values.newUsed,
  //         originalCost: values.originalCost,
  //         adjustedCost: values.adjustedCost,
  //         salvageValue: values.salvageValue,
  //         lifeInMonths: values.lifeInMonths,
  //         depreciationMethod: values.depreciationMethod,
  //         percentage: values.percentage,
  //         description: values.description,
  //         assetCostAccount: values.assetCostAccount,
  //         deprnExpenseAccount: values.deprnExpenseAccount,
  //         deprnReserveAccount: values.deprnReserveAccount,
  //         glLedgerId: values.glLedgerId,
  //       })
  //     )
  //       .unwrap()
  //       .then((res) => {
  //         debugger;
  //         if (res?.succeeded === true) {
  //           CustomAlert({ action: 'Add' });
  //           dispatch(getAssetsList());
  //           formik.resetForm();
  //         } else {
  //           formik.setSubmitting(false);
  //           CustomAlert({ action: 'Error', msg: res?.message });
  //         }
  //       })
  //       .catch((error: any) => {
  //         formik.setSubmitting(false);
  //         CustomAlert({ action: 'Error' });
  //       });
  //   },
  // });
  // // const { values, handleChange, errors, touched, setFieldValue } = formik;
  // //#endregion

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Fa Assets',
      link: null,
    },
    {
      name: 'تفاصيـل الأصل',
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName="تفــاصيـــل الأصل"
          subtitle={i18n.language === 'en' ? data.name : data.name2}
        />
      </div>
      <Card>
        <CardBody className="pt-1">
          <ul className="nav nav-tabs nav-tabs-v2 ps-4 pe-4 ">
            <li className="nav-item me-3">
              <Link to="#dataAssets" className="nav-link active" data-bs-toggle="tab">
                <i className="fa fa-info-circle me-2 text-theme"></i>
                بيانات الأصل
              </Link>
            </li>
            <li className="nav-item me-3">
              <Link to="#AdditionsAssets" className="nav-link" data-bs-toggle="tab">
                <i className="fa fa-plus-circle me-2 text-theme"></i>
                الإضافات
              </Link>
            </li>
            <li className="nav-item me-3">
              <Link to="#profilev2WithCard" className="nav-link" data-bs-toggle="tab">
                <i className="fa fa-search-dollar me-2 text-theme"></i>
                financial inquiry - الإستعلامات الماليـة
              </Link>
            </li>
            <li className="nav-item me-3">
              <Link to="#profilev2WithCard" className="nav-link" data-bs-toggle="tab">
                <i className="fa fa-sync-alt me-2 text-theme"></i>
                إعادة تقييم الأصل
              </Link>
            </li>
            <li className="nav-item me-3">
              <Link to="#profilev2WithCard" className="nav-link" data-bs-toggle="tab">
                <i className="fa fa-trash-alt me-2 text-theme"></i>
                استبعاد الأصل
              </Link>
            </li>
          </ul>
          <div className="tab-content py-4 px-1">
            <div className="tab-pane fade show active" id="dataAssets">
              <Update />
            </div>
            <div className="tab-pane fade" id="AdditionsAssets">
              <Additions/>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AssetsDetails;

// {/* تابع لـ  */}
// <div className="col-lg-4">
//   <div className="form-group mb-3">
//     <label className="form-label"> {t('تابع لـ ')}</label>
//     <Select
//       classNamePrefix="react-select"
//       // isLoading={loading3}
//       isSearchable={true}
//       isClearable
//       options={OptionsAssets}
//       value={OptionsAssets?.find((f: any) => f.value === formik.values.parentAssetId)}
//       onChange={(option: any) =>
//         setFieldValue('parentAssetId', option === null ? null : option.value)
//       }
//       placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
//     />
//   </div>
// </div>
