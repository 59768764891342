import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { TreeSelect } from 'antd';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetPaymentTermsList';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import ArReceiptSchedules from './ArReceiptSchedules';
import ArTransactionsLine from './ArTransactionsLine';
import { AddArTransactionSchema } from '../../../ValidationForm/validationSchema';
import useConversionRateByOrganization from '../../../../hooks/Finance/DailyRates/useConversionRateByOrganization';
import { useState } from 'react';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import useGetUnApplyPrepayments from '../../../../hooks/AR/ArReceipts/useGetUnApplyPrepayments';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import { addArTransactions } from '../../../../store/Finance/AR/ArTransactions/ArTransactionsSlice';
import useGetPeopleLocationsList from '../../../../hooks/NTX/Peoples/useGetPeopleLocationsList';
import useGetPersons from '../../../../hooks/HR/use-get-persons';

const Add = () => {
  const [showModal, setShowModal] = useState(true);
  const [peopleId, setPeopleId] = useState<any>(null);
  const [organizationId, setOrganizationId] = useState<any>(null);
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dataEmployees } = useGetPersons();

  const { Currency, loading: loading4 } = useLookupCurrency();
  const { BranchOrgOptions, loading } = useGetBranchOrgWithPermission();
  const { treeCustomerData, loading: loading2, OptionCustomers } = useGetCustomersList();
  const { PaymentTermsList } = useGetPaymentTermsList();
  const { PaymentMethodList } = useGetPaymentMethod();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      transactionNum: '',
      peopleId: '',
      transactionType: '',
      referenceType: '',
      referenceId: '',
      transactionDate: new Date().toISOString().split('T')[0], //'',
      organizationId: '',
      paymentTermsId: '',
      paymentMethodId: '',
      currencyCode: 'SR',
      currencyConversionRate: 1,
      includeVatFlag: true,

      salesrepId: '',
      billToCustomerId: '',
      shipToCustomerId: '',
      // transactionAmount: 0,
      // dueDate: '2024-10-28T14:04:56.765Z',
      // vatAmount: 0,
      // paymentFlag: true,

      arTransactionLinesList: [],
      arReceiptSchedulesList: [],
    },

    enableReinitialize: true,
    validationSchema: AddArTransactionSchema(t),
    onSubmit: (values) => {
      const { arTransactionLinesList, arReceiptSchedulesList } = values;

      if (arTransactionLinesList?.length === 0) {
        SwalAlert({ text: 'لا يمكن متابعة العملية بدون إضافة بنود للفاتورة، يرجى إضافة البنود.' });
        formik.setSubmitting(false);
        return;
      }

      const totalTransactionLines = arTransactionLinesList?.reduce(
        (sum: number, item: any) => sum + item.total,
        0
      );
      const totalReceiptSchedules = arReceiptSchedulesList?.reduce(
        (sum: number, payment: any) => sum + Number(payment.amount),
        0
      );

      if (arReceiptSchedulesList?.length > 0 && totalTransactionLines !== totalReceiptSchedules) {
        SwalAlert({ text: 'لا يتطابق مجموع الفاتورة مع مجموع الجدولة. يرجى المراجعة.' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addArTransactions({
          transactionNum: values.transactionNum,
          peopleId: values.peopleId,
          transactionType: values.transactionType,
          salesrepId: values.salesrepId,
          transactionDate: values.transactionDate,
          billToCustomerId: values.billToCustomerId,
          shipToCustomerId: values.shipToCustomerId,
          transactionAmount: totalTransactionLines,
          currencyCode: values.currencyCode,
          currencyConversionRate: conversionRate,
          paymentTermsId: values.paymentTermsId,
          paymentMethodId: values.paymentMethodId,
          dueDate: null,
          vatAmount: 0,
          paymentFlag: true,
          referenceId: values.referenceId || null,
          organizationId: values.organizationId,
          arTransactionLinesList: values.arTransactionLinesList?.map((line: any, idx) => ({
            lineNumber: ++idx,
            lineType: line.lineType,
            invItemId: line.invItemId,
            uom: '5cc5ff20-b711-4e46-a9ad-0f6de4b81b1a',
            // uom: line.uom || null,
            description: line.description,
            price: line.price || 0,
            qty: line.qty || 0,
            discountRate: line.discountRate || 0,
            discountAmount: line.discountAmount || 0,
            vatRate: line.vatRate || 0,
            total: line.total,
            vatAmount: line.vatAmount || 0,
            accountId: line.accountId,
            costCenterId: line.costCenterId,
            // currencyCode: line.currencyCode,
            // currencyConversionRate: line.currencyConversionRate || 1,
            unitCost: 0,
            salHeaderId: null,
            salLineId: null,
          })),

          arReceiptSchedulesList:
            values.arReceiptSchedulesList?.map((item: any) => ({
              amount: item.amount,
              dueDate: item.dueDate,
              receiptId: item.paymentId,
            })) || null,
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          // console.log(res);

          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

 

  const { hasPrepayments, totalPrepaymentAmount, UnApplyPrepaymentsList } =
    useGetUnApplyPrepayments({
      PeopleId: values.peopleId,
    });

  const { billToLocation, shipToLocation } = useGetPeopleLocationsList({
    peopleId: values.peopleId,
  });

  const conversionRate = useConversionRateByOrganization({
    organizationId: values.organizationId,
    currencyCode: values.currencyCode,
    date: values.transactionDate,
  });

  const handleSubmitModal = async () => {
    if (peopleId && organizationId && values.transactionType) {
      setShowModal(false);
      await formik.setFieldValue('peopleId', peopleId);
      await formik.setFieldValue('organizationId', organizationId);
    } else {
      ToastSwal({ title: 'يرجى اختيار', timer: 1000 });
    }
  };

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'معاملات الإستحقاق',
      link: '/Finance/Ar/ArTransactions',
    },
    {
      name: 'إضافة معاملة جديـدة',
      link: null,
    },
  ];
  //#endregion

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName="إضافة فاتورة جديـدة"
          subtitle={OptionCustomers?.find((f) => f.id === values.peopleId)?.label}
        />
      </div>

      <Card>
        <Loading loading={loading || loading2 || loading || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control "
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />

                    <div className="text-danger small">
                      {touched.transactionDate && errors.transactionDate}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('نوع الفاتورة')} </label>
                    <Form.Select
                      disabled
                      className="form-select"
                      name="invoiceType"
                      onChange={formikhandleChange}
                      value={values.transactionType || ''}
                      // isInvalid={!!(touched.transactionType && errors.transactionType)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      <option value="Standard">
                        {i18n.language === 'en' ? 'Standard' : 'قياسي'}
                      </option>
                      <option value="DebitMemo">
                        {i18n.language === 'en' ? 'Debit Memo' : 'إشعار مدين'}
                      </option>
                      {/* <option value="Recurring">
                        {i18n.language === 'en' ? 'Recurring' : 'دوري'}
                      </option> */}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.transactionType && errors.transactionType}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      isDisabled
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      options={BranchOrgOptions}
                      value={BranchOrgOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      // onChange={(option: any) => handleChangeOrganizationId(option)}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.organizationId && errors.organizationId}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميل')}</label>
                    <TreeSelect
                      disabled
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeCustomerData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger small">{touched.peopleId && errors.peopleId}</div>
                  </div>
                </div>

                {/* 'موقع الشـحن' */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('موقع الشـحن')} </label>
                    <Form.Select
                      className="form-select"
                      name="shipToCustomerId"
                      onChange={formikhandleChange}
                      value={values.shipToCustomerId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {shipToLocation &&
                        shipToLocation?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {formik.touched.shipToCustomerId && formik.errors.shipToCustomerId}
                    </div>
                  </div>
                </div>

                {/* موقع الفوترة' */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('موقع الفوترة')} </label>
                    <Form.Select
                      className="form-select"
                      name="billToCustomerId"
                      onChange={formikhandleChange}
                      value={values.billToCustomerId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {billToLocation &&
                        billToLocation?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {formik.touched.billToCustomerId && formik.errors.billToCustomerId}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group flex-nowrap mb-3">
                    <label className="form-label">{t('رقم فاتورة العميل')}</label>

                    <div className="input-group flex-nowrap">
                      <Form.Control
                        autoComplete="off"
                        className="form-control  text-center"
                        type="text"
                        name="transactionNum"
                        onChange={formikhandleChange}
                        value={values.transactionNum || ''}
                        // isInvalid={!!(touched.transactionNum && errors.transactionNum)}
                      />
                    </div>
                    <div className="text-danger small">
                      {touched.transactionNum && errors.transactionNum}
                    </div>
                  </div>
                </div>

                {/* 'مندوب المبيعات' */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مندوب المبيعات')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="salesrepId"
                      options={dataEmployees}
                      value={dataEmployees?.find((f: any) => f.value === formik.values.salesrepId)}
                      onChange={(option: any) => {
                        formik.setFieldValue('salesrepId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.salesrepId && errors.salesrepId}
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">شروط الدفع</label>
                    <Form.Select
                      className="form-select"
                      name="paymentTermsId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentTermsId || ''}
                      // isInvalid={!!(formik.errors.paymentTermsId && formik.touched.paymentTermsId)}
                    >
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentTermsId && errors.paymentTermsId}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId || ''}
                      // isInvalid={
                      //   !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      // }
                    >
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentMethodId && errors.paymentMethodId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.currencyCode && errors.currencyCode}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('معدل العملة')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="text"
                      autoComplete="off"
                      readOnly
                      // name="currencyConversionRate"
                      // onChange={formikhandleChange}
                      // value={values.currencyConversionRate || ''}
                      value={conversionRate}
                    />
                    <div className="text-danger small">
                      {touched.currencyConversionRate && errors.currencyConversionRate}
                    </div>
                  </div>
                </div>
              </div>

              <div className="alert alert-primary" hidden={!hasPrepayments}>
                <i className="fas fa-exclamation-circle me-2"></i>
                <strong>تنبيه:</strong> هذا العميـل لديه دفعات مقدمة بإجمالي:
                <span className="ms-2 text-success  fw-bold fs-5">{totalPrepaymentAmount}</span>
              </div>
              <CardFooter className="mt-2 mb-2">
                <ul className="nav nav-tabs nav-tabs-v2 p-0">
                  <li className="nav-item me-3">
                    <Link to="#apInvoiceLines" className="nav-link active" data-bs-toggle="tab">
                      <i className="fas fa-file-invoice me-2 text-theme"></i>
                      بنود الفاتورة
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link to="#apPaymentSchedules" className="nav-link" data-bs-toggle="tab">
                      <i className="fas fa-calendar-alt me-2 text-theme"></i>
                      جدولة الفاتورة
                    </Link>
                  </li>
                </ul>

                <div className="tab-content py-4 px-0">
                  <div className="tab-pane fade show active" id="apInvoiceLines">
                    <ArTransactionsLine formik={formik} />
                  </div>
                  <div className="tab-pane fade" id="apPaymentSchedules">
                    <ArReceiptSchedules
                      formik={formik}
                      UnApplyPrepaymentsList={UnApplyPrepaymentsList}
                    />
                  </div>
                </div>
                {formik.errors.arTransactionLinesList?.length && (
                  <div className="text-danger"> * بنود الفاتورة مطلوب </div>
                )}
                {formik.errors.arReceiptSchedulesList?.length && (
                  <div className="text-danger"> * جدولة الفاتورة مطلوب </div>
                )}
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/Finance/Ar/ArTransactions', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        size="md"
        bodyClassName="p-0"
        showHeader={false}
        keyboard={false}
        backdrop={true}>
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع')}</label>
                  <Select
                    classNamePrefix="react-select"
                    // isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchOrgOptions}
                    value={BranchOrgOptions?.find((f: any) => f.value === organizationId)}
                    onChange={(option: any) => {
                      setOrganizationId(option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('العميـل')}</label>
                  <TreeSelect
                    showSearch
                    className="w-100"
                    treeLine
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    allowClear
                    treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                    // loadData={} // يتم تحميل البيانات عند الطلب
                    // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                    value={peopleId}
                    onChange={(option) => {
                      setPeopleId(option === null ? null : option);
                    }}
                    treeData={treeCustomerData}
                    // treeNodeFilterProp="title"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
                  />
                  <div className="text-danger small">{touched.peopleId && errors.peopleId}</div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('نوع الفاتورة')} </label>
                  <Form.Select
                    className="form-select"
                    name="transactionType"
                    onChange={formikhandleChange}
                    value={values.transactionType || ''}
                    // isInvalid={!!(touched.transactionType && errors.transactionType)}
                  >
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    <option value="Standard">
                      {i18n.language === 'en' ? 'Standard' : 'قياسي'}
                    </option>
                    <option value="DebitMemo">
                      {i18n.language === 'en' ? 'Debit Memo' : 'إشعار مدين'}
                    </option>
                    {/* <option value="Recurring">
                        {i18n.language === 'en' ? 'Recurring' : 'دوري'}
                      </option> */}
                  </Form.Select>
                  <div className="text-danger small">
                    {touched.transactionType && errors.transactionType}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-lg btn-theme  w-100 rounded-0"
                  onClick={handleSubmitModal}>
                  <i className="fas fa-lg fa-fw me-2 fa-check-circle"></i>
                  {t('إختيار')}
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-lg  btn-default  w-100 rounded-0"
                  onClick={() => navigate('/Finance/Ar/ArTransactions', { replace: true })}>
                  <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </NeatixModal>
    </>
  );
};

export default Add;
