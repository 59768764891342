import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
import menus from './../../config/app-menu';
import useGetSysCode from '../../hooks/useGetSysCode';

function NavItem({ menu, ...props }) {
  //: LinkProps
  const { t } = useTranslation();
  let path = menu.path ? menu.path : '';
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname });
  let location = useLocation();

  if (menu?.is_header) {
    return <div className="menu-header">{t(menu?.title)}</div>;
  }

  if (menu.is_divider) {
    return <div className="menu-divider"></div>;
  }

  let match2 = matchPath({ path: path, end: false }, location.pathname);

  let icon = menu.icon && (
    <div className="menu-icon">
      <i className={menu.icon}></i>
    </div>
  );
  let img = menu?.img && (
    <div className="menu-icon-img">
      <img src={menu?.img} alt="" />
    </div>
  );
  let caret = menu.children && !menu.badge && (
    <div className="menu-caret">
      <b className="caret"></b>
    </div>
  );
  let label = menu.label && <span className="menu-label ms-5px">{menu.label}</span>;
  let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
  let highlight = menu.highlight && <i className="fa fa-star text-theme"></i>;
  // let title = menu.title && <div className="menu-text">{menu.title} {label} {highlight}</div>;
  let title = menu.title && (
    <div className="menu-text">
      {t(menu.title)} {highlight}{' '}
    </div>
  );

  return (
    <div
      className={
        'menu-item' + (match || match2 ? ' active' : '') + (menu.children ? ' has-sub' : '')
      }>
      <NavLink className="menu-link" to={menu?.path} {...props}>
        {img} {icon} {title}
        {caret} {badge}
      </NavLink>

      {menu?.children && (
        <div className="menu-submenu">
          {menu?.children?.map((submenu, i) =>
            submenu.isActive === true ? <NavItem key={i} menu={submenu} /> : null
          )}
        </div>
      )}
    </div>
  );
}

  function SidebarNav() {
  const { code } = useGetSysCode();

     
  // العثور على القائمة الخاصة بالنظام الحالي بناءً على الكود
  const currentSystemMenu = menus.find((item) => item.System === code);

  // تصفية القائمة لإظهار العناصر النشطة أو الصفحة الرئيسية
  const filteredMenu = currentSystemMenu?.menu?.filter(
    (item) =>
      item.title === 'HomePage' ||
      item.isActive ||
      item.children?.some((child) => child.isActive)
  );

    return (
      <div className="menu">
        {filteredMenu?.map((menu, i) => (
          <NavItem key={i} menu={menu} />
        ))}
      </div>
    );
  }

export default SidebarNav;


// const filteredMenu = currentSystemMenu?.menu?.filter(
//   (item) =>
//     item.title === 'HomePage' ||
//     item.isActive ||
//     item.children?.some((child) => child.isActive)
// );




    // const { code } = useGetSysCode();

    // const SystemMenu = menus?.find((item) => item.System === code);

    // const filteredMenu = useMemo(() => {
    //   return SystemMenu?.menu?.filter(
    //     (item) =>
    //       item.title === 'HomePage' || item.children?.some((child) => child.isActive) || item.isActive
    //   );
    // }, [SystemMenu]);