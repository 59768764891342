import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { ControlCodeCombinationsModels } from './CodeCombinationsModels';

export const ControlCodeCombinations = createAsyncThunk(
  'CodeCombinations/ControlCodeCombinations',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Finance/ControlCodeCombinations', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getGlCodeCombinationsList = createAsyncThunk(
  'CodeCombinations/getGlCodeCombinationsList',
  async (branchId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Finance/GetGlCodeCombinationsList', {
        params: { BranchId: branchId },
      });
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  GlCodeCombinationsList: ControlCodeCombinationsModels[];
  loading: boolean;
  error: any | null;
} = {
  GlCodeCombinationsList: [],
  loading: false,
  error: null,
};

const CodeCombinationsSlice = createSlice({
  name: 'CodeCombinations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getGlCodeCombinationsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getGlCodeCombinationsList.fulfilled,
        (state, action: PayloadAction<ControlCodeCombinationsModels[]>) => {
          state.loading = false;
          state.GlCodeCombinationsList = action.payload;
        }
      )
      .addCase(getGlCodeCombinationsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default CodeCombinationsSlice.reducer;
