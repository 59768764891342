import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const fleetMenu = [
  {
    System: 'FLEET',
    isActive: false,
    menu: [
      { is_header: true, title: 'FleetManagement', isActive: true },
      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'HomePage',
      },

      {
        path: '/FLEETSettings',
        icon: 'bi bi-gear',
        title: 'Settings',
        children: [
          {
            path: 'FLEETSettings/Manufacturers',
            title: 'Manufacturers',
            isActive: true,
          },

          {
            path: 'FLEETSettings/Model',
            title: 'Model',
            isActive: true,
          },
          {
            path: 'FLEETSettings/FleetElementScan',
            title: 'InspectionItems',
            isActive: true,
          },
          {
            path: 'FLEETSettings/WorkShop',
            title: 'Workshops',
            isActive: true,
          },
          {
            path: 'FLEETSettings/Locations',
            title: 'Locations',
            isActive: true,
          },
          {
            path: 'FLEETSettings/Suppliers',
            title: 'Suppliers',
            isActive: true,
          },
          {
            path: 'FLEETSettings/Drivers',
            title: 'Drivers',
            isActive: true,
          },
          {
            path: 'FLEETSettings/Owners',
            title: 'Owners',
            isActive: true,
          },
        ],
      },

      {
        path: 'FLEET/Equipments',
        icon: 'fas fa-fw fa-car',
        title: 'Assets_and_Equipment',
        isActive: true,
      },

      {
        path: 'FLEET/Maintenance/',
        icon: 'bi bi-tools',
        title: 'Maintenance',
        children: [
          {
            path: 'FLEET/Maintenance/Requests',
            title: 'NewRequest',
            isActive: true,
          },
          {
            path: 'FLEET/Maintenance/MaintenanceOrder',
            title: 'MaintenanceOrders',
            isActive: true,
          },
          {
            path: 'SelfService/MyRequests',
            title: 'MyRequests',
            isActive: true,
          },
        ],
      },

      {
        path: 'FLEET/EquipmentInspection',
        icon: 'bi bi-gear-wide-connected',
        title: 'EquipmentInspection',
        isActive: true,
      },
      {
        path: 'FLEET/Reports',
        icon: 'bi bi-clipboard2-data-fill',
        title: 'Reports',
        isActive: true,
      },
      {
        path: 'FLEET/FleetDashboard',
        icon: 'bi bi-cpu',
        title: 'لوحة البيانات',
        // isActive:false,
        isActive: true,
      },
    ],
  },
];

export default fleetMenu;
