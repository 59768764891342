import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { AddDiscountVouchers } from '../../../../store/Sales/DiscountVouchers/DiscountVouchersSlice';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupDiscountType from '../../../../hooks/Lookups/use-Lookup-DiscountType';
import Select from 'react-select';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
import { Link, useParams } from 'react-router-dom';
import Rewards from '../Rewards/Rewards';
import { Switch } from 'antd';
import { Card, CardBody } from '../../../../components/card/card';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import useGetDiscountVoucherTypesList from '../../../../hooks/Sales/Vouchers/useGetDiscountVoucherTypesList';
import useGetPosList from '../../../../hooks/POS/useGetPosList';
import useGetLocations from '../../../../hooks/HRSetting/use-get-locations';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import useGetCategoriesList from '../../../../hooks/INV/useGetCategoriesList';
import useLookupGetCustomerType from '../../../../hooks/Lookups/use-Lookup-CustomerType';

const Add = () => {
  const { voucherTypeId } = useParams();
  const { DiscountVoucherTypesList: VoucherTypesList, loading: loading2 } =
    useGetDiscountVoucherTypesList();
  var VoucherName = VoucherTypesList?.find((f) => f.id === voucherTypeId)?.name2;

  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const { DISCOUNT_TYPE } = useLookupDiscountType();
  const { ItemsOptions, loading } = useGetItemsList();
  const { optionsPosWithAll, error, loading: LoadingPos } = useGetPosList();
  const { LocationsOptionsWithAll, loading: loading3, error: error3 } = useGetLocations();
  const { OptionCustomersWithAll } = useGetCustomersList();
  const { CategoriesOptionsWithAll } = useGetCategoriesList();
  const { OptionsCustomerTypeWithAll } = useLookupGetCustomerType();

  //#region
  const formik = useFormik({
    initialValues: {
      voucherTypeId: '',
      validationDateFrom: '',
      validationDateTo: '',
      name: '',
      name2: '',
      conditionalRulesType: 'qty',
      conditionalRulesQty: '',
      conditionalRulesAmount: '',
      pricelIstId: '',
      allItems: false,
      allCategories: false,
      allPeoples: false,
      allPeopleCategory: false,
      allPos: false,
      allLocations: false,
      discountVouchersCategoriesList: [],
      discountVouchersCouponsList: [],
      discountVouchersItemsList: [],
      discountVouchersLocationsList: [],
      discountVouchersPeopleCategoryList: [],
      discountVouchersPeoplesList: [],
      discountVouchersPosList: [],
      // discountVouchersPosList: [optionsPosWithAll?.find((option) => option.value === 'All')],

      discountVouchersRewardsList: {
        discountVouchersId: '',
        rewardsType: '',
        discountPercentage: 0,
        discountVouchersRewardsItemsList: [],
      },

      //__ not for submit
      AllItemsAllCategories: '',
      AllPeoplesAllPeopleCategory: '',
    },

    // validationSchema: formOrganization(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        AddDiscountVouchers({
          voucherTypeId: values.voucherTypeId,
          validationDateFrom: values.validationDateFrom,
          validationDateTo: values.validationDateTo,
          name: values.name,
          name2: values.name2,

          conditionalRulesType: values.conditionalRulesType,

          conditionalRulesQty:
            values.conditionalRulesType === 'qty' ? values.conditionalRulesQty : 0,

          conditionalRulesAmount:
            values.conditionalRulesType === 'amount' ? values.conditionalRulesAmount : 0,

          pricelIstId: values.conditionalRulesType === 'fixed' ? values.pricelIstId : null,

          allItems: values.AllItemsAllCategories === 'allItems', // return boolean
          allCategories: values.AllItemsAllCategories === 'allCategories', // return boolean
          allPeoples: values.AllPeoplesAllPeopleCategory === 'allPeoples', // return boolean
          allPeopleCategory: values.AllPeoplesAllPeopleCategory === 'allPeopleCategory', // return boolean
          allPos: false,
          allLocations: false,

          discountVouchersCategoriesList: values.discountVouchersCategoriesList?.map(
            (item: any) => ({
              discountVouchersId: null,
              categoryId: item.categoryId,
            })
          ),

          //   discountVouchersCouponsList:
          //     values.voucherTypeId === '2ad37011-c6c3-4987-a92a-e99830edf2f1'
          //       ? values.discountVouchersCouponsList?.map((c) => ({
          //           discountVouchersId: null,
          //           expiryDate: c.expiryDate,
          //           couponNumber: c.NumberOfCoupons,
          //           isValid: true,
          //         }))
          //       : [],

          discountVouchersItemsList: values.discountVouchersItemsList?.map((item: any) => ({
            discountVouchersId: null,
            itemId: item.itemId,
          })),

          discountVouchersPeopleCategoryList: values.discountVouchersPeopleCategoryList?.map(
            (item: any) => ({
              discountVouchersId: null,
              peopleCategory: item.peopleCategory,
            })
          ),

          discountVouchersPeoplesList: values.discountVouchersPeoplesList?.map((item: any) => ({
            discountVouchersId: null,
            peopleId: item?.peopleId,
          })),

          //   discountVouchersRewardsList: [
          //     {
          //       discountVouchersId: null,
          //       rewardsType: values.discountVouchersRewardsList.rewardsType,
          //       discountPercentage:
          //         values.discountVouchersRewardsList.rewardsType === 'DISCOUNT'
          //           ? values.discountVouchersRewardsList.discountPercentage
          //           : 0,
          //       discountVouchersRewardsItemsList:
          //         values.discountVouchersRewardsList.rewardsType === 'FREE_ITEM'
          //           ? values.discountVouchersRewardsList.discountVouchersRewardsItemsList?.map(
          //               (r) => ({
          //                 discountVouchersRewardsId: null,
          //                 itemId: r.itemId,
          //                 itemQty: r.itemQty,
          //               })
          //             )
          //           : [],
          //     },
          //   ],

          discountVouchersPosList: [],
          discountVouchersLocationsList: [],
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res.succeeded === true) {
            formik.resetForm();
            document.getElementById('AddPriceList')?.click();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  //#endregion

  //#region Handle changes
  const fieldKeyMap: { [key: string]: string } = {
    discountVouchersItemsList: 'itemId',
    discountVouchersPosList: 'posId',
    discountVouchersLocationsList: 'locationId',
    discountVouchersCategoriesList: 'categoryId',
    discountVouchersPeopleCategoryList: 'peopleCategory',
    discountVouchersPeoplesList: 'peopleId',
  };
  const handleChange = (selectedOptions: any, fieldName: string) => {
    const key = fieldKeyMap[fieldName]; // Get the respective key for the field

    const selectedItems = selectedOptions
      ? selectedOptions?.map((option: any) => ({
          [key]: option.value, // Use the dynamic key
        }))
      : [];

    formik.setFieldValue(fieldName, selectedItems);
  };
  //#endregion

  const HandleChangeRadio = (e: any) => {
    formik.handleChange(e);
    console.log(e.target.value);

    if (e.target.value === 'allItems') {
      formik.setFieldValue('discountVouchersCategoriesList', []);
    } else if (e.target.value === 'allCategories') {
      formik.setFieldValue('discountVouchersItemsList', []);
    } else if (e.target.value === 'allPeoples') {
      formik.setFieldValue('discountVouchersPeopleCategoryList', []);
    } else if (e.target.value === 'allPeopleCategory') {
      formik.setFieldValue('discountVouchersPeoplesList', []);
    }
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'القسـائم',
      link: '/SalesSettings/Vouchers2',
    },
    {
      name: 'اضافة وتعديل القسائم',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          // PageName={t('القسـائم')}
          subtitle={VoucherName}
        />
      </div>
      <hr className="mb-3 mt-0" />
      <Form onSubmit={formik.handleSubmit}>
        <h6 className=" p-0 mt-2">
          <i className="fas fa-database me-2 text-theme fs-3"></i> البيانات الأساسيــة
        </h6>

        <Card>
          <CardBody>
            <Row className="row d-flex align-items-center">
              <Col lg={4}>
                <div className="form-group mb-3">
                  <Form.Label>{t('LocalName')}</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </Col>

              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('GlobalName')}</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('From')}</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="date"
                    name="validationDateFrom"
                    onChange={formik.handleChange}
                    value={formik.values.validationDateFrom}
                    isInvalid={
                      !!(formik.touched.validationDateFrom && formik.errors.validationDateFrom)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.validationDateFrom}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('To')}</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="date"
                    name="validationDateTo"
                    onChange={formik.handleChange}
                    value={formik.values.validationDateTo}
                    isInvalid={
                      !!(formik.touched.validationDateTo && formik.errors.validationDateTo)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.validationDateTo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <h6 className=" p-0 mt-4">
          <i className="fas fa-file-alt me-2 text-theme fs-3"></i> الشروط
        </h6>
        <Card>
          <CardBody>
            <Row className="row d-flex align-items-center">
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="form-label">{t('conditionalRulesType')}</Form.Label>
                  <Form.Select
                    disabled
                    className="form-select"
                    name="conditionalRulesType"
                    onChange={formik.handleChange}
                    value={formik.values.conditionalRulesType}
                    isInvalid={
                      !!(formik.touched.conditionalRulesType && formik.errors.conditionalRulesType)
                    }>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {DISCOUNT_TYPE &&
                      DISCOUNT_TYPE?.map((item: any, idx: any) =>
                        item.list?.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === 'en' ? list.meaning : list.meaning2}
                          </option>
                        ))
                      )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={1}>
                <div className="form-group mb-3">
                  <label className="form-label">{t('الكمية')}</label>
                  <Form.Control
                    disabled={formik.values.conditionalRulesType !== 'qty'}
                    className={`form-control text-center ${
                      formik.values.conditionalRulesType !== 'qty' && 'bg-inverse bg-opacity-10'
                    } `}
                    type="text" 
autoComplete='off'
                    name="conditionalRulesQty"
                    onChange={formik.handleChange}
                    value={formik.values.conditionalRulesQty}
                    isInvalid={
                      !!(formik.touched.conditionalRulesQty && formik.errors.conditionalRulesQty)
                    }
                    onInput={(e) => {
                      // إلغاء أي إدخال غير رقمي يدويًا
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/[^0-9]/g, '');
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.conditionalRulesQty}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col lg={8}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('الأصناف')}</Form.Label>
                  <Select
                    isMulti
                    classNamePrefix="react-select"
                    // className="bg-inverse bg-opacity-10"
                    // className={`${
                    //   formik.values.AllItemsAllCategories !== 'allItems' && 'bg-inverse bg-opacity-10'
                    // } `}
                    isLoading={loading}
                    // isDisabled={formik.values.AllItemsAllCategories !== 'allItems'}
                    isSearchable={true}
                    isClearable
                    options={ItemsOptions}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    // onChange={handleChange}
                    onChange={(selectedOptions) =>
                      handleChange(selectedOptions, 'discountVouchersItemsList')
                    }
                    value={formik.values.discountVouchersItemsList?.map((item: any) =>
                      ItemsOptions?.find((option) => option.value === item.itemId)
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* <Row className="row d-flex align-items-center">
              <Col lg={4} className=" ">
                <div className="p-2 bg-theme bg-opacity-10 ">
                  <div className="form-group mb-0">
                    <div className="shipping-container">
                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">{t('allItems')}</div>

                        <div className="col-6 d-flex align-items-center">
                          <div className="form-check  form-switch ms-auto">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="defaultRadio1"
                              name="AllItemsAllCategories"
                              // onChange={formik.handleChange}
                              onChange={HandleChangeRadio}
                              checked={formik.values.AllItemsAllCategories === 'allItems'}
                              value="allItems"
                            />

                            <label className="form-check-label" htmlFor="defaultRadio1">
                              &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr className="my-1" />

                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">{t('allCategories')}</div>

                        <div className="col-6 d-flex align-items-center">
                          <div className="form-check form-switch ms-auto">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="defaultRadio2"
                              name="AllItemsAllCategories"
                              // onChange={formik.handleChange}
                              onChange={HandleChangeRadio}
                              checked={formik.values.AllItemsAllCategories === 'allCategories'}
                              value="allCategories"
                            />
                            <label className="form-check-label" htmlFor="defaultRadio2">
                              &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={4} className=" ">
                <div className="p-2 bg-theme bg-opacity-10 ">
                  <div className="form-group mb-0">
                    <div className="shipping-container">
                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">{t('allPeoples')}</div>

                        <div className="col-6 d-flex align-items-center">
                          <div className="form-check form-switch ms-auto">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="defaultRadio3"
                              name="AllPeoplesAllPeopleCategory"
                              // onChange={formik.handleChange}
                              onChange={HandleChangeRadio}
                              checked={formik.values.AllPeoplesAllPeopleCategory === 'allPeoples'}
                              value="allPeoples"
                            />
                            <label className="form-check-label" htmlFor="defaultRadio3">
                              &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr className="my-1" />

                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">{t('allPeopleCategory')}</div>

                        <div className="col-6 d-flex align-items-center">
                          <div className="form-check form-switch ms-auto">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="defaultRadio4"
                              name="AllPeoplesAllPeopleCategory"
                              // onChange={formik.handleChange}
                              onChange={HandleChangeRadio}
                              value="allPeopleCategory"
                              checked={
                                formik.values.AllPeoplesAllPeopleCategory === 'allPeopleCategory'
                              }
                            />
                            <label className="form-check-label" htmlFor="defaultRadio4">
                              &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={4} className=" ">
                <div className="p-2 bg-theme bg-opacity-10 ">
                  <div className="form-group mb-0">
                    <div className="shipping-container">
                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">{t('allPos')}</div>

                        <div className="col-6 d-flex align-items-center">
                          <div className="form-check form-switch ms-auto">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="defaultRadio5"
                              name="allPos"
                              onChange={formik.handleChange}
                              // value={formik.values.allPos}
                            />
                            <label className="form-check-label" htmlFor="defaultRadio5">
                              &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr className="my-1" />

                      <div className="row align-items-center">
                        <div className="col-6 pt-1 pb-1">{t('allLocations')}</div>

                        <div className="col-6 d-flex align-items-center">
                          <div className="form-check form-switch ms-auto">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="defaultRadio6"
                              name="allLocations"
                              onChange={formik.handleChange}
                              // value={formik.values.allLocations}
                            />
                            <label className="form-check-label" htmlFor="defaultRadio6">
                              &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}
            <Row className="row d-flex align-items-center">
              <Col lg={12} className=" mt-3">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('نقاط البيـع')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <Select
                          classNamePrefix="react-select"
                          className={`w-100`}
                          isLoading={loading}
                          isMulti
                          isSearchable={true}
                          isClearable
                          options={[...optionsPosWithAll]}
                          // onChange={handleChange}
                          onChange={(selectedOptions) =>
                            handleChange(selectedOptions, 'discountVouchersPosList')
                          }
                          value={formik.values.discountVouchersPosList?.map((item: any) =>
                            optionsPosWithAll?.find((option) => option.value === item.posId)
                          )}
                          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                          defaultValue={[optionsPosWithAll[0]]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={12} className=" mt-3">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('المواقع')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <Select
                          classNamePrefix="react-select"
                          className={`w-100`}
                          isLoading={loading}
                          isMulti
                          isSearchable={true}
                          isClearable
                          options={[...LocationsOptionsWithAll]}
                          // onChange={handleChange2}
                          onChange={(selectedOptions) =>
                            handleChange(selectedOptions, 'discountVouchersLocationsList')
                          }
                          value={formik.values.discountVouchersLocationsList?.map((item: any) =>
                            LocationsOptionsWithAll?.find(
                              (option: any) => option.value === item.locationId
                            )
                          )}
                          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={12} className=" mt-3">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('العملاء')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <Select
                          classNamePrefix="react-select"
                          className={`w-100`}
                          isLoading={loading}
                          isMulti
                          isSearchable={true}
                          isClearable
                          options={[...OptionCustomersWithAll]}
                          // onChange={handleChange}
                          onChange={(selectedOptions) =>
                            handleChange(selectedOptions, 'discountVouchersPeoplesList')
                          }
                          value={formik.values.discountVouchersPeoplesList?.map((item: any) =>
                            OptionCustomersWithAll?.find((option) => option.value === item.peopleId)
                          )}
                          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={12} className=" mt-3">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('الفئـات')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <Select
                          classNamePrefix="react-select"
                          className={`w-100`}
                          isLoading={loading}
                          isMulti
                          isSearchable={true}
                          isClearable
                          options={[...CategoriesOptionsWithAll]}
                          // onChange={handleChange}
                          onChange={(selectedOptions) =>
                            handleChange(selectedOptions, 'discountVouchersCategoriesList')
                          }
                          value={formik.values.discountVouchersCategoriesList?.map((item: any) =>
                            CategoriesOptionsWithAll?.find((option) => option.value === item.categoryId)
                          )}
                          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={12} className=" mt-3">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('انواع العملاء')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <Select
                          classNamePrefix="react-select"
                          className={`w-100`}
                          isLoading={loading}
                          isMulti
                          isSearchable={true}
                          isClearable
                          options={[...OptionsCustomerTypeWithAll]}
                          // onChange={handleChange}
                          onChange={(selectedOptions) =>
                            handleChange(selectedOptions, 'discountVouchersPeopleCategoryList')
                          }
                          value={formik.values.discountVouchersPeopleCategoryList?.map((item: any) =>
                            OptionsCustomerTypeWithAll?.find((option) => option.value === item.peopleCategory)
                          )}
                          placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <h6 className=" p-0 mt-2">
          <i className="fas fa-gift text-theme fs-3 me-2"></i> المكافئات
        </h6>
        <hr />

        <Rewards formik={formik} />
      </Form>
    </div>
  );
};

export default Add;
