import { useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Get_OWNED_LEASED } from '../../store/Lookup/lookupSlice';
// use-Get_DEPRECIATION_METHOD
const useGetOwnedLeased = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (OWNED_LEASED.length === 0) {
      dispatch(Get_OWNED_LEASED());
    }
  }, []);

  const { OWNED_LEASED, loading, error } = useSelector((state) => state.Lookup);

  return { OWNED_LEASED, loading, error };
};

export default useGetOwnedLeased;
