import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getBranchWithPermission } from '../../../store/HR/Organizations/organizationSlice';

const useGetBranchOrgWithPermission = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranchWithPermission()); // المخازن
  }, []);

  const { BranchOrgList: data, loading, error } = useSelector((state) => state.Organizations);

  // const BranchOrgOptions =
  //   data &&
  //   data?.map((item) => ({
  //     value: item.id,
  //     label: i18n.language === 'en' ? item.name : item.name2,
  //   }));

  const BranchOrgOptions = useMemo(
    () =>
      data &&
      data?.map((branch) => ({
        label: i18n.language === 'en' ? branch.name : branch.name2,
        value: branch.id,
      })),
    [data]
  );

  return { data, BranchOrgOptions, loading, error };
};

export default useGetBranchOrgWithPermission;
