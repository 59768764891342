import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import MyFingerPrintList from './MyFingerPrintList';
import MyInformations from './MyInformations';
import ProfileSidebar from './ProfileSidebar';
import MyDelegate from './MyDelegate';
import MyRequests from './MyRequests';

const UserProfile = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Card>
        <CardBody className="p-0">
          <div className="profile">
            <div className="profile-container">
              <div className="profile-sidebar">
                <ProfileSidebar />
              </div>

              <div className="profile-content">
                <ul className="profile-tab nav nav-tabs nav-tabs-v2 text-nowrap">
                  <li className="nav-item">
                    <a href="#my-Info" className="nav-link active" data-bs-toggle="tab">
                      <div className="nav-field">{t('MyInformation')}</div>
                      <div className="nav-value">
                        <i className="fas fa-lg fa-fw fa-info-circle text-theme"></i>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#my-Request" className="nav-link" data-bs-toggle="tab">
                      <div className="nav-field">{t('MyRequests')}</div>
                      <div className="nav-value text-theme">
                        <i className="fa-solid fa-code-compare fa-lg"></i>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#EmpTime" className="nav-link" data-bs-toggle="tab">
                      <div className="nav-field">{t('بصماتي')}</div>
                      <div className="nav-value">
                        <i className="bi bi-fingerprint fa-lg text-theme"></i>
                      </div>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="#my-Mandates" className="nav-link" data-bs-toggle="tab">
                      <div className="nav-field">{t('تفويضاتي')}</div>
                      <div className="nav-value text-theme">
                        {/* <i className="fa-solid fa-hands-helping  fa-lg"></i> */}
                        <i className="fa-solid fa-user-shield  fa-lg"></i>
                      </div>
                    </a>
                  </li>
                </ul>

                <div className="profile-content-container p-0 ">
                  {/* <PerfectScrollbar className="h-100"> */}
                  <div className="tab-content p-0">
                    <div className="tab-pane fade show active p-3" id="my-Info">
                      <MyInformations />
                    </div>

                    <div className="tab-pane fade show p-3" id="my-Request">
                      {/* <MyRequestsList /> */}
                      <MyRequests />
                    </div>

                    <div className="tab-pane fade show" id="EmpTime">
                      <MyFingerPrintList />
                    </div>
                    <div className="tab-pane fade show" id="my-Mandates">
                      <MyDelegate />
                    </div>
                  </div>
                  {/* </PerfectScrollbar> */}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      {/* <ModalComponent id="modalViewRequests" title={t('ViewRequestDetails')}>
        {transactionHistoryId && <OnlyViewRequests TransactionHistoryId={transactionHistoryId} />}
      </ModalComponent> */}
    </div>
  );
};

export default UserProfile;
