import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/card/card';
import PIECHART from '../ChartRaqeeb/PIECHART';
import POLARCHART from '../ChartRaqeeb/POLARCHART';
import DOUGHNUTCHART from '../ChartRaqeeb/DOUGHNUTCHART';
import DOUGHNUTCHART2 from '../ChartRaqeeb/DOUGHNUTCHART2';

import CardHeader from 'react-bootstrap/esm/CardHeader';
import { useDispatch } from 'react-redux';
import {
  GetRaqResultByOrganization,
  GetResultList,
  GetResultSummaryList,
  clearState,
} from '../../../../store/HR/Raqeeb/raqeebSlice';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import AnalysisTable from '../AttendanceAnalysis/AnalysisTable';
import TableSummaryList from '../AttendanceAnalysis/TableSummaryList';
// import ModalComponent from '../../../../components/Modal/ModalComponent';
// import AnalysisTable from './AnalysisTable';
// import TableSummaryList from './TableSummaryList';

const AttendanceAnalysis = () => {
  const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' });
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearState());
  }, []);

  const {
    ChartRaqResultList: data,
    ResultSummaryList,
    ResultList,
    loading,
  } = useSelector((state) => state.Raqeeb);

  const handleGetResult = async () => {
    await dispatch(clearState());
    await new Promise((resolve) => setTimeout(resolve, 500));

    await dispatch(
      GetResultSummaryList({
        DateFrom: dateRange.fromDate,
        DateTo: dateRange.toDate,
      })
    );

    await dispatch(GetResultList({ DateFrom: dateRange.fromDate, DateTo: dateRange.toDate }));

    await dispatch(
      GetRaqResultByOrganization({
        DateFrom: dateRange.fromDate,
        DateTo: dateRange.toDate,
      })
    );
  };

  //____________
  const titleModal = () => {
    return (
      <div className="">
        {t('From')}
        <span className="text-theme">{dateRange.fromDate}</span>
        {t('To')}
        <span className="text-theme">{dateRange.toDate}</span>
      </div>
    );
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/dashboard',
    },
    {
      name: t('AttendanceAnalysis'),
      link: null,
    },
  ];

  return (
    <>
      <div className="row">
        {/* <Breadcrumb BreadcrumbList={BcrumbList} PageName="AttendanceAnalysis" /> */}
        {/* <Loader loading={loading} /> */}
        <hr />

        <div className="row mx-0">
          <div className="col-xl-3">
            <div className="input-group mb-3 ">
              <span className="input-group-text">{t('From')}</span>

              <input
                type="date"
                className="form-control text-center"
                value={dateRange.fromDate}
                onChange={(e) => setDateRange({ ...dateRange, fromDate: e.target.value })}
              />
            </div>
          </div>
          <div className="col-xl-3">
            <div className="input-group mb-3">
              <span className="input-group-text">{t('To')}</span>
              <input
                type="date"
                className="form-control text-center"
                value={dateRange.toDate}
                onChange={(e) => setDateRange({ ...dateRange, toDate: e.target.value })}
              />
            </div>
          </div>
          <div className="col-xl-2">
            <button type="button" className="btn btn-theme w-75" onClick={handleGetResult}>
              {loading ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw fa-filter me-2 "></i>
              )}
              تحليــل
            </button>
          </div>

          <div className="col-xl-4  text-end">
            <div className="btn-group">
              <button
                disabled={ResultList?.length === 0}
                className="btn btn-theme"
                data-bs-toggle="modal"
                data-bs-target="#modalِAnalysisTable">
                {t('ViewDetails')}
              </button>
              <button
                disabled={ResultSummaryList?.length === 0}
                className="btn btn-theme"
                data-bs-toggle="modal"
                data-bs-target="#modalِResultSummaryList">
                {t('AttendanceSummary')}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-3" />

        {['ABSENT', 'LATE', 'EARLY', 'ExtraHours']?.map((type, index) => (
          <div key={index} className="col-xl-6 mx-auto mb-4">
            <Card>
              <CardHeader className="bg-theme bg-opacity-20">
                <div className="text-center fs-5 fw-bold ">{t(type)}</div>
              </CardHeader>

              {data?.length > 0 && (
                <>
                  {type === 'ABSENT' && (
                    <PIECHART
                      AbsenceData={data
                        ?.slice()
                        ?.filter((f) => f.nonFingerprinted !== 0)
                        ?.map((m) => m.nonFingerprinted)}
                      labels={data
                        ?.slice()
                        ?.filter((f) => f.nonFingerprinted !== 0)
                        ?.map((m) => m.organizationName)}
                    />
                  )}

                  {type === 'LATE' && (
                    <DOUGHNUTCHART
                      LateData={data
                        ?.slice()
                        ?.filter((f) => f.delay !== 0)
                        ?.map((m) => m.delay)}
                      labels={data
                        ?.slice()
                        ?.filter((f) => f.delay !== 0)
                        ?.map((m) => m.organizationName)}
                    />
                  )}

                  {type === 'ExtraHours' && (
                    <POLARCHART
                      overTimeData={data
                        ?.slice()
                        ?.filter((f) => f.overTime !== 0)
                        ?.map((m) => m.overTime)}
                      labels={data
                        ?.slice()
                        ?.filter((f) => f.overTime !== 0)
                        ?.map((m) => m.organizationName)}
                    />
                  )}

                  {type === 'EARLY' && (
                    <DOUGHNUTCHART2
                      EARLYData={data
                        ?.slice()
                        ?.filter((f) => f.early !== 0)
                        ?.map((m) => m.early)}
                      labels={data
                        ?.slice()
                        ?.filter((f) => f.early !== 0)
                        ?.map((m) => m.organizationName)}
                    />
                  )}
                </>
              )}
            </Card>
          </div>
        ))}

        <div />
      </div>

      <ModalComponent id="modalِAnalysisTable" title={titleModal()}>
        <AnalysisTable
          ResultList={ResultList}
          fromDate={dateRange.fromDate}
          toDate={dateRange.toDate}
        />
      </ModalComponent>

      <ModalComponent id="modalِResultSummaryList" title={titleModal()}>
        <TableSummaryList
          SummaryList={ResultSummaryList}
          fromDate={dateRange.fromDate}
          toDate={dateRange.toDate}
        />
      </ModalComponent>
    </>
  );
};

export default AttendanceAnalysis;
