// import { lazy, Suspense } from 'react';
// import LazyLoading from './LazyLoading/LazyLoading.jsx';

// const Employees = lazy(() => import('../../pages/HR/Employees/HR-PERSON/Index/Index.js'));
// const Assignment = lazy(() => import('../../pages/HR/Employees/Assignment/Assignment.js'));
// const Absence = lazy(() => import('../../pages/HR/Employees/Absence/absence'));
// // const HRRequests = lazy(() => import('../../pages/HR/HRRequest/HRRequests'));
// const Delegation = lazy(() => import('../../pages/HR/Delegation/Delegation'));
// const ExtraRecordFormPerson = lazy(() =>
//   import('../../pages/HR/Employees/HR-PERSON/ExtraRecord/ExtraRecordFormPerson.js')
// );
// const HRReports = lazy(() => import('../../pages/HR/HRReports/HRReports'));
// const ReportsBuilder = lazy(() =>
//   import('../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder')
// );
// const ViewAbsence = lazy(() => import('../../pages/HR/Employees/Absence/ViewAbsence/Index'));
// const UpdatePerson = lazy(() => import('../../pages/HR/Employees/HR-PERSON/Edit/UpdatePerson'));
// const DashboardHR = lazy(() => import('../../pages/HR/DashboardHR/DashboardHR'));
// const BuilderDashboard = lazy(() =>
//   import('../../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard')
// );
// const EndOfService = lazy(() => import('../../pages/HR/Employees/EndOfService/EndOfService'));

// //________________________________________________________________
// const Job = lazy(() => import('../../pages/HR/settings/Jobs/Job.js'));
// const JobGrades = lazy(() => import('../../pages/HR/settings/JobGrades/JobGrades.js'));
// const Location = lazy(() => import('../../pages/HR/settings/Location/Location.js'));
// const Position = lazy(() => import('../../pages/HR/settings/position/position.js'));
// const GroupEmployees = lazy(() =>
//   import('../../pages/HR/settings/GroupEmployees/GroupEmployees.js')
// );
// const EditGroupEmployees = lazy(() =>
//   import('../../pages/HR/settings/GroupEmployees/EditGroupEmployees.js')
// );
// const EditJob = lazy(() => import('../../pages/HR/settings/Jobs/EditJob.js'));
// const EditJobGrade = lazy(() => import('../../pages/HR/settings/JobGrades/EditJobGrade.js'));
// const EditLocation = lazy(() =>
//   import('../../pages/HR/settings/Location/EditLocation/EditLocation.js')
// );
// const EditPosition = lazy(() => import('../../pages/HR/settings/position/EditPosition.js'));

// const hrRoutes = [
//   {
//     path: 'HRsettings/*',
//     children: [
//       {
//         path: 'Job',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <Job />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'Job/:id/Edit',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <EditJob />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'JobGrade',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <JobGrades />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'JobGrade/:id/Edit',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <EditJobGrade />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'Location',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <Location />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'Location/:id/Edit',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <EditLocation />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'Position',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <Position />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'Position/:id/Edit',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <EditPosition />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'StaffGroup',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <GroupEmployees />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'StaffGroup/:id/Edit',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <EditGroupEmployees />
//           </Suspense>
//         ),
//       },
//     ],
//   },

//   {
//     path: 'HR/Employees/*',
//     children: [
//       {
//         path: '*',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <Employees />
//           </Suspense>
//         ),
//       },
//       {
//         path: ':id/Edit',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <UpdatePerson />
//           </Suspense>
//         ),
//       },
//       {
//         path: ':id/Assignments',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <Assignment />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'absence/:personId',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <Absence />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'EndOfService/:personId',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <EndOfService />
//           </Suspense>
//         ),
//       },
//       {
//         path: 'absence/:id/ViewAbsence',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <ViewAbsence />
//           </Suspense>
//         ),
//       },
//       {
//         path: ':PersonId/ExtraRecordFormPerson',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <ExtraRecordFormPerson />
//           </Suspense>
//         ),
//       },
//     ],
//   },
//   {
//     path: 'HR/Delegation',
//     element: (
//       <Suspense fallback={<LazyLoading />}>
//         <Delegation />
//       </Suspense>
//     ),
//   },
//   {
//     path: 'HR/Reports/*',
//     children: [
//       {
//         path: '*',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <HRReports />
//           </Suspense>
//         ),
//       },
//       {
//         path: ':ReportId/ReportsBuilder',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <ReportsBuilder />
//           </Suspense>
//         ),
//       },
//     ],
//   },
//   {
//     path: 'HrDashboard/*',
//     children: [
//       {
//         path: '*',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <DashboardHR />
//           </Suspense>
//         ),
//       },
//       {
//         path: ':dashboardId/BuilderDashboard',
//         element: (
//           <Suspense fallback={<LazyLoading />}>
//             <BuilderDashboard />
//           </Suspense>
//         ),
//       },
//     ],
//   },
//   // {
//   //   path: 'HRAme/Requests/*',
//   //   children: [{ path: '*', element: <HRRequests /> }],
//   // },
// ];

// export default hrRoutes;

import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading.jsx';

// HR Pages
const Employees = lazy(() => import('../../pages/HR/Employees/HR-PERSON/Index/Index.js'));
const Assignment = lazy(() => import('../../pages/HR/Employees/Assignment/Assignment.js'));
const Absence = lazy(() => import('../../pages/HR/Employees/Absence/absence'));
const ViewAbsence = lazy(() => import('../../pages/HR/Employees/Absence/ViewAbsence/Index'));
const UpdatePerson = lazy(() => import('../../pages/HR/Employees/HR-PERSON/Edit/UpdatePerson'));
const ExtraRecordFormPerson = lazy(() =>
  import('../../pages/HR/Employees/HR-PERSON/ExtraRecord/ExtraRecordFormPerson.js')
);
const EndOfService = lazy(() => import('../../pages/HR/Employees/EndOfService/EndOfService'));

// HR Delegation and Reports
const Delegation = lazy(() => import('../../pages/HR/Delegation/Delegation'));
const HRReports = lazy(() => import('../../pages/HR/HRReports/HRReports'));
const ReportsBuilder = lazy(() =>
  import('../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder')
);

// HR Dashboard
const DashboardHR = lazy(() => import('../../pages/HR/DashboardHR/DashboardHR'));
const BuilderDashboard = lazy(() =>
  import('../../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard')
);

// HR Settings
const Job = lazy(() => import('../../pages/HR/settings/Jobs/Job.js'));
const JobGrades = lazy(() => import('../../pages/HR/settings/JobGrades/JobGrades.js'));
const Location = lazy(() => import('../../pages/HR/settings/Location/Location.js'));
const Position = lazy(() => import('../../pages/HR/settings/position/position.js'));
const GroupEmployees = lazy(() =>
  import('../../pages/HR/settings/GroupEmployees/GroupEmployees.js')
);

// HR Settings - Edit Pages
const EditJob = lazy(() => import('../../pages/HR/settings/Jobs/EditJob.js'));
const EditJobGrade = lazy(() => import('../../pages/HR/settings/JobGrades/EditJobGrade.js'));
const EditLocation = lazy(() =>
  import('../../pages/HR/settings/Location/EditLocation/EditLocation.js')
);
const EditPosition = lazy(() => import('../../pages/HR/settings/position/EditPosition.js'));
const EditGroupEmployees = lazy(() =>
  import('../../pages/HR/settings/GroupEmployees/EditGroupEmployees.js')
);

// Reusable Suspense Wrapper
const withLazyLoading = (Component) => (
  <Suspense fallback={<LazyLoading />}>
    <Component />
  </Suspense>
);

const hrRoutes = [
  {
    path: 'HRsettings/*',
    children: [
      { path: 'Job', element: withLazyLoading(Job) },
      { path: 'Job/:id/Edit', element: withLazyLoading(EditJob) },
      { path: 'JobGrade', element: withLazyLoading(JobGrades) },
      { path: 'JobGrade/:id/Edit', element: withLazyLoading(EditJobGrade) },
      { path: 'Location', element: withLazyLoading(Location) },
      { path: 'Location/:id/Edit', element: withLazyLoading(EditLocation) },
      { path: 'Position', element: withLazyLoading(Position) },
      { path: 'Position/:id/Edit', element: withLazyLoading(EditPosition) },
      { path: 'StaffGroup', element: withLazyLoading(GroupEmployees) },
      { path: 'StaffGroup/:id/Edit', element: withLazyLoading(EditGroupEmployees) },
    ],
  },
  {
    path: 'HR/Employees/*',
    children: [
      { path: '*', element: withLazyLoading(Employees) },
      { path: ':id/Edit', element: withLazyLoading(UpdatePerson) },
      { path: ':id/Assignments', element: withLazyLoading(Assignment) },
      { path: 'absence/:personId', element: withLazyLoading(Absence) },
      { path: 'EndOfService/:personId', element: withLazyLoading(EndOfService) },
      { path: 'absence/:id/ViewAbsence', element: withLazyLoading(ViewAbsence) },
      { path: ':PersonId/ExtraRecordFormPerson', element: withLazyLoading(ExtraRecordFormPerson) },
    ],
  },
  {
    path: 'HR/Delegation',
    element: withLazyLoading(Delegation),
  },
  {
    path: 'HR/Reports/*',
    children: [
      { path: '*', element: withLazyLoading(HRReports) },
      { path: ':ReportId/ReportsBuilder', element: withLazyLoading(ReportsBuilder) },
    ],
  },
  {
    path: 'HrDashboard/*',
    children: [
      { path: '*', element: withLazyLoading(DashboardHR) },
      { path: ':dashboardId/BuilderDashboard', element: withLazyLoading(BuilderDashboard) },
    ],
  },
  // Uncomment when ready to add HRRequests
  // {
  //   path: 'HRAme/Requests/*',
  //   children: [{ path: '*', element: <HRRequests /> }],
  // },
];

export default hrRoutes;
