import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetPeopleLocationsByPeopleId } from '../../../store/NTX/Peoples/PeopleLocationsSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  peopleId: string;
}

const useGetPeopleLocationsList = ({ peopleId }: Props) => {
  const { i18n, t } = useTranslation();

  const dispatch = useAppDispatch();

  const { PeopleLocationsListByPeopleId, loading, error } = useAppSelector(
    (state) => state.PeopleLocations
  );
  const data = PeopleLocationsListByPeopleId;

  useEffect(() => {
    if (peopleId) {
      dispatch(GetPeopleLocationsByPeopleId(peopleId));
    }
  }, [dispatch, peopleId]);

  const shipToLocation = data?.filter((f) => f.shipToSiteFlag === true);
  const billToLocation = data?.filter((f) => f.billToSiteFlag === true);

  return {
    data,
    billToLocation,
    shipToLocation,
    loading,
    error,
  };
};

export default useGetPeopleLocationsList;
