import React, { useState } from 'react';
import MedModalComponent from '../../../../../components/Modal/MedModalComponent';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Add from './Add';
import Update from './Update';
import { Link } from 'react-router-dom';
import useGetApExpenseCategoryList from '../../../../../hooks/Finance/Expense/useGetApExpenseCategoryList';
import i18n from '../../../../../i18n';
import useGetGlJeCategoryList from '../../../../../hooks/Finance/useGetGlJeCategoryList';
import useGetGlJeSourceList from '../../../../../hooks/Finance/useGetGlJeSourceList';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import ExpenseCategoryItems from '../ExpenseCategoryItems/ExpenseCategoryItems';
import NtxTable from '../../../../../components/DataTable/NtxTable';
import Loading from '../../../../../components/Shared/Loader/Loading';

const Index = () => {
  const [ID, setID] = useState<string>('');
  const [Show, setShow] = useState(false);
  const [Show2, setShow2] = useState(false);

  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<any>(null); // لحفظ الفئة المختارة
  const [selectedSource, setSelectedSource] = useState<any>(null);

  const { t } = useTranslation();

  const { OptionsJeCategory, loading: loading2, error: error2 } = useGetGlJeCategoryList();
  const { OptionsJeSource, loading: loading3, error: error3 } = useGetGlJeSourceList();

  const { ApExpenseCategoryList, loading, error } = useGetApExpenseCategoryList();

  // فلترة البيانات بناءً على البحث، الفئة والمصدر
  const filteredSearch = ApExpenseCategoryList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    const matchesSearch =
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase);

    const matchesCategory = selectedCategory ? item.jeCategory === selectedCategory : true;

    const matchesSource = selectedSource ? item.jeSource === selectedSource : true;

    return matchesSearch && matchesCategory && matchesSource;
  });

  const columns = [
    { title: t('Name'), key: 'name' },
    { title: t('Category'), key: 'Category' },
    { title: t('Source'), key: 'Source' },
    { title: t('Account'), key: 'Account' },
    { title: '', key: 'action', className: 'text-end' },
  ];

  const tableData =
    filteredSearch?.map((item) => {
      let rowClass;

      return {
        name: i18n.language === 'en' ? item.name : item.name2,
        Category: i18n.language === 'en' ? item.jeCategoryName : item.jeCategoryName2,
        Source: i18n.language === 'en' ? item.jeSourceName : item.jeSourceName2,
        Account: (
          <td className="align-middle">
            {i18n.language === 'en' ? item.expenseAccountName : item.expenseAccountName2}{' '}
            <span className="text-theme">{item.expenseAccountCode}</span>
          </td>
        ),

        action: (
          <>
            <button
              type="button"
              className="btn btn-outline-theme ms-1"
              data-bs-toggle="modal"
              data-bs-target="#modalAddExpenseCategoryItems"
              onClick={() => {
                setID(item.id);
                setShow2(true);
              }}>
              <i className="far fa-lg fa-fw fa-edit me-1"></i>
              {t('ExpenseCategoryItems')}
            </button>
            <button
              type="button"
              className="btn btn-outline-theme ms-1"
              data-bs-toggle="modal"
              data-bs-target="#modalEdit"
              onClick={() => setID(item.id)}>
              <i className="far fa-lg fa-fw fa-edit me-1"></i>
              {t('Button.Edit')}
            </button>
          </>
        ),
      };
    }) || [];

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'ExpenseTypes',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('ExpenseTypes')} />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAdd"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('Add')}
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-1">
              <div className="row gy-1 mb-2">
                <div className="col-lg-4">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">{t('Search')}</span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control ps-35px"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text  fw-bold">{t('Category')}</span>
                    <Select
                      classNamePrefix="react-select"
                      className={`w-100 `}
                      isLoading={loading2}
                      isSearchable={true}
                      isClearable
                      options={OptionsJeCategory}
                      onChange={(option) => {
                        setSelectedCategory(option?.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text  fw-bold">{t('Source')}</span>
                    <Select
                      classNamePrefix="react-select"
                      className={`w-100 `}
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={OptionsJeSource}
                      onChange={(option) => {
                        setSelectedSource(option?.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>
              </div>

              <NtxTable
                data={tableData || []}
                columns={columns || []}
                dataPerPage={2}
                theadClass=""
                tableClass="table text-nowrap table-hover "
                // enableSearch={true}
                // showRecordsSelector
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title={t('Add')} id="modalAdd">
        {Show && <Add />}
      </MedModalComponent>

      <ModalComponent title="تعديل" id="modalEdit">
        {ID && <Update ID={ID} />}
      </ModalComponent>

      <MedModalComponent title={t('ExpenseCategoryItems')} id="modalAddExpenseCategoryItems">
        {Show2 && <ExpenseCategoryItems expenseCategoryId={ID} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
