import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { TaxManagerModels } from './TaxManagerModels';
import { APIResponse } from '../../Shared/shared';

export const AddTaxManagers = createAsyncThunk(
  'TaxManagers/AddTaxManagers',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Finance/AddTaxManagers', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatTaxManagers = createAsyncThunk(
  'TaxManagers/UpdatTaxManagers',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('Finance/UpdatTaxManagers', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetTaxManagersList = createAsyncThunk(
  'TaxManagers/GetTaxManagersList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Finance/GetTaxManagersList');
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
        // return [];
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// إنشاء الـ Thunk لجلب نسبة القيمة المضافة
export const getTaxRate = createAsyncThunk(
  'TaxManagers/getetTaxRate',
  async (
    {
      ledgerId,
      taxType,
      dateActive,
    }: {
      ledgerId: string;
      taxType: string;
      dateActive: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<number>>('Finance/GetTaxRate', {
        params: {
          LedgerId: ledgerId,
          TaxType: taxType,
          DateActive: dateActive,
        },
      });
      if (data.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  TaxManagerList: TaxManagerModels[];
  loading: boolean;
  error: any | null;
  taxRate: number | null;
} = {
  TaxManagerList: [],
  loading: false,
  error: null,
  taxRate: null,
};

const TaxManagerSlice = createSlice({
  name: 'TaxManagers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(AddTaxManagers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddTaxManagers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.TaxManagerList.push(action.payload.data);
      })
      .addCase(AddTaxManagers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //_________________________________________
      .addCase(GetTaxManagersList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetTaxManagersList.fulfilled, (state, action: PayloadAction<TaxManagerModels[]>) => {
        state.loading = false;
        state.TaxManagerList = action.payload;
      })
      .addCase(GetTaxManagersList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //_________________________________________
      .addCase(getTaxRate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTaxRate.fulfilled, (state, action: PayloadAction<APIResponse<number>>) => {
        state.taxRate = action.payload.data;
        state.loading = false;
      })
      .addCase(getTaxRate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch tax rate';
      });
  },
});

export default TaxManagerSlice.reducer;
