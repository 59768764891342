import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import DynamicPrint from '../../../../components/DynamicPrint/DynamicPrint';
import useGetQuotationList from '../../../../hooks/Sales/useGetQuotationList';

const Index = () => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { SalesQuotationList, loading, error } = useGetQuotationList();

  const filteredSearch = SalesQuotationList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'عروض الأسعار',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="عروض الأسعار" />
        <div className="ms-auto">
          <Link to="AddQuotation" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            عرض سعر جديد
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap text-center">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('رقم العرض ')}</th>
                      <th scope="col">{t('تاريخ العرض ')}</th>
                      <th scope="col">{t('العميـل ')}</th>
                      <th scope="col">{t('الفرع ')}</th>
                      <th scope="col">{t('قيمة العرض ')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle"> {item.code} </td>
                        <td className="align-middle"> {item.transactionDate} </td>
                        <td className="align-middle"> {item.peopleName2} </td>
                        <td className="align-middle"> {item.organizationName2} </td>
                        <td className="align-middle"> {item.grossSale}</td>

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            onClick={() => Navigate(`${item.id}/UpdateQuotation`)}>
                            <i className="far fa-lg fa-fw me-2 fa-eye"></i> {t('عرض التفاصيل')}
                          </button>
                          <button type="button" className="btn btn-outline-theme mx-1">
                            <i className="far fa-lg fa-fw me-2 fa-paper-plane"></i>
                            {t('إرسـال للعميــل')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Index;
