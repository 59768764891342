import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetModule } from '../../store/Lookup/lookupSlice';
import { useTranslation } from 'react-i18next';

const useLookupModule = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Module?.length === 0) {
      dispatch(GetModule());
    }
  }, [dispatch]);

  const { Module, loading } = useSelector((state) => state.Lookup);

  const OptionsModule =
    Module &&
    Module?.map((item) => ({
      value: item.id,
      label: i18n.language === 'ar' ? item.name2 : item.name,
    }));

  return { Module, loading , OptionsModule };
};

export default useLookupModule;
