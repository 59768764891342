// import { AddFlexValue } from '../../pages/NTX/FlexValue/ListSystem/FlexValue/AddFlexValue.js';
// import AddFormColumn from '../../pages/NTX/FlexValue/FormColumn/AddFormColumn.js';

// import ListSystem from '../../pages/NTX/FlexValue/ListSystem/ListSystem.js';

// import ExtraInformationForm from '../../pages/NTX/FlexValue/ExtraInformationForm/ExtraInformationForm.js';
// import EditExtraInformationForm from '../../pages/NTX/FlexValue/ExtraInformationForm/EditExtraInformationForm.js';
// import AddSubLists from '../../pages/NTX/FlexValue/ListSystem/FlexValue/AddSubLists.js';
// import ExtraRecordsForm from '../../pages/NTX/FlexValue/ExtraRecordsForm/ExtraRecordsForm.js';
// import EditExtraRecordsForm from '../../pages/NTX/FlexValue/ExtraRecordsForm/EditExtraRecordsForm.js';
// import Lookup from '../../pages/NTX/Lookup/Lookup';
// import Applications from '../../pages/Applications/Applications';
// import NtxSettings from '../../pages/NTX/Settings/NtxSettings';
// import NtxProfile from '../../pages/NTX/Profile/NtxProfile';
// import Integration from '../../pages/Integration/Index';

// const ntxRoutes = [
//   {
//     path: 'NTX/*',
//     children: [
//       { path: 'Forms/:id/AddFormColumns', element: <AddFormColumn /> },
//       { path: 'ListSystem', element: <ListSystem /> },
//       { path: 'ListSystem/:id/AddFlexValue', element: <AddFlexValue /> },
//       { path: 'ListSystem/:id/AddSubLists', element: <AddSubLists /> },
//     ],
//   },

//   {
//     path: 'Forms/*',
//     children: [
//       { path: 'ExtraInformationForm', element: <ExtraInformationForm /> },
//       {
//         path: 'ExtraInformationForm/:id/EditExtraInformationForm',
//         element: <EditExtraInformationForm />,
//       },

//       { path: 'ExtraRecordsForm', element: <ExtraRecordsForm /> },
//       {
//         path: 'ExtraRecordsForm/:id/EditExtraRecordsForm',
//         element: <EditExtraRecordsForm />,
//       },
//     ],
//   },
//   { path: 'NTX/*', children: [{ path: 'Lookup', element: <Lookup /> }] },

//   {
//     path: 'Neatix/Applications',
//     element: <Applications />,
//   },
//   {
//     path: 'Neatix/Settings',
//     element: <NtxSettings />,
//   },
//   {
//     path: 'Neatix/Profile',
//     element: <NtxProfile />,
//   },
//   {
//     path: 'Integration/',
//     element: <Integration />,
//   },
// ];

// export default ntxRoutes;

import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';

// Lazy load components
const AddFlexValue = lazy(() =>
  import('../../pages/NTX/FlexValue/ListSystem/FlexValue/AddFlexValue')
);
const AddFormColumn = lazy(() => import('../../pages/NTX/FlexValue/FormColumn/AddFormColumn'));
const ListSystem = lazy(() => import('../../pages/NTX/FlexValue/ListSystem/ListSystem'));
const ExtraInformationForm = lazy(() =>
  import('../../pages/NTX/FlexValue/ExtraInformationForm/ExtraInformationForm')
);
const EditExtraInformationForm = lazy(() =>
  import('../../pages/NTX/FlexValue/ExtraInformationForm/EditExtraInformationForm')
);
const AddSubLists = lazy(() =>
  import('../../pages/NTX/FlexValue/ListSystem/FlexValue/AddSubLists')
);
const ExtraRecordsForm = lazy(() =>
  import('../../pages/NTX/FlexValue/ExtraRecordsForm/ExtraRecordsForm')
);
const EditExtraRecordsForm = lazy(() =>
  import('../../pages/NTX/FlexValue/ExtraRecordsForm/EditExtraRecordsForm')
);
const Lookup = lazy(() => import('../../pages/NTX/Lookup/Lookup'));
const Applications = lazy(() => import('../../pages/Applications/Applications'));
const NtxSettings = lazy(() => import('../../pages/NTX/Settings/NtxSettings'));
const NtxProfile = lazy(() => import('../../pages/NTX/Profile/NtxProfile'));
const Integration = lazy(() => import('../../pages/Integration/Index'));

const ntxRoutes = [
  {
    path: 'NTX/*',
    children: [
      {
        path: 'Forms/:id/AddFormColumns',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddFormColumn />
          </Suspense>
        ),
      },
      {
        path: 'ListSystem',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ListSystem />
          </Suspense>
        ),
      },
      {
        path: 'ListSystem/:id/AddFlexValue',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddFlexValue />
          </Suspense>
        ),
      },
      {
        path: 'ListSystem/:id/AddSubLists',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <AddSubLists />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'Forms/*',
    children: [
      {
        path: 'ExtraInformationForm',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ExtraInformationForm />
          </Suspense>
        ),
      },
      {
        path: 'ExtraInformationForm/:id/EditExtraInformationForm',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <EditExtraInformationForm />
          </Suspense>
        ),
      },
      {
        path: 'ExtraRecordsForm',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ExtraRecordsForm />
          </Suspense>
        ),
      },
      {
        path: 'ExtraRecordsForm/:id/EditExtraRecordsForm',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <EditExtraRecordsForm />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'NTX/*',
    children: [
      {
        path: 'Lookup',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Lookup />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'Neatix/Applications',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <Applications />
      </Suspense>
    ),
  },
  {
    path: 'Neatix/Settings',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <NtxSettings />
      </Suspense>
    ),
  },
  {
    path: 'Neatix/Profile',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <NtxProfile />
      </Suspense>
    ),
  },
  {
    path: 'Integration/',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <Integration />
      </Suspense>
    ),
  },
];

export default ntxRoutes;
