import React from 'react';
// import NtxPeoples from '../../../NTX/Peoples/NtxPeoples';
// import NtxSuppliers from '../../../NTX/Peoples/NtxSuppliers';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import NtxPeoples2 from '../../../NTX/Peoples/NtxPeoples2';

const Add = () => {
  // var fieldList = [
  //   'typeCode',

  //   'personId',
  //   'billToLocationId',
  //   'shipToLocationId',
  //   'termsId', //المعرف الفريد لشروط الدفع.
  //   'setOfBooksId', //  الاستاذ العام
  //   'creditLimit', // حد الائتمان للمورد.
  //   // "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل  ??
  //   'invoiceCurrencyCode', //
  //   'paymentCurrencyCode', //
  //   'invoiceLimit', // موجود في المورد وليس في العميل ونوعه رقم   حد الفاتورة للمورد ??
  //   'paymentMethodLookupCode', //  ??
  //   'bankAccountName', // اسم الحساب البنكي للمورد.
  //   'bankAccountNum', //  : رقم الحساب البنكي.
  //   'bankNumber', //  رقم البنك.
  //   'bankAccountType', // نوع الحساب البنكي.
  //   'vatCode', //  الرقم الضريبي للمورد
  //   'tradingName', // الاسم التجاري للمورد
  //   // "workReference", // اسم الشخص
  //   'companyRegistrationNumber', // السجل التجاري للمورد
  //   'peopleCategory',
  //   'priceListId',
  //   'accountId',
  // ];

  const { t, i18n } = useTranslation();

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Suppliers'),
      link: '/PurchasesSettings/SUPPLIERS',
    },
    {
      name: t('AddNewSupplier'),
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('AddNewSupplier')} />

     
      {/* <NtxPeoples typeCode="SUPPLIERS" isUpdate={false} fieldList={fieldList} /> */}

      {/* <NtxSuppliers isUpdate={false} parentId={null} /> */}

      <NtxPeoples2 isUpdate={false} typeCode="SUPPLIERS" parentId={null} />
    </div>
  );
};

export default Add;
