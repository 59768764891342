import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getArReceiptsList } from '../../../store/Finance/AR/ArReceipts/ArReceiptsSlice';

const useGetReceiptsList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ArReceiptsList?.length === 0) {
      dispatch(getArReceiptsList());
    }
  }, []);
  const { ArReceiptsList, loading, error } = useAppSelector((state) => state.ArReceipts);

  return { ArReceiptsList, loading, error };
};

export default useGetReceiptsList;
