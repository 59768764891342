import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {  getInventoryWithPermission} from '../../../store/HR/Organizations/organizationSlice';

const useGetInventoryWithPermission = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInventoryWithPermission()); // المخازن
  }, []);

  const {
    InventoryList: data,
    loading,
    error,
  } = useSelector((state) => state.Organizations);

  const InventoryOptions =
    data &&
    data?.map((item) => ({
      value: item.id,
      label: i18n.language === 'en' ? item.name : item.name2,
    }));

  return { data, InventoryOptions, loading, error };
};

export default useGetInventoryWithPermission;
