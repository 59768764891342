import { useFormik } from 'formik';
// import React, { useContext, useEffect } from "react";
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import ItemsList from './ItemsList/ItemsList';
import { addInventoryCount } from '../../../../store/INV/InventoryCount/inventoryCountSlice';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import { useState } from 'react';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import useGetInventoryWithPermission from '../../../../hooks/NTX/Organization/useGetInventoryWithPermission';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import { getPersonId } from '../../../../store/Login/loginSlice';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';

const Add = () => {
  const [showModal, setShowModal] = useState(true);
  const [inventory, setInventory] = useState<any>(null);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { dataEmployees, loading } = useGetPersons();
  const personId = useAppSelector(getPersonId);

  // const { loading: loading1, TreeSubInventoryData } = useGetSuppliersList();

  const { InventoryOptions, loading: loading3 } = useGetInventoryWithPermission();
  const { BranchOrgOptions } = useGetBranchOrgWithPermission();
  const { LedgersList } = useGetGlLedgersList();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      name: '',
      name2: '',
      personId: personId,
      inventoryId: '',
      branchId: '',
      startDate: '',
      description: '',
      freezeDate: '',
      immediately: false,
      ledgerId: '',

      inventoryCountItemsList: [] as any[],
    },
    enableReinitialize: true,
    // validationSchema: AddGlJeHeaderSchema(t),
    onSubmit: (values) => {
      dispatch(
        addInventoryCount({
          name: values.name,
          name2: values.name2,
          personId: values.personId,
          inventoryId: values.inventoryId,
          branchId: values.branchId,
          startDate: values.startDate,
          description: values.description,
          freezeDate: values.freezeDate,
          immediately: values.immediately,
          ledgerId: values.ledgerId,
          inventoryCountItemsList: values.inventoryCountItemsList?.map((item) => ({
            inventoryItemId: item.inventoryItemId,
            systemQuantity: item.systemQuantity,
            description: item.description,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const handleSubmitModal = async () => {
    if (inventory) {
      setShowModal(false);
      await formik.setFieldValue('inventoryId', inventory);
    } else {
      ToastSwal({ title: 'يرجى اختيار المخزن', timer: 1000 });
    }
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'جرد المخزون',
      link: '',
    },
    {
      name: 'إضافة جرد جديد',
      link: null,
    },
  ];

  console.log(values);

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="إضافة جرد جديــد" />

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('LocalName')}</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('GlobalName')}</label>
                  <Form.Control
                    className="form-control"
                    type="text" 
autoComplete='off'
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchOrgOptions}
                    onChange={(option: any) => {
                      formik.setFieldValue('branchId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    value={InventoryOptions.find((d: any) => d.value === values.inventoryId)}
                    onChange={(option: any) => {
                      formik.setFieldValue('inventoryId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">ledgerId - الأستاذ العام</label>
                  <Form.Select
                    className="form-select"
                    name="ledgerId"
                    onChange={formik.handleChange}
                    value={formik.values.ledgerId}
                    isInvalid={!!(formik.errors.ledgerId && formik.touched.ledgerId)}>
                    <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {LedgersList &&
                      LedgersList?.map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.ledgerId}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-lg-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('startDate')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="startDate"
                    onChange={formikhandleChange}
                    value={values.startDate || ''}
                    isInvalid={!!(touched.startDate && errors.startDate)}
                    // isValid={formik.touched.startDate && !formik.errors.startDate}
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('freezeDate')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="date"
                    name="freezeDate"
                    onChange={formikhandleChange}
                    value={values.freezeDate || ''}
                    isInvalid={!!(touched.freezeDate && errors.freezeDate)}
                    // isValid={formik.touched.freezeDate && !formik.errors.freezeDate}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('personId')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    name="personId"
                    options={dataEmployees}
                    value={dataEmployees?.find((f: any) => f.value === values.personId)}
                    onChange={(option: any) => {
                      formik.setFieldValue('personId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-2 ">
                <div className="form-group mb-3">
                  <label className="form-label mb-4"></label>

                  <div className="form-check ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="immediately"
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label">immediately</label>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Note')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={values.description}
                    isInvalid={!!(touched.description && errors.description)}
                    isValid={touched.description && !errors.description}
                  />
                </div>
              </div>
            </div>

            <div className="row m-0 p-0">
              <div className="bg-inverse bg-opacity-5 p-2">
                {/* <TransactionsInvLines formik={formik} /> */}
                <ItemsList formik={formik} />
              </div>
            </div>

            <div className="text-center mt-2  ">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn btn-lg me-1 btn-theme mb-1">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t('Add')}
              </button>
              <button
                type="button"
                className="btn btn-lg  me-1 btn-default mb-1"
                onClick={() => Navigate(-1)}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        size="md"
        bodyClassName="p-0"
        showHeader={false}
        keyboard={false}
        backdrop={true}>
        <Card>
          <CardBody>
            <div className="text-center bg-inverse bg-opacity-10 rounded-0 p-2 fw-bold fs-5 mb-2">
              حدد لمخزن المراد جرده
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    onChange={(option: any) => {
                      // formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      setInventory(option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-lg btn-theme  w-100 rounded-0"
                  onClick={handleSubmitModal}>
                  <i className="fas fa-lg fa-fw me-2 fa-check-circle"></i>
                  {t('إختيار')}
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-lg  btn-default  w-100 rounded-0"
                  onClick={() => Navigate('/INV/InventoryCount', { replace: true })}>
                  <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </NeatixModal>
    </>
  );
};

export default Add;
