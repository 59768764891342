import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';
import { ntxAPI } from '../../API/axiosNeatex';
import isOnline from 'is-online';
import i18n from '../../i18n';
import ToastSwal from '../../components/Shared/Alert/ToastSwal';
import Swal from 'sweetalert2';

const Pos = lazy(() => import('../../pages/POS/PosSetting/Pos/Index'));
const PosDetails = lazy(() => import('../../pages/POS/PosSetting/PosDetails/PosDetails'));
const PosCard = lazy(() => import('../../pages/POS/PosCard/PosCard'));
// const PointOfSaleShop = lazy(() => import('../../pages/POS/PointOfSale/PointOfSaleShop'));
const PointOfSaleShop = lazy(() => import('../../pages/POS/PointOfSale/Shop/PointOfSaleShop'));
// const PointOfSaleRestaurant = lazy(() =>
//   import('../../pages/POS/PointOfSale/PointOfSaleRestaurant')
// );

const loadDataForPointOfSaleShop = async ({ params }) => {
  const { sessionId } = params;

  if (!(await isOnline())) return;

  try {
    //   ToastSwal({
    //     // title: 'جاري تحميل البيانات',
    //     icon: 'info',
    //     timer: 2000000,
    //     position: 'top',
    //     htmlContent: `
    //   <div class="text-center d-flex justify-content-between">
    //   <div class="fs-6 w-75">جاري تجهيز بيانات نقطة البيع لبدء عملية البيع</div>
    //   <div class="spinner-border text-theme"></div>
    //   </div>
    //  `,
    //   });
    const { data } = await ntxAPI.get('Pos/GetPosDataBySessionId', {
      params: { sessionId },
    });

    if (data.succeeded === true) {
      const PosData = {
        sessionId: data.data.sessionId,
        posId: data.data.posId,
        posName: data.data.posName,
        posName2: data.data.posName2,
        casherId: data.data.casherId,
        casherName: i18n.language === 'en' ? data.data.casherName : data.data.casherName2,
        peopleName: i18n.language === 'en' ? data.data.peopleName : data.data.peopleName2,
        // casherName2: 'الحارث محمد نعمان المقرمي',
        startDatetime: data.data.startDatetime,
        openingBalance: data.data.openingBalance,
        openingNote: data.data.openingNote,
      };

      const ItemsList = data.data?.itemsList?.map((m) => ({
        id: m.id,
        itemId: m.itemId,
        barcode: m.barcode,
        categoryId: m.categoryId,
        categoryName: i18n.language === 'en' ? m.categoryName : m.categoryName2,
        categoryIcon: m.categoryIcon,
        enabledFlag: m.enabledFlag,
        isPrimaryUOM: m.isPrimaryUOM,
        title: i18n.language === 'en' ? m.name : m.name2,
        unitOfMeasureName: i18n.language === 'en' ? m.unitOfMeasureName : m.unitOfMeasureName2,
        unitOfMeasureId: m.unitOfMeasure,
        price: m.unitPrice,
        image:
          'https://media.licdn.com/dms/image/D4D12AQEFpeQHJkC50g/article-cover_image-shrink_720_1280/0/1667896412196?e=2147483647&v=beta&t=EEBUH-kM2vS3WF2KlDYBY6U6EaNAdj_WSwKLOR7UbMM',
        options: m.attributeList,
      }));

      const CategoriesList = [
        ...new Map(
          ItemsList?.map((item) => [
            item.categoryId,
            { id: item.categoryId, name: item.categoryName, icon: item.categoryIcon },
          ])
        ).values(),
      ];

      const PosPaymentMethodList = data.data?.paymentMethodList
        ?.map((m) => ({
          paymentMethodId: m.paymentMethodId,
          paymentMethodName: i18n.language === 'en' ? m.paymentMethodName : m.paymentMethodName2,
        }))
        .sort((a, b) => {
          // النوع نقدي . يكون في البداية دائما
          if (a.paymentMethodId === '05fe55ee-ade9-4b4b-85f9-c1dbacf40a6b') return -1;
          if (b.paymentMethodId === '05fe55ee-ade9-4b4b-85f9-c1dbacf40a6b') return 1;
          return 0;
        });

      const TransactionsList = data.data.transactionsList;

      const SessionData = {
        posData: PosData,
        items: ItemsList,
        categories: CategoriesList,
        paymentMethods: PosPaymentMethodList,
        transactionsList: TransactionsList,
      };

      localStorage.setItem('sessionData', JSON.stringify(SessionData));
      return data;
    } else {
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    Swal.close();
  }
};

const posRoutes = [
  {
    path: 'Pos/*',
    children: [
      {
        path: 'PosSettings',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Pos />
          </Suspense>
        ),
      },
      {
        path: 'PosSettings/:posId/PosDetails',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <PosDetails />
          </Suspense>
        ),
      },
      {
        path: 'PosCard',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <PosCard />
          </Suspense>
        ),
      },
      {
        path: ':posId/:sessionId/PointOfSaleShop',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <PointOfSaleShop />
          </Suspense>
        ),
        loader: loadDataForPointOfSaleShop,
      },
      // {
      //   path: ':posId/:sessionId/PointOfSaleRestaurant',
      //   element: (
      //     <Suspense fallback={<LazyLoading />}>
      //       <PointOfSaleRestaurant />
      //     </Suspense>
      //   ),
      // },
    ],
  },
];

export default posRoutes;
