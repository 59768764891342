import React, {  useState } from "react";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import Loader from "../../../../components/Shared/Loader/Loader";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/Shared/Pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";
import useGetPurchaseBillList from "../../../../hooks/PUR/useGetPurchaseBillList";

const Index = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const {PurchaseBillList , loading} = useGetPurchaseBillList();

 
  const filteredSearch = PurchaseBillList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "مردود مشتريات",
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="مردود مشتريات" />
 
      </div>
      <Card>
        <Loader loading={loading} />
        <CardBody>
          <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('رقم الفاتورة ')}</th>
                    <th scope="col">{t('تاريخ الفاتورة ')}</th>
                    <th scope="col">{t('العميـل ')}</th>
                    <th scope="col">{t('الفرع ')}</th>
                    <th scope="col">{t('قيمة الفاتورة ')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle"> {item.code} </td>
                      <td className="align-middle"> {item.transactionDate} </td>
                      <td className="align-middle"> {item.peopleName2} </td>
                      <td className="align-middle"> {item.organizationName2} </td>
                      <td className="align-middle"> {item.grossAmount}</td>

                      <td className="text-end">
                        <button
                          type="button"
                          className="btn btn-outline-theme mx-1"
                          onClick={() => Navigate(`${item.id}/AddUpdateViewPurchaseReturn`)}>
                          {/* <i className="far fa-lg fa-fw me-2 fa-edit"></i> */}
                          إسترداد
                        </button>

   
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) =>
                  setCurrentPage(pageNumber)
                }
                currentPage={currentPage}
              />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Index;
