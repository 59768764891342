import { Card, CardBody, CardExpandToggler } from '../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import BarChart from './BarChart.js';

const Transactions = [
  { id: 1, category: 'الراتب', amount: 3000 },
  { id: 2, category: 'تكلفة العمال', amount: 1500 },
  { id: 3, category: 'تكلفة العمال', amount: 2500 },
  { id: 4, category: 'المدفوعات', amount: 2500 },
  { id: 5, category: 'أشعار خصم', amount: 2000 },
  { id: 6, category: 'سند قبض', amount: 3500 },
  { id: 7, category: 'سند صرف', amount: 2500 },
];

const JournalsByCategory = () => {
  const { t } = useTranslation();

  function calculateTotalAmountByCategory(transactions) {
    const categoryDistribution = {};

    transactions?.forEach((transaction) => {
      const category = transaction.category;
      if (categoryDistribution[category]) {
        categoryDistribution[category].totalAmount += transaction.amount;
      } else {
        categoryDistribution[category] = {
          name: category,
          count: transaction.amount,
        };
      }
    });

    return Object.values(categoryDistribution);
  }

  const [data, setData] = useState([]);

  useEffect(() => {
    const generatedData = calculateTotalAmountByCategory(Transactions);
    setData(generatedData);
  }, []);

  return (
    <div>
      <Card className="mb-3" style={{ height: '100%' }}>
        <CardBody>
          <div className="d-flex fw-bold small mb-3">
            <span className="flex-grow-1">{t('توزيع القيود حسب الفئات')}</span>
            <CardExpandToggler />
          </div>
          <div className="ratio ratio-21x9 mb-3">
            {data && data.length > 0 ? (
              <BarChart data={data} />
            ) : (
              <p>{t('لا توجد بيانات لعرضها.')}</p>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default JournalsByCategory;
