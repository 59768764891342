import AsyncSelect from 'react-select/async';
import { ntxAPI } from '../../../../API/axiosNeatex';

const AsyncSelectExample = () => {
    
  // دالة لجلب البيانات من السيرفر
  const fetchOptions = async (inputValue) => {
    try {
      // اجلب البيانات من السيرفر بناءً على قيمة البحث (inputValue)
      const { data } = await ntxAPI.get('InvReceive/GetReceiveLookup', {
        params: {
          Code: inputValue, // تمرير قيمة البحث كمعامل للسيرفر
        },
      });
      //   debugger;

      // تحويل البيانات إلى تنسيق قابل للعرض في Select
      return data.data.map((item) => ({
        value: item.id,
        label: item.code,
      }));
    } catch (error) {
      console.error('Error fetching data: ', error);
      return [];
    }
  };

  return (
    <div>
      <AsyncSelect
        classNamePrefix="react-select"
        cacheOptions
        loadOptions={fetchOptions} // تمرير دالة جلب البيانات
        defaultOptions // عرض بعض الخيارات افتراضيًا
        placeholder="ابحث عن عنصر..."
      />
    </div>
  );
};

export default AsyncSelectExample;

 

