import { useContext } from 'react';
import { AppSettings } from '../config/app-settings';
 import useListApplications from './Applications/useListApplications';

const useGetSysCode = () => {
  const context = useContext(AppSettings);
  var sysCode = context.sysCode;
  const ListApplications = useListApplications()

  // البحث عن التطبيق بناءً على sysCode
  const app = ListApplications.find((application) => application.Code === sysCode);

  // إرجاع الكود والاسم إذا تم العثور على التطبيق، وإلا إرجاع قيم افتراضية
  // return app ? { code: app.Code, name: app.name } : { code: 'N/A', name: 'غير معروف' };
  return app ? { code: app.Code, name: app.name } : { code: '', name: '' };
};

export default useGetSysCode;
