import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../../../components/Shared/Alert/CustomAlert';
import Loading from '../../../../../../components/Shared/Loader/Loading';
import { LotItemSchema } from '../../../../../ValidationForm/validationSchema';
import { addLotDetails } from '../../../../../../store/INV/MasterItems/LotItemSlice';
import useGetUnitsOfMeasureList from '../../../../../../hooks/INV/useGetUnitsOfMeasureList';

interface InventoryItemsOptions {
  value: string;
  label: string;
  itemId: string;
  barcode: string;
  itemName: string;
  uom: string;
  uomName: string;
  isPrimaryUOM: boolean;
  categoryId: string;
  qtyByLot: {
    lotId: string | null;
    lotNumber: string;
    qty: number;
    expirationDate: Date | null;
  }[];
}

interface Props {
  lineData: any;
  onClose: () => void;
  // InventoryItemsOptions: InventoryItemsOptions[]
  // setInventoryItemsOptions : React.Dispatch<React.SetStateAction<InventoryItemsOptions[]>>;
}

const AddLotItem: React.FC<Props> = ({ lineData, onClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { UnitsOfMeasureList, error, loading } = useGetUnitsOfMeasureList();

  const formik = useFormik({
    initialValues: {
      itemId: lineData.transactionItemId,
      lotNumber: '',
      unitOfMeasure: lineData.transactionUom,
      expirationDate: '',
    },

    validationSchema: LotItemSchema(t),

    onSubmit: (values) => {
      dispatch(
        addLotDetails({
          itemId: lineData.transactionItemId,
          lotNumber: values.lotNumber,
          unitOfMeasure: values.unitOfMeasure,
          expirationDate: values.expirationDate,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <Form onSubmit={formik.handleSubmit}>
          <div className="row  ">
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('lotNumber')}</label>
                <Form.Control
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="lotNumber"
                  onChange={formik.handleChange}
                  value={formik.values.lotNumber}
                  isInvalid={!!(formik.touched.lotNumber && formik.errors.lotNumber)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.lotNumber}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label"> {t('الوحدة')}</label>
                <Form.Select
                  disabled
                  className="form-select"
                  name="unitOfMeasure"
                  onChange={formik.handleChange}
                  value={formik.values.unitOfMeasure || ''}
                  isInvalid={!!(formik.touched.unitOfMeasure && formik.errors.unitOfMeasure)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {UnitsOfMeasureList &&
                    UnitsOfMeasureList?.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === 'en' ? item.name : item.name2}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.unitOfMeasure?.toString()}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('تاريخ الإنتهاء')}</label>
                <Form.Control
                  className="form-control"
                  type="date"
                  name="expirationDate"
                  onChange={formik.handleChange}
                  value={formik.values.expirationDate || ''}
                  isInvalid={!!(formik.touched.expirationDate && formik.errors.expirationDate)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.expirationDate}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6  ">
              <div className=" form-group   d-flex justify-content-end ">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme form-control w-100 ">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fa fa-save fa-fw me-1"></i>
                  )}
                  {t('Save')}
                </button>
              </div>
            </div>
            <div className="col-xl-6">
              <button type="button" className=" btn btn-default w-100" onClick={onClose}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </div>
        </Form>
      </Loading>
    </>
  );
};

export default AddLotItem;
