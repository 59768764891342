import Pagination from '../Shared/Pagination/Pagination';
import NoData from '../NoData/NoData';
import { useState, useMemo } from 'react';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

// import styles from './styles.module.css';
// const { hover } = styles;

const NtxTable = ({
  data,
  columns,
  dataPerPage = 10,
  tableClass = '',
  theadClass = '',
  enableSearch = false, //  ظهور ابحث او لا
  showRecordsSelector = false, //  ظهور خيار عدد السجلات المعروضة
}) => {
  const {t} = useTranslation()
  // إدارة الحالة للبحث
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(dataPerPage); // حالة لإدارة عدد السجلات المعروضة

  // تصفية البيانات عند إدخال نص البحث
  const filteredData = useMemo(() => {
    if (!enableSearch || !searchQuery) return data;
    return data.filter((row) =>
      columns.some((column) => {
        const cellValue = row[column.key] || '';
        return cellValue.toString().toLowerCase().includes(searchQuery.toLowerCase());
      })
    );
  }, [data, searchQuery, columns, enableSearch]);

  // فرز البيانات
  const sortedData = useMemo(() => {
    if (!sortConfig.key) return filteredData;
    return [...filteredData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortConfig]);

  // حساب الفهرس لبداية ونهاية البيانات
  const indexOfLastData = currentPage * recordsPerPage;
  const indexOfFirstData = indexOfLastData - recordsPerPage;
  const currentData = useMemo(
    () => sortedData.slice(indexOfFirstData, indexOfLastData),
    [sortedData, indexOfFirstData, indexOfLastData]
  );

  // دالة التعامل مع الترتيب
  const handleSort = (columnKey, sortable = true) => {
    if (!sortable) return;
    setSortConfig((prevConfig) => {
      const direction =
        prevConfig.key === columnKey && prevConfig.direction === 'asc' ? 'desc' : 'asc';
      return { key: columnKey, direction };
    });
  };

  //  {/* <div className="col-lg-4">
  //         {enableSearch && (
  //           <span className="fw-bold">
  //             عدد السجلات الظاهرة: {currentData.length} من أصل {filteredData.length}
  //           </span>
  //         )}
  //       </div> */}

  return (
    <>
      {/* Search field and record count */}
      <div className="row gy-1 mb-3">
        <div className="col-lg-2">
          {showRecordsSelector && (
            <div className="input-group flex-nowrap">
              <span className="input-group-text fw-bold">
                {/* <i className="fa fa-sliders text-theme me-2"></i> */}
                {t('Display')}
              </span>

              <select
                className="form-control text-center"
                value={recordsPerPage}
                onChange={(e) => {
                  setRecordsPerPage(Number(e.target.value));
                  setCurrentPage(1); // إعادة التعيين للصفحة الأولى
                }}>
                <option className="text-dark" value={10}>
                  10
                </option>
                <option className="text-dark" value={20}>
                  20
                </option>
                <option className="text-dark" value={30}>
                  30
                </option>
                <option className="text-dark" value={40}>
                  40
                </option>
              </select>
              <span className="input-group-text fw-bold">{t('Records')}</span>
            </div>
          )}
        </div>
        <div className="col-lg-6"></div>

        {enableSearch && (
          <div className="col-lg-4">
            <div className="input-group flex-nowrap">
              <span className="input-group-text fw-bold">
                <i className="fa fa-search text-theme me-2"></i>
                {t('Search')}
              </span>
              <input
                type="search"
                autoComplete="off"
                className="form-control"
                placeholder={t('SearchPlaceholder')}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ paddingLeft: '2rem' }}
              />
            </div>
          </div>
        )}
      </div>

      {/* جدول البيانات */}
      <div className="table-responsive">
        <table className={tableClass || 'table table-hover'}>
          <thead className={theadClass || 'bg-theme bg-opacity-30'}>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  onClick={() => handleSort(column.key, column.sortable)}
                  style={{
                    cursor: column.sortable === false ? 'default' : 'pointer',
                    whiteSpace: 'nowrap',
                  }}>
                  {column.title}
                  <span style={{ display: 'inline-block', width: '1em' }}>
                    {sortConfig.key === column.key &&
                      column.sortable !== false &&
                      (sortConfig.direction === 'asc' ? (
                        <FaSortUp className="text-theme ms-2" />
                      ) : (
                        <FaSortDown className="text-theme ms-2" />
                      ))}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  <NoData />
                </td>
              </tr>
            ) : (
              currentData.map((row, rowIndex) => (
                <tr key={rowIndex} className={row.rowClass}>
                  {columns.map((column) => (
                    <td key={column.key} className={'align-middle ' + (column.className || '')}>
                      {row[column.key]}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        filteredData={sortedData}
        dataPerPage={recordsPerPage}
        handlePagination={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default NtxTable;
