// import ReportsBuilder from '../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder';

// import Workshop from '../../pages/FLEET/FleetSettings/Workshop/Index';
// import FleetLocations from '../../pages/FLEET/FleetSettings/Locations/Index';
// import UppdateWorkShop from '../../pages/FLEET/FleetSettings/Workshop/UppdateWorkShop';
// import EquipmentsModel from '../../pages/FLEET/FleetSettings/Manufacturer/Index';
// import EditManufacturer from '../../pages/FLEET/FleetSettings/Manufacturer/EditManufacturer';
// import Model from '../../pages/FLEET/FleetSettings/Model/Index';
// import EditModel from '../../pages/FLEET/FleetSettings/Model/EditModel';

// import FleetElementScan from '../../pages/FLEET/FleetScan/ScanElement/Index';
// import AddElementScan from '../../pages/FLEET/FleetScan/ScanElement/AddElement';
// import EquipmentInspection from '../../pages/FLEET/FleetScan/ScanEquipments/Index';
// import ScanningList from '../../pages/FLEET/FleetScan/ScanEquipments/ScanningList';
// import AddScan from '../../pages/FLEET/FleetScan/ScanEquipments/AddScan';
// import UpdateScan from '../../pages/FLEET/FleetScan/ScanEquipments/UpdateScan';
// import Equipments from '../../pages/FLEET/Equipments/Index';
// import Drivers from '../../pages/FLEET/FleetSettings/Drivers/Index';
// import Owners from '../../pages/FLEET/FleetSettings/Owners/Index';
// import Suppliers from '../../pages/FLEET/FleetSettings/Suppliers/Index';
// import EditEquipments from '../../pages/FLEET/Equipments/EditEquipments';
// import ExtraRecordEquipments from '../../pages/FLEET/Equipments/ExtraRecord/ExtraRecordEquipments';
// import EquipmentsAssignment from '../../pages/FLEET/EquipmentsAssignment/EquipmentsAssignment';
// import EquipmentsOdometer from '../../pages/FLEET/EquipmentsOdometer/EquipmentsOdometer';
// import AddEquipmentRoutes from '../../pages/FLEET/EquipmentRoutes/AddEquipmentRoutes';
// import WorkRequests from '../../pages/FLEET/WorkRequests/WorkRequestList';
// import AddWorkRequest from '../../pages/FLEET/WorkRequests/AddWorkRequest';
// import FLEETRequest from '../../pages/FLEET/FLEETRequest/FLEETRequest';
// import MaintenanceOrder from '../../pages/FLEET/MaintenanceOrder/Index';
// import AddOrderMaintenance from '../../pages/FLEET/MaintenanceOrder/AddOrderMaintenance';
// import EditEquipmentRoutes from '../../pages/FLEET/EquipmentRoutes/EditEquipmentRoutes';
// import EditDrivers from '../../pages/FLEET/FleetSettings/Drivers/EditDrivers';
// import EditOwners from '../../pages/FLEET/FleetSettings/Owners/EditOwners';
// import EditSuppliers from '../../pages/FLEET/FleetSettings/Suppliers/EditSuppliers';
// import CloseOrder from '../../pages/FLEET/MaintenanceOrder/CloseOrder';
// import AssignmentRoutes from '../../pages/FLEET/AssignmentRoutes/AssignmentRoutes';
// import FLEETReports from '../../pages/FLEET/FLEETReports/FLEETReports';
// import DashboardFLEET from '../../pages/FLEET/DashboardFLEET/DashboardFLEET';
// import UppdateLocations from '../../pages/FLEET/FleetSettings/Locations/UppdateLocations';

// import BuilderDashboard from '../../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard';

// const fleetRoutes = [
//   {
//     path: 'FLEETSettings/*',
//     children: [
//       { path: 'Workshop', element: <Workshop /> },
//       { path: 'Workshop/:id/Edit', element: <UppdateWorkShop /> },

//       { path: 'Locations', element: <FleetLocations /> },
//       { path: 'Locations/:id/Edit', element: <UppdateLocations /> },

//       { path: 'Manufacturers', element: <EquipmentsModel /> },
//       { path: 'Manufacturers/:id/Edit', element: <EditManufacturer /> },

//       { path: 'Model', element: <Model /> },
//       { path: 'Model/:id/Edit', element: <EditModel /> },

//       { path: 'FleetElementScan', element: <FleetElementScan /> },
//       {
//         path: 'FleetElementScan/:lookupCode/AddScanElement',
//         element: <AddElementScan />,
//       },

//       { path: 'Drivers', element: <Drivers /> },
//       { path: 'Drivers/:Id/Edit', element: <EditDrivers /> },

//       { path: 'Owners', element: <Owners /> },
//       { path: 'Owners/:Id/Edit', element: <EditOwners /> },

//       { path: 'Suppliers', element: <Suppliers /> },
//       { path: 'Suppliers/:Id/Edit', element: <EditSuppliers /> },
//     ],
//   },

//   {
//     path: 'FLEET/*',
//     children: [
//       { path: 'EquipmentInspection', element: <EquipmentInspection /> },
//       {
//         path: 'EquipmentInspection/:fleetEquipmentsId/AddScan',
//         element: <AddScan />,
//       },
//       {
//         path: 'EquipmentInspection/:fleetEquipmentsId/ScanningList',
//         element: <ScanningList />,
//       },
//       {
//         path: 'EquipmentInspection/:fleetEquipmentsId/ScanningList/:id/Edit',
//         element: <UpdateScan />,
//       },

//       { path: 'Equipments', element: <Equipments /> },
//       { path: 'Equipments/:Id/Edit', element: <EditEquipments /> },
//       { path: 'Equipments/:Id/ExtraRecordEquipments', element: <ExtraRecordEquipments /> },

//       {
//         path: 'Equipments/:Id/EquipmentsAssignation',
//         element: <EquipmentsAssignment />,
//       },
//       {
//         path: 'Equipments/:Id/EquipmentsOdometer',
//         element: <EquipmentsOdometer />,
//       },

//       {
//         path: 'Equipments/:Id/EquipmentRoutes',
//         element: <AddEquipmentRoutes />,
//       },
//       {
//         path: 'Equipments/:Id/EquipmentRoutes/:equipmentRoutesID/Edit',
//         element: <EditEquipmentRoutes />,
//       },
//       {
//         path: 'Equipments/:Id/EquipmentRoutes/:equipmentRoutesID/AssignmentRoutes',
//         element: <AssignmentRoutes />,
//       },

//       { path: 'WorkRequests', element: <WorkRequests /> },
//       {
//         path: 'WorkRequests/:Id/AddWorkRequest',
//         element: <AddWorkRequest />,
//       },
//       {
//         path: 'WorkRequests/:Id/AddMaintenanceOrder/:fleetEquipmentsId',
//         element: <AddOrderMaintenance />,
//       },

//       {
//         path: 'Maintenance/MaintenanceOrder',
//         element: <MaintenanceOrder />,
//       },
//       {
//         path: 'Maintenance/MaintenanceOrder/:Id/AddMaintenanceOrder/:fleetEquipmentsId',
//         element: <AddOrderMaintenance />,
//       },
//       {
//         path: 'Maintenance/MaintenanceOrder/:Id/CloseOrder',
//         element: <CloseOrder />,
//       },

//       { path: 'Reports', element: <FLEETReports /> },
//       {
//         path: 'Reports/:ReportId/ReportsBuilder',
//         element: <ReportsBuilder />,
//       },

//       { path: 'FleetDashboard', element: <DashboardFLEET /> },
//       { path: 'FleetDashboard/:dashboardId/BuilderDashboard', element: <BuilderDashboard /> },
//     ],
//   },

//   {
//     path: 'FLEET/Maintenance/Requests/*',
//     children: [{ path: '*', element: <FLEETRequest /> }],
//   },
// ];






import { lazy, Suspense } from 'react';
import LazyLoading from './LazyLoading/LazyLoading';

// Lazy loading components
const Workshop = lazy(() => import('../../pages/FLEET/FleetSettings/Workshop/Index'));
const FleetLocations = lazy(() => import('../../pages/FLEET/FleetSettings/Locations/Index'));
const UpdateWorkshop = lazy(() => import('../../pages/FLEET/FleetSettings/Workshop/UppdateWorkShop'));
const EquipmentsModel = lazy(() => import('../../pages/FLEET/FleetSettings/Manufacturer/Index'));
const EditManufacturer = lazy(() => import('../../pages/FLEET/FleetSettings/Manufacturer/EditManufacturer'));
const Model = lazy(() => import('../../pages/FLEET/FleetSettings/Model/Index'));
const EditModel = lazy(() => import('../../pages/FLEET/FleetSettings/Model/EditModel'));
const FleetElementScan = lazy(() => import('../../pages/FLEET/FleetScan/ScanElement/Index'));
const AddElementScan = lazy(() => import('../../pages/FLEET/FleetScan/ScanElement/AddElement'));
const EquipmentInspection = lazy(() => import('../../pages/FLEET/FleetScan/ScanEquipments/Index'));
const ScanningList = lazy(() => import('../../pages/FLEET/FleetScan/ScanEquipments/ScanningList'));
const AddScan = lazy(() => import('../../pages/FLEET/FleetScan/ScanEquipments/AddScan'));
const UpdateScan = lazy(() => import('../../pages/FLEET/FleetScan/ScanEquipments/UpdateScan'));
const Equipments = lazy(() => import('../../pages/FLEET/Equipments/Index'));
const Drivers = lazy(() => import('../../pages/FLEET/FleetSettings/Drivers/Index'));
const Owners = lazy(() => import('../../pages/FLEET/FleetSettings/Owners/Index'));
const Suppliers = lazy(() => import('../../pages/FLEET/FleetSettings/Suppliers/Index'));
const EditEquipments = lazy(() => import('../../pages/FLEET/Equipments/EditEquipments'));
const ExtraRecordEquipments = lazy(() => import('../../pages/FLEET/Equipments/ExtraRecord/ExtraRecordEquipments'));
const EquipmentsAssignment = lazy(() => import('../../pages/FLEET/EquipmentsAssignment/EquipmentsAssignment'));
const EquipmentsOdometer = lazy(() => import('../../pages/FLEET/EquipmentsOdometer/EquipmentsOdometer'));
const AddEquipmentRoutes = lazy(() => import('../../pages/FLEET/EquipmentRoutes/AddEquipmentRoutes'));
const WorkRequests = lazy(() => import('../../pages/FLEET/WorkRequests/WorkRequestList'));
const AddWorkRequest = lazy(() => import('../../pages/FLEET/WorkRequests/AddWorkRequest'));
const FLEETRequest = lazy(() => import('../../pages/FLEET/FLEETRequest/FLEETRequest'));
const MaintenanceOrder = lazy(() => import('../../pages/FLEET/MaintenanceOrder/Index'));
const AddOrderMaintenance = lazy(() => import('../../pages/FLEET/MaintenanceOrder/AddOrderMaintenance'));
const EditEquipmentRoutes = lazy(() => import('../../pages/FLEET/EquipmentRoutes/EditEquipmentRoutes'));
const EditDrivers = lazy(() => import('../../pages/FLEET/FleetSettings/Drivers/EditDrivers'));
const EditOwners = lazy(() => import('../../pages/FLEET/FleetSettings/Owners/EditOwners'));
const EditSuppliers = lazy(() => import('../../pages/FLEET/FleetSettings/Suppliers/EditSuppliers'));
const CloseOrder = lazy(() => import('../../pages/FLEET/MaintenanceOrder/CloseOrder'));
const AssignmentRoutes = lazy(() => import('../../pages/FLEET/AssignmentRoutes/AssignmentRoutes'));
const FLEETReports = lazy(() => import('../../pages/FLEET/FLEETReports/FLEETReports'));
const DashboardFLEET = lazy(() => import('../../pages/FLEET/DashboardFLEET/DashboardFLEET'));
const UpdateLocations = lazy(() => import('../../pages/FLEET/FleetSettings/Locations/UppdateLocations'));
const BuilderDashboard = lazy(() => import('../../pages/NTX/DashboardManagament/BuilderDashboard/BuilderDashboard'));
const ReportsBuilder = lazy(() => import('../../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder'));

const fleetRoutes = [
  {
    path: 'FLEETSettings/*',
    children: [
      { path: 'Workshop', element: <Suspense fallback={<LazyLoading />}><Workshop /></Suspense> },
      { path: 'Workshop/:id/Edit', element: <Suspense fallback={<LazyLoading />}><UpdateWorkshop /></Suspense> },
      { path: 'Locations', element: <Suspense fallback={<LazyLoading />}><FleetLocations /></Suspense> },
      { path: 'Locations/:id/Edit', element: <Suspense fallback={<LazyLoading />}><UpdateLocations /></Suspense> },
      { path: 'Manufacturers', element: <Suspense fallback={<LazyLoading />}><EquipmentsModel /></Suspense> },
      { path: 'Manufacturers/:id/Edit', element: <Suspense fallback={<LazyLoading />}><EditManufacturer /></Suspense> },
      { path: 'Model', element: <Suspense fallback={<LazyLoading />}><Model /></Suspense> },
      { path: 'Model/:id/Edit', element: <Suspense fallback={<LazyLoading />}><EditModel /></Suspense> },
      { path: 'FleetElementScan', element: <Suspense fallback={<LazyLoading />}><FleetElementScan /></Suspense> },
      { path: 'FleetElementScan/:lookupCode/AddScanElement', element: <Suspense fallback={<LazyLoading />}><AddElementScan /></Suspense> },
      { path: 'Drivers', element: <Suspense fallback={<LazyLoading />}><Drivers /></Suspense> },
      { path: 'Drivers/:Id/Edit', element: <Suspense fallback={<LazyLoading />}><EditDrivers /></Suspense> },
      { path: 'Owners', element: <Suspense fallback={<LazyLoading />}><Owners /></Suspense> },
      { path: 'Owners/:Id/Edit', element: <Suspense fallback={<LazyLoading />}><EditOwners /></Suspense> },
      { path: 'Suppliers', element: <Suspense fallback={<LazyLoading />}><Suppliers /></Suspense> },
      { path: 'Suppliers/:Id/Edit', element: <Suspense fallback={<LazyLoading />}><EditSuppliers /></Suspense> },
    ],
  },
  {
    path: 'FLEET/*',
    children: [
      { path: 'EquipmentInspection', element: <Suspense fallback={<LazyLoading />}><EquipmentInspection /></Suspense> },
      { path: 'EquipmentInspection/:fleetEquipmentsId/AddScan', element: <Suspense fallback={<LazyLoading />}><AddScan /></Suspense> },
      { path: 'EquipmentInspection/:fleetEquipmentsId/ScanningList', element: <Suspense fallback={<LazyLoading />}><ScanningList /></Suspense> },
      { path: 'EquipmentInspection/:fleetEquipmentsId/ScanningList/:id/Edit', element: <Suspense fallback={<LazyLoading />}><UpdateScan /></Suspense> },
      { path: 'Equipments', element: <Suspense fallback={<LazyLoading />}><Equipments /></Suspense> },
      { path: 'Equipments/:Id/Edit', element: <Suspense fallback={<LazyLoading />}><EditEquipments /></Suspense> },
      { path: 'Equipments/:Id/ExtraRecordEquipments', element: <Suspense fallback={<LazyLoading />}><ExtraRecordEquipments /></Suspense> },
      { path: 'Equipments/:Id/EquipmentsAssignation', element: <Suspense fallback={<LazyLoading />}><EquipmentsAssignment /></Suspense> },
      { path: 'Equipments/:Id/EquipmentsOdometer', element: <Suspense fallback={<LazyLoading />}><EquipmentsOdometer /></Suspense> },
      { path: 'Equipments/:Id/EquipmentRoutes', element: <Suspense fallback={<LazyLoading />}><AddEquipmentRoutes /></Suspense> },
      { path: 'Equipments/:Id/EquipmentRoutes/:equipmentRoutesID/Edit', element: <Suspense fallback={<LazyLoading />}><EditEquipmentRoutes /></Suspense> },
      { path: 'Equipments/:Id/EquipmentRoutes/:equipmentRoutesID/AssignmentRoutes', element: <Suspense fallback={<LazyLoading />}><AssignmentRoutes /></Suspense> },
      { path: 'WorkRequests', element: <Suspense fallback={<LazyLoading />}><WorkRequests /></Suspense> },
      { path: 'WorkRequests/:Id/AddWorkRequest', element: <Suspense fallback={<LazyLoading />}><AddWorkRequest /></Suspense> },
      { path: 'WorkRequests/:Id/AddMaintenanceOrder/:fleetEquipmentsId', element: <Suspense fallback={<LazyLoading />}><AddOrderMaintenance /></Suspense> },
      { path: 'Maintenance/MaintenanceOrder', element: <Suspense fallback={<LazyLoading />}><MaintenanceOrder /></Suspense> },
      { path: 'Maintenance/MaintenanceOrder/:Id/AddMaintenanceOrder/:fleetEquipmentsId', element: <Suspense fallback={<LazyLoading />}><AddOrderMaintenance /></Suspense> },
      { path: 'Maintenance/MaintenanceOrder/:Id/CloseOrder', element: <Suspense fallback={<LazyLoading />}><CloseOrder /></Suspense> },
      { path: 'FLEETRequest', element: <Suspense fallback={<LazyLoading />}><FLEETRequest /></Suspense> },
      { path: 'Reports', element: <Suspense fallback={<LazyLoading />}><FLEETReports /></Suspense> },
      { path: 'FleetDashboard', element: <Suspense fallback={<LazyLoading />}><DashboardFLEET /></Suspense> },
      { path: 'Reports/:ReportId/ReportsBuilder', element: <Suspense fallback={<LazyLoading />}><ReportsBuilder /></Suspense> },
      { path: 'FleetDashboard/:dashboardId/BuilderDashboard', element: <Suspense fallback={<LazyLoading />}><BuilderDashboard /></Suspense> },
    ],
  },
];


export default fleetRoutes;