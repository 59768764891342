import React, { useEffect, useRef } from 'react';

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css';
import 'datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import arLanguage from './translateAR.json';
import enLanguage from './translateEN.json';
const $ = require('jquery');
$.DataTable = require('datatables.net');
require('datatables.net-bs5');
require('datatables.net-buttons');
require('datatables.net-buttons/js/buttons.colVis.min.js');
require('datatables.net-buttons/js/buttons.html5.min.js');
require('datatables.net-buttons/js/buttons.print.min.js');
require('datatables.net-buttons-bs5');
require('datatables.net-responsive');
require('datatables.net-responsive-bs5');
require('datatables.net-fixedcolumns');
require('datatables.net-fixedcolumns-bs5');

const DataTableViewer = ({ DataReport }) => {
  const Tableheaders = Object.keys(DataReport?.reportData?.[0] || {});
  const tableRef = useRef();
  
  

  useEffect(() => {
    let tableInstance = null;
    if (DataReport?.reportData && DataReport?.reportData?.length > 0) {
      tableInstance = $(tableRef.current).DataTable({
        dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
        lengthMenu: [10, 20, 30, 40, 50],
        responsive: true,
        data: DataReport?.reportData,
        columns: Tableheaders?.map((header) => ({ data: header })), // Map columns to data properties
        // ordering: false,

        buttons: [
          //         {
          //           text: "Print",
          //           className: "btn btn-outline-default btn-sm ms-2",
          //           extend: "print",
          //           customize: function (win) {
          //             // Customize header and footer here

          //             const customHeader = `
          //     <tr >
          //         <th colspan="10">
          //             <table border="1" cellpadding="1" cellspacing="1" style="width:250px">

          //                     ${DataReport?.paramerersValues
          //                       ?.filter((f) => f.value !== null)
          //                       ?.map(
          //                         (item) =>
          //                           `<tr>
          //                                 <td>${item.label} :</td>
          //                                 <td>${item.value}</td>
          //                             </tr>`
          //                       )
          //                       }

          //             </table>
          //         </th>
          //     </tr>
          // `;

          //             $(win.document.body)?.find("thead").prepend(customHeader);
          //             var logo = new Image();
          //             logo.src =
          //               "https://marketplace.canva.com/EAFLhDqSu-c/1/0/1600w/canva-blue-and-yellow-modern-business-twitter-header-q-uFVr37CSY.jpg"; // Replace with the actual path to your logo image
          //             $(logo).css({
          //               width: "100%", // Adjust the width as needed
          //               height: "10%", // Maintain aspect ratio
          //               borderRadius: "20px",
          //             });

          //             $(win.document.body)
          //               ?.find("h1")
          //               .css("text-align", "center")
          //               .text(DataReport?.reportName);
          //             // $(win.document.body)?.find('h1').prepend(customHeader);
          //             // $(win.document.body).append('<h2>Custom Footer</h2>');

          //             $(win.document.body).css({
          //               border: "2px solid #000",
          //               borderRadius: "10px",
          //               padding: "5px",
          //             });
          //             // const customFooter = `
          //             // 	<div class="custom-footer">
          //             // 		<p>Custom Footer</p>
          //             // 	</div>
          //             // `;

          //             // Add the custom header and footer to the document
          //             // $(win.document.body).prepend(customHeader1);
          //             // $(win.document.body).append(customFooter);

          //             $(win.document.body).prepend(logo);

          //             // 	const customPrintStyles = `
          //             // 	<style>
          //             // 		@media print {
          //             // 			body {
          //             // 				-webkit-print-color-adjust: exact;
          //             // 				color-adjust: exact;
          //             // 			}
          //             // 			@page {
          //             // 				size: landscape;
          //             // 			}
          //             // 		}
          //             // 	</style>
          //             // `;
          //             // $(win.document.head).append(customPrintStyles); // Portrait
          //           },
          //         },
          { extend: 'csv', className: 'btn btn-outline-theme btn-sm' },
          { extend: 'colvis', className: 'btn btn-outline-theme btn-sm' },
          //,
          // {
          // 	extend: 'html5', // You can also use 'excel' for Excel format
          // 	className: 'btn btn-outline-default btn-sm'
          // 	// ,
          // 	// exportOptions: {
          // 	// 	columns: ':visible'
          // 	// }
          // }
        ],

        language: localStorage.language === 'ar' ? arLanguage : enLanguage, // Use local language files
        // language: {
        //   url:
        //     localStorage.language === 'ar'
        //       ? 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/ar.json'
        //       : 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/en-GB.json',
        // },
        initComplete: function () {
          if (localStorage.language === 'ar') {
            // Adjust specific CSS if RTL is used
            $('#datatableDefault_wrapper').attr('dir', 'rtl');
            $('th, td').css('text-align', 'right');
          } else {
            $('#datatableDefault_wrapper').attr('dir', 'ltr');
            $('th, td').css('text-align', 'left');
          }

          // تخصيص form-select طول الجدول
          $('.form-select').css({
            width: '60px',
          });
        },
      });

      return () => {
        if (tableInstance !== null) {
          tableInstance?.destroy();
        }
      };
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <table ref={tableRef} className="table table-hover text-nowrap w-100">
        <thead className=" ">
          <tr>
            {Tableheaders?.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {DataReport?.reportData?.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {Tableheaders?.map((header, cellIndex) => (
                <td key={cellIndex}>{rowData[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTableViewer;
