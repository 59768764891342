import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface BreadcrumbList {
  name: any;
  link: string | null;
}

interface Props {
  BreadcrumbList: BreadcrumbList[];
  PageName?: any;
  subtitle?: any;
}

const Breadcrumb: React.FC<Props> = ({ BreadcrumbList, PageName, subtitle }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          {BreadcrumbList?.map((item, idx) => (
            <li className="breadcrumb-item" key={idx}>
              {item.link !== null ? (
                <Link to={item.link} replace>
                  {t(item.name)}
                </Link>
              ) : (
                t(item.name)
              )}
            </li>
          ))}
        </ul>
        <h3 className=" mb-0">
          {t(PageName)}{' '}
          {subtitle && (
            <small className="fs-6 p-0 m-0 bg-theme bg-opacity-15 rounded-2 px-1 ">
              {' '}
              {subtitle}{' '}
            </small>
          )}
        </h3>
      </div>
    </div>
  );
};

export default Breadcrumb;
// page-header

// {/* <div className="d-flex p-0 m-0 bg-theme bg-opacity-15 rounded-0 p-1">
// رقم المرجع : {data.code}
// </div> */}
