import React, { Fragment } from 'react';
import AddDepartment from '../../../../HR/Departments/AddDepartment';

const AddInventory = () => {
  return (
    <Fragment>
      <AddDepartment type="130" />
    </Fragment>
  );
};

export default AddInventory;
