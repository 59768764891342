import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { TransactionTypesModels, TypeReasonsModels } from './TransactionTypesModels';

export const GetTransactionTypesList = createAsyncThunk(
  'TransactionTypes_Inv/GetTransactionTypesList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetTransactionTypesList');
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addReceiveTransactionTypeReasons = createAsyncThunk(
  'TransactionTypes_Inv/addReceiveTransactionTypeReasons',
  async (item: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ntxAPI.post('Inv/AddReceiveTransactionTypeReasons', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addIssueTransactionTypeReasons = createAsyncThunk(
  'TransactionTypes_Inv/addIssueTransactionTypeReasons',
  async (item: any, { rejectWithValue, dispatch }) => {
    try {
      debugger;
      const { data } = await ntxAPI.post('Inv/AddIssueTransactionTypeReasons', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateInvTransactionTypeReasons = createAsyncThunk(
  'TransactionTypes_Inv/updateInvTransactionTypeReasons',
  async (item: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ntxAPI.post('Inv/UpdateInvTransactionTypeReasons', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetTransactionTypeReasonsByTransactionTypeId = createAsyncThunk(
  'TransactionTypes_Inv/GetInvTransactionTypeReasonsByTransactionTypeId',
  async (TransactionTypeId : string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`Inv/GetInvTransactionTypeReasonsByTransactionTypeId?TransactionTypeId=${TransactionTypeId}`);
      if (data.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  TransactionTypesList: TransactionTypesModels[];
  TypeReasonsByTransactionTypeId: TypeReasonsModels[];
  loading: boolean;
  error: any | null;
} = {
  TransactionTypesList: [],
  TypeReasonsByTransactionTypeId: [],
  loading: false,
  error: null,
};

const TransactionTypesSlice = createSlice({
  name: 'TransactionTypes_Inv',
  initialState,
  reducers: {
    cleanUpTypeReasons: (state) => {
      state.TypeReasonsByTransactionTypeId = [];
    },
  },
  extraReducers(builder) {
    builder

      .addCase(GetTransactionTypesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetTransactionTypesList.fulfilled,
        (state, action: PayloadAction<TransactionTypesModels[]>) => {
          state.loading = false;
          state.TransactionTypesList = action.payload;
        }
      )
      .addCase(GetTransactionTypesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(GetTransactionTypeReasonsByTransactionTypeId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        GetTransactionTypeReasonsByTransactionTypeId.fulfilled,
        (state, action: PayloadAction<TypeReasonsModels[]>) => {
          state.loading = false;
          state.TypeReasonsByTransactionTypeId = action.payload;
        }
      )
      .addCase(
        GetTransactionTypeReasonsByTransactionTypeId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export const { cleanUpTypeReasons } = TransactionTypesSlice.actions;
export default TransactionTypesSlice.reducer;
