import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import {
  NoneInventoryModels,
  PurchaseBillModels,
  NoneInventoryByIdModels,
} from './purchaseBillModels';

export const addPurchaseBill = createAsyncThunk(
  'PurchaseBill/addPurchaseBill',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseBill/Add', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePurchaseBill = createAsyncThunk(
  'PurchaseBill/updatePurchaseBill',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseBill/Update', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchaseBillList = createAsyncThunk(
  'PurchaseBill/getPurchaseBillList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('PurchaseBill/GetList');
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPurchaseBillById = createAsyncThunk(
  'PurchaseBill/getPurchaseBillById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`PurchaseBill/GetById?Id=${id}`);
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionsById = createAsyncThunk(
  'PurchaseBill/getTransactionsById',
  async (id: string, { rejectWithValue }) => {
    try {
      // debugger
      const { data } = await ntxAPI.get(`PurchaseBill/GetTransactionsById?Id=${id}`);
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNoneInventoryList = createAsyncThunk(
  'PurchaseBill/getNoneInventoryList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('PurchaseBill/GetNoneInventoryList');
      if (data?.succeeded) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNoneInventoryById = createAsyncThunk(
  'PurchaseBill/getNoneInventoryById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(`PurchaseBill/GetNoneInventoryById`, {
        params: { Id: id },
      });
      if (data?.succeeded) {
        // ===
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const receiveNoneInventoryItem = createAsyncThunk(
  'PurchaseBill/receiveNoneInventoryItem',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post('PurchaseBill/ReceiveNoneInventoryItem', item);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  PurchaseBillList: PurchaseBillModels[];
  PurchaseBillById: PurchaseBillModels;
  TransactionsById: PurchaseBillModels;
  loading: boolean;
  error: any | null;

  NoneInventoryList: NoneInventoryModels[];
  NoneInventoryById: NoneInventoryByIdModels;
  loadingNoneInventoryById: boolean;
} = {
  PurchaseBillList: [],
  PurchaseBillById: {} as PurchaseBillModels,
  TransactionsById: {} as PurchaseBillModels,
  loading: false,
  error: null,

  NoneInventoryList: [],
  NoneInventoryById: {} as NoneInventoryByIdModels,
  loadingNoneInventoryById: false,
};

const PurchaseBillSlice = createSlice({
  name: 'PurchaseBill',
  initialState,
  reducers: {
    cleanUpPurchaseBillById: (state) => {
      state.PurchaseBillById = {} as PurchaseBillModels;
    },
    cleanUpTransactionsByIdById: (state) => {
      state.TransactionsById = {} as PurchaseBillModels;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getPurchaseBillList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchaseBillList.fulfilled,
        (state, action: PayloadAction<PurchaseBillModels[]>) => {
          state.loading = false;
          state.PurchaseBillList = action.payload;
        }
      )
      .addCase(getPurchaseBillList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getPurchaseBillById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPurchaseBillById.fulfilled,
        (state, action: PayloadAction<PurchaseBillModels>) => {
          state.loading = false;
          state.PurchaseBillById = action.payload;
        }
      )
      .addCase(getPurchaseBillById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getTransactionsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getTransactionsById.fulfilled,
        (state, action: PayloadAction<PurchaseBillModels>) => {
          state.loading = false;
          state.TransactionsById = action.payload;
        }
      )
      .addCase(getTransactionsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getNoneInventoryList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getNoneInventoryList.fulfilled,
        (state, action: PayloadAction<NoneInventoryModels[]>) => {
          state.loading = false;
          state.NoneInventoryList = action.payload;
        }
      )
      .addCase(getNoneInventoryList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getNoneInventoryById.pending, (state, action) => {
        state.loadingNoneInventoryById = true;
      })
      .addCase(
        getNoneInventoryById.fulfilled,
        (state, action: PayloadAction<NoneInventoryByIdModels>) => {
          state.loadingNoneInventoryById = false;
          state.NoneInventoryById = action.payload;
        }
      )
      .addCase(getNoneInventoryById.rejected, (state, action: PayloadAction<any>) => {
        state.loadingNoneInventoryById = false;
        state.error = action.payload;
      });
  },
});

export const { cleanUpPurchaseBillById, cleanUpTransactionsByIdById } = PurchaseBillSlice.actions;
export default PurchaseBillSlice.reducer;
