import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
// import { useFormik } from 'formik';
// import { AddSubinventory } from '../../../../../store/INV/SubInventory/SubInventorySlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
// import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import SubInventoryList from './SubInventoryList';
import { Card, CardBody, CardExpandToggler, CardHeader } from '../../../../../components/card/card';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import MedModalComponent from '../../../../../components/Modal/MedModalComponent';
// import EditSubInventory from './EditSubInventory';
// import Loading from '../../../../../components/Shared/Loader/Loading';
import {
  GetSubinventoryByParentId,
  cleanUpSubInventoryListByParentId,
} from '../../../../../store/INV/SubInventory/SubInventorySlice';
// import { SubInventorySchema } from '../../../../ValidationForm/validationSchema';
import { AppSettings } from '../../../../../config/app-settings';
import AddSubInventorybyParentId from './AddSubInventorybyParentId';
import AddSubInventorybySubInventoryParentId from './AddSubInventorybySubInventoryParentId';
import EditSubInventory from './EditSubInventory';
import { SubInventoryModels } from '../../../../../store/INV/SubInventory/SubInventoryModels';

const SubInventory = () => {
  const { t, i18n } = useTranslation();

  const [subInventoryId, setSubInventoryId] = useState<any>(null);
  const [subInventoryDetails, setSubInventoryDetails] = useState<SubInventoryModels | null>();

  // console.log(subInventoryDetails);

  const { Id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (Id) {
      dispatch(GetSubinventoryByParentId(Id));
    }
    return () => {
      dispatch(cleanUpSubInventoryListByParentId());
    };
  }, [Id]);
  const { SubInventoryListByParentId: data } = useAppSelector((state) => state.SubInventory);

  
  


  //#region context tree file-node.has-sub
  const context = useContext(AppSettings);
  useEffect(() => {
    const handleFileArrowClick = (event: any) => {
      event.preventDefault();
      const node = event.target.closest('.file-node.has-sub');
      if (node) {
        node.classList.toggle('expand');
      }
    };

    const handleFileInfoClick = (event: any) => {
      event.preventDefault();
      const node = event.target.closest('.file-node');
      if (node) {
        const fileInfoNodes = document.querySelectorAll('.file-node');
        fileInfoNodes.forEach((otherNode) => {
          if (otherNode !== node) {
            otherNode.classList.remove('selected');
          }
        });
        node.classList.add('expand');
        node.classList.add('selected');
      }
    };

    const fileHasSubNodes = document.querySelectorAll('.file-node.has-sub');
    fileHasSubNodes.forEach((node) => {
      const fileArrow = node.querySelector('.file-link > .file-arrow');
      fileArrow?.addEventListener('click', handleFileArrowClick);
    });

    const fileInfoNodes = document.querySelectorAll('.file-node');
    fileInfoNodes.forEach((node) => {
      const fileInfo = node.querySelector('.file-link > .file-info');
      fileInfo?.addEventListener('click', handleFileInfoClick);
    });

    context.setAppContentFullHeight(true);

    return function cleanUp() {
      context.setAppContentFullHeight(false);
      fileHasSubNodes.forEach((node) => {
        const fileArrow = node.querySelector('.file-link > .file-arrow');
        fileArrow?.removeEventListener('click', handleFileArrowClick);
      });
      fileInfoNodes.forEach((node) => {
        const fileInfo = node.querySelector('.file-link > .file-info');
        fileInfo?.removeEventListener('click', handleFileInfoClick);
      });
    };
  }, [data, context]);

  //#endregion

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'المخازن',
      link: '/INV/InventorySettings/Inventory',
    },
    {
      name: 'المخازن الفرعية',
      link: null,
    },
  ];

  const location = useLocation();
  const { name } = location?.state;

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName="المخازن الفرعية"
          subtitle={name}
        />
        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddSubInventory"
            onClick={() => setSubInventoryDetails(null)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('إضـافة مخزن فرعي')}
            {/* {name} */}
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center ">
              <span className=" fw-bold m-1">
                المخازن الفرعية لـ
                {/* {name} */}
              </span>

              <CardExpandToggler />
            </CardHeader>

            <CardBody>
              <div className="file-manager-sidebar-content">
                <div className="file-tree">
                  {data
                    ?.filter((f) => f.subinventoryParentId === null)
                    ?.map((tree, idx) => {
                      return (
                        <ItemTree
                          key={tree.id}
                          tree={tree}
                          setTreeDetails={setSubInventoryDetails}
                          setSubInventoryId={setSubInventoryId}
                        />
                      );
                    })}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-lg-6">
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center ">
              <span className=" fw-bold m-1">التفاصيل</span>

              <CardExpandToggler />
            </CardHeader>

            <CardBody>
              {subInventoryDetails && (
                <EditSubInventory subInventoryDetails={subInventoryDetails} />
              )}
            </CardBody>
          </Card>
        </div>
      </div>

      {/* <MedModalComponent id="EditSubInventory" title={t('Edit')}>
        {subInventoryId && <EditSubInventory Id={subInventoryId} />}
      </MedModalComponent> */}

      <MedModalComponent id="modalAddSubInventory" title={`اضافة مخزن فرعي`}>
        {<AddSubInventorybyParentId />}
      </MedModalComponent>

      <MedModalComponent
        id="modalAddSubInventory2"
        title={`اضافة مخزن فرعي ${subInventoryDetails?.name2 || ''}`}>
        {<AddSubInventorybySubInventoryParentId subinventoryParentId={subInventoryId} />}
      </MedModalComponent>
    </>
  );
};

export default SubInventory;

//_______________________________________________________________________________________________

type PropsItemTree = {
  tree: SubInventoryModels;
  setTreeDetails: React.Dispatch<any>;
  setSubInventoryId: React.Dispatch<React.SetStateAction<any>>;
};
const ItemTree: React.FC<PropsItemTree> = ({ tree, setTreeDetails, setSubInventoryId }) => {
  const { SubInventoryListByParentId: data } = useAppSelector((state) => state.SubInventory);

  const { i18n } = useTranslation();
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <Fragment key={tree.id}>
      <div className="d-flex justify-content-between p-1 border-1 border-end border-theme ">
        <div
          className={`file-node ${
            data?.filter((f) => f.subinventoryParentId === tree?.id)?.length && 'has-sub'
          }`}>
          {/* className={`file-node `}> */}
          <Link to={``} className="file-link">
            <span className="file-arrow"></span>
            <span
              ref={linkRef}
              className="file-info ms-1"
              onClick={() => {
                setTreeDetails(tree);
                setSubInventoryId(tree.id);
              }}>
              <span className="file-icon">
                <i className='bi bi-stack fa-lg text-theme'></i>
              </span>
              <span className="file-text fs-6">
                {i18n.language === 'en' ? tree.name : tree.name2}
              </span>
            </span>
          </Link>

          <div className="file-tree">
            {data
              ?.filter((node) => node.subinventoryParentId === tree.id)
              ?.map((node, idx) => {
                return (
                  <ItemTree
                    key={node?.id}
                    tree={node}
                    setTreeDetails={setTreeDetails}
                    setSubInventoryId={setSubInventoryId}
                  />
                );
              })}
          </div>
        </div>

        <div className="pt-1">
          <Link
            // to=""
            to={''}
            data-bs-toggle="modal"
            data-bs-target="#modalAddSubInventory2"
             onClick={() => {
              linkRef?.current?.click();
              setSubInventoryId(tree.id);
            }}>
            <i className="fas fa-lg fa-fw  fs-4 bi bi-plus-square-fill"></i>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};
