import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { TreeSelect } from 'antd';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetPaymentTermsList';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import ArReceiptSchedules from './ArReceiptSchedules';
import ArTransactionsLine from './ArTransactionsLine';
import { AddArTransactionSchema } from '../../../ValidationForm/validationSchema';
import { useEffect, useState } from 'react';
import useGetUnApplyPrepayments from '../../../../hooks/AR/ArReceipts/useGetUnApplyPrepayments';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import {
  updatArTransactions,
  getArTransactionsById,
} from '../../../../store/Finance/AR/ArTransactions/ArTransactionsSlice';
import useGetPeopleLocationsList from '../../../../hooks/NTX/Peoples/useGetPeopleLocationsList';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import useGetCodeCombination from '../../../../hooks/Finance/ChartAccountsDetails/useGetCodeCombination';
import { UnApplyPrepaymentsModels } from '../../../../store/Finance/AP/ApPayments/ApPaymentsModels';

const Update = () => {
  const { transactionId } = useParams();

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dataEmployees } = useGetPersons();
  const { Currency, loading: loading4 } = useLookupCurrency();
  const { BranchOrgOptions, loading } = useGetBranchOrgWithPermission();
  const { treeCustomerData, loading: loading2, OptionCustomers } = useGetCustomersList();
  const { PaymentTermsList } = useGetPaymentTermsList();
  const { PaymentMethodList } = useGetPaymentMethod();
  const { GetCodeCombination } = useGetCodeCombination();

  useEffect(() => {
    if (transactionId) {
      dispatch(getArTransactionsById(transactionId));
    }
  }, [dispatch, transactionId]);
  const { ArTransactionsById: data } = useAppSelector((a) => a.ArTransactions);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      transactionNum: data.transactionNum ?? '',
      peopleId: data.peopleId ?? '',
      transactionType: data.transactionType ?? '',
      referenceType: data.referenceType ?? '',
      referenceId: data.referenceId ?? '',
      transactionDate: data.transactionDate?.toString()?.split('T')[0] ?? '',
      organizationId: data.organizationId ?? '',
      paymentTermsId: data.paymentTermsId ?? '',
      paymentMethodId: data.paymentMethodId ?? '',
      currencyCode: data.currencyCode ?? '',
      currencyConversionRate: data.currencyConversionRate ?? '',
      includeVatFlag: data.includeVatFlag ?? '',

      salesrepId: data.salesrepId ?? '',
      billToCustomerId: data.billToCustomerId ?? '',
      shipToCustomerId: data.shipToCustomerId ?? '',

      arTransactionLinesList:
        data.arTransactionLinesList?.map((item) => ({
          id: item.id,
          lineNumber: item.lineNumber,
          lineType: item.lineType,
          invItemId: item.invItemId,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          currencyCode: data.currencyCode ?? '',
          currencyConversionRate: data.currencyConversionRate ?? '',
          purHeaderId: null,
          purLineId: null,
          accountId: item.accountId,
          costCenterId: item.costCenterId,
          branchId: item.glBrunchId,
          companyId: item.glCompanyId,
          // branchId: GetGlBrancheId(data.organizationId),
          // companyId: GlCompanyId,
          //
          codeCombination: GetCodeCombination(
            item.glCompanyId,
            item.glBrunchId,
            item.accountId,
            item.costCenterId
          ),
        })) ?? [],

      arReceiptSchedulesList:
        data.arReceiptSchedulesList?.map((item) => ({
          id: item.id,
          amount: item.amount,
          dueDate: item.dueDate?.split('T')[0],
          paymentId: item.receiptId,
          invoicePaymentId: item.transactionReceiptId,
          // PaymentAmountUnApply: item.amountUnApply, // مبلغ الدفعة المتبقي - لمنع الممستخدم من إدخال اكبر منه
          // PaymentAmountUnApply: UnApplyPrepaymentsList?.find((f) => f.id === item.paymentId)
          //   ?.amountUnApply,
          paymentCode: item.codeReceipt, // رقم الدفعة
          paymentDate: new Date(item.receiptDate).toLocaleDateString(), // تريخ الدقعة
          paymentAmount: item.amountReceipt,
        })) ?? [],
    },

    enableReinitialize: true,
    validationSchema: AddArTransactionSchema(t),
    onSubmit: (values) => {
      const { arTransactionLinesList, arReceiptSchedulesList } = values;

      if (arTransactionLinesList?.length === 0) {
        SwalAlert({ text: 'لا يمكن متابعة العملية بدون إضافة بنود للفاتورة، يرجى إضافة البنود.' });
        formik.setSubmitting(false);
        return;
      }

      const totalTransactionLines = arTransactionLinesList?.reduce(
        (sum: number, item: any) => sum + item.total,
        0
      );
      const totalReceiptSchedules = arReceiptSchedulesList?.reduce(
        (sum: number, payment: any) => sum + Number(payment.amount),
        0
      );

      if (arReceiptSchedulesList?.length > 0 && totalTransactionLines !== totalReceiptSchedules) {
        SwalAlert({ text: 'لا يتطابق مجموع الفاتورة مع مجموع الجدولة. يرجى المراجعة.' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        updatArTransactions({
          id: data.id,
          transactionNum: values.transactionNum,
          transactionType: values.transactionType,
          paymentTermsId: values.paymentTermsId,
          paymentMethodId: values.paymentMethodId,
          salesrepId: values.salesrepId,
          transactionDate: values.transactionDate,
          billToCustomerId: values.billToCustomerId,
          shipToCustomerId: values.shipToCustomerId,
          transactionAmount: 0,
          dueDate: null,
          vatAmount: 0,

          // arTransactionLinesList: values.arTransactionLinesList?.map((line: any, idx) => ({
          transactionsLinesList: values.arTransactionLinesList?.map((line: any, idx) => ({
            id: line.id,
            lineNumber: ++idx,
            lineType: line.lineType,
            invItemId: line.invItemId,
            uom: '5cc5ff20-b711-4e46-a9ad-0f6de4b81b1a',
            // uom: line.uom || null,
            description: line.description,
            price: line.price || 0,
            qty: line.qty || 0,
            discountRate: line.discountRate || 0,
            discountAmount: line.discountAmount || 0,
            vatRate: line.vatRate || 0,
            total: line.total,
            vatAmount: line.vatAmount || 0,
            accountId: line.accountId,
            costCenterId: line.costCenterId,
            currencyCode: data.currencyCode || '',
            currencyConversionRate: data.currencyConversionRate || '',
            unitCost: 0,
            salHeaderId: null,
            salLineId: null,
          })),

          // arReceiptSchedulesList:
          receiptSchedulesList:
            values.arReceiptSchedulesList?.map((item: any) => ({
              transectionReceiptId: item.invoicePaymentId,
              id: item.id,
              amount: item.amount,
              dueDate: item.dueDate,
              receiptId: item.paymentId,
            })) || null,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
          } else {
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          CustomAlert({ action: 'Error', msg: error });
        })
        .finally(() => formik.setSubmitting(false));
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const { hasPrepayments, totalPrepaymentAmount, UnApplyPrepaymentsList } =
    useGetUnApplyPrepayments({
      PeopleId: values.peopleId,
    });

  const { billToLocation, shipToLocation } = useGetPeopleLocationsList({
    peopleId: values.peopleId,
  });

  // حساب المبلغ المتبقي للدفعة المقدمة إن وجدت
  useEffect(() => {
    const updatedPayments = formik.values.arReceiptSchedulesList.map((item) => {
      const paymentAmountUnApply = item.paymentId
        ? UnApplyPrepaymentsList?.find((f) => f.id === item.paymentId)?.amountUnApply ?? 0
        : 0;

      return {
        ...item,
        // PaymentAmountUnApply: paymentAmountUnApply,
        PaymentAmountUnApply: paymentAmountUnApply + (item.amount ?? 0),
        // يساوي المبلغ المبتقي + المبلغ المدخل سابقا
      };
    });

    formik.setFieldValue('arReceiptSchedulesList', updatedPayments);
  }, [UnApplyPrepaymentsList]);

  // تحديث القيمة المتبقية من قائمة الدفعات المتبقية ان وجدت
  const [unApplyPrepayments, setUnApplyPrepayments] = useState<UnApplyPrepaymentsModels[]>([]);
  useEffect(() => {
    const updatedPrepayments = UnApplyPrepaymentsList.map((prepayment) => {
      const matchingItem = data?.arReceiptSchedulesList.find(
        (item) => item.receiptId === prepayment.id
      );

      if (matchingItem) {
        const paymentAmountUnApply = matchingItem.receiptId ? prepayment.amountUnApply ?? 0 : 0;

        // تعديل القيمة بناءً على paymentAmountUnApply و amount
        return {
          ...prepayment,
          amountUnApply: paymentAmountUnApply + (matchingItem.amount ?? 0),
        };
      }

      return prepayment;
    });

    // تعيين القائمة المعدلة
    setUnApplyPrepayments(updatedPrepayments);
  }, [UnApplyPrepaymentsList, data?.arReceiptSchedulesList]);

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'معاملات الإستحقاق',
      link: '/Finance/Ar/ArTransactions',
    },
    {
      name: 'تفاصيـل فاتورة الإستحقاق',
      link: null,
    },
  ];
  //#endregion

  console.log(values);

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName="تقاصيــل فاتورة الإستحقاق"
          subtitle={OptionCustomers?.find((f) => f.id === values.peopleId)?.label}
        />
      </div>

      <Card>
        <Loading loading={loading || loading2 || loading || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control "
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />

                    <div className="text-danger small">
                      {touched.transactionDate && errors.transactionDate}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('نوع الفاتورة')} </label>
                    <Form.Select
                      disabled
                      className="form-select"
                      name="invoiceType"
                      onChange={formikhandleChange}
                      value={values.transactionType || ''}
                      // isInvalid={!!(touched.transactionType && errors.transactionType)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      <option value="Standard">
                        {i18n.language === 'en' ? 'Standard' : 'قياسي'}
                      </option>
                      <option value="DebitMemo">
                        {i18n.language === 'en' ? 'Debit Memo' : 'إشعار مدين'}
                      </option>
                      {/* <option value="Recurring">
                        {i18n.language === 'en' ? 'Recurring' : 'دوري'}
                      </option> */}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.transactionType && errors.transactionType}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      isDisabled
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      options={BranchOrgOptions}
                      value={BranchOrgOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      // onChange={(option: any) => handleChangeOrganizationId(option)}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.organizationId && errors.organizationId}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميل')}</label>
                    <TreeSelect
                      disabled
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeCustomerData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger small">{touched.peopleId && errors.peopleId}</div>
                  </div>
                </div>

                {/* 'موقع الشـحن' */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('موقع الشـحن')} </label>
                    <Form.Select
                      className="form-select"
                      name="shipToCustomerId"
                      onChange={formikhandleChange}
                      value={values.shipToCustomerId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {shipToLocation &&
                        shipToLocation?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {formik.touched.shipToCustomerId && formik.errors.shipToCustomerId}
                    </div>
                  </div>
                </div>

                {/* موقع الفوترة' */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('موقع الفوترة')} </label>
                    <Form.Select
                      className="form-select"
                      name="billToCustomerId"
                      onChange={formikhandleChange}
                      value={values.billToCustomerId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {billToLocation &&
                        billToLocation?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {formik.touched.billToCustomerId && formik.errors.billToCustomerId}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group flex-nowrap mb-3">
                    <label className="form-label">{t('رقم فاتورة العميل')}</label>

                    <div className="input-group flex-nowrap">
                      <Form.Control
                        autoComplete="off"
                        className="form-control  text-center"
                        type="text"
                        name="transactionNum"
                        onChange={formikhandleChange}
                        value={values.transactionNum || ''}
                        // isInvalid={!!(touched.transactionNum && errors.transactionNum)}
                      />
                    </div>
                    <div className="text-danger small">
                      {touched.transactionNum && errors.transactionNum}
                    </div>
                  </div>
                </div>

                {/* 'مندوب المبيعات' */}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مندوب المبيعات')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="salesrepId"
                      options={dataEmployees}
                      value={dataEmployees?.find((f: any) => f.value === formik.values.salesrepId)}
                      onChange={(option: any) => {
                        formik.setFieldValue('salesrepId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.salesrepId && errors.salesrepId}
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">شروط الدفع</label>
                    <Form.Select
                      className="form-select"
                      name="paymentTermsId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentTermsId || ''}
                      // isInvalid={!!(formik.errors.paymentTermsId && formik.touched.paymentTermsId)}
                    >
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentTermsId && errors.paymentTermsId}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId || ''}
                      // isInvalid={
                      //   !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      // }
                    >
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentMethodId && errors.paymentMethodId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.currencyCode && errors.currencyCode}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('معدل العملة')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="text"
                      autoComplete="off"
                      readOnly
                      // name="currencyConversionRate"
                      // onChange={formikhandleChange}
                      value={values.currencyConversionRate || ''}
                    />
                    <div className="text-danger small">
                      {touched.currencyConversionRate && errors.currencyConversionRate}
                    </div>
                  </div>
                </div>
              </div>

              <div className="alert alert-primary" hidden={!hasPrepayments}>
                <i className="fas fa-exclamation-circle me-2"></i>
                <strong>تنبيه:</strong> هذا العميـل لديه دفعات مقدمة بإجمالي:
                <span className="ms-2 text-success  fw-bold fs-5">{totalPrepaymentAmount}</span>
              </div>
              <CardFooter className="mt-2 mb-2">
                <ul className="nav nav-tabs nav-tabs-v2 p-0">
                  <li className="nav-item me-3">
                    <Link to="#apInvoiceLines" className="nav-link active" data-bs-toggle="tab">
                      <i className="fas fa-file-invoice me-2 text-theme"></i>
                      بنود الفاتورة
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link to="#apPaymentSchedules" className="nav-link" data-bs-toggle="tab">
                      <i className="fas fa-calendar-alt me-2 text-theme"></i>
                      جدولة الفاتورة
                    </Link>
                  </li>
                </ul>

                <div className="tab-content py-4 px-0">
                  <div className="tab-pane fade show active" id="apInvoiceLines">
                    <ArTransactionsLine formik={formik} />
                  </div>
                  <div className="tab-pane fade" id="apPaymentSchedules">
                    <ArReceiptSchedules
                      formik={formik}
                      UnApplyPrepaymentsList={unApplyPrepayments}
                      // UnApplyPrepaymentsList={UnApplyPrepaymentsList}
                    />
                  </div>
                </div>
                {formik.errors.arTransactionLinesList?.length && (
                  <div className="text-danger"> * بنود الفاتورة مطلوب </div>
                )}
                {formik.errors.arReceiptSchedulesList?.length && (
                  <div className="text-danger"> * جدولة الفاتورة مطلوب </div>
                )}
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fa fa-save fa-fw me-1"></i>
                  )}
                  {t('Save')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/Finance/Ar/ArTransactions', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default Update;
