import { useFormik } from 'formik';
import React from 'react';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import { invoicesCreateAccount } from '../../../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';
import { useAppDispatch } from '../../../../../store/hooks';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { CreateAccountSchema } from '../../../../ValidationForm/validationSchema';
import useGetGlLedgersList from '../../../../../hooks/Finance/useGetGlLedgersList';
import useGetGlPeriodsList from '../../../../../hooks/Finance/GlPeriods/useGetGlPeriodsList';
import { Form } from 'react-bootstrap';
import { ReceiptsCreateAccount } from '../../../../../store/Finance/AR/ArReceipts/ArReceiptsSlice';
import useCheckDateOnPeriod from '../../../../../hooks/Finance/GlPeriods/useCheckDateOnPeriod';
import SwalAlert from '../../../../../components/Shared/Alert/SwalAlert';

interface Props {
  selectedApPayments: any[];
}

const AddCreateAccountArReceipts = ({ selectedApPayments }: Props) => {
  const { t, i18n } = useTranslation();
  const { OptionsLedgers, loading } = useGetGlLedgersList();
  const { GlPeriods } = useGetGlPeriodsList();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      ledgerId: '',
      periodId: '',
      glDate: '',
    },
    validationSchema: CreateAccountSchema(t),
    onSubmit: (values) => {
      let ids = selectedApPayments.map((m) => m.id);

      const body = {
        transections: ids,
        periodId: values.periodId,
        ledgerId: values.ledgerId,
        glDate: values.glDate,
      };

      if (!isDateValid) {
        SwalAlert({ text: 'التاريـخ ليس ضمن الفترة المحاسبية' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(ReceiptsCreateAccount(body))
        .unwrap()
        .then((res) => {
          if (res.succeeded) {
            CustomAlert({
              action: 'info',
              msg: `تم الترحيـــل بنجاح `,
            });
          } else {
            CustomAlert({
              action: 'Error',
              msg: res.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error', msg: error });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const isDateValid = useCheckDateOnPeriod(formik.values.glDate, formik.values.periodId);

  return (
    <>
      <div className="table-responsive   mb-2">
        <table className="table  table-sm table-bordered">
          <thead className="bg-theme bg-opacity-10 ">
            <tr>
              <th className="pt-2 pb-2">رقم السند</th>
              <th className="pt-2 pb-2">العميــل</th>
              <th className="pt-2 pb-2">{t('Date')}</th>
              <th className="pt-2 pb-2">المبلغ</th>
            </tr>
          </thead>

          <tbody>
            {selectedApPayments?.map((item, index) => (
              <tr key={item.id}>
                <td className="align-middle">{item.code}</td>
                <td className="align-middle">
                  {i18n.language === 'en' ? item.peopleName : item.peopleName2}
                </td>
                <td className="align-middle">{item.paymentDate?.toString()?.split('T')[0]} </td>
                <td className="align-middle">{item.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('الإستاذ العام')} </label>
              <Select
                classNamePrefix="react-select"
                className="w-100 text-center "
                isSearchable={true}
                isClearable
                options={OptionsLedgers}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                // value={formik.values.ledgerId}
                onChange={(option) => formik.setFieldValue('ledgerId', option ? option.value : '')}
              />
              {formik.errors.ledgerId && formik.touched.ledgerId && (
                <div className="text-danger">{formik.errors.ledgerId}</div>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('AccountingPeriod')} </label>
              <Form.Select
                className="form-select form-select-lg text-center"
                name="periodId"
                value={formik.values.periodId}
                onChange={formik.handleChange}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {GlPeriods &&
                  GlPeriods?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
              {formik.errors.periodId && formik.touched.periodId && (
                <div className="text-danger">{formik.errors.periodId}</div>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('التاريـخ')} </label>
              <Form.Control
                className="form-control  form-control-lg  text-center"
                type="date"
                name="glDate"
                value={formik.values.glDate}
                onChange={formik.handleChange}
              />
              {formik.errors.glDate && formik.touched.glDate && (
                <div className="text-danger">{formik.errors.glDate}</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <button
              type="submit"
              className="btn btn-lg btn-theme  w-100 rounded-0"
              disabled={formik.isSubmitting || !formik.isValid}>
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw me-2 fa-check-circle"></i>
              )}
              {t('ترحيـل')}
            </button>
          </div>
          <div className="col-6">
            <button
              type="button"
              className="btn btn-lg  btn-default   w-100 rounded-0"
              data-bs-dismiss="modal">
              <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddCreateAccountArReceipts;
