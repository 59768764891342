import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../../../components/Shared/Alert/CustomAlert';
import useGetUnitsOfMeasureList from '../../../../../../hooks/INV/useGetUnitsOfMeasureList';
import { useParams } from 'react-router-dom';
import Loading from '../../../../../../components/Shared/Loader/Loading';
import { LotItemSchema } from '../../../../../ValidationForm/validationSchema';
import {
  addLotDetails,
  getLotDetailsListByItemId,
} from '../../../../../../store/INV/MasterItems/LotItemSlice';
import useGetLotsItemByItemId from '../../../../../../hooks/INV/LotItems/useGetLotsItemByItemId';
import { LotItemModel } from '../../../../../../store/INV/MasterItems/MasterItemsModels';
import NeatixModal from '../../../../../../components/Modal/NeatixModal';
import { Card, CardBody } from '../../../../../../components/card/card';
import UpdateLotItem from './UpdateLotItem';
import useGetItemConversionByItemId from '../../../../../../hooks/INV/ItemConversion/useGetItemConversionByItemId';

const LotItem: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { ItemId } = useParams<{ ItemId: string }>();

  const [showModal, setShowModal] = useState(false);
  const [LotDetiles, setLotDetiles] = useState<LotItemModel>();
  const dispatch = useAppDispatch();

  const {
    ItemConversionListByItem,
     error: error1,
  } = useGetItemConversionByItemId(ItemId ?? '');

  const { LotDetailsListByItem, loading, error } = useGetLotsItemByItemId(ItemId ?? '');


  const formik = useFormik({
    initialValues: {
      itemId: ItemId,
      lotNumber: '',
      unitOfMeasure: '',
      expirationDate: '',
    },

    validationSchema: LotItemSchema(t),

    onSubmit: (values) => {
      dispatch(
        addLotDetails({
          itemId: ItemId,
          lotNumber: values.lotNumber,
          unitOfMeasure: values.unitOfMeasure,
          expirationDate: values.expirationDate,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: 'Add' });
            dispatch(getLotDetailsListByItemId(ItemId || ''));
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <>
      <Loading loading={loading } error={error1 || error}>
        <div>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row align-items-end">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('lotNumber')}</label>
                  <Form.Control
                    autoComplete="off"
                    className="form-control"
                    type="text"
                    name="lotNumber"
                    onChange={formik.handleChange}
                    value={formik.values.lotNumber}
                    isInvalid={!!(formik.touched.lotNumber && formik.errors.lotNumber)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.lotNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('الوحدة')}</label>
                  <Form.Select
                    className="form-select"
                    name="unitOfMeasure"
                    onChange={formik.handleChange}
                    value={formik.values.unitOfMeasure || ''}
                    isInvalid={!!(formik.touched.unitOfMeasure && formik.errors.unitOfMeasure)}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {ItemConversionListByItem &&
                      ItemConversionListByItem?.map((item, idx) => (
                        <option key={++idx} value={item.uom}>
                          {i18n.language === 'en' ? item.uomName : item.uomName2}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.unitOfMeasure}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('تاريخ الإنتهاء')}</label>
                  <Form.Control
                    className="form-control"
                    type="date"
                    name="expirationDate"
                    onChange={formik.handleChange}
                    value={formik.values.expirationDate}
                    isInvalid={!!(formik.touched.expirationDate && formik.errors.expirationDate)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.expirationDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3 text-end  ">
                <div className=" form-group mb-3 d-flex justify-content-end ">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme   form-control w-100">
                    {formik.isSubmitting ? (
                      <div className="spinner-border spinner-border-sm me-2"></div>
                    ) : (
                      <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                    )}
                    {t('Add')}
                  </button>
                </div>
              </div>
            </div>
          </Form>

          <div className="table-responsive  rounded-2">
            <table className="table table-sm table-hover text-nowrap text-center">
              {/* <thead className="table-dark"> */}
              <thead className="fw-bold">
                <tr>
                  <th scope="col">{t('lotNumber')}</th>
                  <th scope="col">{t('الوحدة')}</th>
                  <th scope="col">{t('تاريخ الإنتهاء')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {LotDetailsListByItem &&
                  LotDetailsListByItem?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">{item.lotNumber}</td>
                      <td className="align-middle">
                        {i18n.language === 'en'
                          ? ItemConversionListByItem?.find((f) => f.uom === item.unitOfMeasure)
                              ?.uomName
                          : ItemConversionListByItem?.find((f) => f.uom === item.unitOfMeasure)
                              ?.uomName2}
                      </td>

                      <td className="align-middle">
                        {item.expirationDate?.toString()?.split('T')[0]}
                      </td>
                      <td className="align-middle text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-theme"
                          onClick={() => {
                            setLotDetiles(item);
                            setShowModal(true);
                          }}>
                          <i className="far fa-lg fa-fw  fa-edit  "></i>
                          {t('Button.Edit')}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Loading>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        size="md"
        bodyClassName="p-0"
        showHeader={false}>
        <Card>
          <CardBody>
            {LotDetiles && <UpdateLotItem data={LotDetiles} onClose={() => setShowModal(false)} />}
          </CardBody>
        </Card>
      </NeatixModal>
    </>
  );
};

export default LotItem;
