import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import PopoverCustom from '../../Shared/Popover/PopoverCustom';
import { useDispatch } from 'react-redux';
import { refreashToken } from '../../../store/Login/loginSlice';
import CustomAlert from '../../Shared/Alert/CustomAlert';
import 'animate.css';


const TimerToken = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userLogin?.AuthUser?.token);

  // Memoize the expiration time calculation
  const expirationTime = useMemo(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      return decodedToken.exp * 1000; // Convert seconds to milliseconds
    }
    return 0;
  }, [token]);

  const [timeLeft, setTimeLeft] = useState(expirationTime - Date.now());

  useEffect(() => {
    if (expirationTime > 0) {
      // Update time left every second
      const timer = setInterval(() => {
        const newTimeLeft = expirationTime - Date.now();
        setTimeLeft(newTimeLeft);

        // If time left is zero or less, clear the interval
        if (newTimeLeft <= 0) {
          clearInterval(timer);
          // Handle token expiration (e.g., log out the user, redirect, etc.)
        }
      }, 1000); // Update every second

      // Cleanup interval on component unmount
      return () => clearInterval(timer);
    }
  }, [expirationTime]);

  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeLeft / 1000 / 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (expirationTime > 0) {
      const timer = setInterval(() => {
        const newTimeLeft = expirationTime - Date.now();
        setTimeLeft(newTimeLeft);

        if (newTimeLeft <= 0) {
          clearInterval(timer);

          Swal.fire({
            title: 'انتهت صلاحية الجلسة',
            text: 'لقد انتهت صلاحية جلستك. هل ترغب في تجديد بيانات الدخول أو تسجيل الخروج؟',
            showCancelButton: true,
            confirmButtonText: '<i class="fa fa-lg fa-redo  me-2 "></i> تجديد بيانات الدخول',
            cancelButtonText: '<i class="fa fa-lg fa-sign-out-alt me-2"></i> تسجيل الخروج',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
            },
     
            background: 'var(--bs-body-bg)',
            color: 'var(--bs-heading-color)',
            customClass: {
              popup: 'border border-theme border-3 rounded-4',
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(refreashToken())
                .unwrap()
                .then((res) => {
                  CustomAlert({
                    action: 'Add',
                    Title: 'تم بنجاح!',
                    msg: 'تم تجديد بيانات الدخول بنجاح. يمكنك متابعة استخدام النظام.',
                  });
                })
                .catch(async (error) => {
                  CustomAlert({
                    action: 'Error',
                    Title: 'عذرًا، حدث خطأ',
                    msg: 'تعذر تجديد بيانات الدخول. سيتم تسجيل خروجك تلقائيًا لضمان الأمان.',
                    Timer: 4000,
                  });
                  await delay(4000);
                  localStorage.clear();
                  window.location.replace('/login');
                });

              // عرض Swal أثناء تحديث الرمز
              Swal.fire({
                title: 'جارٍ تجديد بيانات الدخول...',
                html: 'يرجى الانتظار لحظات.',
                allowOutsideClick: false,
                allowEscapeKey: false,
                background: 'var(--bs-body-bg)',
                color: 'var(--bs-heading-color)',
                customClass: {
                  popup: 'border border-theme border-3 rounded-4',
                },
                didOpen: () => {
                  Swal.showLoading(); // عرض تحميل أثناء العملية
                },
              });
            } else {
              // Logout logic
              localStorage.clear();
              window.location.replace('/login');
            }
          });
        }
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(timer);
    }
  }, [expirationTime]);

  return (
    <>
      <PopoverCustom
        childern={
          <div className="me-2">
            {timeLeft > 0 ? (
              <i className="bi bi-circle-fill text-success"></i>
            ) : timeLeft < 0 ? (
              <i className="bi bi-circle-fill text-light"></i>
            ) : null}
          </div>
        }
        Header="حالة صلاحية الجلسة"
        Body={
          <div className="text-center">
            {timeLeft > 0 ? (
              <p className="text-center m-0">
                <span>تنتهي صلاحية الجلسة خلال</span>
                <span className="fw-bold mx-2 text-theme fw-bold fs-6">
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </p>
            ) : timeLeft < 0 ? (
              <p className="text-center text-danger m-0 p-0 ">إنتهت الجلسة</p>
            ) : null}
          </div>
        }
        placement="bottom"
      />
    </>
  );
};

export default TimerToken;
