import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getBalanceSheet } from '../../../../store/Finance/FinancialReports/FinancialReportsSlice';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody, CardHeader } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/Shared/Loader/Loading';

const BalanceSheet = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState({ startData: '', endData: '' });

  useEffect(() => {
    dispatch(
      getBalanceSheet({
        startDate: filter.startData,
        endDate: filter.endData,
      })
    );
  }, []);

  const handleDispatch = () => {
    dispatch(
      getBalanceSheet({
        startDate: filter.startData,
        endDate: filter.endData,
      })
    );
  };

  const { BalanceSheet: data, loading, error } = useAppSelector((s) => s.FinancialReports);

  const Assets = data?.list?.find((item) => item.accountNumber === '10000000');
  const Liabilities = data?.list?.find((item) => item.accountNumber === '20000000');
  const Equity = data?.list?.find((item) => item.accountNumber === '30000000');

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'المركــز المالي',
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="المركــز المالي" />
      <hr />

      <div className="tab-content p-1">
        <div className="row mb-2">
          <div className="col-lg-5">
            <div className="input-group flex-nowrap">
              <span className="input-group-text fw-bold">{t('من تاريـخ')}</span>
              <div className="input-group">
                <input
                  type="date"
                  autoComplete="off"
                  className="form-control"
                  value={filter.startData}
                  onChange={(e) => {
                    setFilter({ ...filter, startData: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="input-group flex-nowrap">
              <span className="input-group-text fw-bold">{t('إلى تاريـخ')}</span>
              <div className="input-group">
                <input
                  type="date"
                  autoComplete="off"
                  className="form-control"
                  value={filter.endData}
                  onChange={(e) => {
                    setFilter({ ...filter, endData: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-2">
            <button className="btn btn-theme w-100" onClick={handleDispatch}>
              {t('تحديث')}
            </button>
          </div>
        </div>

        <Loading loading={loading} error={error} Type="Dots">
          <div className="row d-flex align-items-stretch">
            <div className="col-12 col-lg-6 mb- ">
              <Card className="h-100">
                <CardHeader className="bg-theme bg-opacity-15">
                  <h5 className="m-0">{Assets?.name2} </h5>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table text-nowrap">
                      <thead className=" ">
                        <tr>
                          <th>رقم الحساب</th>
                          <th>اسم الحساب</th>
                          <th>مدين (DR)</th>
                          <th>دائن (CR)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Assets?.children?.map((category) => (
                          <React.Fragment key={category.id}>
                            <tr className="table-active fw-bold">
                              <td>{category.accountNumber}</td>
                              <td>{category.name2}</td>
                              <td>{category.amounT_DR}</td>
                              <td>{category.amounT_CR}</td>
                            </tr>
                            {category.children?.map((account) => (
                              <tr key={account.id}>
                                <td className="ps-4">{account.accountNumber}</td>
                                <td>{account.name2}</td>
                                <td>{account.amounT_DR}</td>
                                <td>{account.amounT_CR}</td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}
                        {/* إجمالي الأصول */}
                        <tr className="   fw-bold">
                          <td colSpan={2} className="text-center ">
                            إجمالي الأصول
                          </td>
                          <td>{Assets?.amounT_DR}</td>
                          <td>{Assets?.amounT_CR}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-12 col-lg-6 mb-">
              <Card className="h-100">
                <CardHeader className="bg-theme bg-opacity-15">
                  <h5 className="m-0">الخصوم</h5>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table text-nowrap">
                      <thead className=" ">
                        <tr>
                          <th>رقم الحساب</th>
                          <th>اسم الحساب</th>
                          <th>مدين (DR)</th>
                          <th>دائن (CR)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Liabilities?.children?.map((category) => (
                          <React.Fragment key={category.id}>
                            <tr className="table-active fw-bold">
                              <td>{category.accountNumber}</td>
                              <td>{category.name2}</td>
                              <td>{category.amounT_DR}</td>
                              <td>{category.amounT_CR}</td>
                            </tr>
                            {category.children?.map((account) => (
                              <tr key={account.id}>
                                <td className="ps-4">{account.accountNumber}</td>
                                <td>{account.name2}</td>
                                <td>{account.amounT_DR}</td>
                                <td>{account.amounT_CR}</td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}
                        {/*  إجمالي الخصـوم */}
                        <tr className="  fw-bold">
                          <td colSpan={2} className="text-center">
                            إجمالي الخصـوم
                          </td>
                          <td>{Liabilities?.amounT_DR}</td>
                          <td>{Liabilities?.amounT_CR}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>

                <CardHeader className="bg-theme bg-opacity-15">
                  <h5 className="m-0">حقوق الملكيـة</h5>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table text-nowrap">
                      <thead className=" ">
                        <tr>
                          <th>رقم الحساب</th>
                          <th>اسم الحساب</th>
                          <th>مدين (DR)</th>
                          <th>دائن (CR)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Equity?.children?.map((category) => (
                          <React.Fragment key={category.id}>
                            <tr className="table-active fw-bold">
                              <td>{category.accountNumber}</td>
                              <td>{category.name2}</td>
                              <td>{category.amounT_DR}</td>
                              <td>{category.amounT_CR}</td>
                            </tr>
                            {category.children?.map((account) => (
                              <tr key={account.id}>
                                <td className="ps-4">{account.accountNumber}</td>
                                <td>{account.name2}</td>
                                <td>{account.amounT_DR}</td>
                                <td>{account.amounT_CR}</td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}

                        <tr className="  fw-bold">
                          <td colSpan={2} className="text-center">
                            إجمالي حقوق الملكيـة
                          </td>
                          <td>{Equity?.amounT_DR}</td>
                          <td>{Equity?.amounT_CR}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row d-flex align-items-stretch mt-2">
            <div className="col-12 col-lg-6 mb-">
              <Card className="h-100">
                <CardBody className="d-flex justify-content-between align-items-center">
                  <span className="text-muted fs-5 fw-bold">إجمالي الأصول</span>
                  <span className="text-theme fs-4 fw-bold">00000</span>
                </CardBody>
              </Card>
            </div>

            <div className="col-12 col-lg-6 mb-">
              <Card className="h-100">
                <CardBody className="d-flex justify-content-between align-items-center">
                  <span className="text-muted fs-5 fw-bold"> إجمالي الخصوم وحقوق الملكــية</span>
                  <span className="text-theme fs-4 fw-bold">00000</span>
                </CardBody>
              </Card>
            </div>
          </div>
        </Loading>
      </div>
    </>
  );
};

export default BalanceSheet;
