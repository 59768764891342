import { HasPermission } from '../../Helper/Permisstions/Permisstions';

const salesMenu = [
   {
    System: 'SAL',
    menu: [
      { is_header: true, title: 'المبيعات', isActive: true },

      {
        path: 'SystemDashboard',
        icon: 'fas fa-home',
        title: 'Home',
        isActive: true,
      },

      { is_header: true, title: 'العمليات', isActive: true },

      {
        path: 'SalesTransactions/Quotation',
        icon: 'fas fa-file-alt',
        title: 'عروض الأسعار',
        isActive: true,
      },

      {
        path: 'SalesTransactions/SellOrder',
        icon: 'fas fa-shopping-cart',
        title: 'أوامر البيع',
        isActive: true,
      },

   
      {
        path: 'SalesTransactions/SalesReturn',
        icon: 'fas fa-undo',
        title: 'مردود المبيعات',
        isActive: true,
      },

      

      { is_header: true, title: 'الإعدادات', isActive: true },

      {
        path: 'SalesSettings/Customers',
        icon: 'fas fa-users',
        title: 'العملاء',
        isActive: true,
      },
   
      {
        path: 'SalesSettings/PriceList',
        icon: 'fas fa-tags',
        title: 'قائمة الأسعار',
        isActive: true,
      },
      {
        path: "SalesSettings/Vouchers",
        icon: "fas fa-ticket-alt",
        title: "القسـائم",
        isActive: true,
      },
      {
        path: 'SalesSettings/Vouchers2',
        icon: 'fas fa-ticket-alt',
        title: 'القسـائم',
        isActive: true,
      },
   

      { is_header: true, title: '_', isActive: true },

      // {
      //   path: 'Sales/SalesRequests',
      //   icon: 'fas fa-clipboard-list',
      //   title: 'الطلبات',
      //   isActive: true,
      // },
      {
        path: 'Sales/SalesReports',
        icon: 'fas fa-chart-bar',
        title: 'التقارير',
        isActive: true,
      },
      {
        path: 'Sales/SalesDashboards',
        icon: 'fas fa-tachometer-alt',
        title: 'لوحة البيانات',
        isActive: true,
      },
    ],
  },
];

export default salesMenu;
