import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../API/axiosNeatex';
import { MaterialTransactionsModels } from './MaterialTransactionsModels';

export const AddMaterialTransactions = createAsyncThunk(
  'MaterialTransactions/AddMaterialTransactions',
  async (item: any, { rejectWithValue }) => {
    // console.log(JSON.stringify(item));
    // try {
    //   const { data } = await ntxAPI.post("Inv/AddMaterialTransactions", item);
    //   return data;
    // } catch (error: any) {
    //   return rejectWithValue(error.message);
    // }
  }
);

export const GetMaterialTransactionsList = createAsyncThunk(
  'MaterialTransactions/GetMaterialTransactionsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get('Inv/GetMaterialTransactionsList');
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetMaterialTransactionsPaged = createAsyncThunk(
  'MaterialTransactions/GetMaterialTransactionsPaged',
  async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get(
        `Inv/GetMaterialTransactionsPaged?PageIndex=${pageIndex}&PageSize=${pageSize}`
      );
      if (data?.succeeded === true) {
        return data.data;
      } else {
        return rejectWithValue(data?.message || 'هناك خطأ');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);





const initialState: {
  MaterialTransactionsList: MaterialTransactionsModels[];
  loading: boolean;
  error: any | null;
  //_______________________________

  data: any[];
  totalItems: number;
  pageIndex: number;
  pageSize: number;

} = {
  MaterialTransactionsList: [],
  loading: false,
  error: null,

  data: [],
  totalItems: 0,
  pageIndex: 1,
  pageSize: 10,

  //_______________________________

 
};

const MaterialTransactionsSlice = createSlice({
  name: 'MaterialTransactions',
  initialState,
  reducers: {
    setPageIndex: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    // setSearchQuery: (state, action: PayloadAction<string>) => {
    //   state.searchQuery = action.payload; // تحديث قيمة البحث
    // }
  },
  extraReducers(builder) {
    builder

      // .addCase(GetMaterialTransactionsList.pending, (state, action) => {
      //   state.loading = true;
      // })
      // .addCase(
      //   GetMaterialTransactionsList.fulfilled,
      //   (state, action: PayloadAction<MaterialTransactionsModels[]>) => {
      //     state.loading = false;
      //     state.MaterialTransactionsList = action.payload;
      //   }
      // )
      // .addCase(GetMaterialTransactionsList.rejected, (state, action: PayloadAction<any>) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })

      .addCase(GetMaterialTransactionsPaged.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetMaterialTransactionsPaged.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        // state.data = action.payload;
        state.data = action.payload.data;
        state.totalItems = action.payload.totalItems;

 
      })
      .addCase(GetMaterialTransactionsPaged.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setPageIndex, setPageSize } = MaterialTransactionsSlice.actions
export default MaterialTransactionsSlice.reducer;
