import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetTransactionTypesList } from '../../../store/INV/TransactionTypes/TransactionTypesSlice';
import { useTranslation } from 'react-i18next';

const useGetTransactionTypesList = () => {
  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();

  useEffect(() => {
    if (TransactionTypesList?.length === 0) {
      dispatch(GetTransactionTypesList());
    }
  }, []);
  const { TransactionTypesList, loading, error } = useAppSelector(
    (state) => state.TransactionTypes_Inv
  );

  const OptionsTransactionTypes = TransactionTypesList?.map((item) => ({
    value: item.code,
    label: i18n.language === 'en' ? item.name : item.name2,
    transTypeId: item.id,
    // isDisabled: ['WOI', 'MOI'].includes(item.code),
  }));

  return { TransactionTypesList, OptionsTransactionTypes, error, loading };
};

export default useGetTransactionTypesList;
