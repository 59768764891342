import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

 import Pagination from "../../../../../components/Shared/Pagination/Pagination.jsx";
import { Card, CardBody } from "../../../../../components/card/card.jsx";
import Loader from "../../../../../components/Shared/Loader/Loader.js";
import { GetContractLease } from "../../../../../store/SU/contractLeaseSlice.js";
import Breadcrumb from "../../../../../components/Shared/Breadcrumb";

const Contracts = () => {
  // const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  /////////////////////////////////////////////////////
  const [search, setSearch] = useState("");
  // const [sortBy, setSortBy] = useState("");
  // const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const { ContractLeaseList, loading } = useSelector(
    (state) => state.Contracts
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if(ContractLeaseList?.length === 0){
      dispatch(GetContractLease());
    }
  }, [dispatch]);

  //_____________________
 
  //_____________________
  const filteredSearch = ContractLeaseList?.slice(0 ,1)?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase) ||
      item.contractLeaseId?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  
 
  //_____________________
  var BcrumbList = [
    {
      name: t('Home') ,
      link: "/",
    },
    {
      name: "Contracts",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName= "Contracts" />
      <Card>
        <CardBody>
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text" 
autoComplete='off'
                          className="form-control ps-35px"
                          onChange={(event) => setSearch(event.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end flex ">
                  <Link to="AddSalesContracts" className="btn btn-theme me-1">
                    <i className="fa fa-plus-circle fa-fw me-1"></i>
                    {t("Add_Contract")}
                  </Link>

                </div>
              </div>

              <Loader loading={loading} />

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table ">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">رقم العقد</th>
                      <th scope="col">بداية العقد</th>
                      <th scope="col">نهاية العقد</th>
                      <th scope="col">العميل</th>
                       <th scope="col">قيمة العقد</th>
                      <th scope="col">الفرع</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>

                        <tr >
                          <td className="align-middle">
                            35889
                          </td>
                           <td className="align-middle">
                           2024-01-01
                          </td>
                          <td className="align-middle">2024-12-31	</td>
 
                          <td className="align-middle">
                            المجلة العربية
                          </td>
                          <td className="align-middle">120.000</td>
                          <td className="align-middle">
                           أبهــا
                          </td>
                          <td>
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-outline-theme btn-lg"
     
                              >
                                <i className="far fa-lg fa-fws fa-edit text-theme"></i>
                               </button>
                
                            </div>
                          </td>
                        </tr>
      

                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Contracts;

 