import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import { _AddPriceList } from '../../../../store/INV/PriceList/PriceListSlice';
import { AddPriceListSchema } from '../../../ValidationForm/validationSchema';
import useGetPriceList from '../../../../hooks/INV/PriceList/useGetPriceList';

const AddPriceList = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { Currency } = useLookupCurrency();
  const { PriceList } = useGetPriceList();

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      name2: '',
      description: '',
      typeCode: '',
      currencyCode: 'SR',
      startDateActive: '',
      endDateActive: '',
      parentId: '',
    },

    validationSchema: AddPriceListSchema(t),

    onSubmit: (values) => {
      dispatch(
        _AddPriceList({
          name: values.name,
          name2: values.name2,
          description: values.description,
          typeCode: values.typeCode,
          currencyCode: values.currencyCode,
          startDateActive: values.startDateActive,
          endDateActive: values.endDateActive,
          parentId: values.parentId || null,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            document.getElementById('AddPriceList')?.click();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
      formik.setSubmitting(false);
    },
  });
  // console.log(formik.errors);

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control "
                type="text" 
autoComplete='off'
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control"
                type="text" 
autoComplete='off'
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('parentId')}</label>
              <Form.Select
                className="form-select"
                name="parentId"
                onChange={formik.handleChange}
                value={formik.values.parentId}
                isInvalid={!!(formik.touched.parentId && formik.errors.parentId)}>
                <option value="">
                  {i18n.language === 'ar' ? 'قائمة أسعار رئيسية' : 'Main price list'}
                </option>
                {PriceList &&
                  PriceList?.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === 'en' ? item.name : item.name2}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('من تاريخ')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="startDateActive"
                onChange={formik.handleChange}
                value={formik.values.startDateActive}
                isInvalid={!!(formik.touched.startDateActive && formik.errors.startDateActive)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.startDateActive}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('إلى ناريخ')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="endDateActive"
                onChange={formik.handleChange}
                value={formik.values.endDateActive}
                isInvalid={!!(formik.touched.endDateActive && formik.errors.endDateActive)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.endDateActive}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group mb-3">
              <label className="form-label"> {t('Currency')} </label>
              <Form.Select
                className="form-select"
                name="currencyCode"
                onChange={formik.handleChange}
                value={formik.values.currencyCode}
                isInvalid={!!(formik.touched.currencyCode && formik.errors.currencyCode)}
                // isValid={formik.touched.currencyCode && !formik.errors.currencyCode}
              >
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {Currency &&
                  Currency?.map((item: any, idx: number) => (
                    <option key={++idx} value={item.currencyCode}>
                      {item.description}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddPriceList;
